import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Constants from "../constants";
import API from "../api";
import Loader from '../loader'
import _ from "lodash";
import NotificationManager from "react-notifications/lib/NotificationManager";
import MultiSelect from "../broadcast/MultiSelect/MultiSelect";
import tabList from "../TabList";
import { Editor } from '@tinymce/tinymce-react';
import "./Middleupper.css";
import HomepageHeadings from "./HomepageHeadings";

const Middleupper = (props) => {
  let {
    tag,
    showRows,
    onAddClick,
    showLoader,
    getFinal,
    seeLoader,
    finalContent,
    homepageHeadings,
    getHomepageHeadings
  } = props;
  const initialFaqState = { question: "", answer: "",order:"" };
  const [realFaq, setRealFaq] = useState([])
  const [realBtm, setRealBtm] = useState({})
  const [realSeo, setRealSeo] = useState({})
  const [faq, setFaq] = useState([]);
  const [realSub_heading, setRealSub_heading] = useState('')
  const [sub_heading, setSub_heading] = useState('')

  const [staticFaq, setStaticFaq] = useState(initialFaqState);
  const [state, setState] = useState({
    isActive: null,
    selectedTag: null,
    view:"hidden",
    viewTemplate:false,
    brands: [],
    bottom_content:{},
    selectedBrandForModel: [],
    brandsList: [],
    selectedBrand:[],
    selected_segment: null,
    modelsForBrand: [],
    segmentsList: [],
    categoryList: [],
    subCategoryList: [],
    categoriesList:[],
    filter_key: '',
    faq_name:'',
    model:'',
    selectedModel: [],
    searchedItem: [],
    store_code:'',
    heading:'',
    sub_desc:'',
    sub_heading:'',
    page_heading:'',
    title:'',
    meta_description:'',
    about:'',
    desc:'',
    selected_category: null,
    selected_sub_category: null,
  });
  
  useEffect(() => {
    window.scrollTo(0,0)
    setState({...state, view: "hidden" });
    setState((prev) => ({ ...prev, viewTemplate: false}))
    setState((prev) => ({ ...prev, selected_segment: []})); 
    setState((prev) => ({ ...prev, selected_sub_category: []})); 
    setState((prev) => ({ ...prev, selected_category: []}));  
    setState((prev) => ({ ...prev, selectedModel: []}));  
    setState((prev) => ({ ...prev, selectedBrand: []}));  
    setState((prev) => ({ ...prev, filter_key: ''})); 
    setFaq([])
    setState({...state,title:'',bottom_content:{...state.bottom_content,heading: ''}, meta_description:'', page_heading:''})
    
    if(tag === "HOMEPAGE" ){
    // props.setLoader({showLoader:false})
    // setState((prev)=>({...prev,selectedTag: "home_related" }));
    setState((prev)=>({...prev,selectedTag: "spares" }));
    setState((prev) => ({ ...prev, isActive: true }));
   }
    else if(tag === "PRODUCT"){
    setState((prev) => ({ ...prev, selectedTag: "product-faq" }));
    setState((prev) => ({ ...prev, isActive: true }));
    } 
    else if(tag=== "CATEGORY" ){
    setState({...state, view: "hidden" });
      setState((prev) => ({ ...prev, selectedTag: "category_faq" }));
      setState((prev) => ({ ...prev, isActive: true }));

      }
    else if(tag=== "SEGMENT" ){
    setState({...state, view: "hidden" });
      setState((prev) => ({ ...prev, selectedTag: "segment-faq" }));
      setState((prev) => ({ ...prev, isActive: true }));

      }
    else if( tag === "BRAND"  ){
    setState({...state, view: "hidden" });
    setState((prev) => ({ ...prev, selectedTag: "brand_faq" }));
    setState((prev) => ({ ...prev, isActive: true }));
    
  }
    
    else if( tag === "SUBCATEGORY" ){
    setState({...state, view: "hidden" });

    setState((prev) => ({ ...prev, selectedTag: "subCategory_faq" }));
    setState((prev) => ({ ...prev, isActive: true }));

    }
    else if(tag === "VEHICLE"  ){
    setState({...state, view: "hidden" });

    setState((prev) => ({ ...prev, selectedTag: "vehicle_faq" }));
    setState((prev) => ({ ...prev, isActive: true }));

    }
    else if( tag === "STORE" ){
    setState({...state, view: "hidden" });

    setState((prev) => ({ ...prev, selectedTag: "store-faq" }));
    setState((prev) => ({ ...prev, isActive: true }));

    }
    getSubCategoriesList();
    getCategoriesList();
    getSegmentList() 
    getCarBrands();
    getBrandsList();
  }, [tag]);

  useEffect(() => {
    if(tag === "HOMEPAGE"){
      setFaq(finalContent?finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].faq:[])
      setRealFaq(finalContent?finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].faq:[])
    setRealBtm(finalContent?finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].bottom_content:{})
    setRealSeo(finalContent?finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].meta_data:{})
   
      // seeLoader()  
   setState({...state,page_heading:finalContent?(finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].meta_data && finalContent.filter(item=>item.slug==="spares")[0].meta_data).page_heading||'':'',
      title:finalContent?(finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].meta_data && finalContent.filter(item=>item.slug==="spares")[0].meta_data).title||'':'',
    meta_description:finalContent?(finalContent.filter(item=>item.slug==="spares")[0] && finalContent.filter(item=>item.slug==="spares")[0].meta_data && finalContent.filter(item=>item.slug==="spares")[0].meta_data).meta_description||'':'',
    bottom_content:finalContent?( finalContent.filter(item=>item.slug==="spares")[0] &&finalContent.filter(item=>item.slug==="spares")[0]).bottom_content||{}:{},
  })
}

else if(tag==="PRODUCT"){
    setRealFaq(finalContent?finalContent.filter(item=>item.slug==="product")[0]&& finalContent.filter(item=>item.slug==="product")[0].faq:[])
    setFaq(finalContent?finalContent.filter(item=>item.slug==="product")[0] && finalContent.filter(item=>item.slug==="product")[0].faq:[])
}
}, [state.selectedTag,finalContent])
  
  let history = useHistory();

  const homeTop = tabList[0].subTabs[0].subTabs[0].attributes;
  const productTop = tabList[0].subTabs[0].subTabs[1].attributes;
  const brandTop = tabList[0].subTabs[0].subTabs[2].attributes;
  const segmentTop = tabList[0].subTabs[0].subTabs[3].attributes;
  const catTop = tabList[0].subTabs[0].subTabs[4].attributes;
  const subCatTop = tabList[0].subTabs[0].subTabs[5].attributes;
  const vehicleTop = tabList[0].subTabs[0].subTabs[6].attributes; 
  const storeTop = tabList[0].subTabs[0].subTabs[7].attributes;


  function handleTinyMCEChange(type, e) {
    let obj=state.bottom_content
    obj[type]=e
    // setState({...obj,[type]:e});
    setState({...state,bottom_content:{...state.bottom_content,[type]:e}})
  }

  const onInputChange = (e) => {
    if(e.target.name==="order"){
      setStaticFaq({ ...staticFaq, [e.target.name]: e.target.value==0?"":Number(e.target.value) })
    }
    else{
    setStaticFaq({ ...staticFaq, [e.target.name]: e.target.value });
  }
  };

function saveApi(obj){
  API.addContent(obj).then(function (response) {
    if (response.data.status) {
       // to get updated finalContent in props
       getFinal() 
      NotificationManager.success(
    'Successfully',
    'FAQs added'  )} 
  })
  .catch(function () {
    NotificationManager.error('FAQ is already created', );
    setStaticFaq(initialFaqState);
  })
  .finally(function () {
  });
}

  function saveFaqs(){
    if( tag=== "PRODUCT" ){
      const obj={
        name:"product",
        type:tag,
        faq:faq
      }
      saveApi(obj)
    }
    else if(tag==="HOMEPAGE"){
      const obj={
        name:state.selectedTag,
        type:tag,
        faq:faq
      }
      saveApi(obj)
    }
    else if(tag==="STORE"){
      const obj={
        name:"store "+state.store_code,
        type:tag,
        faq:faq
      }
      saveApi(obj)
      setState({...state,store_code:""})
    }
   else if(tag==="VEHICLE"){
      const obj={
        name:state.model+ " " +state.filter_key,
        type:tag,
        faq:faq
      }
      saveApi(obj)
    }
    else{
    const obj={
      name:state.filter_key,
      type:tag,
      faq:faq
    }
    saveApi(obj)
     if(tag==="BRAND"){
      setRealFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~s')[0].faq||[]:[])
     }

  }
}
function updateApi(obj){
  API.updateContent(obj).then(function (response) {
    if (response.data.status) {
      // to get updated finalContent in props
      getFinal()      
      NotificationManager.success(
    'Updated',
    'Successfully',
    
  )} 
  })
  .catch(function () {
  })
  .finally(function () {
  });
}

 function updateFaqs(){
  if(tag==="HOMEPAGE" ){
    const obj={
      slug:state.selectedTag,
      faq:faq
    }
    updateApi(obj)
  
  }
  else if(tag==="PRODUCT" ){
    const obj={
      slug:"product",
      faq:faq
    }
    updateApi(obj)
  }
  else if(tag==="STORE" ){
    const obj={
      slug:("store-"+state.store_code).toLowerCase(),
      faq:faq
    }
    updateApi(obj)
  }
  else{
    if(tag==="BRAND"){
      const obj={
        slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~s",
        faq:faq ?faq:[]
      }
          updateApi(obj)

    }
    else if(tag==="SEGMENT"){
      const obj={
        slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~c",
        faq:faq ?faq:[]
      }
          updateApi(obj)
    }
    else if(tag==="CATEGORY"){
      const obj={
        slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc',
        faq:faq ?faq:[]
      }
          updateApi(obj)

    }
    else if(tag==="SUBCATEGORY"){
      const obj={
        slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc',
        faq:faq ?faq:[]
      }
          updateApi(obj)

    }
    else if(tag==="VEHICLE"){
      const obj={
        slug:(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()
        ,
        faq:faq ?faq:[]
      }
      updateApi(obj)

    }
  }
  
 }
 function deleteApi(obj){
  API.deleteContent(obj).then(function (response) {
    if (response.data.status) {
      NotificationManager.success(
    'Deleted',
    'Successfully',
    
  )} 
  setState((prev)=>({...prev, view: "hidden" }));
  setFaq([])
  tag="BRAND" && getBrandsList();
  tag="SUBCATEGORY" && getSubCategoriesList();
  tag="CATEGORY" && getCategoriesList()
  tag="SEGMENT" && getSegmentList()
  tag="VEHICLE" && getCarBrands();
  })
  .catch(function () {
  })
  .finally(function () {
  });
 }

  function deleteFaq(){
    if(tag=== "PRODUCT"){
      const obj={
        slug:"product",
      }
      deleteApi(obj)
  }
  else if(tag==="HOMEPAGE"){
    const obj={
      slug:state.selectedTag,
    }
    deleteApi(obj)
  }
  else if(tag==="STORE"){
    const obj={
      slug:("store-"+state.store_code).toLowerCase(),
    }
    deleteApi(obj)
    setState({...state,store_code:""})
  }
  else{
    if(tag==="BRAND"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~s",
    }
    deleteApi(obj)
  }
  else if(tag==="SEGMENT"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~c",
    }
    deleteApi(obj)
  }
  else if(tag==="CATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~sc",
    }
    deleteApi(obj)
  }
  else if(tag==="SUBCATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~ssc",
    }
    deleteApi(obj)
  }
  else if(tag==="VEHICLE"){
    const obj={
      slug:(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase(),
    }
    deleteApi(obj)

  }
  
  }
}

  function topNav() {
    return (
      <>
        {homeTop &&
          homeTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}
      </>
    );
  }
function saveBtmApi(obj){
  API.addContent(obj).then(function (response) {  
    if (response.data.status) {
      // to get updated finalContent in props
      getFinal() 
      NotificationManager.success(
    'Successfully',
    'Bottom Content added',
    
  )}
})
  .catch(function () {
    NotificationManager.error('Bottom Content is already created', );
  })
  .finally(function () {
  });
}
function saveBTM(){

 if(tag==="STORE"){
  const obj={
    name:"store "+state.store_code,
    type:tag,
    bottom_content:{
      heading: state.bottom_content && state.bottom_content.heading,
      sub_desc: state.bottom_content && state.bottom_content.sub_desc,
      desc: state.bottom_content && state.bottom_content.desc
    }
}
  saveBtmApi(obj)
}
else if(tag==="HOMEPAGE"){
  const obj={
    name:'spares',
    type:tag,
    bottom_content:{
      heading: state.bottom_content && state.bottom_content.heading,
      sub_desc: state.bottom_content && state.bottom_content.sub_desc,
      desc: state.bottom_content && state.bottom_content.desc
    }
  }
  saveBtmApi(obj)
}
else if(tag==="VEHICLE"){
  const obj={
    name:state.model+ " " +state.filter_key,
    type:tag,
    bottom_content:{
      heading: state.bottom_content && state.bottom_content.heading,
      sub_desc: state.bottom_content && state.bottom_content.sub_desc,
      desc: state.bottom_content && state.bottom_content.desc
    }
  }
  saveBtmApi(obj)
}
else{
const obj={
  name:state.filter_key,
  type:tag,
  bottom_content:{
    heading: state.bottom_content && state.bottom_content.heading,
    sub_desc: state.bottom_content && state.bottom_content.sub_desc,
    desc: state.bottom_content && state.bottom_content.desc
  }
}
saveBtmApi(obj)

}
}

function deleteBtmApi(obj){
  API.deleteContent(obj).then(function (response) {
    if (response.data.status) {
      NotificationManager.success(
    'Deleted',
    'Successfully',
    
  )} 
  
  setState((prev)=>({...prev, view: "hidden" }));
  setState((prev) => ({ ...prev, filter_key: ''})); 
  tag="BRAND" && getBrandsList();
  tag="SUBCATEGORY" && getSubCategoriesList();
  tag="CATEGORY" && getCategoriesList()
  tag="SEGMENT" && getSegmentList()
  tag="VEHICLE" && getCarBrands();
  })
  .catch(function () {
  })
  .finally(function () {
  });
}
function deleteBTM(){

  if(tag==="STORE"){
    const obj={
      slug:("store-"+state.store_code).toLowerCase(),
    }
    deleteBtmApi(obj)
    setState({...state,store_code:""})
  }
  else{
    if(tag==="BRAND"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~s",
    }
    deleteBtmApi(obj)
  }
  else if(tag==="SEGMENT"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~c",
    }
    deleteBtmApi(obj)
  }
  else if(tag==="CATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~sc",
    }
    deleteBtmApi(obj)
  }
  else if(tag==="HOMEPAGE"){
    const obj={
      slug:"spares",
    }
    deleteBtmApi(obj)
  }
  else if(tag==="SUBCATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~ssc",
    }
    deleteBtmApi(obj)
  }
  else if(tag==="VEHICLE"){
    const obj={
      slug:(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase(),
    }
    deleteBtmApi(obj)

  }
}
}

function updateBtmApi(obj){
  API.updateContent(obj).then(function (response) {
    if (response.data.status) {
      // to get updated finalContent in props
      getFinal() 
      NotificationManager.success(
    'Updated',
    'Successfully',
    
  )} 
  })
  .catch(function () {
  })
  .finally(function () {
  });
}
function updateBTM(){
  
  if(tag==="STORE"){
    const obj={
      slug:("store-"+state.store_code).toLowerCase(),
      bottom_content:{
        heading: state.bottom_content && state.bottom_content.heading,
        sub_desc: state.bottom_content && state.bottom_content.sub_desc,
        desc: state.bottom_content && state.bottom_content.desc
      }
    }
    updateBtmApi(obj)
  }
  else if(tag==="HOMEPAGE"){
    const obj={
      slug:'spares',
      bottom_content:{
        heading: state.bottom_content && state.bottom_content.heading,
        sub_desc: state.bottom_content && state.bottom_content.sub_desc,
        desc: state.bottom_content && state.bottom_content.desc
      }
    }
    updateBtmApi(obj)
  }
  else if(tag==="VEHICLE"){
    const obj={
        slug:(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase(),
        bottom_content:{
          heading: state.bottom_content && state.bottom_content.heading,
          sub_desc: state.bottom_content && state.bottom_content.sub_desc,
          desc: state.bottom_content && state.bottom_content.desc
        }
    }
      updateBtmApi(obj)

    }
  else{
    if(tag==="BRAND"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~s",
      bottom_content:{
        heading: state.bottom_content && state.bottom_content.heading,
        sub_desc: state.bottom_content && state.bottom_content.sub_desc,
        desc: state.bottom_content && state.bottom_content.desc
      }
    }
    updateBtmApi(obj)
  }
  else if(tag==="SEGMENT"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~c",
      bottom_content:{
        heading: state.bottom_content && state.bottom_content.heading,
        sub_desc: state.bottom_content && state.bottom_content.sub_desc,
        desc: state.bottom_content && state.bottom_content.desc
      }
    }
    updateBtmApi(obj)
  }
  else if(tag==="CATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc',
      bottom_content:{
        heading: state.bottom_content && state.bottom_content.heading,
        sub_desc: state.bottom_content && state.bottom_content.sub_desc,
        desc: state.bottom_content && state.bottom_content.desc
      }
    }
    updateBtmApi(obj)
  }
  else if(tag==="SUBCATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc',
      bottom_content:{
        heading: state.bottom_content && state.bottom_content.heading,
        sub_desc: state.bottom_content && state.bottom_content.sub_desc,
        desc: state.bottom_content && state.bottom_content.desc
      }
    }
    updateBtmApi(obj)
  }
}
}

function saveSEOApi(obj){
  API.addContent(obj).then(function (response) {  
    if (response.data.status) {
      // to get updated finalContent in props
      getFinal() 
      NotificationManager.success(
    'Successfully',
    'SEO added',
    
  )}
})
  .catch(function () {
    NotificationManager.error('SEO is already created', );
  })
  .finally(function () {
  });
}
function saveSEO(){
  if (
    (state.title && state.title.trim() )=== '' ||
    (state.meta_description&& state.meta_description.trim()) === ''
  ) {
    NotificationManager.error('Title & Description are mandatory.', 'Error!!', 5000);
    return;
  }

 if(tag==="STORE"){
  const obj={
    name:"store "+state.store_code,
    type:tag,
    meta_data:{
      page_heading:state.page_heading,
      title: state.title,
      meta_description: state.meta_description,
    }
}
  saveSEOApi(obj)
}
else if(tag==="HOMEPAGE"){
  const obj={
    name:"spares",
    type:tag,
    meta_data:{
      page_heading:state.page_heading,
      title: state.title,
      meta_description: state.meta_description,
    }
  }
  saveSEOApi(obj)

}
else if(tag==="VEHICLE"){
  const obj={
    name:state.model+ " " +state.filter_key,
    type:tag,
    meta_data:{
      page_heading:state.page_heading,
      title: state.title,
      meta_description: state.meta_description,
    }
  }
  saveSEOApi(obj)
}
else{
const obj={
  name:state.filter_key,
  type:tag,
  meta_data:{
    page_heading:state.page_heading,
    title: state.title,
    meta_description: state.meta_description,
  }
}
saveSEOApi(obj)

}
}
function saveSubHeadingApi(obj){
  API.addSubHeadingContent(obj).then(function (response) {  
    if (response.data.status) {
       // to get updated finalContent in props
        getFinal() 
      NotificationManager.success(
    'Successfully',
    'SubHeading saved',
    
  )}
})
  .catch(function () {
    NotificationManager.error('SubHeading is already created', );
  })
  .finally(function () {
  });
}
function saveSubHeading(){
  const obj={
    name:state.filter_key,
    type:tag,
    sub_heading:sub_heading
  }
  saveSubHeadingApi(obj)
}
function updateSEOApi(obj){
  API.updateContent(obj).then(function (response) {  
    if (response.data.status) {
    // to get updated finalContent in props
    getFinal() 
    NotificationManager.success(
    'Successfully',
    'SEO updated ',
    
  )}
})
  .catch(function () {
    NotificationManager.error('SEO is already created', );
  })
  .finally(function () {
  });
}

function updateSEO(){
  if (
  (  state.title && state.title.trim() )=== '' ||
   (state.meta_description && state.meta_description.trim()) === ''
  ) {
    NotificationManager.error('Title & Description are mandatory.', 'Error!!', 5000);
    return;
  }

  if(tag==="STORE"){
   const obj={
     slug:("store-"+state.store_code).toLowerCase(),
     meta_data:{
      page_heading:state.page_heading,
       title: state.title,
       meta_description: state.meta_description,
     }
 }
   updateSEOApi(obj)
 }
 else if(tag==="HOMEPAGE"){
   const obj={
     slug:"spares",
     meta_data:{
      page_heading:state.page_heading,
       title: state.title,
       meta_description: state.meta_description,
     }
   }
   updateSEOApi(obj)
 
 }

 else{
  if(tag==="BRAND"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~s",
      meta_data:{
        page_heading:state.page_heading,
         title: state.title,
         meta_description: state.meta_description,
       }
     }
     updateSEOApi(obj)
  }
 else if(tag==="SEGMENT"){
  const obj={
    slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~c",
    meta_data:{
       page_heading:state.page_heading,
       title: state.title,
       meta_description: state.meta_description,
     }
   }
   updateSEOApi(obj)
}
 else if(tag==="CATEGORY"){
  const obj={
    slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc',
    meta_data:{
      page_heading:state.page_heading,
       title: state.title,
       meta_description: state.meta_description,
     }
   }
   updateSEOApi(obj)
}
 else if(tag==="SUBCATEGORY"){
  const obj={
    slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc',
    meta_data:{
      page_heading:state.page_heading,
       title: state.title,
       meta_description: state.meta_description,
     }
  }
  updateSEOApi(obj)
}
else if(tag==="VEHICLE"){
  const obj={
    slug:(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase(),
    meta_data:{
     page_heading:state.page_heading,
      title: state.title,
      meta_description: state.meta_description,
    }
  }
  updateSEOApi(obj)
}
}
}
function updateSubHeadingApi(obj){
  API.updateSubHeadingContent(obj).then(function (response) {  
    if (response.data.status) {
    // to get updated finalContent in props
    getFinal() 
      NotificationManager.success(
    'Successfully',
    'SubHeading updated ',
    
  )}
})
  .catch(function () {
    NotificationManager.error('SubHeading is already created', );
  })
  .finally(function () {
  });
}
function updateSubHeading(){
    const obj={
      name:state.filter_key,
      type:tag,
      sub_heading:sub_heading
     }
     updateSubHeadingApi(obj)

}
 function deleteSEOApi(obj){
  API.deleteContent(obj).then(function (response) {
    if (response.data.status) {
      NotificationManager.success(
    'Deleted',
    'Successfully',
    
  )} 
  
  setState((prev)=>({...prev, view: "hidden" }));
  setState((prev) => ({ ...prev, filter_key: ''})); 
  tag="BRAND" && getBrandsList();
  tag="SUBCATEGORY" && getSubCategoriesList();
  tag="CATEGORY" && getCategoriesList()
  tag="SEGMENT" && getSegmentList()
  tag="VEHICLE" && getCarBrands();
  })
  .catch(function () {
  })
  .finally(function () {
  });
}
 function deleteSEO(){

  if(tag==="STORE"){
    const obj={
      slug:("store-"+state.store_code).toLowerCase(),
    }
    deleteSEOApi(obj)
    setState({...state,store_code:""})
  }
  else{
    if(tag==="BRAND"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~s",
    }
    deleteSEOApi(obj)
  }
  else if(tag==="SEGMENT"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~c",
    }
    deleteSEOApi(obj)
  }
  else if(tag==="CATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~sc",
    }
    deleteSEOApi(obj)
  }
  else if(tag==="HOMEPAGE"){
    const obj={
      slug:"spares",
    }
    deleteSEOApi(obj)
  }
  else if(tag==="SUBCATEGORY"){
    const obj={
      slug:state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+"~ssc",
    }
    deleteSEOApi(obj)
  }
  else if(tag==="VEHICLE"){
    const obj={
      slug:(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase(),
    }
    deleteSEOApi(obj)

  }
}
}
  function handleInnerTabClick(obj) {
    setState((prev) => ({ ...prev, isActive: !obj.active }));
    setState((prev) => ({ ...prev, selectedTag: obj.tag }));
    obj.tag!=="home_related"?showRows(false):showRows(true)
  }
 

  const FAQS = (props ) => {
    function faqOnChange(e,index){
      let faq=[...props.faq]
        if(e.target.name==="order"){
      faq[index]={...props.itemFaq,[e.target.name]:e.target.value==0?"":Number(e.target.value)}
        
        }
        else{
      faq[index]={...props.itemFaq,[e.target.name]:e.target.value}
    }
      props.setFaq(faq)
    }
  
  return (
    <React.Fragment key={props.key}>
      <div className="faqs" style={{marginTop:"1rem"}}>
     <div>
     <div className="position_relative">
         <p className="numbering-padding">({props.index+1})</p>
         </div>
       <label for="question" className="faq-label">
         Question:
       </label>
       <br />
       <textarea
         type="text"
         className="faq-input"
         name="question"
         autoComplete="off"
         autoCapitalize="on"
         style={{minHeight:'5rem'}}
         value={props.itemFaq.question}
         onChange={(e)=>faqOnChange(e,props.index)}
       />
       <br />
       <label for="answer" className="faq-label">
         Answer:
       </label>
       <br />
       <textarea
         type="text"
         name="answer"
         className="faq-input"
         autoComplete="off"
         style={{minHeight:'5rem'}}
         value={props.itemFaq.answer}
         onChange={(e)=>faqOnChange(e,props.index)}
         autoCapitalize="on"
 
       />
          <br />
       <label for="order" className="faq-label">
         Order:
       </label>
       <br />
       <input
         type="number"
         name="order"
         className="faq-input"
         autoComplete="off"
         min={1}
         value={props.itemFaq.order}
         onKeyPress={event => {let e = event || window.event;
    let key = e.keyCode || e.which;
    if (!(key >= 49 && key <= 57)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }}}
         onChange={(event)=>{faqOnChange(event,props.index)
          let e = event || window.event;
    let key = e.keyCode || e.which;
    if (!(key >= 49 && key <= 57)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }}}
         autoCapitalize="on"
 
       />
     </div>

     {/* <div className="btn-div" style={{marginRight:"3rem"}}>
     <button
        id="deletebtn"
       className="btn btn_small btncolorprim btncard move" onClick={()=>onDeleteFaq(props.index)}>
       Delete
     </button>
     </div> */}
   </div>
  </React.Fragment>

  );
};
const onDeleteFaq = (index) => {
  NotificationManager.success(
    'Deleted',
    
  )
  setFaq(faq.filter((item)=>faq.indexOf(item)!==index));
};

  function SEO(){

    return(    
    <div>
     <br/>
          <label for="" className="faq-label" style={{fontSize:"1rem"}}>
            Page Heading:
          </label>
          <br />
          <textarea
            type="text"
            className="faq-input"
            name="page_heading"
            autoComplete="off"
            autoCapitalize="on"
            placeholder="Write Page Heading here..."
            value={state.page_heading?state.page_heading:''}
            style={{minHeight:'6rem'}}
            onChange={(e)=> setState({...state,page_heading:e.target.value})}

          />
          <br />
          <br />

        <br/>
          <label for="" className="faq-label" style={{fontSize:"1rem"}}>
            Title:
          </label>
          <br />
          <textarea
            type="text"
            className="faq-input"
            name="title"
            autoComplete="off"
            autoCapitalize="on"
            placeholder="Write title here..."
            value={state.title?state.title:''}
            style={{minHeight:'6rem'}}
            onChange={(e)=> setState({...state,title:e.target.value})}
            onKeyPress={(e) => {
                    try {
                      var startPos = e.currentTarget.selectionStart;
                      if (e.which === 32 && startPos == 0) {
                        e.preventDefault();
                      }
                    } catch (err) { console.log(err); }
                  }}


          />
          <br />
          <br />

          <label for="" className="faq-label" style={{fontSize:"1rem"}}>
            Description:
          </label>
          <br />
          <textarea
            type="text"
            name="description"
            className="faq-input"
            autoComplete="off"
            placeholder="Write description here..."
            value={state.meta_description?state.meta_description:''}
            autoCapitalize="on"
            style={{minHeight:'10rem'}}
            onChange={(e)=> setState({...state,meta_description:e.target.value})}
            onKeyPress={(e) => {
                    try {
                      var startPos = e.currentTarget.selectionStart;
                      if (e.which === 32 && startPos == 0) {
                        e.preventDefault();
                      }
                    } catch (err) { console.log(err); }
                  }}



          />
        </div>)
  }

  function BOTTOM()  {
 return ( 
   <>
   <div style={{marginTop: "1rem",}}>
   <div >
   <label for=""  style={{fontWeight:600,color: "#e73c33",}}>
     Heading:
   </label>
   <br />
   <textarea
   style={{minHeight:"10rem"}}
     type="text"
     name="heading"
     autoComplete="off"
     placeholder="Write heading here..."
     value={state.bottom_content && state.bottom_content.heading}
     rows="6"
     onChange={(e)=> {
       setState({...state,bottom_content:{...state.bottom_content,heading: e.target.value}})}}
   />  
   </div>
   <div style={{marginTop: "1rem",}}>
   <label for=""  style={{fontWeight:600,color: "#e73c33",}}>
     Sub-Description:
   </label>
                 <Editor
                 apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                 value={state.bottom_content && state.bottom_content.sub_desc}
                 init={{
                   height: 500,
                   menubar: false,
                   plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   toolbar:
                     'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | help'
                     
                 }}
                 onEditorChange={(e) => handleTinyMCEChange("sub_desc", e,)}
               />
           </div>
           <div style={{marginTop: "1rem",}}>
   <label for=""  style={{fontWeight:600,color: "#e73c33",}}>
     Description:
   </label>
               <Editor
                 apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                 value={state.bottom_content && state.bottom_content.desc}
                 init={{
                   height: 500,
                   menubar: false,
                   plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   toolbar:
                     'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | help'
                     
                 }}
                 onEditorChange={(e) => handleTinyMCEChange("desc", e)}
               />
           </div>
           </div>
          </>
)
  }
  function funcFaq() {
    return (
      <div className="faqs">
      	        
                     <React.Fragment>
                       <button
													className='btn btn_small btncolorprim btncard move'
										      // className='btn btnwhite btncard move'
                          style={{top:"-10px",left:'20px'}}
													onClick={()=>{state.viewTemplate===false?setState((prev) => ({ ...prev, viewTemplate: true,})):
                          setState((prev) => ({ ...prev, viewTemplate: false,}))
                          }}
												>
											{	state.viewTemplate===false?	"Add New FAQ":"Close"}
												</button>
                        {state.viewTemplate? 
                        <>
                        <div>
          <label for="" className="faq-label">
            Question:
          </label>
          <br />
          <textarea
            type="text"
            className="faq-input"
            name="question"
            autoComplete="off"
            autoCapitalize="on"
            placeholder="Write question here..."
            value={staticFaq?staticFaq.question:''}
            style={{minHeight:'5rem'}}
            onChange={(e)=>{onInputChange(e)}}
          />
          <br />
          <label for="answer" className="faq-label">
            Answer:
          </label>
          <br />
          <textarea
            type="text"
            name="answer"
            className="faq-input"
            autoComplete="off"
            placeholder="Write answer here..."
            value={staticFaq?staticFaq.answer:''}
            autoCapitalize="on"
            style={{minHeight:'5rem'}}
            onChange={(e)=>{onInputChange(e)}}

          />
           <br />
          <label for="answer" className="faq-label">
            Order:
          </label>
          <br />
          <input
            type="number"
            name="order"
            className="faq-input"
            autoComplete="off"
            placeholder="Write order here..."
            min={1}
            value={staticFaq?staticFaq.order:1}
            onKeyPress={event => {let e = event || window.event;
            let key = e.keyCode || e.which;
            if (!(key >= 49 && key <= 57)) {
              e.returnValue = false;
              if (e.preventDefault) e.preventDefault();
            }}}
            
            onChange={(e)=>{onInputChange(e)}}

          />
          <div className="btn-div" style={{marginRight:"3rem"}}>
           <button
            id="filterbtn"
            className="btn btn_small btncolorprim btncard move"
            onClick={(e) => {
            setFaq([...(faq || []), staticFaq]);
            setStaticFaq(initialFaqState);
            setState((prev) => ({ ...prev, viewTemplate: false,}));
        }}
      >
        save
      </button>
      </div>
      </div>
   
      </>:
      <React.Fragment>
    
      {faq?faq.map((item, index) => (
        FAQS({itemFaq:item,faq:faq,setFaq:setFaq,index:index,handleDelete:(e)=>onDeleteFaq(e),key:index})
      )):<></>}
      </React.Fragment>
    }

      </React.Fragment>
      </div>
    );
  }
  // function handleAddClick() {
	// 	onAddClick(tag);
	// }
  function home() {
    return (
      <>
   {showLoader?
    <div id='dashboardprimeextra' className='bgwhite centeralign'>
          <Loader showLoader={showLoader} />
        </div>:
     <>
        <div className="attr-container">{topNav()}</div>
        {state.isActive && state.selectedTag === "home_related" ? (
          <>
												<button
													className='btn btn_small btncolorprim btncard move'
                          style={{top:"45px",left:'5px'}}
													// onClick={()=>handleAddClick()}
												>
													Add New Related Blog
												</button>
						<div
							className='middle border-box clearfix leftalign'
							style={{ marginTop: '5px',display:"flex",justifyContent:"space-around" }}
						>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Heading</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes25' style={{ textAlign: 'center',}}>
								<table className='stdtable'>
									<tr>
										<td valign='center' >
											Image
										</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes25' style={{marginLeft: "-4.5rem",}}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes40'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>URL</td>
									</tr>
								</table>
							</div>
							{/* <div
								className='border-box blackspan65 weight700 dpm1boxes20'
								style={{ marginLeft: '45px' }}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>End date</td>
									</tr>
								</table>
							</div> */}
						</div>
          </>

        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "spares" ?
         (  
          <div>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
             {!realFaq || (realFaq&& realFaq.length<1) ?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  Home FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "home_about" ? (
          <div className="adjusting-div">
            <div>
            <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  // onClick={()=>{updateBTM()}}

                >
                  Update
                </button>
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  // onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  // onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
              <label for="story">About GoMechanic Spares</label>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={''}
                        value={state.about}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onEditorChange={(e) => handleTinyMCEChange("about", e)}
                      />
            </div>
          </div>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "home_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33" placeholder="Give review here...">
                
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "Near me Pages" ? (
          <div className="adjusting-div">
            <div>Near me Pages</div>
          </div>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag==="Home_BtmContent"?
          <>
        <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
            { realBtm?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}
                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">HomePage Bottom Content</div>
                    {BOTTOM()}
                  </div>
                  

                </div>
        </>
        :<></> }
        {state.isActive && state.selectedTag === "home_seo" ? (
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                    { realSeo && (realSeo.title  || realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}
                >
                  Delete
                </button> */}
                </div>
          <div className="faqs">
        {SEO()}
      </div>
      </div>
      </div>

        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "home_headings" ?
        <HomepageHeadings homepageHeadings={homepageHeadings} getHomepageHeadings={getHomepageHeadings}/>:<></>
        }
        </>
    }
    {state.isActive && state.selectedTag === "home_headings" ?
        <HomepageHeadings homepageHeadings={homepageHeadings} getHomepageHeadings={getHomepageHeadings}/>:<></>
        }
      </>
    );
  }

  function product() {
    return (
      <>
        <div className="product-container" >
          {productTop &&
            productTop.map((obj, i) => {
              return (
                <>
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </>
              );
            })}
        </div>
        {state.isActive && state.selectedTag === "product-faq" ? (
          <div>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                {!realFaq || (realFaq&& realFaq.length<1)?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  Product FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "product_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea
                id="story"
                name="story"
                rows="30"
                cols="33"
                placeholder="Write review here..."
              ></textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
  function getBrandsList() {
    API.getSparesBrandsList()
      .then((result) => {
        setState((prev) => ({ ...prev, brandsList: result.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function brand() {
    return (
      <>
        <div
          className="blackspan65 weight700 floatleft"
          style={{ height: "auto", overflow: "visible" }}
        >
          <table className="stdtable" style={{ height: "auto" }} >
            <tr>
              <td valign="center">
                  <MultiSelect
                    options={state.brandsList}
                    labelKey="brand_name"
                    checkbox_id="brand_name"
                    placeholder="Brands"
                    disabledItems={[]}
                    selectedOptions={state.selectedBrand}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,filter_key: e.brand_name,
                        selectedBrand: e,
                      }));
                    }}
                  />
              </td>
              <button
                id="filterbtn"
                className="btn btn_small btncolorprim btncard move"
                onClick={()=>{ 
                setRealBtm (finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0].bottom_content:{})
                setRealFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~s')[0] &&finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~s')[0].faq:[])
                setRealSeo(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0] &&finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0].meta_data:{})
                setFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~s')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~s')[0].faq:[])
                setState({...state, 
                bottom_content:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0]).bottom_content||{}:{},
                page_heading:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0].meta_data).page_heading|| '':'',
                title:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0] &&finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0].meta_data).title||'':'',
                meta_description:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~s')[0].meta_data).meta_description||'':''
                ,view: "visible"
                })
                }}
              
                >
                  Filter
              </button>
            </tr>
          </table>
        </div>
      </>
    );
  }

  function brandTab() {
    return (
      <>
        <div className="attr-container" >{brandTop &&
          brandTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}</div>
          {state.isActive && state.selectedTag==="brand_faq"?
          <>
        <div className="product-container">
          <div className="border-box content_tab">{brand()}</div>
        </div>
        
        <div style={{visibility:state.view}}>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {!realFaq ||(  realFaq && realFaq.length<1)?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  {state.page_heading} FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        </>
        :<></> }
        {state.isActive && state.selectedTag === "brand_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33">
                Give review here...
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
          {state.isActive && state.selectedTag==="brand_BtmContent"?
          <>
         
          <div className="product-container">
          <div className="border-box content_tab">{brand()}</div>
        </div>
        <div style={{visibility:state.view}}>
        <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
         {( realBtm && realBtm.sub_desc) ||  (realBtm && realBtm.desc)?       
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">{state.page_heading} Bottom Content</div>
                    {BOTTOM()}
                  </div>
                  

                </div>
        </div>
            
        </>
        :<></> }
        {state.isActive && state.selectedTag==="brand_seo"?
        (
          <>
          <div className="product-container">
          <div className="border-box content_tab"> {brand()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                { (realSeo && realSeo.title)  || (realSeo && realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                {state.page_heading} SEO
                </div>  
          <div className="faqs">
        {SEO()}
      </div>
      </div>
      </div>
      </div>
      </>
        )
        :<></> }
       
      </>
    );
  }


  function segment() {
    return (
      <>
        <div
          className="upload-row border-bottom"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            border: "none",
          }}
        >
          <div
            className="blackspan65 weight700 floatleft"
            style={{ height: "auto", overflow: "visible" }}
          >
            <table className="stdtable" style={{ height: "auto" }}>
              <tr>
                <td valign="center">
                  <MultiSelect
                    options={state.segmentsList ? state.segmentsList : []}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Segment"
                    disabledItems={[]}
                    selectedOptions={{
                      name: state.selected_segment,
                    }}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,filter_key: e.name,
                        selected_segment: e.name,
                      }));
                    }}
                  />
                </td>
                <button
                id="filterbtn"
                className="btn btn_small btncolorprim btncard move"
                onClick={()=>{ 
                 API.getSubHeadingContent(state.filter_key,tag).then((res)=>{
                  setRealSub_heading(res.data.data.about)
                  setSub_heading(res.data.data.about)
                 }).catch(function (error) {
                  // handle error
                  // console.log(error)
                })
                .finally(function () {
                  // always executed
                });
                setRealSeo(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0] &&finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0].meta_data:{})
                setRealBtm (finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0].bottom_content:{})
                setRealFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~c')[0].faq:[])
                setFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~c')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase()+'~c')[0].faq:[])
                setState({...state,
                page_heading:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0].meta_data).page_heading||'':'',
                title:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0].meta_data).title||'':'',
                bottom_content: finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0]).bottom_content||{}:{},
                meta_description:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase() + '~c')[0].meta_data).meta_description||'':''
                ,view: "visible"
                }) }}
                >
                  Filter
              </button>
              </tr>
            </table>
          </div>
        </div>
      </>
    );
  }
  function getSegmentList() {
    API.getSparesSegments() 
      .then((result) => {
        setState((prev) => ({ ...prev, segmentsList: result.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function segmentTab() {
    return (
      <>
       <div className="attr-container" >{segmentTop &&
          segmentTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}</div>
          {(state.isActive && state.selectedTag) === "segment-faq" ?
        <>
        <div className="product-container">
          <div className="border-box content_tab"> {segment()}</div>
        </div>
        <div style={{visibility:state.view}}>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {!realFaq || (realFaq && realFaq.length<1)?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  {state.page_heading} FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        </>:<></>}
        {state.isActive && state.selectedTag==="segment_subHeading"?
        (<>
          <div className="product-container">
          <div className="border-box content_tab"> {segment()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {realSub_heading?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSubHeading()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSubHeading()}}
                  
                >
                  Save 
                </button>
                }
                
              </div> 
                </div>
                <div className="filter_key">
                {state.page_heading} SubHeading
                </div>  
          <div className="faqs">
          <label for="" className="faq-label" style={{fontSize:"1rem"}}>
            SubHeading:
          </label>
          <br />
          <textarea
            type="text"
            className="faq-input"
            name="sub_heading"
            autoComplete="off"
            autoCapitalize="on"
            placeholder="Write SubHeading here..."
             value={sub_heading?sub_heading:''}
            style={{minHeight:'6rem'}}
            onChange={(e)=> setSub_heading(e.target.value)}
            onKeyPress={(e) => {
                    try {
                      var startPos = e.currentTarget.selectionStart;
                      if (e.which === 32 && startPos == 0) {
                        e.preventDefault();
                      }
                    } catch (err) { console.log(err); }
                  }}
          />
      </div>
      </div>
      </div>
      </div>
      </>
        )
        :<></> }
          {state.isActive && state.selectedTag === "segment_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33">
                Give review here...
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
    

        {state.isActive && state.selectedTag==="segment_BtmContent"?
          <>
          <div className="product-container">
          <div className="border-box content_tab"> {segment()}</div>
        </div>
          <div style={{visibility:state.view}}>
        <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
              { (realBtm && realBtm.sub_desc)  ||(realBtm && realBtm.desc)?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">{state.page_heading} Bottom Content</div>
                    {BOTTOM()}
                  </div>
                  

                </div>
        </div>
        </>
        :<></> }
        {state.isActive && state.selectedTag==="segment_seo"?
        (<>
          <div className="product-container">
          <div className="border-box content_tab"> {segment()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 { (realSeo && realSeo.title)  || (realSeo && realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                {state.page_heading} SEO
                </div>  
          <div className="faqs">
          {SEO()}
      </div>
      </div>
      </div>
      </div>
      </>
        )
        :<></> }
       
      </>
    );
  }
  function getCategoriesList() {
    API.getSparesCategoryList()
      .then((result) => {
        setState((prev) => ({ ...prev, categoriesList: result.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function category() {
    return (
      <>
        <div
          className="upload-row border-bottom"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            margin: "0",
            border: "none",
          }}
        >
          <div
            className="blackspan65 weight700 floatleft"
            style={{ height: "auto", overflow: "visible" }}
          >
            <table className="stdtable" style={{ height: "auto" }}>
              <tr>
                <td valign="center">
                  <MultiSelect
                    options={_.get(state, ["categoriesList"], [])}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Category"
                    disabledItems={[]}
                    selectedOptions={{
                      name: state.selected_category,
                    }}
                    onChange={(value) => {
                      setState((prev) => ({
                        ...prev, filter_key: value.name,
                        selected_category: value.name,
                      }));
                    }}
                  />
                </td>
                <button
                id="filterbtn"
                className="btn btn_small btncolorprim btncard move"
                onClick={()=>{ 
                  API.getSubHeadingContent(state.filter_key,tag).then((res)=>{
                  setRealSub_heading(res.data.data.about)
                  setSub_heading(res.data.data.about)
                 }).catch(function (error) {
                  // handle error
                  // console.log(error)
                })
                .finally(function () {
                  // always executed
                });
                setRealSeo(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].meta_data:{})
                setRealBtm (finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].bottom_content:{})
                setRealFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].faq:[])
                
                setFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].faq:[])
                setState({...state,
                page_heading:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].meta_data).page_heading||'':'',
                title:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].meta_data).title||'':'',
                bottom_content:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0]).bottom_content||{}:{},
                meta_description:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~sc')[0].meta_data).meta_description||'':'',
                view: "visible"})
                }} 
                >
                  Filter
              </button>
              </tr>
            </table>
          </div>
        </div>
      </>
    );
  }
  function categoryTab() {
    return (
      <>
        <div className="attr-container" >{catTop &&
          catTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}</div>
          {(state.isActive && state.selectedTag)==="category_faq"?
          <>
        {category()}
        <div style={{visibility:state.view}}>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {!realFaq || (realFaq && realFaq.length)<1?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  {state.page_heading} FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        </>:<></>}
        {state.isActive && state.selectedTag === "category_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33" placeholder="Give review here...">
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag==="category_subHeading"?
        (<>
          <div className="product-container">
          <div className="border-box content_tab"> {category()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {realSub_heading?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSubHeading()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSubHeading()}}
                  
                >
                  Save 
                </button>
                }
                
              </div> 
                </div>
                <div className="filter_key">
                {state.page_heading} SubHeading
                </div>  
          <div className="faqs">
          <label for="" className="faq-label" style={{fontSize:"1rem"}}>
            SubHeading:
          </label>
          <br />
          <textarea
            type="text"
            className="faq-input"
            name="sub_heading"
            autoComplete="off"
            autoCapitalize="on"
            placeholder="Write SubHeading here..."
             value={sub_heading?sub_heading:''}
            style={{minHeight:'6rem'}}
            onChange={(e)=> setSub_heading(e.target.value)}
            onKeyPress={(e) => {
                    try {
                      var startPos = e.currentTarget.selectionStart;
                      if (e.which === 32 && startPos == 0) {
                        e.preventDefault();
                      }
                    } catch (err) { console.log(err); }
                  }}
          />
      </div>
      </div>
      </div>
      </div>
      </>
        )
        :<></> }
        {state.isActive && state.selectedTag==="category_BtmContent"?
          <>
          {category()}
          <div style={{visibility:state.view}}>
        <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
              { (realBtm  && realBtm.sub_desc)  || (realBtm && realBtm.desc)?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">{state.page_heading} Bottom Content</div>
                    {BOTTOM()}
                  </div>
                  

                </div>
        </div>
        </>
        :<></> }
        {state.isActive && state.selectedTag==="category_seo"?
        (<>
          <div className="product-container">
          <div className="border-box content_tab"> {category()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                {  (realSeo && realSeo.title ) || (realSeo && realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}           
                  >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                {state.page_heading} SEO
                </div>  
          <div className="faqs">
          {SEO()}
      </div>
      </div>
      </div>
      </div></>
        )
        :<></> }

      </>
    );
  }

  function getSubCategoriesList() {
    API.getSparesSubCategory()
      .then((result) => {
        setState((prev) => ({ ...prev, subCategoryList: result.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function subCategory() {
    return (
      <>
        <div
          className="upload-row border-bottom"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            margin: "0",
            border: "none",
          }}
        >
          <div
            className="blackspan65 weight700 floatleft"
            style={{ height: "auto", overflow: "visible" }}
          >
            <table className="stdtable" style={{ height: "auto" }}>
              <tr>
                <td valign="center">
                  <MultiSelect
                    options={_.get(state, ["subCategoryList"], [])}
                    labelKey="name"
                    checkbox_id="name"
                    placeholder="Sub-category"
                    disabledItems={[]}
                    selectedOptions={{
                      name: state.selected_sub_category,
                    }}
                    onChange={(value) => {
                      setState((prev) => ({
                        ...prev, filter_key: value.name,
                        selected_sub_category: value.name,
                      }));
                    }}
                  />
                </td>
                <button
                id="filterbtn"
                className="btn btn_small btncolorprim btncard move"
                onClick={()=>{ 
                  API.getSubHeadingContent(state.filter_key,tag).then((res)=>{
                  setRealSub_heading(res.data.data.about)
                  setSub_heading(res.data.data.about)
                 }).catch(function (error) {
                  // handle error
                  // console.log(error)
                })
                .finally(function () {
                  // always executed
                });
                setRealSeo(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].meta_data:{})
                setRealBtm (finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].bottom_content:{})
                setRealFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].faq:[])
                
                setFaq(finalContent?finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].faq:[])
                setState({...state,
                page_heading:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].meta_data).page_heading||'':'',
                title:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].meta_data).title||'':'',
                bottom_content:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0]&& finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0]).bottom_content||{}:{},
                meta_description:finalContent?(finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0] && finalContent.filter(item=>item.slug===state.filter_key.replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').replace('/', '-').toLowerCase() + '~ssc')[0].meta_data).meta_description||'':'',
                view: "visible"})
                }}
                >
                  Filter
              </button>
              </tr>
            </table>
          </div>
        </div>
      </>
    );
  }
  function subCategoryTab() {
    return (
      <>
        <div className="attr-container" >{subCatTop &&
          subCatTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}</div>
          {state.isActive && state.selectedTag==="subCategory_faq"?
          <>
        {subCategory()}
        <div style={{visibility:state.view}}>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {!realFaq ||( realFaq && realFaq.length<1)?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  {state.page_heading} FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        </>:<></>}
        {state.isActive && state.selectedTag === "subCategory_review" ? (
          <>
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33">
                Give review here...
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag==="subCategory_subHeading"?
        (<>
          <div className="product-container">
          <div className="border-box content_tab"> {subCategory()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {realSub_heading?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSubHeading()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSubHeading()}}
                  
                >
                  Save 
                </button>
                }
                
              </div> 
                </div>
                <div className="filter_key">
                {state.page_heading} SubHeading
                </div>  
          <div className="faqs">
          <label for="" className="faq-label" style={{fontSize:"1rem"}}>
            SubHeading:
          </label>
          <br />
          <textarea
            type="text"
            className="faq-input"
            name="sub_heading"
            autoComplete="off"
            autoCapitalize="on"
            placeholder="Write SubHeading here..."
            value={sub_heading?sub_heading:''}
            style={{minHeight:'6rem'}}
            onChange={(e)=> setSub_heading(e.target.value)}
            onKeyPress={(e) => {
                    try {
                      var startPos = e.currentTarget.selectionStart;
                      if (e.which === 32 && startPos == 0) {
                        e.preventDefault();
                      }
                    } catch (err) { console.log(err); }
                  }}
          />
      </div>
      </div>
      </div>
      </div>
      </>
        )
        :<></> }
        {state.isActive && state.selectedTag==="subCategory_BtmContent"?
          <>
          {subCategory()}
          <div style={{visibility:state.view}}>
        <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                { (realBtm && realBtm.sub_desc)  ||( realBtm && realBtm.desc)?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">{state.page_heading} Bottom Content</div>
                    {BOTTOM()}
                  </div>
                  

                </div>
        </div>
         
        </>
        :<></> }
        {state.isActive && state.selectedTag==="subCategory_seo"?
        (
          <>
          <div className="product-container">
          <div className="border-box content_tab"> {subCategory()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                  { (realSeo && realSeo.title)  || (realSeo && realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                {state.page_heading} SEO
                </div>             
          <div className="faqs">
          {SEO()}
      </div>
      </div>
      </div>
      </div>
</>        )
        :<></> }

    </>
    );
  }
 

  function getCarBrands() {
    axios
      .get(Constants.getSparesBaseURL() + "car-brands/")
      .then((data) => {
        var brands = [];
        brands = [...data.data.data];
        setState((prev) => ({ ...prev, brands: brands }));
      })
      .catch((e) => console.log("error ", e));
  }

  function afterFilterChangeMake(field,value) {
    field = field || null;
    if (field === "brand") {
      axios
        .get(
          Constants.getSparesBaseURL() +
            "vehicles-cms/?varient=0&source=cms&make=" +
            value.name
        )
        .then((data) => {
          var models = [];
          models = [...data.data.data];
          models = _.uniqBy(models, (item) => item.model);
          setState((prev) => ({ ...prev, modelsForBrand: models }));
          console.log("go",data)
        })
        .catch((e) => console.log("error ", e));
    }
  }
function vehicle(){
  return(
    <>
       <div
              className="blackspan65 weight700 floatleft"
              style={{
                overflow: "initial",
                height: "auto",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <table className="stdtable">
                <tr style={{display:"flex"}}>
                  <td valign="center" style={{ display: "flex" }}>
                    <MultiSelect
                      options={state.brands}
                      labelKey="name"
                      checkbox_id="_id"
                      placeholder="Select Make"
                      disabledItems={[]}
                      selectedOptions={state.selectedBrandForModel}
                      onChange={(value) => {
                        setState((prev) => ({
                          ...prev,
                          selectedBrandForModel: value,
                        }));
                        setState((prev) => ({
                        ...prev,
                        model: value.name ,
                      }));
                        afterFilterChangeMake("brand",value);
                      }}
                    />
                    <MultiSelect
                      options={state.modelsForBrand}
                      labelKey="model"
                      checkbox_id="_id"
                      placeholder="Select Model"
                      disabledItems={[]}
                      selectedOptions={state.selectedModel}
                      onChange={(value) => {
                        setState((prev) => ({ ...prev, selectedModel: value }));
                        setState((prev) => ({
                        ...prev,
                        filter_key: value.model,
                      }));
                      }}
                    />
                  </td>
                  <div>
                  <button
                id="filterbtn"
                className="btn btn_small btncolorprim btncard move"
                onClick={()=>{ 
                setRealSeo(finalContent?finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0] && finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].meta_data:{})
                setRealBtm (finalContent?finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0]&& finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].bottom_content:{})
                setRealFaq(finalContent?finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0] && finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].faq:[])
                
                setFaq(finalContent?finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0] && finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].faq:[])
                setState({...state,
                bottom_content:finalContent?finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0]&& finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].bottom_content:{},
                page_heading:finalContent?(finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0] && finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].meta_data).page_heading||'':'', 
                title:finalContent?(finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0] && finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].meta_data).title||'':'',
                meta_description:finalContent?(finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0] && finalContent.filter(item=>item.slug===(state.model +'~b-' + state.filter_key + '~m').replace('&','').replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase())[0].meta_data).meta_description||'':'',
                 view: "visible"})
                }}
                >
                  Filter
              </button>
              </div>
                </tr>
              </table>
            </div>
    </>
  )
}

  function vehicleTab() {
    return (
      <div>
        <div className="attr-container" >{vehicleTop &&
          vehicleTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}</div>
          {state.isActive && state.selectedTag==='vehicle_faq'?
          <>
        <div className="product-container">
          <div className="border-box content_tab">
              {vehicle()}
          </div>
        </div>
        <div>
        <div style={{visibility:state.view}}>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {!realFaq || (realFaq && realFaq.length<1)?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                {state.page_heading} FAQs
                </div>             
                {funcFaq()}
        
            </div>
          </div>
        </div> 
        </div> 
        </>:<></>}
        {state.isActive && state.selectedTag === "vehicle_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33">
                Give review here...
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag==="vehicle_BtmContent"?
          <>
          <div className="product-container">
          <div className="border-box content_tab">
              {vehicle()}
          </div>
        </div>
          <div style={{visibility:state.view}}>
        <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
             {  (realBtm && realBtm.sub_desc)  || (realBtm &&  realBtm.desc)?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">{state.page_heading} Bottom Content</div>
                  {BOTTOM()}
                  </div>
                  

                </div>
        </div>
        </>
        :<></> }
        {state.isActive && state.selectedTag==="vehicle_seo"?
        (
          <>
          <div className="product-container">
          <div className="border-box content_tab"> {vehicle()} </div>
        </div>
          <div style={{visibility:state.view}}>
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                  { (realSeo && realSeo.title)  ||(realSeo && realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                {state.page_heading} SEO
                </div>             
          <div className="faqs">
          {SEO()}
      </div>
      </div>
      </div>
      </div>
      </>
        )
        :<></> }
        </div>
      
    );
  }
  function store(){
    return(
      <>
        <div className="attr-container" >{storeTop &&
          storeTop.map((obj, i) => {
            return (
              <>
                <div className="attr-tab-parent">
                  <div
                    className={`attr-tab ${
                      state.selectedTag === obj.tag 
                        ? "active-attr-tab"
                        : ""
                    }`}
                    onClick={() => {
                      handleInnerTabClick(obj, i);
                    }}
                  >
                    <p className="attr-name">{obj.title}</p>
                  </div>
                </div>
              </>
            );
          })}</div>
        {state.isActive && state.selectedTag === "store-faq" ?
         (  
          <div>
        <div
            className="adjusting-div faq_styled"
          >
            <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                 {!realFaq || (realFaq&& realFaq.length<1)?  
             <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveFaqs()}}
                >
                  Save 
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateFaqs()}}

                >
                  Update
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteFaq()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key">
                  Store FAQs
                </div>
          <div style={{width:"50%",display:"flex",alignItems:"center", marginBottom:"1rem"}}>
          <div>
          <label for="" className="faq-label">
            Code:
          </label>
          <br />
          <input
            type="text"
            className="faq-input"
            name="code"
            autoComplete="off"
            placeholder="Enter Code here..."
            value={state.store_code}
            onChange={(e)=> setState({...state,store_code:e.target.value})}
          />  
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "1rem",}}>
          <button
           id="deletebtn"
           className="btn btn_small btncolorprim btncard move"
          onClick={()=>{
            setRealFaq(finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].faq:[]);
            setRealBtm (finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].bottom_content:{})
            setRealSeo(finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].meta_data:{});
            setFaq(finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].faq:[])}}>
            Fetch FAQs
          </button>
          </div>
          </div>           
                {funcFaq()}
            </div>
          </div>
        </div> 
    
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag==="store_BtmContent"?
          <>
          <div className="border-box clearfix fields" style={{margin: "2rem",}}>
                  <div className="border-box clearfix field1">
                  <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
               { (realBtm && realBtm.sub_desc)  || (realBtm && realBtm.desc)?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateBTM()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveBTM()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{deleteBTM()}}
                >
                  Delete
                </button> */}
                </div>
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Bottom Content</div>
                    <div style={{marginTop: "1rem",}}>
                    <div style={{display:"flex",alignItems:"center"}}>
          <div>
          <label for="" style={{fontWeight:600,color: "#e73c33",}}>
            Code:
          </label>
          <br />
          <input
            type="text"
            className=""
            name="code"
            autoComplete="off"
            placeholder="Enter Code here..."
            value={state.store_code}
            style={{fontSize:"16px",marginLeft:"2px"}}
            onChange={(e)=> setState({...state,store_code:e.target.value})}
          />  
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "1rem",}}>
          <button
           id="deletebtn"
           className="btn btn_small btncolorprim btncard move"
          onClick={()=>{setState({...state,
          bottom_content:finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].bottom_content:{}});
          setRealBtm (finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].bottom_content:{})
          setRealSeo(finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].meta_data:{});

          }}
          >
            Fetch 
          </button>
          </div>
          </div>
          <div >
          <label for=""  style={{fontWeight:600,color: "#e73c33",}}>
            Heading:
          </label>
          <br />
          <textarea
          style={{minHeight:"10rem"}}
            type="text"
            name="heading"
            autoComplete="off"
            placeholder="Write heading here..."
            value={state.bottom_content && state.bottom_content.heading}
            rows="6"
            onChange={(e)=>  setState({...state,bottom_content:{...state.bottom_content,heading: e.target.value}})}
          />  
          </div>
          <div style={{marginTop: "1rem",}}>
          <label for=""  style={{fontWeight:600,color: "#e73c33",}}>
            Sub-Description:
          </label>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        // initialValue={state.bottom_content?state.bottom_content.sub_desc:''}
                        value={state.bottom_content && state.bottom_content.sub_desc}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onEditorChange={(e) => handleTinyMCEChange("sub_desc", e)}
                      />
                  </div>
                  <div style={{marginTop: "1rem",}}>
          <label for=""  style={{fontWeight:600,color: "#e73c33",}}>
            Description:
          </label>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        // initialValue={state.bottom_content?state.bottom_content.desc:''}
                        value={state.bottom_content && state.bottom_content.desc}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onEditorChange={(e) => handleTinyMCEChange("desc", e)}
                      />
                  </div>
                  </div>
                  </div>
                  

                </div>

        </>
        :<></> }
        {state.isActive && state.selectedTag === "store_review" ? (
          <div className="adjusting-div">
            <div>
              <label for="story">Review</label>
              <textarea id="story" name="story" rows="30" cols="33">
                Give review here...
              </textarea>
              <div className="btn-div">
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag === "Near me Pages" ? (
          <div className="adjusting-div">
            <div>Near me Pages</div>
          </div>  
        ) : (
          <></>
        )}
        {state.isActive && state.selectedTag==="store_seo"?
        (
          <div className="adjusting-div faq_styled">
          <div className="formm">
                 <div className="btn-div" style={{marginRight: "2rem",paddingTop: ".2rem",}}>  
                 <div
                style={{marginRight:".5rem"}}
              >
                  { (realSeo && realSeo.title  )|| (realSeo && realSeo.meta_description) ?        
          <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{updateSEO()}}

                >
                  Update
                </button>:
                <button
                  id="filterbtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>{saveSEO()}}
                  
                >
                  Save 
                </button>}
                
              </div> 
                 {/* <button
                  id="deletebtn"
                  className="btn btn_small btncolorprim btncard move"
                  onClick={()=>deleteSEO()}
                >
                  Delete
                </button> */}
                </div>
                <div className="filter_key" style={{marginLeft:".5rem"}}>
                {state.store_code} SEO
                </div>  
                <div style={{display:"flex",alignItems:"center",marginLeft:"1.5rem"}}>
          <div>
          <label for="" style={{fontWeight:600,color: "#e73c33",}}>
            Code:
          </label>
          <br />
          <input
            type="text"
            className=""
            name="code"
            autoComplete="off"
            placeholder="Enter Code here..."
            value={state.store_code}
            style={{fontSize:"16px",marginLeft:"2px"}}
            onChange={(e)=> setState({...state,store_code:e.target.value})}
          />  
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "1rem",}}>
          <button
            id="deletebtn"
            className="btn btn_small btncolorprim btncard move"
            onClick={()=>{
            setState({...state,title:finalContent?(finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].meta_data).title||'':'',
            page_heading:finalContent?(finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].meta_data).page_heading|| '':'',
            meta_description:finalContent?(finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].meta_data).meta_description|| '':''});
            setRealSeo(finalContent?finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0] && finalContent.filter(item=>item.slug===("store-"+state.store_code).toLowerCase())[0].meta_data:{});
            }}
          >
            Fetch SEO
          </button>
          </div>
          </div>           
          <div className="faqs">
        {SEO()}
      </div>
      </div>
      </div>
        )
        :<></> }
      </>
    )
  }


  return (
    <>
      {(() => {
        switch (tag) {
          case "HOMEPAGE":
            return home();
          case "PRODUCT":
            return product();
          case "BRAND":       
            return brandTab();
          case "SEGMENT":
            return segmentTab();
          case "CATEGORY":
            return categoryTab();
          case "SUBCATEGORY":
            return subCategoryTab();
          case "VEHICLE":
            return vehicleTab();
            case "STORE":
            return store();
            case "BACK":
            history.push("/spares")
            break
          default:
            return null;
        }
      })()}
    </>
  );
};

export default Middleupper;
