import React from 'react';
// import "./dashboard/dashboard.css"

import Constants from "./constants";

class Row extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleClick = this.handleClick.bind(this);
    }
  
      handleClick() {
          // console.log("clicking");
          this.props.onRowClick(this.props.data, this.props.tag);
          /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
      }
  
    render() {
        // console.log(this.props.tag);
        // console.log(Constants.orderTypes()[this.props.data.status_id]);
        switch(this.props.tag){
            case 'orders':
                var statuses = JSON.parse(localStorage.getItem("statuses"));
                var filteredstatuses = statuses.filter(o => (o.status_type == this.props.data.order_type && o.id == this.props.data.status_id));
                var status = "NA";
                if(filteredstatuses.length > 0){
                    status = filteredstatuses[0].status;
                }
                
                return(
                    <div className={this.props.selectedRow===this.props.data.order_id ? "boxes orders border-box clearfix leftalign blackspan9 selected" : "boxes orders border-box clearfix leftalign blackspan9"} onClick={this.handleClick}
                    data-order-id={this.props.data.order_id} data-order-type={this.props.data.order_type} data-status-id={this.props.data.status_id} 
                    data-pick-date={this.props.data.pick_date} data-delivery-date={this.props.data.delivery_date} 
                    data-estimated-delivery={this.props.data.estimated_delivery} data-amount={this.props.data.amount} 
                    data-fleet-type={this.props.data.fleet_type} data-fleet-name={this.props.data.fleet_name} data-fleet-id={this.props.data.fleet_id}
                    data-reg-num={this.props.data.reg_num}>
        
                    <div className="textsize12 border-box weight500 dpmboxes1"><table className="stdtable"><tr><td valign="center">&#9679;</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable colorprim500span" style={{textTransform:'uppercase'}}><tr><td valign="center">{this.props.data.order_id}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable colorprim500span"><tr><td valign="center">{Constants.orderTypes()[this.props.data.order_type]}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.reg_num}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.amount}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.fleet_type == 0 ? "Business" : "Retail"}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{status}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.pick_date}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.delivery_date}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.estimated_delivery}</td></tr></table></div>
                <div className="textsize12 border-box weight500 dpmboxes9"><table className="stdtable"><tr><td valign="center"></td></tr></table></div>
                </div>
                );
            case 'fleets':
                return(
                    <div className="boxes fleets border-box clearfix leftalign blackspan9" onClick={this.handleClick}
                    data-id={this.props.data.id} data-url={this.props.data.url} data-name={this.props.data.name} data-location_id={this.props.data.location_id} 
                    data-description={this.props.data.description} data-key_assistant_manager_name={this.props.data.key_assistant_manager_name} 
                    data-key_assistant_manager_number={this.props.data.key_assistant_manager_number} data-key_assistant_manager_email={this.props.data.key_assistant_manager_email} 
                    data-primary_contact_name={this.props.data.primary_contact_name} data-primary_contact_number={this.props.data.primary_contact_number} 
                    data-primary_contact_email={this.props.data.primary_contact_email} data-secondary_contact_name={this.props.data.secondary_contact_name} 
                    data-secondary_contact_number={this.props.data.secondary_contact_number} data-secondary_contact_email={this.props.data.secondary_contact_email} 
                    data-location={this.props.data.location} data-country={this.props.data.country} data-created_at={this.props.data.created_at} data-is_demo={this.props.data.is_demo} 
                    data-updated_at={this.props.data.updated_at} data-billing_name={this.props.data.billing_name} data-billing_title={this.props.data.billing_title} 
                    data-billing_vat={this.props.data.billing_vat} data-billing_service_tax={this.props.data.billing_service_tax} data-billing_address={this.props.data.billing_address} 
                    data-pan={this.props.data.pan} data-tin={this.props.data.tin} data-seller_address={this.props.data.seller_address} data-seller_tin={this.props.data.seller_tin} 
                    data-seller_gstin={this.props.data.seller_gstin} data-apply_avat={this.props.data.apply_avat} data-fleet_type={this.props.data.fleet_type} 
                    data-fleet_logo={this.props.data.fleet_logo} data-state_code={this.props.data.state_code} data-billing_manager={this.props.data.billing_manager} 
                    data-seller_address_id={this.props.data.seller_address_id} data-fleet_deal={this.props.data.fleet_deal} data-billing_mgr={this.props.data.billing_mgr} 
                    data-tech_mgr={this.props.data.tech_mgr} data-key_mgr={this.props.data.key_mgr}>
        
                    <div className="textsize12 border-box weight500 dpmboxes1"><table className="stdtable"><tr><td valign="center">&#9679;</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes26"><table className="stdtable colorprim500span" style={{textTransform:'uppercase'}}><tr><td valign="center">{this.props.data.id}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable colorprim500span"><tr><td valign="center">{this.props.data.name}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.fleet_type == 0 ? "Business" : "Retail"}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.location}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.primary_contact_name}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.billing_name}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes9"><table className="stdtable"><tr><td valign="center"></td></tr></table></div>
                    </div>
                );
            case 'garages':
                return(
                    <div className="boxes garages border-box clearfix leftalign blackspan9" onClick={this.handleClick}
                    data-id={this.props.data.id} data-url={this.props.data.url} data-name={this.props.data.name} data-location_id={this.props.data.location_id} 
                    data-description={this.props.data.description} data-key_assistant_manager_name={this.props.data.key_assistant_manager_name} 
                    data-key_assistant_manager_number={this.props.data.key_assistant_manager_number} data-key_assistant_manager_email={this.props.data.key_assistant_manager_email} 
                    data-primary_contact_name={this.props.data.primary_contact_name} data-primary_contact_number={this.props.data.primary_contact_number} 
                    data-primary_contact_email={this.props.data.primary_contact_email} data-secondary_contact_name={this.props.data.secondary_contact_name} 
                    data-secondary_contact_number={this.props.data.secondary_contact_number} data-secondary_contact_email={this.props.data.secondary_contact_email} 
                    data-location={this.props.data.location} data-country={this.props.data.country} data-created_at={this.props.data.created_at} data-is_demo={this.props.data.is_demo} 
                    data-updated_at={this.props.data.updated_at} data-billing_name={this.props.data.billing_name} data-billing_title={this.props.data.billing_title} 
                    data-billing_vat={this.props.data.billing_vat} data-billing_service_tax={this.props.data.billing_service_tax} data-billing_address={this.props.data.billing_address} 
                    data-pan={this.props.data.pan} data-tin={this.props.data.tin} data-seller_address={this.props.data.seller_address} data-seller_tin={this.props.data.seller_tin} 
                    data-seller_gstin={this.props.data.seller_gstin} data-apply_avat={this.props.data.apply_avat} data-fleet_type={this.props.data.fleet_type} 
                    data-fleet_logo={this.props.data.fleet_logo} data-state_code={this.props.data.state_code} data-billing_manager={this.props.data.billing_manager} 
                    data-seller_address_id={this.props.data.seller_address_id} data-fleet_deal={this.props.data.fleet_deal} data-billing_mgr={this.props.data.billing_mgr} 
                    data-tech_mgr={this.props.data.tech_mgr} data-key_mgr={this.props.data.key_mgr}>
        
                    <div className="textsize12 border-box weight500 dpmboxes1"><table className="stdtable"><tr><td valign="center">&#9679;</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes26"><table className="stdtable colorprim500span" style={{textTransform:'uppercase'}}><tr><td valign="center">{this.props.data.id}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable colorprim500span"><tr><td valign="center">{this.props.data.name}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes8"><table className="stdtable"><tr><td valign="center">{this.props.data.garage_code}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.location}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.garage_contact}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.display_name}</td></tr></table></div>
                    <div className="textsize12 border-box weight500 dpmboxes9"><table className="stdtable"><tr><td valign="center"></td></tr></table></div>
                    </div>
                );
            default:
                return null;
          }
    }
  }

export default Row;