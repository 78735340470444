import React from 'react';
import API from '../api';
import MultiSelect from '../broadcast/MultiSelect/MultiSelect';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Constants from '../constants';
import axios from 'axios';
import _ from 'lodash';
import ButtonLoader from '../ButtonLoader/ButtonLoader';

class MiddleUpper extends React.Component {
	constructor(props) {
		super(props);
		this.brand = React.createRef();
		this.sub_category = React.createRef();
		this.state = {
			selectedProductDetail:null,
			masterTabCategory: 'Brand',
			selectedPage: null,
			downloadablePages: null,
			searchTerm: [],
			count:0,
			searchedItem: [],
			discountSearch: '',
			defaultDiscounts: {},
			discountValue: 0,
			selectedBrand: null,
			isDefaultSuccess: false,
			showSaveForDiscount: false,
			brands: [],
			selectedBrandForModel: [],
			modelsForBrand: [],
			selectedModel: [],
			selectedFuelType:'',
			varientType:[],
			year_range:'',
			selectedVarientType:'',
			handleAddCategory: false,
			newCategory: '',
			bannerType: 'web',
			bannerFile: '',
			addNewBrands: false,
			newDiscountValue: 0,
			selectedClass: '',
			selectedClass1: '',
			newBrandName: '',
			mobile: '9566212403',
			segmentsList: [],
			dataUploaded:false,
			filter_key: null,
			brandsList: [],
			subCategoryList: [],
			badges: [],
			parts: [],
			categoryPayload: {},
			downloadOptions: [
				{ name: 'Brand wise' },
				{ name: 'Category wise' },
				 {name: 'Vehicle wise'}
			],
			downloadOption: '',
			attributesList: [
				{
					name: 'select all',
					id: 'select-all',
				},
				{
					name: 'sku_code',
					id: 'sku_code',
				},
				{
					name: 'segment_name',
					id: 'segment_name',
				},
				{
					name: 'sku_brand',
					id: 'sku_brand',
				},
				{
					name: 'sku_category',
					id: 'sku_category',
				},
				{
					name: 'sku_desc',
					id: 'sku_desc',
				},
				{
					name: 'title',
					id: 'title',
				},
				{
					name: 'price',
					id: 'price',
				},
				{
					name: 'mrp',
					id: 'mrp',
				},
				{
					name: 'tax',
					id: 'tax',
				},
				{
					name: 'hsn_code',
					id: 'hsn_code',
				},
				{
					name: 'compatibility_group_id',
					id: 'compatibility_group_id',
				},
				{
					name: 'sku_code_search',
					id: 'sku_code_search',
				},
				{
					name: 'product_id',
					id: 'product_id',
				},
				{
					name: 'is_visible',
					id: 'is_visible',
				},
				{
					name: 'oem_alternatives',
					id: 'oem_alternatives',
				},
				{
					name: 'oes_alternatives',
					id: 'oes_alternatives',
				},
				{
					name: 'sku_class',
					id: 'sku_class',
				},
				{
					name: 'is_duplicate',
					id: 'is_duplicate',
				},
				{
					name: 'image_count',
					id: 'image_count',
				},
				{
					name: 'sub_category_name',
					id: 'sub_category_name',
				},
				{
					name: 'instock',
					id: 'instock',
				},
				{
					name: 'image_url',
					id: 'image_url',
				},
			],
			selectedAttributesList: [],
			cmsBrandList: [],
			cashback: { limit: '', min_cashback: '', max_cashback: '', sku_code: '' },
			selected_brandSub_category:null,
			selected_brand_radio:{},
			selected_subCat_radio:null,
			searched_brand:'',
			searched_subcategory:'',
			expandedBrand:false,
			expanded_subCategory:false,
			checkout_data:{},
			middleContent:this.props.tag==="checkout"?this.props.content:[],
			loader:false,
			loader_1:false,
			global_wallet:{},
		};
		this.handleFilterClick = this.handleFilterClick.bind(this);
		this.handleSummaryClick = this.handleSummaryClick.bind(this);
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleSearchClickVehicle = this.handleSearchClickVehicle.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.onFormSubmitPricing = this.onFormSubmitPricing.bind(this);
		this.onFormSubmitPincode = this.onFormSubmitPincode.bind(this);
		this.onChange = this.onChange.bind(this);
		this.fileUpload = this.fileUpload.bind(this);
		this.fileUploadPricing = this.fileUploadPricing.bind(this);
		this.fileUploadDelivery = this.fileUploadDelivery.bind(this);
		this.downloadExcel = this.downloadExcel.bind(this);
		this.downloadExcelPricing = this.downloadExcelPricing.bind(this);
		this.downloadExcelCompatibility =
			this.downloadExcelCompatibility.bind(this);
		// this.uploadCompatibilityFile = this.uploadCompatibilityFile.bind(this)
		this.downloadCustomersDiscount = this.downloadCustomersDiscount.bind(this);
		this.downloadSampleDeliveryExcel =
			this.downloadSampleDeliveryExcel.bind(this);
		this.fileUploadCompatibility = this.fileUploadCompatibility.bind(this);
		this.onFormSubmitCompatibility = this.onFormSubmitCompatibility.bind(this);
		this.getLineItems = this.getLineItems.bind(this);
		this.getLineItemsSearch = this.getLineItemsSearch.bind(this);
		this.debounceData = this.debounceData.bind(this);
		this.handleSearchDefault = this.handleSearchDefault.bind(this);
		this.brandSelectValue = this.brandSelectValue.bind(this);
		this.updateDefaultDiscount = this.updateDefaultDiscount.bind(this);
		this.onChangeBanner = this.onChangeBanner.bind(this);
		this.onFormBannerSubmit = this.onFormBannerSubmit.bind(this);
		this.fileUploadBanner = this.fileUploadBanner.bind(this);
		this.addBannerHnadler = this.addBannerHnadler.bind(this);
		this.deleteSelectedBrand = this.deleteSelectedBrand.bind(this);
		this.handleChangeForClass = this.handleChangeForClass.bind(this);
		this.syncSparesBanners = this.syncSparesBanners.bind(this);
		this.handleCategoryBrandDiscount =
			this.handleCategoryBrandDiscount.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.tag !== this.props.tag)
			this.setState({
				cashback: {
					limit: '',
					min_cashback: '',
					max_cashback: '',
					sku_code: '',
				},
				selected_brand: null,
				selected_category_discount: null,
				selected_brand_discount: null,
				combinationDisount: 0,
				selected_year_range: '',
				masterTabCategory: 'Brand',
				downloadOption: {},
				selected_download_category: null,
				selected_download_segment: null,
				image_file: null,
				image_url: null,
				family: null,
				selected_segment: null,
				selected_category: null,
				selected_sub_category: null,
				selectedAttributesList: [],
				selectedRow: '',
				file: null,
				categoryPayload: {},
				parts: null,
				epcData: null,
				// selectedBrandForModel: null,
				selectedModel: null,
				selected_varient: null,
				selected_category: null,
				selected_sub_category: null,
				search:''
			});
	}
	componentWillMount(){
		if(this.props.tag=='checkout'){
			this.getSubCategoriesList();
			this.getPaymentModeDefault();
		}
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick = (event) => {
		if (this.brand &&  this.brand.current && !this.brand.current.contains(event.target)) {
		 this.setState({expandedBrand:false})
		}
		if (this.sub_category &&  this.sub_category.current && !this.sub_category.current.contains(event.target)) {
			this.setState({expanded_subCategory:false})
		   }
	}

	getCMSBrands = () => {
		API.getCMSBrands()
			.then((response) => {
				this.setState({
					middleContent: response.data.data,
				});
			})
			.catch((error) => {
				console.log(error.response);
				// handle error
				// console.log(error)
			});
	};
	handleButtonClick =(type)=> {
		this.props.onRightButtonClick(
			type,
			this.props.tag,
			this.props.selectedRow,
			this.props.content
		);
	}

	syncSparesBanners() {
		this.setState({loader:true})
		API.syncSparesBanners().then(() => {
			this.setState({loader:false})
			NotificationManager.success("Banners Synced", 'Success', 5000)
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Banners Not Synced", 'Error', 5000)
			this.setState({loader:false})
		})
		;
	}
	handleCategoryBrandDiscount() {
		this.props.onCombinationDiscount(!this.props.showCombinationDiscount);
	}
	async downloadCustomersDiscount() {
		const csvData = await API.downloadCustomersDiscount();
		// return;
		if (csvData.data) {
			// const csvDaTa = await csvData.text();
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `customers_discounts_data.csv`;
			a.click();
		} else alert('No Data');
	}

	getCarBrands() {
		axios
			.get(Constants.getSparesBaseURL() + 'car-brands/')
			.then((data) => {
				var brands = [];
				brands = [
					// { id: "select", name: "Select Brands" },
					...data.data.data,
				];
				this.setState({ brands: brands }, () => {});
			})
			.catch((e) => console.log('error ', e));
	}

	afterFilterChangeMake(field) {
		field = field || null;
		if (field == 'brand') {
			// this.brandWiseModel();
			axios
				.get(
					Constants.getSparesBaseURL() +
						// 'car-models/?source=cms&car_brand_id=' +
						'vehicles-cms/?varient=0&source=cms&make=' +
						this.state.selectedBrandForModel.name
				)
				.then((data) => {
					var models = [];
					models = [...data.data.data];
					models = _.uniqBy(models, (item) => item.model);
					this.setState({ modelsForBrand: models }, () => {});
				})
				.catch((e) => console.log('error ', e));
		}
	}

	addBannerHnadler() {
		this.props.handleAddClickSpares(this.props.tag, this.state.bannerType);
	}

	handleFilterClick() {
		this.props.onFilterClick();
	}

	callSparesMainBannerList=(source)=> {
		this.setState({ bannerType: source });
		this.props.changeStateBanner('main_banner', this, source);
	}
	callSparesTrendingBannerList=(source) => {
		this.setState({ bannerType: source });
		this.props.changeStateBanner('trending_banner', this, source);
	}
	callSparesTyreBannerList=(source)=> {
		this.setState({ bannerType: source });
		this.props.changeStateBanner('tyre_banner', this, source);
	}

	handleSearchClick() {
		if (this.state.search != null) {
			this.props.onSearch1Click(this.state.search);
		} else {
			NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			);
		}
	}
	async downloadCustomersDiscount() {
		const csvData = await API.customersDiscountDownload();
		// return;
		if (csvData.data) {
			// const csvDaTa = await csvData.text();
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `customers_discounts_data.csv`;
			a.click();
		} else alert('No Data');
	}
	async downloadSampleDeliveryExcel() {
		const csvData = await API.getSampleDeliveryExcel();
		if (csvData.data) {
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `sample_pincodes.csv`;
			a.click();
		} else alert('No data');
	}

	handleSearchClickVehicle() {
		this.props.tag === 'master' ?this.state.selectedBrandForModel.name != null ?
			this.props.onSearch1Click(
				this.state.selectedBrandForModel.name,
				this.state.selectedModel.model
			):NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			)
			:this.state.selectedBrandForModel.name != null && this.state.selectedModel.model ?	this.props.onSearch1Click(
				this.state.selectedBrandForModel.name,
				this.state.selectedModel.model
			):NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			)
	}

	handleAddNewVariant() {
		if (
			this.state.selectedBrandForModel.name != null &&
			this.state.selectedModel.name
		) {
			this.props.onAddNewVariant(
				this.props.tag,
				this.state.selectedBrandForModel.name,
				this.state.selectedModel.name
			);
		} else {
			NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			);
		}
	}

	async handleSearchDefault() {
		let response = await API.getDefaultDiscounts();

		this.setState({
			defaultDiscounts: response.data.data,
			isDefaultSuccess: true,
		});
	}

	brandSelectValue(value) {
		this.setState({ selectedBrand: value });
		this.setState(
			{
				discountValue: this.state.defaultDiscounts[value],
			},
			() => {
				console.log('this.state ', this.state);
			}
		);
	}

	handleChangeForDiscount(type, e) {
		let obje = this.state;
		obje[type] = e.target.value;
		this.setState(obje);
		if (type !== 'newDiscountValue')
			this.setState({ discountValue: e.target.value });
	}

	updateDefaultDiscount() {
		const newObj = {};
		newObj['brand'] = this.state.selectedBrand;
		newObj['default_discount'] = this.state.discountValue;
		newObj[this.state.selectedBrand] = this.state.discountValue;
		API.updateDiscountDefault(newObj)
			.then(() => {
				NotificationManager.success('Discount Updated', 'Success', 3000);
			})
			.catch(() => alert('Error'));
	}

	deleteSelectedBrand() {
		const newObj = {};
		newObj['brand_name'] = this.state.selectedBrand;
		API.deleteDiscountBrand(newObj)
			.then(() => {
				NotificationManager.success('Deleted', 'Successfully', 3000);
			})
			.catch(() => alert('Error'));
	}

	handleChangeForClass(type, e) {
		let obje = this.state;
		obje[type] = e.target.value;
		// this.setState({ discountValue: e.target.value });
		this.setState(obje);
	}

	handleButtonClickAddNewBrand() {
		const newObj = {
			sku_brand: this.state.newBrandName,
			is_spare: 0,
			is_oem: 0,
			is_lubricant: 0,
			is_tyre: 0,
			is_oes: 0,
			is_aftermarket: 0,
			is_battery: 0,
			default_discount: parseInt(this.state.newDiscountValue),
			brand_name: this.state.newBrandName,
		};

		newObj[this.state.selectedClass] = 1;
		newObj[this.state.selectedClass1] = 1;

		API.addNewBrand(newObj)
			.then(() => {
				NotificationManager.success('Discount Updated', 'Success', 3000);
			})
			.catch(() => alert('Error'));
	}

	handleChange(type, e) {
		if (/^$|[A-Z0-9]/i.test(e.target.value)) {
			let obj = this.state;
			obj[type] = e.target.value;
			this.setState(obj);
			console.log(this.state);
		} else {
			return;
		}
	}

	handleSummaryClick() {}

	handleAddClick() {
		this.props.onAddClick(this.props.tag);
	}

	onChange(e) {
		let file = e.target.files[0];
		// if (
		// 	!file.type.match('.csv') &&
		// 	!file.type.match(
		// 		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		// 	)
		// ) {
		// 	NotificationManager.error('Please Choose an Excel File', 'Error', 3000);
		// 	return;
		// } else 
		if(!file){
			NotificationManager.error('Please Choose a File', 'Error', 3000);
			return
		}
		else{
			this.setState({ file: e.target.files[0] });
		}
	}

	onChangeBanner(e) {
		let file = e.target.files[0];
		if (!file.type.match('image/*')) {
			NotificationManager.error('Please Choose an image File', 'Error', 3000);
			return;
		} else this.setState({ bannerFile: e.target.files[0] });
	}

	onFormSubmit(e) {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			this.fileUpload(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 2000);
					setTimeout(() => {
						if(response.data && response.data.data){ 
						var el=document.getElementById('partBulkUpload');
						el.href='https://spares-mp.gomechanic.app/error-csv/?request_id='+response.data.data;
						window.open(el.href);
						NotificationManager.success(response.data.message, 'Success', 3000);
					}
					}, 2000);
					
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
		}
	}

	onEPCCSVSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadEPCCSV(this.state.file)
				.then((response) => {
					console.log(response.data);
					let currProd = {};
					_.get(response, ['data', 'data'], []).forEach((item) => {
						currProd = {
							...currProd,
							[item.product_id]: { figNum: item.index },
						};
					});

					this.setState({
						categoryPayload: currProd,
						parts: response.data.data,
					});
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	onDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	
	onSubCategroyDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadSubCategoryBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	onBrandSubCategroyDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadBrandSubCategoryBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	
	onBrandDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadBrandBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	onFormBannerSubmit(e) {
		e.preventDefault();
		if (!this.state.bannerFile)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else
			this.fileUploadBanner(this.state.bannerFile)
				.then((response) => {
					console.log(response.data);
					NotificationManager.success('File Uploaded', 'Success', 3000);
					window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
	}
	onFormSubmitPincode(e) {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Choose A File Before Uploading',
				'Error',
				3000
			);
		else {
			this.fileUploadDelivery(this.state.file)
				.then((res) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((error) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
				});
		}
	}

	uploadCategoryFile = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.bulkUploadCategorization(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
					// window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e.response);
				});
		}
	};

	onFormSubmitPricing(e) {
		e.preventDefault(); // Stop form submit
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			this.fileUploadPricing(this.state.file)
				.then((response) => {
					console.log(response.data);
					NotificationManager.success('File Uploaded', 'Success', 3000);
					// window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
		}
	}

	uploadSpecificationCSV = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileUploadSpecification(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	
	uploadAlternativesCSV = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileUploadAlternatives(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};

	onFormSubmitCompatibility(e) {
		e.preventDefault(); // Stop form submit
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileUploadCompatibility(this.state.file)
				.then((response) => {
					console.log(response.data);
					NotificationManager.success('File Uploaded', 'Success', 3000);
					window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
		}
	}

	fileUploadAlternatives(file) {
		return API.uploadAlternativesCSV(file);
	}

	fileUploadSpecification(file) {
		return API.uploadSpecificationCSV(file);
	}

	fileUploadCompatibility(file) {
		return API.uploadSparesProductsCompatibility(file);
	}

	fileUpload(file) {
		return API.bulkUploadSparesProducts(file);
	}

	fileUploadBanner(file) {
		return API.sparesBannerUpload(file);
	}

	fileUploadPricing(file) {
		return API.bulkUploadSparesProductsPricing(file);
	}
	fileUploadDelivery(file) {
		return API.bulkUploadSparesDelivery(file);
	}

	downloadExcel() {
		// let csvData = "Part Number,Part Description,SKU Brand,Sku Class,Category,Tax,HSN,Price,Threshold Quantity,Pack,Height,Width,Lenght,Weight";
		// const csvDaTa = csvData.text();
		// var a = document.createElement("a");
		// const blob = new Blob([csvData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		// const url = window.URL.createObjectURL(blob);
		// a.href = url;
		// a.download = `sample.xls`;
		// a.click();
		// let csvData = "https://storage.googleapis.com/spares/spares/parts_sample.xlsx";
		// var xhr = new XMLHttpRequest();
		// xhr.onreadystatechange = function () {
		//     if (this.readyState === 4 && this.status === 200) {
		//         console.log('success', this.responseText.length);
		//     }
		// };
		// xhr.open('GET', 'https://storage.googleapis.com/spares/spares/parts_sample.xlsx', true);
		// xhr.send(null);
		// let a = document.createElement("a");
		// a.download = "https://storage.googleapis.com/spares/spares/parts_sample.xlsx";
		// a.click()
	}

	async downloadExcelPricing() {
		const csvData = await API.downloadPricingExcel();
		// return;
		if (csvData.data) {
			// const csvDaTa = await csvData.text();
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `excel_file.csv`;
			a.click();
		} else alert('No Data');
	}

	downloadExcelCompatibility() {
		let csvData = 'Part Number,Make,Model,Variant Year,Fuel Engine';
		var a = document.createElement('a');
		const blob = new Blob([csvData], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = `sample.xls`;
		a.click();
	}

	debounceData(fn, delay) {
		let timer = null;
		return function (...args) {
			const context = this;
			timer && clearTimeout(timer);
			timer = setTimeout(() => {
				fn.apply(context, args);
			}, delay);
		};
	}

	getLineItemsSearch = this.debounceData(this.getLineItems, 1000);

	// export default debounceData;
	getLineItems(term) {
		var that = this;

		// var delayTimer;
		if (term.length >= 3) {
			let modifiedTerm = '';
			for (var i = 0; i < term.length; i++) {
				if (term.charAt(i).match(/^[a-zA-Z0-9]/))
					modifiedTerm += term.charAt(i);
			}
			if (modifiedTerm.match(/^[a-zA-Z0-9]+$/)) {
				// clearTimeout(delayTimer);
				// delayTimer = setTimeout(() => {
				console.log('term ', term);
				API.getProductIdSuggestions(modifiedTerm).then(function (response) {
					if (response.data.data.options.length) {
						that.setState({ searchTerm: response.data.data.options }, () => {
							that.state.searchTerm.map(
								(code, id) =>
									(that.state.searchTerm[id]['sku_code'] =
										code.sku_code +
										' - ' +
										code.sku_brand +
										' - ' +
										code.product_id)
							);
						});
						// that.setState({ searchTerm: that.state.searchTerm["newOp"] = response.data.data.options })

						var obj = that.state;
						// obj["search"] = term;
						obj['search'] = that.state.searchedItem.product_id;
						that.setState(obj);
					} else NotificationManager.error('No Content', 'Error', 2000);
				});
				// }, 2000);
			} else {
				NotificationManager.error('Invalid input', 'Error', 2000);
			}
		} else {
			this.setState({ searchTerm: [] });
		}
	}
	afterVehicleFilterChange(field) {
    field = field || null;
    if (field == "brand") {
      // this.brandWiseModel();
      axios
        .get(
          Constants.getSparesBaseURL() +
            "car-models/?car_brand_id=" +
            this.state.selectedBrandForModel._id
        )
        .then((data) => {
          var models = [];
          models = [...data.data.data];
          this.setState({ modelsForBrand: models }, () => {});
        })
        .catch((e) => console.log("error ", e));
    }

    if (field == "model") {
      axios.get(`${Constants.getSparesBaseURL()}vehicles-cms/?varient=0&source=cms&make=${encodeURIComponent(this.state.selectedBrandForModel.name)}&model=${encodeURIComponent(
			this.state.selectedModel.name)}`)
      .then((response)=> {
        if (response.status === 204 || response.data.data.length == 0) {
          alert('No Content');
        } else
        this.setState({ varientType: response.data.data }, () => {});
      })
      .catch((error)=> {
        console.log(error)
      })
      .finally(function () {
        // always executed
      });
    }
  }
	afterFilterChange() {
		var that = this;
		var obj = that.state;
		obj['search'] = that.state.searchedItem.product_id;
		that.setState(obj);
		this.props.tag==='products'&& this.handleSearchClick()
	}

	handleChangeForPhone(type, e) {
		if (isNaN(Number(e.target.value))) {
			return;
		} else {
			this.setState({ mobile: e.target.value });
		}
		let obje = this.state;
		obje[type] = Number(e.target.value);
		// obje["discounts"][this.state.selectedBrand] = Number(e.target.value);
		// const newObj = {};
		// newObj["customer_id"] = this.state.customer_id;
		// newObj["discounts"] = this.state.discounts;
		// this.setState(newObj);
	}

	handleButtonClickOTP(type) {
		let that = this;
		// if (this.state.mobile.length !== 10)
		//     NotificationManager.error(
		//         "Please add a Valid Number",
		//         "Error",
		//         3000
		//     );
		// else
		API.getOTPForDiscount(this.state.mobile)
			.then(function (response) {
				if (response.data.status) {
					NotificationManager.success('OTP Sent', 'Successfully', 3000);
					// that.props.onMiddleExtraButtonClick(
					//     type,
					//     that.state,
					//     that.props.selectedRow
					// );
					that.setState({ showVerifyOTP: true });
				} else {
				}
			})
			.catch(function (error) {
				// handle error
				NotificationManager.error('An Error Occured', 'Error', 3000);
				that.props.onMiddleExtraButtonClick(
					'close',
					that.state,
					that.props.selectedRow
				);
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	handleChangeForOTP(type, e) {
		if (isNaN(Number(e.target.value))) {
			return;
		} else {
			this.setState({ otp: e.target.value });
		}
		let obje = this.state;
		obje[type] = Number(e.target.value);
		// obje["discounts"][this.state.selectedBrand] = Number(e.target.value);
		// const newObj = {};
		// newObj["customer_id"] = this.state.customer_id;
		// newObj["discounts"] = this.state.discounts;
		// this.setState(newObj);
	}

	handleButtonClickOTPVerify(type) {
		let that = this;
		API.verifyOTPForDiscount(this.state.mobile, this.state.otp)
			.then(function (response) {
				if (response.data.status) {
					NotificationManager.success('OTP Verified', 'Successfully', 3000);
					// that.props.onMiddleExtraButtonClick(
					//     type,
					//     that.state,
					//     that.props.selectedRow
					// );
					that.setState({ showSaveForDiscount: true });
				} else {
				}
			})
			.catch(function (error) {
				// handle error
				NotificationManager.error('OTP Invalid', 'Error', 3000);
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	getSampleSpecificationCSV = () => {
		const segemnt = _.get(this.state, ['selected_segment', 'name'], '');
		const category = _.get(
			this.state,
			['selected_category', 'display_name'],
			''
		);
		const subCategory = _.get(
			this.state,
			['selected_sub_category', 'name'],
			''
		);
		API.getSpecificationSampleCSV(segemnt, category, subCategory);
	};

	getSegmentList() {
		API.getSparesSegments()
			.then((result) => {
				this.setState({ segmentsList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getCategoryList(segmentId) {
		API.getCategories(segmentId)
			.then((res) => this.setState({ categoryList: res.data.data }))
			.catch((err) => {
				console.log(err);
			});
	}
	getPaymentModeDefault = () => {
		API.paymentModeDefault().then((res)=>{
			
			let filtered_payment= res.data.data.map((item)=>{
				let filtered_array=item.array.map((arrayItem)=>{
				  return(
				   {
					enum_name:arrayItem.enum_name,
					enum_number:arrayItem.enum_number,
					is_visible:arrayItem.is_visible,
					name:arrayItem.name,
				})
				})

				return(
				  {
					enum_name:item.enum_name,
					enum_number:item.enum_number,
					is_visible:item.is_visible,
					name:item.name,
					array:filtered_array
			   })
			  })
			this.setState({...this.state,checkout_data:{...this.state.checkout_data,payment_mode:filtered_payment}})
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Data can't be Updated", 'Error', 5000)
		})
	};
	
	setToDefaultPaymentMode = () => {
		this.setState({loader:true})
		API.deleteCheckoutPaymentMode(this.state.selected_brand_radio && this.state.selected_brand_radio.sku_brand,this.state.selected_subCat_radio && this.state.selected_subCat_radio.name)
		API.paymentModeDefault().then((res)=>{
			NotificationManager.success("Set to default", "Successfully", 3000);
			let filtered_payment= res.data.data.map((item)=>{
				let filtered_array=item.array.map((arrayItem)=>{
				  return(
				   {
				 enum_name:arrayItem.enum_name,
				 enum_number:arrayItem.enum_number,
				 is_visible:arrayItem.is_visible,
				 name:arrayItem.name,
			   }
				  )
				})
				return(
				  {
				 enum_name:item.enum_name,
				 enum_number:item.enum_number,
				 is_visible:item.is_visible,
				 name:item.name,
				 array:filtered_array
			   }
				)
			  })
			this.setState({...this.state,checkout_data:{...this.state.checkout_data,payment_mode:filtered_payment}})
			this.setState({loader:false})
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Data can't be Updated", 'Error', 5000)
			this.setState({loader:false})
		})
	};

	getSubCategoriesList = () => {
		API.getSparesSubCategory()
			.then((result) => {
				this.setState({ subCategoryList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getBrandsList() {
		API.getSparesBrandsList()
			.then((result) => {
				this.setState({ brandsList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	syncSparesBrands() {
		API.syncSparesBrands()
			.then(() => {
				NotificationManager.success('Brand Sync Started.', 'Success', 5000);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	syncSparesCatgeory() {
		API.syncSparesCategories()
			.then(() => {
				NotificationManager.success(
					'Categories Sync Started.',
					'Success',
					5000
				);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	syncSparesSegment() {
		API.syncSparesSegment()
			.then(() => {
				NotificationManager.success('Segement Sync Started.', 'Success', 5000);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	syncSparesVehicle() {
		API.syncSparesCars()
			.then(() => {
				NotificationManager.success('Vehicle Sync Started.', 'Success', 5000);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	getAllParts = (make, model, varient, engine, segmentId, skuCategory) => {
		API.getAllProducts(make, model, varient, engine, segmentId, skuCategory)
			.then((res) => {
				if (!res.data.data.data || res.data.data.data.length === 0) {
					NotificationManager.error('Data not found');
				}
				this.setState((prevState) => {
					return {
						parts: res.data.data.data,
						partDetail: {
							...prevState.partDetail,
							sku_brand: _.get(
								res,
								['data', 'data', 'data', [0], 'sku_brand'],
								''
							),
						},
					};
				});
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	};

	getEPCData = (
		make,
		model,
		varient,
		engine,
		segmentId,
		family,
		year_range
	) => {
		API.getEPCData(make, model, varient, engine, segmentId, family, year_range)
			.then((res) => {
				// const figMap = _.get(res, ['data', 'data', 'epc_data', 'fig_map'], {});
				const seq_map = _.get(res, ['data', 'data', 'epc_data', 'seq_map'], {});
				const image_url = _.get(res, ['data', 'data', 'epc_data', 'image'], '');
				const parts = _.get(
					res,
					['data', 'data', 'epc_data', 'list_parts'],
					''
				);

				// let categoryPayload = _.get(res,["data","data","epc_data","seq_map"],{});
				// let image_url = _.get(res, ["data","data","epc_data", "image_url"],'');
				this.setState({
					categoryPayload: seq_map,
					image_url,
					parts,
					image_file: null,
				});
			})
			.catch((err) => {
				// console.log(_.get(err, ["response", "data", "message"], ""));
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	addBadges = (event) => {
		if (!this.state.selectedRow || this.state.selectedRow === '') return;
		var mouseX = event.pageX;
		var mouseY = event.pageY;
		var top = mouseY - 10;
		var left = mouseX - 10;
		let imageDim = document.getElementById('part-img').getBoundingClientRect();
		top = ((top - imageDim.top) / imageDim.height) * 100;
		left = ((left - imageDim.left) / imageDim.width) * 100;
		let selectedProduct = this.state.categoryPayload[this.state.selectedRow];
		this.setState((state) => {
			return {
				...state,
				// figMap: {
				// 	..._.get(state, ['figMap'], {}),
				// 	[selectedProduct.figNum]: Array.from(
				// 		new Set([
				// 			..._.get(state, ['figMap', selectedProduct.figNum], []),
				// 			state.selectedRow,
				// 		])
				// 	),
				// },
				categoryPayload: {
					...state.categoryPayload,
					[state.selectedRow]: {
						...selectedProduct,
						coordinates: [
							..._.get(selectedProduct, ['coordinates'], []),
							[top, left],
						],
					},
				},
			};
		});
	};

	handleEPCUpload = () => {
		const brand = _.get(this.state, ['selectedBrandForModel', 'name'], null);
		const model = _.get(this.state, ['selectedModel', 'model'], null);
		const varient = _.get(this.state, ['selected_varient', 'varient'], null);
		const fuel_engine = _.get(
			this.state,
			['fuel_engine'],
			null
		);
		const year_range = _.get(this.state, ['selected_year_range'], '');
		const segmentId = _.get(this.state, ['selected_segment', '_id'], null);
		// const {name: subCategory} = this.state.selected_sub_category;
		let partDetail = {
			make: brand,
			model,
			fuel_engine,
			varient,
			year: year_range,
			// sku_category: subCategory,
			segment_name: _.get(this.state, ['selected_segment', 'name'], null),
			// sku_brand: null,
			// image_url: this.state.image_file,
			image: this.state.image_url,
			seq_map: [],
			segment_id: segmentId,
			family: this.state.family,
		};
		// this.setState({partDetail, categoryPayload: {}, file: null});

		// this.getAllParts(brand, model, varient,fuel_engine, segmentId, subCategory);
		// this.getEPCData(brand, model, varient,fuel_engine, segmentId, subCategory);
		if (
			!this.state.image_url ||
			!brand ||
			!model ||
			!varient ||
			!_.get(this.state, ['selected_segment', 'name'], null) ||
			!this.state.family ||
			year_range === '' ||
			!year_range
		) {
			NotificationManager.error('All fields are mandatory.', 'Error!!', 5000);
			return;
		}
		let payload = { ...partDetail };
		payload['seq_map'] = this.state.categoryPayload;
		// payload['fig_map'] = this.state.figMap;
		// payload["list_parts"] = Object.keys(this.state.categoryPayload);
		payload['list_parts'] = Object.keys(this.state.categoryPayload).map(
			(product_id) => {
				const partDetail =
					this.state.parts[
						_.findIndex(this.state.parts, { product_id: product_id })
					];
				return {
					sku_code: partDetail.sku_code,
					sku_brand: partDetail.sku_brand,
					title: partDetail.title,
					product_id: partDetail.product_id,
					index: partDetail.index,
				};
			}
		);
		// return;
		this.setState({dataUploaded:true})
		API.uploadEPCData(payload)
			.then(() => {
				this.setState({dataUploaded:false})
				NotificationManager.success('Uploading data.', 'Success', 5000);
				document.getElementById('myepcfile').value = '';
			})
			.catch((error) => {	
				if(error.message==="Network Error"){
				NotificationManager.error(
					'Check your Internet Connection!',
					'Error!!'
				);	
			} 
			else{
				this.setState({dataUploaded:false})
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);}
			});
	};

	handleBrandRadioChange=(e,item)=>{
		if(e.target.checked){
			this.setState({selected_brand_radio:item,searched_brand:'',expandedBrand:false})
		}	
	}

	customDeliveryChargeUpdate=()=>{
		let obj={
			brand:this.state.selected_brand_radio?this.state.selected_brand_radio.sku_brand:null,
			sub_category_name:this.state.selected_subCat_radio  &&  this.state.selected_subCat_radio.name || '',
			delivery_charge:this.state.checkout_data.delivery_charge,
			payment_mode:this.state.checkout_data.payment_mode
		}
			this.setState({loader_1:true})
			NotificationManager.success('Please Wait','Updating...', 5000)
		API.customDeliveryCharge(obj).then(() => {
			NotificationManager.success('Delivery Charge updated.', 'Success', 5000)
			this.setState({loader_1:false})
			this.setState({selected_brand_radio:{},selected_subCat_radio:null,searched_brand:'',searched_subcategory:'',checkout_data:{},default_checkout_data:{}})
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Data can't be Updated", 'Error', 5000)
			this.setState({loader_1:false})
		})
	}
	
	getCustomDeliveryData=()=>{
		this.setState({checkout_data:{}})
		let sub_category_name=this.state.selected_subCat_radio &&  this.state.selected_subCat_radio.name==='Select'?'' :this.state.selected_subCat_radio && this.state.selected_subCat_radio.name || ''
		let sku_brand=this.state.selected_brand_radio ? this.state.selected_brand_radio.sku_brand:null
		API.getCustomDeliveryData(sku_brand,sub_category_name).then((res) => {
			if(!res.data.data){
				NotificationManager.error('Data not found for the given brand & SubCategory.', 'Error', 5000)
			}else{
				if(res.data.data.payment_mode){
					this.setState({...this.state,checkout_data:res.data.data})
					this.setState({...this.state,default_checkout_data:res.data.data})
				}
				else{
					this.getPaymentModeDefault();
					this.setState({...this.state,checkout_data:res.data.data})
					this.setState({...this.state,default_checkout_data:res.data.data})
				}
			}
		}).catch(error=>console.log(error))
	}

	uploadBulkWallet=(e)=>{
		e.preventDefault();
		if (!this.state.file){
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		}
		else{
			this.setState({loader:true})
			NotificationManager.success("Uploading...", "Please Wait", 3000);
			API.globalCustomerWallet(this.state.file).then((res)=>{
			if(res.status){
				this.setState({loader:false})
				NotificationManager.success("Updated", "Successfully", 3000);
			}
		})}
	}

	handleSubCatRadioChange=(e,item)=>{
		if(e.target.checked){
			item={id:item.id,name:item.name}	
			this.setState({selected_subCat_radio:item,searched_subcategory:'',expanded_subCategory:false})
		}		
	}


	handleBrandRadioSearch=()=>{
		let arr=[];
		if(this.state.searched_brand && this.state.searched_brand.length){
			this.state.middleContent &&	this.state.middleContent.map(item=>{
				if(item.sku_brand && item.sku_brand.includes(this.state.searched_brand)){
					arr.push(item)
				}
			})
		}
		return (
			arr && arr.map((item)=>{
				return  (
				<li className="dropdown-check-newList">
				<label for={'radio'+item.brand_name} style={{width: "100%",boxSizing:'border-box'}}> 
				<input 
				 type="radio" 
				 id={"radio"+item.brand_name}
				 value={item.brand_name} 
				 checked={this.state.selected_brand_radio  && this.state.selected_brand_radio.brand_name==item.brand_name}
				 onChange={(e)=>this.handleBrandRadioChange(e,item) } 
				 />
				<span 
				style={this.state.selected_brand_radio  && this.state.selected_brand_radio.brand_name==item.brand_name?{color: "green",fontWeight:600,fontSize:'.75rem',paddingLeft:'8px'}:{fontSize:'.75rem',paddingLeft:'8px'}}
				>
					{item.brand_name}
				</span> 
				</label>
			</li> )
			})
		)
	}
	handleSubCatRadioSearch=()=>{
		let arr=[];
		if(this.state.searched_subcategory && this.state.searched_subcategory.length){
			this.state.subCategoryList &&	this.state.subCategoryList.map(item=>{
				if(item.name && item.name.includes(this.state.searched_subcategory)){
					arr.push(item)
				}
			})
		}
		return (
			arr && [{name:"Select",id:"All"},...arr].map((item)=>{
				return  (
					<li className="dropdown-check-newList">
					<label for={'radio'+item.name} style={{width: "100%",boxSizing:'border-box'}}> 
					<input 
					type="radio" 
					id={"radio"+item.name}
					value={item.name} 
					checked={this.state.selected_subCat_radio && this.state.selected_subCat_radio.name===item.name}
					onChange={(e)=>this.handleSubCatRadioChange(e,item) } 
					/>
					<span 
					style={this.state.selected_subCat_radio && this.state.selected_subCat_radio.name===item.name?{color: "green",fontWeight:600,fontSize:'.75rem',paddingLeft:'8px'}:{fontSize:'.75rem',paddingLeft:'8px'}}
					>
						{item.name + '-' + item.id}
					</span> 
					</label>
				</li> )
			})
		)
	}

	handleEPCImageUpload = () => {
		API.getEPCImageURL(this.state.image_file)
			.then((res) => {
				NotificationManager.success('Image uploaded.', 'Success', 3000);
				this.setState({
					image_url: _.get(res, ['data', 'data'], null),
				});
				API.getEPCTags(_.get(res, ['data', 'data'], null))
					.then((res) => {
						let response = _.get(res, ['data', 'data'], []);
						let parts = _.get(this.state, ['parts'], []);
						let categoryPayload = JSON.parse(
							JSON.stringify(this.state.categoryPayload)
						);
						response.forEach((item) => {
							let index = _.findIndex(parts, { index: item.text });
							if (index >= 0) {
								let productId = parts[index].product_id;
								categoryPayload[productId]['coordinates'] = [
									..._.get(categoryPayload, [productId, 'coordinates'], []),
									[item.center[1], item.center[0]],
								];
							}
						});
						this.setState({ categoryPayload });
					})
					.catch((err) =>
						NotificationManager.error('Auto tagging failed.', 'Error!!', 3000)
					);
			})
			.catch((err) =>
				NotificationManager.error('Failed to upload image.', 'Error!!', 3000)
			);
	};

	fetchDownloadData = (fetchPageCount = false, page = 0) => {
		let urlPath = {
			sku_brand_filter: null,
			segment_name_filter: null,
			sku_category_filter: null,
			model_filter: null,
		};
		let attributes = this.state.selectedAttributesList.reduce((ar, item) => {
			if (item.name !== 'select all') ar[item.name] = 1;
			return ar;
		}, {});

		switch (this.state.downloadOption.name) {
			case 'Brand wise': {
				let sku_brand = _.get(
					this.state,
					['selectedBrandForModel', 'sku_brand'],
					null
				);
				if (!sku_brand && Object.keys(attributes).length === 0) {
					NotificationManager.error(
						'All the fields can not be empty',
						'Error!!',
						5000
					);
					return;
				}
				urlPath['sku_brand_filter'] = sku_brand;
				break;
			}

			case 'Category wise': {
				let segment_name = _.get(
					this.state,
					['selected_download_segment', 'name'],
					null
				);
				let sku_category = _.get(
					this.state,
					['selected_download_category', 'name'],
					null
				);

				if (
					!segment_name &&
					!sku_category &&
					Object.keys(attributes).length === 0
				) {
					NotificationManager.error(
						'All the fields can not be empty',
						'Error!!',
						5000
					);
					return;
				}
				urlPath['segment_name_filter'] = encodeURIComponent(segment_name);
				urlPath['sku_category_filter'] = encodeURIComponent(sku_category);
				break;
			}

			case 'Vehicle wise': {
				let make=_.get(
					this.state,
					['selectedBrandForModel', 'name'],
					null
				);
				let model=_.get(
					this.state,
					['selectedModel', 'name'],
					null
				);
				let year_range=_.get(
					this.state,
					['year_range'],
					null
				);
				let fuel_engine=_.get(
					this.state,
					['selectedFuelType','fuel_engine'],
					null
				);
				let varient=_.get(
					this.state,
					['selectedVarientType','varient'],
					null
				);
				if (
					!make &&
					!model &&
					!year_range &&
					!fuel_engine &&
					!varient &&
					Object.keys(attributes).length === 0
				) {
					NotificationManager.error(
						'All the fields can not be empty',
						'Error!!',
						5000
					);
					return;
				}
			
			urlPath['make'] = encodeURIComponent(make);
			urlPath['model'] = encodeURIComponent(model);
			urlPath['year_range'] = encodeURIComponent(year_range);
			urlPath['fuel_engine'] = encodeURIComponent(fuel_engine);
			urlPath['varient'] = encodeURIComponent(varient);
			break;
			}
			default:
			break
		}

		if (fetchPageCount) {
			API.getDownloadTabPageCount(urlPath, attributes)
				.then((res) =>
					this.setState({ downloadablePages: _.get(res, ['data', 'data'], 0) })
				)
				.catch((err) =>
					NotificationManager.error(
						_.get(err, ['response', 'data', 'message'], 'Data not found.'),
						'Error!!',
						5000
					)
				);
		} else {
			API.getDownloadTabCSV(urlPath, attributes, page);
		}
	};

	zoomIn = () => {
	    this.setState({count:this.state.count+1})
		var myImg = document.getElementById('part-img');
		var currWidth = myImg.clientWidth;

		myImg.style.width = currWidth + 100 + 'px';
	};
	zoomOut = () => {
	    this.setState({count:this.state.count-1})
		var myImg = document.getElementById('part-img');
		var currWidth = myImg.clientWidth;
			myImg.style.width = currWidth - 100 + 'px';
	};
	masterTabs = () => {
		return (
			<div className='border-box d-flex mbottom-1 master-tab-container'>
				<div
					className={`blackspan65 weight700 floatleft ${
						_.get(this.props, ['masterTabCategory', 'category'], '') ===
							'brands_spares' && 'active-master-tab'
					}`}
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								<button
									className={`btn btn_small ${
										_.get(this.props, ['masterTabCategory', 'category'], '') ===
										'brands_spares'
											? 'btncolorprim'
											: 'btnwhite'
									} btncard move`}
									onClick={() => {
										this.props.updateMasterTabCategory('brands_spares');
									}}
								>
									Brands
								</button>{' '}
							</td>
						</tr>
					</table>
				</div>
				<div
					className={`blackspan65 weight700 floatleft ${
						_.get(this.props, ['masterTabCategory', 'category'], '') ===
							'categories_spares' && 'active-master-tab'
					}`}
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								<button
									className={`btn btn_small ${
										_.get(this.props, ['masterTabCategory', 'category'], '') ===
										'categories_spares'
											? 'btncolorprim'
											: 'btnwhite'
									} btncard move`}
									onClick={() => {
										this.props.updateMasterTabCategory('categories_spares');
									}}
								>
									Categories
								</button>
							</td>
						</tr>
					</table>
				</div>
				<div
					className={`blackspan65 weight700 floatleft ${
						_.get(this.props, ['masterTabCategory', 'category'], '') ===
							'vehicles' && 'active-master-tab'
					}`}
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								<button
									className={`btn btn_small ${
										_.get(this.props, ['masterTabCategory', 'category'], '') ===
										'vehicles'
											? 'btncolorprim'
											: 'btnwhite'
									} btncard move`}
									onClick={() => this.props.updateMasterTabCategory('vehicles')}
								>
									Vehicles
								</button>
							</td>
						</tr>
					</table>
				</div>
			</div>
		);
	};

	getBrandView = () => {
		if (this.state.brandsList.length < 1) {
			this.getBrandsList();
		}
		return (
			<React.Fragment>
				<div className='top border-box leftalign'>
					<div>
						{this.props.tag === 'master' ? this.masterTabs() : null}
						<div className='border-box'>
							<div
								className='blackspan65 weight700 floatleft'
								style={{ height: 'auto', overflow: 'visible' }}
							>
								<table className='stdtable' style={{ height: 'auto' }}>
									<tr>
										<td valign='center'>
											{this.state.brandsList && (
												<MultiSelect
													options={this.state.brandsList}
													labelKey='brand_name'
													checkbox_id='brand_name'
													placeholder='Brands'
													disabledItems={[]}
													selectedOptions={{
														brand_name: this.state.filter_key,
													}}
													onChange={(e) => {
														this.setState({ filter_key: e.brand_name });
													}}
												/>
											)}
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={() =>
													this.props.filterFunc(
														this.state.filter_key,
														'brands_spares'
													)
												}
											>
												Filter
											</button>
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatright'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.syncSparesBrands}
											>
												Sync Brand
											</button>
										</td>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleAddClick}
											>
												Add Brand
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className='middle border-box clearfix leftalign'>
					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Slug</td>
							</tr>
						</table>
					</div> */}
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Brand Name</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Brand SKU</td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Default Discount</td>
							</tr>
						</table>
					</div> */}
					<div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is Visible</td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is Spares</td>
							</tr>
						</table>
					</div> */}
					{/* <div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is OEM</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is OES</td>
							</tr>
						</table>
					</div> */}
					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	};
	getVehicleView = () => {
		if (this.state.brands.length < 1) {
			this.getCarBrands();
		}
		return (
			<React.Fragment>
				<div className='top border-box leftalign'>
					<div>
						{this.props.tag === 'master' ? this.masterTabs() : null}
						{this.props.tag === 'master' ? <div className='border-box'>
							<div
								className='blackspan65 weight700 floatleft'
								style={{
									overflow: 'initial',
									height: 'auto',
								}}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ display: 'flex' }}>
											<MultiSelect
												options={this.state.brands}
												labelKey='name'
												checkbox_id='_id'
												placeholder='Select Make'
												disabledItems={[]}
												selectedOptions={this.state.selectedBrandForModel}
												onChange={(value, e) => {
													this.setState(
														{
															selectedBrandForModel: value,
														},
														// (e) => {
														// 	this.afterFilterChangeMake('brand');
													
														// }
													);
												}}
											/>
											{/* <MultiSelect
												options={this.state.modelsForBrand}
												labelKey='model'
												checkbox_id='_id'
												placeholder='Select Model'
												disabledItems={[]}
												selectedOptions={this.state.selectedModel}
												onChange={(value, e) =>
													this.setState(
														{
															selectedModel: value,
														},
														(e) => {
															// this.afterFilterChange(
															//     "model"
															// );
															// this.handleChange(
															//     "model",
															//     value.name
															// );
														}
													)
												}
											/> */}
											{/* <input
												type="text"
												class="dashboardfields dashboardchangefields"
												name="searchbysku"
												placeholder="Search Compatilbilty Group ID"
												value={this.state.search}
												style={{
													width: "270px",
													fontSize: "16px",
												}}
												onChange={(e) =>
													this.handleChange(
														"search",
														e
													)
												}
											></input> */}
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleSearchClickVehicle}
											>
												Search
											</button>
										</td>
									</tr>
								</table>
							</div>

							<div className='blackspan65 weight700 floatright'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.syncSparesVehicle}
											>
												Sync Vehicles
											</button>
										</td>
									</tr>
								</table>
							</div>

							{/* {_.get(this.state, ['selectedBrandForModel', 'name'], null) !=
								null &&
								
									<div className='border-box floatright rightalign'>
										<button
											class='btn btn_small btncolorprim btncard move btncloseextra'
											onClick={() => this.handleAddNewVariant()}
										>
											Add New Varient
										</button>
									</div>
								} */}
						</div>:<div className='border-box'>
							<div
								className='blackspan65 weight700 floatleft'
								style={{
									overflow: 'initial',
									height: 'auto',
								}}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ display: 'flex' }}>
											<MultiSelect
												options={this.state.brands}
												labelKey='name'
												checkbox_id='_id'
												placeholder='Select Make'
												disabledItems={[]}
												selectedOptions={this.state.selectedBrandForModel}
												onChange={(value, e) => {
													this.setState(
														{
															selectedBrandForModel: value,
														},
														(e) => {
															this.afterFilterChangeMake('brand');
															// this.handleChange(
															//     "make",
															//     value.name,
															//     this.state
															//         .brands
															// );
														}
													);
												}}
											/>
											<MultiSelect
												options={this.state.modelsForBrand}
												labelKey='model'
												checkbox_id='_id'
												placeholder='Select Model'
												disabledItems={[]}
												selectedOptions={this.state.selectedModel}
												onChange={(value, e) =>
													this.setState(
														{
															selectedModel: value,
														},
														(e) => {
															// this.afterFilterChange(
															//     "model"
															// );
															// this.handleChange(
															//     "model",
															//     value.name
															// );
														}
													)
												}
											/>
											{/* <input
												type="text"
												class="dashboardfields dashboardchangefields"
												name="searchbysku"
												placeholder="Search Compatilbilty Group ID"
												value={this.state.search}
												style={{
													width: "270px",
													fontSize: "16px",
												}}
												onChange={(e) =>
													this.handleChange(
														"search",
														e
													)
												}
											></input> */}
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleSearchClickVehicle}
											>
												Search
											</button>
										</td>
									</tr>
								</table>
							</div>

							<div className='blackspan65 weight700 floatright'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.syncSparesVehicle}
											>
												Sync Vehicles
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>}
					</div>
				</div>
				<div className='middle border-box clearfix leftalign'>
					<div className='border-box blackspan65 weight700 dpmboxes1'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Make</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Model</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes35'>
						<table className='stdtable' style={{textAlign:'center'}}>
							<tr>
								<td valign='center'>Image</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>is Visbile</td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpmboxes9'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div> */}
				</div>
			</React.Fragment>
		);
	};
	getCategoryView = () => {
		if (this.state.segmentsList.length < 1) {
			this.getSegmentList();
		}
		return (
			<React.Fragment>
				<div className='top border-box leftalign'>
					<div>
						{this.props.tag === 'master' ? this.masterTabs() : null}
						<div
							className='border-box left-align d-flex'
							style={{margin: "-6px 0px 7px", }}
						>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className={`btn btn_small ${
													_.get(
														this.props,
														['masterTabCategory', 'subCategory'],
														''
													) === 'segment'
														? 'btncolorprim'
														: 'btnwhite'
												} btncard move`}
												onClick={() => {
													this.props.updateMasterTabCategory(
														'categories_spares',
														'segment'
													);
												}}
											>
												Segment
											</button>
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className={`btn btn_small ${
													_.get(
														this.props,
														['masterTabCategory', 'subCategory'],
														''
													) === 'category'
														? 'btncolorprim'
														: 'btnwhite'
												} btncard move`}
												onClick={() => {
													this.props.updateMasterTabCategory(
														'categories_spares',
														'category'
													);
												}}
											>
												Category
											</button>
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className={`btn btn_small ${
													_.get(
														this.props,
														['masterTabCategory', 'subCategory'],
														''
													) === 'subCategory'
														? 'btncolorprim'
														: 'btnwhite'
												} btncard move`}
												onClick={() => {
													this.props.updateMasterTabCategory(
														'categories_spares',
														'subCategory'
													);
												}}
											>
												Sub-category
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>
						{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
						'category' ? (
							<div
								className='border-box d-flex f-justifybetween'
								style={{ height: 'auto', overflow: 'visible' }}
							>
								<div style={{ height: 'auto', overflow: 'visible' }}>
									{/* <div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													{this.state.segmentsList && (
														<MultiSelect
															options={this.state.segmentsList}
															labelKey='name'
															checkbox_id='_id'
															placeholder='Segment'
															disabledItems={[]}
															selectedOptions={{ _id: this.state.filter_key }}
															onChange={(e) => {
																this.setState({ filter_key: e._id });
															}}
														/>
													)}
												</td>
											</tr>
										</table>
									</div> */}

									{/* <div className='blackspan65 weight700 floatleft'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<button
														id='filterbtn'
														className='btn btn_small btncolorprim btncard move'
														onClick={() =>
															this.props.filterFunc(
																this.state.filter_key,
																'categories_spares'
															)
														}
													>
														Filter
													</button>
												</td>
											</tr>
										</table>
									</div> */}
									{_.get(this.props, ['category'], []).length ? (
										<>
											<div
												className='blackspan65 weight700 floatleft'
												style={{ height: 'auto', overflow: 'visible' }}
											>
												<table className='stdtable' style={{ height: 'auto' }}>
													<tr>
														<td valign='center'>
															{_.get(this.props, ['category'], []).length ? (
																<MultiSelect
																	options={this.props.category}
																	labelKey='slug'
																	checkbox_id='slug'
																	placeholder='Category'
																	disabledItems={[]}
																	selectedOptions={{
																		slug: this.state.category_filter_key,
																	}}
																	onChange={(e) => {
																		this.setState({
																			category_filter_key: e.slug,
																		});
																	}}
																/>
															) : null}
														</td>
													</tr>
												</table>
											</div>
											<div className='blackspan65 weight700 floatleft'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<button
																id='filterbtn'
																className='btn btn_small btncolorprim btncard move'
																onClick={() =>
																	this.props.filterCategory(
																		this.state.category_filter_key
																	)
																}
															>
																Filter
															</button>
														</td>
													</tr>
												</table>
											</div>
										</>
									) : null}
								</div>
								{/* <div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesCatgeory}
												>
													Sync Category
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesSegment}
												>
													Sync Segment
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Category
												</button>
											</td>
										</tr>
									</table>
								</div> */}
							</div>
						) : null}
					</div>
				</div>
				<div className='middle border-box clearfix leftalign' style={{paddingTop:".7rem"}}>
					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
					{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
					'segment' ? (
						<>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
						</>
					) : null}
					{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
					'subCategory' ? (
						<>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>SubCategory Id</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
						</>
					) : null}
					{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
					'category' ? (
						<>
							{' '}
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Segment</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Visible</td>
									</tr>
								</table>
							</div>
						</>
					) : null}

					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	};

	isValidDiscount = (discount) => {
		if (Number(discount) < 0 || Number(discount) > 100) return false;
		return true;
	};

	updateSubCategoryDiscount = () => {
		let obj={
			_id:this.state.selected_sub_category._id,
			default_discount:this.state.selected_category_discount.new_discount?this.state.selected_category_discount.new_discount:0
		}

		if (!this.isValidDiscount(obj.default_discount)) {
			NotificationManager.error(
				'Discount should be in range of 0 to 100',
				'Invalid Discount!!',
				5000
			);
			return;
		}
		API.updateSparesSubCategory(obj)
			.then((res) =>
				NotificationManager.success('SubCategory updated.', 'Success', 5000)
			)
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	updateBrandDiscount = () => {
		let {
			_id,
			sku_brand = '',
		} = this.state.selected_brand;
		let{new_discount: default_discount = 0}=this.state.selected_brand_discount

		if (!this.isValidDiscount(default_discount)) {
			NotificationManager.error(
				'Discount should be in range of 0 to 100',
				'Invalid Discount!!',
				5000
			);
			return;
		}
		API.updateSparesBrand({
			_id,
			default_discount: Number(default_discount),
			sku_brand,
		})
			.then((res) =>
				NotificationManager.success('Brand updated.', 'Success', 5000)
			)
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	handleCombinationUpdate = () => {
		let sku_brand = _.get(
			this.state,
			['selectedBrandForModel', 'sku_brand'],
			''
		);
		let sub_category_name = _.get(
			this.state,
			['selected_brandSub_category', 'name'],
			''
		);
		let default_discount = _.get(this.state, ['combinationDiscount'], 0);
		if (!this.isValidDiscount(default_discount)) {
			NotificationManager.error(
				'Discount should be in range of 0 to 100',
				'Invalid Discount!!',
				5000
			);
			return;
		}
		if (
			!sku_brand ||
			!sub_category_name ||
			(default_discount !== 0 && !default_discount)
		) {
			NotificationManager.error(
				'All fields are necessary (Brand, Category and Discount)',
				'Error!!',
				5000
			);
			return;
		}

		API.combinationDiscount(sub_category_name, sku_brand, Number(default_discount))
			.then((response) => {
				if (response.status === 201)
					NotificationManager.success(
						'Discount added successfully.',
						'Success',
						5000
					);
				else alert('Something went wrong on the servwr');
			})
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	fetchSegmentList = _.debounce(this.getSegmentList, 1000);
	fetchSubCategoryList = _.debounce(this.getSubCategoriesList, 1000);
	fetchCarBrands = _.debounce(this.getCarBrands, 1000);
	fetchCMSBrands = _.debounce(this.getCMSBrands, 1000);
	fetchCategoryList = _.debounce(this.getCategoryList, 1000);

	checkImageWidth = async (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const image = new Image();
				image.src = event.target.result;
				image.onload = () => {
					resolve(image.width);
					return image.width;
				};
				reader.onerror = (err) => reject(err);
			};
		});
	};

	handlePrivateFieldsNumberChange = (e) => {
		let cashback = this.state.cashback;
		let val = e.target.value;
		cashback = {
			...cashback,
			[e.target.name]: val === '' || val >= 0 ? parseInt(val) : 0,
		};
		this.setState({ cashback });
	};

	downloadPDF = (filename, data) => {
		const file = new Blob([data], {
			type: 'application/pdf',
		});
		let a = document.createElement('a');
		document.body.appendChild(a);
		a.style = 'display: none';
		let url = window.URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	handleUpdateCashback = () => {
		if (parseInt(this.state.cashback.limit) < 1) {
			NotificationManager.error(
				'Quanity value should be greater than 1',
				'Error!!',
				5000
			);
			return;
		}
		if (this.state.cashback.min_cashback > this.state.cashback.max_cashback) {
			NotificationManager.error(
				'Min Cashback should be smaller than Max Cashback',
				'Error!!',
				5000
			);
			return;
		}
		API.updatePrivateLevelCashback(this.state.cashback)
			.then((res) => {
				NotificationManager.success('', 'Success', 5000);
				if (
					!_.get(res, ['data', 'data'], null) &&
					_.get(res, ['data', 'message'], null)
				) {
					NotificationManager.error(res.data.message, 'Error!!', 5000);
					return;
				}
				try {
					this.downloadPDF(
						`sku_code_${this.state.cashback.sku_code}`,
						res.data
					);
				} catch (err) {
					NotificationManager.error(
						'Something is wrong with the PDF',
						'Error!!',
						5000
					);
				}
			})
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	onBadgeMouseDown = (event, params) => {
		event.preventDefault(); // prevent selection start (browser action)
		let root = this;
		let slider = document.getElementById('part-img');
		let thumb = document.getElementById(params.badgeId);
		let shiftX = event.pageX - thumb.getBoundingClientRect().left;
		let shiftY = event.pageY - thumb.getBoundingClientRect().top;

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);

		function onMouseMove(event) {
			let newLeft = event.pageX - shiftX - slider.getBoundingClientRect().left;
			let newTop = event.pageY - shiftY - slider.getBoundingClientRect().top;
			// the pointer is out of slider => lock the thumb within the bounaries
			if (newLeft < 0) {
				newLeft = 0;
			}
			if (newTop < 0) {
				newTop = 0;
			}

			let rightEdge = slider.offsetWidth - thumb.offsetWidth;
			let bottomEdge = slider.offsetHeight - thumb.offsetHeight;

			if (newTop > bottomEdge) {
				newTop = bottomEdge;
			}
			if (newLeft > rightEdge) {
				newLeft = rightEdge;
			}
			let imageDim = document
				.getElementById('parentDiv')
				.getBoundingClientRect();

			newTop = (newTop / imageDim.height) * 100;
			newLeft = (newLeft / imageDim.width) * 100;

			thumb.style.left = newLeft + '%';
			thumb.style.top = newTop + '%';
		}

		function onMouseUp() {
			let left = '' + thumb.style.left;
			left = Number(left.slice(0, -1));
			let top = '' + thumb.style.top;
			top = Number(top.slice(0, -1));

			let categoryPayload = {
				...root.state.categoryPayload,
			};
			let coordinates = categoryPayload[params.productId].coordinates;
			coordinates.splice(params.index, 1, [top, left]);
			categoryPayload[params.productId] = {
				figNum: categoryPayload[params.productId].figNum,
				coordinates,
			};
			root.setState({
				categoryPayload: categoryPayload,
			});

			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('mousemove', onMouseMove);
		}
	};
	render() {
		switch (this.props.tag) {

			case 'main_banner':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='banner-tab-container '>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'web' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'web'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										onClick={() => {
											this.callSparesMainBannerList('web');
										}}
									>
										Desktop
									</button>
								</div>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'app' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'app'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										onClick={() => {
											this.callSparesMainBannerList('app');
										}}
									>
										Mobile
									</button>
								</div>
							</div>
							<div
								className='blackspan65 weight700 floatright'
								style={{ overflow: 'visible', marginTop: '12px' }}
							>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
											{this.state.loader?
											<ButtonLoader width={'7rem'}/>:	
											<button
											className='btn btn_small btncolorprim btncard move'
											onClick={this.syncSparesBanners}>
												Sync Banners
											</button>}
											
											</td>
										</tr>
									</table>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div
										type='submit'
										className='btn btnwhite btncard move'
										onClick={this.addBannerHnadler}
									>
										ADD NEW BANNER
									</div>
								</div>
							</div>
							{this.state.bannerType === 'web' ? (
								<div className='bannerSize'>Banner Size should be: 943*384</div>
							) : (
								<div className='bannerSize'>
									Banner Size should be: 1020*495
								</div>
							)}
						</div>
						<div
							className='middle border-box clearfix leftalign'
							style={{ top: '180px' }}
						>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Visible</td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
        case 'trending_banner':
          return (
            <React.Fragment>
              <div className='top border-box clearfix leftalign'>
                <div className='banner-tab-container '>
                  <div
                    className={`blackspan65 weight700 floatright height-100 ${
                      this.state.bannerType === 'web' && 'active-banner-tab'
                    }`}
                  >
                    <button
                      id='mapleadbtn'
                      style={{ padding: '0.5rem 1rem' }}
                      className={
                        this.state.bannerType === 'web'
                          ? 'btn btn_small btncolorprim btncard move'
                          : 'btn btn_small btncard move'
                      }
                      onClick={() => {
                        this.callSparesTrendingBannerList('web');
                      }}
                    >
                      Desktop
                    </button>
                  </div>
                  <div
                    className={`blackspan65 weight700 floatright height-100 ${
                      this.state.bannerType === 'app' && 'active-banner-tab'
                    }`}
                  >
                    <button
                      id='mapleadbtn'
                      style={{ padding: '0.5rem 1rem' }}
                      className={
                        this.state.bannerType === 'app'
                          ? 'btn btn_small btncolorprim btncard move'
                          : 'btn btn_small btncard move'
                      }
                      onClick={() => {
                        this.callSparesTrendingBannerList('app');
                      }}
                    >
                      Mobile
                    </button>
                  </div>
                </div>
                <div
                  className='blackspan65 weight700 floatright'
                  style={{ overflow: 'visible', marginTop: '12px' }}
                >
                  <div className='blackspan65 weight700 floatleft'>
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>
						{this.state.loader?
						<ButtonLoader width={'7rem'}/>:	
						<button
						className='btn btn_small btncolorprim btncard move'
						onClick={this.syncSparesBanners}>
							Sync Banners
						</button>}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      type='submit'
                      className='btn btnwhite btncard move'
                      onClick={this.addBannerHnadler}
                    >
                      ADD NEW BANNER
                    </div>
                  </div>
                </div>
                {this.state.bannerType === 'web' ? (
                  <div className='bannerSize'>Banner Size should be: 943*384</div>
                ) : (
                  <div className='bannerSize'>
                    Banner Size should be: 1020*495
                  </div>
                )}
              </div>
              <div
                className='middle border-box clearfix leftalign'
                style={{ top: '180px' }}
              >
                <div className='border-box blackspan65 weight700 dpmboxes1'>
                  <table className='stdtable'>
                    <tr>
                      <td valign='center'></td>
                    </tr>
                  </table>
                </div>
                <div className='border-box blackspan65 weight700 dpm1boxes15'>
                  <table className='stdtable'>
                    <tr>
                      <td valign='center'>Name</td>
                    </tr>
                  </table>
                </div>
                <div className='border-box blackspan65 weight700 dpm1boxes20'>
                  <table className='stdtable'>
                    <tr>
                      <td valign='center'>Image</td>
                    </tr>
                  </table>
                </div>
                <div className='border-box blackspan65 weight700 dpm1boxes15'>
                  <table className='stdtable'>
                    <tr>
                      <td valign='center'>Order</td>
                    </tr>
                  </table>
                </div>
                <div className='border-box blackspan65 weight700 dpm1boxes15'>
                  <table className='stdtable'>
                    <tr>
                      <td valign='center'>Is Visible</td>
                    </tr>
                  </table>
                </div>
              </div>
            </React.Fragment>
          );
          case 'tyre_banner':
            return (
              <React.Fragment>
                <div className='top border-box clearfix leftalign'>
                  <div className='banner-tab-container '>
                    <div
                      className={`blackspan65 weight700 floatright height-100 ${
                        this.state.bannerType === 'web' && 'active-banner-tab'
                      }`}
                    >
                      <button
                        id='mapleadbtn'
                        style={{ padding: '0.5rem 1rem' }}
                        className={
                          this.state.bannerType === 'web'
                            ? 'btn btn_small btncolorprim btncard move'
                            : 'btn btn_small btncard move'
                        }
                        onClick={() => {
                          this.callSparesTyreBannerList('web');
                        }}
                      >
                        Desktop
                      </button>
                    </div>
                    <div
                      className={`blackspan65 weight700 floatright height-100 ${
                        this.state.bannerType === 'app' && 'active-banner-tab'
                      }`}
                    >
                      <button
                        id='mapleadbtn'
                        style={{ padding: '0.5rem 1rem' }}
                        className={
                          this.state.bannerType === 'app'
                            ? 'btn btn_small btncolorprim btncard move'
                            : 'btn btn_small btncard move'
                        }
                        onClick={() => {
                          this.callSparesTyreBannerList('app');
                        }}
                      >
                        Mobile
                      </button>
                    </div>
                  </div>
                  <div
                    className='blackspan65 weight700 floatright'
                    style={{ overflow: 'visible', marginTop: '12px' }}
                  >
                    <div className='blackspan65 weight700 floatleft'>
                      <table className='stdtable'>
                        <tr>
                          <td valign='center'>
                          {this.state.loader?
							<ButtonLoader width={'7rem'}/>:	
							<button
							className='btn btn_small btncolorprim btncard move'
							onClick={this.syncSparesBanners}>
								Sync Banners
							</button>}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <div
                        type='submit'
                        className='btn btnwhite btncard move'
                        onClick={this.addBannerHnadler}
                      >
                        ADD NEW BANNER
                      </div>
                    </div>
                  </div>
                  {this.state.bannerType === 'web' ? (
                    <div className='bannerSize'>Banner Size should be: 943*384</div>
                  ) : (
                    <div className='bannerSize'>
                      Banner Size should be: 1020*495
                    </div>
                  )}
                </div>
                <div
                  className='middle border-box clearfix leftalign'
                  style={{ top: '180px' }}
                >
                  <div className='border-box blackspan65 weight700 dpmboxes1'>
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'></td>
                      </tr>
                    </table>
                  </div>
                  <div className='border-box blackspan65 weight700 dpm1boxes15'>
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>Name</td>
                      </tr>
                    </table>
                  </div>
                  <div className='border-box blackspan65 weight700 dpm1boxes20'>
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>Image</td>
                      </tr>
                    </table>
                  </div>
                  <div className='border-box blackspan65 weight700 dpm1boxes15'>
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>Order</td>
                      </tr>
                    </table>
                  </div>
                  <div className='border-box blackspan65 weight700 dpm1boxes15'>
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>Is Visible</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </React.Fragment>
            );

			default:
				return null;
		}
	}
}

export default MiddleUpper;

