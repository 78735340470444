import React, { Fragment, useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import API from '../api'
import MultipleSelection from '../broadcast/MultiSelect/MultiSelect'
import OverlayLoader from '../common/OverlayLoader'

const ServiceInstallation = (props) => {
  const { handleButtonClick, productDetail = {}, type, pageRefresh } = props
  const [installationServiceObj, setInstallationServiceObj] = useState({
    applicable_pincodes: [],
    sku_code: null,
    name: null,
    price: 0,
    mrp: 0,
    gst_rate: 0,
    city: null,
    state: null,
    pincode: [],
  })

  const [pincodeList, setPincodeList] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const mandateFields = [
    { key: 'sku_code', err_msg: 'Service SKU Code' },
    { key: 'name', err_msg: 'Service Name' },
    { key: 'price', err_msg: 'Price' },
    { key: 'mrp', err_msg: 'MRP' },
    { key: 'gst_rate', err_msg: 'GST Rate' },
    { key: 'applicable_pincodes', err_msg: 'Applicable pincodes' },
  ]

  const onInputChange = (e, fieldName, type) => {
    const temp = { ...installationServiceObj }
    if (type === 'number') {
      temp[fieldName] = Number(e.target.value)
    } else {
      temp[fieldName] = e.target.value
    }
    setInstallationServiceObj(temp)
  }

  const getPincodeList = (data) => {
    setShowLoader(true)
    API.getAccessoriesPincodes(data, true)
      .then((response) => {
        const pincode = []
        response.data.data.map((item, index) => {
          pincode.push({ id: index + 1, name: item.toString() })
        })
        setShowLoader(false)
        setPincodeList(pincode)
      })
      .catch((err) => {
        console.error(err)
        setShowLoader(false)
      })
  }

  const getStateList = () => {
    API.getAccessoriesStates(true)
      .then((response) => {
        const state = []
        response.data.data.map((item, index) => {
          state.push({ id: index + 1, name: item })
        })
        setStateList(state)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const getCitiesList = (value) => {
    setShowLoader(true)
    API.getAccessoriesCities(value, true)
      .then((response) => {
        const city = []
        response.data.data.map((item, index) => {
          city.push({ id: index + 1, name: item })
        })
        setCityList(city)
        setShowLoader(false)
      })
      .catch((err) => {
        console.error(err)
        setShowLoader(false)
      })
  }

  const saveInstallationService = () => {
    const errStr = mandateFields.find(
      (item) => !installationServiceObj[item.key]
    )
    if (errStr) {
      NotificationManager.error(
        `${errStr.err_msg} is required.`,
        'Error!!',
        5000
      )
      return
    }

    if (
      Number(installationServiceObj.mrp) <= Number(installationServiceObj.price)
    ) {
      NotificationManager.error('MRP should be > Price.', 'Error!!', 5000)
      return
    }

    if (installationServiceObj.applicable_pincodes.length < 1) {
      NotificationManager.error(
        `Applicable Pincodes is required.`,
        'Error!!',
        5000
      )
      return
    }
    const data = { ...installationServiceObj }
    data.applicable_pincodes = data.applicable_pincodes.map((item) =>
      Number(item.pincode)
    )

    if (type !== 'edit') {
      data.product_id = productDetail.product_id
      API.createAccProductInstallationService(data, true)
        .then(() => {
          pageRefresh()
          handleButtonClick()
          NotificationManager.success(
            'Installation Service updated.',
            'Success',
            5000
          )
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : 'error occured!',
            'Error!!',
            5000
          )
          console.error(error)
        })
    } else {
      API.updateAccProductInstallationService(data, true)
        .then(() => {
          pageRefresh()
          handleButtonClick()
          NotificationManager.success(
            'Installation Service updated.',
            'Success',
            5000
          )
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : 'error occured!',
            'Error!!',
            5000
          )
          console.error(error)
        })
    }
  }

  const onChangeRemoveClick = (index) => {
    let temp = installationServiceObj.applicable_pincodes
    temp.splice(index, 1)
    setInstallationServiceObj({
      ...installationServiceObj,
      applicable_pincodes: temp,
    })
  }

  const addPincode = () => {
    const temp = installationServiceObj.applicable_pincodes
    installationServiceObj.pincode.map((item) => {
      temp.push({
        state: installationServiceObj.state,
        city_name: installationServiceObj.city,
        pincode: item.name,
      })
    })
    setInstallationServiceObj({
      ...installationServiceObj,
      state: null,
      city: null,
      pincode: [],
      applicable_pincodes: temp,
    })
  }

  useEffect(() => {
    getStateList()

    if (type === 'edit') {
      const temp = { ...productDetail }

      setInstallationServiceObj({ ...temp, pincode: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showLoader ? <OverlayLoader /> : null}
      <div id='dashboardprimeextra' className='border-box bgwhite1 shadow-box'>
        <div className='extramainpanel border-box leftalign'>
          <div className='border-box clearfix title'>
            <div
              className='border-box floatleft leftalign blackspan2 weight900 shadow-text'
              style={{ padding: '0px 0px 0px 10px' }}
            >
              {type === 'edit'
                ? 'Edit Installation Service'
                : 'Add Installation Service'}
            </div>
            <div className='border-box floatright rightalign'>
              <button
                className='btn btn_small btncolorprim btncard move btncloseextra'
                onClick={() => handleButtonClick('close')}
              >
                Close
              </button>
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Service SKU<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Service sku'
                className='field border-box small'
                value={installationServiceObj.sku_code}
                readOnly={type === 'edit'}
                onChange={(e) => onInputChange(e, 'sku_code', 'string')}
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Service Name<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Service Name'
                value={installationServiceObj.name}
                className='field border-box small'
                onChange={(e) => onInputChange(e, 'name', 'string')}
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Price<sup>*</sup>
              </div>
              <input
                type='number'
                min='0'
                placeholder='Price'
                value={installationServiceObj.price}
                className='field border-box small'
                onChange={(e) => onInputChange(e, 'price', 'number')}
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                MRP<sup>*</sup>
              </div>
              <input
                type='number'
                min='0'
                placeholder='MRP'
                value={installationServiceObj.mrp}
                className='field border-box small'
                onChange={(e) => onInputChange(e, 'mrp', 'number')}
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                GST Rate<sup>*</sup>
              </div>
              <input
                type='number'
                min='0'
                placeholder='GST Rate'
                value={installationServiceObj.gst_rate}
                className='field border-box small'
                onChange={(e) => onInputChange(e, 'gst_rate', 'number')}
              />
            </div>
          </div>

          <div className='border-box fields'>
            <div className='border-box field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                State
              </div>
              {stateList && (
                <MultipleSelection
                  options={stateList}
                  labelKey='name'
                  checkbox_id='name'
                  placeholder='State'
                  disabledItems={[]}
                  hideOnBlur
                  selectedOptions={{
                    name: installationServiceObj.state,
                  }}
                  onChange={(e) => {
                    setInstallationServiceObj({
                      ...installationServiceObj,
                      state: e.name,
                    })
                    getCitiesList(e.name)
                  }}
                />
              )}
            </div>
            <div className='border-box field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                City
              </div>
              {cityList && (
                <MultipleSelection
                  options={cityList}
                  labelKey='name'
                  checkbox_id='name'
                  placeholder='City'
                  disabledItems={[]}
                  hideOnBlur
                  selectedOptions={{
                    name: installationServiceObj.city,
                  }}
                  onChange={(e) => {
                    setInstallationServiceObj({
                      ...installationServiceObj,
                      city: e.name,
                    })
                    getPincodeList(e.name)
                  }}
                />
              )}
            </div>
          </div>

          <div className='border-box fields'>
            <div className='border-box field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Pincodes
              </div>
              {pincodeList && (
                <MultipleSelection
                  options={pincodeList}
                  labelKey='name'
                  checkbox_id='name'
                  placeholder='Pincodes'
                  disabledItems={[]}
                  hideOnBlur
                  multiple
                  selectedOptions={installationServiceObj.pincode}
                  onChange={(e) => {
                    setInstallationServiceObj({
                      ...installationServiceObj,
                      pincode: e,
                    })
                    // // console.log(e)
                  }}
                />
              )}
            </div>
            <div className='border-box field2 floatright rightalign'>
              <button
                className='btn btn_small btncolorprim btncard move btncloseextra'
                onClick={addPincode}
                style={{ marginTop: '50px' }}
              >
                Add Pincode
              </button>
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Applicable Pincodes<sup>*</sup>
            </div>
            <div className='middle border-box clearfix leftalign'>
              <div
                className='border-box blackspan65 weight700 dpm1boxes20'
                style={{ display: 'inline-block' }}
              >
                <table className='stdtable'>
                  <tr>
                    <td valign='center'>State</td>
                  </tr>
                </table>
              </div>
              <div
                className='border-box blackspan65 weight700 dpm1boxes20'
                style={{ display: 'inline-block' }}
              >
                <table className='stdtable'>
                  <tr>
                    <td valign='center'>City</td>
                  </tr>
                </table>
              </div>
              <div
                className='border-box blackspan65 weight700 dpm1boxes20'
                style={{ display: 'inline-block' }}
              >
                <table className='stdtable'>
                  <tr>
                    <td valign='center'>Pincode</td>
                  </tr>
                </table>
              </div>
              <div
                className='border-box blackspan65 weight700 dpm1boxes15'
                style={{ display: 'inline-block' }}
              >
                <table className='stdtable'>
                  <tr>
                    <td valign='center'>Remove</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className='border-box clearfix fields'>
            {installationServiceObj.applicable_pincodes &&
              installationServiceObj.applicable_pincodes.map((item, index) => (
                <Fragment>
                  <div className='middle border-box clearfix leftalign'>
                    <div
                      className='textsize12 border-box weight500 dpm1boxes20'
                      style={{ display: 'inline-block' }}
                    >
                      <table className='stdtable'>
                        <tr>
                          <td valign='center'>{item.state}</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className='textsize12 border-box weight500 dpm1boxes20'
                      style={{ display: 'inline-block' }}
                    >
                      <table
                        className='stdtable'
                        style={{ textTransform: 'uppercase' }}
                      >
                        <tr>
                          <td valign='center'>{item.city_name}</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className='textsize12 border-box weight500 dpm1boxes20'
                      style={{ display: 'inline-block' }}
                    >
                      <table className='stdtable colorprim500span'>
                        <tr>
                          <td valign='center'>{item.pincode}</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className='textsize12 border-box weight500 dpm1boxes15'
                      style={{ display: 'inline-block' }}
                    >
                      <table className='stdtable'>
                        <tr>
                          <td valign='center'>
                            <span
                              style={{
                                display: 'inline-block',
                                padding: '0px 5px',
                                background: '#ccc',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                onChangeRemoveClick(index)
                              }}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </Fragment>
              ))}
          </div>

          <div className='border-box clearfix footer'>
            <div className='border-box floatright rightalign'>
              <button
                className='btn btn_small btncolorprim btncard move btncloseextra'
                onClick={saveInstallationService}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceInstallation
