import React from 'react';
import "../dashboard/dashboard.css"
import $ from 'jquery';

import PanelLeft from './leftpanel';
import PanelRight from './rightpanel';
import Row from './row';
import FilterBox from './filterbox';
import MiddleUpper from './middleupper';
import MiddleMain from './middlemain';
import MiddleExtra from './middleextra';
import Loader from '../loader';

import API from '../api';
import Constants from '../constants';
import {Redirect} from 'react-router-dom';
import firebase from '../firebase';

const axios = require('axios');



// const tabList = [{tag:"content_website" , title : "Website content"}, {tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];











class PanelMiddle extends React.Component {
  constructor(props) {
    super(props);
   	this.state = {showLoader : false};
	this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
	  var that = this;
	  that.setState({showLoader : true});
	  	var file = e.target.files[0];
		// console.log("file", file);
		var storageRef = firebase.storage().ref();
		var fileRef = storageRef.child('policies/' + file.name);
		fileRef.put(file).then(function(snapshot) {
			// console.log('Uploaded a blob or file!', snapshot);
			fileRef.getDownloadURL().then(function(url) {
				// console.log("url", url);
				API.insurancePolicyUpload(url, file.name).then(function (response) {
					// console.log(response.data, response.message, response.status);
					that.props.onFileUploaded(response.data);
					})
					.catch(function (error) {
					// handle error
						// console.log(error);
						that.props.onFileUploaded({status : "Failure", message : "Internal Server Error", data : {extraction : "failure"}});
					})
					.finally(function () {
					// always executed
						that.setState({showLoader : false});
					});
			  }).catch(function(error) {
				// Handle any errors
			  });
			
		});
		
		
  }

  render() {
	// console.log(this.props.tag);
	if(this.state.showLoader){
		// if(true){
		return (
			<div id="dashboardmainmiddleloader" className="bgwhite centeralign">
			<Loader showLoader = {this.state.showLoader}/>
			</div>
		);
	}else{
		// // console.log("extra1", this.props.middleExtraContent);
		return (
			<div id="dashboardmainmiddle" className="bgwhite leftalign">
			<div id="dashboardprime" className="leftalign">
				<input type="file" onChange={this.onChange} />
			</div>
			</div>
		);
	}
  }
}













class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		status : "logged in",
    	rightContent: NaN,
    	middleContent: NaN,
    	showMiddleContent: false,
		showRightContent: false,
		middleExtraContent : NaN,
		selectedTab: 'policy',
		selectedRow: "",
		filter_showing : false,
		filter_args : undefined
    };

    this.handleTabClick = this.handleTabClick.bind(this);
	this.handleRowClick = this.handleRowClick.bind(this);
	this.handleFilterClick = this.handleFilterClick.bind(this);
	this.handleAddClick = this.handleAddClick.bind(this);
	this.handleFileUploaded = this.handleFileUploaded.bind(this);
	this.handleRightButtonClick = this.handleRightButtonClick.bind(this);
	this.handleMiddleExtraButtonClick = this.handleMiddleExtraButtonClick.bind(this);
  }

  handleFilterClick() {
	this.setState({filter_showing : !this.state.filter_showing});
  }

  handleAddClick(tag) {
	this.setState({middleExtraContent: {type : "add", tag : tag, row : null, content : null}});
  }

  handleFileUploaded(content) {
	  
	this.setState({selectedRow : 1, showRightContent : true, rightContent : content});
	//this.setState({filter_showing : !this.state.filter_showing});
  }

  handleSearch1Click(text) {
	// console.log("searching");
	// console.log(text);
	API.tabSearch1Call(this.state.selectedTab, this, text);
	// this.setState({selectedRow : data.order_id, showRightContent : true, rightContent : data});
}

  handleRowClick(data, tag) {
	// console.log(data);
	// console.log(tag);
	// if(tag == "page_path_mapping")
	// 	this.setState({selectedRow : data.id, showRightContent : true, rightContent : data});
	// else

	if(tag == "products"){
		this.setState({selectedRow : data.product_id, showRightContent : true, rightContent : data});
	}else{
		this.setState({selectedRow : data._id, showRightContent : true, rightContent : data});
	}
		
  }

  handleRightButtonClick(type, tag, row, content) {
	// console.log(type, tag, row, content);
	this.setState({middleExtraContent: {type : type, tag : tag, row : row, content : content}});
  }

  handleMiddleExtraButtonClick(type, content, row) {
	// // console.log(type, tag, row, content);
	if (type == "save"){
		// var middleContent = this.state.middleContent;
		// let obj = middleContent.find(o => o.id === row);
		// if(obj != null){
		// 	var indexObj = middleContent.indexOf(obj);
			
			
		// 	middleContent[indexObj] = content;
		// }
		
		// this.setState({middleExtraContent: NaN, middleContent : middleContent, rightContent: content});

		this.setState({showMiddleContent: false, showRightContent : false, rightContent: NaN, middleExtraContent: NaN});

		API.tabClickCallSpares(this.state.selectedTab, this);
	}else{
		this.setState({middleExtraContent: NaN});
	}
  }

  handleTabClick(tag) {
  	var that = this;

  	that.setState({selectedTab : tag, showMiddleContent: false, showRightContent : false, rightContent: NaN});

	API.tabClickCallSpares(tag, that);
  }

  componentWillMount() {
	  this.setState({"showLoader" : false});
	
}

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
	return (
		<div id="containermainouter1">
			<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedRow={this.state.selectedRow} onRightButtonClick={this.handleRightButtonClick}/>
			<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
			<PanelMiddle tag={this.state.selectedTab} filterShowing={this.state.filter_showing} selectedRow={this.state.selectedRow} filterArgs={this.state.filter_args} showLoader={this.state.showLoader} 
				extraContent={this.state.middleExtraContent} onMiddleExtraButtonClick={this.handleMiddleExtraButtonClick} onAddClick={this.handleAddClick}
				content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick} onFilterClick={this.handleFilterClick} onFileUploaded={this.handleFileUploaded} />
		</div>
	);
  }
}


export default Dashboard;
