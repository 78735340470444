import React from 'react';
import API from '../api';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
// import "./dashboard/dashboard.css"

import Constants from '../constants';

class Row extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			_id: this.props.data._id ? this.props.data._id : null,
			payment_methods: this.props.data.name ? this.props.data.name : null,
			isVisible: this.props.data.isVisible,
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleChangeForPayments = this.handleChangeForPayments.bind(this);
	}

	handleClick(e) {
		// console.log('clicking row ', this.props.data)
		this.props.onRowClick(this.props.data, this.props.tag);
		/*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
	}

	handleChangeForPayments(e, id, name, visible) {
		// console.log("30000", id, name, visible);
		// method.is_enabled = isVisible;
		this.setState({ isVisible: e.target.checked });
		let obj = {
			_id: id,
			method_name: name,
			is_visible: visible,
		};

		// console.log("4555"+JSON.stringify(obj));

		API.sparesPaymentToggel(obj)
			.then(function (response) {
				if (response.data.status) {
					// that.props.onMiddleExtraButtonClick(
					//     type,
					//     that.state,
					//     that.props.selectedRow
					// );
					NotificationManager.success('Updated', 'Successfully', 3000);
				} else {
				}
			})
			.catch(function (error) {
				// that.props.onMiddleExtraButtonClick(
				//     "close",
				//     that.state,
				//     that.props.selectedRow
				// );
				NotificationManager.error('An Error Occured', 'Error', 3000);
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	getBrandsRows = () => {
		return (
			<div
				className={
					this.props.selectedRow === this.props.data._id
						? 'boxes products border-box clearfix leftalign blackspan9 selected'
						: 'boxes products border-box clearfix leftalign blackspan9'
				}
				onClick={this.handleClick}
			>
				<div className='textsize12 border-box weight500 dpm1boxes3'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>&#9679;</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table
						className='stdtable colorprim500span'
						style={{ textTransform: 'uppercase' }}
					>
						<tr>
							<td valign='center'>{this.props.data.slug}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.brand_name}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.sku_brand}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.default_discount}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								{this.props.data.is_visible ? 'Yes' : 'No'}
							</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_spare ? 'Yes' : 'No'}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_oem ? 'Yes' : 'No'}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_oes ? 'Yes' : 'No'}</td>
						</tr>
					</table>
				</div>
			</div>
		);
	};

	getVehicleRows = () => {
		return (
			<div
				className={
					this.props.selectedRow === this.props.data._id
						? 'boxes products border-box clearfix leftalign blackspan9 selected'
						: 'boxes products border-box clearfix leftalign blackspan9'
				}
				onClick={this.handleClick}
			>
				<div className='textsize12 border-box weight500 dpm1boxes3'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>&#9679;</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table
						className='stdtable colorprim500span'
						style={{ textTransform: 'uppercase' }}
					>
						<tr>
							<td valign='center'>{this.props.data.make}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table
						className='stdtable colorprim500span'
						style={{ textTransform: 'uppercase' }}
					>
						<tr>
							<td valign='center'>{this.props.data.model}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes35'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.varient}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.fuel_engine}</td>
						</tr>
					</table>
				</div>
			</div>
		);
	};

	getCategoryRows = () => {
		if (
			_.get(this.props, ['masterTabCategory', 'subCategory'], '') === 'segment'
		) {
			return (
				<div
					className={
						this.props.selectedRow === this.props.data._id
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					// onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center'>{this.props.data.slug}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.order}</td>
							</tr>
						</table>
					</div>
				</div>
			);
		} else if (
			_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
			'subCategory'
		) {
			return (
				<div
					className={
						this.props.selectedRow === this.props.data._id
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					// onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.category_name}</td>
							</tr>
						</table>
					</div>
				</div>
			);
		} else
			return (
				<div
					className={
						this.props.selectedRow === this.props.data._id
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center'>{this.props.data.slug}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.display_name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.segment_name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									{this.props.data.is_visible ? 'Yes' : 'No'}
								</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									{this.props.data.updated_at
										? new Date(this.props.data.updated_at).toLocaleString()
										: 'NA'}
								</td>
							</tr>
						</table>
					</div>

					{/* <div className="textsize12 border-box weight500 dpm1boxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.segment_name}</td></tr></table></div>
                        <div className="textsize12 border-box weight500 dpm1boxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.price}</td></tr></table></div>
                        <div className="textsize12 border-box weight500 dpm1boxes2"><table className="stdtable"><tr><td valign="center"></td></tr></table></div> */}
				</div>
			);
	};

	render() {
		// console.log(this.props.tag, "this.props ", this.props);
		switch (this.props.tag) {
			case 'segments':
				var statuses = JSON.parse(localStorage.getItem('statuses'));
				// var filteredstatuses = statuses.filter(o => (o.status_type == this.props.data.order_type && o.id == this.props.data.status_id));
				// var status = "NA";
				// if(filteredstatuses.length > 0){
				//     status = filteredstatuses[0].status;
				// }

				return (
					<div
						className={
							this.props.selectedRow === this.props.data.uuid
								? 'boxes segments border-box clearfix leftalign blackspan9 selected'
								: 'boxes segments border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes25'>
							<table
								className='stdtable colorprim500span'
								style={{
									textTransform: 'uppercase',
									wordWrap: 'break-word',
								}}
							>
								<tr>
									<td valign='center'>{this.props.data._id}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.slug}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.meta_description}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes10'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.page_name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.page_title}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes2'>
							<table className='stdtable'>
								<tr>
									<td valign='center'></td>
								</tr>
							</table>
						</div>
					</div>
				);
				case 'HOMEPAGE':
					// console.log(this.props.tag)
					return (
					<div
						className={
							this.props.selectedRow === this.props.data.id
								? 'boxes categories border-box clearfix leftalign blackspan9 selected'
								: 'boxes categories border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes25'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data.heading}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes35'>
					<table className='stdtable'>
					{this.props.data.image?<img id="myImg" src={this.props.data.image} alt="" style={{ objectFit: "contain",width: "100%",height:70,border:"none"}} />:null}
					</table>
				</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									{/* <td valign='center'>{this.props.data?this.props.data.order:''}</td> */}
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes70'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.url}</td>
								</tr>
							</table>
						</div>
						{/* <div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.end_time}</td>
								</tr>
							</table>
						</div> */}
					</div>
				);
			case 'delivery':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.pincode
								? 'boxes categories border-box clearfix leftalign blackspan9 selected'
								: 'boxes categories border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes25'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data.pincode}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.cod}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.prepaid}</td>
								</tr>
							</table>
						</div>
					</div>
				);
			case 'categories':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.uuid
								? 'boxes categories border-box clearfix leftalign blackspan9 selected'
								: 'boxes categories border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes25'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data._id}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.segment_name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes10'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>
										{this.props.data.star_category == 1 ? 'Yes' : 'No'}
									</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.page_name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.page_title}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes2'>
							<table className='stdtable'>
								<tr>
									<td valign='center'></td>
								</tr>
							</table>
						</div>
					</div>
				);
			// case 'products':
			// 	return (
			// 		<div
			// 			className={
			// 				this.props.selectedRow === this.props.data.sku_code
			// 					? 'boxes products border-box clearfix leftalign blackspan9 selected'
			// 					: 'boxes products border-box clearfix leftalign blackspan9'
			// 			}
			// 			onClick={this.handleClick}
			// 		>
			// 			<div className='textsize12 border-box weight500 dpm1boxes3'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>&#9679;</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes15'>
			// 				<table
			// 					className='stdtable colorprim500span'
			// 					style={{ textTransform: 'uppercase' }}
			// 				>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.sku_code}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes30'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.sku_desc}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes10'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.mrp}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes10'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.tax}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes10'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.price}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes20'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>
			// 							{new Date(this.props.data.updated_at).toLocaleString()}
			// 						</td>
			// 					</tr>
			// 				</table>
			// 			</div>

			// 			{/* <div className="textsize12 border-box weight500 dpm1boxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.segment_name}</td></tr></table></div>
			//                   <div className="textsize12 border-box weight500 dpm1boxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.price}</td></tr></table></div>
			//                   <div className="textsize12 border-box weight500 dpm1boxes2"><table className="stdtable"><tr><td valign="center"></td></tr></table></div> */}
			// 		</div>
			// 	);
			case 'pricing':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.sku_code
								? 'boxes products border-box clearfix leftalign blackspan9 selected'
								: 'boxes products border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data.sku_code}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data.product_id}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes35'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.sku_desc}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>
										{new Date(this.props.data.updated_at).toLocaleString()}
									</td>
								</tr>
							</table>
						</div>
					</div>
				);

			case 'master': {
				switch (this.props.masterTabCategory.category) {
					case 'brands_spares':
						return this.getBrandsRows();
					case 'categories_spares':
						return this.getCategoryRows();
					case 'vehicles':
						return this.getVehicleRows();
					default:
						return this.getBrandsRows();
				}
				break;
			}
			// case 'content_brand': {
			// 	return this.getBrandsRows()
			
			// 	break;
			// }
			// case 'content_brandVehicle': {
			// 	return this.getBrandsRows()
			
			// 	break;
			// }
			
			// case 'content_brandSegment': {
			// 	return this.getBrandsRows()
			// 	break;
			// }
			// case 'content_brandSubCategory': {
			// 	return this.getBrandsRows()
			// 	break;
			// }
			case 'categories_spares':
				return this.getCategoryRows();

			case 'brands_spares':
				return this.getBrandsRows();

			case 'vehicles':
				return this.getVehicleRows();

			case 'brands':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.uuid
								? 'boxes orders border-box clearfix leftalign blackspan9 selected'
								: 'boxes orders border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes45'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data._id}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes40'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes10'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.brand_code}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes2'>
							<table className='stdtable'>
								<tr>
									<td valign='center'></td>
								</tr>
							</table>
						</div>
					</div>
				);
			case 'testimonials':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.uuid
								? 'boxes orders border-box clearfix leftalign blackspan9 selected'
								: 'boxes orders border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
						data-page-id={this.props.data.page_id}
						data-page-type={this.props.data.page_type}
						data-page-path={this.props.data.page_path}
						data-content={this.props.data.content}
						data-heading={this.props.data.heading}
						data-content-heading={this.props.data.content_heading}
						data-title={this.props.data.title}
					>
						<div className='textsize12 border-box weight500 dpmboxes1'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						{/* <div className="textsize12 border-box weight500 dpmboxes26"><table className="stdtable colorprim500span" style={{textTransform:'uppercase'}}><tr><td valign="center">{this.props.data.page_id}</td></tr></table></div> */}
						<div className='textsize12 border-box weight500 dpmboxes8'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.ppm.page_type}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes12'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.ppm.page_path}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes8'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.customer_name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.heading}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.content}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes8'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.source}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes9'>
							<table className='stdtable'>
								<tr>
									<td valign='center'></td>
								</tr>
							</table>
						</div>
					</div>
				);
			case 'garages':
				return (
					<div
						className='boxes garages border-box clearfix leftalign blackspan9'
						onClick={this.handleClick}
						data-id={this.props.data.id}
						data-url={this.props.data.url}
						data-name={this.props.data.name}
						data-location_id={this.props.data.location_id}
						data-description={this.props.data.description}
						data-key_assistant_manager_name={
							this.props.data.key_assistant_manager_name
						}
						data-key_assistant_manager_number={
							this.props.data.key_assistant_manager_number
						}
						data-key_assistant_manager_email={
							this.props.data.key_assistant_manager_email
						}
						data-primary_contact_name={this.props.data.primary_contact_name}
						data-primary_contact_number={this.props.data.primary_contact_number}
						data-primary_contact_email={this.props.data.primary_contact_email}
						data-secondary_contact_name={this.props.data.secondary_contact_name}
						data-secondary_contact_number={
							this.props.data.secondary_contact_number
						}
						data-secondary_contact_email={
							this.props.data.secondary_contact_email
						}
						data-location={this.props.data.location}
						data-country={this.props.data.country}
						data-created_at={this.props.data.created_at}
						data-is_demo={this.props.data.is_demo}
						data-updated_at={this.props.data.updated_at}
						data-billing_name={this.props.data.billing_name}
						data-billing_title={this.props.data.billing_title}
						data-billing_vat={this.props.data.billing_vat}
						data-billing_service_tax={this.props.data.billing_service_tax}
						data-billing_address={this.props.data.billing_address}
						data-pan={this.props.data.pan}
						data-tin={this.props.data.tin}
						data-seller_address={this.props.data.seller_address}
						data-seller_tin={this.props.data.seller_tin}
						data-seller_gstin={this.props.data.seller_gstin}
						data-apply_avat={this.props.data.apply_avat}
						data-fleet_type={this.props.data.fleet_type}
						data-fleet_logo={this.props.data.fleet_logo}
						data-state_code={this.props.data.state_code}
						data-billing_manager={this.props.data.billing_manager}
						data-seller_address_id={this.props.data.seller_address_id}
						data-fleet_deal={this.props.data.fleet_deal}
						data-billing_mgr={this.props.data.billing_mgr}
						data-tech_mgr={this.props.data.tech_mgr}
						data-key_mgr={this.props.data.key_mgr}
					>
						<div className='textsize12 border-box weight500 dpmboxes1'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes26'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data.id}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes10'>
							<table className='stdtable colorprim500span'>
								<tr>
									<td valign='center'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes8'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.garage_code}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes10'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.location}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes10'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.garage_contact}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes10'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>{this.props.data.display_name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpmboxes9'>
							<table className='stdtable'>
								<tr>
									<td valign='center'></td>
								</tr>
							</table>
						</div>
					</div>
				);
			// case 'content_home':
			// 	return (
			// 		<div
			// 			className={
			// 				this.props.selectedRow === this.props.data.sku_code
			// 					? 'boxes products border-box clearfix leftalign blackspan9 selected'
			// 					: 'boxes products border-box clearfix leftalign blackspan9'
			// 			}
			// 			onClick={this.handleClick}
			// 		>
			// 			<div className='textsize12 border-box weight500 dpm1boxes3'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>&#9679;</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes15'>
			// 				<table
			// 					className='stdtable colorprim500span'
			// 					style={{ textTransform: 'uppercase' }}
			// 				>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.name}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes35'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>
			// 							<img
			// 								src={this.props.data.image_url}
			// 								height='100'
			// 								width='100'
			// 								alt='img'
			// 							/>
			// 						</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes15'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>{this.props.data.order}</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='textsize12 border-box weight500 dpm1boxes15'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>
			// 							{this.props.data.is_visible ? 'True' : 'False'}
			// 						</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 		</div>
			// 	);

			case 'spares_payment':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data._id
								? 'boxes products border-box clearfix leftalign blackspan9 selected'
								: 'boxes products border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes35'>
							<table className='stdtable'>
								<tr>
									<td valign='center'>
										<div style={{ width: '25%' }}>
											<input
												type='checkbox'
												className='toggle-switch-checkbox'
												name='toggleSwitchLater'
												id={this.props.data._id + this.props.data.name}
												onChange={(e) =>
													this.handleChangeForPayments(
														e,
														this.props.data._id,
														this.props.data.name,
														!this.props.data.is_visible
													)
												}
												defaultChecked={this.props.data.is_visible}
												checked={
													this.state.isVisible
													// .isPayLater
												}
											/>
											<label
												className='toggle-switch-label'
												htmlFor={this.props.data._id + this.props.data.name}
											>
												<span className='toggle-switch-inner' />
												{/* <span className="toggle-switch-switch" /> */}
											</label>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				);
			default:
				return null;
		}
	}
}

export default Row;
