import React from 'react';
import API from '../api';
import MultiSelect from '../broadcast/MultiSelect/MultiSelect';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Constants from '../constants';
import axios from 'axios';
import CarImage from '../image.jpg';
import './middleupper.css';
import _ from 'lodash';
import ButtonLoader from "./../ButtonLoader/ButtonLoader";
import template from './WalletSampleCsv/customer.csv'
import { data } from 'jquery';
import HomepageHeadings from '../SparesContent/HomepageHeadings';
// import { data } from 'jquery'
// import "./dashboard/dashboard.css"
class MiddleUpper extends React.Component {
	constructor(props) {
		super(props);
		this.brand = React.createRef();
		this.sub_category = React.createRef();
		this.state = {
			selectedProductDetail:null,
			masterTabCategory: 'Brand',
			selectedPage: null,
			downloadablePages: null,
			searchTerm: [],
			count:0,
			searchedItem: [],
			discountSearch: '',
			defaultDiscounts: {},
			discountValue: 0,
			selectedBrand: null,
			isDefaultSuccess: false,
			showSaveForDiscount: false,
			brands: [],
			selectedBrandForModel: [],
			modelsForBrand: [],
			selectedModel: [],
			selectedFuelType:'',
			varientType:[],
			year_range:'',
			selectedVarientType:'',
			handleAddCategory: false,
			newCategory: '',
			bannerType: 'web',
			bannerFile: '',
			addNewBrands: false,
			newDiscountValue: 0,
			selectedClass: '',
			selectedClass1: '',
			newBrandName: '',
			mobile: '9566212403',
			segmentsList: [],
			dataUploaded:false,
			filter_key: null,
			brandsList: [],
			subCategoryList: [],
			badges: [],
			parts: [],
			categoryPayload: {},
			downloadOptions: [
				{ name: 'Brand wise' },
				{ name: 'Category wise' },
				{name: 'Vehicle wise'},
				{name: 'Subcategory wise'}
			],
			downloadOption: '',
			attributesList: [
				{
					name: 'select all',
					id: 'select-all',
				},
				{
					name: 'sku_code',
					id: 'sku_code',
				},
				{
					name: 'segment_name',
					id: 'segment_name',
				},
				{
					name: 'sku_brand',
					id: 'sku_brand',
				},
				{
					name: 'sku_category',
					id: 'sku_category',
				},
				{
					name: 'sku_desc',
					id: 'sku_desc',
				},
				{
					name: 'title',
					id: 'title',
				},
				{
					name: 'price',
					id: 'price',
				},
				{
					name: 'mrp',
					id: 'mrp',
				},
				{
					name: 'tax',
					id: 'tax',
				},
				{
					name: 'hsn_code',
					id: 'hsn_code',
				},
				{
					name: 'compatibility_group_id',
					id: 'compatibility_group_id',
				},
				{
					name: 'sku_code_search',
					id: 'sku_code_search',
				},
				{
					name: 'product_id',
					id: 'product_id',
				},
				{
					name: 'is_visible',
					id: 'is_visible',
				},
				{
					name: 'oem_alternatives',
					id: 'oem_alternatives',
				},
				{
					name: 'oes_alternatives',
					id: 'oes_alternatives',
				},
				{
					name: 'sku_class',
					id: 'sku_class',
				},
				{
					name: 'is_duplicate',
					id: 'is_duplicate',
				},
				{
					name: 'image_count',
					id: 'image_count',
				},
				{
					name: 'sub_category_name',
					id: 'sub_category_name',
				},
				{
					name: 'instock',
					id: 'instock',
				},
				{
					name: 'image_url',
					id: 'image_url',
				},
				{
					name: 'specifications',
					id: 'specifications',
				},
			],
			selectedAttributesList: [],
			cmsBrandList: [],
			cashback: { limit: '', min_cashback: '', max_cashback: '', sku_code: '' },
			cashbackTab : 'cashback',
			tech_specs_batchId: '',
			tech_specs_data: [],
			selected_brandSub_category:null,
			selected_brand_radio:{},
			selected_subCat_radio:null,
			selected_sub_ids:[],
			searched_brand:'',
			searched_subcategory:'',
			expandedBrand:false,
			expanded_subCategory:false,
			checkout_data:{
				payment_method:{
					enum_number:2,
					method_name:"cod",
					is_visible:true
				}
			},
			middleContent:this.props.tag==="checkout"?this.props.content:[],
			loader:false,
			loader_1:false,
			loader_2:false,
			fetched:false
		};
		this.handleFilterClick = this.handleFilterClick.bind(this);
		this.handleSummaryClick = this.handleSummaryClick.bind(this);
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleSearchClickVehicle = this.handleSearchClickVehicle.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.onFormSubmitPricing = this.onFormSubmitPricing.bind(this);
		this.onFormSubmitPincode = this.onFormSubmitPincode.bind(this);
		this.onChange = this.onChange.bind(this);
		this.fileUpload = this.fileUpload.bind(this);
		this.fileUploadPricing = this.fileUploadPricing.bind(this);
		this.fileUploadDelivery = this.fileUploadDelivery.bind(this);
		this.downloadExcel = this.downloadExcel.bind(this);
		this.downloadExcelPricing = this.downloadExcelPricing.bind(this);
		this.downloadExcelCompatibility =
			this.downloadExcelCompatibility.bind(this);
		// this.uploadCompatibilityFile = this.uploadCompatibilityFile.bind(this)
		this.downloadCustomersDiscount = this.downloadCustomersDiscount.bind(this);
		this.downloadSampleDeliveryExcel =
			this.downloadSampleDeliveryExcel.bind(this);
		this.fileUploadCompatibility = this.fileUploadCompatibility.bind(this);
		this.onFormSubmitCompatibility = this.onFormSubmitCompatibility.bind(this);
		this.getLineItems = this.getLineItems.bind(this);
		this.getLineItemsSearch = this.getLineItemsSearch.bind(this);
		this.debounceData = this.debounceData.bind(this);
		this.handleSearchDefault = this.handleSearchDefault.bind(this);
		this.brandSelectValue = this.brandSelectValue.bind(this);
		this.updateDefaultDiscount = this.updateDefaultDiscount.bind(this);
		this.onChangeBanner = this.onChangeBanner.bind(this);
		this.onFormBannerSubmit = this.onFormBannerSubmit.bind(this);
		this.fileUploadBanner = this.fileUploadBanner.bind(this);
		this.addBannerHnadler = this.addBannerHnadler.bind(this);
		this.callSparesBannerList = this.callSparesBannerList.bind(this);
		this.deleteSelectedBrand = this.deleteSelectedBrand.bind(this);
		this.handleChangeForClass = this.handleChangeForClass.bind(this);
		this.syncSparesBanners = this.syncSparesBanners.bind(this);
		this.handleCategoryBrandDiscount =
			this.handleCategoryBrandDiscount.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.tag !== this.props.tag)
			this.setState({
				cashback: {
					limit: '',
					min_cashback: '',
					max_cashback: '',
					sku_code: '',
				},
				selected_brand: null,
				selected_category_discount: null,
				selected_brand_discount: null,
				combinationDisount: 0,
				selected_year_range: '',
				masterTabCategory: 'Brand',
				downloadOption: {},
				selected_download_category: null,
				selected_download_segment: null,
				selected_download_subcategory: null,
				image_file: null,
				image_url: null,
				family: null,
				selected_segment: null,
				selected_category: null,
				selected_sub_category: null,
				selectedAttributesList: [],
				selectedRow: '',
				file: null,
				categoryPayload: {},
				parts: null,
				epcData: null,
				// selectedBrandForModel: null,
				selectedModel: null,
				selected_varient: null,
				selected_category: null,
				// selected_sub_category: null,
				search:''
			});
	}
	componentWillMount(){
		if(this.props.tag=='checkout'){
			this.getSubCategoriesList();
		}
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick = (event) => {
		if (this.brand &&  this.brand.current && !this.brand.current.contains(event.target)) {
		 this.setState({expandedBrand:false})
		}
		if (this.sub_category &&  this.sub_category.current && !this.sub_category.current.contains(event.target)) {
			this.setState({expanded_subCategory:false})
		   }
	}

	getCMSBrands = () => {
		API.getCMSBrands()
			.then((response) => {
				this.setState({
					middleContent: response.data.data,
				});
			})
			.catch((error) => {
				console.log(error.response);
				// handle error
				// console.log(error)
			});
	};
	handleButtonClick =(type)=> {
		this.props.onRightButtonClick(
			type,
			this.props.tag,
			this.props.selectedRow,
			this.props.content
		);
	}

	syncSparesBanners() {
		this.setState({loader:true})
		API.syncSparesBanners().then(() => {
			this.setState({loader:false})
			NotificationManager.success('Banners Synced.', 'Success', 3000)
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Banners not Synced", 'Error', 5000)
			this.setState({loader:false})
		})
	}
	handleCategoryBrandDiscount() {
		this.props.onCombinationDiscount(!this.props.showCombinationDiscount);
	}
	async downloadCustomersDiscount() {
		const csvData = await API.downloadCustomersDiscount();
		// return;
		if (csvData.data) {
			// const csvDaTa = await csvData.text();
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `customers_discounts_data.csv`;
			a.click();
		} else alert('No Data');
	}

	getCarBrands() {
		axios
			.get(Constants.getSparesBaseURL() + 'car-brands/')
			.then((data) => {
				var brands = [];
				brands = [
					// { id: "select", name: "Select Brands" },
					...data.data.data,
				];
				this.setState({ brands: brands }, () => {});
			})
			.catch((e) => console.log('error ', e));
	}

	afterFilterChangeMake(field) {
		field = field || null;
		if (field == 'brand') {
			// this.brandWiseModel();
			axios
				.get(
					Constants.getSparesBaseURL() +
						// 'car-models/?source=cms&car_brand_id=' +
						'vehicles-cms/?varient=0&source=cms&make=' +
						this.state.selectedBrandForModel.name
				)
				.then((data) => {
					var models = [];
					models = [...data.data.data];
					models = _.uniqBy(models, (item) => item.model);
					this.setState({ modelsForBrand: models }, () => {});
				})
				.catch((e) => console.log('error ', e));
		}
	}

	addBannerHnadler() {
		this.props.handleAddClickSpares(this.props.tag, this.state.bannerType);
	}

	handleFilterClick() {
		this.props.onFilterClick();
	}

	callSparesBannerList(source) {
		this.setState({ bannerType: source });
		this.props.changeStateBanner('spares_banner', this, source);
	}

	handleSearchClick() {
		if (this.state.search != null) {
			this.props.onSearch1Click(this.state.search);
		} else {
			NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			);
		}
	}

	async downloadCustomersDiscount() {
		const csvData = await API.customersDiscountDownload();
		// return;
		if (csvData.data) {
			// const csvDaTa = await csvData.text();
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `customers_discounts_data.csv`;
			a.click();
		} else alert('No Data');
	}
	async downloadSampleDeliveryExcel() {
		const csvData = await API.getSampleDeliveryExcel();
		if (csvData.data) {
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `sample_pincodes.csv`;
			a.click();
		} else alert('No data');
	}

	handleSearchClickVehicle() {
		this.props.tag === 'master' ?this.state.selectedBrandForModel.name != null ?
			this.props.onSearch1Click(
				this.state.selectedBrandForModel.name,
				this.state.selectedModel.model
			):NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			)
			:this.state.selectedBrandForModel.name != null && this.state.selectedModel.model ?	this.props.onSearch1Click(
				this.state.selectedBrandForModel.name,
				this.state.selectedModel.model
			):NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			)
	}

	handleAddNewVariant() {
		if (
			this.state.selectedBrandForModel.name != null &&
			this.state.selectedModel.name
		) {
			this.props.onAddNewVariant(
				this.props.tag,
				this.state.selectedBrandForModel.name,
				this.state.selectedModel.name
			);
		} else {
			NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			);
		}
	}

	async handleSearchDefault() {
		let response = await API.getDefaultDiscounts();

		this.setState({
			defaultDiscounts: response.data.data,
			isDefaultSuccess: true,
		});
	}

	brandSelectValue(value) {
		this.setState({ selectedBrand: value });
		this.setState(
			{
				discountValue: this.state.defaultDiscounts[value],
			},
			() => {
				console.log('this.state ', this.state);
			}
		);
	}

	handleChangeForDiscount(type, e) {
		let obje = this.state;
		obje[type] = e.target.value;
		this.setState(obje);
		if (type !== 'newDiscountValue')
			this.setState({ discountValue: e.target.value });
	}

	updateDefaultDiscount() {
		const newObj = {};
		newObj['brand'] = this.state.selectedBrand;
		newObj['default_discount'] = this.state.discountValue;
		newObj[this.state.selectedBrand] = this.state.discountValue;
		API.updateDiscountDefault(newObj)
			.then(() => {
				NotificationManager.success('Discount Updated', 'Success', 3000);
			})
			.catch(() => alert('Error'));
	}

	deleteSelectedBrand() {
		const newObj = {};
		newObj['brand_name'] = this.state.selectedBrand;
		API.deleteDiscountBrand(newObj)
			.then(() => {
				NotificationManager.success('Deleted', 'Successfully', 3000);
			})
			.catch(() => alert('Error'));
	}

	handleChangeForClass(type, e) {
		let obje = this.state;
		obje[type] = e.target.value;
		// this.setState({ discountValue: e.target.value });
		this.setState(obje);
	}

	handleButtonClickAddNewBrand() {
		const newObj = {
			sku_brand: this.state.newBrandName,
			is_spare: 0,
			is_oem: 0,
			is_lubricant: 0,
			is_tyre: 0,
			is_oes: 0,
			is_aftermarket: 0,
			is_battery: 0,
			default_discount: parseInt(this.state.newDiscountValue),
			brand_name: this.state.newBrandName,
		};

		newObj[this.state.selectedClass] = 1;
		newObj[this.state.selectedClass1] = 1;

		API.addNewBrand(newObj)
			.then(() => {
				NotificationManager.success('Discount Updated', 'Success', 3000);
			})
			.catch(() => alert('Error'));
	}

	handleChange(type, e) {
		if (/^$|[A-Z0-9]/i.test(e.target.value)) {
			let obj = this.state;
			obj[type] = e.target.value;
			this.setState(obj);
			console.log(this.state);
		} else {
			return;
		}
	}

	handleSummaryClick() {}

	handleAddClick() {
		this.props.onAddClick(this.props.tag);
	}

	onChange(e) {
		let file = e.target.files[0];
		// if (
		// 	!file.type.match('.csv') &&
		// 	!file.type.match(
		// 		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		// 	)
		// ) {
		// 	NotificationManager.error('Please Choose an Excel File', 'Error', 3000);
		// 	return;
		// } else 
		if(!file){
			NotificationManager.error('Please Choose a File', 'Error', 3000);
			return
		}
		else{
			this.setState({ file: e.target.files[0] });
		}
	}

	onChangeBanner(e) {
		let file = e.target.files[0];
		if (!file.type.match('image/*')) {
			NotificationManager.error('Please Choose an image File', 'Error', 3000);
			return;
		} else this.setState({ bannerFile: e.target.files[0] });
	}

	onFormSubmit(e) {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			this.fileUpload(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 2000);
					setTimeout(() => {
						if(response.data && response.data.data){ 
						var el=document.getElementById('partBulkUpload');
						el.href='https://spares-mp.gomechanic.app/error-csv/?request_id='+response.data.data;
						window.open(el.href);
						NotificationManager.success(response.data.message, 'Success', 3000);
					}
					}, 2000);
					
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
		}
	}

	onEPCCSVSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadEPCCSV(this.state.file)
				.then((response) => {
					console.log(response.data);
					let currProd = {};
					_.get(response, ['data', 'data'], []).forEach((item) => {
						currProd = {
							...currProd,
							[item.product_id]: { figNum: item.index },
						};
					});

					this.setState({
						categoryPayload: currProd,
						parts: response.data.data,
					});
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	onDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	
	onSubCategroyDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadSubCategoryBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	onBrandSubCategroyDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadBrandSubCategoryBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	
	onCustomerDiscountBulkSubmit = (e) => {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.uploadCustomerBulkDiscount(this.state.file)
				.then(() => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	onFormBannerSubmit(e) {
		e.preventDefault();
		if (!this.state.bannerFile)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else
			this.fileUploadBanner(this.state.bannerFile)
				.then((response) => {
					console.log(response.data);
					NotificationManager.success('File Uploaded', 'Success', 3000);
					window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
	}
	onFormSubmitPincode(e) {
		e.preventDefault();
		if (!this.state.file)
			NotificationManager.error(
				'Choose A File Before Uploading',
				'Error',
				3000
			);
		else {
			this.fileUploadDelivery(this.state.file)
				.then((res) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((error) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
				});
		}
	}

	uploadCategoryFile = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			API.bulkUploadCategorization(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
					// window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e.response);
				});
		}
	};

	onFormSubmitPricing(e) {
		e.preventDefault(); // Stop form submit
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
			this.fileUploadPricing(this.state.file)
				.then((response) => {
					console.log(response.data);
					NotificationManager.success('File Uploaded', 'Success', 3000);
					// window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
		}
	}

	uploadSpecificationCSV = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileUploadSpecification(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};
	
	uploadAlternativesCSV = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileUploadAlternatives(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};

	deleteMappedAlternativesCSV = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileDeleteMappedAlternatives(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};

	deleteAllAlternativesCSV = (e) => {
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileDeleteAllAlternatives(this.state.file)
				.then((response) => {
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
					NotificationManager.error(
						`${_.get(
							e,
							['response', 'data', 'message'],
							'Invalid file format'
						)}`,
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
	};

	onFormSubmitCompatibility(e) {
		e.preventDefault(); // Stop form submit
		if (!this.state.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				5000
			);
		else {
			this.fileUploadCompatibility(this.state.file)
				.then((response) => {
					console.log(response.data);
					NotificationManager.success('File Uploaded', 'Success', 3000);
					window.location.reload(false);
				})
				.catch((e) => {
					NotificationManager.error('Invalid File Format', 'Error', 3000);
					console.log('error ', e);
				});
		}
	}

	fileUploadAlternatives(file) {
		return API.uploadAlternativesCSV(file);
	}

	fileDeleteMappedAlternatives(file) {
		return API.deleteMappedAlternativesCSV(file);
	}

	fileDeleteAllAlternatives(file) {
		return API.deleteAllAlternativesCSV(file);
	}

	fileUploadSpecification(file) {
		return API.uploadSpecificationCSV(file);
	}

	fileUploadCompatibility(file) {
		return API.uploadSparesProductsCompatibility(file);
	}

	fileUpload(file) {
		return API.bulkUploadSparesProducts(file);
	}

	fileUploadBanner(file) {
		return API.sparesBannerUpload(file);
	}

	fileUploadPricing(file) {
		return API.bulkUploadSparesProductsPricing(file);
	}
	fileUploadDelivery(file) {
		return API.bulkUploadSparesDelivery(file);
	}

	downloadExcel() {
		// let csvData = "Part Number,Part Description,SKU Brand,Sku Class,Category,Tax,HSN,Price,Threshold Quantity,Pack,Height,Width,Lenght,Weight";
		// const csvDaTa = csvData.text();
		// var a = document.createElement("a");
		// const blob = new Blob([csvData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		// const url = window.URL.createObjectURL(blob);
		// a.href = url;
		// a.download = `sample.xls`;
		// a.click();
		// let csvData = "https://storage.googleapis.com/spares/spares/parts_sample.xlsx";
		// var xhr = new XMLHttpRequest();
		// xhr.onreadystatechange = function () {
		//     if (this.readyState === 4 && this.status === 200) {
		//         console.log('success', this.responseText.length);
		//     }
		// };
		// xhr.open('GET', 'https://storage.googleapis.com/spares/spares/parts_sample.xlsx', true);
		// xhr.send(null);
		// let a = document.createElement("a");
		// a.download = "https://storage.googleapis.com/spares/spares/parts_sample.xlsx";
		// a.click()
	}

	async downloadExcelPricing() {
		const csvData = await API.downloadPricingExcel();
		// return;
		if (csvData.data) {
			// const csvDaTa = await csvData.text();
			var a = document.createElement('a');
			const blob = new Blob([csvData.data], {
				type: 'octet/stream',
			});
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = `excel_file.csv`;
			a.click();
		} else alert('No Data');
	}

	downloadExcelCompatibility() {
		let csvData = 'Part Number,Make,Model,Variant Year,Fuel Engine';
		var a = document.createElement('a');
		const blob = new Blob([csvData], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = `sample.xls`;
		a.click();
	}

	debounceData(fn, delay) {
		let timer = null;
		return function (...args) {
			const context = this;
			timer && clearTimeout(timer);
			timer = setTimeout(() => {
				fn.apply(context, args);
			}, delay);
		};
	}

	getLineItemsSearch = this.debounceData(this.getLineItems, 1000);

	// export default debounceData;
	getLineItems(term) {
		var that = this;

		// var delayTimer;
		if (term.length >= 3) {
			let modifiedTerm = '';
			for (var i = 0; i < term.length; i++) {
				if (term.charAt(i).match(/^[a-zA-Z0-9]/))
					modifiedTerm += term.charAt(i);
			}
			if (modifiedTerm.match(/^[a-zA-Z0-9]+$/)) {
				// clearTimeout(delayTimer);
				// delayTimer = setTimeout(() => {
				console.log('term ', term);
				API.getProductIdSuggestions(modifiedTerm).then(function (response) {
					if (response.data.data.options.length) {
						that.setState({ searchTerm: response.data.data.options }, () => {
							that.state.searchTerm.map(
								(code, id) =>
									(that.state.searchTerm[id]['sku_code'] =
										code.sku_code +
										' - ' +
										code.sku_brand +
										' - ' +
										code.product_id)
							);
						});
						// that.setState({ searchTerm: that.state.searchTerm["newOp"] = response.data.data.options })

						var obj = that.state;
						// obj["search"] = term;
						obj['search'] = that.state.searchedItem.product_id;
						that.setState(obj);
					} else NotificationManager.error('No Content', 'Error', 2000);
				});
				// }, 2000);
			} else {
				NotificationManager.error('Invalid input', 'Error', 2000);
			}
		} else {
			this.setState({ searchTerm: [] });
		}
	}
	afterVehicleFilterChange(field) {
    field = field || null;
    if (field == "brand") {
      // this.brandWiseModel();
      axios
        .get(
          Constants.getSparesBaseURL() +
            "car-models/?car_brand_id=" +
            this.state.selectedBrandForModel._id
        )
        .then((data) => {
          var models = [];
          models = [...data.data.data];
          this.setState({ modelsForBrand: models }, () => {});
        })
        .catch((e) => console.log("error ", e));
    }

    if (field == "model") {
      axios.get(`${Constants.getSparesBaseURL()}vehicles-cms/?varient=0&source=cms&make=${encodeURIComponent(this.state.selectedBrandForModel.name)}&model=${encodeURIComponent(
			this.state.selectedModel.name)}`)
      .then((response)=> {
        if (response.status === 204 || response.data.data.length == 0) {
          alert('No Content');
        } else
        this.setState({ varientType: response.data.data }, () => {});
      })
      .catch((error)=> {
        console.log(error)
      })
      .finally(function () {
        // always executed
      });
    }
  }
	afterFilterChange() {
		var that = this;
		var obj = that.state;
		obj['search'] = that.state.searchedItem.product_id;
		that.setState(obj);
		this.props.tag==='products'&& this.handleSearchClick()
	}

	handleChangeForPhone(type, e) {
		if (isNaN(Number(e.target.value))) {
			return;
		} else {
			this.setState({ mobile: e.target.value });
		}
		let obje = this.state;
		obje[type] = Number(e.target.value);
		// obje["discounts"][this.state.selectedBrand] = Number(e.target.value);
		// const newObj = {};
		// newObj["customer_id"] = this.state.customer_id;
		// newObj["discounts"] = this.state.discounts;
		// this.setState(newObj);
	}

	handleButtonClickOTP(type) {
		let that = this;
		// if (this.state.mobile.length !== 10)
		//     NotificationManager.error(
		//         "Please add a Valid Number",
		//         "Error",
		//         3000
		//     );
		// else
		API.getOTPForDiscount(this.state.mobile)
			.then(function (response) {
				if (response.data.status) {
					NotificationManager.success('OTP Sent', 'Successfully', 3000);
					// that.props.onMiddleExtraButtonClick(
					//     type,
					//     that.state,
					//     that.props.selectedRow
					// );
					that.setState({ showVerifyOTP: true });
				} else {
				}
			})
			.catch(function (error) {
				// handle error
				NotificationManager.error('An Error Occured', 'Error', 3000);
				that.props.onMiddleExtraButtonClick(
					'close',
					that.state,
					that.props.selectedRow
				);
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	handleChangeForOTP(type, e) {
		if (isNaN(Number(e.target.value))) {
			return;
		} else {
			this.setState({ otp: e.target.value });
		}
		let obje = this.state;
		obje[type] = Number(e.target.value);
		// obje["discounts"][this.state.selectedBrand] = Number(e.target.value);
		// const newObj = {};
		// newObj["customer_id"] = this.state.customer_id;
		// newObj["discounts"] = this.state.discounts;
		// this.setState(newObj);
	}

	handleButtonClickOTPVerify(type) {
		let that = this;
		API.verifyOTPForDiscount(this.state.mobile, this.state.otp)
			.then(function (response) {
				if (response.data.status) {
					NotificationManager.success('OTP Verified', 'Successfully', 3000);
					// that.props.onMiddleExtraButtonClick(
					//     type,
					//     that.state,
					//     that.props.selectedRow
					// );
					that.setState({ showSaveForDiscount: true });
				} else {
				}
			})
			.catch(function (error) {
				// handle error
				NotificationManager.error('OTP Invalid', 'Error', 3000);
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	getSampleSpecificationCSV = () => {
		const segemnt = _.get(this.state, ['selected_segment', 'name'], '');
		const category = _.get(
			this.state,
			['selected_category', 'display_name'],
			''
		);
		const subCategory = _.get(
			this.state,
			['selected_sub_category', 'name'],
			''
		);
		API.getSpecificationSampleCSV(segemnt, category, subCategory);
	};

	getSegmentList() {
		API.getSparesSegments()
			.then((result) => {
				this.setState({ segmentsList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getCategoryList(segmentId) {
		API.getCategories(segmentId)
			.then((res) => this.setState({ categoryList: res.data.data }))
			.catch((err) => {
				console.log(err);
			});
	}
	
	setToDefaultPaymentMode = () => {
		this.setState({loader:true})
		API.deleteCheckoutPaymentMode(this.state.selected_brand_radio && this.state.selected_brand_radio.sku_brand,this.state.selected_subCat_radio===null || this.state.selected_subCat_radio && this.state.selected_subCat_radio.name==="Select"?[0,...this.state.subCategoryList.map(item=>item.id)]:[this.state.selected_subCat_radio.id] || '',).then((res)=>{
		NotificationManager.success("Set to default", "Successfully", 3000);
		this.setState({loader:false,checkout_data:{...this.state.checkout_data,payment_method:{...this.state.checkout_data.payment_method,is_visible:true}}})
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Data can't be Updated", 'Error', 5000)
			this.setState({loader:false})
		})
	};

	getSubCategoriesList = () => {
		API.getSparesSubCategory()
			.then((result) => {
				this.setState({ subCategoryList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getBrandsList() {
		API.getSparesBrandsList()
			.then((result) => {
				this.setState({ brandsList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	syncSparesBrands() {
		API.syncSparesBrands()
			.then(() => {
				NotificationManager.success('Brand Sync Started.', 'Success', 5000);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	syncSparesCatgeory() {
		API.syncSparesCategories()
			.then(() => {
				NotificationManager.success(
					'Categories Sync Started.',
					'Success',
					5000
				);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	syncSparesSegment() {
		API.syncSparesSegment()
			.then(() => {
				NotificationManager.success('Segement Sync Started.', 'Success', 5000);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	syncSparesVehicle() {
		API.syncSparesCars()
			.then(() => {
				NotificationManager.success('Vehicle Sync Started.', 'Success', 5000);
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	}

	getAllParts = (make, model, varient, engine, segmentId, skuCategory) => {
		API.getAllProducts(make, model, varient, engine, segmentId, skuCategory)
			.then((res) => {
				if (!res.data.data.data || res.data.data.data.length === 0) {
					NotificationManager.error('Data not found');
				}
				this.setState((prevState) => {
					return {
						parts: res.data.data.data,
						partDetail: {
							...prevState.partDetail,
							sku_brand: _.get(
								res,
								['data', 'data', 'data', [0], 'sku_brand'],
								''
							),
						},
					};
				});
			})
			.catch((error) => {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);
				console.log(error);
			});
	};

	getEPCData = (
		make,
		model,
		varient,
		engine,
		segmentId,
		family,
		year_range
	) => {
		API.getEPCData(make, model, varient, engine, segmentId, family, year_range)
			.then((res) => {
				// const figMap = _.get(res, ['data', 'data', 'epc_data', 'fig_map'], {});
				const seq_map = _.get(res, ['data', 'data', 'epc_data', 'seq_map'], {});
				const image_url = _.get(res, ['data', 'data', 'epc_data', 'image'], '');
				const parts = _.get(
					res,
					['data', 'data', 'epc_data', 'list_parts'],
					''
				);

				// let categoryPayload = _.get(res,["data","data","epc_data","seq_map"],{});
				// let image_url = _.get(res, ["data","data","epc_data", "image_url"],'');
				this.setState({
					categoryPayload: seq_map,
					image_url,
					parts,
					image_file: null,
				});
			})
			.catch((err) => {
				// console.log(_.get(err, ["response", "data", "message"], ""));
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	addBadges = (event) => {
		if (!this.state.selectedRow || this.state.selectedRow === '') return;
		var mouseX = event.pageX;
		var mouseY = event.pageY;
		var top = mouseY - 10;
		var left = mouseX - 10;
		let imageDim = document.getElementById('part-img').getBoundingClientRect();
		top = ((top - imageDim.top) / imageDim.height) * 100;
		left = ((left - imageDim.left) / imageDim.width) * 100;
		let selectedProduct = this.state.categoryPayload[this.state.selectedRow];
		this.setState((state) => {
			return {
				...state,
				// figMap: {
				// 	..._.get(state, ['figMap'], {}),
				// 	[selectedProduct.figNum]: Array.from(
				// 		new Set([
				// 			..._.get(state, ['figMap', selectedProduct.figNum], []),
				// 			state.selectedRow,
				// 		])
				// 	),
				// },
				categoryPayload: {
					...state.categoryPayload,
					[state.selectedRow]: {
						...selectedProduct,
						coordinates: [
							..._.get(selectedProduct, ['coordinates'], []),
							[top, left],
						],
					},
				},
			};
		});
	};

	handleEPCUpload = () => {
		const brand = _.get(this.state, ['selectedBrandForModel', 'name'], null);
		const model = _.get(this.state, ['selectedModel', 'model'], null);
		const varient = _.get(this.state, ['selected_varient', 'varient'], null);
		const fuel_engine = _.get(
			this.state,
			['fuel_engine'],
			null
		);
		const year_range = _.get(this.state, ['selected_year_range'], '');
		const segmentId = _.get(this.state, ['selected_segment', '_id'], null);
		// const {name: subCategory} = this.state.selected_sub_category;
		let partDetail = {
			make: brand,
			model,
			fuel_engine,
			varient,
			year: year_range,
			// sku_category: subCategory,
			segment_name: _.get(this.state, ['selected_segment', 'name'], null),
			// sku_brand: null,
			// image_url: this.state.image_file,
			image: this.state.image_url,
			seq_map: [],
			segment_id: segmentId,
			family: this.state.family,
		};
		// this.setState({partDetail, categoryPayload: {}, file: null});

		// this.getAllParts(brand, model, varient,fuel_engine, segmentId, subCategory);
		// this.getEPCData(brand, model, varient,fuel_engine, segmentId, subCategory);
		if (
			!this.state.image_url ||
			!brand ||
			!model ||
			!varient ||
			!_.get(this.state, ['selected_segment', 'name'], null) ||
			!this.state.family ||
			year_range === '' ||
			!year_range
		) {
			NotificationManager.error('All fields are mandatory.', 'Error!!', 5000);
			return;
		}
		let payload = { ...partDetail };
		payload['seq_map'] = this.state.categoryPayload;
		// payload['fig_map'] = this.state.figMap;
		// payload["list_parts"] = Object.keys(this.state.categoryPayload);
		payload['list_parts'] = Object.keys(this.state.categoryPayload).map(
			(product_id) => {
				const partDetail =
					this.state.parts[
						_.findIndex(this.state.parts, { product_id: product_id })
					];
				return {
					sku_code: partDetail.sku_code,
					sku_brand: partDetail.sku_brand,
					title: partDetail.title,
					product_id: partDetail.product_id,
					index: partDetail.index,
				};
			}
		);
		// return;
		this.setState({dataUploaded:true})
		API.uploadEPCData(payload)
			.then(() => {
				this.setState({dataUploaded:false})
				NotificationManager.success('Uploading data.', 'Success', 5000);
				document.getElementById('myepcfile').value = '';
			})
			.catch((error) => {	
				if(error.message==="Network Error"){
				NotificationManager.error(
					'Check your Internet Connection!',
					'Error!!'
				);	
			} 
			else{
				this.setState({dataUploaded:false})
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'error occured!',
					'Error!!',
					5000
				);}
			});
	};

	handleSubCatDropdown=(value)=>{
		let temp=[...value]
		let that=this
		if(temp.find(item=>item.id==='ALL')){

			if (!that.state.selected_sub_ids.find(item=>item.id==='ALL')){
			that.setState({...that.state,selected_sub_ids: [{name:'SELECT',id:'ALL'},...that.state.subCategoryList].map((item)=>{
				return( {...item,label:item.name+'-'+item.id})
				}),default_checkout_data:{},fetched:false})
			}
			// else if(temp.length<that.state.subCategoryList.length+1){
			// 	that.setState({...that.state,selected_sub_ids:temp.splice(1),default_checkout_data:{},fetched:false})
			// }
			//to get data of all subcategories except few subCategories

		}

		else if(!temp.find(item=>item.id==="ALL") && that.state.selected_sub_ids.find(item=>item.id==="ALL")){
			that.setState({...that.state,selected_sub_ids:[],default_checkout_data:{},fetched:false})	
		}

		else{
			that.setState({...that.state,selected_sub_ids:temp,default_checkout_data:{},fetched:false})
		}
	}

	handleBrandRadioChange=(e,item)=>{
		if(e.target.checked){
			this.setState({selected_brand_radio:item,searched_brand:'',selected_sub_ids :[],expandedBrand:false,fetched:false,default_checkout_data:{},checkout_data:{...this.state.checkout_data,delivery_charge:null,payment_method:{...this.state.checkout_data.payment_method,is_visible:true}}})
		}	
	}

	customDeliveryChargeUpdate=()=>{
		let id_array=this.state.selected_sub_ids  &&this.state.selected_sub_ids.map(item=>item.id)
		let obj={
			brand:this.state.selected_brand_radio?this.state.selected_brand_radio.sku_brand:null,
			sub_category_id:(id_array &&  id_array.includes('ALL'))|| this.state.selected_sub_ids &&this.state.selected_sub_ids.length===0?[0,...this.state.subCategoryList.map(item=>item.id)] :id_array || '',
			delivery_charge:this.state.checkout_data.delivery_charge?this.state.checkout_data.delivery_charge:0,
			payment_method:this.state.checkout_data.payment_method,
		}
		// this.state.checkout_data && !this.state.checkout_data.delivery_charge>0 && delete obj.delivery_charge
			this.setState({loader_1:true})
			NotificationManager.success('Please Wait','Updating...', 5000)
		API.customDeliveryCharge(obj).then(() => {
			NotificationManager.success('Delivery Charge & Payment Option updated.', 'Success', 5000)
			this.setState({loader_1:false,fetched:false})
			this.setState({selected_brand_radio:{},selected_subCat_radio:null,selected_sub_ids:[],searched_brand:'',searched_subcategory:'',checkout_data:{},default_checkout_data:{}})
		}).catch(error=>{
			NotificationManager.error(`${error.message}`? `${error.message}`:"Data can't be Updated", 'Error', 5000)
			this.setState({loader_1:false})
		})
	}

	validateNumber=(evt)=> {
		let e = evt || window.event;
		let key = e.keyCode || e.which;
		if (!(key >= 48 && key <= 57)) {
		  e.returnValue = false;
		  if (e.preventDefault) e.preventDefault();
		}
	  }
	
	getCustomDeliveryData=()=>{
		if(_.isEmpty(this.state.selected_brand_radio)){
			NotificationManager.error(`Please select brand also`, 'Error', 3000)
			return 
		}
		else{
			let that=this
		let id_array=this.state.selected_sub_ids  &&this.state.selected_sub_ids.map(item=>item.id)
		let sub_category_id=id_array &&  id_array.includes('ALL')?'' :id_array || ''

		let sku_brand=this.state.selected_brand_radio ? this.state.selected_brand_radio.sku_brand:null
		that.setState({...that.state,loader_2:true})

		API.getCustomDeliveryData(sku_brand,sub_category_id).then((res) => {
			if(res.data.data && res.data.data.length===0){
			setTimeout(() => {
				that.setState({...that.state,fetched:true,loader_2:false})
			}, 1000);	
				NotificationManager.error('Data not found for the given brand & SubCategory.', 'Error', 3000)		
			}else{
				if(res.data.data){
					setTimeout(() => {
						this.setState({...this.state,checkout_data:{...this.state.checkout_data,...res.data.data[0]},default_checkout_data:res.data.data[0],fetched:true,loader_2:false})

					}, 1000);
				}
			}
		}).catch((error) => {
			if (error.message){
				that.setState({...that.state,loader_2:false})
			  NotificationManager.error(`${error.message}`, 'Error', 5000)
			}
			else{
			  NotificationManager.error(`Something went wrong, Please try again later`, 'Error', 3000)
			}
			that.setState({...that.state,loader_2:false})
			});
		}
	
	}

	uploadBulkWallet=(e)=>{
		e.preventDefault();
		if (!this.state.file){
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		}
		else{
			let that=this
			this.setState({loader:true})
			NotificationManager.success("Uploading...", "Please Wait", 2000);
			API.globalCustomerWallet(this.state.file).then((res)=>{
			if(res.status){
				this.setState({loader:false})
				NotificationManager.success("Updated", "Successfully", 2000);
			}
		}).catch((error) => {
			if (error.message){
			  NotificationManager.error(`${error.message}`, 'Error', 5000)
			}
			else{
			  NotificationManager.error(`Something went wrong, Please try again later`, 'Error', 3000)
			}
			that.setState({...that.state,loader:false})
			});
		}
	}

	handleSubCatRadioChange=(e,item,data)=>{
		if(e.target.checked){
			item={id:item.id,name:item.name}	
			this.setState({selected_subCat_radio:item,searched_subcategory:'',expanded_subCategory:false,fetched:false,checkout_data:{...this.state.checkout_data,delivery_charge:null,payment_method:{...this.state.checkout_data.payment_method,is_visible:true}}})
		}		
	}

	handleSubCategoryArray=(e,item,data)=>{
		let temp={...this.state}
		if(e.target.checked){
			temp.subCategoryList[temp.subCategoryList.findIndex((i) => i.id === item.id)].selected=true
			let array=[...temp.selected_sub_ids]
			temp.subCategoryList.map((data)=>{
			  if(data.selected){
				let item={
					name:data.name,
					id:data.id,
				}
				array.push(item.id)
			  }})
			
			  //To avoid duplicacy in array

			const filteredArr =[...new Set(array)]
			temp["selected_sub_ids"] = filteredArr
		}else {
			let array2=[...temp.selected_sub_ids]
			array2.splice(array2.indexOf(data.id),1)
			temp['selected_sub_ids'] = array2 
		}
		this.setState({...temp})

	}


	handleBrandRadioSearch=()=>{
		let arr=[];
		if(this.state.searched_brand && this.state.searched_brand.length){
			this.state.middleContent &&	this.state.middleContent.map(item=>{
				if(item.sku_brand && item.sku_brand.includes(this.state.searched_brand)){
					arr.push(item)
				}
			})
		}
		return (
			arr && arr.map((item)=>{
				return  (
				<li className="dropdown-check-newList">
				<label for={'radio'+item.brand_name} style={{width: "100%",boxSizing:'border-box'}}> 
				<input 
				 type="radio" 
				 id={"radio"+item.brand_name}
				 value={item.brand_name} 
				 checked={this.state.selected_brand_radio  && this.state.selected_brand_radio.brand_name==item.brand_name}
				 onChange={(e)=>this.handleBrandRadioChange(e,item) } 
				 />
				<span 
				style={this.state.selected_brand_radio  && this.state.selected_brand_radio.brand_name==item.brand_name?{color: "green",fontWeight:600,fontSize:'.75rem',paddingLeft:'8px'}:{fontSize:'.75rem',paddingLeft:'8px'}}
				>
					{item.brand_name}
				</span> 
				</label>
			</li> )
			})
		)
	}
	handleSubCatRadioSearch=()=>{
		let arr=[];
		if(this.state.searched_subcategory && this.state.searched_subcategory.length){
			this.state.subCategoryList &&	this.state.subCategoryList.map(item=>{
				if(item.name && item.name.includes(this.state.searched_subcategory)){
					arr.push(item)
				}
			})
		}
		return (
			arr && [{name:"Select",id:"All"},...arr].map((item)=>{
				return  (
					<li className="dropdown-check-newList">
					<label for={'checkbox'+data.name} style={{width: "100%",boxSizing:'border-box'}}> 
					<input 
					type="checkbox" 
					id={"checkbox"+data.name}
					value={data.name} 
					checked={this.state.selected_sub_ids && this.state.selected_sub_ids.includes(data.id)}
					onChange={(e)=>this.handleSubCategoryArray(e,item,data)}
					/>
					<span 
					style={this.state.selected_sub_ids && this.state.selected_sub_ids.id===data.id?{color: "green",fontWeight:600,fontSize:'.75rem',paddingLeft:'8px'}:{fontSize:'.75rem',paddingLeft:'8px'}}
					>
						{data.name + '-' + data.id}
					</span> 
					</label>
				</li> )
			})
		)
	}

	handleEPCImageUpload = () => {
		API.getEPCImageURL(this.state.image_file)
			.then((res) => {
				NotificationManager.success('Image uploaded.', 'Success', 3000);
				this.setState({
					image_url: _.get(res, ['data', 'data'], null),
				});
				API.getEPCTags(_.get(res, ['data', 'data'], null))
					.then((res) => {
						let response = _.get(res, ['data', 'data'], []);
						let parts = _.get(this.state, ['parts'], []);
						let categoryPayload = JSON.parse(
							JSON.stringify(this.state.categoryPayload)
						);
						response.forEach((item) => {
							let index = _.findIndex(parts, { index: item.text });
							if (index >= 0) {
								let productId = parts[index].product_id;
								categoryPayload[productId]['coordinates'] = [
									..._.get(categoryPayload, [productId, 'coordinates'], []),
									[item.center[1], item.center[0]],
								];
							}
						});
						this.setState({ categoryPayload });
					})
					.catch((err) =>
						NotificationManager.error('Auto tagging failed.', 'Error!!', 3000)
					);
			})
			.catch((err) =>
				NotificationManager.error('Failed to upload image.', 'Error!!', 3000)
			);
	};

	fetchDownloadData = (fetchPageCount = false, page = 0) => {
		let urlPath = {
			sku_brand_filter: null,
			segment_name_filter: null,
			sku_category_filter: null,
			model_filter: null,
			sub_category_name_filter: null,
		};
		let attributes = this.state.selectedAttributesList.reduce((ar, item) => {
			if (item.name !== 'select all') ar[item.name] = 1;
			return ar;
		}, {});

		switch (this.state.downloadOption.name) {
			case 'Brand wise': {
				let sku_brand = _.get(
					this.state,
					['selectedBrandForModel', 'sku_brand'],
					null
				);
				if (!sku_brand && Object.keys(attributes).length === 0) {
					NotificationManager.error(
						'All the fields can not be empty',
						'Error!!',
						5000
					);
					return;
				}
				urlPath['sku_brand_filter'] = sku_brand;
				break;
			}

			case 'Category wise': {
				let segment_name = _.get(
					this.state,
					['selected_download_segment', 'name'],
					null
				);
				let sku_category = _.get(
					this.state,
					['selected_download_category', 'name'],
					null
				);

				if (
					!segment_name &&
					!sku_category &&
					Object.keys(attributes).length === 0
				) {
					NotificationManager.error(
						'All the fields can not be empty',
						'Error!!',
						5000
					);
					return;
				}
				urlPath['segment_name_filter'] = encodeURIComponent(segment_name);
				urlPath['sku_category_filter'] = encodeURIComponent(sku_category);
				break;
			}

			case 'Vehicle wise': {
				let make=_.get(
					this.state,
					['selectedBrandForModel', 'name'],
					null
				);
				let model=_.get(
					this.state,
					['selectedModel', 'name'],
					null
				);
				let year_range=_.get(
					this.state,
					['year_range'],
					null
				);
				let fuel_engine=_.get(
					this.state,
					['selectedFuelType','fuel_engine'],
					null
				);
				let varient=_.get(
					this.state,
					['selectedVarientType','varient'],
					null
				);
				if (
					!make &&
					!model &&
					!year_range &&
					!fuel_engine &&
					!varient &&
					Object.keys(attributes).length === 0
				) {
					NotificationManager.error(
						'All the fields can not be empty',
						'Error!!',
						5000
					);
					return;
				}
			
			urlPath['make'] = encodeURIComponent(make);
			urlPath['model'] = encodeURIComponent(model);
			urlPath['year_range'] = encodeURIComponent(year_range);
			urlPath['fuel_engine'] = encodeURIComponent(fuel_engine);
			urlPath['varient'] = encodeURIComponent(varient);
			break;
			}

			case 'Subcategory wise': {
				let subCategory_name = _.get(this.state, ['selected_download_subcategory', 'name'], null);

				if (!subCategory_name && Object.keys(attributes).length === 0) {
					NotificationManager.error('All the fields can not be empty', 'Error!!', 5000);
					return;
				}
				urlPath['sub_category_name_filter'] = encodeURIComponent(subCategory_name);
				break;
			}
			default:
			break
		}

		if (fetchPageCount) {
			API.getDownloadTabPageCount(urlPath, attributes)
				.then((res) =>
					this.setState({ downloadablePages: _.get(res, ['data', 'data'], 0) })
				)
				.catch((err) =>
					NotificationManager.error(
						_.get(err, ['response', 'data', 'message'], 'Data not found.'),
						'Error!!',
						5000
					)
				);
		} else {
			API.getDownloadTabCSV(urlPath, attributes, page);
		}
	};

	zoomIn = () => {
	    this.setState({count:this.state.count+1})
		var myImg = document.getElementById('part-img');
		var currWidth = myImg.clientWidth;

		myImg.style.width = currWidth + 100 + 'px';
	};
	zoomOut = () => {
	    this.setState({count:this.state.count-1})
		var myImg = document.getElementById('part-img');
		var currWidth = myImg.clientWidth;
			myImg.style.width = currWidth - 100 + 'px';
	};
	masterTabs = () => {
		return (
			<div className='border-box d-flex mbottom-1 master-tab-container'>
				<div
					className={`blackspan65 weight700 floatleft ${
						_.get(this.props, ['masterTabCategory', 'category'], '') ===
							'brands_spares' && 'active-master-tab'
					}`}
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								<button
									className={`btn btn_small ${
										_.get(this.props, ['masterTabCategory', 'category'], '') ===
										'brands_spares'
											? 'btncolorprim'
											: 'btnwhite'
									} btncard move`}
									onClick={() => {
										this.props.updateMasterTabCategory('brands_spares');
									}}
								>
									Brands
								</button>{' '}
							</td>
						</tr>
					</table>
				</div>
				<div
					className={`blackspan65 weight700 floatleft ${
						_.get(this.props, ['masterTabCategory', 'category'], '') ===
							'categories_spares' && 'active-master-tab'
					}`}
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								<button
									className={`btn btn_small ${
										_.get(this.props, ['masterTabCategory', 'category'], '') ===
										'categories_spares'
											? 'btncolorprim'
											: 'btnwhite'
									} btncard move`}
									onClick={() => {
										this.props.updateMasterTabCategory('categories_spares');
									}}
								>
									Categories
								</button>
							</td>
						</tr>
					</table>
				</div>
				<div
					className={`blackspan65 weight700 floatleft ${
						_.get(this.props, ['masterTabCategory', 'category'], '') ===
							'vehicles' && 'active-master-tab'
					}`}
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								<button
									className={`btn btn_small ${
										_.get(this.props, ['masterTabCategory', 'category'], '') ===
										'vehicles'
											? 'btncolorprim'
											: 'btnwhite'
									} btncard move`}
									onClick={() => this.props.updateMasterTabCategory('vehicles')}
								>
									Vehicles
								</button>
							</td>
						</tr>
					</table>
				</div>
			</div>
		);
	};

	getBrandView = () => {
		if (this.state.brandsList.length < 1) {
			this.getBrandsList();
		}
		return (
			<React.Fragment>
				<div className='top border-box leftalign'>
					<div>
						{this.props.tag === 'master' ? this.masterTabs() : null}
						<div className='border-box'>
							<div
								className='blackspan65 weight700 floatleft'
								style={{ height: 'auto', overflow: 'visible' }}
							>
								<table className='stdtable' style={{ height: 'auto' }}>
									<tr>
										<td valign='center'>
											{this.state.brandsList && (
												<MultiSelect
													options={this.state.brandsList}
													labelKey='brand_name'
													checkbox_id='brand_name'
													placeholder='Brands'
													disabledItems={[]}
													selectedOptions={{
														brand_name: this.state.filter_key,
													}}
													onChange={(e) => {
														this.setState({ filter_key: e.brand_name });
													}}
												/>
											)}
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={() =>
													this.props.filterFunc(
														this.state.filter_key,
														'brands_spares'
													)
												}
											>
												Filter
											</button>
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatright'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.syncSparesBrands}
											>
												Sync Brand
											</button>
										</td>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleAddClick}
											>
												Add Brand
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className='middle border-box clearfix leftalign'>
					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Slug</td>
							</tr>
						</table>
					</div> */}
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Brand Name</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Brand SKU</td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Default Discount</td>
							</tr>
						</table>
					</div> */}
					<div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is Visible</td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is Spares</td>
							</tr>
						</table>
					</div> */}
					{/* <div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is OEM</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes10'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Is OES</td>
							</tr>
						</table>
					</div> */}
					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	};
	getVehicleView = () => {
		if (this.state.brands.length < 1) {
			this.getCarBrands();
		}
		return (
			<React.Fragment>
				<div className='top border-box leftalign'>
					<div>
						{this.props.tag === 'master' ? this.masterTabs() : null}
						{this.props.tag === 'master' ? <div className='border-box'>
							<div
								className='blackspan65 weight700 floatleft'
								style={{
									overflow: 'initial',
									height: 'auto',
								}}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ display: 'flex' }}>
											<MultiSelect
												options={this.state.brands}
												labelKey='name'
												checkbox_id='_id'
												placeholder='Select Make'
												disabledItems={[]}
												selectedOptions={this.state.selectedBrandForModel}
												onChange={(value, e) => {
													this.setState(
														{
															selectedBrandForModel: value,
														},
														// (e) => {
														// 	this.afterFilterChangeMake('brand');
													
														// }
													);
												}}
											/>
											{/* <MultiSelect
												options={this.state.modelsForBrand}
												labelKey='model'
												checkbox_id='_id'
												placeholder='Select Model'
												disabledItems={[]}
												selectedOptions={this.state.selectedModel}
												onChange={(value, e) =>
													this.setState(
														{
															selectedModel: value,
														},
														(e) => {
															// this.afterFilterChange(
															//     "model"
															// );
															// this.handleChange(
															//     "model",
															//     value.name
															// );
														}
													)
												}
											/> */}
											{/* <input
												type="text"
												class="dashboardfields dashboardchangefields"
												name="searchbysku"
												placeholder="Search Compatilbilty Group ID"
												value={this.state.search}
												style={{
													width: "270px",
													fontSize: "16px",
												}}
												onChange={(e) =>
													this.handleChange(
														"search",
														e
													)
												}
											></input> */}
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleSearchClickVehicle}
											>
												Search
											</button>
										</td>
									</tr>
								</table>
							</div>

							<div className='blackspan65 weight700 floatright'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.syncSparesVehicle}
											>
												Sync Vehicles
											</button>
										</td>
									</tr>
								</table>
							</div>

							{/* {_.get(this.state, ['selectedBrandForModel', 'name'], null) !=
								null &&
								
									<div className='border-box floatright rightalign'>
										<button
											class='btn btn_small btncolorprim btncard move btncloseextra'
											onClick={() => this.handleAddNewVariant()}
										>
											Add New Varient
										</button>
									</div>
								} */}
						</div>:<div className='border-box'>
							<div
								className='blackspan65 weight700 floatleft'
								style={{
									overflow: 'initial',
									height: 'auto',
								}}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ display: 'flex' }}>
											<MultiSelect
												options={this.state.brands}
												labelKey='name'
												checkbox_id='_id'
												placeholder='Select Make'
												disabledItems={[]}
												selectedOptions={this.state.selectedBrandForModel}
												onChange={(value, e) => {
													this.setState(
														{
															selectedBrandForModel: value,
														},
														(e) => {
															this.afterFilterChangeMake('brand');
															// this.handleChange(
															//     "make",
															//     value.name,
															//     this.state
															//         .brands
															// );
														}
													);
												}}
											/>
											<MultiSelect
												options={this.state.modelsForBrand}
												labelKey='model'
												checkbox_id='_id'
												placeholder='Select Model'
												disabledItems={[]}
												selectedOptions={this.state.selectedModel}
												onChange={(value, e) =>
													this.setState(
														{
															selectedModel: value,
														},
														(e) => {
															// this.afterFilterChange(
															//     "model"
															// );
															// this.handleChange(
															//     "model",
															//     value.name
															// );
														}
													)
												}
											/>
											{/* <input
												type="text"
												class="dashboardfields dashboardchangefields"
												name="searchbysku"
												placeholder="Search Compatilbilty Group ID"
												value={this.state.search}
												style={{
													width: "270px",
													fontSize: "16px",
												}}
												onChange={(e) =>
													this.handleChange(
														"search",
														e
													)
												}
											></input> */}
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleSearchClickVehicle}
											>
												Search
											</button>
										</td>
									</tr>
								</table>
							</div>

							<div className='blackspan65 weight700 floatright'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='addleadbtn'
												className='btn btn_small btncolorprim btncard move'
												onClick={this.syncSparesVehicle}
											>
												Sync Vehicles
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>}
					</div>
				</div>
				<div className='middle border-box clearfix leftalign'>
					<div className='border-box blackspan65 weight700 dpmboxes1'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Make</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>Model</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes35'>
						<table className='stdtable' style={{textAlign:'center'}}>
							<tr>
								<td valign='center'>Image</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan65 weight700 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>is Visbile</td>
							</tr>
						</table>
					</div>
					{/* <div className='border-box blackspan65 weight700 dpmboxes9'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div> */}
				</div>
			</React.Fragment>
		);
	};
	getCategoryView = () => {
		if (this.state.segmentsList.length < 1) {
			this.getSegmentList();
		}
		return (
			<React.Fragment>
				<div className='top border-box leftalign'>
					<div>
						{this.props.tag === 'master' ? this.masterTabs() : null}
						<div
							className='border-box left-align d-flex'
							style={{margin: "-6px 0px 7px", }}
						>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className={`btn btn_small ${
													_.get(
														this.props,
														['masterTabCategory', 'subCategory'],
														''
													) === 'segment'
														? 'btncolorprim'
														: 'btnwhite'
												} btncard move`}
												onClick={() => {
													this.props.updateMasterTabCategory(
														'categories_spares',
														'segment'
													);
												}}
											>
												Segment
											</button>
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className={`btn btn_small ${
													_.get(
														this.props,
														['masterTabCategory', 'subCategory'],
														''
													) === 'category'
														? 'btncolorprim'
														: 'btnwhite'
												} btncard move`}
												onClick={() => {
													this.props.updateMasterTabCategory(
														'categories_spares',
														'category'
													);
												}}
											>
												Category
											</button>
										</td>
									</tr>
								</table>
							</div>
							<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className={`btn btn_small ${
													_.get(
														this.props,
														['masterTabCategory', 'subCategory'],
														''
													) === 'subCategory'
														? 'btncolorprim'
														: 'btnwhite'
												} btncard move`}
												onClick={() => {
													this.props.updateMasterTabCategory(
														'categories_spares',
														'subCategory'
													);
												}}
											>
												Sub-category
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>
						{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
						'category' ? (
							<div
								className='border-box d-flex f-justifybetween'
								style={{ height: 'auto', overflow: 'visible' }}
							>
								<div style={{ height: 'auto', overflow: 'visible' }}>
									{/* <div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													{this.state.segmentsList && (
														<MultiSelect
															options={this.state.segmentsList}
															labelKey='name'
															checkbox_id='_id'
															placeholder='Segment'
															disabledItems={[]}
															selectedOptions={{ _id: this.state.filter_key }}
															onChange={(e) => {
																this.setState({ filter_key: e._id });
															}}
														/>
													)}
												</td>
											</tr>
										</table>
									</div> */}

									{/* <div className='blackspan65 weight700 floatleft'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<button
														id='filterbtn'
														className='btn btn_small btncolorprim btncard move'
														onClick={() =>
															this.props.filterFunc(
																this.state.filter_key,
																'categories_spares'
															)
														}
													>
														Filter
													</button>
												</td>
											</tr>
										</table>
									</div> */}
									{_.get(this.props, ['category'], []).length ? (
										<>
											<div
												className='blackspan65 weight700 floatleft'
												style={{ height: 'auto', overflow: 'visible' }}
											>
												<table className='stdtable' style={{ height: 'auto' }}>
													<tr>
														<td valign='center'>
															{_.get(this.props, ['category'], []).length ? (
																<MultiSelect
																	options={this.props.category}
																	labelKey='slug'
																	checkbox_id='slug'
																	placeholder='Category'
																	disabledItems={[]}
																	selectedOptions={{
																		slug: this.state.category_filter_key,
																	}}
																	onChange={(e) => {
																		this.setState({
																			category_filter_key: e.slug,
																		});
																	}}
																/>
															) : null}
														</td>
													</tr>
												</table>
											</div>
											<div className='blackspan65 weight700 floatleft'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<button
																id='filterbtn'
																className='btn btn_small btncolorprim btncard move'
																onClick={() =>
																	this.props.filterCategory(
																		this.state.category_filter_key
																	)
																}
															>
																Filter
															</button>
														</td>
													</tr>
												</table>
											</div>
										</>
									) : null}
								</div>
								{/* <div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesCatgeory}
												>
													Sync Category
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesSegment}
												>
													Sync Segment
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Category
												</button>
											</td>
										</tr>
									</table>
								</div> */}
							</div>
						) : null}
					</div>
				</div>
				<div className='middle border-box clearfix leftalign' style={{paddingTop:".7rem"}}>
					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
					{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
					'segment' ? (
						<>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
						</>
					) : null}
					{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
					'subCategory' ? (
						<>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>SubCategory Id</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
						</>
					) : null}
					{_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
					'category' ? (
						<>
							{' '}
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Segment</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Visible</td>
									</tr>
								</table>
							</div>
						</>
					) : null}

					<div className='border-box blackspan65 weight700 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'></td>
							</tr>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	};

	isValidDiscount = (discount) => {
		if (Number(discount) < 0 || Number(discount) > 100) return false;
		return true;
	};

	updateSubCategoryDiscount = () => {
		let obj={
			_id:this.state.selected_sub_category._id,
			default_discount:this.state.selected_category_discount.new_discount?this.state.selected_category_discount.new_discount:0,
			sub_category_id: this.state.selected_sub_category.id,
			sub_category_name: this.state.selected_sub_category.name,
		}

		if (!this.isValidDiscount(obj.default_discount)) {
			NotificationManager.error(
				'Discount should be in range of 0 to 100',
				'Invalid Discount!!',
				5000
			);
			return;
		}
		API.updateSparesSubCategory(obj)
			.then((res) =>
				NotificationManager.success('SubCategory updated.', 'Success', 5000)
			)
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	updateBrandDiscount = () => {
		let {
			_id,
			sku_brand = '',
		} = this.state.selected_brand;
		let{new_discount: default_discount = 0}=this.state.selected_brand_discount

		if (!this.isValidDiscount(default_discount)) {
			NotificationManager.error(
				'Discount should be in range of 0 to 100',
				'Invalid Discount!!',
				5000
			);
			return;
		}
		API.updateSparesBrandDiscount({
			_id,
			default_discount: Number(default_discount),
			sku_brand,
		})
			.then((res) =>
				NotificationManager.success('Brand updated.', 'Success', 5000)
			)
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	handleCombinationUpdate = () => {
		let sku_brand = _.get(
			this.state,
			['selectedBrandForModel', 'sku_brand'],
			''
		);
		let sub_category_name = _.get(
			this.state,
			['selected_brandSub_category', 'name'],
			''
		);
		let default_discount = _.get(this.state, ['combinationDiscount'], 0);
		if (!this.isValidDiscount(default_discount)) {
			NotificationManager.error(
				'Discount should be in range of 0 to 100',
				'Invalid Discount!!',
				5000
			);
			return;
		}
		if (
			!sku_brand ||
			!sub_category_name ||
			(default_discount !== 0 && !default_discount)
		) {
			NotificationManager.error(
				'All fields are necessary (Brand, Category and Discount)',
				'Error!!',
				5000
			);
			return;
		}

		API.combinationDiscount(sub_category_name, sku_brand, Number(default_discount))
			.then((response) => {
				if (response.status === 201)
					NotificationManager.success(
						'Discount added successfully.',
						'Success',
						5000
					);
				else alert('Something went wrong on the servwr');
			})
			.catch((err) => {
				NotificationManager.error(
					_.get(err, ['response', 'data', 'message'], ''),
					'Error!!',
					5000
				);
			});
	};

	fetchSegmentList = _.debounce(this.getSegmentList, 1000);
	fetchSubCategoryList = _.debounce(this.getSubCategoriesList, 1000);
	fetchCarBrands = _.debounce(this.getCarBrands, 1000);
	fetchCMSBrands = _.debounce(this.getCMSBrands, 1000);
	fetchCategoryList = _.debounce(this.getCategoryList, 1000);

	checkImageWidth = async (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const image = new Image();
				image.src = event.target.result;
				image.onload = () => {
					resolve(image.width);
					return image.width;
				};
				reader.onerror = (err) => reject(err);
			};
		});
	};

	handlePrivateFieldsNumberChange = (e) => {
		let cashback = this.state.cashback;
		let val = e.target.value;
		cashback = {
			...cashback,
			[e.target.name]: val === '' || val >= 0 ? parseInt(val) : 0,
		};
		this.setState({ cashback });
	};

	downloadPDF = (filename, data) => {
		const file = new Blob([data], {
			type: 'application/pdf',
		});
		let a = document.createElement('a');
		document.body.appendChild(a);
		a.style = 'display: none';
		let url = window.URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	handleUpdateCashback = () => {
		if (parseInt(this.state.cashback.limit) < 1) {
			NotificationManager.error(
				'Quanity value should be greater than 1',
				'Error!!',
				5000
			);
			return;
		}
		if (this.state.cashback.min_cashback > this.state.cashback.max_cashback) {
			NotificationManager.error(
				'Min Cashback should be smaller than Max Cashback',
				'Error!!',
				5000
			);
			return;
		}
		API.downloadPrivateLevelCashbackExcel(this.state.cashback).then((res) => {
			NotificationManager.success('', 'Success', 5000);
			if (!_.get(res, ['data', 'data'], null) && _.get(res, ['data', 'message'], null)) {
				NotificationManager.error(res.data.message, 'Error!!', 5000);
				return;
			}
			try {
				// this.downloadPDF(`sku_code_${this.state.cashback.sku_code}`, res.data);
				const csvData = res;
				if (csvData.data) {
					var a = document.createElement('a');
					const blob = new Blob([csvData.data], {
						type: 'octet/stream',
					});
					const url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = `sku_code_${this.state.cashback.sku_code}.csv`;
					a.click();
				} else alert('No data');
			} catch (err) {
				NotificationManager.error('Something is wrong with the Excel', 'Error!!', 5000);
			}
		}).catch((err) => {
			NotificationManager.error(
				_.get(err, ['response', 'data', 'message'], ''),
				'Error!!',
				5000
			);
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.searchedItem.product_id !== this.state.searchedItem.product_id) {
			this.fetchTechSpecsList();
		}
	}

	fetchTechSpecsList = () => {
		if (!this.state.searchedItem.product_id) {
			return;
		}
		API.getTechSpecsListing(this.state.searchedItem.product_id).then((res) => {
			if (res.status) {
				this.setState({ tech_specs_data: res.data.data });
				NotificationManager.success('Data Fetched Successfully', 'Success', 5000);
			}
		}).catch((err) => {
			NotificationManager.error(
				_.get(err, ['response', 'data', 'message'], ''),
				'Error!!',
				5000
			);
			this.setState({ tech_specs_data: []});
		});
	}

	convertDateInStandardFormat = (date) => {
		let format = new Date(date);
		return format.toISOString().split('T')[0];
	}

	handleUpdateTechSpecs = () => {
		if (!this.state.searchedItem.product_id) {
			NotificationManager.error('Please enter sku code', 'Error!!', 5000);
			return;
		}
		if (this.state.tech_specs_batchId.trim() == '') {
			NotificationManager.error('Please add batch id', 'Error!!', 5000);
			return;
		}
		if (!this.state.file) {
			NotificationManager.error('Please upload file in pdf format', 'Error!!', 5000);
			return;
		}
		this.setState({ loader_1: true });
		let data = {
			product_id: this.state.searchedItem.product_id,
			batch_id: this.state.tech_specs_batchId,
			pdf: this.state.file,
		}
		API.updateTechSpecs(data).then((res) => {
			if (res.status) {
				NotificationManager.success('Tech Spec Added!', 'Success', 5000);
				this.setState({ loader_1: false });
				this.fetchTechSpecsList();
			}
		}).catch((err) => {
			NotificationManager.error(
				_.get(err, ['response', 'data', 'message'], ''),
				'Error!!',
				5000
			);
			this.setState({ loader_1: false });
		});
	}

	onBadgeMouseDown = (event, params) => {
		event.preventDefault(); // prevent selection start (browser action)
		let root = this;
		let slider = document.getElementById('part-img');
		let thumb = document.getElementById(params.badgeId);
		let shiftX = event.pageX - thumb.getBoundingClientRect().left;
		let shiftY = event.pageY - thumb.getBoundingClientRect().top;

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);

		function onMouseMove(event) {
			let newLeft = event.pageX - shiftX - slider.getBoundingClientRect().left;
			let newTop = event.pageY - shiftY - slider.getBoundingClientRect().top;
			// the pointer is out of slider => lock the thumb within the bounaries
			if (newLeft < 0) {
				newLeft = 0;
			}
			if (newTop < 0) {
				newTop = 0;
			}

			let rightEdge = slider.offsetWidth - thumb.offsetWidth;
			let bottomEdge = slider.offsetHeight - thumb.offsetHeight;

			if (newTop > bottomEdge) {
				newTop = bottomEdge;
			}
			if (newLeft > rightEdge) {
				newLeft = rightEdge;
			}
			let imageDim = document
				.getElementById('parentDiv')
				.getBoundingClientRect();

			newTop = (newTop / imageDim.height) * 100;
			newLeft = (newLeft / imageDim.width) * 100;

			thumb.style.left = newLeft + '%';
			thumb.style.top = newTop + '%';
		}

		function onMouseUp() {
			let left = '' + thumb.style.left;
			left = Number(left.slice(0, -1));
			let top = '' + thumb.style.top;
			top = Number(top.slice(0, -1));

			let categoryPayload = {
				...root.state.categoryPayload,
			};
			let coordinates = categoryPayload[params.productId].coordinates;
			coordinates.splice(params.index, 1, [top, left]);
			categoryPayload[params.productId] = {
				figNum: categoryPayload[params.productId].figNum,
				coordinates,
			};
			root.setState({
				categoryPayload: categoryPayload,
			});

			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('mousemove', onMouseMove);
		}
	};
	render() {
		switch (this.props.tag) {
			case 'private_label': {
				return (
					<React.Fragment>
					  <div className='pl-cashback-Container'>
						<div 
							className='pl-cashback-tab' 
							style={this.state.cashbackTab == 'cashback' ? { background: '#ccc', cursor: 'default' } : {}} 
							onClick={() => this.setState({ cashbackTab: 'cashback' })}
						>
							Cashback
						</div>
						<div 
							className='pl-cashback-tab' 
							style={this.state.cashbackTab == 'tech_specs_table' ? { background: '#ccc', cursor: 'default' } : {}} 
							onClick={() => this.setState({ cashbackTab: 'tech_specs_table' })}
						>
							Tech Specs Upload
						</div>
					  </div>
					  {this.state.cashbackTab == 'cashback' ? (
						<div className='top border-box leftalign' style={{ position: 'unset' }}>
							<div className='d-flex private-lvl-row'>
								<div>
									<input
										type='text'
										style={{ maxHeight: '100%' }}
										className='dashboardfields dashboardchangefields margin-0'
										placeholder='Sku code'
										name='sku_code'
										value={_.get(this.state, ['cashback', 'sku_code'])}
										onChange={(e) => {
											this.setState({
												cashback: {
													...this.state.cashback,
													sku_code: e.target.value,
												},
											});
										}}
									/>
								</div>
								<div>
									<input
										type='number'
										placeholder='Quantity'
										min={0}
										name='limit'
										className='field border-box small margin-0'
										value={_.get(this.state, ['cashback', 'limit'])}
										onChange={this.handlePrivateFieldsNumberChange}
									/>
								</div>
								<div>
									<input
										type='number'
										placeholder='Min cashback'
										min={0}
										name='min_cashback'
										className='field border-box small margin-0'
										value={_.get(this.state, ['cashback', 'min_cashback'])}
										onChange={this.handlePrivateFieldsNumberChange}
									/>
								</div>
								<div>
									<input
										type='number'
										placeholder='Max cashback'
										min={0}
										name='max_cashback'
										className='field border-box small margin-0'
										value={_.get(this.state, ['cashback', 'max_cashback'])}
										onChange={this.handlePrivateFieldsNumberChange}
									/>
								</div>
								<div>
									<button
										id='filterbtn'
										className='btn btn_small btncolorprim btncard move'
										onClick={this.handleUpdateCashback}
									>
										DOWNLOAD QR
									</button>
								</div>
							</div>
						</div>
					  ) : null}
					  {this.state.cashbackTab == 'tech_specs_table' ? (
						<div className='tech-specs-container'>
							<MultiSelect
								options={this.state.searchTerm}
								labelKey='sku_code'
								checkbox_id='product_id'
								placeholder='Search'
								new_placeholder='Search Part no. (sku code)'
								disabledItems={[]}
								selectedOptions={this.state.searchedItem}
								onSearch={this.getLineItemsSearch}
								onChange={(value) => this.setState({ searchedItem: value })}
							/>
							{this.state.searchedItem.product_id ? (
								<React.Fragment>
									<div style={{ display: "grid", gap: "2rem", marginTop: "2rem" }}>
										<div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", alignItems: "center" }}>
											<div>Enter Batch Number *</div>
											<input
												type='text'
												style={{ maxHeight: '100%' }}
												className='dashboardfields dashboardchangefields margin-0'
												placeholder='Batch Number'
												name='b_number'
												value={this.state.tech_specs_batchId}
												onChange={(e) => this.setState({ tech_specs_batchId: e.target.value })}
											/>
										</div>
										<div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", alignItems: "center" }}>
											<div>Upload PDF for Specs *</div>
											<input
												type='file'
												onChange={this.onChange}
												accept="application/pdf"
											/>
										</div>
										{this.state.loader_1 ? (
											<div style={{ height: "1.8rem",width: "3.7rem", display: "flex", margin: "0 auto" }}>
												<ButtonLoader width={'100%'} height={'100%'} />
											</div>
										) : (
											<button
												type='submit'
												className='btn btn_small btnwhite btncard move'
												style={{ width: "fit-content", display: "flex", margin: "0 auto", padding: "0.4rem 1.5rem" }}
												onClick={this.handleUpdateTechSpecs}
											>
												Save
											</button>
										)}
									</div>

									{this.state.tech_specs_data.length ? (
										<div className='tech-specs-table-container'>
											<h4 className='tech-specs-table-header'>Table for Specs Upload History</h4>
											<table className='tech-specs-table'>
												<tr>
													<th>Date</th>
													<th>Batch Id</th>
													<th>PDF URL</th>
												</tr>
												{(this.state.tech_specs_data.length ? this.state.tech_specs_data : []).map((list) => (
													<tr>
														<td>{this.convertDateInStandardFormat(list.created_at)}</td>
														<td>{list.batch_id}</td>
														<td>
															<a href={list.pdf_url} target="_blank">
																{list.pdf_url}
															</a>
														</td>
													</tr>
												))}
											</table>
										</div>
									) : null}
								</React.Fragment>
							) : null}
						</div>
					  ) : null}
					</React.Fragment>
				);
			}
			case 'delivery':
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box '>
								<div
									className='blackspan65 weight700 floatleft'
									style={{
										height: 'auto',
										overflow: 'visible',
									}}
								>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<input
													type='text'
													className='dashboardfields dashboardchangefields tiny'
													name='searchPincodes'
													placeholder='Search Pincode'
													value={this.state.search}
													style={({ width: '200px' }, { height: '35px' })}
													onChange={(e) => this.handleChange('search', e)}
												/>
												{/* <MultiSelect
                          options={this.state.searchTerm}
                          labelKey='sku_code'
                          checkbox_id='_id'
                          placeholder='Search'
                          disabledItems={[]}
                          selectedOptions={this.state.searchedItem}
                          // onSearch={this.getLineItems}
                          onSearch={this.getLineItemsSearch}
                          onChange={(value) =>
                            this.setState(
                              {
                                //   rateFilter: {
                                // ...this.state.rateFilter,
                                searchedItem: value,
                                //   },
                              },
                              () => this.afterFilterChange()
                            )
                          }
                        /> */}
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<form onSubmit={this.onFormSubmitPincode}>
													<input
														type='file'
														onChange={this.onChange}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='submit'
														className='btn btn_small btnwhite btncard move'
													>
														Upload Pincodes
													</button>
												</form>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleSearchClick}
												>
													Search
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												{/* <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Download Excel</button> */}
												{/* <a
                          href='https://storage.googleapis.com/spares/spares/pricing_module_sample.xlsx
'
                          target='_blank'
                        > */}
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={this.downloadSampleDeliveryExcel}
												>
													TEMPLATE
												</button>{' '}
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes25'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Pincode</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>COD</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Prepaid</td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'segments':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='border-box clearfix'>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleFilterClick}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								{/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add segment
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='excelleadsbtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
												>
													Get as Excel file
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='summarybtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
													onClick={this.handleSummaryClick}
												>
													Summary
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes25'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>ID</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Slug</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Description</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Page Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Page Title</td>
									</tr>
								</table>
							</div>
							{/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Content</td>
                                    </tr>
                                </table>
                            </div> */}
							<div className='border-box blackspan65 weight700 dpm1boxes2'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'categories':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='border-box clearfix'>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleFilterClick}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								{/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Question
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='excelleadsbtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
												>
													Get as Excel file
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='summarybtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
													onClick={this.handleSummaryClick}
												>
													Summary
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes25'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>ID</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Segment</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Star Category</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Page Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Page Title</td>
									</tr>
								</table>
							</div>
							{/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Content</td>
                                    </tr>
                                </table>
                            </div> */}
							<div className='border-box blackspan65 weight700 dpm1boxes2'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'brands':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='border-box clearfix'>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleFilterClick}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								{/*		        <div className="blackspan65 weight700 floatleft">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>*/}
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Comparison
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='excelleadsbtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
												>
													Get as Excel file
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='summarybtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
													onClick={this.handleSummaryClick}
												>
													Summary
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes45'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>ID</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes40'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Code</td>
									</tr>
								</table>
							</div>

							{/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Content</td>
                                                            </tr>
                                                        </table>
                                                    </div> */}
							<div className='border-box blackspan65 weight700 dpm1boxes2'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'testimonials':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='border-box clearfix'>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleFilterClick}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								{/*		        <div className="blackspan65 weight700 floatleft">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>*/}
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Testimonial
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='excelleadsbtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
												>
													Get as Excel file
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='summarybtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
													onClick={this.handleSummaryClick}
												>
													Summary
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Page Type</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes12'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Page Path</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Customer</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Heading</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Content</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Source</td>
									</tr>
								</table>
							</div>

							{/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Content</td>
                                                            </tr>
                                                        </table>
                                                    </div> */}
							<div className='border-box blackspan65 weight700 dpmboxes9'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'fleets':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='border-box clearfix'>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								{/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
												>
													Add fleet
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='excelleadsbtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
												>
													Get as Excel file
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes26'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>ID</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Type</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Location</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Primary contact</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Billing name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes9'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'garages':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='border-box clearfix'>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								{/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
												>
													Add garage
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='excelleadsbtn'
													className='btn btn_small btnwhite btncard move'
													data-type=''
												>
													Get as Excel file
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes26'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>ID</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Code</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Location</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Contact</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Display name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes9'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'products':
				if (this.state.segmentsList.length < 1) {
					this.fetchSegmentList();
				}
				if (this.state.subCategoryList.length < 1) {
					this.fetchSubCategoryList();
				}

				if (_.get(this.state, ['brands'], []).length < 1) {
					this.fetchCarBrands();
				}

				if (_.get(this.state, ['middleContent'], []).length < 1) {
					this.fetchCMSBrands();
				}

				return (
					<>
						<div className='top border-box leftalign'>
							<div className='top border-box leftalign'>
								<div className='border-box '>
									<div
										className='blackspan65 weight700 floatleft'
										style={{
											height: 'auto',
											overflow: 'visible',
											width:'13rem'
										}}
									>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.searchTerm}
														labelKey='sku_code'
														checkbox_id='product_id'
														placeholder='Search'
														new_placeholder='Part no. search'
														disabledItems={[]}
														selectedOptions={this.state.searchedItem}
														onSearch={this.getLineItemsSearch}
														onChange={(value) =>
															this.setState(
																{
																	searchedItem: value,
																},
																() => this.afterFilterChange()
															)
															}
													/>
												</td>
											</tr>
										</table>
									</div>
									{/* <div className='blackspan65 weight700 floatleft'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<button
														className='btn btn_small btncolorprim btncard move'
														onClick={this.handleSearchClick}
													>
														Part search
													</button>
												</td>
											</tr>
										</table>
									</div> */}
										{/* this.state.search && this.state.search.length && */}

									{
									 this.props.content?		
									<div className='blackspan65 weight700 floatleft'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra" 
										onClick={()=>this.handleButtonClick('edit')}
										 >Edit</button></td>
									</tr>
								</table>
							</div>:<></>}
							
								</div>
							</div>
							<div className='border-box '>
								<div
									className='blackspan65 weight700 d-flex f-aligncenter'
								>
									<div className='discount-heading'>UPLOAD SECTION</div>
								</div>
								<div className='upload-row border-bottom mtop-1'>
									<div
										className='blackspan65 weight700 floatright'
										style={{
											overflow: 'visible',
											marginTop: '12px',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Part Add/Edit
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														onChange={this.onChange}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='submit'
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
												<a id ='partBulkUpload'></a>
											</div>
											<div>
													<button className='btn btn_small btnwhite btncard move'
													onClick={API.partsEditSampleCSV}
													>
														TEMPLATE
													</button>
											</div>
										</div>
									</div>
								</div>
								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatright'
										style={{ overflow: 'visible' }}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Part Compatibility
											</div>
											<div>
												<form onSubmit={this.onFormSubmitCompatibility}>
													<input
														type='file'
														onChange={this.onChange}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>

													<button
														type='submit'
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<a href='https://storage.googleapis.com/spares/spares/compatibility_sample.xlsx'>
													<button className='btn btn_small btnwhite btncard move'>
														TEMPLATE
													</button>
												</a>
											</div>
										</div>
									</div>
								</div>

								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatright'
										style={{
											overflow: 'visible',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Categorization
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														// onChange={this.onChange}
														onChange={(e) =>
															this.setState({ file: e.target.files[0] })
														}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='button'
														onClick={this.uploadCategoryFile}
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={API.getCategorizationSampleCSV}
												>
													TEMPLATE
												</button>{' '}
											</div>
											{/* <div>
				      <a
				        href="https://storage.googleapis.com/spares/spares/parts_sample_final.xlsx"
				        target="_blank"
				      >
				        <button className="btn btn_small btnwhite btncard move">
				          TEMPLATE
				        </button>{' '}
				      </a>
				    </div> */}
										</div>
									</div>
								</div>
								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatright'
										style={{
											overflow: 'visible',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Alternatives & Replacements
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														// onChange={this.onChange}
														onChange={(e) =>
															this.setState({ file: e.target.files[0] })
														}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='button'
														onClick={this.uploadAlternativesCSV}
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={API.getAlternativesSampleCSV}
												>
													TEMPLATE
												</button>{' '}
											</div>
											{/* <div>
				      <a
				        href="https://storage.googleapis.com/spares/spares/parts_sample_final.xlsx"
				        target="_blank"
				      >
				        <button className="btn btn_small btnwhite btncard move">
				          TEMPLATE
				        </button>{' '}
				      </a>
				    </div> */}
										</div>
									</div>
								</div>

								{/* delete-mapped-alternatives/replacement Start */}
								<div className='upload-row border-bottom'>
									<div className='blackspan65 weight700 floatright' style={{ overflow: 'visible' }}>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<div style={{ width: '25vw', marginTop: '16px' }}>
												Delete mapped alternatives/replacement
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														onChange={(e) => this.setState({ file: e.target.files[0] })}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='button'														
														onClick={this.deleteMappedAlternativesCSV}
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={API.getAlternativesSampleCSV} // no change
												>
													TEMPLATE
												</button>{' '}
											</div>
										</div>
									</div>
								</div>
								{/* delete-mapped-alternatives/replacement End */}

								{/* delete-all-alternatives Start */}
								<div className='upload-row border-bottom'>
									<div className='blackspan65 weight700 floatright' style={{ overflow: 'visible' }}>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<div style={{ width: '25vw', marginTop: '16px' }}>
												Delete all alternatives
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														onChange={(e) => this.setState({ file: e.target.files[0] })}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='button'
														onClick={this.deleteAllAlternativesCSV}
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={API.deleteAlternativesSampleCSV}
												>
													TEMPLATE
												</button>{' '}
											</div>
										</div>
									</div>
								</div>
								{/* delete-all-alternatives End */}

								<div className='upload-row' style={{ marginBottom: '0' }}>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ overflow: 'visible' }}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Specifications
											</div>
											<div>
												<input
													type='file'
													onChange={this.onChange}
													accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
												/>

												<button
													type='button'
													onClick={this.uploadSpecificationCSV}
													className='btn btn_small btnwhite btncard move'
												>
													Upload
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={
															this.state.segmentsList
																? this.state.segmentsList
																: []
														}
														labelKey='name'
														checkbox_id='name'
														placeholder='Segment'
														disabledItems={[]}
														selectedOptions={{
															name: _.get(
																this.state,
																['selected_segment', 'name'],
																null
															),
														}}
														onChange={(e) => {
															this.getCategoryList(e._id);
															this.setState({
																selected_segment: e,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={_.get(
															this.state,
															['categoryList'],
															[]
														).filter(
															(item) =>
																item.segment_name ===
																_.get(
																	this.state,
																	['selected_segment', 'name'],
																	null
																)
														)}
														labelKey='display_name'
														checkbox_id='display_name'
														placeholder='Category'
														disabledItems={[]}
														selectedOptions={{
															display_name: this.state.selected_category
																? this.state.selected_category.display_name
																: null,
														}}
														onChange={(e) => {
															this.setState({
																selected_category: e,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={_.get(
															this.state,
															['subCategoryList'],
															[]
														).filter(
															(item) =>
																item.category_name ===
																_.get(
																	this.state,
																	['selected_category', 'display_name'],
																	null
																)
														)}
														labelKey='name'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
														selectedOptions={{
															name: _.get(
																this.state,
																['selected_sub_category', 'name'],
																null
															),
														}}
														onChange={(value, e) => {
															this.setState({
																selected_sub_category: value,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div className='blackspan65 weight700 floatleft'>
										<table className='stdtable'>
											<tr>
												<td valign='top'>
													<div>
														<button
															className='btn btn_small btnwhite btncard move'
															onClick={this.getSampleSpecificationCSV}
															disabled={
																!this.state.selected_segment ||
																!this.state.selected_sub_category ||
																!this.state.selected_category
															}
														>
															TEMPLATE
														</button>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>

							<div className='border-box clearfix'>
								<div
									className='blackspan65 weight700 d-flex f-aligncenter'
								>
									<div className='discount-heading'>Download section</div>
								</div>
							</div>

							<div
								className='border-box mtop-1'
								style={{ marginBottom: '20rem' }}
							>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												<MultiSelect
													options={this.state.downloadOptions}
													labelKey='name'
													checkbox_id='name'
													placeholder='Download Option'
													disabledItems={[]}
													selectedOptions={this.state.downloadOption}
													onChange={(value, e) => {
														this.setState({
															downloadablePages: 0,
															selectedPage: null,
															downloadOption: value,
															selected_segment: null,
															selected_category: null,
															selectedBrandForModel: null,
															selectedAttributesList: [],
														});
													}}
												/>
											</td>
										</tr>
									</table>
								</div>

								{this.state.downloadOption.name === 'Brand wise' && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.middleContent}
														labelKey='sku_brand'
														checkbox_id='sku_brand'
														placeholder='Brand'
														disabledItems={[]}
														selectedOptions={this.state.selectedBrandForModel}
														onChange={(value, e) => {
															this.setState({
																selectedBrandForModel: value,
																downloadablePages: null,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}
								{this.state.downloadOption.name === 'Vehicle wise' && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.brands}
														labelKey='name'
														checkbox_id='_id'
														placeholder='Make'
														disabledItems={[]}
														selectedOptions={this.state.selectedBrandForModel}
														onChange={(value, e) => {
															this.setState({
																selectedBrandForModel: value,
																downloadablePages: null,
															},
															(e) => {
															this.afterVehicleFilterChange('brand');
															}
															)}}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}
								{this.state.downloadOption.name === 'Category wise' && (
									<>
										<div
											className='blackspan65 weight700 floatleft'
											style={{ height: 'auto', overflow: 'visible' }}
										>
											<table className='stdtable' style={{ height: 'auto' }}>
												<tr>
													<td valign='center'>
														{this.state.brandsList && (
															<MultiSelect
																options={
																	this.state.segmentsList
																		? this.state.segmentsList
																		: []
																}
																labelKey='name'
																checkbox_id='name'
																placeholder='Segment'
																disabledItems={[]}
																selectedOptions={{
																	name: _.get(
																		this.state,
																		['selected_download_segment', 'name'],
																		null
																	),
																}}
																onChange={(e) => {
																	this.getCategoryList(e._id);
																	this.setState({
																		selected_download_segment: e,
																		downloadablePages: null,
																	});
																}}
															/>
														)}
													</td>
												</tr>
											</table>
										</div>
										<div
											className='blackspan65 weight700 floatleft'
											style={{ height: 'auto', overflow: 'visible' }}
										>
											<table className='stdtable' style={{ height: 'auto' }}>
												<tr>
													<td valign='center'>
														<MultiSelect
															options={_.get(
																this.state,
																['categoryList'],
																[]
															).filter(
																(item) =>
																	item.segment_name ===
																	_.get(
																		this.state,
																		['selected_download_segment', 'name'],
																		null
																	)
															)}
															labelKey='display_name'
															checkbox_id='display_name'
															placeholder='Category'
															disabledItems={[]}
															selectedOptions={{
																display_name: this.state
																	.selected_download_category
																	? this.state.selected_download_category
																			.display_name
																	: null,
															}}
															onChange={(e) => {
																this.setState({
																	selected_download_category: e,
																	downloadablePages: null,
																});
															}}
														/>
													</td>
												</tr>
											</table>
										</div>
									</>
								)}

								{this.state.downloadOption.name === 'Vehicle wise' && (
									<>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
												<MultiSelect
              									options={this.state.modelsForBrand}
              									labelKey="name"
              									checkbox_id="_id"
              									placeholder="Select Model"
              									disabledItems={[]}
              									selectedOptions={this.state.selectedModel}
              									onChange={(value, e) =>
                									this.setState(
                 									 {
                    									selectedModel: value,
                 									 },
                  									(e) => {
                  									  this.afterVehicleFilterChange("model");
                  									}
                									)
              									}
            									/>
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
											    <MultiSelect
                        						options={this.state.varientType?[...new Map(this.state.varientType.map(item=> [item['year_range'], item])).values()].sort((a,b) => (a.year_range > b.year_range) ? 1 : ((b.year_range > a.year_range) ? -1 : 0)):[]}
                        						labelKey="year_range"
                        						checkbox_id="year_range"
                        						placeholder="year range"
                         						selectedOptions={{year_range:this.state.year_range}}
                        						onChange={(value) => {
                          						this.setState({
                            					year_range: value.year_range,
                         						 });
												}}
                    							  />
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
											      <MultiSelect
              										options={this.state.varientType?[...new Map(this.state.varientType.filter(item=>item.year_range===this.state.year_range).map(item=> [item['fuel_engine'], item])).values()].filter(item=>item.fuel_engine):[]}
              										labelKey="fuel_engine"
              										checkbox_id="_id"
              										placeholder="Select FuelType"
              										disabledItems={[]}
              										selectedOptions={this.state.selectedFuelType}
              										onChange={(value, e) =>
                									this.setState(
                 										 {
                    									selectedFuelType: value,
                  										},
                									)
              										}
           											 />
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
												<MultiSelect
              									options={this.state.selectedFuelType ?this.state.varientType.filter(item=>item.year_range===this.state.year_range).filter(item=>item.fuel_engine === this.state.selectedFuelType.fuel_engine):''}
              									labelKey="varient"
              									checkbox_id="varient"
              									placeholder="Select Varient"
              									disabledItems={[]}
              									selectedOptions={this.state.selectedVarientType}
             									 onChange={(value, e) =>{
                									this.setState(
                									  {selectedVarientType: value},
               									 )}
            									  }
           									 />
												</td>
											</tr>
										</table>
									</div>
									</>
								)}

								{/* Subcategory Input Box for list */}
								{this.state.downloadOption.name === 'Subcategory wise' && (
									<>
										<div
											className='blackspan65 weight700 floatleft'
											style={{ height: 'auto', overflow: 'visible' }}
										>
											<table className='stdtable' style={{ height: 'auto' }}>
												<tr>
													<td valign='center'>
														<MultiSelect
															options={_.get(this.state, ['subCategoryList'], [])}
															labelKey='name'
															checkbox_id='name'
															placeholder='Sub Category'
															disabledItems={[]}
															selectedOptions={{
																name: _.get(this.state, ['selected_download_subcategory', 'name'], null)
															}}
															onChange={(e) => {
																this.setState({
																	selected_download_subcategory: e,
																	downloadablePages: null,
																});
															}}
														/>
													</td>
												</tr>
											</table>
										</div>
									</>
								)}

								{this.state.downloadOption && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.attributesList}
														labelKey='name'
														checkbox_id='id'
														placeholder='Attributes'
														disabledItems={[]}
														multiple
														selectedOptions={this.state.selectedAttributesList}
														onChange={(value, e) => {
															this.setState({
																selectedAttributesList: value,
																downloadablePages: null,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={() => {
														this.fetchDownloadData(true);
														this.setState({ downloadablePages: null });
													}}
												>
													Fetch
												</button>
											</td>
										</tr>
									</table>
								</div>

								{this.state.downloadablePages ? (
									<div
										className='border-box clearfix mtop-1'
										style={{ overflow: 'visible', marginBottom: '1rem' }}
									>
										<div
											className='blackspan65 weight700 d-flex f-aligncenter'
											style={{
												fontSize: '16px',
												height: 'auto',
												marginTop: '1rem',
											}}
										>
											<div className='blackspan65 weight700 floatleft'>
												<table className='stdtable' style={{ height: 'auto' }}>
													<tr>
														<td valign='center'>
															<MultiSelect
																options={Array(
																	_.get(this.state, ['downloadablePages'], 0)
																)
																	.fill(0)
																	.map((page, index) => ({
																		page: '' + (index + 1),
																	}))}
																labelKey='page'
																checkbox_id='page'
																placeholder='File to download'
																disabledItems={[]}
																selectedOptions={this.state.selectedPage}
																onChange={(value, e) => {
																	this.setState({
																		selectedPage: value,
																	});
																}}
															/>
														</td>
													</tr>
												</table>
											</div>
											<div className='blackspan65 weight700 floatleft'>
												<table
													className='stdtable'
													style={{ marginLeft: '7px' }}
												>
													<tr>
														<td valign='center'>
															<button
																id='filterbtn'
																className='btn btn_small btncolorprim btncard move'
																disabled={!this.state.selectedPage}
																onClick={() => {
																	this.fetchDownloadData(
																		false,
																		_.get(
																			this.state,
																			['selectedPage', 'page'],
																			0
																		)
																	);
																}}
															>
																Download
															</button>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</>
				);
			// #region old product code
			// return (
			// 	<React.Fragment>
			// 		<div className='top border-box leftalign'>
			// 			<div className='border-box '>
			// 				<div
			// 					className='blackspan65 weight700 floatleft'
			// 					style={{
			// 						height: 'auto',
			// 						overflow: 'visible',
			// 					}}
			// 				>
			// 					<table className='stdtable'>
			// 						<tr>
			// 							<td valign='center'>
			// 								<MultiSelect
			// 									options={this.state.searchTerm}
			// 									labelKey='sku_code'
			// 									checkbox_id='product_id'
			// 									placeholder='Search'
			// 									disabledItems={[]}
			// 									selectedOptions={this.state.searchedItem}
			// 									// onSearch={this.getLineItems}
			// 									onSearch={this.getLineItemsSearch}
			// 									onChange={(value) =>
			// 										this.setState(
			// 											{
			// 												//   rateFilter: {
			// 												// ...this.state.rateFilter,
			// 												searchedItem: value,
			// 												//   },
			// 											},
			// 											() => this.afterFilterChange()
			// 										)
			// 									}
			// 								/>
			// 							</td>
			// 						</tr>
			// 					</table>
			// 				</div>
			// 				<div className='blackspan65 weight700 floatleft'>
			// 					<table className='stdtable'>
			// 						<tr>
			// 							<td valign='center'>
			// 								<button
			// 									className='btn btn_small btncolorprim btncard move'
			// 									onClick={this.handleSearchClick}
			// 								>
			// 									Search
			// 								</button>
			// 							</td>
			// 						</tr>
			// 					</table>
			// 				</div>
			// 			</div>
			// 		</div>
			// 		<div
			// 			className='middle border-box clearfix leftalign'
			// 			style={{ top: '195px' }}
			// 		>
			// 			<div className='border-box blackspan65 weight700 dpmboxes1'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'></td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='border-box blackspan65 weight700 dpm1boxes15'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>Sku Code</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='border-box blackspan65 weight700 dpm1boxes30'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>Sku Description</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='border-box blackspan65 weight700 dpm1boxes10'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>MRP</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='border-box blackspan65 weight700 dpm1boxes10'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>Tax %</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='border-box blackspan65 weight700 dpm1boxes10'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>Price</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 			<div className='border-box blackspan65 weight700 dpm1boxes20'>
			// 				<table className='stdtable'>
			// 					<tr>
			// 						<td valign='center'>Updated At</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 		</div>
			// 	</React.Fragment>
			// );
			//#endregion end
			case 'discount':
				if (_.get(this.state, ['middleContent'], []).length < 1) {
					this.fetchCMSBrands();
				}
				if (this.state.subCategoryList.length < 1) {
					this.getSubCategoriesList();
				}
				return (
					<React.Fragment>
								<div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box" >
          							<div className="extramainpanel border-box leftalign font-15">
									  <div className='border-box clearfix' style={{paddingTop:'.5rem',paddingBottom:'1rem'}}>
									  <div className='blackspan65 weight700 f-aligncenter'>
									<div className='discount-heading'>Default Discount</div>
									</div>
									</div>
									<div className='border-box clearfix fields'>
									<div className='border-box clearfix field2' style={{ overflow: 'visible' }}>
											<div className='uppercased fieldtitle weight700 blackspan7 font-15'>
												Brand Discount
											</div>
										<div className='d-flex f-justifybetween'  style={{width:'67%'}}>
										<div style={{width:'49%'}}>
											<MultiSelect
												options={this.state.middleContent}
												labelKey='brand_name'
												checkbox_id='brand_name'
												placeholder='Brand'
												disabledItems={[]}
												selectedOptions={this.state.selected_brand}
												onChange={(value, e) => {
													this.setState({
														selected_brand: value,
													});
												}}
											/>
											</div>
											<input
												type='number'
												placeholder='Discount'
												min={0}
												max={100}
												style={{width:'32%'}}
												value={_.get(
													this.state,
													['selected_brand_discount', 'new_discount'],
													''
												)}
												className='field border-box small'
												onChange={(e) =>
													this.setState({
														selected_brand_discount: {
															..._.get(
																this.state,
																['selected_brand_discount'],
																{}
															),
															new_discount: Math.floor(Number(e.target.value)),
														},
													})
												}
											/>
											<div>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.updateBrandDiscount}
													disabled={
														!this.state.selected_brand_discount 
													}
												>
													Update
												</button>
											</div>
										</div>
							
										</div>
									<div className='border-box clearfix field2' style={{ overflow: 'visible' }}>
									<div className='uppercased fieldtitle weight700 blackspan7 font-15'>
												SubCategory Discount
											</div>
											<div className='d-flex f-justifybetween' style={{width:'67%'}}>
										<div style={{width:'49%'}}>
											<MultiSelect
														options={this.state.subCategoryList&&this.state.subCategoryList.map((item)=>{
														return( {...item,label:item.name + '-' + item.id})
														})}
														labelKey='label'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
														selectedOptions={ _.get(
																this.state,
																['selected_sub_category'],
																null
															)
														}
														onChange={(value, e) => {
															this.setState({
																selected_sub_category: value,
															});
														}}
													/>
													</div>
											<input
												type='number'
												placeholder='Discount'
												min={0}
												max={100}
												style={{width:'32%'}}
												value={_.get(
													this.state,
													['selected_category_discount', 'new_discount'],
													''
												)}
												className='field border-box small '
												onChange={(e) =>
													this.setState({
														selected_category_discount: {
															..._.get(
																this.state,
																['selected_category_discount'],
																{}
															),
															new_discount: Math.floor(Number(e.target.value)),
														},
													})
												}
											/>
											<div>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.updateSubCategoryDiscount}
													disabled={
														!this.state.selected_category_discount
													}
												>
													Update
												</button>
											</div>
										</div>
									</div>
									</div>

									<div className='border-box clearfix fields font-15' style={{padding: "10px 10px 15px 1.8rem",}}>									
									<div className='uppercased fieldtitle weight700 blackspan7 font-15'>
												Brand and SubCategory Discount
											</div>
										<div className='d-flex f-justifybetween' style={{width:'50%'}}>
										<div style={{width:'32%'}}>
											<MultiSelect
												options={this.state.middleContent}
												labelKey='sku_brand'
												checkbox_id='sku_brand'
												placeholder='Brand'
												disabledItems={[]}
												selectedOptions={this.state.selectedBrandForModel}
												onChange={(value, e) => {
													this.setState({
														selectedBrandForModel: value,
													});
												}}
											/>
											</div>
											<div style={{width:'33%'}}>
													<MultiSelect
														options={this.state.subCategoryList&&this.state.subCategoryList.map((item)=>{
														return( {...item,label:item.name + '-' + item.id})
														})}
														labelKey='label'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
														selectedOptions={ _.get(
																this.state,
																['selected_brandSub_category'],
																null
															)
														}
														onChange={(value, e) => {
															this.setState({
																selected_brandSub_category: value,
															});
														}}
													/>
													</div>
											<input
												type='number'
												placeholder='Discount'
												min={0}
												max={100}
												style={{width:'22%'}}
												value={this.state.combinationDiscount}
												className='field border-box small'
												onChange={(e) =>
													this.setState({ combinationDiscount: Math.floor(Number(e.target.value)) })
												}
											/>
											<div>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleCombinationUpdate}
													disabled={
														!this.state.selectedBrandForModel ||
														!this.state.selected_brandSub_category
													}
												>
													Update
												</button>
											</div>
										</div>
							</div>
							<div className='border-box clearfix fields'>
							<div className='border-box clearfix field2' style={{ overflow: 'visible' }}>
							<div className='d-flex f-aligncenter f-justifybetween width-100'>
								{/* <div className='blackspan65 weight700 floatleft' style={{ marginLeft: '.22rem' }}>
									<div className='bulk-discount'>Brand Bulk Discount</div>
									<div>
										<form onSubmit={this.onBrandDiscountBulkSubmit}>
											<input
												className='epc-file-upload'
												type='file'
												id='myepcfile'
												onChange={(e) =>
													this.setState({ file: e.target.files[0] })
												}
												accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
											/>
											<button
												type='submit'
												className='btn btn_small btnwhite btncard move'
											>
											Upload
											</button>
											<a
												href='https://dev.spares-mp.gomechanic.app/brand-discount-bulk-upload/'
												target='_blank'
											>
												<button
													type='button'
													className='btn btn_small btnwhite btncard move'
												>
													TEMPLATE
												</button>
											</a>
										</form>
									</div>
								</div> */}
							</div>
							</div>
							<div className='border-box clearfix field2' style={{ overflow: 'visible' }}>
							<div className='d-flex f-aligncenter f-justifybetween width-100'>
								{/* <div className='blackspan65 weight700 floatleft' style={{ marginLeft: '.22rem' }}>
								<div className='bulk-discount'>SubCategory Bulk Discount</div>
								<div>
								<form onSubmit={this.onSubCategroyDiscountBulkSubmit}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												this.setState({ file: e.target.files[0] })
											}
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										/>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
										 Upload
										</button>
										<a
											href='https://dev.spares-mp.gomechanic.app/subcategory-discount-bulk-upload/'
											target='_blank'
										>
											<button
												type='button'
												className='btn btn_small btnwhite btncard move'
											>
												TEMPLATE
											</button>
										</a>
									</form>
								</div>
								</div> */}
							</div>
							</div>
							</div>
							<div className='border-box clearfix fields' style={{borderBottom: "1px solid #ddd",paddingBottom:'1rem',paddingTop:'.5rem' }}>
							<div className='border-box clearfix field2' style={{ overflow: 'visible' }}>
							<div className='d-flex f-aligncenter f-justifybetween width-100'>
										<div
								className='blackspan65 weight700 floatleft'
								style={{ marginLeft: '.22rem' }}>
								<div className='bulk-discount'>Brand & SubCategory Bulk Discount</div>
								<div>
								<form onSubmit={this.onBrandSubCategroyDiscountBulkSubmit}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												this.setState({ file: e.target.files[0] })
											}
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										/>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
										 Upload
										</button>
										<a
											href='https://dev.spares-mp.gomechanic.app/sub-brand-discount-upload/'
											target='_blank'
										>
											<button
												type='button'
												className='btn btn_small btnwhite btncard move'
											>
												TEMPLATE
											</button>
										</a>
									</form>
								</div>
								</div>
							</div>
							</div>
							<div className='border-box clearfix field2' style={{ overflow: 'visible'}}>
							<div className='d-flex f-aligncenter f-justifybetween width-100'>
										<div
								className='blackspan65 weight700 floatleft'
								style={{ marginLeft: '.22rem' }}>
								<div className='bulk-discount'>Product Level Bulk Discount</div>
								<div>
								<form onSubmit={this.onDiscountBulkSubmit}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												this.setState({ file: e.target.files[0] })
											}
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										/>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
										 Upload
										</button>
										<a
											href='https://dev.spares-mp.gomechanic.app/discount-bulk-upload/'
											target='_blank'
										>
											<button
												type='button'
												className='btn btn_small btnwhite btncard move'
											>
												TEMPLATE
											</button>
										</a>
									</form>
								</div>
								</div>
							</div>
							</div>
							</div>

							<div className='border-box clearfix fields'>
									<div className='border-box clearfix field2' style={{ overflow: 'visible' }}>
							<div
								className='blackspan65 weight700 floatleft'
								style={{ marginLeft: '.22rem' }}>
								<div className='bulk-discount'>Customer Bulk Discount</div>
								<div>
									<form onSubmit={this.onCustomerDiscountBulkSubmit}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												this.setState({ file: e.target.files[0] })
											}
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										/>
												<button type='submit' className='btn btn_small btnwhite btncard move'>
												Upload
												</button>
											<a href='https://dev.spares-mp.gomechanic.app/customer-bulk-discount/'
											target='_blank'>
												<button	type='button'	className='btn btn_small btnwhite btncard move'>
													TEMPLATE
												</button>
											</a>
									</form>
								</div>
								</div>
								</div>
							</div>

							<div className='border-box clearfix' style={{paddingTop:'.5rem',paddingBottom:'.5rem'}}>
									  <div className='blackspan65 weight700 f-aligncenter'>
									<div className='discount-heading'>Customer Discount</div>
									</div>
									</div>
									<div
								className='border-box clearfix'
								style={{ overflow: 'visible' }}
							>
										<div className='floatleft' style={{marginLeft:'1rem'}}>
											<input
												type='text'
												class='dashboardfields dashboardchangefields'
												name='searchbysku'
												placeholder='Search customer name or No.'
												value={this.state.search}
												style={{
													width: '270px',
													fontSize: '16px',
												}}
												onChange={(e) => this.handleChange('search', e)}
											></input>
											</div>
										<div className='floatleft' style={{marginLeft:'.5rem',marginTop:'.3rem'}}>

											<button
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleSearchClick}
											>
												Search
											</button>
							</div>
							</div>
							</div>	
							{this.props.content?
							<div
							className='boxes categories border-box clearfix leftalign blackspan9 d-flex' style={{paddingTop:'1rem'}}>
							<div className='textsize12 border-box weight500 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>&#9679;</td>
									</tr>
								</table>
							</div>
							<div className='textsize12 border-box weight500 dpm1boxes10'>
								<table
									className='stdtable colorprim500span'
									style={{ textTransform: 'uppercase' }}
								>
									<tr>
										<td valign='center'>{this.props.content.customer_id}</td>
									</tr>
								</table>
							</div>
							<div className='textsize12 border-box weight500 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>{this.props.content.first_name.toUpperCase()}</td>
									</tr>
								</table>
							</div>
							<div className='textsize12 border-box weight500 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>{this.props.content.phone_number}</td>
									</tr>
								</table>
							</div>
						<div className='textsize12 border-box weight500 dpm1boxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra" 
										onClick={()=>this.handleButtonClick('edit')}
										 >Edit</button></td>
									</tr>
								</table>
							</div>
							{/* <div className='textsize12 border-box weight500 dpm1boxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra"
										onClick={()=>this.handleButtonClick('delete')}
										
										>Delete</button></td>
									</tr>
								</table>
							</div> */}
						
						</div>:<></>}
							<div className='border-box clearfix'>
								{/* <div
									className='blackspan65 weight700 floatleft'
									style={{
										overflow: 'initial',
										height: 'auto',
									}}
								>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<input
													type='text'
													class='dashboardfields dashboardchangefields'
													name='searchbysku'
													placeholder='Search customer name or No.'
													value={this.state.search}
													style={{
														width: '270px',
														fontSize: '16px',
													}}
													onChange={(e) => this.handleChange('search', e)}
												></input>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleSearchClick}
												>
													Search
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleSearchDefault}
												>
													Defaults
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={this.downloadCustomersDiscount}
												>
													Download Excel
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={this.handleCategoryBrandDiscount}
												>
													Category-Brand Discount
												</button>
											</td>
										</tr>
									</table>
								</div> */}

								{/* //////////////////////////////////////////////////////            */}
								{/* {this.state.isDefaultSuccess &&
                  !this.state.showSaveForDiscount && (
                    <div
                      className="border-box clearfix fields"
                      style={{ height: 'auto' }}
                    >
                      <select
                        style={{
                          width: 'auto',
                          marginLeft: '36px',
                        }}
                        value={this.state.mobile}
                        onChange={(e) => this.handleChangeForPhone('mobile', e)}
                      >
                        <option value="9566212403">9566212403</option>
                        <option value="8871719169">8871719169</option>
                        <option value="8555968996">855968996</option>
                        <option value="9711181607">9711181607</option>
                        <option value="8802442620">8802442620</option>
                      </select>
                      <div className="border-box floatright rightalign">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClickOTP('otp')}
                        >
                          Get OTP
                        </button>
                      </div>
                      <div className="border-box clearfix field2"></div>
                      <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                          {this.state.showVerifyOTP && (
                            <>
                              <input
                                style={{
                                  width: '28%',
                                  marginLeft: '52%',
                                }}
                                type="text"
                                placeholder="Enter OTP"
                                value={this.state.otp}
                                maxLength="4"
                                className="field border-box small"
                                onChange={(e) =>
                                  this.handleChangeForOTP('otp', e)
                                }
                              />
                              <div className="border-box floatright rightalign">
                                <button
                                  class="btn btn_small btncolorprim btncard move btncloseextra"
                                  onClick={() =>
                                    this.handleButtonClickOTPVerify('otp')
                                  }
                                >
                                  Verify OTP
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}

								{/* {this.state.showSaveForDiscount && (
                  <>
                    <div
                      className="border-box clearfix fields"
                      style={{
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div
                        className="border-box clearfix field2"
                        style={{
                          overflow: 'initial',
                        }}
                      >
                        <select
                          style={{ fontSize: '1rem' }}
                          className="dashboardfields dashboardchangefields select"
                          id="fieldstarttimefrom"
                          onChange={(e) =>
                            this.brandSelectValue(e.target.value)
                          }
                        >
                          <option>Select Brand</option>
                          {Object.keys(this.state.defaultDiscounts).map(
                            (brand) => {
                              return <option>{brand}</option>;
                            }
                          )}
                        </select>
                      </div>

                      <div
                        className="border-box clearfix field2"
                        style={{ overflow: 'initial' }}
                      >
                        <input
                          type="number"
                          placeholder="Discount"
                          value={this.state.discountValue}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleChangeForDiscount('discountValue', e)
                          }
                        />
                      </div>
                    </div>
                  </>
                )} */}
								{/* {this.state.showSaveForDiscount && (
                  <div className="blackspan65 weight700">
                    <table className="stdtable" style={{ textAlign: 'center' }}>
                      <tr>
                        <td valign="center">
                          <button
                            className="btn btn_small btncolorprim btncard move"
                            onClick={this.updateDefaultDiscount}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                )} */}
							</div>
						</div>

						
						<div className='middle border-box clearfix leftalign'></div>
					</React.Fragment>
				);
				case 'epc':
				if (this.state.brands.length < 1) {
					this.getCarBrands();
				}
				if (this.state.segmentsList.length < 1) {
					this.fetchSegmentList();
				}

				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box' style={{ marginBottom: '2rem' }}>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													disabled={!this.state.image_url || this.state.dataUploaded}
													onClick={this.handleEPCUpload}
												>
													Upload Data
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box'>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												<MultiSelect
													options={this.state.brands}
													labelKey='name'
													checkbox_id='_id'
													placeholder='Brand'
													disabledItems={[]}
													selectedOptions={this.state.selectedBrandForModel}
													onChange={(value, e) => {
														this.setState(
															{
																selectedBrandForModel: value,
															},
															(e) => {
																this.afterFilterChangeMake('brand');
															}
														);
													}}
												/>
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												<MultiSelect
													options={this.state.modelsForBrand}
													labelKey='model'
													checkbox_id='_id'
													placeholder='Select Model'
													disabledItems={[]}
													selectedOptions={this.state.selectedModel}
													disabled={!this.state.selectedBrandForModel}
													onChange={(value, e) =>
														this.setState(
															{
																selectedModel: value,
															},
															(e) => {
																this.handleSearchClickVehicle();
															}
														)
													}
												/>
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												{/* <input
													type='text'
													className='dashboardfields dashboardchangefields family-input'
													placeholder='year range'
													name='family'
													value={_.get(
														this.state,
														['selected_varient', 'year_range'],
														''
													)}
													readOnly
												/> */}
												<MultiSelect
													options={_.uniqBy(
														_.get(this.props, ['content', 'data'], []),
														(item) => item.year_range
													).sort((a,b) => (a.year_range > b.year_range) ? 1 : ((b.year_range > a.year_range) ? -1 : 0))}
													labelKey='year_range'
													checkbox_id='year_range'
													placeholder='year range'
													disabledItems={[]}
													selectedOptions={{
														year_range: _.get(
															this.state,
															['selected_year_range'],
															''
														),
													}}
													disabled={!this.state.selectedModel}
													onChange={(e) => {
														this.setState({
															selected_year_range: e.year_range,
														});
													}}
												/>
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												<MultiSelect
													options={
														_.get(this.props, ['content', 'data'], [])
															.filter(item => item.year_range == _.get(this.state,['selected_year_range'],''))}
													labelKey='varient'
													checkbox_id='varient'
													placeholder='Variant'
													selectedOptions={{
														varient: _.get(
															this.state,
															['selected_varient', 'varient'],
															null
														),
													}}
													disabled={!this.state.selected_year_range}
													onChange={(e) => {
														this.setState({
															selected_varient: e,
														});
													}}
												/>
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												{/* <input
													type='text'
													className='dashboardfields dashboardchangefields family-input'
													placeholder='year range'
													name='family'
													value={_.get(
														this.state,
														['selected_varient', 'year_range'],
														''
													)}
													readOnly
												/> */}
												<MultiSelect
													options={
														_.get(this.props, ['content', 'data'], []).filter(
															(item) =>
																(item.varient ===
																_.get(
																	this.state,
																	['selected_varient', 'varient'],
																	''
																) && item.year_range === _.get(this.state,['selected_year_range'],''))
														)
														// 	[
														// 	{
														// 		name: _.get(
														// 			this.state,
														// 			['selected_varient', 'year_range'],
														// 			''
														// 		),
														// 	},
														// ]
													}
													labelKey='fuel_engine'
													checkbox_id='fuel_engine'
													placeholder='fuel engine'
													disabledItems={[]}
													selectedOptions={{
														fuel_engine: _.get(
															this.state,
															['fuel_engine'],
															''
														),
													}}
													disabled={!this.state.selected_varient}
													onChange={(e) => {
														this.setState({
															fuel_engine: e.fuel_engine,
														});
													}}
												/>
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												{this.state.brandsList && (
													<MultiSelect
														options={
															this.state.segmentsList
																? this.state.segmentsList
																: []
														}
														labelKey='name'
														checkbox_id='name'
														placeholder='Segment'
														disabledItems={[]}
														selectedOptions={{
															name: this.state.selected_segment
																? this.state.selected_segment.name
																: null,
														}}
														disabled={!this.state.selected_varient}
														onChange={(e) => {
															this.setState({
																selected_segment: e,
															});
														}}
													/>
												)}
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												<input
													type='text'
													onChange={(e) =>
														this.setState({ family: e.target.value.toUpperCase() })
													}
													className='dashboardfields family-input small uppercased'
													disabled={!this.state.selected_segment}
													placeholder='Family'
													name='family'
												/>
												{/* {this.state.brandsList && (
                          <MultiSelect
                            options={this.state.selected_category ? this.state.selected_category.category_details : []}
                            labelKey="display_name"
                            checkbox_id="display_name"
                            placeholder="Sub-category"
                            disabledItems={[]}
                            selectedOptions={{
                              display_name:
                                this.state.selected_sub_category ? this.state.selected_sub_category.display_name : null,
                            }}
                            disabled={
                              !this.state.selected_category
                            }
                            onChange={(e) => {
                              this.setState({
                                selected_sub_category: e,
                              });
                            }}
                          />
                        )} */}
											</td>
										</tr>
									</table>
								</div>

								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													disabled={!this.state.family}
													onClick={() => {
														const { name: brand } =
															this.state.selectedBrandForModel;
														const { model } = this.state.selectedModel;
														const { varient } =
															this.state.selected_varient;
														const { _id: segmentId } =
															this.state.selected_segment;
														const fuel_engine = this.state.fuel_engine
														const year_range = this.state.selected_year_range;
														const family = this.state.family;
														// const {name: subCategory} = this.state.selected_sub_category;
														let partDetail = {
															make: brand,
															model,
															fuel_engine,
															varient,
															year: year_range,
															// sku_category: subCategory,
															family,
															segment_name: this.state.selected_segment.name,
															sku_brand: null,
															image_file: null,
															seq_map: [],
															segment_id: segmentId,
														};
														// this.setState({partDetail, categoryPayload: {}, file: null});

														// this.getAllParts(brand, model, varient,fuel_engine, segmentId, subCategory);
														this.getEPCData(
															brand,
															model,
															varient,
															fuel_engine,
															segmentId,
															family,
															year_range
														);
													}}
												>
													Fetch Details
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>

						<div className='middle parts border-box clearfix leftalign'>
							<div className='blackspan65 weight700 floatleft'>
								<div style={{ margin: '5px' }}>Upload Image</div>

								<input
									type='file'
									className='dashboardfields dashboardchangefields family-input'
									placeholder={'Browse Image'}
									accept='image/png, image/gif, image/jpeg'
									onChange={async (event) => {
										let image_file = event.target.files[0];
										let width = await this.checkImageWidth(image_file);
										// alert(width);
										this.setState({})
										document.getElementById('part-img').style.width='100%'
										this.setState({
											count:0,
											badges: [],
											image_url: null,
											image_file: image_file,
										});
									}}
								/>
							</div>
							{/* <div className="blackspan65 weight700 floatleft" style={{marginTop: "20px"}}>
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                          disabled={!this.state.file}
                        >
                          Get URL
                        </button>
                      </td>
                    </tr>
                  </table>
                </div> */}
							<div
								className='blackspan65 weight700 floatleft'
								style={{ marginTop: '20px' }}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												disabled={!this.state.parts}
												className='btn btn_small btncolorprim btncard move'
												onClick={
													this.handleEPCImageUpload
													// 	() => {
													// 	API.getEPCImageURL(this.state.image_file)
													// 		.then((res) => {
													// 			NotificationManager.success(
													// 				'Image uploaded.',
													// 				'Success',
													// 				3000
													// 			);
													// 			this.setState({
													// 				image_url: _.get(res, ['data', 'data'], null),
													// 			});
													// 		})
													// 		.catch((err) =>
													// 			NotificationManager.error(
													// 				'Failed to upload image.',
													// 				'Error!!',
													// 				3000
													// 			)
													// 		);
													// }
												}
											>
												Upload Image
											</button>
										</td>
									</tr>
								</table>
							</div>
							{/* <div
								className='blackspan65 weight700 floatleft'
								style={{ marginTop: '20px' }}
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>
											<button
												id='filterbtn'
												className='btn btn_small btncolorprim btncard move'
												disabled={!this.state.image_url}
												onClick={this.handleEPCUpload}
											>
												Upload Data
											</button>
										</td>
									</tr>
								</table>
							</div> */}

							<div
								className='blackspan65 weight700 floatleft'
								style={{ marginLeft: '12rem' }}
							>
								<div style={{ margin: '5px' }}>Upload Excel</div>
								<div style={{ height: '3rem' }}>
									<form onSubmit={this.onEPCCSVSubmit}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												this.setState({ file: e.target.files[0] })
											}
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										/>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
											Upload
										</button>
										<a
											href='https://dev.spares-mp.gomechanic.app/epc_bulk/'
											target='_blank'
										>
											<button
												type='button'
												className='btn btn_small btnwhite btncard move'
											>
												TEMPLATE
											</button>{' '}
										</a>
									</form>
								</div>

								{/* <input
                  className="btn_small btncard move"
                  type="file"
                  disabled={this.state.selected_sub_category === undefined}
                  placeholder={'Browse Image'}
                  accept=".xls, .xlsx, .csv"
                  onChange={(event) =>
                    this.setState({
                      file: URL.createObjectURL(event.target.files[0]),
                    })
                  }
                /> */}
							</div>
						</div>
						<div
							className='zoom_part'
							style={{
								visibility: `${
									this.state.image_file || this.state.image_url
										? 'visible'
										: 'hidden'
								}`,
							}}
						>
							<button className='zoom-In' onClick={() => this.zoomIn()}>
								+
							</button>
							<button className='zoom-Out' onClick={() => this.zoomOut()}>
								-
							</button>
						</div>
						<div className='epc-image-section'>
							<div className='blackspan65 weight700 floatleft sticky' style={{transform:"rotateX(180deg)",}}>
								<div>
									<div id='parentDiv' style={{transform:"rotateX(180deg)"}}>
										<img
											id='part-img'
											onClick={this.addBadges}
											style={{
												visibility: `${
													this.state.image_file || this.state.image_url
														? 'visible'
														: 'hidden'
												}`,
											}}
											src={
												this.state.image_file
													? URL.createObjectURL(this.state.image_file)
													: this.state.image_url
											}
											alt='image'
										/>
										{(this.state.image_file || this.state.image_url) &&
											Object.entries(this.state.categoryPayload).map(
												([productId, item], index) => {
													if (!item.coordinates) return null;
													return _.get(item, ['coordinates'], []).map(
														(point, index) => {
															return (
																<div
																	className={`childDiv ${
																		_.get(
																			this.state,
																			['highlightedRow', 'figNum'],
																			null
																		) === item.figNum && 'highlightedBadge'
																	}`}
																	id={'' + point[0] + point[1] + index}
																	key={'' + point[0] + point[1] + index}
																	style={{
																		left: `${point[1]+ (this.state.count*0.0941)}%`,
																		top: `${point[0] + (this.state.count*0.09193)}%`,
																		// left: `${point[1]}%`,
																		// top: `${point[0]}%`,
																	}}
																	onMouseDown={(e) => {
																		if (e.target.className === 'badge-del') {
																			e.target.click();
																			return;
																		}
																		this.onBadgeMouseDown(e, {
																			badgeId: '' + point[0] + point[1] + index,
																			index,
																			productId,
																		});
																	}}
																	onMouseOver={(e) =>
																		this.setState({
																			highlightedRow: {
																				productId,
																				figNum: item.figNum,
																			},
																		})
																	}
																	onMouseOut={(e) =>
																		this.setState({ highlightedRow: {} })
																	}
																>
																	{item.figNum}
																	<div
																		className='badge-del'
																		onClick={(e) => {
																			let badgeList = this.state.badges;
																			// let figProdList =
																			// 	this.state.figMap[item.figNum];
																			// figProdList.splice(
																			// 	figProdList.indexOf(productId),
																			// 	1
																			// );
																			badgeList.splice(index, 1);
																			let categoryPayload = {
																				...this.state.categoryPayload,
																			};
																			let coordinates =
																				categoryPayload[productId].coordinates;
																			coordinates.splice(index, 1);
																			categoryPayload[productId] = {
																				figNum:
																					categoryPayload[productId].figNum,
																				coordinates,
																			};
																			this.setState({
																				// figMap: {
																				// 	...this.state.figMap,
																				// 	[item.figNum]: figProdList,
																				// },
																				badges: badgeList,
																				categoryPayload: categoryPayload,
																				// selectedRow: '',
																			});
																		}}
																	>
																		X
																	</div>
																</div>
															);
														}
													);
												}
											)}
									</div>
								</div>
							</div>

							<div className='blackspan65 floatleft'>
								<table className='stdtable epc-table'>
									{this.state.parts && (
										<thead>
											<tr>
												<th></th>
												<th>Fig No.</th>
												<th>sku_code</th>
												<th>title</th>
											</tr>
										</thead>
									)}

									<tbody>
										{this.state.parts &&
											this.state.parts.map((item) => (
												<tr
													key={item.product_id}
													className={`${
														_.get(
															this.state,
															['highlightedRow', 'figNum'],
															null
														) === item.index && 'highlightedRow'
													}`}
													onMouseOver={(e) =>
														this.setState({
															highlightedRow: {
																productId: item.product_id,
																figNum: item.index,
															},
														})
													}
													onMouseOut={(e) =>
														this.setState({ highlightedRow: {} })
													}
												>
													<td>
														<input
															type='checkbox'
															name={item.product_id}
															onChange={(e) => {
																if (!e.target.checked) {
																	this.setState({ selectedRow: '' });
																	return;
																}
																const product =
																	this.state.categoryPayload[e.target.name];
																if (product)
																	this.setState({ selectedRow: e.target.name });
															}}
															checked={
																this.state.selectedRow === item.product_id
															}
														/>
													</td>
													<td>{item.index}</td>
													<td>{item.sku_code}</td>
													<td>{item.title}</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'coupons':
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box '>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add New Coupon
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div
							className='middle border-box clearfix leftalign'
							style={{ marginTop: '5px' }}
						>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Coupon code</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ textAlign: 'center' }}>
											Coupon type
										</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes15'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Max Discount Amount</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes15'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Start date</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes15'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>End date</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes6'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes22'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
				case 'customers':
				return (
					<React.Fragment>
								<div
								className='blackspan65 weight700 floatleft'
								style={{ overflow: 'visible' }}>
										<div className='floatleft' style={{margin:'12px 5px 0 10px'}}>
											<input
												type='text'
												name='searchbysku'
												placeholder='Search by customer name or No.'
												value={this.state.search}
												style={{
													width: '240px',
													height:'42px',
													fontSize: '14px',
												}}
												onChange={(e) => this.handleChange('search', e)}
											></input>
											</div>
										<div className='floatleft' style={{marginTop:'16px',}}>
											{this.props.customer_loader?
											<ButtonLoader/>
											:<button
												className='btn btn_small btncolorprim btncard move'
												onClick={this.handleSearchClick}
											>
												Search
											</button>}
										</div>
										</div>
							{this.props.content?			<div
							className='middle border-box clearfix leftalign'
							style={{ marginTop: '5px' }}
						>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Customer Id</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ textAlign: 'center' }}>
												 Name
										</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Phone</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>GST Number</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Monthly Target</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes8'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes1'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>:null}	
								</React.Fragment>
								
							);
				case 'checkout':
					return(
						<React.Fragment>
						<div className='border-box d-flex' style={{margin:'.4rem 0 0 .2rem',justifyContent:'space-between'}}>
						<div className='border-box d-flex' style={{flex:1,}}>
							<div className='blackspan65 weight700 ' style={{ height: 'auto', overflow: 'visible',width:'15rem' }}>
								<div className="dropdown-check-listNew" style={{position:'relative'}} ref={this.brand}>
								<span className="selected-labelSpan">{this.state.selected_brand_radio? this.state.selected_brand_radio.brand_name:null}</span>

									<input
									type="text"
									placeholder="Brand"
									style={{fontSize:16,fontFamily:'inherit',padding: "16px 0px 4px 7px",background: "unset",margin: "unset",border: "solid 1px #d0d0d0",boxShadow: "none",}}
									value={this.state.searched_brand}
									onChange={(e) =>this.setState({searched_brand:e.target.value.toUpperCase(),fetched:false})}
									onClick={()=>this.setState({expandedBrand:true})}
													/>
								{this.state.middleContent && this.state.middleContent.length && this.state.expandedBrand
								? 
								<ul className="new_Ul" style={{maxHeight:300,width: "96%",}}>
								{this.state.searched_brand && this.state.searched_brand.length?this.handleBrandRadioSearch():this.state.middleContent.map((item)=>{
									return  (
									<li className="dropdown-check-newList">
									<label for={'radio'+item.brand_name} style={{width: "100%",boxSizing:'border-box'}}> 
									<input 
									type="radio" 
									id={"radio"+item.brand_name}
									value={item.brand_name} 
									checked={this.state.selected_brand_radio  && this.state.selected_brand_radio.brand_name==item.brand_name}
									onChange={(e)=>this.handleBrandRadioChange(e,item) } 
									/>
									<span 
									style={this.state.selected_brand_radio  && this.state.selected_brand_radio.brand_name==item.brand_name?{color: "green",fontWeight:600,fontSize:'.75rem',paddingLeft:'8px'}:{fontSize:'.75rem',paddingLeft:'8px'}}
									>
										{item.brand_name}
									</span> 
									</label>
								</li> )
								})}
								</ul>:null}
								</div>
								</div>	

							<div className='blackspan65 weight700 ' style={{ height: 'auto', overflow: 'visible',width:'15rem',marginLeft:'.5rem' }}>
								<MultiSelect
								options={this.state.subCategoryList && [{name:'SELECT',id:'ALL'},...this.state.subCategoryList].map((item)=>{
								return( {...item,label:item.name+'-'+item.id})
								})}
								labelKey='label'
								checkbox_id='id'
								placeholder='SubCategory'
								disabledItems={[]}
								defaultValue={''}
								multiple selectedOptions={this.state.selected_sub_ids}
								onChange={(value) =>this.handleSubCatDropdown(value)}
								/>
								</div>
								{!this.state.fetched ? this.state.loader_2 ?
                            		<ButtonLoader width={'6rem'}/>:
								<div className="border-box floatleft leftalign">
									<button
									className="btn btn_small btncolorprim btncard move btncloseextra"
									onClick={() => this.getCustomDeliveryData()}
									>
									Fetch Data
									</button>
								</div>:null}
								</div>

								{this.state.fetched ?
								<div className="border-box" style={{marginRight:'6.5rem'}}>
									{_.isEmpty(this.state.default_checkout_data)?
									this.state.loader_1?
									<div
									style={{height: "1.8rem",width: "3.7rem",}}
									>
									<ButtonLoader width={'100%'} height={'100%'}/>
									</div>:
									<button
									className="btn btn_small btncolorprim btncard move btncloseextra"
									onClick={() => this.customDeliveryChargeUpdate()}
									>
									Save
									</button>:
									this.state.loader_1?<div
									style={{height: "1.8rem",width: "3.7rem",}}
									>
									<ButtonLoader width={'100%'} height={'100%'}/>
									</div>:
									<button
									className="btn btn_small btncolorprim btncard move btncloseextra"
									onClick={() => this.customDeliveryChargeUpdate()}
									>
									Update
									</button>}
								</div>:null
								}
						</div>
					
						{this.state.fetched?
						<>
						<div className="border-box" style={{margin:'5rem 0 1rem 0',width:'25%',fontSize:'15px'}}>
                          <div className="border-box uppercased weight700 blackspan7" style={{margin:'0 0 0.5rem 10px'}}>
                            Delivery Charge
                          </div>
                          <input
						    style={{fontSize:'15px',marginLeft:'10px',height:'45px'}}
                            type="number"
							min="0"
                            placeholder="Delivery Charge"
							onKeyPress={event => this.validateNumber(event)}
                            value={this.state.checkout_data.delivery_charge}
                            onChange={(e)=>this.setState({checkout_data:{...this.state.checkout_data, delivery_charge:Number(e.target.value)}})}
                          />
                        </div>
						<div className="border-box clearfix fields" style={{display:'flex',flexDirection:'column',marginTop:'1rem'}}>
						<div style={{display:'flex',alignItems:'center'}}>
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7" style={{paddingLeft:'10px',fontSize:'15px'}}>
                          Payment Options
                        </div>
						{this.state.loader?<div style={{marginLeft:'1rem'}}>
								<ButtonLoader width={"6.5rem"}/>
							</div>:
							<button
								className="btn btn_small btncolorprim btncard move btncloseextra"
								onClick={() => this.setToDefaultPaymentMode()}
								style={{marginLeft:'1rem'}}
								>
								Set to Default
							</button>
							}
							</div>
								<div style={{padding:'1rem'}}>
								<div className="border-box paymentOptionsDiv" >
									<div className="border-box clearfix uppercased weight700 blackspan7">
									COD
									</div>
									<div className="toggleWid">
									<input
										type="checkbox"
										className="toggle-switch-checkbox"
										name="toggleSwitchWallet"
										id="cod"
										checked={this.state.checkout_data && this.state.checkout_data.payment_method && this.state.checkout_data.payment_method.is_visible}
										onChange={()=>{
											this.state.checkout_data.payment_method.is_visible =!(this.state.checkout_data && this.state.checkout_data.payment_method && this.state.checkout_data.payment_method.is_visible);
											this.setState({checkout_data:JSON.parse(JSON.stringify(this.state.checkout_data))})}}
									/>
									<label
										className="toggle-switch-label"
										htmlFor='cod'
									>
									<span className="toggle-switch-inner" />
									</label>
								</div>
								</div>
								</div>
							</div>
							</>:null}
							</React.Fragment>
								)
								
				case 'wallet':
					return (
					<>
					<div style={{ display:'flex', alignItems:'center',justifyContent:'space-between', margin: "8px 12px 0rem 12px",}}>
									<div
									className='blackspan65 weight700 floatleft'
									style={{ overflow: 'visible',display: "flex",alignItems: "center",gap: "0.2rem"}}>
											<div className='floatleft'>
												<input
													type='text'
													name='searchbysku'
													placeholder='Search by customer name or No.'
													value={this.state.search}
													style={{
														width: '240px',
														height:'42px',
														fontSize: '14px',
													}}
													onChange={(e) => this.handleChange('search', e)}
												></input>
												</div>
											<div className='floatleft'>
	
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleSearchClick}
												>
													Search
												</button>
											</div>
											</div>
											<div
								className='blackspan65 weight700 floatleft'
								style={{ marginRight: '3rem' }}>
								<div className='bulk-discount'>Upload Bulk Excel</div>
								<div style={{display:'flex'}}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												this.setState({ file: e.target.files[0] })
											}
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										/>
										{this.state.loader?<div
										>
										<ButtonLoader whiteColor={true}/>
										</div>:
									<form onSubmit={this.uploadBulkWallet}>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
										Upload
										</button>
									</form>}
									<form method="get" action={template}>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
											TEMPLATE
										</button>
									</form>
									</div>
									</div>
								</div>
								{this.props.content?			<div
								className='middle border-box clearfix leftalign'
								style={{ marginTop: '5px' }}
							>
								<div className='border-box blackspan65 weight700 dpmboxes1'>
									<table className='stdtable'>
										<tr>
											<td valign='center'></td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan65 weight700 dpm1boxes10'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>Customer Id</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan65 weight700 dpm1boxes20'>
									<table className='stdtable'>
										<tr>
											<td valign='center' style={{ textAlign: 'center' }}>
												Name
											</td>
										</tr>
									</table>
								</div>
								<div
									className='border-box blackspan65 weight700 dpm1boxes15'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>Phone</td>
										</tr>
									</table>
								</div>
								<div
									className='border-box blackspan65 weight700 dpm1boxes20'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>GST Number</td>
										</tr>
									</table>
								</div>
								<div
									className='border-box blackspan65 weight700 dpm1boxes20'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>GoApp Money</td>
										</tr>
									</table>
								</div>
								<div
									className='border-box blackspan65 weight700 dpm1boxes8'
								>
									<table className='stdtable'>
										<tr>
											<td valign='center'></td>
										</tr>
									</table>
								</div>
								<div
									className='border-box blackspan65 weight700 dpm1boxes1'
								>
									<table className='stdtable'>
										<tr>
											<td valign='center'></td>
										</tr>
									</table>
								</div>
							</div>:null}
							</>	
							);

				case 'agents':
				return (
					<React.Fragment>
							<div style={{display:'flex',gap:'.5rem',alignItems:'center',marginTop:'5px'}}>
								<div className='blackspan65 weight700 floatleft' style={{paddingLeft:'.5rem'}}>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add New Agent
												</button>
											</td>
										</tr>
									</table>
								</div>	
								<div style={{display:'flex'}}>					
                                <div
                                    className="blackspan65 weight700 floatleft"
                                    style={{height: 'auto', overflow: 'visible',marginTop:'5px'}}
                                >
                                    <table className="stdtable" style={{height: 'auto'}}>
                                        <tr>
                                            <td valign="center">
                                                <input
                                                    type="text"
                                                    className="field border-box tiny"
                                                    name="searchbysku"
                                                    placeholder="Search by Name or Phone"
                                                    style={{ width: "225px",fontSize: "14px"}}
                                                    onChange={(e) => this.handleChange("search", e)}
                                                /> 
                                            </td>
                                        </tr>
                                    </table>
                                </div>
								<div className="blackspan65 weight700 floatleft" style={{paddingLeft:'2px'}}>
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
												{this.props.agent_loader ?
												<ButtonLoader/>:
												<button
													id="summarybtn"
													className="btn btn_small btncolorprim btncard move"
													data-type=""
													onClick={this.handleSearchClick}>
													Search
												</button>}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
								</div>
							</div>

						<div
							className='middle border-box clearfix leftalign'
							style={{ marginTop: '5px' }}
						>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Customer Id</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center' style={{ textAlign: 'center' }}>
												 Name
										</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Phone</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes20'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Email</td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes1'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div
								className='border-box blackspan65 weight700 dpm1boxes1'
							>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);

			case 'master': {
				switch (this.props.masterTabCategory.category) {
					case 'brands_spares': {
						return this.getBrandView();
					}
					case 'categories_spares': {
						return this.getCategoryView();
					}
					case 'vehicles': {
						return this.getVehicleView();
					}
					default:
						return null;
				}
				break;
			}
			case 'brands_spares':
				return this.getBrandView();
				//#region brands code
				if (this.state.brandsList.length < 1) {
					this.getBrandsList();
				}
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box'>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												{this.state.brandsList && (
													<MultiSelect
														options={this.state.brandsList}
														labelKey='brand_name'
														checkbox_id='brand_name'
														placeholder='Brands'
														disabledItems={[]}
														selectedOptions={{
															brand_name: this.state.filter_key,
														}}
														onChange={(e) => {
															this.setState({ filter_key: e.brand_name });
														}}
													/>
												)}
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={() =>
														this.props.filterFunc(
															this.state.filter_key,
															'brands_spares'
														)
													}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesBrands}
												>
													Sync Brand
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Brand
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Slug</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Brand Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Brand SKU</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Default Discount</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Visible</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Spares</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is OEM</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes10'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is OES</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			//#endregion
			case 'pricing':
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box'>
								<div
									className='blackspan65 weight700 floatleft'
									style={{
										height: 'auto',
										overflow: 'visible',
									}}
								>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												{/* <input type="text" class="dashboardfields dashboardchangefields tiny" name="searchbysku" placeholder="Search by SKU" style={{ width: "200px" }} onChange={(e) => this.handleChange("search", e)}></input> */}
												<MultiSelect
													options={this.state.searchTerm}
													labelKey='sku_code'
													checkbox_id='product_id'
													placeholder='Search'
													disabledItems={[]}
													selectedOptions={this.state.searchedItem}
													// onSearch={this.getLineItems}
													onSearch={this.getLineItemsSearch}
													onChange={(value) =>
														this.setState(
															{
																//   rateFilter: {
																// ...this.state.rateFilter,
																searchedItem: value,
																//   },
															},
															() => this.afterFilterChange()
														)
													}
												/>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleSearchClick}
												>
													Search
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												{/* <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Download Excel</button> */}
												<a
													href='https://storage.googleapis.com/spares/spares/pricing_module_sample.xlsx
'
													target='_blank'
												>
													<button className='btn btn_small btnwhite btncard move'>
														TEMPLATE
													</button>{' '}
												</a>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<form onSubmit={this.onFormSubmitPricing}>
													<input
														type='file'
														onChange={this.onChange}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='submit'
														className='btn btn_small btnwhite btncard move'
													>
														Upload File
													</button>
												</form>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													type='submit'
													className='btn btn_small btnwhite btncard move'
													onClick={this.downloadExcelPricing}
												>
													Download Excel
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							{/* <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">User Name</td>
                                    </tr>
                                </table>
                            </div> */}
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>SKU Code</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Product ID</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes35'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>SKU Description</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Updated At</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes9'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			case 'vehicles':
				return this.getVehicleView();
				//#region vehicles code
				if (this.state.brands.length < 1) {
					this.getCarBrands();
				}
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box'>
								<div
									className='blackspan65 weight700 floatleft'
									style={{
										overflow: 'initial',
										height: 'auto',
									}}
								>
									<table className='stdtable'>
										<tr>
											<td valign='center' style={{ display: 'flex' }}>
												<MultiSelect
													options={this.state.brands}
													labelKey='name'
													checkbox_id='_id'
													placeholder='Select Make'
													disabledItems={[]}
													selectedOptions={this.state.selectedBrandForModel}
													onChange={(value, e) => {
														this.setState(
															{
																selectedBrandForModel: value,
															},
															(e) => {
																this.afterFilterChangeMake('brand');
																// this.handleChange(
																//     "make",
																//     value.name,
																//     this.state
																//         .brands
																// );
															}
														);
													}}
												/>
												<MultiSelect
													options={this.state.modelsForBrand}
													labelKey='name'
													checkbox_id='_id'
													placeholder='Select Model'
													disabledItems={[]}
													selectedOptions={this.state.selectedModel}
													onChange={(value, e) =>
														this.setState(
															{
																selectedModel: value,
															},
															(e) => {
																// this.afterFilterChange(
																//     "model"
																// );
																// this.handleChange(
																//     "model",
																//     value.name
																// );
															}
														)
													}
												/>
												{/* <input
                                                        type="text"
                                                        class="dashboardfields dashboardchangefields"
                                                        name="searchbysku"
                                                        placeholder="Search Compatilbilty Group ID"
                                                        value={this.state.search}
                                                        style={{
                                                            width: "270px",
                                                            fontSize: "16px",
                                                        }}
                                                        onChange={(e) =>
                                                            this.handleChange(
                                                                "search",
                                                                e
                                                            )
                                                        }
                                                    ></input> */}
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleSearchClickVehicle}
												>
													Search
												</button>
											</td>
										</tr>
									</table>
								</div>

								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesVehicle}
												>
													Sync Vehicles
												</button>
											</td>
										</tr>
									</table>
								</div>

								{_.get(this.state, ['selectedBrandForModel', 'name'], null) !=
									null &&
									_.get(
										this.state,
										['selectedBrandForModel', 'name'],
										null
									) && (
										<div className='border-box floatright rightalign'>
											<button
												class='btn btn_small btncolorprim btncard move btncloseextra'
												onClick={() => this.handleAddNewVariant()}
											>
												Add New Varient
											</button>
										</div>
									)}
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Make</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Model</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes35'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Variant</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Fuel</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpmboxes9'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			//#endregion
			case 'categories_spares':
				return this.getCategoryView();
				//#region categories code
				if (this.state.segmentsList.length < 1) {
					this.getSegmentList();
				}
				console.log(this.state.segmentsList);
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box'>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												{this.state.segmentsList && (
													<MultiSelect
														options={this.state.segmentsList}
														labelKey='name'
														checkbox_id='_id'
														placeholder='Segment'
														disabledItems={[]}
														selectedOptions={{ _id: this.state.filter_key }}
														onChange={(e) => {
															this.setState({ filter_key: e._id });
														}}
													/>
												)}
											</td>
										</tr>
									</table>
								</div>

								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={() =>
														this.props.filterFunc(
															this.state.filter_key,
															'categories_spares'
														)
													}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												{_.get(this.props, ['category'], []).length && (
													<MultiSelect
														options={this.props.category}
														labelKey='slug'
														checkbox_id='slug'
														placeholder='Category'
														disabledItems={[]}
														selectedOptions={{
															slug: this.state.category_filter_key,
														}}
														onChange={(e) => {
															this.setState({ category_filter_key: e.slug });
														}}
													/>
												)}
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={() =>
														this.props.filterCategory(
															this.state.category_filter_key
														)
													}
												>
													Filter
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='blackspan65 weight700 floatright'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesCatgeory}
												>
													Sync Category
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesSegment}
												>
													Sync Segment
												</button>
											</td>
											<td valign='center'>
												<button
													id='addleadbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.handleAddClick}
												>
													Add Category
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className='middle border-box clearfix leftalign'>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Slug</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Display Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Segment</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Visible</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Updated At</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes3'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);
			//#endregion
			case 'download': {
				if (_.get(this.state, ['brands'], []).length < 1) {
					this.getCarBrands();
				}
				if (_.get(this.state, ['segmentsList'], []).length < 1) {
					this.getSegmentList();
				}
				if (_.get(this.state, ['middleContent'], []).length < 1) {
					this.getCMSBrands();
				}
				if (this.state.subCategoryList.length < 1) {
					this.getSubCategoriesList();
				}
				return (
					<React.Fragment>
						<div className='top border-box leftalign'>
							<div className='border-box'>
								<div
									className='blackspan65 weight700 floatleft'
									style={{ height: 'auto', overflow: 'visible' }}
								>
									<table className='stdtable' style={{ height: 'auto' }}>
										<tr>
											<td valign='center'>
												<MultiSelect
													options={this.state.downloadOptions}
													labelKey='name'
													checkbox_id='name'
													placeholder='Download Option'
													disabledItems={[]}
													selectedOptions={this.state.downloadOption}
													onChange={(value, e) => {
														this.setState({
															downloadOption: value,
															selected_segment: null,
															selected_category: null,
															selectedBrandForModel: null,
															selectedAttributesList: [],
														});
													}}
												/>
											</td>
										</tr>
									</table>
								</div>

								{this.state.downloadOption.name === 'Brand wise' && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.middleContent}
														labelKey='sku_brand'
														checkbox_id='sku_brand'
														placeholder='Brand'
														disabledItems={[]}
														selectedOptions={this.state.selectedBrandForModel}
														onChange={(value, e) => {
															this.setState({
																selectedBrandForModel: value,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}
								{this.state.downloadOption.name === 'Vehicle wise' && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.brands}
														labelKey='name'
														checkbox_id='_id'
														placeholder='Brand'
														disabledItems={[]}
														selectedOptions={this.state.selectedBrandForModel}
														onChange={(value, e) => {
															this.setState({
																selectedBrandForModel: value,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}
								{this.state.downloadOption.name === 'Category wise' && (
									<>
										<div
											className='blackspan65 weight700 floatleft'
											style={{ height: 'auto', overflow: 'visible' }}
										>
											<table className='stdtable' style={{ height: 'auto' }}>
												<tr>
													<td valign='center'>
														{this.state.brandsList && (
															<MultiSelect
																options={
																	this.state.segmentsList
																		? this.state.segmentsList
																		: []
																}
																labelKey='name'
																checkbox_id='name'
																placeholder='Segment'
																disabledItems={[]}
																selectedOptions={{
																	name: _.get(
																		this.state,
																		['selected_segment', 'name'],
																		null
																	),
																}}
																onChange={(e) => {
																	this.getCategoryList(e._id);
																	this.setState({
																		selected_segment: e,
																	});
																}}
															/>
														)}
													</td>
												</tr>
											</table>
										</div>
										<div
											className='blackspan65 weight700 floatleft'
											style={{ height: 'auto', overflow: 'visible' }}
										>
											<table className='stdtable' style={{ height: 'auto' }}>
												<tr>
													<td valign='center'>
														<MultiSelect
															options={_.get(this.state, ['categoryList'], [])}
															labelKey='display_name'
															checkbox_id='display_name'
															placeholder='Category'
															disabledItems={[]}
															selectedOptions={{
																display_name: this.state.selected_category
																	? this.state.selected_category.display_name
																	: null,
															}}
															onChange={(e) => {
																this.setState({
																	selected_category: e,
																});
															}}
														/>
													</td>
												</tr>
											</table>
										</div>
									</>
								)}

								{this.state.downloadOption.name === 'Vehicle wise' && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.modelsForBrand}
														labelKey='name'
														checkbox_id='_id'
														placeholder='Select Model'
														disabledItems={[]}
														selectedOptions={this.state.selectedModel}
														disabled={!this.state.selectedBrandForModel}
														onChange={(value, e) =>
															this.setState(
																{
																	selectedModel: value,
																},
																(e) => {
																	this.handleSearchClickVehicle();
																}
															)
														}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}

								{this.state.downloadOption && (
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={this.state.attributesList}
														labelKey='name'
														checkbox_id='id'
														placeholder='Attributes'
														disabledItems={[]}
														multiple
														selectedOptions={this.state.selectedAttributesList}
														onChange={(value, e) => {
															this.setState({
																selectedAttributesList: value,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
								)}
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													id='filterbtn'
													className='btn btn_small btncolorprim btncard move'
													onClick={this.fetchDownloadData}
												>
													Fetch
												</button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}
			case 'upload':
				if (this.state.segmentsList.length < 1) {
					this.getSegmentList();
				}
				if (this.state.subCategoryList.length < 1) {
					this.getSubCategoriesList();
				}

				return (
					<>
						<div className='top border-box leftalign'>
							<div className='border-box '>
								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatright'
										style={{
											overflow: 'visible',
											marginTop: '12px',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Part Add/Edit
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														onChange={this.onChange}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='submit'
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<a
													href='https://storage.googleapis.com/spares/spares/parts_sample_final.xlsx'
													target='_blank'
												>
													<button className='btn btn_small btnwhite btncard move'>
														TEMPLATE
													</button>{' '}
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatright'
										style={{ overflow: 'visible' }}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Part Compatibility
											</div>
											<div>
												<form onSubmit={this.onFormSubmitCompatibility}>
													<input
														type='file'
														onChange={this.onChange}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>

													<button
														type='submit'
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<a href='https://storage.googleapis.com/spares/spares/compatibility_sample.xlsx'>
													<button className='btn btn_small btnwhite btncard move'>
														TEMPLATE
													</button>
												</a>
											</div>
										</div>
									</div>
								</div>

								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatright'
										style={{
											overflow: 'visible',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Categorization
											</div>
											<div>
												<form onSubmit={this.onFormSubmit}>
													<input
														type='file'
														// onChange={this.onChange}
														onChange={(e) =>
															this.setState({ file: e.target.files[0] })
														}
														accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
													/>
													<button
														type='button'
														onClick={this.uploadCategoryFile}
														className='btn btn_small btnwhite btncard move'
													>
														Upload
													</button>
												</form>
											</div>
											<div>
												<button
													className='btn btn_small btnwhite btncard move'
													onClick={API.getCategorizationSampleCSV}
												>
													TEMPLATE
												</button>{' '}
											</div>
											{/* <div>
                      <a
                        href="https://storage.googleapis.com/spares/spares/parts_sample_final.xlsx"
                        target="_blank"
                      >
                        <button className="btn btn_small btnwhite btncard move">
                          TEMPLATE
                        </button>{' '}
                      </a>
                    </div> */}
										</div>
									</div>
								</div>

								<div className='upload-row' style={{ marginBottom: '0' }}>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ overflow: 'visible' }}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '25vw',
													marginTop: '16px',
												}}
											>
												Specifications
											</div>
											<div>
												<input
													type='file'
													onChange={this.onChange}
													accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
												/>

												<button
													type='button'
													onClick={this.uploadSpecificationCSV}
													className='btn btn_small btnwhite btncard move'
												>
													Upload
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='upload-row border-bottom'>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={
															this.state.segmentsList
																? this.state.segmentsList
																: []
														}
														labelKey='name'
														checkbox_id='name'
														placeholder='Segment'
														disabledItems={[]}
														selectedOptions={{
															name: _.get(
																this.state,
																['selected_segment', 'name'],
																null
															),
														}}
														onChange={(e) => {
															this.getCategoryList(e._id);
															this.setState({
																selected_segment: e,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={_.get(this.state, ['categoryList'], [])}
														labelKey='display_name'
														checkbox_id='display_name'
														placeholder='Category'
														disabledItems={[]}
														selectedOptions={{
															display_name: this.state.selected_category
																? this.state.selected_category.display_name
																: null,
														}}
														onChange={(e) => {
															this.setState({
																selected_category: e,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div
										className='blackspan65 weight700 floatleft'
										style={{ height: 'auto', overflow: 'visible' }}
									>
										<table className='stdtable' style={{ height: 'auto' }}>
											<tr>
												<td valign='center'>
													<MultiSelect
														options={_.get(
															this.state,
															['subCategoryList'],
															[]
														).filter(
															(item) =>
																item.category_name ===
																_.get(
																	this.state,
																	['selected_category', 'display_name'],
																	null
																)
														)}
														labelKey='name'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
														selectedOptions={{
															name: _.get(
																this.state,
																['selected_sub_category', 'name'],
																null
															),
														}}
														onChange={(value, e) => {
															this.setState({
																selected_sub_category: value,
															});
														}}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div className='blackspan65 weight700 floatleft'>
										<table className='stdtable'>
											<tr>
												<td valign='top'>
													<div>
														<button
															className='btn btn_small btnwhite btncard move'
															onClick={this.getSampleSpecificationCSV}
															disabled={
																!this.state.selected_segment ||
																!this.state.selected_sub_category ||
																!this.state.selected_category
															}
														>
															TEMPLATE
														</button>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</>
				);
			case 'spares_banner':
				return (
					<React.Fragment>
						<div className='top border-box clearfix leftalign'>
							<div className='banner-tab-container '>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'web' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'web'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										onClick={() => {
											this.callSparesBannerList('web');
										}}
									>
										Web
									</button>
								</div>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'app' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'app'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										onClick={() => {
											this.callSparesBannerList('app');
										}}
									>
										APP
									</button>
								</div>
							</div>
							<div
								className='blackspan65 weight700 floatright'
								style={{ overflow: 'visible', marginTop: '12px' }}
							>
								<div className='blackspan65 weight700 floatleft'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
											{this.state.loader?
												<ButtonLoader width={'6.5rem'}/>:
												<button
													className='btn btn_small btncolorprim btncard move'
													onClick={this.syncSparesBanners}>
													Sync Banners
												</button>}
											</td>
										</tr>
									</table>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div
										type='submit'
										className='btn btnwhite btncard move'
										onClick={this.addBannerHnadler}
									>
										ADD NEW BANNER
									</div>
								</div>
							</div>
							{this.state.bannerType === 'web' ? (
								<div className='bannerSize'>Banner Size should be: 943*384</div>
							) : (
								<div className='bannerSize'>
									Banner Size should be: 1020*495
								</div>
							)}
						</div>
						<div
							className='middle border-box clearfix leftalign'
							style={{ top: '180px' }}
						>
							<div className='border-box blackspan65 weight700 dpmboxes1'>
								<table className='stdtable'>
									<tr>
										<td valign='center'></td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Name</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes20'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Image</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Order</td>
									</tr>
								</table>
							</div>
							<div className='border-box blackspan65 weight700 dpm1boxes15'>
								<table className='stdtable'>
									<tr>
										<td valign='center'>Is Visible</td>
									</tr>
								</table>
							</div>
						</div>
					</React.Fragment>
				);

			case 'spares_payment':
				return (
					<React.Fragment>
						<div
							className='middle border-box clearfix leftalign'
							style={{ top: '5px' }}
						>
							{/* <div className='banner-tab-container d-flex'>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'web' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'web'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										// onClick={() => {
										// 	this.callSparesBannerList('web');
										// }}
									>
										OVERALL
									</button>
								</div>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'app' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'app'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										// onClick={() => {
										// 	this.callSparesBannerList('app');
										// }}
									>
										BRAND
									</button>
								</div>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'app' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'app'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										// onClick={() => {
										// 	this.callSparesBannerList('app');
										// }}
									>
										SEGMENT
									</button>
								</div>
								<div
									className={`blackspan65 weight700 floatright height-100 ${
										this.state.bannerType === 'app' && 'active-banner-tab'
									}`}
								>
									<button
										id='mapleadbtn'
										style={{ padding: '0.5rem 1rem' }}
										className={
											this.state.bannerType === 'app'
												? 'btn btn_small btncolorprim btncard move'
												: 'btn btn_small btncard move'
										}
										// onClick={() => {
										// 	this.callSparesBannerList('app');
										// }}
									>
										CATEGORY
									</button>
								</div>
							</div> */}
							<div className='d-flex' style={{marginTop: "10rem",marginLeft: "1.5rem",display: "flex",}}>
								<div className='border-box blackspan65 weight700 dpmboxes1'>
									<table className='stdtable'>
										<tr>
											<td valign='center'></td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan65 weight700 dpm1boxes15'>
									<table className=''>
										<tr>
											<td valign='center'>Name</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan65 weight700 dpm1boxes35'>
									<table className=''>
										<tr>
											<td valign='center'>Is Visible</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				);

			default:
				return null;
		}
	}
}

export default MiddleUpper;
