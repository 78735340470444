import React, { Fragment, useEffect, useState, useRef } from "react";
import MultiSelect from "../broadcast/MultiSelect/MultiSelect";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import API from "../api";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from "rxjs";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

const ManageCoupons = (props) => {
  const {
    handleButtonClick,
    brandsList = [],
    categoriesList = [],
    type,
    couponData,
    pageRefresh,
  } = props;
  const [discountAppliedOn, setDiscountAppliedOn] = useState(new Set([..._.get(couponData, ["discount_on"], [])] ))
  const [onCustomerList, setOnCustomerList] = useState(new Subject());
  const [expanded, setExpanded] = useState(true)

  const ref = useRef(null);

  const couponType = [
    { label: "FREE", value: "FREE" },
    { label: "PERCENT", value: "PCT" },
  ];
  const formatDate=(date)=>{
    const year=new Date(date).getFullYear()
    const month=new Date(date).getMonth()+1
    const day=new Date(date).getUTCDate()
    return day+'/'+month+'/'+year
  }

  const mandateFields = [
    { key: "code", err_msg: "Coupon code" },
    { key: "description", err_msg: "description" },
    { key: "display_name", err_msg: "Display Name" },
    { key: "start_time", err_msg: "Start time" },
    { key: "end_time", err_msg: "End time" },
    { key: "coupon_type", err_msg: "Coupon Type" },
    { key: "amount", err_msg: "Minimum Cart Value" }, 
  ];

  let dateStart=_.get(couponData, ["start_time"], null)
	let dateEnd=_.get(couponData, ["end_time"], null)

  const [couponObj, setcouponObj] = useState({
    is_b2b: _.get(couponData, ["is_b2b"], false),
    description: _.get(couponData, ["description"], null),
    saving_title: _.get(couponData, ["saving_title"], null),  
    over_all_lmt: _.get(couponData, ["over_all_lmt"], null),  
    lmt_per_user: _.get(couponData, ["lmt_per_user"], null),
    discount_on: _.get(couponData, ["discount_on"], null),
    code: _.get(couponData, ["code"], null),
    percentage: _.get(couponData, ["percentage"], 0),
    /*usethis*/ max_discount_amount: _.get(
      couponData,
      ["max_discount_amount"],
      null
    ),
    // /*usethis*/ minimum_order: _.get(couponData, ["minimum_order"], 0),
   cod_available: _.get(couponData, ["cod_available"], false), 
          /*usethis*/cod_visible: _.get(couponData, ["cod_visible"], false),
      /*usethis*/cod_enabled: _.get(couponData, ["cod_enabled"], false), 

    /*usethis minimum cart value*/ amount:
      couponData && couponData.amount
        ? _.get(couponData, ["amount"], 0)
        : Object.keys(_.get(couponData, ["cart_value_map"], 0))[0] ||
          Object.keys(_.get(couponData, ["ficv_map"], 0))[0],
    /*usethis*/ start_time: dateStart?dateStart.slice(0,10):null,
    /*usethis*/ end_time: dateEnd?dateEnd.slice(0,10):null,
    // applicable_per_order: 1,
    brands: _.get(couponData, ["sku_brand"], []).map((brand)=>({brand_name:brand})),
    coupon_type: _.get(couponData, ["coupon_type"], null),
    discount_type: couponData && _.get(couponData, ["discount_type"], null),
    display_name: _.get(couponData, ["code"], null),
    product_id:
      couponData && couponData.discount_type==="FICV"?Object.values(_.get(couponData, ["ficv_map"], []))[0]:_.get(couponData, ["prod_map"], [])[0],
    is_display: _.get(couponData, ["is_display"], false),
    categories: _.get(couponData, ["cat_map"], []).map((category)=>({name:category})),
    customerList:_.get(couponData,["customerList"],[]),
    selectedCustomer:Object.values(_.get(couponData,["customer_ids"],[])),
    couponLoader:false
  });

  function getBrandNameFromSkuBrand(brand) { 
    if (brandsList && brandsList.length) {
      let new_brand = brandsList.find((list) => list.sku_brand == brand);
      return new_brand.brand_name ? new_brand.brand_name : '';
    }
  }

  useEffect(() => {
    if (brandsList?.length) {
      setcouponObj({
        ...couponObj, 
        brands: _.get(couponData, ["sku_brand"], []).map((brand)=>({brand_name: getBrandNameFromSkuBrand(brand), sku_brand: brand}))
      })
    }
  }, [brandsList]);

  useEffect(() => {
    const subscription = onCustomerList.pipe(debounceTime(1500),
        distinctUntilChanged())
      .subscribe((searchString) => {        
        if (searchString && searchString !== '') {
          API.getCustomersList(searchString.value).then((res)=>setcouponObj({...searchString.couponObj, customerList:res.data.data})).catch(err=>console.log(err))
        }
      })
    return () => subscription.unsubscribe();
  }, [onCustomerList]);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
  };

  useEffect(() => {
    setExpanded(false)
    document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }
  const onInputChange = (e, keyName, type,item,data) => {
    let temp = { ...couponObj };
    if (type === "number") {
      if (
        temp.coupon_type === "FLAT_PRICE_INC" &&
        keyName === "percentage"
      ) {
        temp[keyName] = Number(e.target.value);
        temp["max_discount_amount"] = Number(e.target.value);
      } else {
        temp[keyName] = Number(e.target.value);
      }
    }
    else if(keyName==='selectedCustomer' && type==='checkbox'){
      if(e.target.checked){
        temp.customerList[temp.customerList.findIndex((i) => i === item)].selected=true
        let array=[...temp.selectedCustomer]
        temp.customerList.map((data)=>{
          if(data.selected){
            let item={
              first_name:data.first_name,
              phone_number:data.phone_number,
              customer_id:data.customer_id
            }
            array.push(item)
      
          }})
        //To avoid duplicacy in array
        const filteredArr = array.reduce((acc, current) => {
          const x = acc.find(item => item.customer_id === current.customer_id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        temp[keyName] = filteredArr
      }else{
        let array2=[...temp.selectedCustomer]
        array2.splice(array2.indexOf(data),1)

        temp[keyName] = array2 
      }
    }
    
    else {
      temp[keyName] = e.target.value;
    }
    setcouponObj({ ...temp });
  };

  const saveCouponDetails = () => {
    const errStr = mandateFields.find((item) => !couponObj[item.key]);
    if (errStr) {
      NotificationManager.error(
        `${errStr.err_msg} is required.`,
        "Error!!",
        5000
      );
      return;
    }
    const data = JSON.parse(JSON.stringify(couponObj));
    data.is_b2b = couponObj.is_b2b;
    data.discount_on =[...discountAppliedOn]
    data.code = data.code.toUpperCase();
    data.display_name = data.display_name.toUpperCase();
    data.start_time = formatDate(couponObj.start_time);
		data.end_time=formatDate(couponObj.end_time)
    data.discount_type=couponObj.discount_type;
    data.saving_title=couponObj.saving_title;
    data.over_all_lmt=couponObj.over_all_lmt
    data.lmt_per_user=couponObj.lmt_per_user
    data.customer_ids=couponObj.selectedCustomer.map(item=>item.customer_id)
    data.selectedCustomer=couponObj.selectedCustomer
    delete data.customerList   
    if(data.customer_ids && data.customer_ids.length){
      data.is_display=false 
    }  
  
    if( data.coupon_type==="FREE" ){
      data.amount=couponObj.amount;
      data.product_id=couponObj.product_id;
      delete data.categories
      delete data.brands
      delete data.percentage  
      delete data.max_discount_amount  
    }
  
    if( data.coupon_type==="PCT"){
      delete data.selectedCustomer
     if(data.brands.length>0){
       data.brands=data.brands.map((brands)=> brands.sku_brand)
      delete data.categories 
      delete data.product_id

     }
     else if(data.categories.length>0 ){
       data.categories=data.categories.map((category)=>category.name)
      delete data.brands 
      delete data.product_id

     }else
     if(data.product_id){ 
      delete data.brands 
      delete data.categories
     }else
     if(data.amount){
      data.percentage= couponObj.percentage
      delete data.brands 
      delete data.categories
      delete data.product_id
     }
   }
   
    if (type === "add") {
      setcouponObj({...couponObj,couponLoader:true})
      API.addCoupon(data)
        .then(() => {
           setcouponObj({...couponObj,couponLoader:false})
          pageRefresh();
          handleButtonClick();
          NotificationManager.success("Coupon created.", "Success", 5000);
        })
        .catch((error) => {
          setcouponObj({...couponObj,couponLoader:false})
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
          console.error(error);
        });
    } else {
      setcouponObj({...couponObj,couponLoader:true})
      API.updateCoupon(data)
        .then(() => {
          setcouponObj({...couponObj,couponLoader:false})
          pageRefresh();
          handleButtonClick();
          NotificationManager.success(
            "Coupon updated successfully.",
            "Success",
            5000
          );
        })
        .catch((error) => {
          setcouponObj({...couponObj,couponLoader:false})
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
          console.error(error);
        });
    }
  };

  function convertDateInStandardFormat() {
		let format = new Date();
		return format.toISOString().split('T')[0];
	}

  return (
    <div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box">
      <div className="extramainpanel border-box leftalign">
        <div className="border-box clearfix title">
          <div
            className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
            style={{ padding: "0px 0px 0px 10px" }}
          >
            {type === "edit" ? "Edit Coupon Details" : "Add Coupon Details"}
          </div>
          <div className="border-box floatright rightalign">
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={() => handleButtonClick("close")}
            >
              Close
            </button>
          </div>
        </div>

        <div className="border-box fields">
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Coupon Code<sup>*</sup>
            </div>
            <input
              type="text"
              placeholder="Coupon Code"
              value={couponObj.code}
              className="field border-box small uppercased"
              onChange={(e) => onInputChange(e, "code", "string")}
              readOnly={type === "edit"}
            />
          </div>
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Coupon Display Name<sup>*</sup>
            </div>
            <input
              type="text"
              placeholder="Coupon Display name"
              value={couponObj.display_name}
              className="field border-box small uppercased"
              onChange={(e) => onInputChange(e, "display_name", "string")}
            />
          </div>
        </div>

        <div className="border-box fields">
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Coupon Type<sup>*</sup>
            </div>
            <MultiSelect
              options={couponType}
              labelKey="value"
              checkbox_id="value"
              placeholder="Coupon Type"
              disabledItems={[]}
              hideOnBlur
              selectedOptions={{ value: couponObj.coupon_type }}
              onChange={(e) => {if(type==='edit' && e.value!==_.get(couponData, ["coupon_type"], null)){
                NotificationManager.error(
                  "Please disable this coupon & create a new one",
                  "Error",
                );
                return }
                setcouponObj({
                  ...couponObj,
                  coupon_type: e.value,
                });
              }}
            />
          </div>

          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Coupon Description<sup>*</sup>
            </div>
            <input
              type="text"
              placeholder="Coupon Description"
              value={couponObj.description}
              className="field border-box small"
              onChange={(e) => onInputChange(e, "description", "string")}
            />
          </div>
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Saving Title<sup>*</sup>
            </div>
            <input
              type="text"
              placeholder="Saving Title"
              value={couponObj.saving_title}
              className="field border-box small"
              onChange={(e) => onInputChange(e, "saving_title", "string")}
            />
          </div>
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Overall Limit<sup>*</sup>
            </div>
            <input
              type="number"
              placeholder="Overall Limit"
              value={couponObj.over_all_lmt}
              className="field border-box small"
              onChange={(e) => onInputChange(e, "over_all_lmt", "number")}
              min="0"
            />
          </div>
        </div>
        <div className="border-box clearfix fields" style={{display:"flex"}}>
        <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Limit Per User<sup>*</sup>
            </div>
            <input
              type="number"
              placeholder="Limit Per User"
              value={couponObj.lmt_per_user}
              className="field border-box small"
              onChange={(e) => onInputChange(e, "lmt_per_user", "number")}
              min="0"
            />
          </div>
          <div className="border-box field2" style={{ width: "25%" }}>
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Is Visible
            </div>
            <div style={{ width: "50%" }}>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchWallet"
                id="toggleSwitchWallet"
                onChange={() =>
                  setcouponObj((prev) => ({
                    ...prev,
                    is_display: !prev.is_display,
                  }))
                }
                defaultChecked={couponObj.is_display}
              />
              <label
                className="toggle-switch-label"
                htmlFor="toggleSwitchWallet"
              >
                <span className="toggle-switch-inner" />
                {/* <span className="toggle-switch-switch" /> */}
              </label>
            </div>
          </div>
        </div>

        {couponObj.coupon_type !== "FREE" ? (
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                {couponObj.coupon_type === "FLAT_PRICE_INC"
                  ? "Amount"
                  : "Percentage"}
                <sup>*</sup>
              </div>
              <input
                type="number"
                placeholder="Percentage"
                value={couponObj.percentage}
                min="0"
                className="field border-box small"
                onChange={(e) => onInputChange(e, "percentage", "number")}
              />
            </div>
            {couponObj.coupon_type !== "FLAT_PRICE_INC" && (
              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Max Discount Amount
                </div>
                <input
                  type="number"
                  placeholder="Max Discount Amount"
                  value={couponObj.max_discount_amount}
                  min="0"
                  className="field border-box small"
                  onChange={(e) =>
                    onInputChange(e, "max_discount_amount", "number")
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        <div
          className="border-box fields"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {/* <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              OVERALL LIMIT<sup>*</sup>
            </div>
            <input
              type="number"
              placeholder="Overall limit"
              value={couponObj.over_all_lmt}
              min="0"
              className="field border-box small"
              onChange={(e) => onInputChange(e, "over_all_lmt", "number")}
            />
          </div> */}
         
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Product ID {couponObj.coupon_type == "FREE" ? <sup>*</sup> : null}
            </div>
            <input
              type="text"
              placeholder="Product ID"
              value={couponObj.product_id}
              className="field border-box small"
              onChange={(e) => onInputChange(e, "product_id", "string")}
              readOnly={couponObj.brands&& couponObj.brands.length || couponObj.categories&& couponObj.categories.length}
            />
          </div>
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Minimum Cart Value
            </div>
            <input
              type="number"
              placeholder="Cart Value"
              value={couponObj.amount ? couponObj.amount : null}
              className="field border-box small"
              onChange={(e) => onInputChange(e, "amount", "number")}
              min="0"
            />
          </div>
        </div>

        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Start Date<sup>*</sup>
            </div>
            <input
              type="date"
              name="start_time"
              className="field border-box small"
              value={couponObj.start_time}
              onChange={(e) => onInputChange(e, "start_time", "date")}
              min={convertDateInStandardFormat()}
            />
          </div>
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              End Date<sup>*</sup>
            </div>
            <input
              type="date"
              name="end_time"
              className="field border-box small"
              value={couponObj.end_time}
              onChange={(e) => onInputChange(e, "end_time", "date")}
              min={convertDateInStandardFormat()}
            />
          </div>
        </div>

        {couponObj.coupon_type !== "FREE" ? (
          <div className="border-box fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Brand
              </div>
              {brandsList && (
         
                <MultiSelect
														options={brandsList}
														labelKey='brand_name'
														checkbox_id='brand_name'
														placeholder='Brands'
														disabledItems={[]}
                            disabled={couponObj.product_id && couponObj.product_id.length || couponObj.categories && couponObj.categories.length}
														multiple
														selectedOptions={ couponObj.brands }
														onChange={(value, e) => {
                              setcouponObj(
                                { ...couponObj, brands: value, });
														}}
													/>
              )}
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Categories
              </div>
              <MultiSelect
														options={categoriesList}
														labelKey='name'
														checkbox_id='name'
														placeholder='Category'
														disabledItems={[]}
                            disabled={couponObj.brands && couponObj.brands.length || couponObj.product_id && couponObj.product_id}
														multiple
														selectedOptions={ couponObj.categories }
														onChange={(value, e) => {
                              setcouponObj(
                                { ...couponObj, categories: value, });
														}}
													/>

            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="border-box fields" style={{display:'flex'}}>
            <div className="border-box field2" style={{position:'relative'}} 
                ref={ref}>
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Customers
              </div>
              <div className="dropdown-check-listNew" style={{position:'relative'}}>
              <span className="selected-labelSpan">{couponObj.selectedCustomer?couponObj.selectedCustomer.map(item=> item.first_name+'-'+item.phone_number+ ' | '):null}</span>
                <input
                type="text"
                placeholder="Search customer by name or phone number"
                className="field border-box small"
                style={{height:'40px',fontSize:'16px',padding:'12px 0 0 7px'}}
								onChange={(e) =>{
                onCustomerList.next({ value: e.target.value, couponObj: couponObj });
                 }}
                onClick={()=>setExpanded(true)}
								/>
             {couponObj.customerList && couponObj.customerList && expanded? 
             <ul className="new_Ul">
              {couponObj.customerList && couponObj.customerList.map((item)=>{
                  let data={
                    first_name:item.first_name,
                    phone_number:item.phone_number,
                    customer_id:item.customer_id
                  }
                return  (
                <li className="dropdown-check-newList">
                  <label for={'checkname'+data.first_name}> 
                  <input type="checkbox" id={"checkname"+data.first_name}  value={data.first_name+'-'+data.phone_number} checked={couponObj.selectedCustomer && couponObj.selectedCustomer.some(item=>shallowEqual(item,data))} onChange={(e)=>onInputChange(e,'selectedCustomer','checkbox',item,data) } ></input>
                  <span style={couponObj.selectedCustomer && couponObj.selectedCustomer.some(item=>shallowEqual(item,data))?{color: "green",fontWeight:600,fontSize:'.75rem',paddingLeft:'8px'}:{fontSize:'.75rem',paddingLeft:'8px'}}>{data.first_name+'-'+data.phone_number}</span> 
                </label>
               </li> )
               })}
               </ul>:null}
               </div>
            </div>
              <div className="border-box field2" style={{ width: "25%" }}>
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              COD Visible
            </div>
            <div style={{ width: "50%" }}>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchUPI3"
                id="toggleSwitchUPI3"
                onChange={() =>
                  setcouponObj((prev) => ({
                    ...prev,
                    cod_visible: !prev.cod_visible,
                  }))
                }
                defaultChecked={couponObj.cod_visible}
                // checked={this.state.payment_methods[2].is_enabled}
              />
              <label className="toggle-switch-label" htmlFor="toggleSwitchUPI3">
                <span className="toggle-switch-inner" />
                {/* <span className="toggle-switch-switch" /> */}
              </label>
            </div>
          </div>

          <div className="border-box field2" style={{ width: "25%" }}>
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              COD Enabled
            </div>
            <div style={{ width: "50%" }}>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchUPI1"
                id="toggleSwitchUPI1"
                onChange={() =>
                  setcouponObj((prev) => ({
                    ...prev,
                    cod_enabled: !prev.cod_enabled,
                  }))
                }
                defaultChecked={couponObj.cod_enabled}
                // checked={this.state.payment_methods[2].is_enabled}
              />
              <label className="toggle-switch-label" htmlFor="toggleSwitchUPI1">
                <span className="toggle-switch-inner" />
                {/* <span className="toggle-switch-switch" /> */}
              </label>
            </div>
          </div>
          </div>
        <div
          className="border-box fields"
        >
         <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Discount on
            </div>
            <form action="/action_page.php" style={{position:"relative"}}>
              <input
                type="checkbox"
                className="discountForm"
                name="vehicle1"
                value="APP"
                onChange={(e)=>{if(e.target.checked){
                setDiscountAppliedOn(new Set([...discountAppliedOn,"APP"]))}
                else{
                  let tempDiscountAppliedOn=discountAppliedOn; 
                  tempDiscountAppliedOn.delete("APP")
                  setDiscountAppliedOn(new Set(tempDiscountAppliedOn))
                }
                }
                }
                defaultChecked={couponData && [..._.get(couponData, ["discount_on"], [])].includes("APP")?true:false}
              />
              <label for="App" style={{position:"absolute",top:"4px"}}> APP</label>
              <input
              style={{marginLeft:"3rem"}}
                type="checkbox"
                name="WEBSITE"
                className="discountForm"
                value="WEBSITE"
                onChange={(e)=>{if(e.target.checked){
                setDiscountAppliedOn(new Set([...discountAppliedOn,"WEBSITE"]))}
                else{
                  let tempDiscountAppliedOn=discountAppliedOn; 
                  tempDiscountAppliedOn.delete("WEBSITE")
                  setDiscountAppliedOn(new Set(tempDiscountAppliedOn))
                }
                }
                }
                defaultChecked={couponData &&[..._.get(couponData, ["discount_on"], [])].includes("WEBSITE")?true:false}
              />
              <label for="Website" style={{position:"absolute",top:"4px"}}> WEBSITE</label>
            </form>
          </div>
        </div>
        <div className="border-box fields" style={{ display: 'flex' }}>
          <div className="border-box field2" style={{ width: "fit-content" }}>
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Coupon Visible for B2B User
            </div>
          </div>
          <div style={{ marginLeft: '2%', width: "12%" }}>
            <input
              type="checkbox"
              className="toggle-switch-checkbox"
              name="toggleSwitchB2B"
              id="toggleSwitchB2B"
              onChange={() => {
                setcouponObj((prev) => ({
                  ...prev,
                  is_b2b: !prev.is_b2b,
                }))
              }}
              defaultChecked={couponObj.is_b2b}
            />
            <label className="toggle-switch-label" htmlFor="toggleSwitchB2B">
              <span className="toggle-switch-inner" />
            </label>
          </div>
        </div>

        <div className="border-box clearfix footer">
        <div className="border-box floatright rightalign">
           {!couponData?
            couponObj.couponLoader?
              <ButtonLoader />:
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={saveCouponDetails}
            >
              Save
            </button>:
            couponObj.couponLoader?
              <ButtonLoader />:
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={saveCouponDetails}
            >
              Update
            </button>
            }
        
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCoupons;
