import React from 'react';
import "./topbar/topbar1.css"
import {Redirect, Link} from 'react-router-dom';
import API from './api';

const axios = require('axios');


class Topbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="topbarouter" id="topbarouter">
        <div className="topbarinner" id="topbarinner">
        <nav className="topbar light persistent border-box dashboard" id="topbar">

        <a href="/" className="logo"></a><ul><li><Link to="/logout">Logout</Link></li><li className="mobile_btn mobile_menu_btn"><a href="#" className="btn_topbar">Menu</a></li></ul></nav><nav className="mobile_menu border-box"><div className="mobile_menu_wrapper border-box"><div className="mobile_menu_header border-box"><a href="/" className="logo"></a><a href="#" className="close btn_topbar">Close</a></div><ul><li><a href="/accounts/logout/?next=/accounts/login/">Logout</a></li></ul><ul className="mobile_menu_footer"><li className="colorprimA400span">Available in New Delhi, Gurgaon, Faridabad, NOIDA, Greater NOIDA & Ghaziabad.</li></ul>
      </div>
      </nav>
      </div>
      </div>
    );
  }
}


export default Topbar;
