import React from 'react';
// import "./dashboard/dashboard.css"
import API from '../api';
import Row from './row';
import _ from 'lodash';

class MiddleMain extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			payment_data:[],
		};
	}
	componentWillMount() {
		if(this.props.tag==='customers'){
			API.paymentModeDefault().then((res)=>{
		let filtered_payment= res.data.data.map((item)=>{
       let filtered_array=item.array.map((arrayItem)=>{
         return(
          {
        enum_name:arrayItem.enum_name,
        enum_number:arrayItem.enum_number,
        is_visible:arrayItem.is_visible,
        name:arrayItem.name,
      }
         )
       })
       return(
         {
        enum_name:item.enum_name,
        enum_number:item.enum_number,
        is_visible:item.is_visible,
        name:item.name,
        array:filtered_array
      }
       )
     })
				this.setState({payment_data:filtered_payment})
			}).catch(function (error) {
				console.log(error)
			})
		}
	}

	render() {
		var that = this;

		// console.log("props in spares ", this.props);
		// if (!this.props.content.length) this.props.content.data = [];
		let rows = [];
		 if(this.props.tag==='main_banner'|| this.props.tag==='trending_banner' || this.props.tag==='tyre_banner'){

			rows =
				that.props.content && that.props.content?
				that.props.content.map((obj) => (
					<Row
						data={obj}
						selectedRow={that.props.selectedRow}
						onRightButtonClick={this.props.onRightButtonClick}
						tag={that.props.tag}
						onRowClick={that.props.onRowClick}
					/>
				)):null}
		else
			rows =
				that.props.content.data &&
				that.props.content.data.map((obj) => (
					<Row
						data={obj}
						selectedRow={that.props.selectedRow}
						onRightButtonClick={this.props.onRightButtonClick}
						handleButtonClick={this.props.handleButtonClick}
						onMiddleExtraButtonClick={this.props.onMiddleExtraButtonClick}
						tag={that.props.tag}
						onRowClick={that.props.onRowClick}
					/>
				));
		return (
			<>
				{!that.props.showContent ? null : that.props.content.length == 0 ? (
					<div className='main border-box leftalign'>
						<div className='empty centeralign weight400 blackspan56'>
							No Data.
						</div>
					</div>
				) : (
					<div className='main border-box leftalign'>{rows}</div>
				)}
				{/* <div className="main border-box leftalign">
                {!this.props.showContent ? (
                    <React.Fragment />
                ) : this.props.content.length == 0 ? (
                    <div className="empty centeralign weight400 blackspan56">
                        No Data.
                    </div>
                ) : (
                    rows
                )}
            </div> */}
			</>
		);
	}
}

export default MiddleMain;
