import React from 'react';
import { NotificationManager } from 'react-notifications';
import API from '../api';
import MultiSelect from '../broadcast/MultiSelect/MultiSelect';
// import "./dashboard/dashboard.css"
import Constants from '../constants';

class PanelRight extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category: '',
			brand: '',
			discount: 0,
		};
		this.state = this.props.content
			? {
					content: this.props.content,
					cod: this.props.content.cod,
					prepaid: this.props.content.prepaid,
					is_serviceable: this.props.content.is_serviceable,
			  }
			: {};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChangeCod = this.handleChangeCod.bind(this);
		this.handleChangePrepaid = this.handleChangePrepaid.bind(this);
		this.handleChangeServiceable = this.handleChangeServiceable.bind(this);
		this.handleDeliverySubmit = this.handleDeliverySubmit.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.content != this.state.content) {
			this.setState({
				cod: nextProps.content.cod,
				prepaid: nextProps.content.prepaid,
				is_serviceable: nextProps.content.is_serviceable,
			});
		}
	}
	handleChangeCod() {
		this.state.cod === '1'
			? this.setState({ cod: '0' })
			: this.setState({ cod: '1' });
	}
	handleChangePrepaid() {
		this.state.prepaid === '1'
			? this.setState({ prepaid: '0' })
			: this.setState({ prepaid: '1' });
	}
	handleChangeServiceable() {
		this.state.is_serviceable
			? this.setState({ is_serviceable: false })
			: this.setState({ is_serviceable: true });
	}
	handleDeliverySubmit(e) {
		let obj = this.props.content;
		obj.cod =
			this.state.cod != undefined ? this.state.cod : this.props.content.cod;
		obj.prepaid =
			this.state.prepaid != undefined
				? this.state.prepaid
				: this.props.content.prepaid;
		obj.is_serviceable =
			this.state.is_serviceable != undefined
				? this.state.is_serviceable
				: this.props.content.is_serviceable;
		API.updateDeliveryPincode(obj);
	}
	handleSubmit() {
		if (this.state.discount < 0) alert('Enter valid discount');
		else {
			API.combinationDiscount(
				this.state.category,
				this.state.brand,
				this.state.discount
			);
			this.props.onCombinationDiscount(!this.props.showCombinationDiscount);
		}
	}
	handleDiscountChange(e) {
		if (Number(e.target.value) < 0) alert('Enter Valid Discount');
		else {
			this.setState({ discount: Number(e.target.value) });
		}
	}
	handleClose() {
		this.setState({ category: '', brand: '', discount: 0 });
		this.props.onCombinationDiscount(!this.props.showCombinationDiscount);
	}
	handleButtonClick(type) {
		this.props.onRightButtonClick(
			type,
			this.props.tag,
			this.props.selectedRow,
			this.props.content
		);
		/*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
	}

	deleteCategory(data) {
		if (window.confirm('Do you really want to delete this category?')) {
			API.deleteSparesCategory({
				_id: data._id,
				segment_id: data.segment_id,
				name: data.name,
				display_name: data.display_name,
			})
				.then(() => {
					NotificationManager.success(
						'Cateogry Deleted successfully',
						'Success',
						5000
					);
				})
				.catch((error) => {
					NotificationManager.error(
						error.response && error.response.data
							? error.response.data.message
							: 'error occured!',
						'Error!!',
						5000
					);
					console.error(error);
				});
		}
	}

	deleteBrand(data) {
		if (window.confirm('Do you really want to delete this brand?')) {
			API.deleteSparesBrand(data)
				.then(() => {
					NotificationManager.success(
						'Brand Deleted successfully',
						'Success',
						5000
					);
				})
				.catch((error) => {
					NotificationManager.error(
						error.response && error.response.data
							? error.response.data.message
							: 'error occured!',
						'Error!!',
						5000
					);
					console.error(error);
				});
		}
	}

	showBrandContent = () => {
		return (
			<div
				id='dashboardmainright'
				className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
			>
				<div className='border-box clearfix buttons'>
					<div className='border-box blackspan9 weight500'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									<button
										class='btn btn_small btncolorprim btncard move btneditright'
										onClick={() => this.handleButtonClick('edit')}
									>
										Edit
									</button>
								</td>
								<td valign='center'>
									<button
										class='btn btn_small btncolorprim btncard move btndeleteright'
										onClick={() => this.deleteBrand(this.props.content)}
									>
										Delete
									</button>
								</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan9 weight500 rightalign'></div>
				</div>
				<div className='border-box clearfix prime blackspan4 leftalign weight700'>
					{this.props.content.brand_name}
				</div>
				<div className='border-box clearfix details blackspan65 leftalign weight300'>
					<div className='border-box clearfix blackspan56 leftalign weight500'>
						Details
					</div>
					<div className='border-box clearfix'>
						<div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Slug
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.slug}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Brand SKU
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.sku_brand}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Default Discount
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.default_discount}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Is Spares
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>
													{this.props.content.is_spare ? 'Yes' : 'No'}
												</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Is Visible
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>
													{this.props.content.is_visible ? 'Yes' : 'No'}
												</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	showCategoryContent = () => {
		return (
			<div
				id='dashboardmainright'
				className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
			>
				<div className='border-box clearfix buttons'>
					<div className='border-box blackspan9 weight500'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									<button
										class='btn btn_small btncolorprim btncard move btneditright'
										onClick={() => this.handleButtonClick('edit')}
									>
										Edit
									</button>
								</td>
								<td valign='center'>
									<button
										class='btn btn_small btncolorprim btncard move btndeleteright'
										onClick={() => this.deleteCategory(this.props.content)}
									>
										Delete
									</button>
								</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan9 weight500 rightalign'></div>
				</div>
				<div className='border-box clearfix prime blackspan4 leftalign weight700'>
					{this.props.content.name}
				</div>
				<div className='border-box clearfix details blackspan65 leftalign weight300'>
					<div className='border-box clearfix blackspan56 leftalign weight500'>
						Details
					</div>
					<div className='border-box clearfix'>
						<div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Slug
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.slug}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													About
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.about}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Display Name
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.display_name}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Segment
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.segment_name}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Is Visible
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>
													{this.props.content.is_visible ? 'Yes' : 'No'}
												</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	showVehicleContent = () => {
		return (
			<div
				id='dashboardmainright'
				className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
			>
				<div className='border-box clearfix buttons'>
					<div className='border-box blackspan9 weight500'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									<button
										class='btn btn_small btncolorprim btncard move btneditright'
										onClick={() => this.handleButtonClick('edit')}
									>
										Edit
									</button>
								</td>
							</tr>
						</table>
					</div>
					<div className='border-box blackspan9 weight500 rightalign'></div>
				</div>
				<div className='border-box clearfix prime blackspan4 leftalign weight700'>
					{this.props.content.make}
				</div>
				<div className='border-box clearfix details blackspan65 leftalign weight300'>
					<div className='border-box clearfix blackspan56 leftalign weight500'>
						Details
					</div>
					<div className='border-box clearfix'>
						<div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Brand
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.make}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Model
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.model}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Variant
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.varient}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Fuel Type
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.fuel_engine}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className='border-box clearfix'>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span
													style={{
														textTransform: 'none',
													}}
												>
													Year Range
												</span>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box inner'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<span>{this.props.content.year_range}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	render() {
		if (this.props.showCombinationDiscount) {
			return (
				<div
					id='dashboardmainright'
					className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								Discount Percentage
								<input
									type='Number'
									name='Discount'
									onChange={(e) => this.handleDiscountChange(e)}
									min='0'
								/>
							</td>
						</tr>
						<tr>
							<td valign='center'>
								Brand
								<select
									className='dashboardfields dashboardchangefields'
									id='fieldstarttimefrom'
									style={{
										padding: '.45rem .75rem .55rem',
										background: 'transparent',
									}}
									defaultValue={''}
									onChange={(e) => this.setState({ brand: e.target.value })}
								>
									{this.props.combinationBrands.map((brand) => (
										<option value={brand}>{brand}</option>
									))}
								</select>
							</td>
						</tr>
						<tr>
							<td valign='center'>
								Category
								<select
									className='dashboardfields dashboardchangefields'
									id='fieldstarttimefrom'
									style={{
										padding: '.45rem .75rem .55rem',
										background: 'transparent',
									}}
									defaultValue={''}
									onChange={(e) => this.setState({ category: e.target.value })}
								>
									{this.props.combinationCategories.map((category) => (
										<option value={category}>{category}</option>
									))}
								</select>
							</td>
						</tr>
						<tr>
							<td valign='center'>
								<button
									class='btn btn_small btncolorprim btncard move btneditright'
									onClick={this.handleSubmit}
								>
									Submit
								</button>
							</td>
							<td valign='center'>
								<button
									class='btn btn_small btncolorprim btncard move btneditright'
									onClick={this.handleClose}
								>
									close
								</button>
							</td>
						</tr>
					</table>
				</div>
			);
		} else if (
			this.props.tag === 'delivery' &&
			!this.props.showContent &&
			this.props.pincodeSummary
		) {
			return (
				<div
					id='dashboardmainright'
					className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
				>
					<div className='border-box clearfix details blackspan65 leftalign weight300'>
						<div className='border-box clearfix blackspan56 leftalign weight500'>
							Details
						</div>
						<div className='border-box clearfix'>
							<div>
								<div className='border-box clearfix'>
									<div className='border-box inner'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<span style={{ textTransform: 'none' }}>
														Unique Pincodes:
													</span>
												</td>
											</tr>
										</table>
									</div>
									<div className='border-box inner'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<span>
														{this.props.pincodeSummary.total_unique_pincode}
													</span>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className='border-box clearfix'>
							<div>
								<div className='border-box clearfix'>
									<div className='border-box inner'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<span style={{ textTransform: 'none' }}>
														COD Availability:
													</span>
												</td>
											</tr>
										</table>
									</div>
									<div className='border-box inner'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<span>
														{this.props.pincodeSummary.cod_availability}
													</span>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className='border-box clearfix'>
							<div>
								<div className='border-box clearfix'>
									<div className='border-box inner'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<span style={{ textTransform: 'none' }}>
														Prepaid Availability:
													</span>
												</td>
											</tr>
										</table>
									</div>
									<div className='border-box inner'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<span>
														{this.props.pincodeSummary.prepaid_availability}
													</span>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (!this.props.showContent) {
			return (
				<div
					id='dashboardmainright'
					className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
				>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								Select an item on the left to view its details.
							</td>
						</tr>
					</table>
				</div>
			);
		} else if (this.props.showContent) {
			switch (this.props.tag) {
				case 'delivery':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Pincode : {this.props.content.pincode}
								</div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									District : {this.props.content.District}
								</div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									City : {this.props.content.city}
								</div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									COD :
									<>
										{this.state.cod}
										<div>
											<input
												type='checkbox'
												name=''
												id='cod-yes'
												checked={this.state.cod === '1'}
												onChange={this.handleChangeCod}
											/>
											<label style={{ fontSize: '12px' }} htmlFor='cod-yes'>
												Yes
											</label>
											<input
												type='checkbox'
												name=''
												id='cod-no'
												checked={this.state.cod === '0'}
												onChange={this.handleChangeCod}
											/>
											<label style={{ fontSize: '12px' }} htmlFor='cod-no'>
												no
											</label>
										</div>
									</>
								</div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Prepaid :
									<>
										{this.state.prepaid}
										<div>
											<input
												type='checkbox'
												name=''
												id='prepaid-no'
												checked={this.state.prepaid === '1'}
												onChange={this.handleChangePrepaid}
											/>
											<label style={{ fontSize: '12px' }} htmlFor='preapid-yes'>
												Yes
											</label>
											<input
												type='checkbox'
												name=''
												id='prepaid-no'
												checked={this.state.prepaid === '0'}
												onChange={this.handleChangePrepaid}
											/>
											<label style={{ fontSize: '12px' }} htmlFor='prepaid-no'>
												no
											</label>
										</div>
									</>
								</div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Serviceable :
									<>
										{this.state.is_serviceable ? 'YES' : 'NO'}
										<div>
											<input
												type='checkbox'
												name=''
												id='serviceable-yes'
												checked={this.state.is_serviceable}
												onChange={this.handleChangeServiceable}
											/>
											<label
												style={{ fontSize: '12px' }}
												htmlFor='serviceable-yes'
											>
												Yes
											</label>
											<input
												type='checkbox'
												name=''
												id='serviceable-no'
												checked={!this.state.is_serviceable}
												onChange={this.handleChangeServiceable}
											/>
											<label
												style={{ fontSize: '12px' }}
												htmlFor='serviceable-no'
											>
												no
											</label>
										</div>
									</>
								</div>
							</div>
							<div>
								<button
									className='btn btn_small btncolorprim btncard move btneditright'
									onClick={this.handleDeliverySubmit}
								>
									Submit
								</button>
							</div>
						</div>
					);
				case 'segments':
					// var s = '<div></div>';
					// var temp = document.createElement('div');
					// temp.innerHTML = this.props.content.content;
					// var contentHTML = temp;
					// var statuses = JSON.parse(localStorage.getItem("statuses"));
					// var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
					// var status = "NA";
					// if(filteredstatuses.length > 0){
					//     status = filteredstatuses[0].status;
					// }
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											{/* <td valign="center">
                                    <button class="btn btn_small btncolorprim btncard move btndeleteright" onClick={() => this.handleButtonClick("delete")}>Delete</button>
                                </td> */}
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.name}
							</div>
							<div class='border-box clearfix name blackspan65 leftalign weight500'>
								<span style={{ textTransform: 'none' }}>
									{this.props.content.page_title}
								</span>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content._id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.name}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Slug
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.slug}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Meta Description
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.meta_description}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.page_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page Title
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.page_title}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page Type
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.page_type}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            
                            </td></tr></table></div></div></div></div></div> */}
						</div>
					);
				case 'categories':
					// var s = '<div></div>';
					// var temp = document.createElement('div');
					// temp.innerHTML = this.props.content.content;
					// var contentHTML = temp;
					// var statuses = JSON.parse(localStorage.getItem("statuses"));
					// var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
					// var status = "NA";
					// if(filteredstatuses.length > 0){
					//     status = filteredstatuses[0].status;
					// }
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btndeleteright'
													onClick={() => this.handleButtonClick('delete')}
												>
													Delete
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.name}
							</div>
							<div class='border-box clearfix name blackspan65 leftalign weight500'>
								<span style={{ textTransform: 'none' }}>
									{this.props.content.page_title}
								</span>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content._id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.name}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Segment ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.segment_id}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Segment Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.segment_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Star Category
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.star_category == 1
																? 'Yes'
																: 'No'}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Slug
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.slug}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Meta Description
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.meta_description}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.page_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page Title
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.page_title}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page Type
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.page_type}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Google Category ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.google_category_id}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Variable Fields
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.variable_fields}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                
                                </td></tr></table></div></div></div></div></div> */}
						</div>
					);
				case 'products':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											{/* <td valign="center">
                                        <button class="btn btn_small btncolorprim btncard move btneditcompatibilityright" onClick={() => this.handleButtonClick("editcompatibility")}>Edit Compatibility</button>
                                    </td> */}
											{/* <td valign="center">
                                        <button class="btn btn_small btncolorprim btncard move btndeleteright" onClick={() => this.handleButtonClick("delete")}>Delete</button>
                                    </td> */}
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.title}
							</div>
							<div class='border-box clearfix name blackspan65 leftalign weight500'>
								<span style={{ textTransform: 'none' }}>
									{this.props.content.brand_name}
								</span>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																ProductID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.product_id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Title
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.title}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Slug
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.slug}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Price
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.price}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>MRP</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.mrp}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Discount
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.discount_percent
																? this.props.content.discount_percent
																: 'No Discounts'}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Tax %
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.tax}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																HSN code
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.hsn_code}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Segment Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.segment_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Brand
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.sku_brand}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Category
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.sku_category}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				case 'coupons':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											{/* <td valign="center">
                                          <button class="btn btn_small btncolorprim btncard move btneditcompatibilityright" onClick={() => this.handleButtonClick("editcompatibility")}>Edit Compatibility</button>
                                      </td> */}
											{/* <td valign="center">
                                          <button class="btn btn_small btncolorprim btncard move btndeleteright" onClick={() => this.handleButtonClick("delete")}>Delete</button>
                                      </td> */}
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
						</div>
					);

				case 'discount':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											{/* <td valign="center">
                                        <button class="btn btn_small btncolorprim btncard move btneditcompatibilityright" onClick={() => this.handleButtonClick("editcompatibility")}>Edit Compatibility</button>
                                    </td> */}
											{/* <td valign="center">
                                        <button class="btn btn_small btncolorprim btncard move btndeleteright" onClick={() => this.handleButtonClick("delete")}>Delete</button>
                                    </td> */}
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							{/* <div className="border-box clearfix prime blackspan4 leftalign weight700">{this.props.content.customer_id}</div>
                            <div class="border-box clearfix name blackspan65 leftalign weight500"><span style={{ textTransform: 'none' }}>{this.props.content.phone_number}</span></div> */}
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Customer ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.customer_id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Customer Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.first_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Phone Number
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.phone_number}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div
												className='border-box inner'
												style={{ display: 'inline-table' }}
											>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Customer Address
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<ul>
																{!Array.isArray(
																	this.props.content.customer_address
																) ? (
																	<li>{'Address Not Available'}</li>
																) : (
																	this.props.content.customer_address.map(
																		(address) => {
																			return <li>{address}</li>;
																		}
																	)
																)}
															</ul>

															{/* Address Comes Here */}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                
                                </td></tr></table></div></div></div></div></div> */}
						</div>
					);
				case 'master': {
					switch (this.props.masterTabCategory) {
						case 'brand_spares':
							return this.showBrandContent();
						case 'categories_spares':
							return this.showCategoryContent();
						case 'vehicles':
							return this.showVehicleContent();
						default:
							return this.showBrandContent();
					}
					break;
				}
				case 'content_brand': {
					return this.showBrandContent();
					break;
				}
				case 'categories_spares':
					return this.showCategoryContent();

				case 'brands_spares':
					return this.showCategoryContent();

				case 'brands':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btndeleteright'
													onClick={() => this.handleButtonClick('delete')}
												>
													Delete
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.brand_name}
							</div>
							{/* <div class="border-box clearfix name blackspan65 leftalign weight500"><span style={{textTransform:'none'}}>{this.props.content.car_type}</span></div> */}
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content._id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Brand Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.name}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Brand Code
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.brand_code}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                        
                                        </td></tr></table></div></div></div></div></div> */}
						</div>
					);

				case 'HOMEPAGE':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btndeleteright'
													onClick={() => this.handleButtonClick('delete')}
												>
													Delete
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.brand_name}
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
															Id:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix' style={{display:"flex"}}>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}>
																Heading:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.heading}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix' style={{display:"flex"}}>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																URL:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner' 	style={{
																	textTransform: 'lowercase',
																}} >
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.url}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Order: 
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.order?this.props.content.order:''}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                        
                                        </td></tr></table></div></div></div></div></div> */}
						</div>
					);
				case 'testimonials':
					// var s = '<div></div>';
					// var temp = document.createElement('div');
					// temp.innerHTML = this.props.content.content;
					// var contentHTML = temp;
					// var statuses = JSON.parse(localStorage.getItem("statuses"));
					// var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
					// var status = "NA";
					// if(filteredstatuses.length > 0){
					//     status = filteredstatuses[0].status;
					// }
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btndeleteright'
													onClick={() => this.handleButtonClick('delete')}
												>
													Delete
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.heading}
							</div>
							<div class='border-box clearfix name blackspan65 leftalign weight500'>
								<span style={{ textTransform: 'none' }}>
									{this.props.content.customer_name}
								</span>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																ID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.uuid}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page type
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.ppm.page_type}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Page path
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.ppm.page_path}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Heading
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.heading}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Content
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.content}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Customer
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.customer_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Source
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.source}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span
																style={{
																	textTransform: 'none',
																}}
															>
																Garage
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.garage}</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                
                                </td></tr></table></div></div></div></div></div> */}
						</div>
					);
				case 'pricing':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix prime blackspan4 leftalign weight700'>
								{this.props.content.title}
							</div>
							<div class='border-box clearfix name blackspan65 leftalign weight500'>
								<span style={{ textTransform: 'none' }}>
									{this.props.content.brand_name}
								</span>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																ProductID
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.product_id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Title
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.title}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Slug
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.slug}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Price
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.price}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>MRP</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.mrp}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Tax %
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>{this.props.content.tax}</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Segment Name
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.segment_name}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Brand
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.sku_brand}
														</td>
													</tr>
												</table>
											</div>
										</div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Category
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															{this.props.content.sku_category}
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);

				case 'vehicles':
					return this.showVehicleContent();

				case 'main_banner':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>ID:</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content._id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Name:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.name}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Order:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.order}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Source:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.source}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Type:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.type}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Desc:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<ul>
															{Object.keys(this.props.content.data).map(
																(key) => {
																	return (
																		<li>
																			{key}: {this.props.content.data[key]}
																		</li>
																	);
																}
															)}
														</ul>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				
					case 'trending_banner':
					return (
						<div
							id='dashboardmainright'
							className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
						>
							<div className='border-box clearfix buttons'>
								<div className='border-box blackspan9 weight500'>
									<table className='stdtable'>
										<tr>
											<td valign='center'>
												<button
													class='btn btn_small btncolorprim btncard move btneditright'
													onClick={() => this.handleButtonClick('edit')}
												>
													Edit
												</button>
											</td>
										</tr>
									</table>
								</div>
								<div className='border-box blackspan9 weight500 rightalign'></div>
							</div>
							<div className='border-box clearfix details blackspan65 leftalign weight300'>
								<div className='border-box clearfix blackspan56 leftalign weight500'>
									Details
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>ID:</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content._id}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Name:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.name}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Order:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.order}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Source:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.source}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Type:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span>{this.props.content.type}</span>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className='border-box clearfix'>
									<div>
										<div className='border-box clearfix'>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<td valign='center'>
															<span style={{ textTransform: 'none' }}>
																Desc:
															</span>
														</td>
													</tr>
												</table>
											</div>
											<div className='border-box inner'>
												<table className='stdtable'>
													<tr>
														<ul>
															{Object.keys(this.props.content.data).map(
																(key) => {
																	return (
																		<li>
																			{key}: {this.props.content.data[key]}
																		</li>
																	);
																}
															)}
														</ul>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
					case 'tyre_banner':
						return (
							<div
								id='dashboardmainright'
								className='rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box'
							>
								<div className='border-box clearfix buttons'>
									<div className='border-box blackspan9 weight500'>
										<table className='stdtable'>
											<tr>
												<td valign='center'>
													<button
														class='btn btn_small btncolorprim btncard move btneditright'
														onClick={() => this.handleButtonClick('edit')}
													>
														Edit
													</button>
												</td>
											</tr>
										</table>
									</div>
									<div className='border-box blackspan9 weight500 rightalign'></div>
								</div>
								<div className='border-box clearfix details blackspan65 leftalign weight300'>
									<div className='border-box clearfix blackspan56 leftalign weight500'>
										Details
									</div>
									<div className='border-box clearfix'>
										<div>
											<div className='border-box clearfix'>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span style={{ textTransform: 'none' }}>ID:</span>
															</td>
														</tr>
													</table>
												</div>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span>{this.props.content._id}</span>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className='border-box clearfix'>
										<div>
											<div className='border-box clearfix'>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span style={{ textTransform: 'none' }}>
																	Name:
																</span>
															</td>
														</tr>
													</table>
												</div>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span>{this.props.content.name}</span>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className='border-box clearfix'>
										<div>
											<div className='border-box clearfix'>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span style={{ textTransform: 'none' }}>
																	Order:
																</span>
															</td>
														</tr>
													</table>
												</div>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span>{this.props.content.order}</span>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className='border-box clearfix'>
										<div>
											<div className='border-box clearfix'>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span style={{ textTransform: 'none' }}>
																	Source:
																</span>
															</td>
														</tr>
													</table>
												</div>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span>{this.props.content.source}</span>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className='border-box clearfix'>
										<div>
											<div className='border-box clearfix'>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span style={{ textTransform: 'none' }}>
																	Type:
																</span>
															</td>
														</tr>
													</table>
												</div>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span>{this.props.content.type}</span>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className='border-box clearfix'>
										<div>
											<div className='border-box clearfix'>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<td valign='center'>
																<span style={{ textTransform: 'none' }}>
																	Desc:
																</span>
															</td>
														</tr>
													</table>
												</div>
												<div className='border-box inner'>
													<table className='stdtable'>
														<tr>
															<ul>
																{Object.keys(this.props.content.data).map(
																	(key) => {
																		return (
																			<li>
																				{key}: {this.props.content.data[key]}
																			</li>
																		);
																	}
																)}
															</ul>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
				default:
					return <React.Fragment />;
			}
		}
	}
}

export default PanelRight;
