import React, { Fragment, useEffect, useState } from 'react'
import MultiSelect from '../broadcast/MultiSelect/MultiSelect'
import { NotificationManager } from 'react-notifications'
import API from '../api'

const ManageCoupons = (props) => {
  const {
    handleButtonClick,
    brandList = [],
    categoriesList = [],
    type,
    couponData,
    pageRefresh,
  } = props

  const couponType = [
    { label: 'PERCENT', value: 'PCT' },
    { label: 'FLAT_PRICE_INC', value: 'FLAT_PRICE_INC' },
    { label: 'FREE_ITEM_ON_CART_VALUE', value: 'FIOCV' },
  ];
  const mandateFields = [
    { key: 'code', err_msg: 'Coupon code' },
    // { key: 'percentage', err_msg: 'percent/amount' },
    { key: 'description', err_msg: 'description' },
    { key: 'display_name', err_msg: 'Display Name' },
    // { key: 'max_discount_amount', err_msg: 'Max Discount amount' },
    { key: 'minimum_order', err_msg: 'Minimum Order' },
    { key: 'start_time', err_msg: 'Start time' },
    { key: 'end_time', err_msg: 'End time' },
    { key: 'coupon_type', err_msg: 'Coupon Type' },
    { key: 'discount_type', err_msg: 'Discount Type' },
    { key: 'over_all_lmt', err_msg: 'Overall limit' },
    { key: 'acc_brand_name', err_msg: 'Brand' },
    { key: 'acc_product_id', err_msg: 'Product' },
    { key: 'acc_collection_slug', err_msg: 'Collection' },
    { key: 'acc_categories_slug', err_msg: 'Categories' },
    { key: 'terms', err_msg: 'Term & Conditions' },
  ];

  const [couponObj, setcouponObj] = useState({
    description: null,
    code: null,
    percentage: 0,
    max_discount_amount: 0,
    minimum_order: 0,
    start_time: null,
    end_time: null,
    // applicable_per_order: 1,
    coupon_type: null,
    discount_type: 'ORDER',
    /*usethisonlyorder*/ discount_on: 'ACCAPP',
    /*usethisonlyACCAPP*/ over_all_lmt: 0,
    /*usethis*/ overall_lmt_used: 0,
    // /*usethis*/ lmt_per_user: 1,
    is_accessories_coupon: true,
    display_name: null,
    // applicableOn: [],
    acc_brand_name: [],
    acc_product_id: [],
    acc_collection_slug: [],
    acc_categories_slug: [],
    product_id: null,
    is_display: 0,
    terms: null,
    acc_free_item_data: '',
  });
  const [collectionList, setCollectionList] = useState([]);
  const [productList, setproductList] = useState([]);

  const onInputChange = (e, keyName, type) => {
    const temp = { ...couponObj }
    if (type === 'number') {
      if (
        couponObj.coupon_type === 'FLAT_PRICE_INC' &&
        keyName === 'percentage'
      ) {
        temp[keyName] = Number(e.target.value)
        temp['max_discount_amount'] = Number(e.target.value)
      } else {
        temp[keyName] = Number(e.target.value)
      }
    } else {
      temp[keyName] = e.target.value
    }
    setcouponObj({ ...temp })
  }

  const saveCouponDetails = () => {
    const errStr = mandateFields.find((item) => !couponObj[item.key])
    if (errStr) {
      NotificationManager.error(
        `${errStr.err_msg} is required.`,
        'Error!!',
        5000
      )
      return;
    }

    const data = JSON.parse(JSON.stringify(couponObj))
    if (data.coupon_type === 'PCT') {
      if (!data.percentage) {
        NotificationManager.error(
          'Discount percentage is required',
          'Error!!',
          5000
        );
        return;
      }
      if (!data.max_discount_amount) {
        NotificationManager.error(
          'Max discount amount is required',
          'Error!!',
          5000
        );
        return;
      }
    }
    if (data.coupon_type === 'FIOCV') {
      if (!data.acc_free_item_data) {
        NotificationManager.error(
          'Free item sku code is required',
          'Error!!',
          5000
        );
        return;
      }
    }
    if (data.coupon_type === 'FLAT_PRICE_INC') {
      if (!data.percentage) {
        NotificationManager.error(
          'Flat discount amount is required',
          'Error!!',
          5000
        );
        return;
      }
      if (data.minimum_order < data.percentage) {
        NotificationManager.error(
          'Minimum order value should be >= coupon amount value',
          'Error!!',
          5000
        )
        data.max_discount_amount = data.percentage;
        return;
      }
    }
    data.acc_categories_slug = couponObj.acc_categories_slug
      ? couponObj.acc_categories_slug.map((item) => item.slug)
      : []
    data.acc_collection_slug = couponObj.acc_collection_slug
      ? couponObj.acc_collection_slug.map((item) => item.slug)
      : []

    data.code = data.code.toUpperCase()
    data.display_name = data.display_name.toUpperCase()
    data.start_time = `${data.start_time}T00:00:00.000`
    data.end_time = `${data.end_time}T23:59:59.000`
    // eslint-disable-next-line no-useless-escape
    data.terms = data.terms.split(/\%\$\#\$\%/g)

    if (type === 'add') {
      API.addAccessoriesCoupon(data)
        .then(() => {
          pageRefresh()
          handleButtonClick()
          NotificationManager.success('Coupon created.', 'Success', 5000)
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : 'error occured!',
            'Error!!',
            5000
          )
          console.error(error)
        })
    } else {
      API.editAccessoriesCoupon(data)
        .then(() => {
          pageRefresh()
          handleButtonClick()
          NotificationManager.success(
            'Coupon updated successfully.',
            'Success',
            5000
          )
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : 'error occured!',
            'Error!!',
            5000
          )
          console.error(error)
        })
    }
  }

  const getCollectionList = () => {
    API.getCollectionList()
      .then((response) => {
        setCollectionList(response.data.data)
      })
      .catch((err) => console.error(err))
  }

  const handleProductInputEnter = (e) => {
    let x = []
    let y = []
    if (productList && productList.length > 0) {
      y = [...productList]
    }
    if (couponObj.acc_product_id && couponObj.acc_product_id.length > 0) {
      x = [...couponObj.acc_product_id]
    }

    API.searchAccessoriesProducts(e.target.value.trim())
      .then((response) => {
        if (response.data.data) {
          if (response.data.data && response.data.data.length) {
            x = [
              ...x,
              ...response.data.data.filter(item => !couponObj.acc_product_id.includes(item.product_id)).map(
                item => item.product_id,
              ),
            ];
            y = [
              ...y,
              ...response.data.data.filter(item => !couponObj.acc_product_id.includes(item.product_id)),
            ];
          } else if (response.data.data.product_id) {
            if (
              couponObj.acc_product_id.includes(response.data.data.product_id)
            ) {
              NotificationManager.error(
                'Product already added in the list.',
                'Error!!',
                5000
              )
              return
            }
            x.push(response.data.data.product_id);
            y.push(response.data.data);
          }
          setcouponObj({ ...couponObj, acc_product_id: x, product_id: null })
          setproductList(y)
        } else {
          NotificationManager.error(
            'No product found for given product ID.',
            'Error!!',
            5000
          )
        }
      })
      .catch((err) => console.error(err))
  }

  const onChangeRemoveClick = (index) => {
    let temp = productList
    let temp2 = couponObj.acc_product_id
    temp.splice(index, 1)
    temp2.splice(index, 1)
    setcouponObj({ ...couponObj, acc_product_id: temp2 })
    setproductList(temp)
  }

  useEffect(() => {
    getCollectionList()
    if (
      type === 'edit' &&
      couponData.acc_product_id &&
      couponData.acc_product_id.length > 0
    ) {
      API.getAccessoriesCouponProducts(couponData.acc_product_id)
        .then((response) => {
          setproductList(response.data.data)
        })
        .catch((err) => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (type === 'edit') {
      const accSelectedCategories = categoriesList
        ? categoriesList.filter((item) =>
            couponData.acc_categories_slug.includes(item.slug)
          )
        : []
      const accSelectedCollection = collectionList
        ? collectionList.filter((item) =>
            couponData.acc_collection_slug.includes(item.slug)
          )
        : []
      setcouponObj({
        ...couponData,
        start_time: new Date(couponData.start_time).toISOString().slice(0, 10),
        end_time: new Date(couponData.end_time).toISOString().slice(0, 10),
        acc_categories_slug: accSelectedCategories,
        acc_collection_slug: accSelectedCollection,
        // eslint-disable-next-line no-useless-escape
        terms: couponData.terms.join('%$#$%'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionList])

  return (
    <div id='dashboardprimeextra' className='border-box bgwhite1 shadow-box'>
      <div className='extramainpanel border-box leftalign'>
        <div className='border-box clearfix title stickyTitle'>
          <div
            className='border-box floatleft leftalign blackspan2 weight900 shadow-text'
            style={{ padding: '0px 0px 0px 10px' }}
          >
            {type === 'edit' ? 'Edit Coupon Details' : 'Add Coupon Details'}
          </div>
          <div className='border-box floatright rightalign'>
            <button
              className='btn btn_small btncolorprim btncard move btncloseextra'
              onClick={() => handleButtonClick('close')}
            >
              Close
            </button>
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={() => saveCouponDetails()}
            >
              Save
            </button>
          </div>
        </div>

        <div className='border-box fields'>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Coupon Code<sup>*</sup>
            </div>
            <input
              type='text'
              placeholder='Coupon Code'
              value={couponObj.code}
              className='field border-box small uppercased'
              onChange={(e) => onInputChange(e, 'code', 'string')}
              readOnly={type === 'edit'}
            />
          </div>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Coupon Type<sup>*</sup>
            </div>
            <MultiSelect
              options={couponType}
              labelKey='value'
              checkbox_id='value'
              placeholder='Coupon Type'
              disabledItems={[]}
              hideOnBlur
              selectedOptions={{ value: couponObj.coupon_type }}
              onChange={(e) => {
                setcouponObj({
                  ...couponObj,
                  coupon_type: e.value,
                })
              }}
            />
          </div>
        </div>

        <div className='border-box fields'>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Coupon Display Name<sup>*</sup>
            </div>
            <input
              type='text'
              placeholder='Coupon Display name'
              value={couponObj.display_name}
              className='field border-box small uppercased'
              onChange={(e) => onInputChange(e, 'display_name', 'string')}
            />
          </div>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Coupon Description<sup>*</sup>
            </div>
            <input
              type='text'
              placeholder='Coupon Description'
              value={couponObj.description}
              className='field border-box small'
              onChange={(e) => onInputChange(e, 'description', 'string')}
            />
          </div>
        </div>

        <div className='border-box clearfix fields'>
          {couponObj.coupon_type === 'FIOCV' ? (
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Enter Free Item SKU code<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='SKU code'
                value={couponObj.acc_free_item_data}
                className='field border-box small'
                onChange={(e) => onInputChange(e, 'acc_free_item_data', 'string')}
              />
            </div>
          ) : (
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                {couponObj.coupon_type === 'FLAT_PRICE_INC'
                  ? 'Amount'
                  : 'Percentage'}
                <sup>*</sup>
              </div>
              <input
                type='number'
                placeholder='Percentage'
                value={couponObj.percentage}
                min='0'
                className='field border-box small'
                onChange={(e) => onInputChange(e, 'percentage', 'number')}
              />
            </div>
          )}
          {couponObj.coupon_type === 'PCT' && (
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Max Discount Amount<sup>*</sup>
              </div>
              <input
                type='number'
                placeholder='Max Discount Amount'
                value={couponObj.max_discount_amount}
                min='0'
                className='field border-box small'
                onChange={(e) =>
                  onInputChange(e, 'max_discount_amount', 'number')
                }
              />
            </div>
          )}
        </div>

        <div className='border-box clearfix fields'>
          <div className='border-box clearfix field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              OVERALL LIMIT<sup>*</sup>
            </div>
            <input
              type='number'
              placeholder='Overall limit'
              value={couponObj.over_all_lmt}
              min='0'
              className='field border-box small'
              onChange={(e) => onInputChange(e, 'over_all_lmt', 'number')}
            />
          </div>
          <div className='border-box clearfix field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Minimum Order<sup>*</sup>
            </div>
            <input
              type='number'
              placeholder='Minimum order'
              value={couponObj.minimum_order}
              min='0'
              className='field border-box small'
              onChange={(e) => onInputChange(e, 'minimum_order', 'number')}
            />
          </div>
        </div>

        <div className='border-box clearfix fields'>
          <div className='border-box clearfix field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Start Date<sup>*</sup>
            </div>
            <input
              type='date'
              name='start_time'
              className='field border-box small'
              value={couponObj.start_time}
              max={couponObj.end_time}
              onChange={(e) => onInputChange(e, 'start_time', 'date')}
            />
          </div>
          <div className='border-box clearfix field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              End Date<sup>*</sup>
            </div>
            <input
              type='date'
              name='end_time'
              className='field border-box small'
              value={couponObj.end_time}
              min={couponObj.start_time}
              onChange={(e) => onInputChange(e, 'end_time', 'date')}
            />
          </div>
        </div>

        <div className='border-box fields'>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Is Display
            </div>
            <input
              className='field border-box small'
              type='radio'
              id='is_display1'
              name='is_display'
              value='1'
              onChange={(e) => {
                setcouponObj({
                  ...couponObj,
                  is_display: Number(e.target.value),
                })
              }}
              checked={couponObj.is_display}
            />
            <label for='is_display1'>Yes</label>
            <input
              className='field border-box small'
              type='radio'
              id='is_display2'
              name='is_display'
              value='0'
              onChange={(e) => {
                setcouponObj({
                  ...couponObj,
                  is_display: Number(e.target.value),
                })
              }}
              checked={!couponObj.is_display}
            />
            <label for='is_display2'>No</label>
          </div>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Term & Conditions
            </div>
            <textarea
              className='field border-box small'
              placeholder='Enter terms & condition in the given format - /nOffer applicable with any service on offer%$#$%AC inspection will be carried out at the time of service'
              onChange={(e) => onInputChange(e, 'terms', 'string')}
              value={couponObj.terms}
            >
              {couponObj.terms}
            </textarea>
          </div>
        </div>

        <div className='border-box fields'>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Brand
            </div>
            {brandList && (
              <MultiSelect
                options={brandList}
                labelKey='name'
                checkbox_id='name'
                placeholder='Brand'
                disabledItems={[]}
                multiple
                selectedOptions={couponObj.acc_brand_name.map((item) => { return { name: item } })}
                onChange={(e) => {
                  setcouponObj({
                    ...couponObj,
                    acc_brand_name: e.map((item) => item.name),
                  })
                }}
              />
            )}
          </div>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Categories
            </div>
            <MultiSelect
              options={categoriesList}
              labelKey='name'
              checkbox_id='name'
              placeholder='Category/Segment'
              disabledItems={[]}
              selectedOptions={couponObj.acc_categories_slug}
              multiple
              onChange={(e) => {
                setcouponObj({ ...couponObj, acc_categories_slug: e })
              }}
            />
          </div>
        </div>

        <div className='border-box fields'>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Products
            </div>
            <input
              type='text'
              placeholder='Product ID or SKU Code'
              value={couponObj.product_id}
              className='field border-box small'
              onChange={(e) => onInputChange(e, 'product_id')}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleProductInputEnter(e)
                  e.target.value = ''
                }
              }}
            />
          </div>
          <div className='border-box field2'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Collection
            </div>
            <MultiSelect
              options={collectionList}
              labelKey='name'
              checkbox_id='name'
              placeholder='Collection'
              disabledItems={[]}
              selectedOptions={couponObj.acc_collection_slug}
              multiple
              onChange={(e) => {
                setcouponObj({ ...couponObj, acc_collection_slug: e })
              }}
            />
          </div>
        </div>

        <div className='border-box clearfix fields'>
          <div className='middle border-box clearfix leftalign'>
            <div
              className='border-box blackspan65 weight700 dpm1boxes10'
              style={{ display: 'inline-block' }}
            >
              <table className='stdtable'>
                <tr>
                  <td valign='center'>Product ID</td>
                </tr>
              </table>
            </div>
            <div
              className='border-box blackspan65 weight700 dpm1boxes20'
              style={{ display: 'inline-block' }}
            >
              <table className='stdtable'>
                <tr>
                  <td valign='center'>SKU Code</td>
                </tr>
              </table>
            </div>
            <div
              className='border-box blackspan65 weight700 dpm1boxes30'
              style={{ display: 'inline-block' }}
            >
              <table className='stdtable'>
                <tr>
                  <td valign='center'>Product Name</td>
                </tr>
              </table>
            </div>
            <div
              className='border-box blackspan65 weight700 dpm1boxes20'
              style={{ display: 'inline-block' }}
            >
              <table className='stdtable'>
                <tr>
                  <td valign='center'>Price</td>
                </tr>
              </table>
            </div>
            <div
              className='border-box blackspan65 weight700 dpm1boxes15'
              style={{ display: 'inline-block' }}
            >
              <table className='stdtable'>
                <tr>
                  <td valign='center'>Remove</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className='border-box clearfix fields'>
          {productList &&
            productList.map((item, index) => (
              <Fragment>
                <div className='middle border-box clearfix leftalign'>
                  <div
                    className='textsize12 border-box weight500 dpm1boxes10'
                    style={{ display: 'inline-block' }}
                  >
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>{item.product_id}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className='textsize12 border-box weight500 dpm1boxes20'
                    style={{ display: 'inline-block' }}
                  >
                    <table
                      className='stdtable colorprim500span'
                      style={{ textTransform: 'uppercase' }}
                    >
                      <tr>
                        <td valign='center'>{item.sku_code[0]}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className='textsize12 border-box weight500 dpm1boxes30'
                    style={{ display: 'inline-block' }}
                  >
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>{item.title}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className='textsize12 border-box weight500 dpm1boxes20'
                    style={{ display: 'inline-block' }}
                  >
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>{item.final_price}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className='textsize12 border-box weight500 dpm1boxes15'
                    style={{ display: 'inline-block' }}
                  >
                    <table className='stdtable'>
                      <tr>
                        <td valign='center'>
                          <span
                            style={{
                              display: 'inline-block',
                              padding: '0px 5px',
                              background: '#ccc',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              onChangeRemoveClick(index)
                            }}
                          >
                            x
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
        <div className='border-box clearfix footer'>
          {/* <div className='border-box floatright rightalign'>
            <button
              className='btn btn_small btncolorprim btncard move btncloseextra'
              onClick={saveCouponDetails}
            >
              Save
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ManageCoupons
