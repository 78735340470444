import React from "react";
// import "./dashboard/dashboard.css"
import Row from "./row";
import _ from "lodash";
import { Editor } from "@tinymce/tinymce-react";
import API from "../api";
import firebase from "../firebase";
import Constants from "../constants";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import MultiSelect from "../broadcast/MultiSelect/MultiSelect";
import ManageCoupons from "./manageCoupons";
import ManageAgents from "./ManageAgents";
import ButtonLoader from "./../ButtonLoader/ButtonLoader";


var new_images = [];
var cParams = [];
var updatedCParams = [];
var newCParams = [];
var deletedCParams = [];
var taxOptions = ["18.0", "28.0"];
var sparesType = [
  {
    title: "Product",
    type: 1,
  },
  {
    title: "Brand",
    type: 2,
  },
  {
    title: "SubCategory",
    type: 3,
  },
  {
    title: "Generic",
    type: 4,
  },
  {
    title: "Brand + SubCategory",
    type: 5,
  },
  {
    title: "Inventory- Additional Discount",
    type: 6,
  },
  {
    title: "Clearance",
    type: 7,
  },
  
];
const mandateFields = {
  categories_spares: [
    { key: "name", err_msg: "Category name" },
    // { key: "display_name", err_msg: "Display name" },
    // { key: "about", err_msg: "About" },
    // { key: "segment_id", err_msg: "Segment" },
    // { key: 'slug', err_msg: 'slug' },
  ],
  brands_spares: [
    { key: "brand_name", err_msg: "Brand Name" },
    // { key: 'slug', err_msg: 'slug' },
    { key: "sku_brand", err_msg: "SKU Brand" },
  ],
};

class MiddleExtra extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.content.content
      ? {
          selected_year_range: "",
          userSubCategoryDiscount: 0,
          userSubCategoryDiscountKey:null,
          sku_desc: this.props.content.content.sku_desc,
          title: this.props.content.content.title,
          sku_brand: this.props.content.content.sku_brand,
          sku_category: this.props.content.content.sku_category,
          price: this.props.content.content.price,
          discount_percent: this.props.content.content.discount_percent
            ? this.props.content.content.discount_percent
            : 0,
          mrp: this.props.content.content.mrp,
          hsn_code: this.props.content.content.hsn_code,
          // tax: this.props.content.content.igst,
          tax: this.props.content.content.tax,
          unit_price: this.props.content.content.unit_price,
          color: this.props.content.content.color,
          image_url: this.props.content.content.image_url
            ? this.props.content.content.image_url
            : [],
          sku_code: this.props.content.content.sku_code,
          sku_class: this.props.content.content.sku_class,
          compatibility_params: this.props.content.content.compatibility_params
            ? this.props.content.content.compatibility_params
            : [],
          extraDiscountData: this.props.content.content.discounts,
          modifiedExtraDiscountData: [],
          payment_methods: this.props.content.content.payment_methods
            ? this.props.content.content.payment_methods
            : [],
          customer_id: this.props.content.content.customer_id,
          compatibility_group_id:
            this.props.content.content.compatibility_group_id,
          make: this.props.content.content.make,
          model: this.props.content.content.model,
          varient: this.props.content.content.varient,
          fuel_type: this.props.content.content.fuel_engine,
          year: this.props.content.content.year,
          end_year: this.props.content.content.end_year,
          year_range:'',
          discounts: this.props.content.content.discounts,
          sub_category_discounts:
            this.props.content.content? this.props.content.content.user_sub_category_discounts:{},
          specifications: this.props.content.content.specifications
            ? this.props.content.content.specifications
            : {},
          discountValue: 0,
          selectedBrand: null,
          oem_alternatives: this.props.content.content.oem_alternatives
            ? this.props.content.content.oem_alternatives
            : [],
          oes_alternatives: this.props.content.content.oes_alternatives
            ? this.props.content.content.oes_alternatives
            : [],
          categories: this.props.content.content
            ? this.props.content.content
            : {},
          description: this.props.content.content.description,
          name: this.props.content.content.name,
          sku_sub_category:{name:this.props.content.content.sub_category_name},
          segment_id: this.props.content.content.id,
          segment_name: this.props.content.content.segment_name,
          newSku: [],
          oemNumber: "",
          oesNumber: "",
          product_id: this.props.content.content.product_id,
          _id: this.props.content.content._id,
		      is_valid:this.props.content.content.is_valid,
          brands: [],
          selectedBrandForModel: [],
          modelsForBrand: [],
          brandWiseModels: [],
          selectedModel: [],
          fuelType: [],
          subCategoryList:[],
          selectedFuelType: '',
          varientType: [],
          selectedVarientType: [],
          filteredVarients:[],
          modelList:[],
          status: "",
          mobile: "9566212403",
          otp: "",
          showVerifyOTP: false,
          showSaveForDiscount: true,
          showSaveForImages: true,
          isPayLater: false,
          modalDisplay:null,
          modalSrc:null,
          isCOD: false,
          isCard: false,
          isUPI: false,
          isNetBanking: false,
          isWallet: false,
          search:'',
          product_kit:this.props.content.content.product_kit
            ? this.props.content.content.product_kit
            : [],
          brandSubCategoryDiscount: {
            brand: undefined,
            subCategory: undefined,
            discount: undefined,
          },
          vechilesArr:
            this.props.content.sourceType === "other"
              ? [
                  {
                    make: this.props.content.content[0].make,
                    model: this.props.content.content[0].model,
                  },
                ]
              : [],
          newCategory: [],
          newCategoryName: "",
          isNewCategoryAdded: false,
          banner_id:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content._id
              ? this.props.content.content._id
              : null,
          banner_name:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.name
              ? this.props.content.content.name
              : "",
          banner_type:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.type
              ? this.props.content.content.type
              : 1,
          banner_is_visible:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.is_visible
              ? this.props.content.content.is_visible
              : false,
          banner_is_clearance:
              this.props.content &&
              this.props.content.content &&
              this.props.content.content.data &&
              this.props.content.content.data.is_clearance
                ? this.props.content.content.data.is_clearance
                : false,
          banner_product_id:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.product_id
              ? this.props.content.content.data.product_id
              : "",
          banner_sku_code:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.sku_code
              ? this.props.content.content.data.sku_code
              : "",
          banner_brand_name:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.brand_name
              ? this.props.content.content.data.brand_name
              : "",
          banner_sku_subCategory:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.sub_category_name
              ? this.props.content.content.data.sub_category_name
              : '',
              
            banner_additional:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.additional_discount
              ? this.props.content.content.data.additional_discount
              : null,
            sub_category_id:this.props.content &&
              this.props.content.content &&
              this.props.content.content.data &&
              this.props.content.content.data.sub_category_id
                ? this.props.content.content.data.sub_category_id
                : '',
          banner_order:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.order
              ? this.props.content.content.order
              : "",
          banner_sku_brand:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.brand_name
              ? this.props.content.content.data.brand_name
              : [],
            brand_id: this.props.content &&
            this.props.content.content &&
            this.props.content.content.data &&
            this.props.content.content.data.brand_id
              ? this.props.content.content.data.brand_id
              : null,
          //   image_url_banner: this.props.content && this.props.content.content && this.props.content.content.image_url ? this.props.content.content.image_url : [],
          source:
            this.props.content &&
            this.props.content.content &&
            this.props.content.content.source
              ? this.props.content.content.source
              : "",
          image_url_banner: [],
          bannerDropDownSubCategory: [],
          brandDropDown: [],
          selectedBanner: [],
          bannerLoder: false,
          segmentsList: [],
          uploaded:false,
          loader:false,
          loader_1:false,
          searchedItem: [],
        }
      : {};

    if (
      this.props.content &&
      this.props.content.content &&
      this.props.content.content.compatibility_params
    )
      cParams = this.props.content.content.compatibility_params.slice();
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
	this.filterVarients=this.filterVarients.bind(this)
  this.zoominView=this.zoominView.bind(this)
  this.handleCross=this.handleCross.bind(this)
    this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    this.deleteCompatibility = this.deleteCompatibility.bind(this);
    this.updateCompatibility = this.updateCompatibility.bind(this);
    this.addCompatibility = this.addCompatibility.bind(this);
    this.brandSelectValue = this.brandSelectValue.bind(this);
    this.handleChangeForDiscount = this.handleChangeForDiscount.bind(this);
    this.handleChangeForPayments = this.handleChangeForPayments.bind(this);
    this.handleButtonClickDiscount = this.handleButtonClickDiscount.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addNewSku = this.addNewSku.bind(this);
    this.removeSku = this.removeSku.bind(this);
    this.addNewSkuOes = this.addNewSkuOes.bind(this);
    this.removeSkuOes = this.removeSkuOes.bind(this);
    this.addNewCategory = this.addNewCategory.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.addVechile = this.addVechile.bind(this);
    this.onChangeSpares = this.onChangeSpares.bind(this);
    this.myRef = React.createRef();
    this.saveBannerHandler = this.saveBannerHandler.bind(this);
    this.handleVisibilityOfBanner = this.handleVisibilityOfBanner.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.getLineItemsSearch = this.getLineItemsSearch.bind(this);
    this.debounceData = this.debounceData.bind(this);
    this.getLineItems = this.getLineItems.bind(this)

    // console.log(this.props.content.type)
    if (this.props.content.type === "add") {
      let tag =
        this.props.content.tag === "master"
          ? _.get(this.props, ["masterTabCategory", "category"], "")
          : this.props.content.tag;
      switch (tag) {
        case "categories_spares":
          this.state = {
            categories: {
              slug: null,
              name: null,
              display_name: null,
              tax: null,
              category_discount: null,
              hsn_code: null,
              about: null,
              is_visible: false,
              is_clearance:false,
              is_trending:false
            },
          };
          break;
        case "brands_spares":
          this.state = {
            categories: {
              sku_brand: null,
              is_lubricant: 0,
              is_tyre: 0,
              is_oes: 0,
              is_oem: 0,
              is_aftermarket: 0,
              is_battery: 0,
              brand_name: null,
              slug: null,
              default_discount: 0,
              dispatch_time: 0,
              is_visible: 0,
              is_spare: 0,
            },
          };
          break;
        default:
          console.log("No match");
      }
    }
  }

  componentWillMount() {
	if (
		(this.props.masterTabCategory.subCategory ==="category") &&
		this.props.content &&
		this.props.content.content
	  ){
      
      API.getSparesSubCategory()
      .then((result) => {
        var res=result.data.data.filter(item=>item.category_name===this.props.content.content.name?item:null)
        this.setState({ subCategoryList:res});
      })
      .catch((err) => {
        console.log(err);
      });
			  this.setState({modelList:	_.get(
				this.props,
				["contentList", "data"],
				[]
			  ).filter((item)=>item.model===this.state.model ?item:null)});
        this.setState({filteredVarients:	_.get(
          this.props,
          ["contentList", "data"],
          []
          ).filter((item)=>item.model===this.state.model ?item:null)});
	  } 

    if (
      this.props.content.tag === "discount" &&
      this.props.content &&
      this.props.content.content
    ) {
      API.getSparesSubCategory()
			.then((result) => {
				this.setState({ subCategoryList: result.data.data });
			})
			.catch((err) => {
				console.log(err);
			});
      const temp = Object.keys(this.state.extraDiscountData).map((item) => {
        return {
          brand_name: item,
          default_discount: this.state.extraDiscountData[item],
        };
      });

      this.setState({
        modifiedExtraDiscountData: temp,
      });

      // for(let [key,value] of this.state.extraDiscountData){
      //   console.log("303 "+ key + value );
      // }`
    }
    if (this.props.content && this.props.content.content) {
      if (this.props.content.sourceType !== "other")
        this.setState({
          vechilesArr: [...this.state.vechilesArr, this.props.content.content],
        });
      console.log("173proos ", this.props.content, this.state.vechilesArr);
    }
    if (!this.props.content.content) {
      this.setState({
        banner_type: 1,
        image_url_banner: [],
        banner_is_visible: false,
      });
      axios
        .get(Constants.getSparesBaseURL() + "car-brands/")
        .then((data) => {
          var brands = [];
          brands = [{ id: "select", name: "Select Brands" }, ...data.data.data];
          this.setState({ brands: brands }, () => {});
        })
        .catch((e) => console.log("error ", e));
      console.log("this prps ", this.props);
    }
    if (this.props.content.type !== "edit") {
      API.getSparesSubCategory().then((data) => {
          this.setState({ bannerDropDownSubCategory: data.data });
        })
        .catch((e) => console.log("error ", e));
      axios
        .get(Constants.getSparesBaseURL() + "brands-cms-v2/")
        .then((data) => {
          this.setState({ brandDropDown: data.data.data });
        })
        .catch((e) => console.log("error ", e));

      // axios
      //   .get(Constants.getSparesBaseURL() + `user-discounts/?customer_search=${9319393810}&source=cms`)
      //   .then((data) => {
      //     let res = [];
      //     res = data.data.data.discounts.map(discount=>Object.keys(discount)[0])
      //     this.setState({ brandDropDown: res},function(){
      //       console.log("329 "+ res );
      //     });
      //   })
      //   .catch((e) => console.log('error ', e))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state) {
    //     Object.entries(this.state).forEach(([key, val]) => {
    //         console.log("key val ", key, val, prevState[key]);
    //         return (
    //             prevState[key] !== val &&
    //             console.log(`State '${key}' changed`)
    //         );
    //     });
    // }
  }

  deleteCompatibility(id, idToDelete) {
    for (var i = 0; i < cParams.length; i++) {
      if (cParams[i].compatibility_group_id == id) {
        cParams[i]["status"] = "deleted";
        deletedCParams.push({
          compatibility_group_id: cParams[i].compatibility_group_id,
          status: "deleted",
        });
        cParams.splice(i, 1);
        break;
      }
    }
    let that = this;
    let arr = [];
    arr.push(id);
    API.deleteSparesProductCompatibility(
      this.state.sku_code,
      this.state.product_id,
      arr
    )
      .then(function (response) {
        if (response.data.status) {
          // that.props.onMiddleExtraButtonClick(type, that.state, that.props.selectedRow);
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.setState({ status: deletedCParams });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  setToDefaultPaymentMode = () => {
    let that=this
		that.setState({loader:true})
    API.deletePaymentMode(this.state.categories.phone_number)
		API.paymentModeDefault().then((res)=>{
      NotificationManager.success("Set to default", "Successfully", 3000);
     let filtered_payment= res.data.data.map((item)=>{
       let filtered_array=item.array.map((arrayItem)=>{
         return(
          {
        enum_name:arrayItem.enum_name,
        enum_number:arrayItem.enum_number,
        is_visible:arrayItem.is_visible,
        name:arrayItem.name,
      }
         )
       })
       return(
         {
        enum_name:item.enum_name,
        enum_number:item.enum_number,
        is_visible:item.is_visible,
        name:item.name,
        array:filtered_array
      }
       )
     })
			this.setState({...this.state,categories:{...this.state.categories,payment_mode:filtered_payment},loader:false})
		}).catch(function (error) {
      that.setState({...that.state,loader:false})
      if (error.message){
        NotificationManager.error(`${error.message}`, 'Error', 3000)
      }
      else{
        NotificationManager.error(`Something went wrong, Please try again later`, 'Error', 3000)
      }

		})
	};

  handleMonthlyTarget=(customerId)=>{
    let that=this
    if(this.state.categories.email && this.state.categories.email.length && !this.state.categories.email.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/)){
      NotificationManager.error('Enter your correct email id',"Error")
        return;
    }
    var data={
      phone_number:this.state.categories.phone_number,
      monthly_target:this.state.categories.monthly_target,
      email:this.state.categories.email?this.state.categories.email:null,
      delivery_charge:this.state.categories.delivery_charge,
      payment_mode:this.state.categories.payment_mode,
      is_b2b:this.state.categories.is_b2b?this.state.categories.is_b2b:false
    }
    that.setState({loader_1:true})
    API.customerTab(customerId,data).then((res)=>{
      if(res.status){
        that.setState({loader_1:false})
        NotificationManager.success("Updated", "Successfully", 3000);
        this.props.onMiddleExtraButtonClick(
          "close",
          this.state,
          this.props.selectedRow
        );
      }
    }).catch(error=>{
      that.setState({loader_1:false})
      if (error && error.response && error.response.data && error.response.data.message){
        NotificationManager.error(`${error.response.data.message}`, 'Error', 5000)
    }
    else{
      NotificationManager.error(`Something went wrong, Please try again later`, 'Error', 5000)
    }
		})
  }
   formatDate=(date)=>{
    const year=new Date(date).getFullYear()
    const month=new Date(date).getMonth()+1
    const day=new Date(date).getUTCDate()
    return day+'/'+month+'/'+year
  }

  TodayDate=(e)=>{
    var ToDate = new Date();
    if (new Date(e.target.value).setHours(0, 0, 0, 0)< ToDate.setHours(0, 0, 0, 0)) {
          alert("The Date must be Greater than or Equal to today's date");
          e.target.value=null
     }
     this.setState({...this.state,categories:{...this.state.categories,end_date:e.target.value}})
  }
   validateNumber=(evt)=> {
    let e = evt || window.event;
    let key = e.keyCode || e.which;
    if (!(key >= 48 && key <= 57)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  }

  handleWallet=(customerId)=>{
    if(!this.state.categories.amount ){
      NotificationManager.error('Please enter GoApp Money',"Error")
        return;
    }
    else if(!this.state.categories.remark || (this.state.categories.remark && this.state.categories.remark.trim()=='')){
      NotificationManager.error('Please enter Remark',"Error")
        return;
    }
    else if(!this.state.categories.is_b2b && !this.state.categories.end_date){
      NotificationManager.error('Please enter End Date',"Error")
        return;
    }
    var data={
      phone_number:this.state.categories.phone_number,
      remark:this.state.categories.remark,
      go_app_money:this.state.categories.amount,
      end_date:this.state.categories.end_date?this.formatDate(this.state.categories.end_date):'',
    }
    !data.end_date.length && delete data.end_date
    this.setState({loader:true})
    API.customerWalletTab(customerId,data).then((res)=>{
      if(res.status){
      this.setState({loader:false})
        NotificationManager.success("Updated", "Successfully", 3000);
        this.props.onMiddleExtraButtonClick(
          "close",
          this.state,
          this.props.selectedRow
        );
      }
    }).catch(error=>{
      this.setState({loader:false})
      if (error && error.response && error.response.data && error.response.data.message){
          NotificationManager.error(`${error.response.data.message}`, 'Error', 5000)
      }
      else{
        NotificationManager.error(`Something went wrong, Please try again later`, 'Error', 5000)
      }
		})
  }

  updateCompatibility(obj) {
    updatedCParams=obj



    // for (var i = 0; i < cParams.length; i++) {
    //   if (cParams[i]._id == obj._id) {
    //     updatedCParams.push(obj);
    //     cParams[i] = obj;
    //     console.log("Params",updatedCParams)
    //   }
    // }

    // for (var i = 0; i < newCParams.length; i++) {
    //   if (newCParams[i].id == obj.id) {
    //     newCParams[i] = obj;
    //     console.log("newCParams",newCParams[i])
    //   }
    // }
  }
  handleVisibilityOfBanner() {
    this.setState({
      banner_is_visible: this.state.banner_is_visible ? false : true,
    });
  }
  handleCheckout() {
    this.setState({
      banner_checkout_page: this.state.banner_checkout_page ? false : true,
    });
    console.log("418 " + this.state.banner_checkout_page);
  }
  
  handleClearance=()=> {
    this.setState({
      banner_is_clearance : this.state.banner_is_clearance ? false : true,
    });
    console.log("418 " + this.state.banner_is_clearance);
  }
  addCompatibility() {
    cParams.push({
      _id: "new" + (cParams.length + 1),
      fuel_engine: "",
      make: "",
      model: "",
      varient: "",
    });
    this.setState({ compatibility_params: cParams });
  }
  getBrandsList() {
    API.getSparesBrandsList()
      .then((result) => {
        this.setState({ brandsList: result.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onChange(e) {
    // var files = this.state.files;
    // if (!files || files == null){
    //   files = [];
    // }
    var image_url = this.state.image_url;
    let file = e.target.files[0];
    if (!file.type.match("image.*")) {
      NotificationManager.error("The file is not an image", "Error", 5000);
      return;
    } else {
      new_images.push(e.target.files[0]);
      image_url.push(URL.createObjectURL(e.target.files[0]));
      console.log("check ", image_url);
      this.setState({ image_url: image_url });
    }
  }

  onChangeSpares(e) {
    var that = this;
    var image_url = that.state.image_url_banner;
    let file = e.target.files[0];
    if (!file.type.match("image.*")) {
      NotificationManager.error("The file is not an image", "Error", 5000);
      return;
    } else {
      new_images.push(e.target.files[0]);
      image_url.push(URL.createObjectURL(e.target.files[0]));
      // console.log("check ", image_url)
      that.setState({ image_url: image_url });
    }
  }

  onDeleteImage(type, url) {
    var that = this;
    API.deleteSparesImage(this.props.content.tag, {
      product_id: this.state.product_id,
      image_urls: url,
    })
      .then(function (response) {
        if (response.data.status) {
          // that.props.onMiddleExtraButtonClick(type, that.state, null);
          // that.setState({})
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.setState({
            image_url: that.state.image_url.filter((urls) => urls !== url),
          });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClick(type, tab) {
    // const ref = React.useRef();
    let messageString = "";
    var that = this;
    let data;
    if (
      mandateFields[
        _.get(this.props, ["masterTabCategory", "category"], null)
      ] &&
      type === "save"
    ) {
      const errStr = mandateFields[
        _.get(this.props, ["masterTabCategory", "category"], "")
      ].find((item) => {
        return !this.state.categories[item.key];
      });

      if (errStr) {
        NotificationManager.error(
          `${errStr.err_msg} is required.`,
          "Error!!",
          5000
        );
        return;
      }
    }
    if (type == "save") {
      // this.refs.btn.setAttribute("disabled", "disabled");
      if (this.props.content.type === "edit") {
        let tag =
          this.props.content.tag === "master"
            ? _.get(this.props, ["masterTabCategory", "category"], "")
            : this.props.content.tag;
        switch (tag) {
          case "products":
            this.setState({loader_1:true})
            API.updateSparesProduct(
              this.props.selectedRow,
              // this.state,
              {
                sku_code: this.state.sku_code,
                sku_desc: this.state.sku_desc,
                compatibility_params: this.state.compatibility_params,
                discount_percent: this.state.discount_percent,
                oem_alternatives: this.state.oem_alternatives,
                oes_alternatives: this.state.oes_alternatives,
                product_id: this.state.product_id,
                sku_brand: this.state.sku_brand,
                sku_category: this.state.sku_category,
                specifications: this.state.specifications,
                title: this.state.title,
                price: this.state.price,
                tax: this.state.tax,
                mrp: this.state.mrp,
                hsn_code: this.state.hsn_code,
                is_visible: this.state.categories.is_visible,
                is_feed_data:this.state.categories.is_feed_data,
                is_clearance:this.state.categories.is_clearance,
                is_trending:this.state.categories.is_trending,
                sub_category_id:this.state.sku_sub_category?this.state.sku_sub_category.id:null,
                product_kit:this.state.product_kit? this.state.product_kit:[]
              },
              new_images
            )
              .then(function (response) {
                if (response.data.status) {
                  NotificationManager.success("Updated", "Success", 3000);
                  let {
                    sku_category,
                    sku_desc,
                    sku_code,
                    sku_brand,
                    oem_alternatives,
                    oes_alternatives,
                  } = response.data.data;
                  that.setState({
                    sku_category: sku_category,
                    sku_desc: sku_desc,
                    sku_code: sku_code,
                    sku_brand: sku_brand,
                    oem_alternatives: oem_alternatives,
                    oes_alternatives: oes_alternatives,
                    loader_1:false
                  });
                  new_images.length = 0;
                  that.props.onMiddleExtraButtonClick("save", null);
                } else {
                }
              })
              .catch(function (error) {
                that.setState({loader_1:false})
                messageString = error.response && error.response.data
                  ? (messageString += error.response.data.message)
                  : "An Error Ocurred";
                new_images.length = 0;
                NotificationManager.error(
                  // "Invalid SKU code",
                  // // "An Error Occured",
                  messageString,
                  "Error",
                  2000
                );
                console.log(error);
              });
            break;
          case "categories_spares":
            // data = { ...this.state.categories,segment_id: this.state.segment_id };
            data={
              name:this.state.categories.name,
              slug:this.state.categories.slug,
              segment_id: this.state.categories.id,
              is_visible:this.state.categories.is_visible,
              _id:this.state.categories._id
            }
            NotificationManager.info("", "Saving data.", 5000);
            this.setState({ updating: true });
            API.updateSparesCategory(data)
              .then(() => {
                NotificationManager.success(
                  "Category Updated.",
                  "Success",
                  5000
                );
                this.props.onMiddleExtraButtonClick("save", null);
              })
              .catch((error) => {
                NotificationManager.error(
                  error.response && error.response.data
                    ? error.response.data.message
                    : "error occured!",
                  "Error!!",
                  5000
                );
                this.setState({ updating: false });
                console.error(error);
              });
            break;
          case "brands_spares":
            data = { ...this.state.categories };
            this.setState({ updating: true });
            NotificationManager.info("", "Saving data.", 5000);
            API.updateSparesBrand(data)
              .then(() => {
                NotificationManager.success("Brand Updated.", "Success", 5000);
                this.props.onMiddleExtraButtonClick("save", null);
              })
              .catch((error) => {
                NotificationManager.error(
                  error.response && error.response.data
                    ? error.response.data.message
                    : "error occured!",
                  "Error!!",
                  5000
                );
                this.setState({ updating: false });
                console.error(error);
              });
            break;
          default:
            console.log("no case match");
        }
      } else if (this.props.content.type === "add") {
        switch (this.props.content.tag) {
          case "categories_spares":
            data = { ...this.state.categories };
            this.setState({ updating: true });
            this.setState({ updating: true });
            NotificationManager.info("", "Saving data.", 5000);
            API.addSparesCategory(data)
              .then(() => {
                NotificationManager.success(
                  "Category Created.",
                  "Success",
                  5000
                );
                this.props.onMiddleExtraButtonClick("save", null);
              })
              .catch((error) => {
                NotificationManager.error(
                  error.response && error.response.data
                    ? error.response.data.message
                    : "error occured!",
                  "Error!!",
                  5000
                );
                this.setState({ updating: false });
                console.error(error);
              });
            break;
          case "brands_spares":
            data = { ...this.state.categories };
            this.setState({ updating: true });
            this.setState({ updating: true });
            NotificationManager.info("", "Saving data.", 5000);
            API.addSparesBrand(data)
              .then(() => {
                NotificationManager.success("Brand Created.", "Success", 5000);
                this.props.onMiddleExtraButtonClick("save", null);
              })
              .catch((error) => {
                NotificationManager.error(
                  error.response && error.response.data
                    ? error.response.data.message
                    : "error occured!",
                  "Error!!",
                  5000
                );
                this.setState({ updating: false });
                console.error(error);
              });
            break;
          default:
            console.log("no case match");
        }
      } else if (this.props.content.type == "delete") {
      }
    }
    else if (type == "save_image") {
      that.setState({ showSaveForImages: false });
      if (new_images.length == 0) {
        NotificationManager.error("Please Choose Image First", "Error", 2000);
        that.setState({ showSaveForImages: true });
      } else {
        console.log(new_images);
        API.updateSparesProductImage(
          this.props.selectedRow,
          this.state,
          new_images[0]
        )
          .then(function (response) {
            that.setState({ showSaveForImages: true });
            if (response.data.status) {
              NotificationManager.success("Image Updated", "Success", 3000);
              that.myRef.current.value = "";
              new_images.length = 0;
            }
          })
          .catch(function (error) {
            that.setState({ showSaveForImages: true });
            NotificationManager.error("An Error Occured", "Error", 2000);
            that.myRef.current.value = "";
            that.props.onMiddleExtraButtonClick(
              "close",
              that.state,
              that.props.selectedRow
            );
            new_images.length = 0;
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }
    } else if (type == "save_processed_image") {
      that.setState({ showSaveForImages: false });
      if (new_images.length == 0) {
        NotificationManager.error("Please Choose Image First", "Error", 2000);
        that.setState({ showSaveForImages: true });
      } else {
        API.imageProcessing(new_images[0]).then((processedImg) => {
          var processed = processedImg.data;
          let image = Buffer.from(processed).toString("base64");
          var xUrl = `data:${processedImg.headers[
            "content-type"
          ].toLowerCase()};base64,${image}`;
          // document.getElementById('preview').src = xUrl;
          let image_url = this.state.image_url;
          image_url[image_url.length - 1] = [xUrl];
          this.setState({ image_url });
          function urltoFile(url, filename, mimeType) {
            return fetch(url)
              .then(function (res) {
                return res.arrayBuffer();
              })
              .then(function (buf) {
                return new File([buf], filename, { type: mimeType });
              });
          }
          //Usage example:
          urltoFile(xUrl, "hello.jpg", "image/jpeg")
            .then(function (file) {
              if (new_images.length > 1) {
                new_images[new_images.length - 1] = file;
              } else new_images.push(file);

              that.setState({ showSaveForImages: true });
              API.updateSparesProductImage(
                that.props.selectedRow,
                that.state,
                file
              )
                .then(function (response) {
                  that.setState({ showSaveForImages: true });
                  if (response.data.status) {
                    NotificationManager.success(
                      "Image Updated",
                      "Success",
                      3000
                    );
                    that.myRef.current.value = "";
                    new_images.length = 0;
                  }
                })
                .catch(function (error) {
                  that.setState({ showSaveForImages: true });
                  NotificationManager.error("An Error Occured", "Error", 2000);
                  that.myRef.current.value = "";
                  that.props.onMiddleExtraButtonClick(
                    "close",
                    that.state,
                    that.props.selectedRow
                  );
                  new_images.length = 0;
                  console.log(error);
                })
                .finally(function () {
                  // always executed
                });
            })
            .catch((err) => console.log(err));
        });
      }
    }
 else if (type == "save_compatibility") {
      // this.refs.btn.setAttribute("disabled", "disabled");
      new_images.length = 0;
      if (this.props.content.type == "edit") {
        API.updateSparesProductCompatibility(
          this.state.sku_code,
          this.state.product_id,
          updatedCParams
        )
          .then(function (response) {
            if (response.data.status) {
              NotificationManager.success("Updated", "Successfully", 3000);
              // that.props.onMiddleExtraButtonClick(
              //     type,
              //     that.state,
              //     that.props.selectedRow
              // );
              updatedCParams.length = 0;
            } else {
            }
          })
          .catch(function (error) {
            // handle error
            NotificationManager.error("An Error Occured", "Error", 2000);
            that.props.onMiddleExtraButtonClick(
              "close",
              that.state,
              that.props.selectedRow
            );
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }
    } else if (type == "brand") {
      console.log("get brands");
    } else {
      new_images.length = 0;
      this.props.onMiddleExtraButtonClick(type, null);
    }
  }

  handleButtonClickDiscount(type) {
    let that = this;

    API.updateDiscount(
      this.state.customer_id,
      this.state.discounts,
      this.state.sub_category_discounts,
      this.state.brandSubCategoryDiscount
    )
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Updated", "Successfully", 3000);
          // that.props.onMiddleExtraButtonClick(
          //     type,
          //     that.state,
          //     that.props.selectedRow
          // );
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickOTP(type) {
    let that = this;
    // if (this.state.mobile.length !== 10)
    //     NotificationManager.error(
    //         "Please add a Valid Number",
    //         "Error",
    //         3000
    //     );
    // else
    API.getOTPForDiscount(this.state.mobile)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("OTP Sent", "Successfully", 3000);
          // that.props.onMiddleExtraButtonClick(
          //     type,
          //     that.state,
          //     that.props.selectedRow
          // );
          that.setState({ showVerifyOTP: true });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("An Error Occured", "Error", 3000);
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickOTPVerify(type) {
    let that = this;
    API.verifyOTPForDiscount(this.state.mobile, this.state.otp)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("OTP Verified", "Successfully", 3000);
          // that.props.onMiddleExtraButtonClick(
          //     type,
          //     that.state,
          //     that.props.selectedRow
          // );
          that.setState({ showSaveForDiscount: true });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("OTP Invalid", "Error", 3000);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickPricing(type) {
    let that = this;
    let obj = {
      sku_desc: this.state.sku_desc,
      sku_code: this.state.sku_code,
      mrp: this.state.mrp,
      hsn_code: this.state.hsn_code,
      // tax: this.state.igst,
      tax: this.state.tax,
      product_id: this.state.product_id,
      sku_class: this.state.sku_class,
      sku_category: this.state.sku_category,
      sku_brand: this.state.sku_brand,
      price: this.state.price.toString(),
    };
    API.updatePricing(obj)
      .then(function (response) {
        if (response.data.status) {
          // that.props.onMiddleExtraButtonClick(
          //     type,
          //     that.state,
          //     that.props.selectedRow
          // );
          that.setState({ price: response.data.data.price });
          NotificationManager.success("Updated", "Successfully", 3000);
          // console.log("res ", response.data.data);
        } else {
          // that.props.onMiddleExtraButtonClick(
          //     "close",
          //     that.state,
          //     that.props.selectedRow
          // );
          // NotificationManager.error(
          //     "An Error Occured",
          //     "Error",
          //     3000
          // );
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  saveBannerHandler() {
    let that = this;
    let obj = {
      name: this.state.banner_name,
      type: this.state.banner_type ? this.state.banner_type : 1,
      product_id: this.state.banner_product_id?this.state.banner_product_id:null,
      // sku_code: this.state.banner_sku_code,
      // brand_name: this.state.banner_brand_name,
      sub_category_name:
        this.state.banner_sku_subCategory && this.state.banner_sku_subCategory.category_name
          ? this.state.banner_sku_subCategory.category_name
          : null,
      sub_category_display_id:this.state.banner_sku_subCategory && this.state.banner_sku_subCategory.id
      ? this.state.banner_sku_subCategory.id
      : null,
      sub_category_id:  this.state.banner_sku_subCategory && this.state.banner_sku_subCategory._id
      ? this.state.banner_sku_subCategory._id
      : null,
      source: this.props.content.source,
      order: this.state.banner_order,
      brand_name:
        this.state.banner_sku_brand && this.state.banner_sku_brand.brand_name
          ? this.state.banner_sku_brand.brand_name
          : null,
      brand_id: this.state.banner_sku_brand && this.state.banner_sku_brand.id
          ? this.state.banner_sku_brand.id
          : null,
      is_visible: this.state.banner_is_visible,
      checkout_page: this.state.checkout_page,
      is_clearance:this.state.banner_is_clearance,
      additional_discount:this.state.banner_additional
    };
    if (!obj.name || !obj.source || !obj.order || !obj.is_visible) {
      NotificationManager.error("Please fill all the fields", "Error", 3000);
      return;
    } else if (obj.type == 1 && !obj.product_id) {
      NotificationManager.error("Please check the product id.", "Error", 3000);
      return;
    } else if (obj.type == 2 && !obj.brand_name) {
      NotificationManager.error("Please check the brand name.", "Error", 3000);
      return;
    } else if (obj.type == 3 && !obj.sub_category_name) {
      NotificationManager.error("Please check the sku subcategory", "Error", 3000);
      return;
    }
    else if (obj.type == 5 && (!obj.brand_name || !obj.sub_category_name)) {
      NotificationManager.error("Please check the brand name &  sku subcategory.", "Error", 3000);
      return;
    }
    else if (obj.type == 6 && !obj.additional_discount ) {
      NotificationManager.error("Please check the Additional Discount.", "Error", 3000);
      return;
    }    
    else if (obj.additional_discount && !this.state.uploaded ) {
      NotificationManager.error("Please upload the Additional Discount.", "Error", 3000);
      return;
    }
    else if (!new_images.length) {
      NotificationManager.error(
        "Please select the correct image",
        "Error",
        3000
      );
      return;
    }
    that.setState({ bannerLoder: true });
    this.setState({loader_1:true})
    API.sparesBannerUpload(obj, new_images)
      .then(function (response) {
        if (response.data.status) {
          that.setState({loader_1:false})
          NotificationManager.success("Uploaded", "Successfully", 3000);
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
          that.setState({ bannerLoder: false });
        } else {
          console.log("668");
          that.setState({loader_1:false})
          that.setState({ bannerLoder: false });
        }
      })
      .catch(function (error) {
        that.setState({loader_1:false})
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
        that.setState({ bannerLoder: false });
      })
      .finally(function () {
        // always executed
      });
  }

  editBannerHandler(id) {
    if (this.props.content.content) {
      let that = this;
      let obj = {
        _id: id,
        name: this.state.banner_name,
        type: this.state.banner_type ? this.state.banner_type : 1,
        product_id: this.state.banner_product_id?this.state.banner_product_id:null,
        sub_category_id:  this.state.sub_category_id && this.state.sub_category_id,
        sub_category_display_id:this.state.banner_sku_subCategory && this.state.banner_sku_subCategory.id
        ? this.state.banner_sku_subCategory.id
        : null,
        sub_category_name: this.state.banner_sku_subCategory,
        source: this.state.source,
        order: this.state.banner_order,
        brand_name:
        this.state.banner_sku_brand && this.state.banner_sku_brand
          ? this.state.banner_sku_brand
          : null,
        brand_id: this.state.brand_id?this.state.brand_id:null,
        is_visible: this.state.banner_is_visible,
        checkout_page: this.state.checkout_page,
        is_clearance:this.state.banner_is_clearance,
        additional_discount:this.state.banner_additional
      };
      let imgs =
        this.props.content.content.order == this.state.banner_order
          ? new_images
          : this.props.content.content.image_url;
      this.setState({loader_1:true})
      API.sparesBannerEdit(obj, imgs)
        .then(function (response) {
          if (response.data.status) {
            that.setState({loader_1:false})
            NotificationManager.success("Updated", "Successfully", 3000);
            that.props.onMiddleExtraButtonClick(
              "close",
              that.state,
              that.props.selectedRow
            );
            new_images = [];
          } else if (!new_images.length) {
          that.setState({loader_1:false})
            NotificationManager.error(
              "Please select the correct image",
              "Error",
              3000
            );
            return;
          } else {
            console.log("668");
          }
        })
        .catch(function (error) {
          that.setState({loader_1:false})
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
          NotificationManager.error("An Error Occured", "Error", 3000);
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }
  }

  handleButtonClickBannerDelete(id) {
    let that = this;
    API.deleteSparesBanner(id)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  handleButtonClickCuponDelete(code) {
    let that = this;
    API.deleteCoupon(code)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  
  handleButtonClickAgentDelete(customer_id) {
    let that = this;
    API.deleteAgent(customer_id)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  handleButtonClickVechile(type) {
    // const obj = { ...this.state.vechilesArr };
    let that = this;
    API.updateVechileDetails(this.state.vechilesArr[0])
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Updated", "Successfully", 3000);
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickCategories(type) {
    let that = this;
    console.log("this state ", this.state.categories);
    // let obj = {
    //     // description: this.state.description,
    //     segment_id: this.state.segment_id,
    //     segment_name: this.state.segment_name,
    //     name: this.state.name,
    //     _id: this.state._id,
    // };
    let obj = {
      data: this.state.categories,
    };
    API.updateCategories(obj)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Updated", "Successfully", 3000);
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickVechilePost(type) {
    let obj = [...this.state.vechilesArr];
    obj.splice(0, 1);
    // this.setState({ vechilesArr: obj });
    let obj1 = {
      data: this.state.vechilesArr,
    };
    let that = this;
    // console.log("veeeeeee"+JSON.stringify(obj1));return;
    API.addNewVechile(obj1)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Updated", "Successfully", 3000);
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickCategoriesPost(type) {
    let that = this;
    let obj = [];
    this.state.categories.map((cat) => {
      if (!cat._id) obj.push(cat);
    });
    let obj1 = {
      data: obj,
    };
    API.addNewCategories(obj1)
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Updated", "Successfully", 3000);
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

   validateNumber=(evt)=> {
    let e = evt || window.event;
    let key = e.keyCode || e.which;
    if (!(key >= 48 && key <= 57)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  }


  handleAdditionalDiscountChange=(e)=>{
    if(this.state.banner_additional!==Number(e.target.value)){
      this.btn.removeAttribute("disabled");
    }
    this.setState({...this.state,banner_additional:Number(e.target.value)})
  }
   additionalDiscountBulkUpload=(e)=>{
    e.preventDefault();
    this.btn.setAttribute("disabled", "disabled");
		if (!this.state.banner_additional)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000
			);
		else {
      let that=this
        this.setState({...this.state,loader:true})
			API.uploadAdditionalDiscount(this.state.banner_additional)
				.then(() => {
					NotificationManager.success('Additional Discount Uploaded', 'Success', 3000);
          this.setState({...this.state,uploaded:true})
          this.setState({...this.state,loader:false})

				})
				.catch((error) => {
          if (error.message){
            NotificationManager.error(`${error.message}`, 'Error', 3000)
          }
          else{
            NotificationManager.error(`Something went wrong, Please try again later`, 'Error', 3000)
          }
          that.setState({...that.state,loader:false})
				});
		}
  }

  debounceData(fn, delay) {
    console.log('function',fn)
		let timer = null;
		return function (...args) {
			const context = this;
			timer && clearTimeout(timer);
			timer = setTimeout(() => {
				fn.apply(context, args);
			}, delay);
		};
	}
  
  getLineItemsSearch = this.debounceData(this.getLineItems, 1000);

  handleSearchClick() {
		if (this.state.search != null) {
			this.props.onSearch1Click(this.state.search);
		} else {
			NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			);
		}
	}
   getLineItems(term){
		var that = this;

		// var delayTimer;
		if (term && term.length >= 3) {
			let modifiedTerm = '';
			for (var i = 0; i < term.length; i++) {
				if (term.charAt(i).match(/^[a-zA-Z0-9]/))
					modifiedTerm += term.charAt(i);
			}
			if (modifiedTerm.match(/^[a-zA-Z0-9]+$/)) {
				// clearTimeout(delayTimer);
				// delayTimer = setTimeout(() => {
				console.log('term ', term);
				API.getProductIdSuggestions(modifiedTerm).then(function (response) {
					if (response.data.data.options.length) {
						that.setState({ searchTerm: response.data.data.options }, () => {
							that.state.searchTerm.map(
								(code, id) =>
									(that.state.searchTerm[id]['sku_code'] =
										code.sku_code +
										' - ' +
										code.sku_brand +
										' - ' +
										code.product_id)
							);
						});
						// that.setState({ searchTerm: that.state.searchTerm["newOp"] = response.data.data.options })

						var obj = that.state;
						// obj["search"] = term;
						obj['search'] = that.state.searchedItem.product_id;
						that.setState(obj);
					} else NotificationManager.error('No Content', 'Error', 2000);
				});
				// }, 2000);
			} else {
				NotificationManager.error('Invalid input', 'Error', 2000);
			}
		} else {
			this.setState({ searchTerm: [] });
		}
	}

  afterFilterProductsChange() {
		var that = this;
    let arr=[...this.state.product_kit];
    arr.push(that.state.searchedItem.product_id)
    //for unique values in array
    let uniq = [...new Set(arr)];
		that.setState({...this.state,product_kit:uniq});
	}

  removeFromKit=(item)=>{
    let temp={...this.state}
       let arr=[...temp.product_kit]
        arr.splice(arr.indexOf(item),1)
        temp.product_kit = arr
        this.setState({...temp})
  }



  handleChange(type, e, keyNum, spec) {
    var obj = JSON.parse(JSON.stringify(this.state));
    if (spec == "specifications") {
      obj["specifications"][Number(keyNum)].value = e.target.value;
    } else if (spec == "oem_alternatives") {
      obj["oem_alternatives"][Number(keyNum)].oem_number = e.target.value;
    } else if (spec == "oes_alternatives") {
      obj["oes_alternatives"][Number(keyNum)].oes_number = e.target.value;
    } else if (spec == "name") {
      obj["categories"][Number(keyNum)].name = e.target.value;
    } else if (type == "banner_type") {
      obj[type] = Number(e.target.value);
    } 
     else {
      obj[type] = e.target.value;
    }
    for (let key in obj) {
      if (key == "oes_alternatives" && obj[key].length !== 0) {
        obj[key].filter((val) => {
          if (val.props) {
            delete val.key;
            delete val.props;
            delete val.ref;
            delete val.type;
            delete val._owner;
            delete val._store;
          }
        });
      }
      if (key == "oem_alternatives" && obj[key].length > 0) {
        obj[key].filter((val) => {
          if (val.props) {
            delete val.key;
            delete val.props;
            delete val.ref;
            delete val.type;
            delete val._owner;
            delete val._store;
          }
        });
      }
      if (key == "categories" && obj[key].length > 0) {
        obj[key].forEach((seg) => {
          if (!seg.segment_name) seg.segment_id = obj[key][0].segment_id;
        });
        obj[key].filter((val) => {
          if (val.props) {
            delete val.key;
            delete val.props;
            delete val.ref;
            delete val.type;
            delete val._owner;
            delete val._store;
          }
        });
      }
    }
    this.setState(obj);
  }

  handleChangeVechile(type, e, keyNum, spec) {
    var obj = JSON.parse(JSON.stringify(this.state));
    obj["vechilesArr"][Number(keyNum)][spec] = e.target.value;
    // if (spec === "year") {
    //     let y = this.state.year_range;
    //     y = y.split("-");
    //     this.setState({ year_range: y }, () => [
    //         console.log("tjos state ", this.state.year_range),
    //     ]);
    // }
    // console.log("tjos state ", this.state.year_range);
    // this.setState(
    //     {
    //         year_range: [
    //             ...this.state.year_range,
    //             (this.state.year_range[0] = e.target.value),
    //         ],
    //     },
    //     () => console.log("this change ,", this.state.year_range)
    // );
    // this.setState((state) => {
    //     const yearRange = state.year_range.map((item, j) => {
    //         console.log("item ", item[j]);
    //     });
    //     return yearRange;
    // });
    for (let key in obj) {
      if (key == "vechilesArr" && obj[key].length !== 0) {
        obj[key].filter((val) => {
          if (val.props) {
            delete val.key;
            delete val.props;
            delete val.ref;
            delete val.type;
            delete val._owner;
            delete val._store;
          }
        });
      }
    }
    this.setState(obj);
  }

  handleChangeCategory(type, e) {
    this.setState({ newCategoryName: e.target.value });
  }

  handleChangeForDiscount(type, e) {
    let obje = this.state;
    obje[type] = e.target.value === "" ? 0 : e.target.value;
    obje["discounts"][this.state.selectedBrand] =
      e.target.value === "" ? 0 : e.target.value;
    const newObj = {};
    newObj["customer_id"] = this.state.customer_id;
    newObj["discounts"] = this.state.discounts;
    this.setState(newObj);
  }

  // handleChangeForDiscount(type, e) {
  // 	let obje = this.state;
  // 	let { index, brand_name, default_discount } = this.state.selectedBrand;
  // 	obje['discounts'][index] = {
  // 		[brand_name]: e.target.value === '' ? 0 : e.target.value,
  // 	};
  // 	obje[type] = e.target.value === '' ? 0 : e.target.value;
  // 	const newObj = {};
  // 	newObj['customer_id'] = this.state.customer_id;
  // 	newObj['discounts'] = this.state.discounts;
  // 	this.setState(newObj);
  // }

  handleChangeForSubCategoryDiscount(type, e) {
    console.log('event',type)

    // return
    let obj = this.state;
    obj[type] =Math.floor(Number(e.target.value)) === "" ? 0 : Math.floor(Number(e.target.value));
    obj["sub_category_discounts"][this.state.userSubCategoryDiscountKey.name] = this.state.userSubCategoryDiscount
    const newObj = {};
    newObj["customer_id"] = this.state.customer_id;
    newObj["sub_category_discounts"] = this.state.sub_category_discounts;
    this.setState(newObj);
  }

  handleChangeForBrandSubCategoryDiscount(e) {
    this.setState({
      brandSubCategoryDiscount: {
        ...this.state.brandSubCategoryDiscount,
        discount: Math.floor(Number(e.target.value)) === "" ? 0 : Math.floor(Number(e.target.value)),
      },
    });
  }

  handleChangeForPayments(e, type, index) {
    let that = this;
    let methods = [...this.state.payment_methods];
    let method = { ...methods[index] };
    method.is_enabled = e.target.checked;
    methods[index] = method;
    this.setState({ payment_methods: methods });
    let obj = {
      payment_method: null,
      retailer_id: this.state.customer_id,
      is_enable: e.target.checked,
    };
    if (type == "isPayLater") obj.payment_method = "pod";
    else if (type == "isUPI") obj.payment_method = "upi";
    else if (type == "isCOD") obj.payment_method = "cod";
    else if (type == "isCard") obj.payment_method = "card";
    else if (type == "isWallet") obj.payment_method = "wallet";

    API.updatePaymentMethod(obj)
      .then(function (response) {
        if (response.data.status) {
          // that.props.onMiddleExtraButtonClick(
          //     type,
          //     that.state,
          //     that.props.selectedRow
          // );
          NotificationManager.success("Updated", "Successfully", 3000);
        } else {
        }
      })
      .catch(function (error) {
        that.props.onMiddleExtraButtonClick(
          "close",
          that.state,
          that.props.selectedRow
        );
        NotificationManager.error("An Error Occured", "Error", 3000);
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleChangeForOTP(type, e) {
    if (isNaN(Number(e.target.value))) {
      return;
    } else {
      this.setState({ otp: e.target.value });
    }
    let obje = this.state;
    obje[type] = Number(e.target.value);
    // obje["discounts"][this.state.selectedBrand] = Number(e.target.value);
    // const newObj = {};
    // newObj["customer_id"] = this.state.customer_id;
    // newObj["discounts"] = this.state.discounts;
    // this.setState(newObj);
  }

  handleChangeForPhone(type, e) {
    if (isNaN(Number(e.target.value))) {
      return;
    } else {
      this.setState({ mobile: e.target.value });
    }
    let obje = this.state;
    obje[type] = Number(e.target.value);
    // obje["discounts"][this.state.selectedBrand] = Number(e.target.value);
    // const newObj = {};
    // newObj["customer_id"] = this.state.customer_id;
    // newObj["discounts"] = this.state.discounts;
    // this.setState(newObj);
  }

  handleTinyMCEChange(type, e) {
    var obj = this.state;
    obj[type] = e.target.getContent();
    this.setState(obj);
  }

  brandSelectValue(value, discount) {
    this.setState({ selectedBrand: value });
    this.setState(
      {
        discountValue: discount === null ? 0 : discount,
      },
      () => {
        console.log("this.state ", this.state);
      }
    );
  }

  addNewSku() {
    this.setState({
      oem_alternatives: [
        ...this.state.oem_alternatives,
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div style={{ display: "flex" }}>
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                SKU Code
              </div>
              <div
                onClick={() => this.addNewSku()}
                style={{ fontSize: "2rem", cursor: "pointer" }}
              >
                +
              </div>
            </div>
            <input
              type="text"
              placeholder="sku code"
              className="field border-box small"
              value={this.state.oemNumber}
              onChange={(e) =>
                this.handleChange(this.state.oemNumber, e, "oem_alternatives")
              }
            />
          </div>
          <div className="border-box clearfix field2">
            <div style={{ display: "flex" }}>
              <select>
                <option>Alternatives</option>
                <option>Replacements</option>
              </select>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                >
                  -
                </div>
              </div>
            </div>
          </div>
        </div>,
      ],
    });
  }

  addNewSkuOes() {
    this.setState({
      oes_alternatives: [
        ...this.state.oes_alternatives,
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div style={{ display: "flex" }}>
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                SKU Code
              </div>
              <div
                onClick={() => this.addNewSkuOes()}
                style={{ fontSize: "2rem", cursor: "pointer" }}
              >
                +
              </div>
            </div>
            <input
              type="text"
              placeholder="sku code"
              className="field border-box small"
              value={this.state.oesNumber}
              onChange={(e) =>
                this.handleChange(this.state.oesNumber, e, "oes_alternatives")
              }
            />
          </div>
          <div className="border-box clearfix field2">
            <div style={{ display: "flex" }}>
              <select>
                <option>Alternatives</option>
                <option>Replacements</option>
              </select>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                >
                  -
                </div>
              </div>
            </div>
          </div>
        </div>,
      ],
    });
  }

  addVechile() {
    this.setState(
      {
        vechilesArr: [
          ...this.state.vechilesArr,
          {
            // end_year: this.state.end_year,
            // fuel_engine: this.state.fuel_type,
            make: this.state.make || this.props.content.content[0].make || null,
            model:
              this.state.model || this.props.content.content[0].model || null,
            // varient: this.state.varient,
            // year: this.state.year.toString(),
            // "year_range": "2008-2012"
          },
        ],
      },
      () => {
        console.log("Called 1318", this.state.vechilesArr);
      }
    );
  }

  addNewCategory() {
    // if (!this.state.isNewCategoryAdded)
    this.setState({
      isNewCategoryAdded: true,
      categories: [
        ...this.state.categories,
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div style={{ display: "flex" }}>
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                New Category
              </div>
              <div
                onClick={() => this.addNewCategory()}
                style={{
                  fontSize: "2rem",
                  cursor: "pointer",
                }}
              >
                +
              </div>
            </div>
            <input
              type="text"
              placeholder="category"
              className="field border-box small"
              value={this.state.newCategoryName}
              onChange={(e) =>
                this.handleChangeCategory(
                  this.state.newCategoryName,
                  e,
                  "newCategoryName"
                )
              }
            />
          </div>
          {/* <div className="border-box clearfix field2">
                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                    }}
                                >
                                    -
                                </div>
                            </div>
                        </div> */}
        </div>,
      ],
    });
  }

  removeSku(id) {
    this.setState({
      oem_alternatives: this.state.oem_alternatives.filter(
        (skuList) => skuList.oem_number !== id
      ),
    });
  }

  removeSkuOes(id) {
    this.setState({
      oes_alternatives: this.state.oes_alternatives.filter(
        (skuList) => skuList.oes_number !== id
      ),
    });
  }

  removeCategory(_id, segment_id, name) {
    let that = this;
    API.deleteCategory({ _id, segment_id, name })
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.setState({
            categories: that.state.categories.filter((cat) => cat._id !== _id),
          });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Error in Deleting", "Error", 3000);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleButtonClickVechileDelete(compatibility_group_id) {
    let that = this;
    API.deleteVechile({ compatibility_group_id })
      .then(function (response) {
        if (response.data.status) {
          NotificationManager.success("Deleted", "Successfully", 3000);
          that.props.onMiddleExtraButtonClick(
            "close",
            that.state,
            that.props.selectedRow
          );
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        NotificationManager.error("Error in Deleting", "Error", 3000);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleAlternativeChange(id, type, e) {
    //remove from oem and put to oes if type if replacement and vice versa
    if (type === "oes") {
      if (e.target.value === "alternatives") {
        const obj = { oem_number: id };
        this.setState({
          oem_alternatives: [...this.state.oem_alternatives, obj],
          oes_alternatives: this.state.oes_alternatives.filter(
            (skuList) => skuList.oes_number !== id
          ),
        });
      }
    } else {
      if (e.target.value === "replacements") {
        const obj = { oes_number: id };
        this.setState({
          oes_alternatives: [...this.state.oes_alternatives, obj],
          oem_alternatives: this.state.oem_alternatives.filter(
            (skuList) => skuList.oem_number !== id
          ),
        });
      }
    }
  }

  componentDidMount() {
    let tag =
      this.props.content.tag === "master"
        ? _.get(this.props, ["masterTabCategory", "category"], "")
        : this.props.content.tag;
    switch (tag) {
      case "discount": {
        API.getSparesCategoryList()
          .then((response) => {
            this.setState({ categoriesList: response.data.data });
          })
          .catch((error) => console.error(error));
        API.getSparesBrandsList()
          .then((response) => {
            this.setState({ brandsList: response.data.data });
          })
          .catch((error) => console.error(error));
        break;
      }
      case "products":
        API.getSparesCategoryList()
          .then((response) => {
            this.setState({ categoriesList: response.data.data });
          })
          .catch((error) => console.error(error));
          API.getSparesSubCategory()
          .then((result) => {
            this.setState({ subCategoryList: result.data.data });
          })
          .catch((err) => {
            console.log(err);
          });
        API.getSparesBrandsList()
          .then((response) => {
            this.setState({ brandsList: response.data.data });
          })
          .catch((error) => console.error(error));
        axios
          .get(Constants.getSparesBaseURL() + "car-brands/")
          .then((data) => {
            var brands = [];
            brands = [
              { id: "select", name: "Select Brands" },
              ...data.data.data,
            ];
            this.setState({ brands: brands }, () => {});
          })
          .catch((e) => console.log("error ", e));
        break;
      case "coupons":
        API.getSparesBrandsList()
          .then((result) => {
            this.setState({ brandsList: result.data.data });
          })
          .catch((err) => {
            console.log(err);
          });
        API.getSparesCategoryList()
          .then((response) => {
            this.setState({ categoriesList: response.data.data });
          })
          .catch((error) => console.error(error));
        break;

      case "categories_spares":
        API.getSparesSegments()
          .then((result) => {
            this.setState({ segmentsList: result.data.data });
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        console.log("Error");
    }
  }

  handleChange2(field, e, type) {
    let temp = this.state.categories;
    if (type === "number") {
      temp[field] = Number(e.target.value);
    } else {
      temp[field] = e.target.value;
    }

    this.setState({ categories: temp });
  }

  filterVarients(selectedYearRange) {
	
	  this.setState({filteredVarients:this.state.modelList.filter((item)=>item.year_range===selectedYearRange)})
  }

  showBrandContent = () => {
    return (
      <div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box">
        <div className="extramainpanel border-box leftalign">
          <div className="border-box clearfix title">
            <div
              className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
              style={{ padding: "0px 0px 0px 10px" }}
            >
              {this.props.content.type == "add" ? "Add" : "Edit"} details
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.handleButtonClick("close")}
                disabled={this.state.updating}
              >
                Close
              </button>
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                disabled={this.state.updating}
                onClick={() => this.handleButtonClick("save")}
              >
                Save
              </button>
            </div>
          </div>

          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Brand Name<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Brand Name"
                value={_.get(this.state, ["categories", "brand_name"], null)}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("brand_name", e, "string")}
              />
            </div>
            {/* {this.props.content.type !== "add" && (
              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Slug<sup>*</sup>
                </div>
                <input
                  type="text"
                  placeholder="Slug"
                  value={_.get(this.state, ["categories", "slug"], null)}
                  readOnly={this.props.content.type !== "add"}
                  className="field border-box small"
                  onChange={(e) => this.handleChange2("slug", e, "string")}
                />
              </div>
            )} */}
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Brand SKU<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Brand SKU"
                value={_.get(this.state, ["categories", "sku_brand"], null)}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("sku_brand", e, "string")}
              />
            </div>
          </div>
    

          <div className="border-box clearfix fields">
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Dispatch Time
              </div>
              <input
                type="number"
                placeholder="Dispatch Time (In Days)"
                value={_.get(this.state, ["categories", "dispatch_time"], null)}
                min="0"
                className="field border-box small"
                onChange={(e) =>
                  this.handleChange2("dispatch_time", e, "number")
                }
              />
            </div>
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is Visible
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="show_on_listing1"
                name="show_on_listing"
                value="1"
                onChange={(e) => this.handleChange2("is_visible", e, "number")}
                checked={_.get(this.state, ["categories", "is_visible"], 0)}
              />
              <label for="show_on_listing1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="show_on_listing2"
                name="show_on_listing"
                value="0"
                onChange={(e) => this.handleChange2("is_visible", e, "number")}
                checked={!_.get(this.state, ["categories", "is_visible"], 0)}
              />
              <label for="show_on_listing2">No</label>
            </div>
            {/* <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Default Discount
              </div>
              <input
                type="number"
                placeholder="Default Discount"
                value={_.get(this.state, ["categories", "default_discount"], 0)}
                min="0"
                max="100"
                className="field border-box small"
                // onChange={(e) =>
                //   this.handleChange2("default_discount", e, "number")
                // }
              />
            </div> */}
          {/* </div> */}

          {/* <div className="border-box clearfix fields"> */}
            {/* <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is Tyre
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_tyre1"
                name="is_tyre"
                value="1"
                onChange={(e) => this.handleChange2("is_tyre", e, "number")}
                checked={_.get(this.state, ["categories", "is_tyre"], 0)}
              />
              <label for="is_tyre1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_tyre2"
                name="is_tyre"
                value="0"
                onChange={(e) => this.handleChange2("is_tyre", e, "number")}
                checked={!_.get(this.state, ["categories", "is_tyre"], 0)}
              />
              <label for="is_tyre2">No</label>
            </div> */}
          </div>
          <div className="border-box clearfix fields" style={{ marginLeft:"1rem", position:"relative"}}>
          <div className="border-box field2 clearfix">
               <div>
               <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
          Image
         </div>
               <div className="border-box leftAlign">
               <input
                     type="file"
                     // onChange={this.onChangeSpares}
                     // accept="image/*"
                     // ref={this.myRef}
                     style={{paddingBottom: "0.5rem"}}
                   />
                 <button
                   class="btn btn_small btncolorprim btncard move btncloseextra"
                   // onClick={() =>
                   //   this.setState({
                   //     storeImgCnt: this.state.storeImgCnt + 1,
                   //   })
                   // }
                 >
                   Add
                 </button>
                 <button
                   class="btn btn_small btncolorprim btncard move btncloseextra"
                   // onClick={() =>
                   //   this.setState({
                   //     storeImgCnt: this.state.storeImgCnt + 1,
                   //   })
                   // }
                 >
                   Update 
                 </button>
               </div>
               <img id="myImg" src={this.state.categories.icon} alt="" onClick={()=>{this.zoominView(this.state.categories.icon)}}  />
             </div>
             </div>
             <div id="myModal" className="modal" style={{display:this.state.modalDisplay,}}>
               <span className="close" onClick={()=> {this.handleCross()}} >&times;</span>
               <img src={this.state.modalSrc} className="modal-content" id="img01" alt="zoomedImage"/>
               </div>
             {this.state.categories.is_oem ? 
            <div className="border-box field2" style={{ width: "25%" ,position:"absolute"}}>
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
               OEM
            </div>
            <div style={{ width: "50%" , }}>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchWallet"
                // id="toggleSwitchWallet"
                defaultChecked={this.state.categories.is_oem}
              />
              <label
                className="toggle-switch-label"
                htmlFor="toggleSwitchWallet"
              >
                <span className="toggle-switch-inner" />
              </label>
            </div>
          </div>:this.state.categories.is_aftermarket ?  
          <div className="border-box field2" style={{ width: "25%",position:"absolute" }}>
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
               AfterMarket
            </div>
            <div style={{ width: "50%", }}>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchWallet"
                // id="toggleSwitchWallet"
                defaultChecked={this.state.categories.is_aftermarket}
              />
              <label
                className="toggle-switch-label"
                htmlFor="toggleSwitchWallet"
              >
                <span className="toggle-switch-inner" />
              </label>
            </div>
          </div>:''}

          
             </div>

            {/* <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is Spares
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_spares1"
                name="is_spares"
                value="1"
                onChange={(e) => this.handleChange2("is_spare", e, "number")}
                checked={_.get(this.state, ["categories", "is_spare"], 0)}
              />
              <label for="is_spares1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_spares2"
                name="is_spares"
                value="0"
                onChange={(e) => this.handleChange2("is_spare", e, "number")}
                checked={!_.get(this.state, ["categories", "is_spare"], 0)}
              />
              <label for="is_spares2">No</label>
            </div> */}
          {/* </div> */}

          {/* <div className="border-box clearfix fields">
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is OEM
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_oem1"
                name="is_oem"
                value="1"
                onChange={(e) => this.handleChange2("is_oem", e, "number")}
                checked={_.get(this.state, ["categories", "is_oem"], 0)}
              />
              <label for="is_oem1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_oem2"
                name="is_oem"
                value="0"
                onChange={(e) => this.handleChange2("is_oem", e, "number")}
                checked={!_.get(this.state, ["categories", "is_oem"], 0)}
              />
              <label for="is_oem2">No</label>
            </div>
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is OES
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_oes1"
                name="is_oes"
                value="1"
                onChange={(e) => this.handleChange2("is_oes", e, "number")}
                checked={_.get(this.state, ["categories", "is_oes"], null)}
              />
              <label for="is_oes1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_oes2"
                name="is_oes"
                value="0"
                onChange={(e) => this.handleChange2("is_oes", e, "number")}
                checked={!_.get(this.state, ["categories", "is_oes"], 0)}
              />
              <label for="is_oes2">No</label>
            </div>
          </div> */}
{/* 
          <div className="border-box clearfix fields">
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is Lubricant
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_lubricant1"
                name="is_lubricant"
                value="1"
                onChange={(e) =>
                  this.handleChange2("is_lubricant", e, "number")
                }
                checked={_.get(this.state, ["categories", "is_lubricant"], 0)}
              />
              <label for="is_lubricant1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_lubricant2"
                name="is_lubricant"
                value="0"
                onChange={(e) =>
                  this.handleChange2("is_lubricant", e, "number")
                }
                checked={!_.get(this.state, ["categories", "is_lubricant"], 0)}
              />
              <label for="is_lubricant2">No</label>
            </div>
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is Aftermarket
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_aftermarket1"
                name="is_aftermarket"
                value="1"
                onChange={(e) =>
                  this.handleChange2("is_aftermarket", e, "number")
                }
                checked={_.get(this.state, ["categories", "is_aftermarket"], 0)}
              />
              <label for="is_aftermarket1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_aftermarket2"
                name="is_aftermarket"
                value="0"
                onChange={(e) =>
                  this.handleChange2("is_aftermarket", e, "number")
                }
                checked={
                  !_.get(this.state, ["categories", "is_aftermarket"], 0)
                }
              />
              <label for="is_aftermarket2">No</label>
            </div>
          </div> */}

          {/* <div className="border-box clearfix fields">
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Is Battery
              </div>
              <input
                className="field border-box small"
                type="radio"
                id="is_battery1"
                name="is_battery"
                value="1"
                onChange={(e) => this.handleChange2("is_battery", e, "number")}
                checked={_.get(this.state, ["categories", "is_battery"], 0)}
              />
              <label for="is_battery1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_battery2"
                name="is_battery"
                value="0"
                onChange={(e) => this.handleChange2("is_battery", e, "number")}
                checked={!_.get(this.state, ["categories", "is_battery"], 0)}
              />
              <label for="is_battery2">No</label>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  showCategoryContent = () => {
    return (
      <div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box">
        <div className="extramainpanel border-box leftalign">
          <div className="border-box clearfix title">
            <div
              className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
              style={{ padding: "0px 0px 0px 10px" }}
            >
              {this.props.content.type == "add" ? "Add" : "Edit"} details
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.handleButtonClick("close")}
                disabled={this.state.updating}
              >
                Close
              </button>
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.handleButtonClick("save")}
                disabled={this.state.updating}
              >
                Save
              </button>
            </div>
          </div>
          {this.props.masterTabCategory && this.props.masterTabCategory.subCategory==="segment" &&
          <>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Segment Name<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Category Name"
                value={this.state.categories.name}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("name", e, "string")}
              />
            </div>
            {/* {this.props.content.type !== "add" && ( */}
              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Order<sup>*</sup>
                </div>
                <input
                  type="text"
                  placeholder="Order"
                  value={this.props.content.content.order}
                  // readOnly={this.props.content.type !== "add"}
                  className="field border-box small"
                  // onChange={(e) => this.handleChange2("slug", e, "string")}
                />
              </div>
            {/* )} */}
          </div>
          <div className="border-box fields" style={{display:"flex",marginBottom: "1rem",}}>

<div className="border-box field2 clearfix">
         <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
           Is Visible<sup>*</sup>
         </div>
         <input
           className="field border-box small"
           type="radio"
           id="show_on_listing1"
           name="show_on_listing"
           value="1"
           onChange={(e) => this.handleChange2("is_visible", e, "number")}
           checked={this.props.content.content.is_visible}
         />
         <label for="show_on_listing1">Yes</label>
         <input
           className="field border-box small"
           type="radio"
           id="show_on_listing2"
           name="show_on_listing"
           value="0"
           onChange={(e) => this.handleChange2("is_visible", e, "number")}
           checked={!this.state.categories.is_visible}
         />
         <label for="show_on_listing2">No</label>
       </div>
       <div className="border-box clearfix footer" style={{ margin:"auto"}}>
               <div style={{paddingRight: "17rem",}}>
               <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
          Image
         </div>
               {/* <div className="border-box floatright leftAlign">
               <input
                     type="file"
                     // onChange={this.onChangeSpares}
                     // accept="image/*"
                     // ref={this.myRef}
                   />
                 <button
                   class="btn btn_small btncolorprim btncard move btncloseextra"
                   // onClick={() =>
                   //   this.setState({
                   //     storeImgCnt: this.state.storeImgCnt + 1,
                   //   })
                   // }
                 >
                   Add Image
                 </button>
               </div> */}
               <img id="myImg" src={this.state.categories.icon} alt="" onClick={()=>{this.zoominView(this.state.categories.icon)}}  />
             </div>
             </div>
             <div id="myModal" className="modal" style={{display:this.state.modalDisplay,}}>
               <span className="close" onClick={()=> {this.handleCross()}} >&times;</span>
               <img src={this.state.modalSrc} className="modal-content" id="img01" alt="zoomedImage"/>
               </div>
     </div>
     <div className="border-box clearfix fields" style={{display:"flex"}}>   
           <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                       Categories
                    </div>
                    <div style={{border: "2px solid #777777",display: "inline-block",borderRadius:"2px",}}>
                    {this.props.content.content.categories && this.props.content.content.categories.map(item=>
                     <div className="category-drop">{item}</div> )}
                     </div>
            </div>
            <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7" style={{display:"flex",alignItems:"center"}}>
                      Categories Count:  <div style={{fontSize: "1.01rem",marginLeft: "0.5rem"}} >{this.props.content.content.categories.length>0?this.props.content.content.categories.length:null}</div>
                    </div>
									</div>
          </div>
          </>
          }{this.props.masterTabCategory && this.props.masterTabCategory.subCategory==="category" &&
          <>
           <div className="border-box clearfix fields">   
           <div className="border-box field2">
           <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
           Category Name<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Category Name"
                value={this.props.content.content.display_name}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("name", e, "string")}
              />
           </div>
           <div className="border-box field2">
      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
       Segment<sup>*</sup>
      </div>
      <input
      type="text"
      placeholder="Segment"
      value={this.props.content.content.segment_name}
      className="field border-box small"
      // onChange={(e) => this.handleChange2("name", e, "string")}
     />
      </div>
     </div>
     <div className="border-box fields" style={{display:"flex",marginBottom: "1rem"}}>

<div className="border-box field2 clearfix">
         <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
           Is Visible<sup>*</sup>
         </div>
         <input
           className="field border-box small"
           type="radio"
           id="show_on_listing1"
           name="show_on_listing"
           value="1"
           onChange={(e) => this.handleChange2("is_visible", e, "number")}
           checked={this.props.content.content.is_visible}
         />
         <label for="show_on_listing1">Yes</label>
         <input
           className="field border-box small"
           type="radio"
           id="show_on_listing2"
           name="show_on_listing"
           value="0"
           onChange={(e) => this.handleChange2("is_visible", e, "number")}
           checked={!this.state.categories.is_visible}
         />
         <label for="show_on_listing2">No</label>
       </div>
       <div className="border-box clearfix footer" style={{ margin:"auto"}}>
               <div style={{paddingRight: "17rem",}}>
               <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
          Image
         </div>
               {/* <div className="border-box floatright leftAlign">
               <input
                     type="file"
                     // onChange={this.onChangeSpares}
                     // accept="image/*"
                     // ref={this.myRef}
                   />
                 <button
                   class="btn btn_small btncolorprim btncard move btncloseextra"
                   // onClick={() =>
                   //   this.setState({
                   //     storeImgCnt: this.state.storeImgCnt + 1,
                   //   })
                   // }
                 >
                   Add Image
                 </button>
               </div> */}
               <img id="myImg" src={this.state.categories.icon} alt="" onClick={()=>{this.zoominView(this.state.categories.icon)}}  />
             </div>
             </div>
             <div id="myModal" className="modal" style={{display:this.state.modalDisplay,}}>
               <span className="close" onClick={()=> {this.handleCross()}} >&times;</span>
               <img src={this.state.modalSrc} className="modal-content" id="img01" alt="zoomedImage"/>
               </div>
     </div>
     <div className="border-box clearfix fields" style={{display:"flex"}}>
          <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Order<sup>*</sup>
                </div>
                <input
                  type="text"
                  placeholder="Order"
                  value={this.props.content.content.order}
                  // readOnly={this.props.content.type !== "add"}
                  className="field border-box small"
                  // onChange={(e) => this.handleChange2("slug", e, "string")}
                />
              </div>

    <div className="border-box field2 ">
    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
    Discount Running  
    </div>
        <input
    type="number"
    placeholder="Default Discount"
    value={_.get(this.props.content, ["content", "default_discount"], 0)}
    min="0"
    max="100"
    className="field border-box small"
  //   onChange={(e) =>
  //   this.handleChange2("default_discount", e, "number")
  // }
    />
      </div> 
        </div>
        <div className="border-box clearfix fields" style={{display:"flex"}}>
        <div className="border-box field2">
          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Sub Categories
                  </div>
                  <div style={{border: "2px solid #777777",display: "inline-block",borderRadius:"2px",}}>
                  {this.state.subCategoryList && this.state.subCategoryList.map(item=>
                    <div className="category-drop">{item.name}</div> )}
                  </div>
          </div>
          <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7" style={{display:"flex",alignItems:"center"}}>
            Sub Categories Count:  <div style={{fontSize: "1.01rem",marginLeft: "0.5rem"}} >{this.state.subCategoryList.length>0?this.state.subCategoryList.length:null}</div>
                    </div>
									</div>
          </div>
          </>}
          
       {this.props.masterTabCategory && this.props.masterTabCategory.subCategory==="subCategory" &&   <>
          <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Sub Category<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Sub Category"
                value={this.props.content.content.name}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("name", e, "string")}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Category<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Category"
                value={this.props.content.content.category_name}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("name", e, "string")}
              />
            </div>
      
            </div>
            <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Segment<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="Segment"
                value={this.props.content.content.segment_name}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("name", e, "string")}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                SubCategory Id<sup>*</sup>
              </div>
              <input
                type="text"
                placeholder="SubCategory id"
                value={this.props.content.content.id}
                className="field border-box small"
                // onChange={(e) => this.handleChange2("name", e, "string")}
              />
            </div>
            </div>

            <div className="border-box clearfix fields">
          <div className="border-box clearfix field2" >
          <div >
          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
          Image
         </div>
                    {/* <div className="border-box floatright leftAlign">
                    <input
                          type="file"
                          // onChange={this.onChangeSpares}
                          // accept="image/*"
                          // ref={this.myRef}
                        />
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        // onClick={() =>
                        //   this.setState({
                        //     storeImgCnt: this.state.storeImgCnt + 1,
                        //   })
                        // }
                      >
                        Add Image
                      </button>
                    </div> */}

                    <img id="myImg" src={this.props.content.content.image} alt="" onClick={()=>{this.zoominView(this.props.content.content.image)}}  />
                    </div>
                  </div>      
          </div> 
          <div id="myModal" className="modal" style={{display:this.state.modalDisplay,}}>
                  <span className="close" onClick={()=> {this.handleCross()}} >&times;</span>
                  <img src={this.state.modalSrc} className="modal-content" id="img01" alt="zoomedImage"/>
          </div>
          </>}
        </div>
      </div>
    );
  };

  zoominView(icon){
  this.setState((prev)=>({...prev,modalDisplay:"block"}))
  this.setState({modalSrc:icon});
}
handleCross(){
  this.setState((prev)=>({...prev,modalDisplay:"none"}));
}

  showVehicleContent = () => {
    return (
    
	<div 
        id="dashboardprimeextra"
        className="border-box bgwhite1 shadow-box"
        style={{ margin: "2px", width: "100%" }}
      >
	  {this.props.content?
        <div className="extramainpanel border-box leftalign">
          <div className="border-box clearfix title">
            <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
              {this.props.content.type == "add" ? "Add" : "Edit"} details
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="border-box floatright rightalign">
                <button
                  class="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("close")}
                  disabled={this.state.updating}
                >
                  Back
                </button>
              </div>
              {/* {this.props.content.sourceType !== 'other' ? ( */}
              <div className="border-box footer" style={{ overflow: "hidden" }}>
                <div className="border-box floatright rightalign">
                  <button
                    class="btn btn_small btncolorprim btncard move btncloseextra"
                    onClick={() => this.handleButtonClickVechile("save")}
                    disabled={this.state.updating}
                  >
                    Save
                  </button>
                </div>
              </div>
              {/* ) : (
			<div className='border-box floatright rightalign'>
			<button
				class='btn btn_small btncolorprim btncard move btncloseextra'
				onClick={() => this.addVechile()}
			>
				Add New Varient
			</button>
			</div>
		)} */}
            </div>
          </div>
          {this.state.vechilesArr.map((vec, i) => {
            return (
              <>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Make
                    </div>
                    <input
                      type="text"
                      placeholder="Make"
                      value={vec.make || null}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChangeVechile(vec.make, e, i, "make")
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Model
                    </div>
                    <input
                      type="text"
                      placeholder="Model"
                      value={vec.model || null}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChangeVechile(vec.model, e, i, "model")
                      }
                    />
                  </div>
                  <div className="border-box clearfix fields" style={vec.icon?{display:"flex",justifyContent:"space-around"}:{display:"block"}}>
                    <div
                      className="border-box field2 clearfix"
                      style={{ textAlign: "center", marginTop: "1rem" }}
                    >
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Is Visible
                      </div>
                      <input
                        className="field border-box small"
                        type="radio"
                        id="show_on_listing1"
                        name="show_on_listing"
                        value="1"
                        onChange={() =>this.setState({is_valid:!this.state.is_valid})}
                        checked={this.state.is_valid}
                      />
                      <label for="show_on_listing1">Yes</label>
                      <input
                        className="field border-box small"
                        type="radio"
                        id="show_on_listing2"
                        name="show_on_listing"
                        value="0"
                        onChange={() =>this.setState({is_valid:!this.state.is_valid})}
                        checked={!this.state.is_valid}
                      />
                      <label for="show_on_listing2">No</label>
                    </div>
                   {vec.icon? 
                    <>
                    <div
                      className="border-box field2 clearfix"
                      style={{marginTop: "1rem" }}
                    >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Model Image</div>
                    <div  style={{display:"flex"}}>
                      <img id="myImg" src={vec.icon} alt="" onClick={()=>{this.zoominView(vec.icon)}} />
                    {/* <div style={{display:"flex",height:"2rem"}}>
                    <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClick("save_image")}
                        >
                          Update 
                        </button>
                    <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClick("save_image")}
                        >
                          Save 
                        </button>
                        </div> */}
                    </div>

                    </div>
                    <div id="myModal" className="modal" style={{display:this.state.modalDisplay,}}>
                    <span className="close" onClick={()=> {this.handleCross()}} >&times;</span>
                    <img src={this.state.modalSrc} className="modal-content" id="img01" alt="zoomedImage"/>
                    </div>
                    </>:<></>}
                  </div>
                </div>

                <div
                  className="border-box fields"
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="border-box field2"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Year Range
                      </div>
                      <MultiSelect
                        options={[...new Map(this.state.modelList.filter((item) => item.model===vec.model ).map(item=> [item['year_range'], item])).values()].sort((a,b) => (a.year_range > b.year_range) ? 1 : ((b.year_range > a.year_range) ? -1 : 0))}
                        labelKey="year_range"
                        checkbox_id="year_range"
                        placeholder="year range"
                        // disabledItems={[]}
                        selectedOptions={{
                          year_range: _.get(
                            this.state,
                            ["selected_year_range"],
                            ""
                          ),
                        }}
                        // disabled={!this.state.selected_varient}
                        onChange={(e) => {
                          this.setState({
                            selected_year_range: e.year_range,
                          });
                        }}
                      />
                    </div>
                    <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                        <tr>
                          <td valign="center" style={{ paddingTop: "2rem" }}>
                            <button
                              id="filterbtn"
                              className="btn btn_small btncolorprim btncard move"
                              // onClick={() =>
                              // 	this.props.filterFunc(
                              // 		this.state.filter_key,
                              // 		'brands_spares'
                              // 	)
                              // 
							  onClick={()=>this.filterVarients(this.state.selected_year_range)}
                            >
                              Filter
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7" style={{display:"flex",alignItems:"center"}}>
                      Variant Count:  <div style={{color: "#e73c33",fontSize: "1.05rem",marginLeft: "0.5rem"}} >{this.state.filteredVarients?this.state.filteredVarients.length:null}</div>
                    </div>
                    {/* <div className='border-box floatright rightalign'>
										<button
											class='btn btn_small btncolorprim btncard move btncloseextra'
											// onClick={() => this.handleAddNewVariant()}
										>
											Add New Varient
										</button>
									</div> */}
                  </div>
                </div>
				{/* <div
                    className="border-box "
                    style={{ textAlign: "left", marginBottom: "1rem" }}
                  >
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                    //   onClick={() =>
                    //   	this.handleButtonClickVechileDelete(
                    //   		item.compatibility_group_id
                    //   	)
                    //   }
                      style={{ marginLeft: "1rem" }}
                    >
                      Create Varient
                    </button>
                  </div> */}
				{this.state.filteredVarients?
					this.state.filteredVarients.map((item)=>{
				   return(
					   <>
					   <div className="border-box clearfix fields yearRangeData">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Variant
                    </div>
                    <input
                      type="text"
                      placeholder="Variant"
                      value={item.varient}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChangeVechile(vec.varient, e, i, "varient")
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Fuel
                    </div>
                    <input
                      type="text"
                      placeholder="Fuel"
                      value={item.fuel_engine}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChangeVechile(
                          vec.fuel_engine,
                          e,
                          i,
                          "fuel_engine"
                        )
                      }
                    />
                  </div>

                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Year Range
                    </div>
                    <input
                      type="text"
                      placeholder="Year"
                      value={item.year_range}
                      className="field border-box small"
                      onChange={(e) =>
                        // this.handleChangeVechile(vec.year, e, i, 'year')
                        console.log("ravi", vec)
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Compatibility Group Id
                    </div>
                    <input
                      type="text"
                      placeholder="Compatibility Group Id"
                      value={item.compatibility_group_id}
                      className="field border-box small"
                      // onChange={(e) =>
                      // 	this.handleChangeVechile(vec.end_year, e, i, 'end_year')
                      // }
                    />
                  </div>
                  <div
                    className="border-box "
                    style={{ textAlign: "right", marginBottom: "1rem" }}
                  >
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() =>
                      	this.handleButtonClickVechileDelete(
                      		item.compatibility_group_id
                      	)
                      }
                      style={{ marginLeft: ".5rem" }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
					   </>
					   
				   )
			   }):
			   <></>
			   }
               
                {this.state.filteredVarients.length>0?<div className="border-box ">
                  <button
                    class="btn btn_small btncolorprim btncard move btncloseextra"
                    onClick={() =>
                      this.handleButtonClickVechileDelete(
                        vec.compatibility_group_id
                      )
                    }
                    style={{ marginLeft: "1rem" }}
                  >
                    Delete Vehicle
                  </button>
                </div>:<></>}
              </>
            );
          })}
          {(this.state.vechilesArr.length > 1 ||
            this.props.content.sourceType === "other") && (
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.handleButtonClickVechilePost()}
                style={{ marginRight: "1rem", marginBottom: "1rem" }}
              >
                Save Vehicle
              </button>
            </div>
          )}
		  </div>:<></>}  
        </div>
      
    );
  };

  getBrandSubCategoryDiscount = () => {
    API.getBrandSubCategoryDiscount(
      this.state.brandSubCategoryDiscount.brand,
      this.state.brandSubCategoryDiscount.subCategory,
      this.props.content.content.customer_id,
      this.state.brandSubCategoryDiscount
    )
      .then((res) => {
        console.log(res.data);
        NotificationManager.success("Fetched", "Discount retrieved.", 3000);
        this.setState({
          brandSubCategoryDiscount: {
            ...this.state.brandSubCategoryDiscount,
            discount: res.data.data.brand_category_discount,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    var that = this;
    if (that.props.content.type == "delete") {
      return (
        <div
          id="dashboardprimeextra"
          className="border-box bgwhite1 shadow-box"
          style={{ margin: "5px", width: "100%" }}
        >
          <div className="extramainpanel border-box leftalign">
            <div className="border-box clearfix title">
              <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                Delete
              </div>
              <div className="border-box floatright rightalign">
                <button
                  class="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("close")}
                >
                  Close
                </button>
              </div>
            </div>

            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7 rightalign">
                  Are you sure you want to delete this entry?
                </div>
                {/* <input type="text" placeholder="Heading" value={this.state.heading} className="field border-box small" onChange={(e) => this.handleChange("heading", e)}/> */}
              </div>
              {/* <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                    <input type="text" placeholder="Page path" value={this.state.content} className="field border-box small" onChange={(e) => this.handleChange("content", e)}/>
                  </div> */}
            </div>
            <div className="border-box clearfix footer">
              {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
              <div className="border-box floatright rightalign">
                <button
                  class="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() =>{
                  if  (this.props.content.content.code)
                   this.handleButtonClickCuponDelete(this.props.content.content.code)
                   else  if(this.props.content.content.customer_id)
                   this.handleButtonClickAgentDelete(this.props.content.content.customer_id)
                   else if(this.props.content.content._id)
                   this.handleButtonClickBannerDelete(this.props.content.content._id)
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      switch (this.props.content.tag) {
        case "products":
          let images = that.state.image_url.map((image, i) => {
            return (
              <div style={{ display: "flex" }}>
                <ImageBox
                  url={image}
                  index={i}
                  onDelete={this.onDeleteImage}
                  height={"100"}
                  width={"200"}
                />
              </div>
            );
          });
          let compatibility_params = this.state.compatibility_params.map(
            (object) => {
              return (
                <CompatibilityBox
                  content={object}
                  deleteCompatibility={this.deleteCompatibility}
                  updateCompatibility={this.updateCompatibility}
                  brands={this.state.brands}
                  status={this.state.status}
                  addCompatibility={this.addCompatibility}
                />
              );
            }
          );

          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
              style={{ margin: "5px", width: "100%" }}
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.addCompatibility()}
                    >
                      Add compatibility
                    </button>
                  </div>
                  {/* Hide Add compatibilty for the time being */}
                  <div className="border-box floatright rightalign">
                 { this.state.loader_1?<div
									style={{height: "1.8rem",width: "3.7rem",}}
									>
									<ButtonLoader width={'100%'} height={'100%'}/>
									</div>:
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save", "product")}
                    >
                      Save
                    </button>}
                  </div>
                </div>

                {/* {this.props.content.type == "add" ? (<div className="border-box clearfix fields">
                              <div className="border-box clearfix field2">
                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">SKU DESC</div>
                                <input type="text" placeholder="SKU DESC" value={this.state.sku_desc} className="field border-box small" onChange={(e) => this.handleChange("sku_desc", e)}/>
                              </div>
                            </div>) : (<React.Fragment />)} */}

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Product ID
                    </div>
                    <input
                      type="text"
                      placeholder="Product ID"
                      value={this.state.product_id}
                      className="field border-box small"
                      style={{ background: "#f5f5f5" }}
                      readOnly
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      SKU Code
                    </div>
                    <input
                      type="text"
                      placeholder="SKU Code"
                      value={this.state.sku_code}
                      className="field border-box small"
                      // onChange={(e) => this.handleChange("sku_code", e)}
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      SKU Sub Category
                    </div>
                    <MultiSelect
														options={this.state.subCategoryList.map((item)=>{
                              return( {...item,label:item.name + '-' + item.id})
                            })}
														labelKey='label'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
                            value={this.state.sku_sub_category}

														selectedOptions={this.state.sku_sub_category}
														onChange={(value, e) => { 
															this.setState({...this.sku_sub_category,sku_sub_category: value,
															});
														}}
													/>
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      SKU Brand
                    </div>
                    {/* <input
                      type='text'
                      placeholder='SKU Brand'
                      value={this.state.sku_brand}
                      className='field border-box small'
                      onChange={(e) => this.handleChange('sku_brand', e)}
                    /> */}
                    <MultiSelect
                      options={this.state.brandsList}
                      labelKey="sku_brand"
                      checkbox_id="sku_brand"
                      placeholder="Brands"
                      disabledItems={[]}
                      selectedOptions={{
                        sku_brand: this.state.sku_brand,
                      }}
                      onChange={(e) => {
                        this.setState({ sku_brand: e.sku_brand });
                      }}
                    />
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Price
                    </div>
                    <input
                      type="text"
                      placeholder="Price"
                      value={this.state.price}
                      className="field border-box small"
                      style={{ background: "#f5f5f5" }}
                      // onChange={(e) => this.handleChange("price", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      MRP
                    </div>
                    <input
                      type="text"
                      placeholder="MRP"
                      value={this.state.mrp}
                      className="field border-box small"
                      // onChange={(e) => this.handleChange("mrp", e)}
                    />
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Tax
                    </div>
                    <input
                      type="text"
                      placeholder="Tax"
                      value={this.state.tax}
                      className="field border-box small"
                      style={{ background: "#f5f5f5" }}
                      // onChange={(e) => this.handleChange("tax", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      HSN Code
                    </div>
                    <input
                      type="text"
                      placeholder="HSN Code"
                      value={this.state.hsn_code}
                      className="field border-box small"
                      // onChange={(e) => this.handleChange("hsn_code", e)}
                    />
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("title", e)}
                      readOnly
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Discount Percent
                    </div>
                    <input
                      type="number"
                      placeholder="Discount"
                      min="0"
                      max="100"
                      value={this.state.discount_percent}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("discount_percent", e)}
                    />
                  </div>
                </div>

                <div className="border-box clearfix fields">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Sku Description
                    </div>
                    <textarea
                      type="text"
                      placeholder="category"
                      value={this.state.sku_desc}
                      className="field border-box small"
                      style={{minHeight:"9rem"}}
                      onChange={(e) => this.handleChange("sku_desc", e)}
                    />
                  </div>
                  <div className="border-box clearfix fields">
                    <div className="border-box field2 clearfix"
                    >
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Visible on Web
                      </div>
                      <input
                        className="field border-box small"
                        type="radio"
                        id="visible_on_web1"
                        name="visible_on_web"
                        value="1"
                        onChange={(e) => {
                          let temp = this.state.categories;
                          temp["is_visible"] = Number(e.target.value);
                          this.setState({
                            categories: temp,
                          });
                        }}
                        checked={this.state.categories.is_visible}
                      />
                      <label for="visible_on_web1">Yes</label>
                      <input
                        className="field border-box small"
                        type="radio"
                        id="visible_on_web2"
                        name="visible_on_web"
                        value="0"
                        onChange={(e) => {
                          let temp = this.state.categories;
                          temp["is_visible"] = Number(e.target.value);
                          this.setState({
                            categories: temp,
                          });
                        }}
                        checked={!this.state.categories.is_visible}
                      />
                      <label for="visible_on_web2">No</label>
                    </div>
                    <div
                      className="border-box field2 clearfix"
                    >
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                         On Feed
                      </div>
                      <input
                        className="field border-box small"
                        type="radio"
                        id="visible_on_web3"
                        name="on_feed"
                        value="1"
                        onChange={(e) => {
                          let temp1 = this.state.categories;
                          temp1["is_feed_data"] = Number(e.target.value);
                          this.setState({
                            categories: temp1,
                          });
                        }}
                        checked={this.state.categories.is_feed_data}
                      />
                      <label for="visible_on_web1">Yes</label>
                      <input
                        className="field border-box small"
                        type="radio"
                        id="visible_on_web4"
                        name="on_feed"
                        value="0"
                        onChange={(e) => {
                          let temp1 = this.state.categories;
                          temp1["is_feed_data"] = Number(e.target.value);
                          this.setState({
                            categories: temp1,
                          });
                        }}
                        checked={!this.state.categories.is_feed_data}
                      />
                      <label for="visible_on_web2">No</label>
                    </div>
                  </div>
                  <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Clearance
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="clearance2"
                      name="clearance"
                      value="1"
                      onChange={(e) => {
                          let temp1 = this.state.categories;
                          temp1["is_clearance"] = Number(e.target.value);
                          this.setState({
                            categories: temp1,
                          });
                        }}
                      checked={this.state.categories.is_clearance}
                    />
                    <label htmlFor="checkout1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="clearance1"
                      name="clearance"
                      value="0"
                      onChange={(e) => {
                          let temp1 = this.state.categories;
                          temp1["is_clearance"] = Number(e.target.value);
                          this.setState({
                            categories: temp1,
                          });
                        }}
                      checked={!this.state.categories.is_clearance}
                    />
                    <label htmlFor="checkout2">No</label>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Trending
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="trending"
                      name="trending"
                      value="1"
                      onChange={(e) => {
                          let temp1 = this.state.categories;
                          temp1["is_trending"] = Number(e.target.value);
                          this.setState({
                            categories: temp1,
                          });
                        }}
                      checked={this.state.categories.is_trending}
                    />
                    <label htmlFor="trending">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="trending1"
                      name="trending"
                      value="0"
                      onChange={(e) => {
                          let temp1 = this.state.categories;
                          temp1["is_trending"] = Number(e.target.value);
                          this.setState({
                            categories: temp1,
                          });
                        }}
                      checked={!this.state.categories.is_trending}
                    />
                    <label htmlFor="trending1">No</label>
                  </div>
                  </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Add Image
                    </div>
                    <input
                      type="file"
                      onChange={this.onChange}
                      accept="image/*"
                      ref={this.myRef}
                    />
                    <div className="border-box floatright rightalign">
                      {this.state.showSaveForImages && (
                        <>
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClick("save_processed_image")}
                        >
                          Save Processed Image
                        </button>
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClick("save_image")}
                        >
                          Save Image
                        </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">{images}</div>
                  {/* <div className="border-box clearfix field2">
                                        Delete
                                    </div> */}
                </div>

                {Object.keys(this.state.specifications).length > 0 ? (
                  <div className="border-box floatleft leftalign blackspan2 shadow-text">
                    Specifications
                  </div>
                ) : (
                  <></>
                )}

                {Object.keys(this.state.specifications).map((key) => (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        {this.state.specifications[key].name}
                      </div>
                      <input
                        type="text"
                        placeholder={this.state.specifications[key].name}
                        className="field border-box small"
                        value={this.state.specifications[key].value}
                        onChange={(e) =>
                          this.handleChange(
                            this.state.specifications[key].name,
                            e,
                            key,
                            "specifications"
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
                <div>
                <div style={{display:'flex',flexDirection:'column',gap:'10px',margin: "2rem 0rem",}}>
                <div className="border-box leftalign blackspan2 shadow-text" style={{marginLeft:"1rem"}}>
                KIT:
                </div>
                <div  style={{width: "33%",marginLeft: "1rem",}}>
                          <MultiSelect
														options={this.state.searchTerm}
														labelKey='sku_code'
														checkbox_id='product_id'
														placeholder='Search'
														new_placeholder='Part no. search'
														disabledItems={[]}
														selectedOptions={this.state.searchedItem}
														onSearch={this.getLineItemsSearch}
														onChange={(value) =>
															this.setState(
																{
																	searchedItem: value,
																},
																() => this.afterFilterProductsChange()
															)
															}
													/>
                          </div>
                          {this.state.product_kit?this.state.product_kit.map((item)=>{
                           return(
                            <div style={{ display: "flex" }}>
                            <input
                              style={{width: "25%",marginLeft: "1rem",}}
                              type="text"
                              placeholder="product id"
                              className="field border-box small"
                              value={item}
                              
                            />
                            
                            <div className="border-box ">
                              <button
                                className="btn btn_small btncolorprim btncard move btncloseextra"
                                style={{ marginLeft: ".4rem" }}
                                onClick={()=>this.removeFromKit(item)}
                              >
                              
                                Remove
                              </button>
                              </div>
                            </div>
                          )
                          }):null}
                          </div>
                          </div>

                <div className="border-box floatleft leftalign blackspan2 shadow-text" style={{marginLeft:"1rem"}}>
                OEM Alternatives:
                </div>

                {Object.keys(this.state.oem_alternatives).map((key) => (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div style={{ display: "flex" }}>
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          SKU Code
                        </div>
                        {/* <div onClick={() => this.addNewSku()} style={{ fontSize: "2rem", cursor: "pointer" }}>+</div> */}
                      </div>
                      <input
                        type="text"
                        placeholder="sku code"
                        className="field border-box small"
                        value={this.state.oem_alternatives[key].oem_number}
                        onChange={(e) =>
                          this.handleChange(
                            this.state.oem_alternatives[key].name,
                            e,
                            key,
                            "oem_alternatives"
                          )
                        }
                      />
                    </div>
                    <div className="border-box clearfix field2">
                      <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        placeholder="sku code"
                        className="field border-box small"
                        value={this.state.oem_alternatives[key].oem_slug}
                        onChange={(e) =>
                          this.handleChange(
                            this.state.oem_alternatives[key].name,
                            e,
                            key,
                            "oem_alternatives"
                          )
                        }
                      />
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                             <div className="border-box ">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() =>
                              this.removeSku(
                                this.state.oem_alternatives[key].oem_number
                              )
                            }
                          style={{ marginLeft: "1rem" }}
                        >
                          Remove
                        </button>
                      </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                ))}
                {/* <div
                  onClick={() => this.addNewSku()}
                  style={{
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                >
                  +
                </div> */}
                {/* <div className="border-box " style={{paddingBottom: "1rem",}}>
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.addNewSku()}
                          style={{ marginLeft: "1rem" }}
                        >
                          ADD OEM
                        </button>
                      </div> */}

                <div className="border-box floatleft leftalign blackspan2 shadow-text" style={{marginLeft:"1rem"}}>
                  OES Alternatives:
                </div>
                {Object.keys(this.state.oes_alternatives).map((key) => (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div style={{ display: "flex" }}>
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          SKU Code
                        </div>
                        {/* <div onClick={() => this.addNewSkuOes()} style={{ fontSize: "2rem", cursor: "pointer" }}>+</div> */}
                      </div>
                      <input
                        type="text"
                        placeholder="sku code"
                        className="field border-box small"
                        value={this.state.oes_alternatives[key].oes_number}
                        onChange={(e) =>
                          this.handleChange(
                            this.state.oes_alternatives[key].name,
                            e,
                            key,
                            "oes_alternatives"
                          )
                        }
                      />
                    </div>
                    <div className="border-box clearfix field2">
                      <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        placeholder="sku code"
                        className="field border-box small"
                        value={this.state.oes_alternatives[key].oes_slug}
                        onChange={(e) =>
                          this.handleChange(
                            this.state.oes_alternatives[key].name,
                            e,
                            key,
                            "oes_alternatives"
                          )
                        }
                      />
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                        <div className="border-box ">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() =>
                              this.removeSkuOes(
                                this.state.oes_alternatives[key].oes_number
                              )
                            }
                          style={{ marginLeft: "1rem" }}
                        >
                          Remove
                        </button>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="border-box " style={{paddingBottom: "1rem",}}>
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.addNewSkuOes()}
                          style={{ marginLeft: "1rem" }}
                        >
                          ADD OES
                        </button>
                      </div> */}


                {/* <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Color</div>
                    <input type="text" placeholder="Color" value={this.state.Color} className="field border-box small" onChange={(e) => this.handleChange("Color", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Safety Rating</div>
                    <input type="text" placeholder="safety rating" value={this.state["Safety Rating"]} className="field border-box small" onChange={(e) => this.handleChange("Safety Rating", e)} />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Size</div>
                    <input type="text" placeholder="Size" value={this.state.Size} className="field border-box small" onChange={(e) => this.handleChange("Size", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Suitable For</div>
                    <input type="text" placeholder="suitable for" value={this.state["Suitable For"]} className="field border-box small" onChange={(e) => this.handleChange("Suitable For", e)} />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Type</div>
                    <input type="text" placeholder="Type" value={this.state.Type} className="field border-box small" onChange={(e) => this.handleChange("Type", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Visor Present</div>
                    <input type="text" placeholder="Visor present" value={this.state["Visor Present"]} className="field border-box small" onChange={(e) => this.handleChange("Visor Present", e)} />
                  </div>
                </div> */}

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  {/* <div className="border-box floatright rightalign">
                                        <button
                                            class="btn btn_small btncolorprim btncard move btncloseextra"
                                            onClick={() =>
                                                this.handleButtonClick(
                                                    "save",
                                                    "product"
                                                )
                                            }
                                        >
                                            Save
                                        </button>
                                    </div> */}
                </div>

                {this.state.compatibility_params &&
                this.state.compatibility_params.length != 0 ? (
                  compatibility_params
                ) : (
                  <React.Fragment />
                )}

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  {/* <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.addCompatibility()}>Add compatibility</button></div> */}
                </div>

                {this.state.compatibility_params &&
                this.state.compatibility_params.length != 0 ? (
                  <div className="border-box clearfix footer">
                    {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.handleButtonClick("save_compatibility")
                        }
                      >
                        Save compatibility
                      </button>
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          );

        case "discount":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
              // style={{ margin: "5px", width: "100%" }}
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text"  style={{ padding: "0px 0px 0px 10px" }}>
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                  <div className="border-box floatright rightalign">
                    {this.state.showSaveForDiscount && (
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.handleButtonClickDiscount("save")}
                      >
                        Save
                      </button>
                    )}
                  </div>
                  {/* <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.addCompatibility()}>Add compatibility</button></div> */}
                  {/* Hide Add compatibilty for the time being */}
                </div>
                {!this.state.showSaveForDiscount && (
                  <div className="border-box clearfix fields">
                    <select
                      value={this.state.mobile}
                      onChange={(e) => this.handleChangeForPhone("mobile", e)}
                    >
                      <option value="9566212403">9566212403</option>
                      <option value="8871719169">8871719169</option>
                      <option value="8555968996">8555968996</option>
                      <option value="8595910689">8595910689</option>
                      <option value="9711181607">9711181607</option>
                      <option value="8802442620">8802442620</option>
                      <option value="8595910689">8595910689</option>
                    </select>
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.handleButtonClickOTP("otp")}
                      >
                        Get OTP
                      </button>
                    </div>
                    <div className="border-box clearfix field2">
                      {/* <input
                                            style={{
                                                width: "62%",
                                            }}
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder="Enter Phone Number"
                                            value={this.state.mobile}
                                            maxlength="10"
                                            className="field border-box small"
                                            onChange={(e) =>
                                                this.handleChangeForPhone(
                                                    "mobile",
                                                    e
                                                )
                                            }
                                        /> */}
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                        {/* <span className="border-box clearfix uppercased fieldtitle weight700 blackspan7">SKU Brand</span>
                    <span className="field border-box small" style={{ paddingLeft: "20px" }}>{this.state.sku_brand}</span> */}
                        {this.state.showVerifyOTP && (
                          <>
                            <input
                              style={{
                                width: "28%",
                                marginLeft: "52%",
                              }}
                              type="text"
                              placeholder="Enter OTP"
                              value={this.state.otp}
                              maxLength="4"
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChangeForOTP("otp", e)
                              }
                            />
                            <div className="border-box floatright rightalign">
                              <button
                                class="btn btn_small btncolorprim btncard move btncloseextra"
                                onClick={() =>
                                  this.handleButtonClickOTPVerify("otp")
                                }
                              >
                                Verify OTP
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {this.state.showSaveForDiscount && (
                  <>
                    {/* <div className='border-box clearfix fields'>
											<div className='border-box clearfix field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Customer Id
												</div>
												<input
													type='text'
													placeholder='Customer Id'
													className='field border-box small'
													style={{ background: '#f5f5f5' }}
													readOnly
													style={{ background: '#f5f5f5' }}
												/>
											</div>
											
										</div> */}

                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Customer Name
                        </div>
                        <input
                          type="text"
                          placeholder="Customer Name"
                          value={this.state.categories.first_name}
                          className="field border-box small"
                          style={{ background: "#f5f5f5" }}
                          readOnly
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Customer Mobile
                        </div>
                        <input
                          type="text"
                          placeholder="Customer Mobile"
                          value={this.state.categories.phone_number}
                          className="field border-box small"
                          style={{ background: "#f5f5f5" }}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Shipping Address
                        </div>
                        <textarea
                          type="text"
                          placeholder="Shipping Address"
                          value={this.state.categories.shipping_address}
                          className="field border-box small"
                          readOnly
                          style={{minHeight:'5rem'}}
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Shipping State
                        </div>
                        <textarea
                          type="text"
                          placeholder="Shipping State"
                          value={this.state.categories.shipping_state}
                          className="field border-box small"
                          readOnly
                          style={{minHeight:'5rem'}}

                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Customer Address
                        </div>
                        <textarea
                          style={{minHeight:'5rem'}}
                          type="text"
                          placeholder="Customer Address"
                          // value={"Address comes here"}
                          value={
                            Array.isArray(
                              this.state.categories.customer_address
                            )
                              ? this.state.categories.customer_address.map(
                                  (address, i) => {
                                    let res = "";
                                    if (i !== 0) res = "\n";
                                    res += i + 1 + ") " + address;
                                    return res;
                                  }
                                )
                              : "Address Not Available"
                          }
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                    </div>

                    {/* 						
										<div className='border-box fields d-flex'>
											<div className='border-box clearfix field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													GSTIN Number
												</div>
												<input
													type='text'
													placeholder='GSTIN Number'
													// value={this.state.categories.phone_number}
													className='field border-box small'
													style={{ background: '#f5f5f5' }}
													// readOnly
												/>
											</div>

											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													UPDATE GSTIN
												</div>
												<button
													type='button'
													style={{ height: '2rem' }}
													className='btn btn_small btnwhite btncard move width-100'
												>
													UPDATE
												</button>
											</div>
										</div>

										<div className='border-box fields d-flex'>
											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													GSTIN
												</div>
												<MultiSelect
													// options={this.state.modifiedExtraDiscountData}
													labelKey='brand_name'
													checkbox_id='brand_name'
													placeholder='GSTIN'
													disabledItems={[]}
													selectedOptions={{
														brand_name: this.state.selectedBrand,
													}}
													onChange={(e) => {
														this.brandSelectValue(
															e.brand_name,
															e.default_discount
														);
													}}
												/>
											</div>

											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Excel for all given discounts
												</div>
												<button
													type='button'
													style={{ height: '2rem' }}
													className='btn btn_small btnwhite btncard move width-100'
												>
													Download
												</button>
											</div>
										</div>

										<div className='border-box fields d-flex'>
											<div className='border-box clearfix field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Product id
												</div>
												<input
													type='text'
													placeholder='Product id'
													// value={this.state.categories.phone_number}
													className='field border-box small'
													style={{ background: '#f5f5f5' }}
													// readOnly
												/>
											</div>

											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Discount
												</div>
												<input
													type='number'
													placeholder='Discount'
													value={this.state.discountValue}
													className='field border-box small'
													onChange={(e) =>
														this.handleChangeForDiscount('discountValue', e)
													}
												/>
											</div>
										</div> */}

                    <div className="border-box fields d-flex">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Brand
                        </div>
                        <MultiSelect
                          options={this.state.modifiedExtraDiscountData}
                          labelKey="brand_name"
                          checkbox_id="brand_name"
                          placeholder="Brand"
                          disabledItems={[]}
                          selectedOptions={{
                            brand_name: this.state.selectedBrand,
                          }}
                          onChange={(e) => {
                            this.brandSelectValue(
                              e.brand_name,
                              e.default_discount
                            );
                          }}
                        />
                      </div>

                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Brand Discount
                        </div>
                        <input
                          type="number"
                          disabled={!this.state.selectedBrand}
                          placeholder="Discount"
                          value={this.state.discountValue}
                          style={{
                            border: `${
                              !this.state.discountValue
                                ? "1px solid #e5e5e5"
                                : ""
                            }`,
                          }}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleChangeForDiscount("discountValue", e)
                          }
                        />
                      </div>
                    </div>

                    <div className="border-box fields d-flex">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                         Sub Category
                        </div>
                        <MultiSelect
                           options={ this.state.sub_category_discounts && Object.keys(
                            this.state.sub_category_discounts
                          ).map((subCategory) => ({
                            name: subCategory,
                          }))}

														labelKey='name'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
														selectedOptions={_.get(
																this.state,
																['userSubCategoryDiscountKey'],
																null
															)
														}
														onChange={(value, e) => {
															this.setState({
																userSubCategoryDiscountKey: value,
                                userSubCategoryDiscount: undefined,

															});
														}}
													/>
                      </div>

                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          SubCategory Discount
                        </div>
                        <input
                          type="number"
                          disabled={!this.state.userSubCategoryDiscountKey}
                          placeholder="Discount"
                          min={0}
                          value={this.state.sub_category_discounts && this.state.sub_category_discounts[this.state.userSubCategoryDiscountKey && this.state.userSubCategoryDiscountKey.name]}
                          style={{
                            border: `${
                              !this.state.userSubCategoryDiscountKey
                                ? "1px solid #e5e5e5"
                                : ""
                            }`,
                          }}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleChangeForSubCategoryDiscount(
                              "userSubCategoryDiscount",
                              e
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="border-box fields d-flex">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Brand
                        </div>
                        <MultiSelect
                          options={_.get(this.state, ["brandsList"], [])}
                          labelKey="sku_brand"
                          checkbox_id="sku_brand"
                          placeholder="Brand"
                          disabledItems={[]}
                          selectedOptions={{
                            sku_brand: _.get(
                              this.state,
                              ["brandSubCategoryDiscount", "brand"],
                              ""
                            ),
                          }}
                          onChange={(e) => {
                            this.setState(
                              {
                                brandSubCategoryDiscount: {
                                  ...this.state.brandSubCategoryDiscount,
                                  brand: e.sku_brand,
                                  discount: "",
                                },
                              },
                              () => {
                                if (this.state.brandSubCategoryDiscount.subCategory)
                                  this.getBrandSubCategoryDiscount();
                              }
                            );
                          }}
                        />
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          SubCategory
                        </div>
                        <MultiSelect
														options={this.state.subCategoryList&&this.state.subCategoryList.map((item)=>{
														return( {...item,label:item.name + '-' + item.id})
														})}
														labelKey='label'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
														selectedOptions={{name: _.get(
																this.state,
																['brandSubCategoryDiscount','subCategory'],
																null
															)
													}	}
														onChange={(value, e) => {
                            this.setState(
                              {
                                brandSubCategoryDiscount: {
                                  ...this.state.brandSubCategoryDiscount,
                                  subCategory: value.name,
                                  discount: "",
                                },
                              },
                              () => this.getBrandSubCategoryDiscount()
                            );
                            }}
													/>
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Discount
                        </div>
                        <input
                          type="number"
                          disabled={
                            !this.state.brandSubCategoryDiscount.brand ||
                            !this.state.brandSubCategoryDiscount.subCategory
                          }
                          placeholder="Discount"
                          value={
                            !this.discountBrandCategory
                              ? _.get(
                                  this.state,
                                  ["brandSubCategoryDiscount", "discount"],
                                  0
                                )
                              : this.discountBrandCategory
                          }
                          className="field border-box small"
                          style={{
                            border: `${
                              !this.state.brandSubCategoryDiscount.brand ||
                              !this.state.brandSubCategoryDiscount.category
                                ? "1px solid #e5e5e5"
                                : ""
                            }`,
                          }}
                          onChange={(e) =>
                            this.handleChangeForBrandSubCategoryDiscount(e)
                          }
                        />
                      </div>
                    </div>

                    {/* <div className='border-box fields d-flex'>
											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Category
												</div>
												<MultiSelect
													// options={this.state.modifiedExtraDiscountData}
													labelKey='brand_name'
													checkbox_id='brand_name'
													placeholder='Category'
													disabledItems={[]}
													selectedOptions={{
														brand_name: this.state.selectedBrand,
													}}
													onChange={(e) => {
														this.brandSelectValue(
															e.brand_name,
															e.default_discount
														);
													}}
												/>
											
											</div>

											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Discount
												</div>
												<input
													type='number'
													placeholder='Discount'
													value={this.state.discountValue}
													className='field border-box small'
													onChange={(e) =>
														this.handleChangeForDiscount('discountValue', e)
													}
												/>
											</div>
										</div>

										<div className='border-box fields d-flex'>
											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Brand
												</div>
												<MultiSelect
													// options={this.state.modifiedExtraDiscountData}
													labelKey='brand_name'
													checkbox_id='brand_name'
													placeholder='Brand'
													disabledItems={[]}
													selectedOptions={{
														brand_name: this.state.selectedBrand,
													}}
													onChange={(e) => {
														this.brandSelectValue(
															e.brand_name,
															e.default_discount
														);
													}}
												/>
											</div>
											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Category
												</div>
												<MultiSelect
													// options={this.state.modifiedExtraDiscountData}
													labelKey='brand_name'
													checkbox_id='brand_name'
													placeholder='Category'
													disabledItems={[]}
													selectedOptions={{
														brand_name: this.state.selectedBrand,
													}}
													onChange={(e) => {
														this.brandSelectValue(
															e.brand_name,
															e.default_discount
														);
													}}
												/>
											</div>

											<div className='border-box field2'>
												<div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
													Discount
												</div>
												<input
													type='number'
													placeholder='Discount'
													value={this.state.discountValue}
													className='field border-box small'
													onChange={(e) =>
														this.handleChangeForDiscount('discountValue', e)
													}
												/>
											</div>
										</div> */}

                    {/* <div className="slotBookContainer">
                      <div
                        className="selectDate"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Pay Later :</div>
                        <div
                          style={{
                            marginLeft: "65%",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchLater"
                            id="toggleSwitchLater"
                            onChange={(e) =>
                              this.handleChangeForPayments(e, "isPayLater", 0)
                            }
                            defaultChecked={false}
                            checked={
                              this.state.payment_methods[0].is_enabled
                              // .isPayLater
                            }
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitchLater"
                          >
                            <span className="toggle-switch-inner" />
                          </label>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="slotBookContainer">
                      <div
                        className="selectDate"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Cash On Delivery :</div>
                        <div
                          style={{
                            marginLeft: "54%",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchCash"
                            id="toggleSwitchCash"
                            onChange={(e) =>
                              this.handleChangeForPayments(e, "isCOD", 1)
                            }
                            defaultChecked={false}
                            checked={this.state.payment_methods[1].is_enabled}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitchCash"
                          >
                            <span className="toggle-switch-inner" />
                          </label>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="slotBookContainer">
                      <div
                        className="selectDate"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div> UPI :</div>
                        <div
                          style={{
                            marginLeft: "73%",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchUPI"
                            id="toggleSwitchUPI"
                            onChange={(e) =>
                              this.handleChangeForPayments(e, "isUPI", 2)
                            }
                            defaultChecked={false}
                            checked={this.state.payment_methods[2].is_enabled}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitchUPI"
                          >
                            <span className="toggle-switch-inner" />
                          </label>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="slotBookContainer">
                      <div
                        className="selectDate"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div> Wallet :</div>
                        <div
                          style={{
                            marginLeft: "68%",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchWallet"
                            id="toggleSwitchWallet"
                            onChange={(e) =>
                              this.handleChangeForPayments(e, "isWallet", 4)
                            }
                            defaultChecked={false}
                            checked={this.state.payment_methods[4].is_enabled}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitchWallet"
                          >
                            <span className="toggle-switch-inner" />
                          </label>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="slotBookContainer">
                      <div
                        className="selectDate"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div> Card :</div>
                        <div
                          style={{
                            marginLeft: "70%",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchCard"
                            id="toggleSwitchCard"
                            onChange={(e) =>
                              this.handleChangeForPayments(e, "isCard", 3)
                            }
                            defaultChecked={false}
                            checked={this.state.payment_methods[3].is_enabled}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitchCard"
                          >
                            <span className="toggle-switch-inner" />
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

                {/* {Object.keys(this.state.discounts).map(
                                    (key) => (
                                        <div className="border-box clearfix fields">
                                            <div className="border-box clearfix field2">
                                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                                    Brand
                                                </div>
                                                <span className="field border-box small">
                                                    {key}
                                                </span>
                                            </div>
                                            <div className="border-box clearfix field2">
                                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                                    Discount
                                                </div>
                                                <span className="field border-box small">
                                                    {this.state.discounts[key]}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )} */}

                {/* <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Selected Brand</div>
                    <span className="field border-box small">{this.state.selectedBrand ? this.state.selectedBrand : "Select Brand"}</span>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Discount on the Brand</div>
                    <span className="field border-box small">{this.state.discountValue}</span>
                  </div>
                </div> */}

                {/* <div className="border-box clearfix footer">
                  <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.handleButtonClick("brand")}>Add Brand</button></div>
                </div> */}

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                                        <button
                                            class="btn btn_small btncolorprim btncard move btncloseextra"
                                            onClick={() =>
                                                this.handleButtonClickDiscount(
                                                    "save"
                                                )
                                            }
                                        >
                                            Save
                                        </button>
                                    </div> */}
                </div>
              </div>
            </div>
          );
          case "customers":
            return (
              <div
                id="dashboardprimeextra"
                className="border-box bgwhite1 shadow-box"
              >
                <div className="extramainpanel border-box leftalign">
                  <div className="border-box clearfix title">
                    <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text"  style={{ padding: "0px 0px 0px 10px" }}>
                      {this.props.content.type == "add" ? "Add" : "Edit"} details
                    </div>
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.handleButtonClick("close")}
                      >
                        Close
                      </button>
                    </div>
                    <div className="border-box floatright rightalign">
                    {this.state.loader_1?
                        <ButtonLoader/>:
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleMonthlyTarget(this.props.content && this.props.content.content && this.props.content.content.customer_id)}
                        >
                          Update
                        </button>}
                    </div>
                  </div>
                      <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Customer Name
                          </div>
                          <input
                            type="text"
                            placeholder="Customer Name"
                            value={this.state.categories.first_name}
                            className="field border-box small"
                            style={{ background: "#f5f5f5" }}
                            readOnly
                          />
                        </div>
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Customer Mobile
                          </div>
                          <input
                            type="text"
                            placeholder="Customer Mobile"
                            value={this.state.categories.phone_number}
                            className="field border-box small"
                            style={{ background: "#f5f5f5" }}
                            readOnly
                          />
                        </div>
                      </div>
  
                      <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Monthly Target
                          </div>
                          <input
                            type="number"
                            placeholder="Monthly Target"
                            value={this.state.categories.monthly_target}
                            className="field border-box small"
                            min="0"
                            onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,monthly_target:Number(e.target.value)}})}
                          />
                        </div>
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Email
                          </div>
                          <input
                            type="text"
                            placeholder="Email"
                            value={this.state.categories.email}
                            className="field border-box small"
                            onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,email:e.target.value}})}
                          />
                        </div>
                      </div>
                      <div className="border-box clearfix fields">
                      {this.props.content.content.gst_number?<div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            GST Number
                          </div>
                          <input
                            style={{textTransform:'uppercase',background: '#f5f5f5'}}
                            type="text"
                            placeholder="GST number"
                            value={this.state.categories.gst_number}
                            className="field border-box small"
                            onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,gst_number:e.target.value.toUpperCase()}})}
                            readOnly
                          />
                        </div>:null}
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Delivery Charge
                          </div>
                          <input
                            type="number"
                            placeholder="Delivery Charge"
                            value={this.state.categories.delivery_charge}
                            className="field border-box small"
                            min="0"
                            onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,delivery_charge:Number(e.target.value)}})}
                          />
                        </div>
                      </div>
                      <div className="border-box clearfix fields">

                      <div className="border-box field2" style={{ width: "25%" }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Is B2B
                      </div>
                      <div className="toggleWid">
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchWallet"
                            id='isB2B'
                            checked={this.state.categories.is_b2b?this.state.categories.is_b2b:false}
                            onChange={()=>{
                              this.state.categories.is_b2b = !this.state.categories.is_b2b;
                              this.setState({categories:JSON.parse(JSON.stringify(this.state.categories))})}}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor='isB2B'
                          >
                          <span className="toggle-switch-inner" />
                        </label>
                      </div>
              </div>
          </div>
                      <div className="border-box clearfix fields" style={{display:'flex',flexDirection:'column'}}>
                      <div style={{display:'flex',alignItems:'center'}}>
                        <div className="border-box uppercased weight700 blackspan7" style={{paddingLeft:'10px',fontSize:'15px'}}>
                          Payment Options
                        </div>
                      {this.state.loader?
                        <div style={{marginLeft:'1rem'}}>
                          <ButtonLoader width={'6.5rem'}/>
                        </div> :
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setToDefaultPaymentMode()}
                        style={{marginLeft:'1rem'}}
                        >
                        Set to Default
                      </button>
                      }
                      </div>
                  {this.state.categories.payment_mode && this.state.categories.payment_mode.map((item)=>{
                    return (
                      <>
                      {item.name!=='Pay via Link'?  <div style={{borderBottom:'2px solid #e6e6e6'}}>
                      <div className="border-box paymentOptionsDiv" style={item.array && item.array.length?{display:'flex',marginBottom:'1rem'}:{display:'flex'}}>
                        <div className="border-box clearfix uppercased weight700 blackspan7">
                        {item.name}
                        </div>
                         <div className="toggleWid">
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchWallet"
                            id={item.name}
                            checked={item.is_visible}
                            onChange={()=>{
                              item.is_visible = !item.is_visible;
                             item.array= item.array && item.array.map(obj=>{return {...obj,is_visible:item.is_visible}})
                              this.setState({categories:JSON.parse(JSON.stringify(this.state.categories))})}}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor={item.name}
                          >
                          <span className="toggle-switch-inner" />
                        </label>
                      </div>
                      </div>
                      <div style={{paddingLeft:'5rem'}}>
                      {item.array?(item.array.map((arrayItem)=>{
                        return(
                          <>
                          <div className="border-box paymentOptionsDiv" style={{display:'flex'}}>
                          <div className="border-box clearfix weight700 blackspan7">
                          {arrayItem.name}
                          </div>
                          <div className="toggleWid">
                          <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name="toggleSwitchWallet"
                            id={item.name+item.enum_name + arrayItem.name}
                            checked={arrayItem.is_visible}
                            onChange={()=> {
                              if(item.is_visible){
                                arrayItem.is_visible = !arrayItem.is_visible;
                                this.setState({categories:JSON.parse(JSON.stringify(this.state.categories))});
                              }
                            }}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor={item.name+item.enum_name + arrayItem.name}
                          >
                          <span className="toggle-switch-inner" />
                        </label>
                      </div>
                          </div>
                          </>
                        )
                      })):null}
                      </div>
                      </div>:null}
                      </>
                    )})}
                  </div>
                  <div className="border-box clearfix footer">
                  </div>
                </div>
              </div>
            );
            case "wallet":
              return (
                <div
                  id="dashboardprimeextra"
                  className="border-box bgwhite1 shadow-box"
                >
                  <div className="extramainpanel border-box leftalign">
                    <div className="border-box clearfix title">
                      <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text"  style={{ padding: "0px 0px 0px 10px" }}>
                        {this.props.content.type == "add" ? "Add" : "Edit"} details
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClick("close")}
                        >
                          Close
                        </button>
                      </div>
                      <div className="border-box floatright rightalign">
                     
                        {this.state.loader?
                           <div style={{height: "1.8rem",width: "3.7rem",}}>
                            <ButtonLoader width={'100%'} height={'100%'}/>
                            </div> :
                          <button
                            class="btn btn_small btncolorprim btncard move btncloseextra"
                            onClick={() => this.handleWallet(this.props.content && this.props.content.content && this.props.content.content.customer_id)}
                          >
                            Save
                          </button>}
                      </div>
                    </div>
                   {this.state.categories.is_b2b? <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                User Type:<span style={{color:'#e73c33'}}> B2B</span> 
                              </div>
                            </div>
                        </div>:<></>}

                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Customer Name
                            </div>
                            <input
                              type="text"
                              placeholder="Customer Name"
                              value={this.state.categories.first_name}
                              className="field border-box small"
                              style={{ background: "#f5f5f5" }}
                              readOnly
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Customer Mobile
                            </div>
                            <input
                              type="text"
                              placeholder="Customer Mobile"
                              value={this.state.categories.phone_number}
                              className="field border-box small"
                              style={{ background: "#f5f5f5" }}
                              readOnly
                            />
                          </div>
                        </div>
    
                        <div className="border-box clearfix fields">
                          <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                             Total GoApp Money
                            </div>
                            <input
                              type="number"
                              placeholder="GoApp Money"
                              value={this.props.content && this.props.content.content && this.props.content.content.go_app_money}
                              className="field border-box small"
                              min="0"
                              readOnly
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Email
                            </div>
                            <input
                              type="text"
                              placeholder="Email"
                              value={this.state.categories.email}
                              className="field border-box small"
                              onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,email:e.target.value}})}
                            />
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                        {this.props.content.content.gst_number?
                        <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              GST Number
                            </div>
                            <input
                              style={{textTransform:'uppercase',background: '#f5f5f5'}}
                              type="text"
                              placeholder="GST number"
                              value={this.state.categories.gst_number}
                              className="field border-box small"
                              onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,gst_number:e.target.value.toUpperCase()}})}
                              readOnly
                            />
                          </div>:null}
                          <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                             Add GoApp Money
                            </div>
                            <input
                              type="number"
                              placeholder="GoApp Money"
                              value={this.state.categories.amount}
                              className="field border-box small"
                              min="0"
                              onKeyPress={event => this.validateNumber(event)}
                              onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,amount:Number(e.target.value)}})}
                            />
                          </div>
                         
                        </div>
                        <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              End Date {this.state.categories.is_b2b?<span style={{fontSize:'12px',fontWeight:400,textTransform: "capitalize"}}> (Optional)</span>:<></>}
                            </div>
                            <input
                              type="date"
                              placeholder="End Date"
                              value={this.state.categories.end_date}
                              className="field border-box small"
                              onChange={(e)=>this.TodayDate(e)}
                            />
                          </div>
                          <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Remark
                            </div>
                            <input
                              style={{textTransform:'uppercase',background: '#f5f5f5'}}
                              type="text"
                              placeholder="Remark"
                              value={this.state.categories.remark}
                              className="field border-box small"
                              onKeyPress={(e) => {
                                try {
                                  var startPos = e.currentTarget.selectionStart;
                                  if (e.which === 32 && startPos == 0) {
                                    e.preventDefault();
                                  }
                                } catch (err) { console.log(err); }
                              }
                              }
                              onChange={(e)=>this.setState({...this.state,categories:{...this.state.categories,remark:e.target.value}})}
                            />
                          </div>
                        </div>
                    <div className="border-box clearfix footer">
                    </div>
                  </div>
                </div>
              );

        case "pricing":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
              style={{ margin: "5px", width: "100%" }}
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle blackspan2 weight900">
                      Product ID - {this.state.product_id}
                    </div>{" "}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      SKU DESC
                    </div>
                    <input
                      type="text"
                      placeholder="SKU DESC"
                      value={this.state.sku_desc}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("sku_desc", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      SKU Code
                    </div>
                    <input
                      type="text"
                      placeholder="SKU Code"
                      value={this.state.sku_code}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("sku_code", e)}
                      disabled
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      MRP
                    </div>
                    <input
                      type="text"
                      placeholder="MRP"
                      value={this.state.mrp}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("mrp", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      HSN Code
                    </div>
                    <input
                      type="text"
                      placeholder="HSN Code"
                      value={this.state.hsn_code}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("hsn_code", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      TAX
                    </div>
                    <select
                      className="dashboardfields dashboardchangefields select"
                      id="fieldstarttimefrom"
                      style={{
                        width: "130px",
                      }}
                      defaultValue={this.props.content.content.igst}
                      onChange={(e) => this.handleChange("tax", e)}
                    >
                      {taxOptions.map((option) => (
                        <option>{option}</option>
                      ))}
                      {/* <option value="18">18</option>
                                            <option value="28">28</option> */}
                    </select>
                    {/* <input
                                            type="text"
                                            placeholder="tax"
                                            value={this.state.tax}
                                            className="field border-box small"
                                            onChange={(e) =>
                                                this.handleChange("tax", e)
                                            }
                                        /> */}
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Unit Price
                    </div>
                    <input
                      disabled
                      type="text"
                      placeholder="Unit Price"
                      value={this.state.price}
                      className="field border-box small"
                      // onChange={(e) => this.handleChange("unit_price", e)}
                    />
                  </div>
                </div>

                <div className="border-box clearfix footer">
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClickPricing("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );

        case "coupons":
          return (
            <ManageCoupons
              handleButtonClick={this.handleButtonClick}
              brandsList={this.state.brandsList}
              couponData={this.props.content.content}
              type={this.props.content.type}
              categoriesList={this.state.categoriesList}
              pageRefresh={() =>
                this.props.onMiddleExtraButtonClick("save", null)
              }
            />  
          );
          case "agents":
          return (
            <ManageAgents
              handleButtonClick={this.handleButtonClick}
              agentData={this.props.newContent?this.props.newContent:this.props.content.content}
              type={this.props.content.type}
              getAgent={this.props.getAgent}
              pageRefresh={() =>
                this.props.onMiddleExtraButtonClick("save", null)
              }
            />
          );

        case "vehicles":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
              style={{ margin: "5px", width: "100%" }}
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.handleButtonClick("close")}
                      >
                        Back
                      </button>
                    </div>
                    {/* {this.props.content.sourceType !== 'other' ? ( */}
                    <div
                      className="border-box footer"
                      style={{ overflow: "hidden" }}
                    >
                      <div className="border-box floatright rightalign">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.handleButtonClickVechile("save")}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    {/* ) : (
                      <div className='border-box floatright rightalign'>
                        <button
                          class='btn btn_small btncolorprim btncard move btncloseextra'
                          onClick={() => this.addVechile()}
                        >
                          Add New Varient
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
                {this.state.vechilesArr.map((vec, i) => {
                  return (
                    <>
                      <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Make
                          </div>
                          <input
                            type="text"
                            placeholder="Make"
                            value={vec.make || null}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChangeVechile(vec.make, e, i, "make")
                            }
                          />
                        </div>
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Model
                          </div>
                          <input
                            type="text"
                            placeholder="Model"
                            value={vec.model || null}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChangeVechile(vec.model, e, i, "model")
                            }
                          />
                        </div>
                      </div>
                      <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Variant
                          </div>
                          <input
                            type="text"
                            placeholder="Variant"
                            value={vec.varient}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChangeVechile(
                                vec.varient,
                                e,
                                i,
                                "varient"
                              )
                            }
                          />
                        </div>
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Fuel
                          </div>
                          <input
                            type="text"
                            placeholder="Fuel"
                            value={vec.fuel_engine}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChangeVechile(
                                vec.fuel_engine,
                                e,
                                i,
                                "fuel_engine"
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="border-box clearfix fields">
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Year
                          </div>
                          <input
                            type="text"
                            placeholder="Year"
                            value={vec.year}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChangeVechile(vec.year, e, i, "year")
                            }
                          />
                        </div>
                        <div className="border-box clearfix field2">
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            End Year
                          </div>
                          <input
                            type="text"
                            placeholder="End Year"
                            value={vec.end_year}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChangeVechile(
                                vec.end_year,
                                e,
                                i,
                                "end_year"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="border-box ">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() =>
                            this.handleButtonClickVechileDelete(
                              vec.compatibility_group_id
                            )
                          }
                          style={{ marginLeft: "1rem" }}
                        >
                          Delete Vehicle
                        </button>
                      </div>
                    </>
                  );
                })}
                {(this.state.vechilesArr.length > 1 ||
                  this.props.content.sourceType === "other") && (
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClickVechilePost()}
                      style={{ marginRight: "1rem", marginBottom: "1rem" }}
                    >
                      Save Vehicle
                    </button>
                  </div>
                )}
              </div>
            </div>
          );

        case "categories_spares2":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
              style={{ margin: "5px", width: "100%" }}
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div
                    className="border-box clearfix footer"
                    style={{ overflow: "initial" }}
                  >
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.handleButtonClickCategories("save")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix">
                    <div className="border-box clearfix uppercased fieldtitle blackspan2 weight900">
                      Segment ID - {this.state.categories[0].segment_id}
                    </div>
                    {this.state.categories.map((cat, i) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              Name
                            </div>
                            <div
                              className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              HSN
                            </div>
                            <div
                              className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              TAX
                            </div>
                            <div
                              className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              Discount
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Name"
                              value={cat.name}
                              className="field border-box small"
                              onChange={(e) =>
                                this.handleChange(cat.name, e, i, "name")
                              }
                            />
                            <input
                              type="text"
                              placeholder="HSN"
                              className="field border-box small"
                            />
                            <input
                              type="text"
                              placeholder="TAX"
                              className="field border-box small"
                            />
                            <input
                              type="text"
                              placeholder="Discount"
                              className="field border-box small"
                            />
                            <div
                              onClick={() =>
                                this.removeCategory(
                                  cat._id,
                                  cat.segment_id,
                                  cat.name
                                )
                              }
                              style={{
                                fontSize: "2rem",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div
                  className="border-box clearfix fields"
                  style={{ display: "flex", width: "45%" }}
                >
                  <div className="border-box clearfix field2">
                    <div className="border-box floatleft leftalign blackspan2 shadow-text">
                      Add New
                    </div>
                  </div>
                  <div
                    onClick={() => this.addNewCategory()}
                    style={{
                      fontSize: "2rem",
                      cursor: "pointer",
                      marginTop: "11px",
                    }}
                  >
                    +
                  </div>
                </div>
                {/* {this.state.newCategory.map((key) => {
                                    return (
                                        <>
                                            <div className="border-box clearfix fields">
                                                <div className="border-box clearfix field2">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                                            New Category
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder="new category"
                                                        className="field border-box small"
                                                        value={
                                                            this.state
                                                                .newCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            this.handleChangeCategory(
                                                                this.state
                                                                    .newCategoryName,
                                                                e,
                                                                "newCategoryName"
                                                            )
                                                        }
                                                    />
                                                </div> */}
                {/* <div className="border-box clearfix field2">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                // onClick={() =>
                                                                //     this.removeSku(
                                                                //         this.state
                                                                //             .oem_alternatives[
                                                                //             key
                                                                //         ].oem_number
                                                                //     )
                                                                // }
                                                                style={{
                                                                    fontSize:
                                                                        "2rem",
                                                                    cursor:
                                                                        "pointer",
                                                                }}
                                                            >
                                                                -
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                {/* </div>
                                        </>
                                    );
                                })} */}
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() =>
                        this.handleButtonClickCategoriesPost("save")
                      }
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        case "master": {
          switch (_.get(this.props, ["masterTabCategory", "category"], "")) {
            case "brands_spares":
              return this.showBrandContent();
            case "categories_spares":
              return this.showCategoryContent();
            case "vehicles":
              return this.showVehicleContent();
            default:
              return this.showBrandContent();
          }
        }
        case "spares_banner":
          return (
            <>
         
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
              style={{ margin: "5px", width: "100%" }}
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} banner
                  </div>

                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() =>
                        this.handleButtonClickBannerDelete(this.state.banner_id)
                      }
                    >
                      Delete
                    </button>
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      NAME *
                    </div>
                    {this.props.content.type == "add" ? (
                      <input
                        type="text"
                        placeholder="Banner Name"
                        value={this.state.banner_name}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("banner_name", e)}
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Banner Name"
                        value={this.state.banner_name}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("banner_name", e)}
                      />
                    )}
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      TYPE *
                    </div>
                    {this.props.content.type == "add" ? (
                      <select
                        className="dashboardfields dashboardchangefields"
                        id="fieldstarttimefrom"
                        style={{
                          padding: ".45rem .75rem .55rem",
                          background: "transparent",
                        }}
                        defaultValue={this.state.banner_type}
                        onChange={(e) => this.handleChange("banner_type", e)}
                      >
                        {sparesType.map((option) => (
                          <option value={option.type}>{option.title}</option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        placeholder="Type"
                        style={{ background: "#f5f5f5" }}
                        value={
                          this.state.banner_type === 1
                            ? "Product"
                            : this.state.banner_type === 2
                            ? "Brand"
                            : this.state.banner_type === 3
                            ? "SubCategory"
                            : this.state.banner_type === 4
                            ? "Generic"
                            : this.state.banner_type === 5
                            ? "Brand + SubCategory"
                            : this.state.banner_type === 6
                            ? "Inventory- Additional Discount" 
                            : "Clearance"
                        }
                        
                        className="field border-box small"
                        readOnly
                      />
                    )}
                  </div>

                  {(() => {
                  switch (this.state.banner_type) {
                    case 1:
                      return(
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          PRODUCT ID *
                        </div>
                        {this.props.content.type == "add" ? (
                          <input
                            type="text"
                            placeholder="Product Id"
                            value={this.state.banner_product_id}
                            className="field border-box small"
                            onChange={(e) =>
                              this.handleChange("banner_product_id", e)
                            }
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Product Id"
                            value={this.state.banner_product_id}
                            className="field border-box small"
                          />
                        )}
                      </div>
                      );
                      case 2:
                        return (
                          <div className="border-box field2">
                            <div className="border-box uppercased fieldtitle weight700 blackspan7">
                               BRAND NAME*
                            </div>
                            {this.props.content.type == "add" ? (
                              <>
                                <MultiSelect
                                  options={this.state.brandDropDown}
                                  labelKey="brand_name"
                                  checkbox_id="brand_name"
                                  placeholder="BRAND NAME"
                                  disabledItems={[]}
                                  selectedOptions={this.state.banner_sku_brand}
                                  onChange={(e) => {
                                    this.setState({ banner_sku_brand: e });
                                  }}
                                />
                              </>
                            ) : (
                              <input
                                type="text"
                                placeholder="BRAND NAME"
                                value={this.state.banner_sku_brand}
                                className="field border-box small"
                                readOnly
                              />
                            )}
                          </div>
                      )
                      case 3:
                        return (
                        <>
                          <div className="border-box field2">
                            <div className="border-box uppercased fieldtitle weight700 blackspan7">
                               SUBCATEGORY *
                            </div>
                            {this.props.content.type == "add" ? (
                              <>
                              <MultiSelect
														options={this.state.bannerDropDownSubCategory&&this.state.bannerDropDownSubCategory.data.map((item)=>{
                              return( {...item,label:item.name + '-' + item.id})
                            })}
														labelKey='label'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
                            value={this.state.banner_sku_subCategory}

														selectedOptions={this.state.banner_sku_subCategory}
														onChange={(value, e) => { 
															this.setState({...this.state,banner_sku_subCategory: value});
														}}
													/>
                              </>
                            ) : (
                              <input
                                type="text"
                                placeholder="Sku Sub Category"
                                value={this.props.content.content.data.sub_category_display_id?this.state.banner_sku_subCategory +  this.props.content.content.data.sub_category_display_id:this.state.banner_sku_subCategory}
                                className="field border-box small"
                              />
                            )}
                          </div>
                        </>
                      );
                      case 4:
                        return null
                      case 5:
                        return(
                        <>
                        <div className="border-box field2">
                            <div className="border-box uppercased fieldtitle weight700 blackspan7">
                              BRAND NAME *
                            </div>
                            {this.props.content.type == "add" ? (
                              <>
                              <MultiSelect
                                  options={this.state.brandDropDown}
                                  labelKey="brand_name"
                                  checkbox_id="brand_name"
                                  placeholder="BRAND NAME"
                                  disabledItems={[]}
                                  selectedOptions={this.state.banner_sku_brand}
                                  onChange={(e) => {
                                    this.setState({ banner_sku_brand: e });
                                  }}
                                />
                              </>
                            ) : (
                              <input
                                type="text"
                                placeholder="BRAND NAME"
                                value={this.state.banner_sku_brand}
                                className="field border-box small"
                                readOnly
                              />                            )}
                          </div>
                    
                          <div className="border-box field2">
                            <div className="border-box uppercased fieldtitle weight700 blackspan7">
                               SUBCATEGORY *
                            </div>
                            {this.props.content.type == "add" ? (
                              <>
                              <MultiSelect
														options={this.state.bannerDropDownSubCategory&&this.state.bannerDropDownSubCategory.data.map((item)=>{
                              return( {...item,label:item.name + '-' + item.id})
                            })}
														labelKey='label'
														checkbox_id='name'
														placeholder='Sub-category'
														disabledItems={[]}
                            value={this.state.banner_sku_subCategory}

														selectedOptions={this.state.banner_sku_subCategory}
														onChange={(value, e) => { 
															this.setState({...this.state,banner_sku_subCategory: value});
														}}
													/>
                              </>
                            ) : (
                              <input
                                type="text"
                                placeholder="Sku Sub Category"
                                value={this.props.content.content.data.sub_category_display_id?this.state.banner_sku_subCategory +  this.props.content.content.data.sub_category_display_id:this.state.banner_sku_subCategory}
                                className="field border-box small"
                              />
                            )}
                          </div>
                        </>
                      )
                      case 6:
                        return(
                      <div className="border-box clearfix field2" style={{display: "flex",justifyContent: "space-between",alignItems: "flex-end"}} >
                      <div style={{flex:'1 auto'}}>
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Additional Discount*
                        </div>
                          <input
                            type="text"
                            placeholder="Additional Discount"
                            value={this.state.banner_additional}
                            className="field border-box small"
                            onKeyPress={(event) => this.validateNumber(event)}
                            onChange={(e) => this.handleAdditionalDiscountChange(e)}
                          />
                        </div>
                        <div  style={{padding: " 0 0 0.7rem .2rem",}}>
                            {this.state.loader?
                            <ButtonLoader />:
                        <form onSubmit={this.additionalDiscountBulkUpload}>
                           <button
                            className="btn btn_small btncolorprim btncard move btncloseextra"
                            ref={btn => { this.btn = btn; }}>
                            Confirm
                          </button>
                      </form>  
                      }
                          </div>
                      </div>
                      );
                      case 7:
                        return (
                    <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Clearance
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="clearance2"
                      name="clearance"
                      value="1"
                      onChange={this.handleClearance}
                      checked={this.state.banner_is_clearance}
                    />
                    <label htmlFor="checkout1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="clearance1"
                      name="clearance"
                      value="0"
                      onChange={this.handleClearance}
                      checked={!this.state.banner_is_clearance}
                    />
                    <label htmlFor="checkout2">No</label>
                  </div>)
                      default:
                      return null;
                  }
                  })()}

                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      SOURCE *
                    </div>
                    <input
                      type="text"
                      placeholder="Banner Source"
                      value={this.props.content.source || this.state.source}
                      className="field border-box small"
                      disabled
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      ORDER *
                    </div>
                    {this.props.content.type == "add" ? (
                      <input
                        type="text"
                        placeholder="Order"
                        value={this.state.banner_order}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("banner_order", e)}
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Order"
                        value={this.state.banner_order}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("banner_order", e)}
                      />
                    )}
                  </div>
                  {/* <div className="border-box clearfix field2">
                                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                            Is Visible *
                                        </div>
                                        <select
                                            className="dashboardfields dashboardchangefields"
                                            id="fieldstarttimefrom"
                                            style={{
                                                padding: ".45rem .75rem .55rem",
                                                background: "transparent"
                                            }}
                                            defaultValue={this.state.banner_is_visible}
                                            onChange={(e) => this.handleChange("banner_is_visible", e)}
                                        >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </select> 
                                    </div> */}
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Visible *
                    </div>
                    {/* <select
                      className="dashboardfields dashboardchangefields"
                      id="fieldstarttimefrom"
                      style={{
                              padding: ".45rem .75rem .55rem",
                              background: "transparent"
                            }}
                      defaultValue={this.state.banner_is_visible}
                      onChange={(e) => this.handleChange("banner_is_visible", e)}
                    >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                    </select>  */}
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_visible1"
                      name="is_visible"
                      value="1"
                      onChange={this.handleVisibilityOfBanner}
                      checked={this.state.banner_is_visible}
                    />
                    <label htmlFor="is_visible1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_visible2"
                      name="is_visible"
                      value="0"
                      onChange={this.handleVisibilityOfBanner}
                      checked={!this.state.banner_is_visible}
                    />
                    <label htmlFor="is_visible2">No</label>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Checkout Page
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="checkout1"
                      name="checkout"
                      value="1"
                      onChange={this.handleCheckout}
                      checked={this.state.banner_checkout_page}
                    />
                    <label htmlFor="checkout1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="checkout2"
                      name="checkout"
                      value="0"
                      onChange={this.handleCheckout}
                      checked={!this.state.banner_checkout_page}
                    />
                    <label htmlFor="checkout2">No</label>
                  </div>
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        {this.props.content.type == "add"
                          ? "Add Image"
                          : "Uploaded Image"}
                      </div>
                      <div style={{ margin: "1rem 0" }}>
                        {this.props.content.source === "web"
                          ? "Banner Size should be: 943*384"
                          : "Banner Size should be: 1020*495"}
                      </div>
                      {this.props.content.type == "add" ? (
                        <input
                          type="file"
                          onChange={this.onChangeSpares}
                          accept="image/*"
                          ref={this.myRef}
                        />
                      ) : (
                        <img
                          src={this.state.image_url}
                          height="100"
                          width="100"
                          alt="img"
                        />
                      )}
                      {this.props.content.type !== "add" ? (
                        <>
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Change Banner
                          </div>
                          <input
                            type="file"
                            onChange={this.onChangeSpares}
                            accept="image/*"
                            ref={this.myRef}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <hr style={{ marginTop: "2rem" }} />
                  <div className="border-box floatright rightalign">
                    {this.props.content.type == "add" ? ( this.state.loader_1?
                        <ButtonLoader/>:
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={
                          this.state.bannerLoder ? null : this.saveBannerHandler
                        }
                      >
                        Save
                      </button>
                    ) : (
                      this.state.loader_1?
                        <ButtonLoader/>:
                      <>
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() =>
                            this.editBannerHandler(this.state.banner_id)
                          }
                        >
                          Update
                        </button>
                      </>
                    )}
                  </div>
                </div>
            </div>

            </div>
            </>
          );
        case "categories_spares":
          return this.showCategoryContent();

        case "brands_spares":
          return this.showBrandContent();
        default:
          return <React.Fragment />;
      }
    }
  }
}

class ImageBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.content ? this.props.content : {};
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  // handleButtonClick(type) {}

  handleButtonClick(type) {
    if (type == "delete") {
      this.props.onDelete(this.props.type, this.props.url);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="border-box fieldimage1">
          <img
            src={this.props.url}
            height={this.props.height ? this.props.height : "auto"}
            width={this.props.width ? this.props.width : "auto"}
            style={{ objectFit: "contain" }}
          />
          <button
            class="btn btn_small btncolorprim btncard move btndelete"
            onClick={() => this.handleButtonClick("delete")}
          >
            Delete
          </button>
        </div>
      </React.Fragment>
    );
  }
}
class CompatibilityBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.content ? this.props.content : {};
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleButtonClick(type) {}

  handleChange(type, e) {
    var obj = this.state;
    obj[type] = e;
    let data;
    if (type === "varient")
     {
      data=e.map((varient)=>{
        return(
          {...obj,varient}
        )
      })
    }
    this.props.updateCompatibility(data);
  }

  componentDidUpdate() {}

  componentDidMount() {

  }

  brandWiseModel() {
    let brands = this.state.selectedBrandForModel;
    let models = this.state.brandWiseModels;
    let filterModel = this.state.selectedModel;
    let filterModelArr = [];

    let brand_id = brands.id;
    let slug_id = brands.slug_id;
    let brandModel = models[slug_id];

    if (brand_id && brandModel) {
      if (filterModel.length !== 0) {
        for (let j = 0; j < filterModel.length; j++) {
          if (brand_id == filterModel[j].brand_id)
            filterModelArr.push(filterModel[j]);
        }
      }

      var brandWiseModel = [];
      brandWiseModel = [
        // { id: "select-all", name: "Select All" },
        ...brandModel.model,
      ];
      this.setState({
        modelsForBrand: brandWiseModel,
        selectedModel: filterModelArr,
      });
    } else {
      this.setState({
        selectedModel: [],
      });
    }
    localStorage.setItem(
      "pricing_filters",
      JSON.stringify(this.state.pricingFilter)
    );
  }

  afterFilterChange(field) {
    field = field || null;
    if (field == "brand") {
      // this.brandWiseModel();
      axios
        .get(
          Constants.getSparesBaseURL() +
            "car-models/?car_brand_id=" +
            this.state.selectedBrandForModel._id
        )
        .then((data) => {
          var models = [];
          models = [...data.data.data];
          this.setState({ modelsForBrand: models }, () => {});
        })
        .catch((e) => console.log("error ", e));
    }

    if (field == "model") {
      axios.get(`${Constants.getSparesBaseURL()}vehicles-cms/?varient=0&source=cms&make=${this.state.selectedBrandForModel.name}&model=${this.state.selectedModel.name}`)
      .then((response)=> {
        if (response.status === 204 || response.data.data.length == 0) {
          alert('No Content');
        } else
        this.setState({ varientType: response.data.data }, () => {});
      })
      .catch((error)=> {
        console.log(error)
      })
      .finally(function () {
        // always executed
      });
    }
  }

  render() {
    var id = this.state.id;
    return (
      <React.Fragment>
        <hr></hr>
        {this.state.id}
        <div className="border-box fields">
          <div className="border-box field2">
            <div className="border-box  uppercased fieldtitle weight700 blackspan7">
              Make
            </div>
            <MultiSelect
              options={this.props.brands}
              labelKey="name"
              checkbox_id="_id"
              placeholder="Select Brand"
              disabledItems={[]}
              defaultValue={this.state.make}
              selectedOptions={this.state.selectedBrandForModel}
              onChange={(value, e) => {
                this.setState(
                  {
                    selectedBrandForModel: value,
                  },
                  (e) => {
                    this.afterFilterChange("brand");
                    this.handleChange("make", value.name, this.state.brands);
                  }
                );
              }}
            />
          </div>
          <div className="border-box  field2">
            <div className="border-box  uppercased fieldtitle weight700 blackspan7">
              Model
            </div>
            <MultiSelect
              options={this.state.modelsForBrand}
              labelKey="name"
              checkbox_id="_id"
              placeholder="Select Model"
              disabledItems={[]}
              defaultValue={this.state.model}
              selectedOptions={this.state.selectedModel}
              onChange={(value, e) =>
                this.setState(
                  {
                    selectedModel: value,
                  },
                  (e) => {
                    this.afterFilterChange("model");
                    this.handleChange("model", value.name);
                  }
                )
              }
            />
          </div>
        </div>
        <div className="border-box  fields">
        <div className="border-box  field2">
            <div className="border-box  uppercased fieldtitle weight700 blackspan7">
              Year-Range
            </div>
            <MultiSelect
                        options={this.state.varientType?[...new Map(this.state.varientType.map(item=> [item['year_range'], item])).values()].sort((a,b) => (a.year_range > b.year_range) ? 1 : ((b.year_range > a.year_range) ? -1 : 0)):[]}
                        labelKey="year_range"
                        checkbox_id="year_range"
                        placeholder="year range"
                         selectedOptions={{year_range:this.state.year_range}}
                        defaultValue={this.props.content?this.props.content.year_range:''}
                        onChange={(value) => {
                          this.setState({
                            year_range: value.year_range,
                          }
                          ,
                   (e)=> this.handleChange("year_range", value.year_range)
                          );
                        }}
                      />
          </div>
          <div className="border-box  field2">
            <div className="border-box  uppercased fieldtitle weight700 blackspan7">
              Fuel-Engine
            </div>
            <MultiSelect
              options={this.state.varientType?[...new Map(this.state.varientType.filter(item=>item.year_range===this.state.year_range).map(item=> [item['fuel_engine'], item])).values()].filter(item=>item.fuel_engine):[]}
              labelKey="fuel_engine"
              checkbox_id="_id"
              placeholder="Select FuelType"
              disabledItems={[]}
              defaultValue={this.state.fuel_engine}
              selectedOptions={this.state.selectedFuelType}
              onChange={(value, e) =>
                this.setState(
                  {
                    selectedFuelType: value,
                  },
                  (e) => this.handleChange("fuel_engine", value.fuel_engine)
                )
              }
            />
          </div>
          
          <div className="border-box  field2">
            <div className="border-box  uppercased fieldtitle weight700 blackspan7">
              Variant
            </div>
            <MultiSelect
              options={this.state.selectedFuelType ?this.state.varientType.filter(item=>item.year_range===this.state.year_range).filter(item=>item.fuel_engine === this.state.selectedFuelType.fuel_engine):''}
              labelKey="varient"
              checkbox_id="varient"
              placeholder="Select Varient"
              disabledItems={[]}
              defaultValue={this.props.content?this.props.content.varient:''}
              multiple selectedOptions={this.state.selectedVarientType?this.state.selectedVarientType:[]}
              onChange={(value, e) =>{
                this.setState(
                  {selectedVarientType: value},
                  (e) => {
                    this.handleChange("varient", value.map(item=>item.varient));
                  }
                )}
              }
            />
          </div>
        </div>
        <div className="border-box clearfix footer">
          {this.state.status == "deleted" ? (
            <span>Deleted</span>
          ) : (
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() =>
                  this.props.deleteCompatibility(
                    this.state.compatibility_group_id,
                    this.state
                  )
                }
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <hr></hr>
      </React.Fragment>
    );
  }
}

export default MiddleExtra;
