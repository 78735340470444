import React from 'react';
import './OverlayLoader.css';

const OverlayLoader = (props) => {

  return (
    <>
      <div className="overlay" />
      <div className="spinner-container">
        <div className="overlay-spinner" />
      </div>
    </>
  );

};

export default OverlayLoader;