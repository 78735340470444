import React from "react";
// import "./dashboard/dashboard.css"
import Row from "./row";

class MiddleMain extends React.Component {
  constructor(props) {
    super(props);
    //    this.state = {};
  }

  render() {
    var that = this;
    // console.log(that.props.tag);
    // console.log("props in spares colums ", that.props)
    const rows = this.props.content.map((obj) => (
      <Row
        data={obj}
        selectedRow={this.props.selectedRow}
        tag={that.props.tag}
        onRowClick={this.props.onRowClick}
      />
    ));

    return (
      <div className="main border-box leftalign">
        {!this.props.showContent ? (
          <React.Fragment />
        ) : this.props.content.length == 0 ? (
          <div className="empty centeralign weight400 blackspan56">
            No fleets.
          </div>
        ) : (
          rows
        )}
      </div>
    );
  }
}

export default MiddleMain;
