import React from 'react'
import './ButtonLoader.css'

const ButtonLoader = (props) => {
  //use whiteloaderBtn class for white button and pass whiteColor=true
  return (
    <button className={props.whiteColor?'whiteloaderBtn':'loaderBtn'} style={{width:props.width,height:props.height}}>
      <div className="flex">
        <div className="mainDots" data-selected={props.whiteColor}></div>
        <div className="mainDots2"  data-selected={props.whiteColor}></div>
        <div className="mainDots3" data-selected={props.whiteColor}></div>
      </div>
  </button>
  )
}

export default ButtonLoader