import React from 'react';
import Topbar from './topbar';
// import Dashboard from './content_website/dashboard';
import {Helmet} from "react-helmet";
import HttpsRedirect from 'react-https-redirect';
import Dashboard from './insurance/dashboard';

function AppInsurance() {

  return (
    <React.Fragment>
    <Helmet>
      <title>GoMechanic Dashboard</title>
    </Helmet>
    <div id="containermain1">
    <Topbar />

    <Dashboard />
    </div>
    </React.Fragment>
  );
}

export default AppInsurance;
