import React from "react";
import "../dashboard/dashboard.css";
import $ from "jquery";

import PanelLeft from './leftpanel';
import PanelRight from './rightpanel';
import Row from './row';
import FilterBox from './filterbox';
import MiddleUpper from './middleupper';
import MiddleMain from './middlemain';
import MiddleExtra from './middleextra';
import Loader from '../loader'

import API from '../api';
import Constants from '../constants';
import {Redirect} from 'react-router-dom';
import MultiSelect from '../broadcast/MultiSelect/MultiSelect'

const axios = require('axios');

// const tabList = [{tag:"content_website" , title : "Website content"}, {tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];

class PanelMiddle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCountry : null,
			country : [
				{
				"id":1,
				"name":"India"
				},
				{
				"id":2,
				"name":"Malaysia"
				},
			]
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.props.onTabClick(this.props.tag);
	}

  	componentWillMount() {
		if(localStorage.getItem("nearme_country") != undefined){  
		this.state.selectedCountry = JSON.parse(localStorage.getItem("nearme_country"));
		}
	}


  render() {
	console.log(this.props.tag);
	if(this.props.showLoader){
		// if(true){
		return (
			<div id="dashboardmainmiddleloader" className="bgwhite centeralign">
				<Loader showLoader = {this.props.showLoader}/>
			</div>
		);
	}else{
		// console.log("extra1", this.props.middleExtraContent);
		if(this.props.extraContent){
			console.log("extra", this.props.extraContent);
			return (
				<div id="dashboardmainmiddle" className="bgwhite leftalign">
				<div id="dashboardprime" className="leftalign">
					<MiddleExtra content={this.props.extraContent} onMiddleExtraButtonClick={this.props.onMiddleExtraButtonClick} selectedRow={this.props.selectedRow}/>
					<FilterBox tag={this.props.tag} filterShowing={this.props.filterShowing} onFilterClick={this.props.onFilterClick} onSearchClick={this.props.onSearchClick} filterArgs={this.props.filterArgs} />
					<MiddleUpper tag={this.props.tag} onFilterClick={this.props.onFilterClick} onAddClick={this.props.onAddClick} />
					{this.props.showContent ? (<MiddleMain tag={this.props.tag} selectedRow={this.props.selectedRow} content={this.props.content} showContent={this.props.showContent} onRowClick={this.props.onRowClick} />) :
					(<React.Fragment />) }	
				</div>
				</div>
			);
		}else{
			return (
				<div id="dashboardmainmiddle" className="bgwhite leftalign">
				<div id="dashboardprime" className="leftalign">
					<FilterBox tag={this.props.tag} filterShowing={this.props.filterShowing} onFilterClick={this.props.onFilterClick} onSearchClick={this.props.onSearchClick} filterArgs={this.props.filterArgs} />
					
					
					<MiddleUpper tag={this.props.tag} onFilterClick={this.props.onFilterClick} onAddClick={this.props.onAddClick} />
					
					<div className="country-nm">
						<div className="border-box" style={{padding: "8px"}}>
							<div className="blackspan65 floatleft">
								<MultiSelect
									options={this.state.country}
									labelKey="name"
									checkbox_id="name"
									placeholder="Country"
									disabledItems={[]}
									selectedOptions={this.state.selectedCountry}
									onChange={(e) => {this.setState({selectedCountry: e}, () => {localStorage.setItem("nearme_country", JSON.stringify(this.state.selectedCountry))})}}
								/>
							</div>
							<div className="blackspan65 weight700 floatright">
								<button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleClick} onClick={this.handleClick}>Apply Filter</button>
							</div>
						</div>
                     </div>
					{this.props.showContent ? (<MiddleMain tag={this.props.tag} selectedRow={this.props.selectedRow} content={this.props.content} showContent={this.props.showContent} onRowClick={this.props.onRowClick} />) :
					(<React.Fragment />) }
					
				</div>
				</div>
			);
		}
	}
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "logged in",
      rightContent: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent: false,
      middleExtraContent: NaN,
      selectedTab: "page_path_mapping",
      selectedRow: "",
      filter_showing: false,
      filter_args: undefined,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleRightButtonClick = this.handleRightButtonClick.bind(this);
    this.handleMiddleExtraButtonClick =
      this.handleMiddleExtraButtonClick.bind(this);
  }

  handleFilterClick() {
    this.setState({ filter_showing: !this.state.filter_showing });
  }

  handleAddClick(tag) {
    this.setState({
      middleExtraContent: { type: "add", tag: tag, row: null, content: null },
    });
  }

  handleSearchClick(args) {
    // console.log("searching");
    // console.log(args);
    this.setState({
      filter_args: args,
      filter_showing: !this.state.filter_showing,
    });
    API.tabSearchCall(this.state.selectedTab, this, args);
    //this.setState({filter_showing : !this.state.filter_showing});
  }

  handleRowClick(data, tag) {
    // console.log(data);
    // console.log(tag);
    if (tag === "near_me_acc")
      this.setState({
        selectedRow: data._id,
        showRightContent: true,
        rightContent: data,
      });
    else
      this.setState({
        selectedRow: data.uuid,
        showRightContent: true,
        rightContent: data,
      });
  }

  handleRightButtonClick(type, tag, row, content) {
    // console.log(type, tag, row, content);
    this.setState({
      middleExtraContent: { type: type, tag: tag, row: row, content: content },
    });
  }

  handleMiddleExtraButtonClick(type, content, row) {
	// console.log(type, tag, row, content);
		if (type == "save"){
			// var middleContent = this.state.middleContent;
			// let obj = middleContent.find(o => o.id === row);
			// if(obj != null){
			// 	var indexObj = middleContent.indexOf(obj);
				
				
			// 	middleContent[indexObj] = content;
			// }
			
			// this.setState({middleExtraContent: NaN, middleContent : middleContent, rightContent: content});

			this.setState({showMiddleContent: false, showRightContent : false, rightContent: NaN, middleExtraContent: NaN, showLoader: true});

			API.tabClickCallContentWebsite(this.state.selectedTab, this);
		}else{
			this.setState({middleExtraContent: NaN});
		}
  }

  handleTabClick(tag) {
  	var that = this;

  	that.setState({selectedTab : tag, showMiddleContent: false, showRightContent : false, rightContent: NaN, showLoader: true});
		API.tabClickCallContentWebsite(tag, that);
  }

  componentWillMount() {
	  console.log("access_token");
	  console.log(localStorage.getItem("access_token"));
	  console.log((new Date(localStorage.getItem("token_expiration_date"))));
	  console.log(new Date());
	if(localStorage.getItem("access_token") === null){
		this.setState({status : 'not logged in'});
	}else{
		var that = this;
		that.setState({"showLoader" : true});
		if(localStorage.getItem("statuses") === null || localStorage.getItem("fleets_summary") === null || localStorage.getItem("garages_summary") === null){
			API.getStatuses({headers: {'Authorization': "Bearer " + localStorage.getItem("access_token")}}).then(function (response) {
				console.log(response.data);
				Constants.setStatuses(response.data.data);
				API.get_fleets_summary1({headers: {'Authorization': "Bearer " + localStorage.getItem("access_token")}}).then(function (response) {
					console.log(response.data);
					Constants.setFleetsSummary(response.data.data);
					API.get_garages_summary1({headers: {'Authorization': "Bearer " + localStorage.getItem("access_token")}}).then(function (response) {
						console.log(response.data);
						Constants.setGaragesSummary(response.data.data);
						API.tabClickCallContentWebsite(that.state.selectedTab, that);
						that.setState({"showLoader" : false});
						})
						.catch(function (error) {
						// handle error
						console.log(error);
						})
						.finally(function () {
						// always executed
						});
					})
					.catch(function (error) {
					// handle error
					console.log(error);
					})
					.finally(function () {
					// always executed
					});
				})
				.catch(function (error) {
				// handle error
				console.log(error);
				})
				.finally(function () {
				// always executed
				});
		}else{
			that.setState({"showLoader" : true});
			API.tabClickCallContentWebsite(that.state.selectedTab, that);
		}
	}
	
}

  componentDidMount() {

  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
	  if(this.state.status === "not logged in" || new Date(localStorage.getItem("token_expiration_date")) <= new Date()){
		localStorage.removeItem("access_token");
		localStorage.removeItem("token_expiration_date");
		localStorage.removeItem("user_id");
		localStorage.removeItem("phone");
		localStorage.removeItem("custom_token");
		localStorage.removeItem("user_roles");
		return <Redirect to="/login"/>
	  }else if(this.state.selectedTab === 'home') {
		return <Redirect to="/"/>
	  }else if(this.state.selectedTab === 'support_chat') {
		return <Redirect to="/chat"/>
	  }else {
		// return <Redirect to="/dashboard"/>
		return (
			<div id="containermainouter1">
				<PanelRight content={this.state.rightContent} showContent={this.state.showRightContent} tag={this.state.selectedTab} selectedRow={this.state.selectedRow} onRightButtonClick={this.handleRightButtonClick}/>
				<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab}/>
				<PanelMiddle tag={this.state.selectedTab} filterShowing={this.state.filter_showing} selectedRow={this.state.selectedRow} filterArgs={this.state.filter_args} showLoader={this.state.showLoader} 
					extraContent={this.state.middleExtraContent} onMiddleExtraButtonClick={this.handleMiddleExtraButtonClick} onAddClick={this.handleAddClick}
					content={this.state.middleContent} showContent={this.state.showMiddleContent} onRowClick={this.handleRowClick} onFilterClick={this.handleFilterClick} onSearchClick={this.handleSearchClick} onTabClick={this.handleTabClick}/>
			</div>
		);
	  }
  }
}

export default Dashboard;
