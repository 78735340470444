import React from 'react'
import '../dashboard/dashboard.css'
import PanelLeft from './leftpanel'
import PanelRight from './rightpanel'
import MiddleUpper from './middleupper'
import MiddleMain from './middlemain'
import MiddleExtra from './middleextra'
import Loader from '../loader'
import API from '../api'
import { Redirect } from 'react-router-dom'
import './accessories.css'

class PanelMiddle extends React.Component {
  constructor(props) {
    super(props)
    this.state = props
  }

  render() {
    if (this.props.showLoader) {
      return (
        <div id='dashboardmainmiddleloader' className='bgwhite centeralign'>
          <Loader showLoader={this.props.showLoader} />
        </div>
      )
    } else {
      if (this.props.extraContent) {
        return (
          <div id='dashboardmainmiddle' className='bgwhite leftalign'>
            <div id='dashboardprime' className='leftalign'>
              <MiddleExtra
                content={this.props.extraContent}
                onMiddleExtraButtonClick={this.props.onMiddleExtraButtonClick}
                extraData={this.props.extraData}
                selectedRow={this.props.selectedRow}
                showLoader={this.props.showContent}
              />
              <MiddleUpper
                tag={this.props.tag}
                onAddClick={this.props.onAddClick}
                onSearchClick={this.props.onSearchClick}
                filterFunc={this.props.filterSegment}
                noContent={this.props.content ? !this.props.content.length : null}
              />
              {this.props.showContent ? (
                <MiddleMain
                  tag={this.props.tag}
                  selectedRow={this.props.selectedRow}
                  content={this.props.content}
                  showContent={this.props.showContent}
                  onRowClick={this.props.onRowClick}
                />
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
        )
      } else {
        return (
          <div id='dashboardmainmiddle' className='bgwhite leftalign'>
            <div id='dashboardprime' className='leftalign'>
              <MiddleUpper
                tag={this.props.tag}
                onAddClick={this.props.onAddClick}
                onSearchClick={this.props.onSearchClick}
                filterFunc={this.props.filterSegment}
                noContent={this.props.content ? !this.props.content.length : null}
              />
              {this.props.showContent ? (
                <MiddleMain
                  tag={this.props.tag}
                  selectedRow={this.props.selectedRow}
                  content={this.props.content}
                  showContent={this.props.showContent}
                  onRowClick={this.props.onRowClick}
                />
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
        )
      }
    }
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'logged in',
      rightContent: NaN,
      middleContent: NaN,
      showMiddleContent: false,
      showRightContent: false,
      middleExtraContent: NaN,
      selectedTab: 'user',
      selectedRow: '',
    }

    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleRightButtonClick = this.handleRightButtonClick.bind(this)
    this.handleMiddleExtraButtonClick = this.handleMiddleExtraButtonClick.bind(
      this
    )
    this.filterSegment = this.filterSegment.bind(this)
  }

  handleAddClick(tag) {
    this.setState({
      middleExtraContent: { type: 'add', tag: tag, row: null, content: null },
    })
  }

  handleSearchClick(text) {
    API.tabSearch2Call(this.state.selectedTab, this, text, true)
  }

  handleRowClick(data, tag) {
    if (tag === 'products') {
      this.setState({
        selectedRow: data.product_id,
        showRightContent: true,
        rightContent: data,
      })
    } else if (tag === 'collection') {
      this.setState({
        selectedRow: data.slug,
        showRightContent: true,
        rightContent: data,
      })
    } else if (tag === 'coupons') {
      this.setState({
        selectedRow: data.id,
        showRightContent: true,
        rightContent: data,
      })
    } else {
      this.setState({
        selectedRow: data._id,
        showRightContent: true,
        rightContent: data,
      })
    }
  }

  handleRightButtonClick(type, tag, row, content) {
    if (tag === 'brands') {
      API.getAccProductsBrandDetails(content.slug, this, type, tag, row, true)
    } else {
      this.setState({
        middleExtraContent: { type: type, tag: tag, row: row, content: content },
      })
    }
  }

  handleMiddleExtraButtonClick(type, content, row) {
    if (type === 'save') {
      this.setState({
        showMiddleContent: false,
        showRightContent: false,
        rightContent: NaN,
        middleExtraContent: NaN,
      })

      API.tabClickCallAccessories(this.state.selectedTab, this, true)
    } else if (type === 'close') {
      this.setState({
        showMiddleContent: false,
        showRightContent: false,
        rightContent: NaN,
        middleExtraContent: NaN,
      })
    }  else {
      this.setState({ middleExtraContent: NaN })
    }
  }

  handleTabClick(tag) {
    var that = this
    that.setState({
      selectedTab: tag,
      showMiddleContent: false,
      showRightContent: false,
      rightContent: NaN,
			middleExtraContent: NaN
    })
    that.setState({ showLoader: true })
    API.tabClickCallAccessories(tag, that, true);
  }

  componentWillMount() {
    if (localStorage.getItem('access_token') === null) {
      this.setState({ status: 'not logged in' })
    } else {
      var that = this
      that.setState({ showLoader: true })
      API.tabClickCallAccessories(that.state.selectedTab, that, true)
    }
  }

  filterSegment(searchKey, type) {
    if (searchKey) {
      API.searchOrFilterAccessoriesCategory(type, searchKey, true)
        .then((response) => {
          this.setState({ middleContent: response.data.data || [], showContent: true })
        })
        .catch((err) => console.error(err))
    }
  }

  render() {
    if (
      this.state.status === 'not logged in' ||
      new Date(localStorage.getItem('token_expiration_date')) <= new Date()
    ) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('token_expiration_date')
      localStorage.removeItem('user_id')
      localStorage.removeItem('phone')
      localStorage.removeItem('custom_token')
      localStorage.removeItem('user_roles')
      return <Redirect to='/login' />
    } else if (this.state.selectedTab === 'home') {
      return <Redirect to='/' />
    } else if (this.state.selectedTab === 'support_chat') {
      return <Redirect to='/chat' />
    } else {
      return (
        <div id='containermainouter1'>
          <PanelRight
            content={this.state.rightContent}
            showContent={this.state.showRightContent}
            tag={this.state.selectedTab}
            selectedRow={this.state.selectedRow}
            onRightButtonClick={this.handleRightButtonClick}
          />
          <PanelLeft
            onTabClick={this.handleTabClick}
            selectedTab={this.state.selectedTab}
          />
          <PanelMiddle
            tag={this.state.selectedTab}
            selectedRow={this.state.selectedRow}
            showLoader={this.state.showLoader}
            extraContent={this.state.middleExtraContent}
            onMiddleExtraButtonClick={this.handleMiddleExtraButtonClick}
            onAddClick={this.handleAddClick}
            content={this.state.middleContent}
            showContent={this.state.showMiddleContent}
            onRowClick={this.handleRowClick}
            onSearchClick={this.handleSearchClick}
            filterSegment={this.filterSegment}
            extraData={this.state.extraData}
          />
        </div>
      )
    }
  }
}

export default Dashboard
