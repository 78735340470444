import React from "react";
import "./dashboard/dashboard.css";
import $ from "jquery";

import PanelLeft from "./leftpanel";
import PanelRight from "./rightpanel";
import Row from "./row";
import FilterBox from "./filterbox";
import MiddleUpper from "./middleupper";
import MiddleMain from "./middlemain";
import Loader from "./loader";

import API from "./api";
import Constants from "./constants";
import { Redirect } from "react-router-dom";
import AppBroadCast from "./AppBroadCast";

const axios = require("axios");

// const tabList = [{tag:"content_website" , title : "Website content"}, {tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];

class PanelMiddle extends React.Component {
    constructor(props) {
        super(props);
        //    this.state = {};
    }

    render() {
        if (this.props.showLoader) {
            // if(true){
            return (
                <div
                    id="dashboardmainmiddleloader"
                    className="bgwhite centeralign"
                >
                    <Loader showLoader={this.props.showLoader} />
                </div>
            );
        } else {
            return (
                <div id="dashboardmainmiddle" className="bgwhite leftalign">
                    <div id="dashboardprime" className="leftalign">
                        <FilterBox
                            tag={this.props.tag}
                            filterShowing={this.props.filterShowing}
                            onFilterClick={this.props.onFilterClick}
                            onSearchClick={this.props.onSearchClick}
                            filterArgs={this.props.filterArgs}
                        />
                        <MiddleUpper
                            tag={this.props.tag}
                            onFilterClick={this.props.onFilterClick}
                        />
                        {this.props.showContent ? (
                            <MiddleMain
                                tag={this.props.tag}
                                selectedRow={this.props.selectedRow}
                                content={this.props.content}
                                showContent={this.props.showContent}
                                onRowClick={this.props.onRowClick}
                            />
                        ) : (
                            <React.Fragment />
                        )}
                    </div>
                </div>
            );
        }
    }
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "logged in",
            rightContent: NaN,
            middleContent: NaN,
            showMiddleContent: false,
            showRightContent: false,
            selectedTab: "orders",
            selectedRow: "",
            filter_showing: false,
            filter_args: undefined,
        };

        this.handleTabClick = this.handleTabClick.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
    }

    handleFilterClick() {
        this.setState({ filter_showing: !this.state.filter_showing });
    }

    handleSearchClick(args) {
        this.setState({
            filter_args: args,
            filter_showing: !this.state.filter_showing,
        });
        API.tabSearchCall(this.state.selectedTab, this, args);
        //this.setState({filter_showing : !this.state.filter_showing});
    }

    handleRowClick(data, tag) {
        this.setState({
            selectedRow: data.order_id,
            showRightContent: true,
            rightContent: data,
        });
    }

    handleTabClick(tag) {
        var that = this;

        that.setState({ selectedTab: tag, showMiddleContent: false });

        API.tabClickCall(tag, that);
    }

    componentWillMount() {
        if (localStorage.getItem("access_token") === null) {
            this.setState({ status: "not logged in" });
        } else {
            var that = this;
            that.setState({ showLoader: true });
            if (
                localStorage.getItem("statuses") === null ||
                localStorage.getItem("fleets_summary") === null ||
                localStorage.getItem("garages_summary") === null
            ) {
                API.getStatuses({
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("access_token"),
                    },
                })
                    .then(function (response) {
                        Constants.setStatuses(response.data.data);
                        API.get_fleets_summary1({
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    localStorage.getItem("access_token"),
                            },
                        })
                            .then(function (response) {
                                Constants.setFleetsSummary(response.data.data);
                                API.get_garages_summary1({
                                    headers: {
                                        Authorization:
                                            "Bearer " +
                                            localStorage.getItem(
                                                "access_token"
                                            ),
                                    },
                                })
                                    .then(function (response) {
                                        Constants.setGaragesSummary(
                                            response.data.data
                                        );
                                        API.tabClickCall(
                                            that.state.selectedTab,
                                            that
                                        );
                                        that.setState({ showLoader: false });
                                    })
                                    .catch(function (error) {
                                        // handle error
                                        // console.log(error);
                                    })
                                    .finally(function () {
                                        // always executed
                                    });
                            })
                            .catch(function (error) {
                                // handle error
                                // console.log(error);
                            })
                            .finally(function () {
                                // always executed
                            });
                    })
                    .catch(function (error) {
                        // handle error
                        // console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            } else {
                API.tabClickCall(that.state.selectedTab, that);
                that.setState({ showLoader: false });
            }
        }
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        if (
            this.state.status === "not logged in" ||
            new Date(localStorage.getItem("token_expiration_date")) <=
                new Date()
        ) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("token_expiration_date");
            localStorage.removeItem("user_id");
            localStorage.removeItem("phone");
            localStorage.removeItem("custom_token");
            localStorage.removeItem("user_roles");
            return <Redirect to="/login" />;
        } else if (this.state.selectedTab === "home") {
            return <Redirect to="/" />;
        } else if (this.state.selectedTab === "support_chat") {
            return <Redirect to="/chat" />;
        } else if (this.state.selectedTab === "content_website") {
            return <Redirect to="/content/website" />;
        } else if (this.state.selectedTab === "accessories") {
            return <Redirect to="/accessories" />;
        } else if (this.state.selectedTab === "spares") {
            return <Redirect to="/spares" />;
        } 
       else if (this.state.selectedTab === "broadcast") {
            return <Redirect to="/broadcast" />;
        } else if (this.state.selectedTab === "accessoriesb2b") {
            return <Redirect to="/accessoriesb2b" />;
        }
        // else if (this.state.selectedTab === 'incentives') {
        // 	return (
        // 		// <Redirect to="/incentives" />

        // 		<div id="containermainouter1">
        // 			<PanelLeft onTabClick={this.handleTabClick} selectedTab={this.state.selectedTab} />
        // 			<AppIncentives />
        // 		</div>
        // 	);
        // }
        else {
            // return <Redirect to="/dashboard"/>
            return (
                <div id="containermainouter1">
                    <PanelRight
                        content={this.state.rightContent}
                        showContent={this.state.showRightContent}
                        tag={this.state.selectedTab}
                    />
                    <PanelLeft
                        onTabClick={this.handleTabClick}
                        selectedTab={this.state.selectedTab}
                    />
                    <PanelMiddle
                        tag={this.state.selectedTab}
                        filterShowing={this.state.filter_showing}
                        selectedRow={this.state.selectedRow}
                        filterArgs={this.state.filter_args}
                        showLoader={this.state.showLoader}
                        content={this.state.middleContent}
                        showContent={this.state.showMiddleContent}
                        onRowClick={this.handleRowClick}
                        onFilterClick={this.handleFilterClick}
                        onSearchClick={this.handleSearchClick}
                    />
                </div>
            );
        }
    }
}

export default Dashboard;
