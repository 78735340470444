import React, { useState, useEffect, useRef } from 'react'

import MultiSelect from '../broadcast/MultiSelect/MultiSelect'
import VariantItem from './variantItem'
import { NotificationManager } from 'react-notifications'
import API from '../api'
import OverlayLoader from '../common/OverlayLoader'

const AddProduct = (props) => {
  const { brandList, categoriesList, handleButtonClick } = props

  const [variantCount, setVariantCount] = useState(1)
  const [showLoader, setShowLoader] = useState(false)
  const [productObj, setProductObj] = useState({
    description: {},
    warranty_details: {},
    features: {},
    variants: [],
    is_star_product: 0,
    is_hero_product: 0,
    is_trending: 0,
    car_dependent: 0,
    is_bundle_item: 0,
    frequently_bought_together: [],
    suggestedProducts: [],
    faq: [],
    faqCount: 1,
    reviews: [],
    reviewCount: 1,
    tempProductId: '',
    video: [],
    videoCount: 0,
  })
  const [attributes, setAttributes] = useState([]);
  const [moreCarCount, setMoreCarCount] = useState(0);
  const videoThumbnail = useRef();
  const productMandatoryFields = [
    {
      key: 'title',
      type: 'string',
      object_key: null,
      err_msg: 'Product Title is required',
    },
    {
      key: 'page_title',
      type: 'string',
      object_key: null,
      err_msg: 'Page Title is required',
    },
    {
      key: 'display_name',
      type: 'string',
      object_key: null,
      err_msg: 'Product Display Name is required',
    },
    {
      key: 'year',
      type: 'string',
      object_key: null,
      err_msg: 'Product Year is required',
    },
    {
      key: 'brand_name',
      type: 'string',
      object_key: null,
      err_msg: 'Brand Name is required',
    },
    {
      key: 'category_name',
      type: 'string',
      object_key: null,
      err_msg: 'Category Name is required',
    },
    {
      key: 'meta_description',
      type: 'string',
      object_key: null,
      err_msg: 'Meta Description is required',
    },
    {
      key: 'slug',
      type: 'string',
      object_key: null,
      err_msg: 'Slug is required',
    },
    {
      key: 'details',
      type: 'string',
      object_key: 'description',
      err_msg: 'Description is required',
    },
    {
      key: 'text',
      type: 'string',
      object_key: 'features',
      err_msg: 'Features are is required',
    },
    {
      key: 'variants',
      type: 'array',
      object_key: null,
      err_msg: 'At leasr 1 Variant is required',
    },
    {
      key: 'color',
      type: 'array',
      object_key: null,
      err_msg: 'Color is required',
    },
    {
      key: 'tags',
      type: 'array',
      object_key: null,
      err_msg: 'Tags are required',
    },
    {
      key: 'popularity',
      type: 'string',
      object_key: null,
      err_msg: 'Popularity is required',
    },
    {
      key: 'gst_rate',
      type: 'string',
      object_key: null,
      err_msg: 'Gst rate is required',
    },
    {
      key: 'is_trending',
      type: 'bool',
      object_key: null,
      err_msg: 'Trending field is required',
    },
    {
      key: 'is_star_product',
      type: 'bool',
      object_key: null,
      err_msg: 'Star Product field is required',
    },
    {
      key: 'is_hero_product',
      type: 'bool',
      object_key: null,
      err_msg: 'Hero Product field is required',
    },
    {
      key: 'car_dependent',
      type: 'bool',
      object_key: null,
      err_msg: 'Car Dependent field is required',
    },
  ]
  const variantMandatoryFields = [
    {
      key: 'sku_code',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} sku sode is required',
    },
    {
      key: 'name',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} name is required',
    },
    {
      key: 'upc_code',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} upc code is required',
    },
    {
      key: 'mrp',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} mrp is required',
    },
    {
      key: 'price',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} price is required',
    },
    {
      key: 'color',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} color is required',
    },
    {
      key: 'unicommerce_skucode',
      type: 'string',
      object_key: null,
      err_msg: 'Variant {{$index}} unicommerce skucode is required',
    },
    {
      key: 'is_google_yes',
      type: 'bool',
      object_key: null,
      err_msg: 'Variant {{$index}} is google yes field is required',
    },
    {
      key: 'xml_inclusion',
      type: 'bool',
      object_key: null,
      err_msg: 'Variant {{$index}} xml inclusion field is required',
    },
    {
      key: 'is_local_inventory',
      type: 'bool',
      object_key: null,
      err_msg: 'Variant {{$index}} local inventry field is required',
    },
    {
      key: 'availability_status',
      type: 'bool',
      object_key: null,
      err_msg: 'Variant {{$index}} availability status is required',
    },
  ]
  const variantCarMandatoryFields = [
    {
      key: 'car',
      type: 'object',
      object_key: null,
      err_msg: 'Variant {{$index}} car fields are required',
    },
    {
      key: 'model_id',
      type: 'string',
      object_key: 'car',
      err_msg: 'Variant {{$index}} car model id is required',
    },
    {
      key: 'model',
      type: 'string',
      object_key: 'car',
      err_msg: 'Variant {{$index}} car model name is required',
    },
    {
      key: 'brand',
      type: 'string',
      object_key: 'car',
      err_msg: 'Variant {{$index}} car brand name is required',
    },
    {
      key: 'start_year',
      type: 'string',
      object_key: 'car',
      err_msg: 'Variant {{$index}} car start year is required',
    },
    {
      key: 'end_year',
      type: 'string',
      object_key: 'car',
      err_msg: 'Variant {{$index}}  car end year is required',
    },
  ]

  const validateFieldsValue = (value, type) => {
    switch (type) {
      case 'string':
        if (!value || !value.trim()) return false
        else return true
      case 'bool':
        if (typeof value === 'undefined') return false
        else return true
      case 'array':
        if (!value || !value.length) return false
        else return true
      case 'object':
        if (!value || !Object.keys(value).length) return false
        else return true
      default:
        return false
    }
  };
  const checkMandatoryField = () => {
    for (let i = 0; i < productMandatoryFields.length; i += 1) {
      const item = productMandatoryFields[i]
      const checkFlag = item.object_key
        ? validateFieldsValue(productObj[item.object_key][item.key], item.type)
        : validateFieldsValue(productObj[item.key], item.type)
      if (!checkFlag) {
        return {
          flag: false,
          msg: item.err_msg,
        }
      }
    }
    for (let i = 0; i < productObj.variants.length; i += 1) {
      for (let j = 0; j < variantMandatoryFields.length; j += 1) {
        const item = variantMandatoryFields[j]
        const checkFlag = item.object_key
          ? validateFieldsValue(
              productObj.variants[i][item.object_key][item.key],
              item.type
            )
          : validateFieldsValue(productObj.variants[i][item.key], item.type)
        if (!checkFlag) {
          return {
            flag: false,
            msg: item.err_msg.replace(/\{\{\$index\}\}/gi, i + 1),
          }
        }
      }
      if (
        Number(productObj.variants[i].price) >
        Number(productObj.variants[i].mrp)
      ) {
        return {
          flag: false,
          msg: `In Variant ${i + 1} Price cant be greater than MRP!`,
        }
      }
      // if (productObj.car_dependent) {
      //   for (let j = 0; j < variantCarMandatoryFields.length; j += 1) {
      //     const item = variantCarMandatoryFields[j]
      //     const checkFlag = item.object_key
      //       ? validateFieldsValue(
      //           productObj.variants[i][item.object_key][item.key],
      //           item.type
      //         )
      //       : validateFieldsValue(productObj.variants[i][item.key], item.type)
      //     if (!checkFlag) {
      //       return {
      //         flag: false,
      //         msg: item.err_msg.replace(/\{\{\$index\}\}/gi, i + 1),
      //       }
      //     }
      //   }
      // }
    }
    return {
      flag: true,
      msg: ``,
    }
  };
  const handleProductFieldChange = (
    fieldName,
    e,
    fieldLevel,
    fieldType = null,
    fieldObjName = null,
    index
  ) => {
    let temp = JSON.parse(JSON.stringify(productObj))
    if (fieldLevel === 'variant') {
      if (!temp.variants[index]) {
        temp.variants[index] = {}
      }
      if (fieldType && fieldType === 'object') {
        if (!temp.variants[index][fieldObjName]) {
          temp.variants[index][fieldObjName] = {}
        }
        temp.variants[index][fieldObjName][fieldName] = e.target.value
      } else {
        temp.variants[index][fieldName] = e.target.value
      }
    } else {
      if (fieldType && fieldType === 'object') {
        if (!temp[fieldObjName]) {
          temp[fieldObjName] = {}
        }
        temp[fieldObjName][fieldName] = e.target.value
      } else {
        temp[fieldName] = e.target.value
      }
    }
    setProductObj({ ...temp })
  };
  const handleVariantChange = (
    fieldName,
    data,
    fieldType = null,
    fieldObjName = null,
    index,
    fieldIndex,
  ) => {
    let temp = JSON.parse(JSON.stringify(productObj))
    if (!temp.variants[index]) {
      temp.variants[index] = {}
    }
    if (fieldType && fieldType === 'object') {
      if (!temp.variants[index][fieldObjName]) {
        temp.variants[index][fieldObjName] = {}
      }
      temp.variants[index][fieldObjName][fieldName] = data
    } else if (fieldType && fieldType === 'array') {
      if (!temp.variants[index][fieldObjName]) {
        temp.variants[index][fieldObjName] = [];
        temp.variants[index][fieldObjName][fieldIndex] = {};
      }
      if (!temp.variants[index][fieldObjName][fieldIndex]) {
        temp.variants[index][fieldObjName][fieldIndex] = {};
      }
      temp.variants[index][fieldObjName][fieldIndex][fieldName] = data
    } else {
      temp.variants[index][fieldName] = data
    }
    if(!fieldType || fieldType !== 'array')
    temp.variants[index][`${fieldName}_text`] = ''
    setProductObj({ ...temp })
  };
  const handleArraykeyChange = (fieldName, keyName, index, e) => {
    const tempArr = productObj[fieldName] ? JSON.parse(JSON.stringify(productObj[fieldName])) : [];
    if (!tempArr[index]) tempArr[index] = {};
    tempArr[index][keyName] = e.target.value;
    setProductObj({
      ...productObj,
      [fieldName]: tempArr,
    });
  };
  const handleProductInputEnter = (e) => {
    let x = [];
    let y = [];
    if (productObj.frequently_bought_together && productObj.frequently_bought_together > 0) {
      x = [...productObj.frequently_bought_together];
    }
    if (
      productObj.suggestedProducts &&
      productObj.suggestedProducts.length > 0
    ) {
      y = [...productObj.suggestedProducts];
    }

    API.searchAccessoriesProducts(e.target.value.trim())
      .then((response) => {
        if (response.data.data && response.data.data.length) {
          x = [
            ...x,
            ...response.data.data.map(item => item.product_id),
          ];
          y = [
            ...y,
            ...response.data.data,
          ];
        } else if (response.data.data.product_id) {
          x.push(response.data.data.product_id);
          y.push(response.data.data);
        }
        setProductObj({
          ...productObj,
          frequently_bought_together: x,
          tempProductId: null,
          suggestedProducts: y 
        });
      })
      .catch((err) => console.error(err));
  };
  const handleVariantCarChange = (car, Vindex, isMoreCar, carIndex) => {
    let temp = JSON.parse(JSON.stringify(productObj));
    if (!temp.variants[Vindex]) {
      temp.variants[Vindex] = {}
    }
    if (isMoreCar) {
      if (!temp.variants[Vindex].more_cars) {
        temp.variants[Vindex].more_cars = [];
        temp.variants[Vindex].more_cars[carIndex] = {};
      }
      if (!temp.variants[Vindex].more_cars[carIndex]) {
        temp.variants[Vindex].more_cars[carIndex] = {};
      }
      temp.variants[Vindex].more_cars[carIndex] = car;
    }
    else {
      if (!temp.variants[Vindex].car) {
        temp.variants[Vindex].car = {};
      }
      temp.variants[Vindex].car = car;
    }
    setProductObj({ ...temp });
  }
  const uploadImageOnServer = (fieldName, keyName, file, index) => {
    if (!file) {
      NotificationManager.error(
        `Please select an image first`,
        'Error!!',
        5000
      );
      return;
    }
    // if (parseFloat(file.size / 1024).toFixed(2) > 150) {
    //   NotificationManager.error(
    //     `Size of file should be less than 150kb`,
    //     'Error!!',
    //     5000
    //   );
    //   return;
    // }
    // size check if required
    let params;
    params = new FormData()
    params.append('image', file)
    API.uploadAccessoriesImage(params)
      .then((response) => {
        if (response.data.data.img_url) {
          let temp = JSON.parse(JSON.stringify(productObj))
          if (index) temp[fieldName][index][keyName] = response.data.data.img_url;
          temp[fieldName][keyName] = response.data.data.img_url
          setProductObj({ ...temp });
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || 'Error Occured',
          'Error!!',
          5000
        );
      })
  };
  const removeClickHanler = (index) => {
    let temp = [...productObj.frequently_bought_together];
    let temp2 = [...productObj.suggestedProducts];
    temp.splice(index, 1);
    temp2.splice(index, 1);
    setProductObj({
      ...productObj,
      frequently_bought_together: temp,
      suggestedProducts: temp2, 
    });
  };
  const saveProductDetails = () => {
    setShowLoader(true)
    const productCheck = checkMandatoryField()
    if (productCheck.flag) {
      let data = JSON.parse(JSON.stringify(productObj));
      delete data.suggestedProducts;
      delete data.faqCount;
      delete data.reviewCount;
      delete data.tempProductId;
      API.addAccessoriesProdcut(data)
        .then(() => {
          setShowLoader(false)
          handleButtonClick()
          NotificationManager.success(
            'Product added successfully.',
            'Success',
            3000
          )
          NotificationManager.success(
            'Now add images, related products and edit properties from edit menu',
            'Success',
            5000
          )
        })
        .catch((error) => {
          setShowLoader(false)
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : 'error occured!',
            'Error!!',
            3000
          )
        })
    } else {
      setShowLoader(false)
      NotificationManager.error(
        productCheck.msg || 'Enter all field marked with (*).',
        'Error!!',
        3000
      )
    }
  };
  const getVariantList = () => {
    let variantList = []
    for (let i = 0; i < variantCount; i += 1) {
      variantList.push(
        <VariantItem
          index={i}
          carDependent={productObj.car_dependent}
          removeVariant={removeVariant}
          attributes={attributes}
          variant={
            productObj.variants &&
            productObj.variants.length &&
            productObj.variants[i]
              ? productObj.variants[i]
              : {}
          }
          handleProductFieldChange={handleProductFieldChange}
          handleVariantChange={handleVariantChange}
          handleVariantCarChange={handleVariantCarChange}
          moreCarCount={moreCarCount}
          setMoreCarCount={setMoreCarCount}
          removeMoreCar={removeMoreCar}
        />
      )
    }
    return variantList
  };
  const removeVariant = (index) => {
    if (variantCount === 1) {
      NotificationManager.error(
        'Atleast 1 variant must be present.',
        'Error',
        3000
      )
      return
    }
    const variantObj = JSON.parse(JSON.stringify(productObj.variants))
    variantObj.splice(index, 1)
    setProductObj({
      ...productObj,
      variants: variantObj,
    })
    setVariantCount(variantCount - 1)
  };
  const removeReview= (index) => {
    if (productObj.reviewCount === 1) {
      NotificationManager.error("Atleast 1 review must be present.", "Error", 3000);
      return;
    }
    const tempObj = JSON.parse(JSON.stringify(productObj.reviews));
    tempObj.splice(index,1);
    setProductObj({
      ...productObj,
      reviews: tempObj,
      reviewCount: productObj.reviewCount - 1,
    })
  };
  const removeFAQ = (index) => {
    if (productObj.faqCount === 1) {
      NotificationManager.error("Atleast 1 FAQ must be present.", "Error", 3000);
      return;
    }
    const faqObj = JSON.parse(JSON.stringify(productObj.faq));
    faqObj.splice(index,1);
    setProductObj({
      ...productObj,
      faq: faqObj,
      faqCount: productObj.faqCount -1,
    })
  };
  const removeVideo = (index) => {
    const videoOBJ = JSON.parse(JSON.stringify(productObj.video));
    videoOBJ.splice(index,1);
    setProductObj({
      ...productObj,
      video: videoOBJ,
      videoCount: productObj.videoCount -1,
    })
  }
  const removeMoreCar = (vIndx, indx) => {
    const variantObj = JSON.parse(JSON.stringify(productObj.variants))
    if (variantObj[vIndx] && variantObj[vIndx].more_cars && variantObj[vIndx].more_cars.length) variantObj[vIndx].more_cars.splice(indx, 1)
    setProductObj({
      ...productObj,
      variants: variantObj,
    })
    setMoreCarCount(moreCarCount - 1)
  };
  const getFAQList = () => {
    let faqList = [];
    for (let i = 0; i < productObj.faqCount; i += 1) {
      faqList.push(
        <div className="faqItem extramainpanel">
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`FAQ ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => removeFAQ(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Heading
              </div>
              <input
                type="text"
                placeholder="Heading"
                value={productObj.faq[i] ? productObj.faq[i].Q : ''}
                className="field border-box small"
                onChange={(e) => handleArraykeyChange('faq', 'Q', i, e)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) => handleArraykeyChange('faq', 'A', i, e)}
              >
                {productObj.faq[i] ? productObj.faq[i].A : ''}
              </textarea> 
            </div>
          </div>
        </div>
      );
    }
    return faqList;
  };
  const getProductReviewList = () => {
    let reviewList = [];
    for (let i = 0; i < productObj.reviewCount; i += 1) {
      reviewList.push(
        <div className="reviewItem extramainpanel">
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Review ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => removeReview(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Review Title
              </div>
              <input
                type="text"
                placeholder="Review Title"
                value={productObj.reviews[i] ? productObj.reviews[i].title : ''}
                className="field border-box small"
                onChange={(e) => handleArraykeyChange('reviews', 'title', i, e)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Customer Name
              </div>
              <input
                type="text"
                placeholder="Customer Name"
                value={productObj.reviews[i] ? productObj.reviews[i].review_by : ''}
                className="field border-box small"
                onChange={(e) => handleArraykeyChange('reviews', 'review_by', i, e)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Review Date
              </div>
              <input
                type="text"
                placeholder="(dd Month yyyy)"
                value={productObj.reviews[i] ? productObj.reviews[i].review_date : ''}
                className="field border-box small"
                onChange={(e) => handleArraykeyChange('reviews', 'review_date', i, e)}
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Review Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) => handleArraykeyChange('reviews', 'review', i, e)}
              >
                {productObj.reviews[i] ? productObj.reviews[i].review : ''}
              </textarea> 
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Rating
              </div>
              <input
                type="text"
                placeholder="Rating"
                value={productObj.reviews[i] ? productObj.reviews[i].rating : ''}
                className="field border-box small"
                onChange={(e) => handleArraykeyChange('reviews', 'rating', i, e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return reviewList;
  };
  const getProductVideoList = () => {
    let videoList = [];
    for (let i = 0; i < productObj.videoCount; i += 1) {
      let videoThumbnail = React.createRef();
      let videoProfileIcon = React.createRef();
      videoList.push(
      <div className="videoItem extramainpanel">
        <div className="border-box clearfix subtitle">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Video ${i + 1}`}
          </div>
          <div className="border-box floatright rightalign">
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={() => removeVideo(i)}
            >
              Remove
            </button>
          </div>
        </div>
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video URL
            </div>
            <input
              type="text"
              placeholder="Video URL"
              value={productObj.video[i] ? productObj.video[i].link : ""}
              className="field border-box small"
              onChange={(e) => {
                handleArraykeyChange(
                  "video",
                  "link",
                  i,
                  e,               
                );
              }}
            />
          </div>
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video Title
            </div>
            <input
              type="text"
              placeholder="Video Title"
              value={productObj.video[i] ? productObj.video[i].text : ""}
              className="field border-box small"
              onChange={(e) => {
                handleArraykeyChange(
                  "video",
                  "text",
                  i,
                  e,                  
                );
              }}
            />
          </div>
        </div>
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video Description
            </div>
            <input
              type="text"
              placeholder="Video Description"
              value={
                productObj.video[i] ? productObj.video[i].subText : ""
              }
              className="field border-box small"
              onChange={(e) => {
                handleArraykeyChange(
                  "video",
                  "subText",
                  i,
                  e,
                );
              }}
            />
          </div>
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video Thumbnail Icon
            </div>
            {productObj.video[i] && productObj.video[i].thumbnail &&
            typeof productObj.video[i].thumbnail === "string" ? (
              <div className="border-box fieldimage1">
                <img
                  src={productObj.video[i].thumbnail}
                  height="100"
                  width="100"
                  alt="icon"
                />
                <br></br>
                <button
                  class="btn btn_small btncolorprim btncard move btndelete"
                  onClick={() =>
                    handleArraykeyChange("video", "thumbnail",i , { target: { value: null }})
                  }
                >
                  Delete
                </button>
              </div>
            ) : (
              <div className="border-box fieldimage1">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleArraykeyChange("video", "thumbnail", i, { target: { value: e.target.files[0]}})
                  }
                />
                <div
                  className="border-box clearfix field2"
                  style={{ marginTop: "10px" }}
                >
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Or Enter URL
                  </div>
                  <input
                    ref={videoThumbnail}
                    type="text"
                    placeholder="Thumnail URL"
                    className="field border-box small"
                  />
                </div>
                <button
                  class="btn btn_small btncolorprim btncard move btndelete"
                  onClick={() => {
                    if (
                      videoThumbnail.current.value.length &&
                      productObj.video[i].thumbnail &&
                      typeof productObj.video[i].thumbnail === "object"
                    ) {
                      NotificationManager.error(
                        "Either choose a image to upload or enter URL",
                        "Error!!",
                        5000
                      );
                      return;
                    }
                    if (videoThumbnail.current.value) {
                      const e = {
                        target: {
                          value: videoThumbnail.current.value,
                        },
                      };
                      handleArraykeyChange(
                        "video",
                        "thumbnail",
                        i,
                        e,
                      );
                    } else if (
                      typeof productObj.video[i].thumbnail === "object"
                    ) {
                      uploadImageOnServer(
                        "video",
                        "thumbnail",
                        productObj.video[i].thumbnail,
                        i,
                      );
                    }
                  }}
                >
                  Upload Icon
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Profile Name
            </div>
            <input
              type="text"
              placeholder="Profile Name"
              value={
                productObj.video[i] ? productObj.video[i].profile_name : ""
              }
              className="field border-box small"
              onChange={(e) => {
                handleArraykeyChange(
                  "profile_name",
                  "video",
                  i,
                  e,
                );
              }}
            />
          </div>
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
             Profile Icon
            </div>
            {productObj.video[i] &&
            productObj.video[i].profile_icon &&
            typeof productObj.video[i].profile_icon === "string" ? (
              <div className="border-box fieldimage1">
                <img
                  src={productObj.video[i].profile_icon}
                  height="100"
                  width="100"
                  alt="icon"
                />
                <br></br>
                <button
                  class="btn btn_small btncolorprim btncard move btndelete"
                  onClick={() =>
                    handleArraykeyChange("video", "profile_icon",i , { target: { value: null }})
                  }
                >
                  Delete
                </button>
              </div>
            ) : (
              <div className="border-box fieldimage1">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleArraykeyChange("video", "profile_icon", i, { target: { value: e.target.files[0]}})
                  }
                />
                <div
                  className="border-box clearfix field2"
                  style={{ marginTop: "10px" }}
                >
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Or Enter URL
                  </div>
                  <input
                    ref={videoProfileIcon}
                    type="text"
                    placeholder="Icon Url"
                    className="field border-box small"
                  />
                </div>
                <button
                  class="btn btn_small btncolorprim btncard move btndelete"
                  onClick={() => {
                    if (
                      videoProfileIcon.current.value.length &&
                      productObj.video[i].profile_icon &&
                      typeof productObj.video[i].profile_icon === "object"
                    ) {
                      NotificationManager.error(
                        "Either choose a image to upload or enter URL",
                        "Error!!",
                        5000
                      );
                      return;
                    }
                    if (videoProfileIcon.current.value) {
                      const e = {
                        target: {
                          value: videoProfileIcon.current.value,
                        },
                      };
                      handleArraykeyChange(
                        "video",
                        "profile_icon",
                        i,
                        e,
                      );
                    } else if (
                      typeof productObj.video[i].thumbnail === "object"
                    ) {
                      uploadImageOnServer(
                        "video",
                        "profile_icon",
                        productObj.video[i].profile_icon,
                        i,
                      );
                    }
                  }}
                >
                  Upload Icon
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      );
    }
    return videoList;
  }
  useEffect(() => {
    if (productObj && productObj.category_name && !attributes.length) {
      API.getProductAttributes(productObj.category_name).then((response) => {
        if (response.data.data) {
          setAttributes(response.data.data);
          variantMandatoryFields.push({
            key: 'attributes',
            type: 'object',
            object_key: null,
            err_msg: 'Variant {{$index}} attributes fields are required',
          });
        }
      }).catch((error) => {
        console.log("Cant fetch attributes data");
        NotificationManager.error(
          error.response && error.response.data
            ? error.response.data.message
            : 'Cant fetch attributes data',
          'Error!!',
          3000
        )
      });
    }
  }, [productObj.category_name]);

  return (
    <>
      {showLoader ? <OverlayLoader /> : null}
      <div id='dashboardprimeextra' className='border-box bgwhite1 shadow-box'>
        <div className='extramainpanel border-box leftalign'>
          <div className='border-box clearfix title stickyTitle'>
            <div
              className='border-box floatleft leftalign blackspan2 weight900 shadow-text'
              style={{ padding: '0px 0px 0px 10px' }}
            >
              Add Details
            </div>
            <div className='border-box floatright rightalign'>
              <button
                className='btn btn_small btncolorprim btncard move btncloseextra'
                onClick={() => handleButtonClick('close')}
              >
                Close
              </button>
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.handleButtonClick("save")}
              >
                Save
              </button>
            </div>
          </div>
          <div style={{ fontSize: '13px', padding: '0px 0px 0px 30px' }}>
            <b>Note: </b> Field marked (<span style={{ color: 'red' }}>*</span>)
            are mandatory.
          </div>
          <div className='border-box floatleft leftalign blackspan2 shadow-text'>
            <span style={{ padding: '0px 0px 0px 30px' }}>Product Details</span>
            <br />
            <span style={{ fontSize: '13px', padding: '0px 0px 0px 30px' }}>
              <b>Note:</b>
              <i>The following details will be updated at product level.</i>
            </span>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Page Title<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Page Title'
                value={productObj.page_title}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('page_title', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Display Name<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Display Name'
                value={productObj.display_name}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('display_name', e, 'product')
                }
              />
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Title<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Title'
                value={productObj.title}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('title', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Year<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Year'
                value={productObj.year}
                className='field border-box small'
                onChange={(e) => handleProductFieldChange('year', e, 'product')}
              />
            </div>
          </div>

          <div className='border-box fields'>
            <div className='border-box field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Brand<sup>*</sup>
              </div>
              {brandList && (
                <MultiSelect
                  options={brandList}
                  labelKey='name'
                  checkbox_id='name'
                  placeholder='Brand'
                  disabledItems={[]}
                  hideOnBlur
                  selectedOptions={{ name: productObj.brand_name }}
                  onChange={(e) => {
                    setProductObj({
                      ...productObj,
                      brand_name: e.name,
                    })
                  }}
                />
              )}
            </div>
            <div className='border-box field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Category<sup>*</sup>
              </div>
              {categoriesList && (
                <MultiSelect
                  options={categoriesList}
                  labelKey='name'
                  checkbox_id='name'
                  placeholder='Category'
                  disabledItems={[]}
                  selectedOptions={{ name: productObj.category_name }}
                  hideOnBlur
                  onChange={(e) => {
                    setProductObj({
                      ...productObj,
                      category_name: e.name,
                    })
                  }}
                />
              )}
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Meta Description<sup>*</sup>
              </div>
              <textarea
                type='text'
                placeholder='Meta Description'
                value={productObj.meta_description}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('meta_description', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Slug<sup>*</sup>
              </div>
              <textarea
                type='text'
                placeholder='Slug'
                value={productObj.slug}
                className='field border-box small'
                onChange={(e) => handleProductFieldChange('slug', e, 'product')}
              />
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Description<sup>*</sup>
              </div>
              <textarea
                placeholder='Description'
                value={productObj.description.details}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'details',
                    e,
                    'product',
                    'object',
                    'description'
                  )
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Features<sup>*</sup>
              </div>
              <textarea
                type='text'
                placeholder="Features (keywords seperated by '%$#$%')"
                value={productObj.features.text}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'text',
                    e,
                    'product',
                    'object',
                    'features'
                  )
                }
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Properties
              </div>
              <textarea
                type='text'
                placeholder="Properties (lines seperated by '%$#$%')"
                value={productObj.properties}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('properties', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                How to use
              </div>
              <textarea
                type='text'
                placeholder="How to use (lines seperated by '%$#$%')"
                value={productObj.how_to_use}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('how_to_use', e, 'product')
                }
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Color<sup>*</sup>
              </div>
              <input
                type='text'
                className='field border-box small'
                placeholder='Color'
                value={productObj.color_text}
                onChange={(e) =>
                  handleProductFieldChange('color_text', e, 'product')
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    let temp = productObj.color ? [...productObj.color] : []
                    temp.push(e.target.value.trim())
                    setProductObj({
                      ...productObj,
                      color: temp,
                      color_text: '',
                    })
                  }
                }}
              />
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {productObj.color &&
                  productObj.color.map((item) => (
                    <>
                      <li
                        key={item}
                        style={{
                          width: '150px',
                          display: 'inline-block',
                          padding: '5px',
                          margin: '5px',
                          fontSize: '15px',
                        }}
                        className='choiceboxmultiinnerselected'
                      >
                        <span>{item}</span>
                        <span
                          style={{
                            float: 'right',
                            display: 'inline-block',
                            padding: '0px 5px',
                            background: '#ccc',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            let temp = productObj.color
                              ? [...productObj.color]
                              : []
                            temp.splice(temp.indexOf(item), 1)
                            setProductObj({
                              ...productObj,
                              color: temp,
                            })
                          }}
                        >
                          x
                        </span>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Tags<sup>*</sup>
              </div>
              <input
                type='text'
                className='field border-box small'
                placeholder='Tags'
                value={productObj.tags_text}
                onChange={(e) =>
                  handleProductFieldChange('tags_text', e, 'product')
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    let temp = productObj.tags ? [...productObj.tags] : []
                    temp.push(e.target.value.trim())
                    setProductObj({
                      ...productObj,
                      tags: temp,
                      tags_text: '',
                    })
                  }
                }}
              />
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {productObj.tags &&
                  productObj.tags.map((item) => (
                    <>
                      <li
                        key={item}
                        style={{
                          width: '150px',
                          display: 'inline-block',
                          padding: '5px',
                          margin: '5px',
                          fontSize: '15px',
                        }}
                        className='choiceboxmultiinnerselected'
                      >
                        <span>{item}</span>
                        <span
                          style={{
                            float: 'right',
                            display: 'inline-block',
                            padding: '0px 5px',
                            background: '#ccc',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            let temp = productObj.tags
                              ? [...productObj.tags]
                              : []
                            temp.splice(temp.indexOf(item), 1)
                            setProductObj({
                              ...productObj,
                              tags: temp,
                            })
                          }}
                        >
                          x
                        </span>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Popularity<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Popularity(Enter value between 0-1)'
                value={productObj.popularity}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('popularity', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Product Tag
              </div>
              <input
                type='text'
                placeholder='Product Tag'
                value={productObj.product_tag}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('product_tag', e, 'product')
                }
              />
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Is Star Product<sup>*</sup>
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='is_star_product1'
                name='is_star_product'
                value='1'
                checked={productObj.is_star_product}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_star_product: Number(e.target.value),
                  })
                }}
              />
              <label for='is_star_product1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='is_star_product2'
                name='is_star_product'
                value='0'
                checked={!productObj.is_star_product}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_star_product: Number(e.target.value),
                  })
                }}
              />
              <label for='is_star_product2'>No</label>
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Is Hero Product<sup>*</sup>
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='is_hero_product1'
                name='is_hero_product'
                value='1'
                checked={productObj.is_hero_product}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_hero_product: Number(e.target.value),
                  })
                }}
              />
              <label for='is_hero_product1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='is_hero_product2'
                name='is_hero_product'
                value='0'
                checked={!productObj.is_hero_product}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_hero_product: Number(e.target.value),
                  })
                }}
              />
              <label for='is_hero_product2'>No</label>
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box field2 clearfix'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Car Dependent<sup>*</sup>
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='car_dependent1'
                name='car_dependent'
                value='1'
                checked={productObj.car_dependent}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    car_dependent: Number(e.target.value),
                  })
                }}
              />
              <label for='car_dependent1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='car_dependent2'
                name='car_dependent'
                value='0'
                checked={!productObj.car_dependent}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    car_dependent: Number(e.target.value),
                  })
                }}
              />
              <label for='car_dependent2'>No</label>
            </div>
            <div className='border-box field2 clearfix'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Is Trending<sup>*</sup>
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='is_trending1'
                name='is_trending'
                value='1'
                checked={productObj.is_trending}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_trending: Number(e.target.value),
                  })
                }}
              />
              <label for='is_trending1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='is_trending2'
                name='is_trending'
                value='0'
                checked={!productObj.is_trending}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_trending: Number(e.target.value),
                  })
                }}
              />
              <label for='is_trending2'>No</label>
            </div>
          </div>

          <div className='border-box clearfix fields'>
            <div className='border-box field2 clearfix'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Is Installation Required
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='is_installation_required1'
                name='is_installation_required'
                value='1'
                checked={productObj.is_installation_required}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_installation_required: Number(e.target.value),
                  })
                }}
              />
              <label for='is_installation_required1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='is_installation_required2'
                name='is_installation_required'
                value='0'
                checked={!productObj.is_installation_required}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_installation_required: Number(e.target.value),
                  })
                }}
              />
              <label for='is_installation_required2'>No</label>
            </div>
            <div className='border-box field2 clearfix'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Is Locally Available
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='is_locally_available1'
                name='is_locally_available'
                value='1'
                checked={productObj.is_locally_available}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_locally_available: Number(e.target.value),
                  })
                }}
              />
              <label for='is_locally_available1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='is_locally_available2'
                name='is_locally_available'
                value='0'
                checked={!productObj.is_locally_available}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_locally_available: Number(e.target.value),
                  })
                }}
              />
              <label for='is_locally_available2'>No</label>
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box field2 clearfix'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Is Bundled Item
              </div>
              <input
                className='field border-box small'
                type='radio'
                id='is_bundle_item1'
                name='is_bundle_item'
                value='1'
                checked={productObj.is_bundle_item}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_bundle_item: Number(e.target.value),
                  })
                }}
              />
              <label for='is_bundle_item1'>Yes</label>
              <input
                className='field border-box small'
                type='radio'
                id='is_bundle_item2'
                name='is_bundle_item'
                value='0'
                checked={!productObj.is_bundle_item}
                onChange={(e) => {
                  setProductObj({
                    ...productObj,
                    is_bundle_item: Number(e.target.value),
                  })
                }}
              />
              <label for='is_bundle_item2'>No</label>
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Quantity Capacity
              </div>
              <input
                type='text'
                placeholder='Quantity Capacity'
                value={productObj.qty_capacity}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('qty_capacity', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Fragility Level
              </div>
              <input
                type='text'
                placeholder='Fragility Level'
                value={productObj.fragility_level}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('fragility_level', e, 'product')
                }
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                GST Rate<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='GST Rate'
                value={productObj.gst_rate}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('gst_rate', e, 'product')
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Google Category ID
              </div>
              <input
                type='text'
                placeholder='Google Category ID'
                value={productObj.google_category_id}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange('google_category_id', e, 'product')
                }
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Variable Fields
              </div>
              <input
                type='text'
                className='field border-box small'
                placeholder='Variable Field'
                value={productObj.variable_fields_text}
                onChange={(e) =>
                  handleProductFieldChange('variable_fields_text', e, 'product')
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    let temp = productObj.variable_fields
                      ? [...productObj.variable_fields]
                      : []
                    temp.push(e.target.value.trim())
                    setProductObj({
                      ...productObj,
                      variable_fields: temp,
                      variable_fields_text: '',
                    })
                  }
                }}
              />
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {productObj.variable_fields &&
                  productObj.variable_fields.map((item) => (
                    <>
                      <li
                        key={item}
                        style={{
                          width: '150px',
                          display: 'inline-block',
                          padding: '5px',
                          margin: '5px',
                          fontSize: '15px',
                        }}
                        className='choiceboxmultiinnerselected'
                      >
                        <span>{item}</span>
                        <span
                          style={{
                            float: 'right',
                            display: 'inline-block',
                            padding: '0px 5px',
                            background: '#ccc',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            let temp = productObj.variable_fields
                              ? [...productObj.variable_fields]
                              : []
                            temp.splice(temp.indexOf(item), 1)
                            setProductObj({
                              ...productObj,
                              variable_fields: temp,
                            })
                          }}
                        >
                          x
                        </span>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Materials
              </div>
              <input
                type='text'
                className='field border-box small'
                placeholder='Materials (Enter data as key:value)'
                value={productObj.materials_text}
                onChange={(e) =>
                  handleProductFieldChange('materials_text', e, 'product')
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    let temp = { ...productObj.materials } || {}
                    const data = e.target.value.trim().split(':')
                    if (!data[1]) {
                      alert('Please enter data in required format ')
                      // setProductObj({
                      //   ...productObj,
                      //   materials_text: "",
                      // });
                      return
                    }
                    temp[data[0]] = data[1]
                    setProductObj({
                      ...productObj,
                      materials: temp,
                      materials_text: '',
                    })
                  }
                }}
              />
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {productObj.materials &&
                  Object.keys(productObj.materials).map((key) => (
                    <>
                      <li
                        key={key}
                        style={{
                          width: '150px',
                          display: 'inline-block',
                          padding: '5px',
                          margin: '5px',
                          fontSize: '15px',
                        }}
                        className='choiceboxmultiinnerselected'
                      >
                        <span>{`${key}:${productObj.materials[key]}`}</span>
                        <span
                          style={{
                            float: 'right',
                            display: 'inline-block',
                            padding: '0px 5px',
                            background: '#ccc',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            let temp = { ...productObj.materials } || {}
                            delete temp[key]
                            setProductObj({
                              ...productObj,
                              materials: temp,
                            })
                          }}
                        >
                          x
                        </span>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
              Warranty Details
            </div>
          </div>
          <div className="warrantyContainer">
            <div className="videoItem extramainpanel">
              <div className="border-box clearfix fields">
                <div className="border-box clearfix field1">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Title/Warranty
                  </div>
                  <input
                    type="text"
                    placeholder="Title"
                    value={productObj.warranty_details ? productObj.warranty_details.title : ""}
                    className="field border-box small"
                    onChange={(e) => {
                      handleProductFieldChange(
                        "title",
                        e,
                        "product",
                        "object",
                        "warranty_details"
                      );
                    }}
                  />
                </div>
                <div className="border-box clearfix field1">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Covered in warranty
                  </div>
                  <textarea
                    class="field border-box small"
                    placeholder="Descriptionn"
                    value={productObj.warranty_details ? productObj.warranty_details.covered : ""}
                    onChange={(e) => {
                      handleProductFieldChange(
                        "covered",
                        e,
                        "product",
                        "object",
                        "warranty_details"
                      );
                    }}
                  />
                </div>
                <div className="border-box clearfix field1">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Not Covered in warranty
                  </div>
                  <textarea
                    class="field border-box small"
                    placeholder="Descriptionn"
                    value={productObj.warranty_details ? productObj.warranty_details.uncovered : ""}
                    onChange={(e) => {
                      handleProductFieldChange(
                        "uncovered",
                        e,
                        "product",
                        "object",
                        "warranty_details"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
              Video Details 
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => setProductObj({
                  ...productObj,
                  videoCount: (productObj.videoCount + 1)
                })}
              >
                Add Video
              </button>
            </div>
          </div>
          <div className="videoContainer">
            {getProductVideoList()}
          </div>
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
              FAQ Details 
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => setProductObj({
                  ...productObj,
                  faqCount: (productObj.faqCount + 1)
                })}
              >
                Add FAQ
              </button>
            </div>
          </div>
          <div className="faqContainer">
            {getFAQList()}
          </div>
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
              Reviews Details 
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => setProductObj({ ...productObj, reviewCount: (productObj.reviewCount + 1)})}
              >
                Add Review
              </button>
            </div>
          </div>
          <div className="testimonialContainer">
            {getProductReviewList()}
          </div>
          <div className="border-box clearfix fields" style={{ marginTop: '50px'}}>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Frequently Bought Together Products
              </div>
              <input
                type="text"
                placeholder="Product ID or SKU Code"
                value={productObj.tempProductId}
                className="field border-box small"
                onChange={(e) => handleProductFieldChange('tempProductId', e, 'product')}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleProductInputEnter(e);
                    e.target.value = "";
                  }
                }}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="middle border-box clearfix leftalign">
              <div
                className="border-box blackspan65 weight700 dpm1boxes10"
                style={{ display: "inline-block" }}
              >
                <table className="stdtable">
                  <tr>
                    <td valign="center">Product ID</td>
                  </tr>
                </table>
              </div>
              <div
                className="border-box blackspan65 weight700 dpm1boxes20"
                style={{ display: "inline-block" }}
              >
                <table className="stdtable">
                  <tr>
                    <td valign="center">SKU Code</td>
                  </tr>
                </table>
              </div>
              <div
                className="border-box blackspan65 weight700 dpm1boxes30"
                style={{ display: "inline-block" }}
              >
                <table className="stdtable">
                  <tr>
                    <td valign="center">Product Name</td>
                  </tr>
                </table>
              </div>
              <div
                className="border-box blackspan65 weight700 dpm1boxes20"
                style={{ display: "inline-block" }}
              >
                <table className="stdtable">
                  <tr>
                    <td valign="center">Price</td>
                  </tr>
                </table>
              </div>
              <div
                className="border-box blackspan65 weight700 dpm1boxes15"
                style={{ display: "inline-block" }}
              >
                <table className="stdtable">
                  <tr>
                    <td valign="center">Remove</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="border-box clearfix fields" style={{ marginBottom: '50px'}}>
          {productObj.suggestedProducts && productObj.suggestedProducts.map((item, index) => (
            <>
              <div className="middle border-box clearfix leftalign">
                <div
                  className="textsize12 border-box weight500 dpm1boxes10"
                  style={{ display: "inline-block" }}
                >
                  <table className="stdtable">
                    <tr>
                      <td valign="center">{item.product_id}</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="textsize12 border-box weight500 dpm1boxes20"
                  style={{ display: "inline-block" }}
                >
                  <table
                    className="stdtable colorprim500span"
                    style={{ textTransform: "uppercase" }}
                  >
                    <tr>
                      <td valign="center">{item.sku_code[0]}</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="textsize12 border-box weight500 dpm1boxes30"
                  style={{ display: "inline-block" }}
                >
                  <table className="stdtable">
                    <tr>
                      <td valign="center">{item.title}</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="textsize12 border-box weight500 dpm1boxes20"
                  style={{ display: "inline-block" }}
                >
                  <table className="stdtable">
                    <tr>
                      <td valign="center">{item.final_price}</td>
                    </tr>
                  </table>
                </div>
                <div
                  className="textsize12 border-box weight500 dpm1boxes15"
                  style={{ display: "inline-block" }}
                >
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <span
                          style={{
                            display: "inline-block",
                            padding: "0px 5px",
                            background: "#ccc",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            removeClickHanler(index);
                          }}
                        >
                          x
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </>
          ))}
          </div>
          <div className='border-box floatleft leftalign blackspan2 shadow-text'>
            <span style={{ padding: '0px 0px 0px 30px' }}>Variant Details</span>
            <br />
            <span style={{ fontSize: '13px', padding: '0px 0px 0px 30px' }}>
              <b>Note:</b>
              <i>The following details will be updated at variant level.</i>
            </span>
          </div>
          <div className='variantsContainer'>
            <div className='border-box clearfix footer'>
              <div className='border-box floatright rightalign'>
                <button
                  className='btn btn_small btncolorprim btncard move btncloseextra'
                  onClick={() => setVariantCount(variantCount + 1)}
                >
                  Add More Variant
                </button>
              </div>
            </div>
            {getVariantList()}
          </div>
          <div className='border-box clearfix footer'>
            {/* <div className='border-box floatright rightalign'>
              <button
                className='btn btn_small btncolorprim btncard move btncloseextra'
                onClick={saveProductDetails}
              >
                Save
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProduct
