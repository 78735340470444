import React from 'react';
import API from '../api';
// import "./dashboard/dashboard.css"
class MiddleUpper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleSummaryClick = this.handleSummaryClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
    }
  
    handleFilterClick(){
        // console.log("clicking");
        this.props.onFilterClick();
    }

    handleSearchClick(){
        if(this.state.search != null){
            this.props.onSearch1Click(this.state.search);
        }
    }

    handleChange(type, e) {
        // console.log(this.state);
        var obj = this.state;
        obj[type] = e.target.value;
        this.setState(obj);
        // console.log(this.state);
      }

    handleSummaryClick(){
        
    }

    handleAddClick(){
        this.props.onAddClick(this.props.tag);
    }

    onChange(e) {
        this.setState({file:e.target.files[0]})
    }

    onFormSubmit(e){
        e.preventDefault() // Stop form submit
        this.fileUpload(this.state.file).then((response)=>{
            // console.log(response.data);
        })
    }

    fileUpload(file){
        // const url = 'https://devaccessories.gomechanic.app/product-bulk-upload/';
        // const formData = new FormData();
        // formData.append('file',file)
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data'
        //     }
        // }
        // return  post(url, formData,config)
        return API.bulkUploadProducts(file);
      }

    render() {

        switch(this.props.tag){
            case 'segments':
                    return (
                        <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="filterbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleFilterClick}>Filter</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleAddClick}>Add segment</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="summarybtn" className="btn btn_small btnwhite btncard move" data-type="" onClick={this.handleSummaryClick}>Summary</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Slug</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Description</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Title</td>
                                    </tr>
                                </table>
                            </div>
                            {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Content</td>
                                    </tr>
                                </table>
                            </div> */}
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        </React.Fragment>
                    );
            case 'categories':
                            return (
                                <React.Fragment>
                                <div className="top border-box clearfix leftalign">
                                    <div className="border-box clearfix">
                                        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="filterbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleFilterClick}>Filter</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                        {/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleAddClick}>Add Question</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="summarybtn" className="btn btn_small btnwhite btncard move" data-type="" onClick={this.handleSummaryClick}>Summary</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="middle border-box clearfix leftalign">
                                <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Segment</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Star Category</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Title</td>
                                    </tr>
                                </table>
                            </div>
                            {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Content</td>
                                    </tr>
                                </table>
                            </div> */}
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                                </div>
                                </React.Fragment>
                            );
                        case 'brands':
                                            return (
                                                <React.Fragment>
                                                <div className="top border-box clearfix leftalign">
                                                    <div className="border-box clearfix">
                                                        <div className="blackspan65 weight700 floatleft">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="filterbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleFilterClick}>Filter</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                        {/*		        <div className="blackspan65 weight700 floatleft">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>*/}
                                                        <div className="blackspan65 weight700 floatright">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleAddClick}>Add Comparison</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="blackspan65 weight700 floatright">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="blackspan65 weight700 floatright">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="summarybtn" className="btn btn_small btnwhite btncard move" data-type="" onClick={this.handleSummaryClick}>Summary</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="middle border-box clearfix leftalign">
                                                    <div className="border-box blackspan65 weight700 dpm1boxes3">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center"></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpm1boxes45">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">ID</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpm1boxes40">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Name</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpm1boxes10">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Code</td>
                                                            </tr>
                                                        </table>
                                                    </div>
        
                                                    {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Content</td>
                                                            </tr>
                                                        </table>
                                                    </div> */}
                                                    <div className="border-box blackspan65 weight700 dpm1boxes2">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center"></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                </React.Fragment>
                                            );
                    case 'testimonials':
                                            return (
                                                <React.Fragment>
                                                <div className="top border-box clearfix leftalign">
                                                    <div className="border-box clearfix">
                                                        <div className="blackspan65 weight700 floatleft">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="filterbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleFilterClick}>Filter</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                        {/*		        <div className="blackspan65 weight700 floatleft">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>*/}
                                                        <div className="blackspan65 weight700 floatright">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="addleadbtn" className="btn btn_small btncolorprim btncard move" onClick={this.handleAddClick}>Add Testimonial</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="blackspan65 weight700 floatright">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="blackspan65 weight700 floatright">
                                                            <table className="stdtable">
                                                                <tr>
                                                                    <td valign="center">
                                                                        <button id="summarybtn" className="btn btn_small btnwhite btncard move" data-type="" onClick={this.handleSummaryClick}>Summary</button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="middle border-box clearfix leftalign">
                                                    <div className="border-box blackspan65 weight700 dpmboxes1">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center"></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Page Type</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpmboxes12">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Page Path</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Customer</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpmboxes3">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Heading</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpmboxes3">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Content</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Source</td>
                                                            </tr>
                                                        </table>
                                                    </div>
        
                                                    {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center">Content</td>
                                                            </tr>
                                                        </table>
                                                    </div> */}
                                                    <div className="border-box blackspan65 weight700 dpmboxes9">
                                                        <table className="stdtable">
                                                            <tr>
                                                                <td valign="center"></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                </React.Fragment>
                                            );
            case 'fleets':
                    return (
                        <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="filterbtn" className="btn btn_small btncolorprim btncard move">Filter</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="addleadbtn" className="btn btn_small btncolorprim btncard move">Add fleet</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpmboxes1">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes26">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Type</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Location</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Primary contact</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Billing name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpmboxes9">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        </React.Fragment>
                    );

            case 'garages':
                            return (
                                <React.Fragment>
                                <div className="top border-box clearfix leftalign">
                                    <div className="border-box clearfix">
                                        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="filterbtn" className="btn btn_small btncolorprim btncard move">Filter</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                        {/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="addleadbtn" className="btn btn_small btncolorprim btncard move">Add garage</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="blackspan65 weight700 floatright">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="excelleadsbtn" className="btn btn_small btnwhite btncard move" data-type="">Get as Excel file</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="middle border-box clearfix leftalign">
                                    <div className="border-box blackspan65 weight700 dpmboxes1">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center"></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes26">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">ID</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Name</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes8">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Code</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Location</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Contact</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes10">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Display name</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="border-box blackspan65 weight700 dpmboxes9">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                </React.Fragment>
                            );


                            case 'products':
                                return (
                                    <React.Fragment>
                                    <div className="top border-box clearfix leftalign">
                                        <div className="border-box clearfix">
                                            <div className="blackspan65 weight700 floatleft">
                                                <table className="stdtable">
                                                    <tr>
                                                        <td valign="center">
                                                            <input type="text" class="dashboardfields dashboardchangefields tiny" name="searchbysku" placeholder="Search by SKU" style={{width: "200px"}} onChange={(e) => this.handleChange("search", e)}></input>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="blackspan65 weight700 floatleft">
                                                <table className="stdtable">
                                                    <tr>
                                                        <td valign="center">
                                                            <button className="btn btn_small btncolorprim btncard move" onClick={this.handleSearchClick}>Search</button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="middle border-box clearfix leftalign">
                                        
                                    </div>
                                    </React.Fragment>
                                );
            default:
                return null;
        }
    }
  }

export default MiddleUpper;