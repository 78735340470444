import React, { Fragment } from "react";
import MultiSelect from "../broadcast/MultiSelect/MultiSelect";
import ImageUpload from "./imageupload";
import { NotificationManager } from "react-notifications";
import API from '../api';
import AddProduct from './addProduct';
import ManageCoupons from './manageCoupons';
import ServiceInstallation from './serviceInstallation';
import OverlayLoader from '../common/OverlayLoader'
import Row from './row';
import { Editor } from '@tinymce/tinymce-react';

var old_images = [];
var new_images = [];
var new_data = {};
var master_image_url = null;
var master_image = null;
let post_data = {};

class MiddleExtra extends React.Component {
  constructor(props) {
    super(props);

    if (!this.state && this.props.content.type === "add") {
      switch (this.props.content.tag) {
        case "collection":
          this.state = {
            page_title: null,
            description: null,
            slug: null,
            name: null,
            product: null,
            sku_codes: [],
            collection_products: [],
            banner: {},
            sub_banner: {},
            bottom_content: null,
            faq: [],
            faqCount: 1,
          };
          break;
        case "homepage":
          this.state = {
            meta_description: null,
            page_title: null,
            keywords: null,
            bottom_content: null,
            upper_banner: {
              desktop: [],
              mobile: [],
              is_visible: true,
            },
            promotional_upper_banner: {
              desktop: [],
              mobile: [],
              is_visible: true,
            },
            collection_page_banner: {
              desktop: [],
              mobile: [],
              is_visible: true,
            },
            upper_banner_desktopCnt: 1,
            upper_banner_mobileCnt: 1,
            promotional_upper_banner_desktopCnt: 1,
            promotional_upper_banner_mobileCnt: 1,
            collection_page_banner_desktopCnt: 1,
            collection_page_banner_mobileCnt: 1,
            payment_offers_banner: {
              section_heading : null,
              is_visible : true,
            },
            sponsered_brands: {
              section_heading: null,
              is_visible: true,
              cms_data: [],
              count: 1,
            },
            unsponsered_brands: {
              section_heading: null,
              is_visible: true,
              cms_data: [],
              count: 1,
            },
            top_categories: {
              section_heading: null,
              is_visible: true,
              cms_data: [],
              count: 1,
            },
            remaining_categories: {
              section_heading: null,
              is_visible: true,
              cms_data: [],
              count: 1,
            },
            filter_section: {
              section_heading: null,
              is_visible: true,
              data: [],
              count: 1,
            },
            products_list: {
              section_heading: null,
              is_visible: true,
              cms_data: [],
              count: 1,
            },
            upper_video_section: {
              section_heading: null,
              is_visible: true,
              data: [],
              count: 1,
            },
            lower_video_section: {
              section_heading: null,
              is_visible: true,
              data: [],
              count: 1,
            },
            dotd: {
              // section_heading: null,
              is_visible: true,
              cms_data: [],
              count: 1,
            },
            // topCarModels: {},
            testimonials: [],
            faq: [],
            testimonialCount: 1,
            faqCount: 1,
          }
          break;
        case "segments":
          this.state = {
            name: null,
            slug: null,
            meta_description: null,
            page_name: null,
            page_title: null,
            google_category_id: null,
            display_name: null,
            priceList: [],
            popularCategory: [],
            partnerBrands: [],
            banner: {
              desktop: [],
              mobile: [],
            },
            webBannerCnt: 1,
            testimonials: [],
            faq: [],
            testimonialCount: 1,
            faqCount: 1,
          }
          break
        case 'categories':
          this.state = {
            name: null,
            slug: null,
            meta_description: null,
            page_name: null,
            page_title: null,
            google_category_id: null,
            is_popular: 0,
            star_category: 0,
            show_brands_products_count: 1,
            variable_fields: [],
            parent_category_name: null,
            variable_text: null,
            display_name: null,
            priceList: [],
            popularProducts: [],
            partnerBrands: [],
            banner: {
              web: [],
              mobile: [],
            },
            webBannerCnt: 1,
            mobileBannerCnt: 1,
            testimonials: [],
            faq: [],
            attributes: [],
            attribute_text: null,
            promotional_banners: [],
            testimonialCount: 1,
            faqCount: 1,
            pbannerCount: 1,
          }
          break
        case 'searchbysku':
          this.state = {
            variants: {},
            description: {},
            features: {},
            frequently_bought_together: [],
            faq: [],
            faqCount: 1,
            reviews: [],
            reviewCount: 1,
            video: [],
            videoCount: 0,
          };
          break;
        case "coupons":
          this.state = {
            brandList: [],
            categoriesList: [],
            segmentList: [],
          };
          break;
        case "rails":
          this.state = {
            name: "",
            product_ids_string: "",
            ex_product_ids_string: "",
            maximum_price: "",
            minimum_price: "",
            maximum_rating: "",
            minimum_rating: "",
            priority_order: '',
            product_specific_ids_string: [],
            selectedCategories: [],
            excludedCategories: [],
            selectedBrands: [],
            excludedBrands: [],
            selectedTags: [],
            dynamicPricing: 0,
          };
          break;
        case "feed":
          this.state = {
            name: "",
            product_ids_string: "",
            ex_product_ids_string: "",
            maximum_price: "",
            minimum_price: "",
            maximum_rating: "",
            minimum_rating: "",
            selectedCategories: [],
            excludedCategories: [],
            selectedBrands: [],
            excludedBrands: [],
            selectedTags: [],
            excludedTags: [],
            applicable_on: [],
            fetchedProducts: [],
          };
          break;
        case "enquiry":
          this.state = {
            page_title: '',
            metadata: '',
            heading_testimonials: [],
            banner: {},
            video: {},
            store_data: {
              store_images: [],
            },
            customer_testimonials: [],
            faq: [],
            offer: {},
            brands: [],
            popular_category_images: [],
            categoryCount: 1,
            heading_testimonialCount: 1,
            testimonialCount: 1,
            storeImgCount: 1,
            faqCount: 1,
          }
          break;
        case 'brands':
          this.state = {
            name: null,
            slug: null,
            brand_code: null,
            meta_description: null,
            page_title: null,
            description: null,
            icon: null,
            alt_img: null,
            banner: {
              desktop: [],
              mobile: [],
            },
            webBannerCnt: 1,
            mobileBannerCnt: 1,
            // testimonials: [],
            faq: [],
            // testimonialCount: 1,
            faqCount: 1,
            priceList: [],
            video: false,
          }
          break
        case "carPage":
          this.state = {
            meta_description: null,
            page_title: null,
            top_banner: {},
            bottom_banner: {},
            top_content: null,
            bottom_content: null,
            brand: {},
            model: {},
            faq: [],
            faqCount: 1,
            pageType: null,
          }
          break;
        case "collectionv2":
          this.state = {
            basic_details: false,
            title_banner: false,
            upper_category_section: false,
            upper_banner: false,
            offers_banner: false,
            middle_category_banner: false,
            lower_category_section: false,
            filter_section: false,
            lower_banner: false,
            partner_brands: false,
            video: false,
            base_file: false,
            title_banner_file: false,
            upper_category_section_file: false,
            upper_banner_file: false,
            offers_banner_file: false,
            middle_category_banner_file: false,
            lower_category_section_file: false,
            filter_section_file: false,
            lower_banner_file: false,
            partner_brands_file: false,
            video_file: false,
          }
          break;
        case 'export':
          this.state = {
            meta_description: null,
            page_title: null,
            top_content: null,
            banner: {
              desktop: [],
              mobile: [],
            },
            webBannerCnt: 1,
            mobileBannerCnt: 1,
            video: [],
            videoCount: 1,
            categories:[],
          }
          break
        default:
          console.log("error");
      }
    }
    new_data = {};
    post_data = {};
    if (this.props.content.tag === 'segments') {
      this.mainBannerWeb = React.createRef();
    }
    if (this.props.content.tag === 'categories') {
      this.mainBannerWeb = React.createRef();
      this.mainBannerMobile = React.createRef();
      this.mainBannerPortrait = React.createRef();
    }
    if (this.props.content.tag === 'homepage' || this.props.content.tag === 'export') {
      this.mainBannerWeb = React.createRef();
      this.mainBannerMobile = React.createRef();
    }
    if (this.props.content.tag === 'collection') {
      this.mainBannerWeb = React.createRef();
      this.mainBannerMobile = React.createRef();
      this.subBannerWeb = React.createRef();
      this.subBannerMobile = React.createRef();
    }
    if (this.props.content.tag === 'enquiry') {
      this.videoThumbnail = React.createRef();
      this.mainBannerWeb = React.createRef();
      this.mainBannerMobile = React.createRef();
    }
    if (this.props.content.tag === 'brands' ) {
      this.brandIcon = React.createRef();
      this.altImg = React.createRef();
      this.mainBannerWeb = React.createRef();
      this.mainBannerMobile = React.createRef();
    }
    if (this.props.content.tag === 'carPage') {
      this.topBannerDesktop = React.createRef();
      this.topBannerMobile = React.createRef();
      this.bottomBannerDesktop = React.createRef();
      this.bottomBannerMobile = React.createRef();
    }
    // if (this.props.content.tag === 'searchbysku' && this.props.content.type === 'edit') {
    //   this.videoThumbnail = React.createRef();
    // }

    if (this.props.content.type === "editimages") {
      if (props.content.tag === "searchbysku") {
        old_images = [];
        new_images = [];
        new_data = {};
        var imagesX = [];
        var tempData = this.props.content.content;
        tempData["images1"] = old_images;
        tempData["master_image_url"] = master_image_url;
        if (tempData.variants.images) {
          imagesX = tempData.variants.images;
        }
        for (var i = 0; i < imagesX.length; i++) {
          if (i === 0) {
            master_image_url = imagesX[i];
          } else {
            old_images.push(imagesX[i]);
          }
        }
        new_data["sku_code"] = tempData.sku_code;
        this.state = {
          master_image_url: master_image_url,
          sku_code: tempData.sku_code,
          images1: old_images,
        };
      }
    } else if (this.props.content.type === "edit") {
      if (this.props.content.tag === "searchbysku") {
        var dData = {
          ...this.props.content.content,
          sku_codes: this.props.content.content.frequently_bought_together
            ? this.props.content.content.frequently_bought_together.map((item) => item.product_id)
            : [],
          collection_products: this.props.content.content.frequently_bought_together || [],
          faq: this.props.content.content.faq || [],
          faqCount: this.props.content.content.faq ? this.props.content.content.faq.length : 1,
          reviews: this.props.content.content.reviews || [],
          reviewCount: this.props.content.content.reviews ? this.props.content.content.reviews.length : 1,
          video: this.props.content.content.video || [],
          videoCount: this.props.content.content.video ? this.props.content.content.video.length : 0,
        };
        if (
          dData["availability_status"] &&
          dData["availability_status"] !== 0 &&
          dData["availability_status"] !== 1
        ) {
          dData["availability_status"] =
            dData.availability_status.toUpperCase() === "OUT OF STOCK" ? 0 : 1;
        }
        this.state = dData;
        new_data["sku_code"] = this.state.sku_code;
      } else if (this.props.content.tag === "rails") {
        this.state = {
          ...this.props.content.content,
          name: this.props.content.content
            ? this.props.content.content.name
            : "",
          product_ids_string: this.props.content.content.product_ids
            ? this.props.content.content.product_ids.join(",")
            : "",
          ex_product_ids_string: this.props.content.content.exclude_product_ids
            ? this.props.content.content.exclude_product_ids.join(",")
            : "",
          maximum_price: this.props.content.content
            ? this.props.content.content.maximum_price
            : "",
          minimum_price: this.props.content.content
            ? this.props.content.content.minimum_price
            : "",
          maximum_rating: this.props.content.content
          ? this.props.content.content.maximum_rating
          : "",
          minimum_rating: this.props.content.content
          ? this.props.content.content.minimum_rating
          : "",
          priority_order: this.props.content.content
          ? this.props.content.content.priority_order
          : "",
          product_specific_ids_string: this.props.content.content.product_specific_ids
          ? this.props.content.content.product_specific_ids.join(",")
          : "",
          selectedCategories: [],
          excludedCategories: [],
          selectedBrands: [],
          excludedBrands: [],
          selectedTags: [],
          dynamicPricing: this.props.content.content.dynamic_price || 0,
        };
      } else if (this.props.content.tag === "feed") {
        this.state = {
          ...this.props.content.content,
          product_ids_string: this.props.content.content.product_ids
            ? this.props.content.content.product_ids.join(",")
            : "",
          ex_product_ids_string: this.props.content.content.exclude_product_ids
            ? this.props.content.content.exclude_product_ids.join(",")
            : "",
          maximum_price: this.props.content.content
            ? this.props.content.content.maximum_price
            : "",
          minimum_price: this.props.content.content
            ? this.props.content.content.minimum_price
            : "",
          maximum_rating: this.props.content.content
            ? this.props.content.content.max_customer_ratings
            : "",
          minimum_rating: this.props.content.content
            ? this.props.content.content.min_customer_ratings
            : "",
          selectedCategories: [],
          excludedCategories: [],
          selectedBrands: [],
          excludedBrands: [],
          selectedTags: [],
          excludedTags: [],
          applicable_on:
            this.props.content.content &&
            this.props.content.content.applicable_on
              ? this.props.content.content.applicable_on.map((el) => {
                  return { name: el };
                })
              : [],
          fetchedProducts: [],
        }
      } else if (this.props.content.tag === "segments") {
        this.state = {
          ...this.props.content.content,
          priceList: [],
          popularCategory: [],
          partnerBrands: [],
          banner: this.props.content.content.banner || {
            desktop: [],
            mobile: [],
          },
          webBannerCnt: this.props.content.content.banner && this.props.content.content.banner.desktop
            ? this.props.content.content.banner.desktop.length : 1,
          testimonials: this.props.content.content.testimonials || [],
          faq: this.props.content.content.faq || [],
          testimonialCount: this.props.content.content.testimonials ? this.props.content.content.testimonials.length : 1,
          faqCount: this.props.content.content.faq ? this.props.content.content.faq.length : 1,
        }
      } else if (this.props.content.tag === "categories") {
        this.state = {
          ...this.props.content.content,
          parent_category_name: this.props.content.content.hierarchy && this.props.content.content.hierarchy[1]
            ? this.props.content.content.hierarchy[1].name : null,
          priceList: [],
          popularProducts: [],
          partnerBrands: [],
          promotional_banners: this.props.content.promotional_banners || [],
          banner: this.props.content.content.banner || {
            web: [],
            mobile: [],
          },
          webBannerCnt: this.props.content.content.banner && this.props.content.content.banner.web
            ? this.props.content.content.banner.web.length : 1,
          mobileBannerCnt: this.props.content.content.banner && this.props.content.content.banner.mobile
            ? this.props.content.content.banner.mobile.length : 1,
          testimonials: this.props.content.content.testimonials || [],
          faq: this.props.content.content.faq || [],
          attributes: this.props.content.content.attributes || [],
          testimonialCount: this.props.content.content.testimonials ? this.props.content.content.testimonials.length : 1,
          faqCount: this.props.content.content.faq ? this.props.content.content.faq.length : 1,
          pbannerCount: this.props.content.content.promotional_banners ? this.props.content.content.promotional_banners.length : 1,
        }
      } else if (this.props.content.tag === "homepage") {
        this.state = {
          ...this.props.content.content,
          upper_banner_desktopCnt: this.props.content.content?.upper_banner?.desktop?.length || 1,
          upper_banner_mobileCnt: this.props.content.content?.upper_banner?.mobile?.length || 1,
          promotional_upper_banner_desktopCnt: this.props.content.content?.promotional_upper_banner?.desktop?.length || 1,
          promotional_upper_banner_mobileCnt: this.props.content.content?.promotional_upper_banner?.mobile?.length || 1,
          collection_page_banner_desktopCnt: this.props.content.content?.collection_page_banner?.desktop?.length || 1,
          collection_page_banner_mobileCnt: this.props.content.content?.collection_page_banner?.mobile?.length || 1,
          sponsered_brands: {
            ...this.props.content.content.sponsered_brands,
            count: this.props.content.content?.sponsered_brands?.cms_data?.length || 1,
          },
          unsponsered_brands: {
            ...this.props.content.content.unsponsered_brands,
            count: this.props.content.content?.unsponsered_brands?.cms_data?.length || 1,
          },
          top_categories: {
            ...this.props.content.content.top_categories,
            count: this.props.content.content?.top_categories?.cms_data?.length || 1,
          },
          remaining_categories: {
            ...this.props.content.content.remaining_categories,
            count: this.props.content.content?.remaining_categories?.cms_data?.length || 1,
          },
          filter_section: {
            ...this.props.content.content.filter_section,
            count: this.props.content.content?.filter_section?.data?.length || 1,
          },
          products_list: {
            ...this.props.content.content.products_list,
            count: this.props.content.content?.products_list?.cms_data?.length || 1,
          },
          upper_video_section: {
            ...this.props.content.content.upper_video_section,
            count: this.props.content.content?.upper_video_section?.data?.length || 1,
          },
          lower_video_section: {
            ...this.props.content.content.lower_video_section,
            count: this.props.content.content?.lower_video_section?.data?.length || 1,
          },
          dotd: {
            ...this.props.content.content.dotd,
            count: this.props.content.content?.dotd?.cms_data?.length || 1,
          },
          testimonials: this.props.content.content.testimonials || [],
          faq: this.props.content.content.faq || [],
          testimonialCount: this.props.content.content.testimonials ? this.props.content.content.testimonials.length : 1,
          faqCount: this.props.content.content.faq ? this.props.content.content.faq.length : 1,
        }
      } else if (this.props.content.tag === "enquiry") {
        this.state = {
          ...this.props.content.content,
          brands: [],
          popular_category_images: this.props.content.content.popular_category_images || [],
          categoryCount: this.props.content.content.popular_category_images
            ? this.props.content.content.popular_category_images.length : 1,
          customer_testimonials: this.props.content.content.customer_testimonials,
          heading_testimonials: this.props.content.content.heading_testimonials || [],
          faq: this.props.content.content.faq || [],
          testimonialCount: this.props.content.content.customer_testimonials
          ? this.props.content.content.customer_testimonials.length : 1,
          heading_testimonialCount: this.props.content.content.heading_testimonials
          ? this.props.content.content.heading_testimonials.length : 1,
          storeImgCount: this.props.content.content.store_data && this.props.content.content.store_data.store_images
          ? this.props.content.content.store_data.store_images.length : 1,
          faqCount:  this.props.content.content.faq
          ? this.props.content.content.faq.length : 1,
        }
      } else if (this.props.content.tag === "collection"){
        this.state = {
          ...this.props.content.content,
          banner: this.props.content.content.banner || {},
          sub_banner: this.props.content.content.sub_banner || {},
          bottom_content: this.props.content.content.bottom_content || null,
          faq: this.props.content.content.faq || [],
          faqCount: this.props.content.content.faq ? this.props.content.content.faq.length : 1,
        }
      } else if (this.props.content.tag === "brands"){
        this.state = {
          ...this.props.content.content,
          banner: this.props.content.content.banner || {
            desktop: [],
            mobile: [],
          },
          webBannerCnt: this.props.content.content.banner && this.props.content.content.banner.desktop
            ? this.props.content.content.banner.desktop.length : 1,
          mobileBannerCnt: this.props.content.content.banner && this.props.content.content.banner.mobile
            ? this.props.content.content.banner.mobile.length : 1,
          // testimonials: [],
          faq: this.props.content.content.faq || [],
          // testimonialCount: 1,
          faqCount: this.props.content.content.faq ? this.props.content.content.faq.length : 1,
          priceList: [],
          video: this.props.content.content.video || {},
        }
      } else if (this.props.content.tag === "carPage") {
        this.state = {
          ...this.props.content.content,
          meta_description: this.props.content.content ? this.props.content.content.meta_description : null,
          page_title: this.props.content.content ? this.props.content.content.page_title : null,
          top_banner: this.props.content.content && this.props.content.content.top_banner ? this.props.content.content.top_banner : {},
          bottom_banner: this.props.content.content && this.props.content.content.bottom_banner ? this.props.content.content.bottom_banner : {},
          top_content: this.props.content.content ? this.props.content.content.top_content : null,
          bottom_content: this.props.content.content ? this.props.content.content.bottom_content : null,
          faq: this.props.content.content && this.props.content.content.faq ? this.props.content.content.faq : [],
          faqCount: this.props.content.content && this.props.content.content.faq ? this.props.content.content.faq.length : [],
        }
      } else if (this.props.content.tag === "collectionv2") {
        this.state = this.props.content.content ? {
          ...this.props.content.content,
          basic_details: this.props.content.content.page_title || this.props.content.content.meta_description || this.props.content.content.name,
          base_file: false,
          title_banner_file: false,
          upper_category_section_file: false,
          upper_banner_file: false,
          offers_banner_file: false,
          middle_category_banner_file: false,
          lower_category_section_file: false,
          filter_section_file: false,
          lower_banner_file: false,
          partner_brands_file: false,
          video_file: false,
        } : {
          basic_details: false,
          title_banner: false,
          upper_category_section: false,
          upper_banner: false,
          offers_banner: false,
          middle_category_banner: false,
          lower_category_section: false,
          filter_section: false,
          lower_banner: false,
          partner_brands: false,
          video: false,
          base_file: false,
          title_banner_file: false,
          upper_category_section_file: false,
          upper_banner_file: false,
          offers_banner_file: false,
          middle_category_banner_file: false,
          lower_category_section_file: false,
          filter_section_file: false,
          lower_banner_file: false,
          partner_brands_file: false,
          video_file: false,
        }
      } else if (this.props.content.tag === "export") {
        this.state = {
          ...this.props.content.content,
          meta_description: this.props.content.content ? this.props.content.content.meta_description : null,
          page_title: this.props.content.content ? this.props.content.content.page_title : null,
          banner: this.props.content.content && this.props.content.content.banner ? this.props.content.content.banner : {},
          webBannerCnt: this.props.content.content.banner && this.props.content.content.banner.desktop
            ? this.props.content.content.banner.desktop.length : 1,
          mobileBannerCnt: this.props.content.content.banner && this.props.content.content.banner.mobile
          ? this.props.content.content.banner.mobile.length : 1,
          top_content: this.props.content.content ? this.props.content.content.top_content : null,
          video: this.props.content.content ? this.props.content.content.video : [],
          videoCount: this.props.content.content.video && this.props.content.content.video.length
          ? this.props.content.content.video.length : 1,
          categories: this.props.content.categories ? this.props.content.content.categories : [],
        }
      }else {
        this.state = this.props.content.content ? {
          ...this.props.content.content,
        } : {};
      }
    } else if (this.props.content.type === "addRelated") {
      if (this.props.content.tag === "searchbysku") {
        this.state = {
          similarProducts: [],
          customerLike: [],
          recommendedProducts: [],
          product_id: this.props.content.content.product_id,
          selectedSimilarProducts: [],
          selectedCustomerLike: [],
          selectedRecommendedProducts: [],
        };
      }
    }
  
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeMaster = this.onChangeMaster.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.getTestimonialList = this.getTestimonialList.bind(this);
    this.getProductReviewList = this.getProductReviewList.bind(this);
    this.removeTestimonial = this.removeTestimonial.bind(this);
    this.getFAQList = this.getFAQList.bind(this);
    this.removeFAQ = this.removeFAQ.bind(this);
    this.getPromotionaBannerList = this.getPromotionaBannerList.bind(this);
    this.removePromotionalBanner = this.removePromotionalBanner.bind(this);
    this.handleObjectkeyChange = this.handleObjectkeyChange.bind(this);
    this.handleArraykeyChange = this.handleArraykeyChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onInputImage = this.onInputImage.bind(this);
    this.uploadImageOnServer = this.uploadImageOnServer.bind(this);
    this.uploadPromotionalBanner = this.uploadPromotionalBanner.bind(this);
    this.getStoreImgList = this.getStoreImgList.bind(this);
    this.removeStoreImg = this.removeStoreImg.bind(this);
    this.getPopularCategoryImgList = this.getPopularCategoryImgList.bind(this);
    this.removePopularCategoryImg = this.removePopularCategoryImg.bind(this);
    this.getBannerList = this.getBannerList.bind(this);
    this.removeBannerImg = this.removeBannerImg.bind(this);
    this.deleteBannerImage = this.deleteBannerImage.bind(this);
    this.uploadBannerImageOnServer = this.uploadBannerImageOnServer.bind(this);
    this.handleBannerImgkeyChange = this.handleBannerImgkeyChange.bind(this);
    this.uploadCollectionFile = this.uploadCollectionFile.bind(this);
    this.onFileInput = this.onFileInput.bind(this);
    this.getProductVideoList = this.getProductVideoList.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
    this.getHomePageBrandInput = this.getHomePageBrandInput.bind(this);
    this.getHomePageCategoryInput = this.getHomePageCategoryInput.bind(this);
    this.getHomePageProductInput = this.getHomePageProductInput.bind(this);
    this.getHomePageVideo = this.getHomePageVideo.bind(this);
    this.uploadImageOnServer2 = this.uploadImageOnServer2.bind(this);
    this.getHomePageDotdInput = this.getHomePageDotdInput.bind(this);
    this.getHomePageFilterInput = this.getHomePageFilterInput.bind(this);
  }

  getValueFromPath(obj, path) {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, '');           // strip a leading dot
    var a = path.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (obj === Object(obj) && k in obj) {
          obj = obj[k];
        } else {
            return;
        }
    }
    return obj;
  };

  componentDidMount() {
    if ( this.props.content.tag === 'collection' && this.props.content.type !== "add") {
      this.getCollectionProduct()
    } else if (this.props.content.tag === 'segments' && this.props.content.type !== "editimages") {
      if (this.props.content.type !== "add" && this.state.slug) {
        API.searchOrFilterAccessoriesCategory('filter', this.state.slug)
        .then((response) => {
          const tempList = [];
          if (this.state.popular_category_ids &&  this.state.popular_category_ids.length) {
            this.state.popular_category_ids.forEach(
              (item) => {
                const obj = response.data.data.find(element => element._id === item);
                if (obj) tempList.push(obj);
              }
            )
          }
          this.setState({ 
            segmentCategories: response.data.data || [],
            popularCategory: tempList,
          });
        }).catch((err) => console.error(err));

        API.getAccessoriesCategoryProducts(null, this.state.slug).then((res) => {
          const tempList = [];
          if (this.state.pricelist_product_ids &&  this.state.pricelist_product_ids.length) {
            this.state.pricelist_product_ids.forEach(
              (item) => {
                const obj = res.data.data.product_data.find(element => element.product_id === item);
                if (obj) tempList.push(obj);
              }
            )
          }
          this.setState({ 
            segmentProducts: res.data.data.product_data,
            priceList: tempList,
          });
        })
      }
      API.getAllAccessoriesBrandList(1)
      .then((response) => {
        const tempList = [];
        if (this.state.partner_brand_ids &&  this.state.partner_brand_ids.length) {
          this.state.partner_brand_ids.forEach(
            (item) => {
              const obj = response.data.data.find(element => element._id === item);
              if (obj) tempList.push(obj);
            }
          )
        }
        this.setState({
          brandList: response.data.data,
          partnerBrands: tempList,
        });
      })
      .catch((err) => console.error(err))
    } else if (this.props.content.tag === 'searchbysku' && this.props.content.type !== "editimages") {
      if (this.props.content.type === 'addRelated') {
        API.getAccessoriesProductRelated(this.state.product_id)
          .then((response) => {
            this.setState({
              similarProducts: response.data.data.similar_products,
              customerLike: response.data.data.customer_liked,
              recommendedProducts: response.data.data.recommendations,
              selectedCustomerLike: response.data.data.customer_liked.filter(
                (item) => item.selected
              ),
              selectedRecommendedProducts:
                response.data.data.recommendations.filter(
                  (item) => item.selected
                ),
              selectedSimilarProducts:
                response.data.data.similar_products.filter(
                  (item) => item.selected
                ),
            });
          })
          .catch((err) => console.error(err));
      } else {
        if (this.props.content.type !== "add" && this.state.variants && this.state.category_name
          && !this.state.variants.attributes) {
            API.getProductAttributes(this.state.category_name).then((response) => {
              if (response.data.data) {
                const tempAttr = response.data.data;
                const tempState = { ...this.state };
                tempState.variants.attributes = {};
                for(let i = 0; i < tempAttr.length; i += 1) {
                  tempState.variants.attributes[tempAttr[i]] = '';
                }
                this.setState(tempState);
              }
            }).catch((error) => {
              console.log("Cant fetch attributes data");
              NotificationManager.error(
                error.response && error.response.data
                  ? error.response.data.message
                  : 'Cant fetch attributes data',
                'Error!!',
                3000
              )
            });
          }
        if (!localStorage.getItem("accessories_categories")) {
          API.getAllAccessoriesCategoryList()
            .then((response) => {
              localStorage.setItem(
                "accessories_categories",
                JSON.stringify(response.data.data)
              );
              this.setState({ categoriesList: response.data.data });
            })
            .catch((err) => console.error(err));
        } else {
          this.setState({
            categoriesList: JSON.parse(
              localStorage.getItem("accessories_categories")
            ),
          });
        }
        if (!localStorage.getItem("accessories_brands")) {
          API.getAllAccessoriesBrandList()
            .then((response) => {
              localStorage.setItem(
                "accessories_brands",
                JSON.stringify(response.data.data)
              );
              this.setState({ brandList: response.data.data });
            })
            .catch((err) => console.error(err));
        } else {
          this.setState({
            brandList: JSON.parse(localStorage.getItem("accessories_brands")),
          });
        }
        API.getAccProductCityInventory(this.state.variants.sku_code)
          .then((response) => {
            this.setState({
              invenoryList: response.data.data.data,
              cityList: response.data.data.cities,
              acc_inventory_cities: [],
            });
          })
          .catch((err) => console.error(err));
      }
    } else if (this.props.content.tag === 'categories' && this.props.content.type !== "editimages") {
      API.getAllAccessoriesCategoryList(1)
      .then((response) => {

        this.setState({categoriesList: response.data.data})
      })
      .catch((err) => console.error(err))

      API.getAllAccessoriesBrandList(1)
      .then((response) => {
        const tempList1 = [];
        if (this.state.partner_brand_ids &&  this.state.partner_brand_ids.length) {
          this.state.partner_brand_ids.forEach(
            (item) => {
              const obj = response.data.data.find(element => element._id === item);
              if (obj) tempList1.push(obj);
            }
          )
        }
        this.setState({
          brandList: response.data.data,
          partnerBrands: tempList1,
        });
      })
      .catch((err) => console.error(err))

      if (this.props.content.type !== "add" && this.state.slug) {
        API.getAccessoriesCategoryProducts(this.state.slug).then((res) => {
          const tempList1 = [], tempList2= [];
          if (this.state.listing_product_ids &&  this.state.listing_product_ids.length) {
            this.state.listing_product_ids.forEach(
              (item) => {
                const obj = res.data.data.product_data.find(element => element.product_id === item);
                if (obj) tempList1.push(obj);
              }
            )
          }
          if (this.state.pricelist_product_ids &&  this.state.pricelist_product_ids.length) {
            this.state.pricelist_product_ids.forEach(
              (item) => {
                const obj = res.data.data.product_data.find(element => element.product_id === item);
                if (obj) tempList2.push(obj);
              }
            )
          }
          this.setState({
            categoryProducts: res.data.data.product_data,
            popularProducts: tempList1,
            priceList: tempList2,
          });
        })
      }
    } else if (
      this.props.content.tag === "coupons" ||
      this.props.content.tag === "rails" ||
      this.props.content.tag === "feed"
    ) {
      if (!localStorage.getItem("all_accessories_categories")) {
        API.getAllAccessoriesCategoryList(1)
          .then((response) => {
            localStorage.setItem(
              "all_accessories_categories",
              JSON.stringify(response.data.data)
            );
            let tempList1 = [],
              tempList2 = [];
            if (
              (this.props.content.tag === "rails" ||
                this.props.content.tag === "feed") &&
              this.props.content.type === "edit"
            ) {
              if (
                this.state.category_slugs &&
                this.state.category_slugs.length
              ) {
                this.state.category_slugs.forEach((item) => {
                  const obj = response.data.data.find(
                    (element) => element.slug === item
                  );
                  if (obj) tempList1.push(obj);
                });
              }
              if (
                this.state.exclude_category_slugs &&
                this.state.exclude_category_slugs.length
              ) {
                this.state.exclude_category_slugs.forEach((item) => {
                  const obj = response.data.data.find(
                    (element) => element.slug === item
                  );
                  if (obj) tempList2.push(obj);
                });
              }
            }
            this.setState({
              categoriesList: response.data.data,
              selectedCategories: tempList1,
              excludedCategories: tempList2,
            });
          })
          .catch((err) => console.error(err));
      } else {
        const allCatList = JSON.parse(
          localStorage.getItem("all_accessories_categories")
        );
        let tempList1 = [],
          tempList2 = [];
        if (
          (this.props.content.tag === "rails" ||
            this.props.content.tag === "feed") &&
          this.props.content.type === "edit"
        ) {
          if (this.state.category_slugs && this.state.category_slugs.length) {
            this.state.category_slugs.forEach((item) => {
              const obj = allCatList.find((element) => element.slug === item);
              if (obj) tempList1.push(obj);
            });
          }
          if (
            this.state.exclude_category_slugs &&
            this.state.exclude_category_slugs.length
          ) {
            this.state.exclude_category_slugs.forEach((item) => {
              const obj = allCatList.find((element) => element.slug === item);
              if (obj) tempList2.push(obj);
            });
          }
        }
        this.setState({
          categoriesList: allCatList,
          selectedCategories: tempList1,
          excludedCategories: tempList2,
        });
      }
      if (!localStorage.getItem("accessories_brands")) {
        API.getAllAccessoriesBrandList()
          .then((response) => {
            localStorage.setItem(
              "accessories_brands",
              JSON.stringify(response.data.data)
            );
            let tempList1 = [],
              tempList2 = [];
            if (
              (this.props.content.tag === "rails" ||
                this.props.content.tag === "feed") &&
              this.props.content.type === "edit"
            ) {
              if (
                this.state.product_brand_names &&
                this.state.product_brand_names.length
              ) {
                this.state.product_brand_names.forEach((item) => {
                  const obj = response.data.data.find(
                    (element) => element.slug === item
                  );
                  if (obj) tempList1.push(obj);
                });
              }
              if (
                this.state.exclude_product_brand_names &&
                this.state.exclude_product_brand_names.length
              ) {
                this.state.exclude_product_brand_names.forEach((item) => {
                  const obj = response.data.data.find(
                    (element) => element.slug === item
                  );
                  if (obj) tempList2.push(obj);
                });
              }
            }
            this.setState({
              brandList: response.data.data,
              selectedBrands: tempList1,
              excludedBrands: tempList2,
            });
          })
          .catch((err) => console.error(err));
      } else {
        const allBrandList = JSON.parse(
          localStorage.getItem("accessories_brands")
        );
        let tempList1 = [],
          tempList2 = [];
        if (
          (this.props.content.tag === "rails" ||
            this.props.content.tag === "feed") &&
          this.props.content.type === "edit"
        ) {
          if (
            this.state.product_brand_names &&
            this.state.product_brand_names.length
          ) {
            this.state.product_brand_names.forEach((item) => {
              const obj = allBrandList.find((element) => element.slug === item);
              if (obj) tempList1.push(obj);
            });
          }
          if (
            this.state.exclude_product_brand_names &&
            this.state.exclude_product_brand_names.length
          ) {
            this.state.exclude_product_brand_names.forEach((item) => {
              const obj = allBrandList.find((element) => element.slug === item);
              if (obj) tempList2.push(obj);
            });
          }
        }
        this.setState({
          brandList: allBrandList,
          selectedBrands: tempList1,
          excludedBrands: tempList2,
        });
      }
    }
    if (
      this.props.content.tag === "rails" ||
      this.props.content.tag === "feed"
    ) {
      API.getAllTagsList()
        .then((response) => {
          const tempList = response.data.data.map((tag) => {
            return { name: tag };
          });
          let tempList2 = [],
            tempList3 = [];
          if (this.state.product_tags && this.state.product_tags.length) {
            this.state.product_tags.forEach((item) => {
              const obj = tempList.find((element) => element.name === item);
              if (obj) tempList2.push(obj);
            });
          }
          if (
            this.state.exclude_product_tags &&
            this.state.exclude_product_tags.length
          ) {
            this.state.exclude_product_tags.forEach((item) => {
              const obj = tempList.find((element) => element.name === item);
              if (obj) tempList3.push(obj);
            });
          }
          this.setState({
            tagsList: tempList,
            selectedTags: tempList2,
            excludedTags: tempList3,
          });
        })
        .catch((err) => console.log(err));
    }
    if (this.props.content.tag === "homepage") {
      API.getAllAccessoriesCategoryList()
      .then((response) => {
        const tempList1 = [];
        if (this.state.trending_category_ids && this.state.trending_category_ids.length) {
          this.state.trending_category_ids.forEach(
            (item) => {
              const obj = response.data.data.find(element => element._id === item);
              if (obj) tempList1.push(obj);
            }
          )
        }
        this.setState({
          categoriesList: response.data.data,
          trendingCategory: tempList1,
        })
      })
      .catch((err) => console.error(err))

      API.getAllAccessoriesBrandList(1)
      .then((response) => {
        const tempList1 = [];
        if (this.state.partner_brand_ids &&  this.state.partner_brand_ids.length) {
          this.state.partner_brand_ids.forEach(
            (item) => {
              const obj = response.data.data.find(element => element._id === item);
              if (obj) tempList1.push(obj);
            }
          )
        }
        this.setState({
          brandList: response.data.data,
          partnerBrands: tempList1,
        });
      })
      .catch((err) => console.error(err))

      API.getAllCarBrandsModelsList(1).then((response) => {
        const tempList1 = [], tempList2 = [];
        if (this.state.top_car_brand_ids &&  this.state.top_car_brand_ids.length) {
          this.state.top_car_brand_ids.forEach(
            (item) => {
              const obj = response.data.data.brands.find(element => element.id === item);
              if (obj) tempList1.push(obj);
            }
          )
        }
        if (this.state.top_car_model_ids &&  this.state.top_car_model_ids.length) {
          this.state.top_car_model_ids.forEach(
            (item) => {
              const obj = response.data.data.models.find(element => element.id === item);
              if (obj) tempList2.push(obj);
            }
          )
        }
        this.setState({
          carBrandsModels: response.data.data,
          topCarBrands: tempList1,
          topCarModels: tempList2,
        });
      });
    }
    if (this.props.content.tag === "enquiry") {
      API.getAllAccessoriesBrandList(1)
      .then((response) => {
        const tempList = [];
        if (this.state.brand_ids &&  this.state.brand_ids.length) {
          this.state.brand_ids.forEach(
            (item) => {
              const obj = response.data.data.find(element => element._id === item);
              if (obj) tempList.push(obj);
            }
          )
        }
        this.setState({
          brandList: response.data.data,
          brands: tempList,
        });
      })
      .catch((err) => console.error(err))
    }
    if (this.props.content.tag === "carPage" && this.props.content.type === "add") {
      API.getAllCarBrandsModelsList(1).then((response) => {
        this.setState({
          carBrandList: response.data.data.brands,
        });
      }).catch(err => {
        NotificationManager.error(
          "Error in getting car brand list.",
          "Error!!",
          5000
        );
        this.props.onMiddleExtraButtonClick('close', null);
      });
    }
    if (this.props.content.tag === "brands" && this.state.slug) {
      API.getAccessoriesCategoryProducts(null, null, null, this.state.slug).then((res) => {
        const tempList1 = []
        if (this.state.pricelist_product_ids &&  this.state.pricelist_product_ids.length) {
          this.state.pricelist_product_ids.forEach(
            (item) => {
              const obj = res.data.data.product_data.find(element => element.product_id === item);
              if (obj) tempList1.push(obj);
            }
          )
        }
        this.setState({
          brandProducts: res.data.data.product_data,
          priceList: tempList1,
        });
      })
    }
    if (this.props.content.tag === 'export') {
      API.getAllAccessoriesCategoryList(0)
      .then((response) => {
        this.setState({categoriesList: response.data.data});
      })
      .catch((err) => console.error(err))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.content.tag === "carPage" && this.state.selectedCarBrand && this.props.content.type === "add"
    &&  (!this.state.carModelList || prevState.selectedCarBrand.name !== this.state.selectedCarBrand.name)) {
      API.getCarModelFromBrand(this.state.selectedCarBrand.id).then((res) => {
        this.setState({
          carModelList: res.data.data,
        });
      }).catch(err => {
        NotificationManager.error(
          "Error in getting car model list.",
          "Error!!",
          5000
        );
        this.props.onMiddleExtraButtonClick('close', null);
      });
    }
  }

  onChange(e) {
    var images = this.state.images1;
    new_images.push(e.target.files[0]);
    images.push(URL.createObjectURL(e.target.files[0]));
    this.setState({ images1: images });
  }

  onFileInput(e) {
    const temp = {}
    temp[e.target.name] = e.target.files[0]
    this.setState({ ...temp })
  }

  onChangeForCollection(e, type) {
    if (new_images.length < 1) {
      new_images = [
        {
          web_banner: null,
          mobile_banner: null,
          redirect_url: null,
        },
      ];
    }
    switch (type) {
      case "web":
        new_images[0].web_banner = e.target.files[0];
        break;
      case "mobile":
        new_images[0].mobile_banner = e.target.files[0];
        break;
      case "url":
        new_images[0].redirect_url = e.target.value;
        break;
      default:
        console.error("no case match");
    }
  }

  onChangeMaster(e) {
    master_image = e.target.files[0];
    master_image_url = null;
    this.setState({ master_image_url: URL.createObjectURL(e.target.files[0]) });
  }

  onDeleteImage(type, url) {
    var that = this;
    API.deleteAccessoriesVariantImage({
      sku_code: this.state.sku_code,
      image_url: url,
    })
      .then(function (response) {
        if (response.data.status) {
          that.props.onMiddleExtraButtonClick(type, that.state, null);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleButtonClick(type, data) {
    var that = this;
    if (type === "save") {
      if (this.props.content.type === "editimages") {
        if (this.props.content.tag === "searchbysku") {
          API.updateAccessoriesVariantImages(
            that.state.sku_code,
            new_images,
            master_image
          )
            .then(function (response) {
              if (response.data.status) {
                that.props.onMiddleExtraButtonClick(
                  type,
                  that.state,
                  that.props.selectedRow
                );
              }
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
        }
      } else if (this.props.content.type === "edit") {
        if (this.props.content.tag === "searchbysku") {
          if (new_data && (new_data.sku_update || new_data.product_update)) {
            if (
              this.state.variants &&
              this.state.variants.mrp &&
              this.state.variants.price &&
              Number(this.state.variants.mrp) <=
                Number(this.state.variants.price)
            ) {
              NotificationManager.error(
                "MRP should be > Price.",
                "Error!!",
                5000
              );
              return;
            }
            API.updateAccessoriesProduct({
              ...new_data,
              sku_code: this.state.variants.sku_code,
            })
              .then(() => {
                NotificationManager.success(
                  "Product SKU updated successfully.",
                  "Success",
                  5000
                );
                this.props.onMiddleExtraButtonClick("save", null);
              })
              .catch((err) => {
                console.error(err);
              });
          }
          if (
            this.state.acc_inventory_cities &&
            this.state.acc_inventory_cities.length > 0
          ) {
            const cities = this.state.acc_inventory_cities.map(
              (item) => item.id
            );
            API.createAccProductCityInventory({
              city_ids: cities,
              availability_status: 1,
              sku_code: this.state.variants.sku_code,
            })
              .then(() => {
                this.props.onMiddleExtraButtonClick("save", null);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        } else if (this.props.content.tag === "collection") {
          if (!this.state.slug || !this.state.slug.match(/^[A-Za-z0-9-]*$/)) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            return;
          }
          if (this.state.sku_codes.length < 1) {
            NotificationManager.error(
              "Please enter atleast one product.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.banner.web_banner || !this.state.banner.mobile_banner) {
            NotificationManager.error(
              "Please upload main banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.sub_banner.web_banner || !this.state.sub_banner.mobile_banner) {
            NotificationManager.error(
              "Please upload sub banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.faq || !this.state.faq.length) {
            NotificationManager.error(
              "Please upload at least 1 faq.",
              "Error!!",
              5000
            );
            return;
          }
          const postData = {
            meta_description: this.state.meta_description,
            page_title: this.state.page_title,
            name: this.state.name,
            google_category_id: this.state.google_category_id,
            product_ids: this.state.sku_codes,
            slug: this.state.slug,
            banner: this.state.banner,
            sub_banner: this.state.sub_banner,
            faq: this.state.faq,
            bottom_content: this.state.bottom_content,
            visible_on_web: this.state.visible_on_web,
          };
          API.editAccessoriesCollection(postData)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
            })
            .catch((err) => console.error(err));
        } else if (this.props.content.tag === 'homepage') {
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          API.updateAccessoriesHomePage({ ...new_data, id: this.state._id })
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Home Page updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === "segments") {
          if (!this.state.slug || !this.state.slug.match(/^[A-Za-z0-9-]*$/)) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            return;
          }
          API.updateAccessoriesSegment({ ...new_data, id: this.state._id })
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Segment updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'categories') {
          if (!this.state.slug || !this.state.slug.match(/^[A-Za-z0-9-]*$/)) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            return;
          }
          if (
            new_data.name &&
            new_data.parent_category_name &&
            new_data.name === new_data.parent_category_name
          ) {
            NotificationManager.error(
              "Category & Parent Category cannot be same.",
              "Error!!",
              5000
            );
            return;
          }
          API.updateAccessoriesSegment({ ...new_data, id: this.state._id })
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Category updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'rails') {
          if (!this.state.name || !this.state.name.trim()) {
            NotificationManager.error(
              "Please enter a valid name.",
              "Error!!",
              5000
            );
            return;
          }
          let postData = {
            id: this.state._id,
            name: this.state.name.trim(),
          };
          let removeFilterList = [];
          if (
            this.state.product_ids_string && !this.state.dynamicPricing
            && this.state.product_ids_string.trim().split(",").length > 2
          )
            postData.product_ids = this.state.product_ids_string
              .trim()
              .split(",");
          else removeFilterList.push('product_ids')

          if (
            this.state.ex_product_ids_string && !this.state.dynamicPricing
            && this.state.ex_product_ids_string.trim().split(",").length > 2
          )
            postData.exclude_product_ids = this.state.ex_product_ids_string
              .trim()
              .split(",");
          else removeFilterList.push('exclude_product_ids')

          if (this.state.maximum_price && !this.state.dynamicPricing)
            postData.maximum_price = this.state.maximum_price;
          else removeFilterList.push('maximum_price')

          if (this.state.minimum_price && !this.state.dynamicPricing)
            postData.minimum_price = this.state.minimum_price;
          else removeFilterList.push('minimum_price')

          if (
            this.state.selectedCategories && !this.state.dynamicPricing
            && this.state.selectedCategories.length
          )
            postData.category_slugs = this.state.selectedCategories.map(
              (cat) => cat.slug
            );
          else removeFilterList.push('category_slugs')
            
          if (
            this.state.excludedCategories && !this.state.dynamicPricing
            && this.state.excludedCategories.length
          )
            postData.exclude_category_slugs = this.state.excludedCategories.map(
              (cat) => cat.slug
            );
          else removeFilterList.push('exclude_category_slugs')

          if (this.state.selectedBrands && !this.state.dynamicPricing
            && this.state.selectedBrands.length
          )
            postData.product_brand_names = this.state.selectedBrands.map(
              (brand) => brand.name
            );
          else removeFilterList.push('product_brand_names')

          if (this.state.excludedBrands && !this.state.dynamicPricing
            && this.state.excludedBrands.length
          )
            postData.exclude_product_brand_names =
              this.state.excludedBrands.map((brand) => brand.name);
          else removeFilterList.push('exclude_product_brand_names')

          if (this.state.selectedTags && !this.state.dynamicPricing
            && this.state.selectedTags.length
          )
            postData.product_tags = this.state.selectedTags.map(
              (tag) => tag.name
            );
          else removeFilterList.push('product_tags')
            
          if (
            this.state.product_specific_ids_string
            && this.state.product_specific_ids_string.trim().split(",").length
          )
            postData.product_specific_ids = this.state.product_specific_ids_string
              .trim()
              .split(",");
          else removeFilterList.push('product_specific_ids')

          if (this.state.maximum_rating && !this.state.dynamicPricing)
            postData.maximum_rating = this.state.maximum_rating;
          else removeFilterList.push('maximum_rating')
            
          if (this.state.minimum_rating && !this.state.dynamicPricing)
            postData.minimum_rating = this.state.minimum_rating;
          else removeFilterList.push('minimum_rating')
            
          if (this.state.priority_order)
            postData.priority_order = this.state.priority_order;
          else removeFilterList.push('priority_order')

          if (this.state.dynamicPricing)
            postData.dynamic_price = this.state.dynamicPricing;
          else removeFilterList.push('dynamic_price')
          
          if (removeFilterList.length) postData.remove_filter = removeFilterList;
          API.updateAccessoriesRails(postData)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Rails updated successfully.",
                "Success",
                5000
              );
            })
            .catch((err) => {
              console.error(err);
              NotificationManager.error(err.message, "Error!!", 5000);
            });
        } else if (this.props.content.tag === 'enquiry') {
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.metadata) {
            NotificationManager.error(
              "Please enter a valid meta data.",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          API.updateAccessoriesEnquiryPage({ ...new_data, enquiry_id: this.state._id })
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Enquiry Page updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'brands') {
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.meta_description) {
            NotificationManager.error(
              "Please enter a valid meta description.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.name) {
            NotificationManager.error(
              "Please enter a valid name.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.icon) {
            NotificationManager.error(
              "Please enter a valid icon.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.description) {
            NotificationManager.error(
              "Please enter a valid brand content.",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          API.updateAccessoriesBrandPage({ ...new_data, slug: this.state.slug, _id: this.state._id })
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Brand Page updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'carPage') {
          if (!this.state.top_banner || !this.state.top_banner.mobile || !this.state.top_banner.desktop) {
            NotificationManager.error(
              "Please upload a valid top banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.bottom_banner || !this.state.bottom_banner.mobile || !this.state.bottom_banner.desktop) {
            NotificationManager.error(
              "Please upload a valid bottom banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.meta_description) {
            NotificationManager.error(
              "Please enter a valid meta description.",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          const data = {
            _id: this.state._id,
            top_banner: this.state.top_banner,
            bottom_banner: this.state.bottom_banner,
            page_title: this.state.page_title,
            meta_description: this.state.meta_description,
            top_content: this.state.top_content,
            bottom_content: this.state.bottom_content,
            faq: this.state.faq,
            brand_slug: this.state.brand_slug,
            brand_name: this.state.brand_name,
            ...(this.state.model_slug ? {model_slug: this.state.model_slug} : {}),
            ...(this.state.model_name ? {model_name: this.state.model_name} : {}),
          }
          API.updateAccessoriesCarPage(data)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Car Page updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'reviews') {
          if (typeof (this.state.is_visible) === 'undefined') {
            NotificationManager.error(
              "Please enter a valid visble state.",
              "Error!!",
              5000
            );
            return;
          }
          API.updateAccessoriesReview({ _id: this.state._id, is_visible: this.state.is_visible }).then(() => {
            this.props.onMiddleExtraButtonClick(type, null);
            NotificationManager.success(
              "Review updated successfully.",
              "Success",
              5000
            );
          })
          .catch(err => {
            console.error(err);
            NotificationManager.error(err.response.data.message, "Error", 5000);
          })
        } else if (this.props.content.tag === "collectionv2") {
          // if (!this.state.base_file) {
          //   NotificationManager.error(
          //     "Please enter a valid base file from Edit button in basic detail section.",
          //     "Error!!",
          //     5000
          //   );
          //   return;
          // }
          const formdata = new FormData()
          if (this.state.base_file) formdata.append('base_file', this.state.base_file)
          if (this.state.title_banner_file) formdata.append('title_banner_file', this.state.title_banner_file)
          if (this.state.upper_category_section_file) formdata.append('upper_category_section_file', this.state.upper_category_section_file)
          if (this.state.upper_banner_file) formdata.append('upper_banner_file', this.state.upper_banner_file)
          if (this.state.offers_banner_file) formdata.append('offers_banner_file', this.state.offers_banner_file)
          if (this.state.middle_category_banner_file) formdata.append('middle_category_banner_file', this.state.middle_category_banner_file)
          if (this.state.lower_category_section_file) formdata.append('lower_category_section_file', this.state.lower_category_section_file)
          if (this.state.filter_section_file) formdata.append('filter_section_file', this.state.filter_section_file)
          if (this.state.lower_banner_file) formdata.append('lower_banner_file', this.state.lower_banner_file)
          if (this.state.partner_brands_file) formdata.append('partner_brands_file', this.state.partner_brands_file)
          if (this.state.video_file) formdata.append('video_file', this.state.video_file)
          API.editAccCollectionV2(formdata, this.state.slug)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Collection v2 page edited successfully",
                "Success",
                5000
              );
            })
            .catch((err) => {
              console.error(err)
              NotificationManager.error(err.response.data.message, "Error", 5000);
            });
        } else if (this.props.content.tag === 'export') {
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.meta_description) {
            NotificationManager.error(
              "Please enter a valid meta description.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.top_content) {
            NotificationManager.error(
              "Please enter valid top content.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.categories || this.state.categories.length) {
            NotificationManager.error(
              "Please select atleast one category.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state._id) {
            NotificationManager.error(
              "Unable to fetch export page ID. Please try later!",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          const data = {
            _id: this.state._id,
            page_title: this.state.page_title,
            meta_description: this.state.meta_description,
            categories: this.state.categories.map((item) => item._id),
            banner: this.state.banner,
            top_content: this.state.top_content,
            video: this.state.video,
          }
          API.updateAccessoriesExportPage(data)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Export Page updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        }
      } else if (this.props.content.type === "add") {
        if (this.props.content.tag === "collection") {
          if (!this.state.slug || !this.state.slug.match(/^[A-Za-z0-9-]*$/)) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            alert("Please enter a valid slug.");
            return;
          }
          if (this.state.sku_codes.length < 1) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            alert("Please enter atleast one product.");
            return;
          }
          if (!this.state.banner.web_banner || !this.state.banner.mobile_banner) {
            NotificationManager.error(
              "Please upload main banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.sub_banner.web_banner || !this.state.sub_banner.mobile_banner) {
            NotificationManager.error(
              "Please upload sub banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.faq || !this.state.faq.length) {
            NotificationManager.error(
              "Please upload at least 1 faq.",
              "Error!!",
              5000
            );
            return;
          }
          const postData = {
            meta_description: this.state.meta_description,
            page_title: this.state.page_title,
            name: this.state.name,
            google_category_id: this.state.google_category_id,
            product_ids: this.state.sku_codes,
            slug: this.state.slug,
            banner: this.state.banner,
            sub_banner: this.state.sub_banner,
            faq: this.state.faq,
            bottom_content: this.state.bottom_content,
          };
          API.addAccessoriesCollection(postData)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
            })
            .catch((err) => console.error(err));
        } else if (this.props.content.tag === "segments") {
          if (!this.state.slug || !this.state.slug.match(/^[A-Za-z0-9-]*$/)) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.google_category_id || !this.state.google_category_id.match(/^[0-9]*$/)) {
            NotificationManager.error("Please enter a valid Google Category ID.", "Error!!", 5000);
            return
          }
          // const postData = {
          //   meta_description: this.state.meta_description,
          //   page_title: this.state.page_title,
          //   page_name: this.state.page_name,
          //   name: this.state.name,
          //   slug: this.state.slug,
          //   display_name: this.state.display_name || null,
          //   banner: this.state.banner,
          //   testimonials: this.state.testimonials,
          //   faq: this.state.faq,
          //   pricelist_product_ids: this.state.priceList ? this.state.priceList.map((item) => item.product_id) : null,
          //   popular_category_ids: this.state.popularCategory ? this.state.popularCategory.map((item) => item._id) : null,
          //   partner_brand_ids: this.state.partnerBrands ? this.state.partnerBrands.map((item) => item._id) : null,
          // }
          API.addAccessoriesSegment(post_data)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Segment created successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'categories') {
          if (!this.state.slug || !this.state.slug.match(/^[A-Za-z0-9-]*$/)) {
            NotificationManager.error(
              "Please enter a valid slug.",
              "Error!!",
              5000
            );
            return;
          }
          if (
            !this.state.google_category_id ||
            !this.state.google_category_id.match(/^[0-9]*$/)
          ) {
            NotificationManager.error(
              "Please enter a valid Google Category ID.",
              "Error!!",
              5000
            );
            return;
          }
          // const postData = {
          //   name: this.state.name,
          //   slug: this.state.slug,
          //   meta_description: this.state.meta_description,
          //   page_name: this.state.page_name,
          //   page_title: this.state.page_title,
          //   google_category_id: Number(this.state.google_category_id),
          //   is_popular: this.state.is_popular,
          //   star_category: this.state.star_category,
          //   variable_fields: this.state.variable_fields,
          //   parent_category_name: this.state.parent_category_name
          // }
          API.addAccessoriesCategory(post_data)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Category created successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'rails') {
          if (!this.state.name || !this.state.name.trim()) {
            NotificationManager.error(
              "Please enter a valid name.",
              "Error!!",
              5000
            );
            return;
          }
          // if (this.state.product_ids_string && this.state.product_ids_string.split(',').length < 2) {
          //   NotificationManager.error("Please enter atleast 2 VALID ',' sepearted product id. ", "Error!!", 5000);
          //   return;
          // }
          // if (parseInt(this.state.maximum_price)) {
          //   NotificationManager.error("Please enter valid max price", "Error!!", 5000);
          //   return;
          // }
          let postData = {
            id: this.state._id,
            name: this.state.name.trim(),
          };
          if (
            this.state.product_ids_string && !this.state.dynamicPricing
            && this.state.product_ids_string.trim().split(",") > 2
          )
            postData.product_ids = this.state.product_ids_string
              .trim()
              .split(",");

          if (
            this.state.ex_product_ids_string && !this.state.dynamicPricing
            && this.ex_product_ids_string.trim().split(",") > 2
          )
            postData.exclude_product_ids = this.state.ex_product_ids_string
              .trim()
              .split(",");

          if (this.state.maximum_price && !this.state.dynamicPricing)
            postData.maximum_price = this.state.maximum_price;

          if (this.state.minimum_price && !this.state.dynamicPricing)
            postData.minimum_price = this.state.minimum_price;

          if (
            this.state.selectedCategories && !this.state.dynamicPricing
            && this.state.selectedCategories.length
          )
            postData.category_slugs = this.state.selectedCategories.map(
              (cat) => cat.slug
            );

          if (
            this.state.excludedCategories && !this.state.dynamicPricing
            && this.state.excludedCategories.length
          )
            postData.exclude_category_slugs = this.state.excludedCategories.map(
              (cat) => cat.slug
            );

          if (this.state.selectedBrands && this.state.selectedBrands.length
            && !this.state.dynamicPricing
          )
            postData.product_brand_names = this.state.selectedBrands.map(
              (brand) => brand.name
            );

          if (this.state.excludedBrands && this.state.excludedBrands.length
            && !this.state.dynamicPricing
          )
            postData.exclude_product_brand_names =
              this.state.excludedBrands.map((brand) => brand.name);

          if (this.state.selectedTags && this.state.selectedTags.length
            && !this.state.dynamicPricing
            )
            postData.product_tags = this.state.selectedTags.map(
              (tag) => tag.name
            );

          if (
            this.state.product_specific_ids_string
            && this.state.product_specific_ids_string.trim().split(",").length
          )
            postData.product_specific_ids = this.state.product_specific_ids_string
              .trim()
              .split(",");

          if (this.state.maximum_rating && !this.state.dynamicPricing)
            postData.maximum_rating = this.state.maximum_rating;
  
          if (this.state.minimum_rating && !this.state.dynamicPricing)
            postData.minimum_rating = this.state.minimum_rating;
          
          if (this.state.priority_order)
            postData.priority_order = this.state.priority_order;
            
          if (this.state.dynamicPricing)
            postData.dynamic_price = this.state.dynamicPricing;

          API.addAccessoriesRails(postData)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Rails updated successfully.",
                "Success",
                5000
              );
            })
            .catch((err) => {
              console.error(err);
              NotificationManager.error(err.message, "Error!!", 5000);
            });
        } else if (this.props.content.tag === 'brands') {
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.meta_description) {
            NotificationManager.error(
              "Please enter a valid meta description.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.name) {
            NotificationManager.error(
              "Please enter a valid name.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.icon) {
            NotificationManager.error(
              "Please enter a valid icon.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.description) {
            NotificationManager.error(
              "Please enter a valid brand content.",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          API.addAccessoriesBrandPage({ ...post_data })
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Brand Page updated successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === 'carPage') {
          if (!this.state.selectedCarBrand) {
            NotificationManager.error(
              "Please select a valid car brand.",
              "Error!!",
              5000
            );
            return;
          }
          if (this.state.pageType === 1 && !this.state.selectedCarModel) {
            NotificationManager.error(
              "Please select a valid car model.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.top_banner || !this.state.top_banner.mobile || !this.state.top_banner.desktop) {
            NotificationManager.error(
              "Please upload a valid top banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.bottom_banner || !this.state.bottom_banner.mobile || !this.state.bottom_banner.desktop) {
            NotificationManager.error(
              "Please upload a valid bottom banner.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.page_title) {
            NotificationManager.error(
              "Please enter a valid page title.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.meta_description) {
            NotificationManager.error(
              "Please enter a valid meta description.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.top_content) {
            NotificationManager.error(
              "Please enter a valid top content.",
              "Error!!",
              5000
            );
            return;
          }
          if (!this.state.bottom_content) {
            NotificationManager.error(
              "Please enter a valid bottom content.",
              "Error!!",
              5000
            );
            return;
          }
          // validation here
          const data = {
            top_banner: this.state.top_banner,
            bottom_banner: this.state.bottom_banner,
            page_title: this.state.page_title,
            meta_description: this.state.meta_description,
            top_content: this.state.top_content,
            bottom_content: this.state.bottom_content,
            faq: this.state.faq,
            brand_slug: this.state.selectedBrands.slug,
            brand_name: this.state.selectedBrands.name,
            ...(this.state.selectedCarModel ? {model_slug: this.state.selectedCarModel.slug} : {}),
            ...(this.state.selectedCarModel ? {model_name: this.state.selectedCarModel.name} : {}),
          }
          API.addAccessoriesCarPage(data)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Car Page added successfully.",
                "Success",
                5000
              );
            })
            .catch(err => {
              console.error(err);
              NotificationManager.error(err.response.data.message, "Error", 5000);
            })
        } else if (this.props.content.tag === "collectionv2") {
          if (!this.state.base_file) {
            NotificationManager.error(
              "Please enter a valid base file from Edit button in basic detail section.",
              "Error!!",
              5000
            );
            return;
          }
          const formdata = new FormData()
          formdata.append('base_file', this.state.base_file)
          if (this.state.title_banner_file) formdata.append('title_banner_file', this.state.title_banner_file)
          if (this.state.upper_category_section_file) formdata.append('upper_category_section_file', this.state.upper_category_section_file)
          if (this.state.upper_banner_file) formdata.append('upper_banner_file', this.state.upper_banner_file)
          if (this.state.offers_banner_file) formdata.append('offers_banner_file', this.state.offers_banner_file)
          if (this.state.middle_category_banner_file) formdata.append('middle_category_banner_file', this.state.middle_category_banner_file)
          if (this.state.lower_category_section_file) formdata.append('lower_category_section_file', this.state.lower_category_section_file)
          if (this.state.filter_section_file) formdata.append('filter_section_file', this.state.filter_section_file)
          if (this.state.lower_banner_file) formdata.append('lower_banner_file', this.state.lower_banner_file)
          if (this.state.partner_brands_file) formdata.append('partner_brands_file', this.state.partner_brands_file)
          if (this.state.video_file) formdata.append('video_file', this.state.video_file)
          API.addAccCollectionV2(formdata)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Collection v2 page edited successfully",
                "Success",
                5000
              );
            })
            .catch((err) => {
              console.error(err)
              NotificationManager.error(err.response.data.message, "Error", 5000);
            });
        }
      } else if (this.props.content.type === "delete") {
        API.deleteAccessories(this.props.content.tag, this.state)
          .then(function (response) {
            if (response.data.status) {
              that.props.onMiddleExtraButtonClick(type, that.state, null);
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    } else if (type === "addRelated") {
      if (this.props.content.tag === "searchbysku") {
        const params = {
          similar_products: this.state.selectedSimilarProducts.map(
            (item) => item.product_id
          ),
          customer_liked: this.state.selectedCustomerLike.map(
            (item) => item.product_id
          ),
          recommendations: this.state.selectedRecommendedProducts.map(
            (item) => item.product_id
          ),
          product_id: this.state.product_id,
        };
        API.addEditAccessoriesProductRelated(params)
          .then(() => {
            this.props.onMiddleExtraButtonClick(type, null);
            NotificationManager.success(
              "Related products added successfully.",
              "Success",
              5000
            );
          })
          .catch((err) => {
            NotificationManager.error("Some error occured.", "Error!!", 5000);
          });
      }
    } else if (type === "approve" || type === "fetch") {
      if (this.props.content.tag === "feed") {
        if (
          type === "approve" &&
          (!this.state.applicable_on || !this.state.applicable_on.length)
        ) {
          NotificationManager.error(
            "Please choose feed type.",
            "Error!!",
            5000
          );
          return;
        }
        const postData = {};
        if (this.props.content.type === "edit") postData.id = this.state._id;

        if (type === "approve")
          postData.applicable_on = this.state.applicable_on.map(
            (el) => el.name
          );

        if (
          this.state.product_ids_string &&
          this.state.product_ids_string.trim().split(",") > 2
        )
          postData.product_ids = this.state.product_ids_string
            .trim()
            .split(",");

        if (
          this.state.ex_product_ids_string &&
          this.ex_product_ids_string.trim().split(",") > 2
        )
          postData.exclude_product_ids = this.state.ex_product_ids_string
            .trim()
            .split(",");

        if (this.state.maximum_price)
          postData.maximum_price = this.state.maximum_price;

        if (this.state.minimum_price)
          postData.minimum_price = this.state.minimum_price;

        if (this.state.maximum_rating)
          postData.max_customer_ratings = this.state.maximum_price;

        if (this.state.minimum_rating)
          postData.min_customer_ratings = this.state.minimum_price;

        if (
          this.state.selectedCategories &&
          this.state.selectedCategories.length
        )
          postData.category_slugs = this.state.selectedCategories.map(
            (cat) => cat.slug
          );

        if (
          this.state.excludedCategories &&
          this.state.excludedCategories.length
        )
          postData.exclude_category_slugs = this.state.excludedCategories.map(
            (cat) => cat.slug
          );

        if (this.state.selectedBrands && this.state.selectedBrands.length)
          postData.product_brand_names = this.state.selectedBrands.map(
            (brand) => brand.name
          );

        if (this.state.excludedBrands && this.state.excludedBrands.length)
          postData.exclude_product_brand_names = this.state.excludedBrands.map(
            (brand) => brand.name
          );

        if (this.state.selectedTags && this.state.selectedTags.length)
          postData.product_tags = this.state.selectedTags.map(
            (tag) => tag.name
          );

        if (this.state.excludedTags && this.state.excludedTags.length)
          postData.exclude_product_tags = this.state.excludedTags.map(
            (tag) => tag.name
          );

        if (type === "fetch") {
          API.getCustomFeedProducts(postData)
            .then((response) => {
              this.setState({
                fetchedProducts: response.data.data.products,
              });
              if (
                response.data.data &&
                response.data.data.products &&
                response.data.data.products.length
              ) {
                document.getElementById("scroller").scrollIntoView();
                NotificationManager.success(
                  "Please verify the products fetched.",
                  "Success",
                  5000
                );
              } else
                NotificationManager.success(
                  "No Products found",
                  "Success",
                  5000
                );
            })
            .catch((err) => {
              console.error(err);
              NotificationManager.error(
                err.message || "Error in fecthing products",
                "Error!!",
                5000
              );
            });
        } else if (type === "approve") {
          let approveFeed;
          if (this.props.content.type === "edit")
            approveFeed = API.approveEditedCustomFeed;
          else if (this.props.content.type === "add")
            approveFeed = API.approveNewCustomFeed;

          approveFeed(postData)
            .then(() => {
              this.props.onMiddleExtraButtonClick(type, null);
              NotificationManager.success(
                "Feed added successfully.",
                "Success",
                5000
              );
            })
            .catch((err) => {
              console.error(err);
              NotificationManager.error(
                err.message || "Error occured!",
                "Error!!",
                5000
              );
            });
        }
      }
    } else {
      this.props.onMiddleExtraButtonClick(type, null);
    }
  }

  handleChange(type, e) {
    var obj = this.state;
    obj[type] = e.target.value;
    if (this.props.content.type === "edit") new_data[type] = e.target.value;
    else post_data[type] = e.target.value;
    this.setState(obj);
  }

  handleTinyMCEChange(type, e) {
    var obj = this.state;
    obj[type] = e.target.getContent();
    if (this.props.content.type === "edit") new_data[type] = e.target.getContent();
    else post_data[type] = e.target.getContent();
    this.setState(obj);
  }

  handleProductInputEnter(e) {
    let x = [];
    let y = [];
    if (this.state.sku_codes && this.state.sku_codes.length > 0) {
      x = [...this.state.sku_codes];
    }
    if (
      this.state.collection_products &&
      this.state.collection_products.length > 0
    ) {
      y = [...this.state.collection_products];
    }

    API.searchAccessoriesProducts(e.target.value.trim())
      .then((response) => {
        if (response.data.data && response.data.data.length) {
          x = [
            ...x,
            ...response.data.data.map(item => item.product_id),
          ];
          y = [
            ...y,
            ...response.data.data,
          ];
          this.setState({ sku_codes: x, product: null, collection_products: y });
          if (this.props.content.tag === 'searchbysku' && this.props.content.type === "edit") {
            if (!new_data.product_update) new_data.product_update = {};
            new_data.product_update.frequently_bought_together = x;
          }
        } else {
          x.push(response.data.data.product_id);
          y.push(response.data.data);
          this.setState({ sku_codes: x, product: null, collection_products: y });
          if (this.props.content.tag === 'searchbysku' && this.props.content.type === "edit") {
            if (!new_data.product_update) new_data.product_update = {};
            new_data.product_update.frequently_bought_together = x;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          err.message || "Error in fecthing products",
          "Error!!",
          5000
        );
      });
  }

  onChangeRemoveClick(index) {
    let temp = this.state.sku_codes;
    let temp2 = this.state.collection_products;
    temp.splice(index, 1);
    temp2.splice(index, 1);
    this.setState({ sku_codes: temp, collection_products: temp2 });
    if (this.props.content.tag === 'searchbysku' && this.props.content.type === "edit") {
      if (!new_data.product_update) new_data.product_update = {};
      new_data.product_update.frequently_bought_together = temp;
    }
  }

  getCollectionProduct() {
    API.getCollectionProducts(this.props.content.row)
      .then((response) => {
        const data = [];
        response.data.data.products.map((item) => data.push(item.product_id));
        this.setState({
          sku_codes: data,
          collection_products: response.data.data.products,
        });
      })
      .catch((e) => console.error(e));
  }

  onDeleteCollectionImage(e, index, type) {
    let temp = this.state.banners;
    temp[index][type] =
      e && e.target && e.target.files ? e.target.files[0] : null;
    this.setState({ banners: temp });
  }

  makeDimensionUI(objectName) {
    const arr = [];
    const temp = Object.keys(this.state.variants[objectName]);
    for (let index = 0; index < temp.length; index += 2) {
      if (temp[index + 1]) {
        arr.push(
          <>
            <div className="border-box clearfix fields">
              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  {temp[index]}
                </div>
                <input
                  type="text"
                  placeholder={temp[index]}
                  className="field border-box small"
                  value={this.state.variants[objectName][temp[index]]}
                  onChange={(e) =>
                    this.handleProductFieldChange(
                      temp[index],
                      e,
                      "variant",
                      "object",
                      objectName
                    )
                  }
                />
              </div>
              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  {temp[index + 1]}
                </div>
                <input
                  type="text"
                  placeholder={temp[index + 1]}
                  className="field border-box small"
                  value={this.state.variants[objectName][temp[index + 1]]}
                  onChange={(e) =>
                    this.handleProductFieldChange(
                      temp[index + 1],
                      e,
                      "variant",
                      "object",
                      objectName
                    )
                  }
                />
              </div>
            </div>
          </>
        );
      } else {
        arr.push(
          <>
            <div className="border-box clearfix fields">
              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  {temp[index]}
                </div>
                <input
                  type="text"
                  placeholder={temp[index]}
                  className="field border-box small"
                  value={this.state.variants[objectName][temp[index]]}
                  onChange={(e) =>
                    this.handleProductFieldChange(
                      temp[index],
                      e,
                      "variant",
                      "object",
                      objectName
                    )
                  }
                />
              </div>
            </div>
          </>
        );
      }
    }
    return arr;
  }

  handleProductFieldChange(
    fieldName,
    e,
    fieldObj,
    fieldType = null,
    fieldObjName = null
  ) {
    let temp = this.state;
    if (fieldObj === "variant") {
      if (new_data.sku_update) {
        if (fieldType && fieldType === "object") {
          if (!temp.variants[fieldObjName]) {
            temp.variants[fieldObjName] = {};
          }
          temp.variants[fieldObjName][fieldName] = e.target.value;
          if (!new_data.sku_update[fieldObjName]) new_data.sku_update[fieldObjName] = {};
          new_data.sku_update[fieldObjName][fieldName] = e.target.value;
        } else {
          temp.variants[fieldName] = e.target.value;
          new_data.sku_update[fieldName] = e.target.value;
        }
      } else {
        new_data.sku_update = {};
        if (fieldType && fieldType === "object") {
          if (!temp.variants[fieldObjName]) {
            temp.variants[fieldObjName] = {};
          }
          temp.variants[fieldObjName][fieldName] = e.target.value;
          if (!new_data.sku_update[fieldObjName]) new_data.sku_update[fieldObjName] = {};
          new_data.sku_update[fieldObjName][fieldName] = e.target.value;
        } else {
          temp.variants[fieldName] = e.target.value;
          new_data.sku_update[fieldName] = e.target.value;
        }
      }
    } else {
      if (new_data.product_update) {
        if (fieldType && fieldType === "object") {
          if (!temp[fieldObjName]) {
            temp[fieldObjName] = {};
          }
          temp[fieldObjName][fieldName] = e.target.value;
          if (!new_data.product_update[fieldObjName]) new_data.product_update[fieldObjName] = {};
          new_data.product_update[fieldObjName][fieldName] = e.target.value;
        } else {
          temp[fieldName] = e.target.value;
          new_data.product_update[fieldName] = e.target.value;
        }
      } else {
        new_data.product_update = {};
        if (fieldType && fieldType === "object") {
          if (!temp[fieldObjName]) {
            temp[fieldObjName] = {};
          }
          temp[fieldObjName][fieldName] = e.target.value;
          if (!new_data.product_update[fieldObjName]) new_data.product_update[fieldObjName] = {};
          new_data.product_update[fieldObjName][fieldName] = e.target.value;
        } else {
          temp[fieldName] = e.target.value;
          new_data.product_update[fieldName] = e.target.value;
        }
      }
    }
    this.setState(temp);
  }

  removeCityInventory(city_id, index) {
    if (window.confirm("Do you really want to delete this City Inventory?")) {
      API.deleteAccProductCityInventory({
        sku_code: this.state.variants.sku_code,
        city_id,
      })
        .then(() => {
          let temp2 = [...this.state.invenoryList];
          temp2.splice(index, 1);
          this.setState({ invenoryList: temp2 });
          NotificationManager.success(
            "City Inventory Deleted successfully",
            "Success",
            5000
          );
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
          console.error(error);
        });
    }
  }

  getTestimonialList (testimonialKey, countKey, showRating) {
    let testimonialList = [];
    for (let i = 0; i < this.state[countKey]; i += 1) {
      testimonialList.push(
        <div className="testimonialItem extramainpanel">
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Testimonial ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeTestimonial(testimonialKey, countKey, i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Customer Name
              </div>
              <input
                type="text"
                placeholder="Customer Name"
                value={this.state[testimonialKey][i] ? this.state[testimonialKey][i].customer_name : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(testimonialKey, 'customer_name', i, e)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Heading
              </div>
              <input
                type="text"
                placeholder="Heading"
                value={this.state[testimonialKey][i] ? this.state[testimonialKey][i].heading : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(testimonialKey, 'heading', i, e)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Source
              </div>
              <input
                type="text"
                placeholder="source"
                value={this.state[testimonialKey][i] ? this.state[testimonialKey][i].source : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(testimonialKey, 'source', i, e)}
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) => this.handleArraykeyChange(testimonialKey, 'content', i, e)}
              >
                {this.state[testimonialKey][i] ? this.state[testimonialKey][i].content : ''}
              </textarea> 
            </div>
          </div>
          {showRating ? (<div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Rating
              </div>
              <input
                type="text"
                placeholder="rating"
                value={this.state[testimonialKey][i] ? this.state[testimonialKey][i].rating : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(testimonialKey, 'rating', i, e)}
              />
            </div>
          </div>) : null}
        </div>
      );
    }
    return testimonialList;
  }

  getFAQList () {
    let faqList = [];
    for (let i = 0; i < this.state.faqCount; i += 1) {
      faqList.push(
        <div className="faqItem extramainpanel">
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`FAQ ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeFAQ(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Heading
              </div>
              <input
                type="text"
                placeholder="Heading"
                value={this.state.faq[i] ? this.state.faq[i].Q : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange('faq', 'Q', i, e)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) => this.handleArraykeyChange('faq', 'A', i, e)}
              >
                {this.state.faq[i] ? this.state.faq[i].A : ''}
              </textarea> 
            </div>
          </div>
        </div>
      );
    }
    return faqList;
  }

  getProductReviewList (reviewKey, countKey) {
    let reviewList = [];
    for (let i = 0; i < this.state[countKey]; i += 1) {
      reviewList.push(
        <div className="reviewItem extramainpanel">
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Review ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                className="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeTestimonial(reviewKey, countKey, i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Review Title
              </div>
              <input
                type="text"
                placeholder="Review Title"
                value={this.state[reviewKey][i] ? this.state[reviewKey][i].title : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(reviewKey, 'title', i, e)}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Customer Name
              </div>
              <input
                type="text"
                placeholder="Customer Name"
                value={this.state[reviewKey][i] ? this.state[reviewKey][i].review_by : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(reviewKey, 'review_by', i, e)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Review Date
              </div>
              <input
                type="text"
                placeholder="(dd Month yyyy)"
                value={this.state[reviewKey][i] ? this.state[reviewKey][i].review_date : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(reviewKey, 'review_date', i, e)}
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Review Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) => this.handleArraykeyChange(reviewKey, 'review', i, e)}
              >
                {this.state[reviewKey][i] ? this.state[reviewKey][i].review : ''}
              </textarea> 
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Rating
              </div>
              <input
                type="text"
                placeholder="Rating"
                value={this.state[reviewKey][i] ? this.state[reviewKey][i].rating : ''}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange(reviewKey, 'rating', i, e)}
              />
            </div>
            <div className="border-box field2 clearfix">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Show Review on Website
              </div>
              <input
                className="field border-box small"
                type="checkbox"
                id={"is_visible" + i}
                name="is_visible"
                checked={this.state[reviewKey][i] && typeof (this.state[reviewKey][i].is_visible) !== 'undefined'
                  ? this.state[reviewKey][i].is_visible : false}
                onChange={(e) => {
                  const obj = {};
                  obj.target = {};
                  obj.target.value = e.target.checked;
                  this.handleArraykeyChange(reviewKey, 'is_visible', i, obj)
                }}
              />
              {/* <label for="is_visible1">Yes</label>
              <input
                className="field border-box small"
                type="radio"
                id="is_visible2"
                name="is_visible"
                value={0}
                checked={this.state[reviewKey][i] && typeof (this.state[reviewKey][i].is_visible) !== 'undefined'
                 ? !this.state[reviewKey][i].is_visible : false}
                onChange={(e) => {
                  this.handleArraykeyChange(reviewKey, 'is_visible', i, e)
                }}
              />
              <label for="is_visible2">No</label> */}
            </div>
          </div>
        </div>
      );
    }
    return reviewList;
  }

  getProductVideoList (allKeys) {
    let videoList = [];
    for (let i = 0; i < this.state.videoCount; i += 1) {
      let videoThumbnail = React.createRef();
      let videoProfileIcon = React.createRef();
      videoList.push(
      <div className="videoItem extramainpanel">
        <div className="border-box clearfix subtitle">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Video ${i + 1}`}
          </div>
          <div className="border-box floatright rightalign">
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={() => this.removeVideo(i)}
            >
              Remove
            </button>
          </div>
        </div>
        <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video URL
            </div>
            <input
              type="text"
              placeholder="Video URL"
              value={this.state.video[i] ? this.state.video[i].link : ""}
              className="field border-box small"
              onChange={(e) => {
                this.handleArraykeyChange(
                  "video",
                  "link",
                  i,
                  e,               
                );
              }}
            />
          </div>
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video Title
            </div>
            <input
              type="text"
              placeholder="Video Title"
              value={this.state.video[i] ? this.state.video[i].text : ""}
              className="field border-box small"
              onChange={(e) => {
                this.handleArraykeyChange(
                  "video",
                  "text",
                  i,
                  e,                  
                );
              }}
            />
          </div>
        </div>
        <div className="border-box clearfix fields">
          {allKeys ? (
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Video Description
              </div>
              <input
                type="text"
                placeholder="Video Description"
                value={
                  this.state.video[i] ? this.state.video[i].subText : ""
                }
                className="field border-box small"
                onChange={(e) => {
                  this.handleArraykeyChange(
                    "video",
                    "subText",
                    i,
                    e,
                  );
                }}
              />
            </div>
          ) : null}
          <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Video Thumbnail Icon
            </div>
            {this.state.video[i] && this.state.video[i].thumbnail &&
            typeof this.state.video[i].thumbnail === "string" ? (
              <div className="border-box fieldimage1">
                <img
                  src={this.state.video[i].thumbnail}
                  height="100"
                  width="100"
                  alt="icon"
                />
                <br></br>
                <button
                  class="btn btn_small btncolorprim btncard move btndelete"
                  onClick={() =>
                    this.handleArraykeyChange("video", "thumbnail",i , { target: { value: null }})
                  }
                >
                  Delete
                </button>
              </div>
            ) : (
              <div className="border-box fieldimage1">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    this.handleArraykeyChange("video", "thumbnail", i, { target: { value: e.target.files[0]}})
                  }
                />
                <div
                  className="border-box clearfix field2"
                  style={{ marginTop: "10px" }}
                >
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Or Enter URL
                  </div>
                  <input
                    ref={videoThumbnail}
                    type="text"
                    placeholder="Thumnail URL"
                    className="field border-box small"
                  />
                </div>
                <button
                  class="btn btn_small btncolorprim btncard move btndelete"
                  onClick={() => {
                    if (
                      videoThumbnail.current.value.length &&
                      this.state.video[i].thumbnail &&
                      typeof this.state.video[i].thumbnail === "object"
                    ) {
                      NotificationManager.error(
                        "Either choose a image to upload or enter URL",
                        "Error!!",
                        5000
                      );
                      return;
                    }
                    if (videoThumbnail.current.value) {
                      const e = {
                        target: {
                          value: videoThumbnail.current.value,
                        },
                      };
                      this.handleArraykeyChange(
                        "video",
                        "thumbnail",
                        i,
                        e,
                      );
                    } else if (
                      typeof this.state.video[i].thumbnail === "object"
                    ) {
                      this.uploadImageOnServer(
                        "video",
                        "thumbnail",
                        this.state.video[i].thumbnail,
                        null,
                        null,
                        { type: "product", fieldType: "object", index: i },
                      );
                    }
                  }}
                >
                  Upload Icon
                </button>
              </div>
            )}
          </div>
        </div>
        {allKeys ? (
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Profile Name
              </div>
              <input
                type="text"
                placeholder="Profile Name"
                value={
                  this.state.video[i] ? this.state.video[i].profile_name : ""
                }
                className="field border-box small"
                onChange={(e) => {
                  this.handleArraykeyChange(
                    "video",
                    "profile_name",
                    i,
                    e,
                  );
                }}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Profile Icon
              </div>
              {this.state.video[i] &&
              this.state.video[i].profile_icon &&
              typeof this.state.video[i].profile_icon === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state.video[i].profile_icon}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() =>
                      this.handleArraykeyChange("video", "profile_icon",i , { target: { value: null }})
                    }
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      this.handleArraykeyChange("video", "profile_icon", i, { target: { value: e.target.files[0]}})
                    }
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={videoProfileIcon}
                      type="text"
                      placeholder="Icon Url"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        videoProfileIcon.current.value.length &&
                        this.state.video[i].profile_icon &&
                        typeof this.state.video[i].profile_icon === "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      if (videoProfileIcon.current.value) {
                        const e = {
                          target: {
                            value: videoProfileIcon.current.value,
                          },
                        };
                        this.handleArraykeyChange(
                          "video",
                          "profile_icon",
                          i,
                          e,
                        );
                      } else if (
                        typeof this.state.video[i].thumbnail === "object"
                      ) {
                        this.uploadImageOnServer(
                          "video",
                          "profile_icon",
                          this.state.video[i].profile_icon,
                          null,
                          null,
                          { type: "product", fieldType: "object", index: i },
                        );
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      );
    }
    return videoList;
  }

  uploadPromotionalBanner(index, pBannerRef, isHome) {
    if (pBannerRef.current.value.length && this.state.promotional_banners[index]
      && ((!isHome && typeof this.state.promotional_banners[index] === 'object')
          || (isHome && typeof this.state.promotional_banners[index].img_url === 'object')) ) {
      NotificationManager.error(
        'Either choose a image to upload or enter URL',
        'Error!!',
        5000
      );
      return;
    }
    if (pBannerRef.current.value) {
      const tempObj = JSON.parse(JSON.stringify(this.state.promotional_banners));
      if (isHome) {
        if (!tempObj[index]) tempObj[index] = {};
        tempObj[index].img_url = pBannerRef.current.value;
      } else tempObj[index] = pBannerRef.current.value;
      if (this.props.content.type === "edit") new_data.promotional_banners = tempObj;
      else post_data.promotional_banners = tempObj;
      this.setState({
        promotional_banners: tempObj,
      });
    } else  if ((!isHome && typeof this.state.promotional_banners[index] === 'object')
      || (isHome && typeof this.state.promotional_banners[index].img_url === 'object')){
      this.uploadImageOnServer(
        'promotional_banners',
        isHome ? 'img_url' : null,
        isHome ? this.state.promotional_banners[index].img_url : this.state.promotional_banners[index],
        index,
        isHome
      );
    }
  }

  getPromotionaBannerList (isHome) {
    let pbannerList = [];
    for (let i = 0; i < this.state.pbannerCount; i += 1) {
      let pBannerRef = React.createRef();
      pbannerList.push(
        <div className="pBannerItem extramainpanel">
          <div className="border-box clearfix subtitle">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Promotional Banner ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removePromotionalBanner(i)}
              >
                Remove
              </button>
            </div>
          </div>
          {isHome ? (
            <>
              <div className="border-box clearfix fields">
                <div className='border-box clearfix field1'>
                  <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Banner
                  </div>
                  {this.state.promotional_banners && this.state.promotional_banners[i]
                  && this.state.promotional_banners[i].img_url && typeof this.state.promotional_banners[i].img_url === 'string' ? (
                    <div className='border-box fieldimage1'>
                      <img
                        src={this.state.promotional_banners[i].img_url}
                        height='100'
                        width='100'
                        alt='icon'
                      />
                      <br></br>
                      <button
                        class='btn btn_small btncolorprim btncard move btndelete'
                        onClick={() => this.deleteImage('promotional_banners', 'img_url', i, isHome)}
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <div className='border-box fieldimage1'>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={(e) => this.onInputImage('promotional_banners', 'img_url', e, i, isHome)}
                      />
                      <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Or Enter URL</div>
                        <input ref={pBannerRef} type="text" placeholder="Banner URL" className="field border-box small"/>
                      </div>
                      <button
                        class='btn btn_small btncolorprim btncard move btndelete'
                        onClick={() => this.uploadPromotionalBanner(i, pBannerRef, isHome)}
                      >
                        Upload Icon
                      </button>
                    </div>
                  )}
                </div>
                <div className="border-box field1">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Redirect URL
                  </div>
                  <input
                    type="text"
                    placeholder="Redirect URL"
                    value={this.state.promotional_banners[i] ? this.state.promotional_banners[i].redirect_url : ''}
                    className="field border-box small"
                    onChange={(e) => this.handleArraykeyChange('promotional_banners', 'redirect_url', i, e)}
                  />
                </div>
              </div>
              <div className="border-box clearfix fields">
                <div className="border-box field2">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Title
                  </div>
                  <input
                    type="text"
                    placeholder="Title"
                    value={this.state.promotional_banners[i] ? this.state.promotional_banners[i].text : ''}
                    className="field border-box small"
                    onChange={(e) => this.handleArraykeyChange('promotional_banners', 'text', i, e)}
                  />
                </div>
                <div className="border-box field2">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Subtitle
                  </div>
                  <input
                    type="text"
                    placeholder="Subtitle"
                    value={this.state.promotional_banners[i] ? this.state.promotional_banners[i].sub_text : ''}
                    className="field border-box small"
                    onChange={(e) => this.handleArraykeyChange('promotional_banners', 'sub_text', i, e)}
                  />
                </div>
              </div>
            </> 
          ) : (
            <div className="border-box clearfix fields">
              <div className='border-box clearfix field1'>
                <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                  Banner
                </div>
                {this.state.promotional_banners && this.state.promotional_banners[i]
                  && typeof this.state.promotional_banners[i] === 'string' ? (
                  <div className='border-box fieldimage1'>
                    <img
                      src={this.state.promotional_banners[i]}
                      height='100'
                      width='100'
                      alt='icon'
                    />
                    <br></br>
                    <button
                      class='btn btn_small btncolorprim btncard move btndelete'
                      onClick={() => this.deleteImage('promotional_banners', null, i)}
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <div className='border-box fieldimage1'>
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(e) => this.onInputImage('promotional_banners', null, e, i)}
                    />
                    <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Or Enter URL</div>
                      <input ref={pBannerRef} type="text" placeholder="Banner URL" className="field border-box small"/>
                    </div>
                    <button
                      class='btn btn_small btncolorprim btncard move btndelete'
                      onClick={() => this.uploadPromotionalBanner(i, pBannerRef, isHome)}
                    >
                      Upload Icon
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}  
        </div>
      );
    }
    return pbannerList;
  }

  getStoreImgList() {
    let storeImgList = [];
    for (let i = 0; i < this.state.storeImgCount; i += 1) {
      let storeImgRef = React.createRef();
      storeImgList.push(
        <div className="storeImgItem extramainpanel">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Store Image ${i + 1}`}
          </div>
          <div className="border-box clearfix footer">
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeStoreImg(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Image
              </div>
              {this.state.store_data && this.state.store_data.store_images
              && this.state.store_data.store_images[i]
              && typeof this.state.store_data.store_images[i].img_url === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state.store_data.store_images[i].img_url}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() =>
                      this.deleteStoreImg(i)
                    }
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      this.onInputStoreImage( e, i)
                    }
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={storeImgRef}
                      type="text"
                      placeholder="Image URL"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        storeImgRef.current.value.length && this.state.store_data.store_images[i]
                        && this.state.store_data.store_images[i].img_url
                        && typeof this.state.store_data.store_images[i].img_url === "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      if (storeImgRef.current.value) {
                        const tempObj = JSON.parse(
                          JSON.stringify(this.state.store_data)
                        );
                        if (!tempObj.store_images[i]) tempObj.store_images[i] = {};
                        tempObj.store_images[i].img_url = storeImgRef.current.value;
                        this.setState({
                          store_data: tempObj,
                        });
                      } else if (typeof this.state.store_data.store_images[i].img_url === "object") {
                        this.uploadStoreImageOnServer(this.state.store_data.store_images[i].img_url, i);
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Store Address
              </div>
              <input
                type="text"
                placeholder="Addrress"
                value={this.state.store_data.store_images[i] ? this.state.store_data.store_images[i].address : ''}
                className="field border-box small"
                onChange={(e) => this.handleStoreImgkeyChange('address', i, e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return storeImgList;
  }

  getPopularCategoryImgList() {
    let popularCategoryImgList = [];
    for (let i = 0; i < this.state.categoryCount; i += 1) {
      let categoryImgRef = React.createRef();
      popularCategoryImgList.push(
        <div className="popularCategoryImgItem extramainpanel">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Category Image ${i + 1}`}
          </div>
          <div className="border-box clearfix footer">
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removePopularCategoryImg(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Image
              </div>
              {this.state.popular_category_images &&
              this.state.popular_category_images[i] &&
              typeof this.state.popular_category_images[i] === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state.popular_category_images[i]}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() =>
                      this.deleteImage("popular_category_images", null, i)
                    }
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      this.onInputImage("popular_category_images", null, e, i)
                    }
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={categoryImgRef}
                      type="text"
                      placeholder="Image URL"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        categoryImgRef.current.value.length &&
                        this.state.popular_category_images[i] &&
                        typeof this.state.popular_category_images[i] ===
                          "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      if (categoryImgRef.current.value) {
                        const tempObj = JSON.parse(
                          JSON.stringify(this.state.popular_category_images)
                        );
                        tempObj[i] = categoryImgRef.current.value;
                        this.setState({
                          popular_category_images: tempObj,
                        });
                      } else if (
                        typeof this.state.popular_category_images[i] ===
                        "object"
                      ) {
                        this.uploadImageOnServer(
                          "popular_category_images",
                          null,
                          this.state.popular_category_images[i],
                          i
                        );
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return popularCategoryImgList;
  }

  getBannerList(screentype, countKey, noRedirect, bannerKey = 'banner', showName) {
    let bannerList = [];
    for ( let i = 0; i < this.state[countKey]; i += 1) {
      let bannerRef = React.createRef();
      bannerList.push(
        <div className="bannerImgItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`${screentype !== 'mobile' ? 'Desktop' : 'Mobile'} ${bannerKey} ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeBannerImg(screentype, countKey, i, bannerKey)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              {this.state[bannerKey] && this.state[bannerKey][screentype] && this.state[bannerKey][screentype][i]
                && typeof (noRedirect ? this.state[bannerKey][screentype][i] : this.state[bannerKey][screentype][i].banner_url) === 'string' ? (
                <div className='border-box fieldimage1'>
                  <img
                    src={noRedirect ? this.state[bannerKey][screentype][i] : this.state[bannerKey][screentype][i].banner_url}
                    height='100'
                    width='100'
                    alt='icon'
                  />
                  <br></br>
                  <button
                    className='btn btn_small btncolorprim btncard move btndelete'
                    onClick={() => this.deleteBannerImage(screentype, i, noRedirect, bannerKey)}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className='border-box fieldimage1'>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => this.onInputBannerImage(screentype, e, i, noRedirect, bannerKey)}
                  />
                  <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                    <div
                      className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                    >
                      Or Enter URL
                    </div>
                    <input
                      ref={bannerRef}
                      type="text"
                      placeholder="Enter Banner URL"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    className='btn btn_small btncolorprim btncard move btndelete'
                    onClick={() => {
                      if (bannerRef.current.value.length && this.state[bannerKey]
                          && this.state[bannerKey][screentype] && this.state[bannerKey][screentype][i]
                          && (noRedirect ? this.state[bannerKey][screentype][i] : this.state[bannerKey][screentype][i].banner_url) === 'object' ) {
                        NotificationManager.error(
                          'Either choose a image to upload or enter URL',
                          'Error!!',
                          5000
                        );
                        return;
                      }
                      if (bannerRef.current.value && bannerRef.current.value.length) {
                        const tempObj = JSON.parse(
                          JSON.stringify(this.state[bannerKey])
                        );
                        if (!tempObj[screentype][i]) tempObj[screentype][i] = {};
                        if (noRedirect) tempObj[screentype][i] = bannerRef.current.value;
                        else tempObj[screentype][i].banner_url = bannerRef.current.value;
                        if (this.props.content.type === "edit") new_data[bannerKey] = tempObj;
                        else post_data[bannerKey] = tempObj;
                        this.setState({
                          [bannerKey]: tempObj,
                        });
                      } else  if (typeof (noRedirect ? this.state[bannerKey][screentype][i] : this.state[bannerKey][screentype][i].banner_url) === 'object'){
                        this.uploadBannerImageOnServer(
                          (noRedirect ? this.state[bannerKey][screentype][i] : this.state[bannerKey][screentype][i].banner_url),
                          screentype,
                          i,
                          noRedirect,
                          bannerKey,
                        );
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
            {noRedirect ? null : (
              <div className="border-box clearfix field1">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Redirect Url
                </div>
                <input
                  type="text"
                  placeholder="Banner redirect url"
                  value={this.state[bannerKey][screentype][i] ? this.state[bannerKey][screentype][i].redirect_url : ''}
                  className="field border-box small"
                  onChange={(e) => this.handleBannerImgkeyChange(screentype, 'redirect_url', i, e, bannerKey)}
                />
              </div>
            )}
             {showName ? (
              <div className="border-box clearfix field1">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Name
                </div>
                <input
                  type="text"
                  placeholder="Banner Name"
                  value={this.state[bannerKey][screentype][i] ? this.state[bannerKey][screentype][i].name : ''}
                  className="field border-box small"
                  onChange={(e) => this.handleBannerImgkeyChange(screentype, 'name', i, e, bannerKey)}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return bannerList;
  }

  getHomePageBrandInput(key) {
    let brandList = [];
    for( let i = 0; i < this.state?.[key]?.count; i += 1) {
      brandList.push(
        <div className="brandItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Brand Item ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => {
                  if (this.state?.[key]?.count === 1) {
                    NotificationManager.error(
                      "Atleast 1 Brand Item must be present.",
                      "Error",
                      3000
                    );
                    return;
                  }
                  const tempObj = JSON.parse(JSON.stringify(this.state[key]));
                  tempObj.cms_data.splice(i, 1);
                  tempObj.count = tempObj.count - 1;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({
                    [key]: tempObj,
                  });
                }}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Select Brand
              </div>
              <MultiSelect
                options={this.state.brandList}
                labelKey="slug"
                checkbox_id="slug"
                placeholder="Select Brand"
                disabledItems={[]}
                selectedOptions={this.state?.[key]?.cms_data[i]}
                onChange={(e) => {
                  let tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].slug = e.slug;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({ [key] : tempObj });
                }}
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Sub Heading
              </div>
              <input
                type="text"
                placeholder="Sub Heading"
                value={this.state?.[key]?.cms_data[i]?.item_subheading}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].item_subheading = e.target.value;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({
                    [key]: tempObj,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return brandList;
  }

  getHomePageCategoryInput(key) {
    let categoryList = [];
    for( let i = 0; i < this.state?.[key]?.count; i += 1) {
      categoryList.push(
        <div className="categoryItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Category Item ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => {
                  if (this.state?.[key]?.count === 1) {
                    NotificationManager.error(
                      "Atleast 1 category Item must be present.",
                      "Error",
                      3000
                    );
                    return;
                  }
                  const tempObj = JSON.parse(JSON.stringify(this.state[key]));
                  tempObj.cms_data.splice(i, 1);
                  tempObj.count = tempObj.count - 1;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({
                    [key]: tempObj,
                  });
                }}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Select Catgeory
              </div>
              <MultiSelect
                options={this.state.categoriesList}
                labelKey="slug"
                checkbox_id="slug"
                placeholder="Select Category"
                disabledItems={[]}
                selectedOptions={this.state?.[key]?.cms_data[i]}
                onChange={(e) => {
                  let tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].slug = e.slug;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({ [key] : tempObj });
                }}
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Sub Heading
              </div>
              <input
                type="text"
                placeholder="Sub Heading"
                value={this.state?.[key]?.cms_data[i]?.item_subheading}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].item_subheading = e.target.value;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({
                    [key]: tempObj,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return categoryList;
  }

  getHomePageProductInput() {
    let productList = [];
    for( let i = 0; i < this.state?.products_list?.count; i += 1) {
      productList.push(
        <div className="categoryItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Product List ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => {
                  if (this.state?.products_list?.count === 1) {
                    NotificationManager.error(
                      "Atleast 1 product list must be present.",
                      "Error",
                      3000
                    );
                    return;
                  }
                  const tempObj = JSON.parse(JSON.stringify(this.state.products_list));
                  tempObj.cms_data.splice(i, 1);
                  tempObj.count = tempObj.count - 1;
                  if (this.props.content.type === "edit") new_data.products_list = tempObj;
                  else post_data.products_list = tempObj;
                  this.setState({
                    products_list: tempObj,
                  });
                }}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Enter Product Ids(seprated by ,)
              </div>
              <textarea
                className="field border-box small"
                placeholder="Product List"
                value={this.state?.products_list?.cms_data[i]?.product_ids.join(',') || ''}
                onChange={(e) => {
                  const tempObj = this.state.products_list ? JSON.parse(JSON.stringify(this.state.products_list)) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].product_ids = e.target.value.split(',');
                  if (this.props.content.type === "edit") new_data.products_list = tempObj;
                  else post_data.products_list = tempObj;
                  this.setState({
                    products_list: tempObj,
                  });
                }}
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                List Heading
              </div>
              <input
                type="text"
                placeholder="List Heading"
                value={this.state?.products_list?.cms_data[i]?.item_heading}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.products_list ? JSON.parse(JSON.stringify(this.state.products_list)) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].item_heading = e.target.value;
                  if (this.props.content.type === "edit") new_data.products_list = tempObj;
                  else post_data.products_list = tempObj;
                  this.setState({
                    products_list: tempObj,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return productList;
  }

  getHomePageVideo(key) {
    let videoList = [];
    for( let i = 0; i < this.state?.[key]?.count; i += 1) {
      const videoThumbnail = React.createRef();
      videoList.push(
        <div className="categoryItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Video Item ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => {
                  if (this.state?.[key]?.count === 1) {
                    NotificationManager.error(
                      "Atleast 1 video Item must be present.",
                      "Error",
                      3000
                    );
                    return;
                  }
                  const tempObj = JSON.parse(JSON.stringify(this.state[key]));
                  tempObj.data.splice(i, 1);
                  tempObj.count = tempObj.count - 1;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({
                    [key]: tempObj,
                  });
                }}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Video URL
              </div>
              <input
                type="text"
                placeholder="Video URL"
                value={this.state?.[key]?.data?.[i]?.link || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                  if (!tempObj.data[i]) tempObj.data[i] = {};
                  tempObj.data[i].link = e.target.value;
                  if (this.props.content.type === "edit") new_data[key] = tempObj;
                  else post_data[key] = tempObj;
                  this.setState({
                    [key]: tempObj,
                  });
                }}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Video Thumbnail Icon
              </div>
              {this.state?.[key]?.data?.[i] && this.state?.[key]?.data?.[i]?.thumbnail &&
              typeof this.state?.[key]?.data?.[i]?.thumbnail === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state?.[key]?.data?.[i]?.thumbnail}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                      if (!tempObj.data[i]) tempObj.data[i] = {};
                      tempObj.data[i].thumbnail = null;
                      // if (this.props.content.type === "edit") new_data[key] = tempObj;
                      // else post_data[key] = tempObj;
                      this.setState({
                        [key]: tempObj,
                      });
                    }}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                      if (!tempObj.data[i]) tempObj.data[i] = {};
                      tempObj.data[i].thumbnail = e.target.files[0];
                      // if (this.props.content.type === "edit") new_data[key] = tempObj;
                      // else post_data[key] = tempObj;
                      this.setState({
                        [key]: tempObj,
                      });
                    }}
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={videoThumbnail}
                      type="text"
                      placeholder="Thumnail URL"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        videoThumbnail.current.value.length &&
                        this.state?.[key]?.data?.[i]?.thumbnail &&
                        typeof this.state?.[key]?.data?.[i]?.thumbnail === "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      
                      if (videoThumbnail.current.value) {
                        const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
                        if (!tempObj.data[i]) tempObj.data[i] = {};
                        tempObj.data[i].thumbnail = videoThumbnail.current.value;
                        if (this.props.content.type === "edit") new_data[key] = tempObj;
                        else post_data[key] = tempObj;
                        this.setState({
                          [key]: tempObj,
                        });
                      } else if (
                        typeof this.state?.[key]?.data?.[i]?.thumbnail === "object"
                      ) {
                        this.uploadImageOnServer2(key, this.state?.[key]?.data?.[i]?.thumbnail, i);
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return videoList;
  }

  getHomePageDotdInput() {
    let dotdList = [];
    for( let i = 0; i < this.state?.dotd?.count; i += 1) {
      const iconRef = React.createRef();
      dotdList.push(
        <div className="categoryItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Deal of the Day Item ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => {
                  if (this.state?.dotd?.count === 1) {
                    NotificationManager.error(
                      "Atleast 1 Item must be present.",
                      "Error",
                      3000
                    );
                    return;
                  }
                  const tempObj = JSON.parse(JSON.stringify(this.state.dotd));
                  tempObj.cms_data.splice(i, 1);
                  tempObj.count = tempObj.count - 1;
                  if (this.props.content.type === "edit") new_data.dotd = tempObj;
                  else post_data.dotd = tempObj;
                  this.setState({
                    dotd: tempObj,
                  });
                }}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Item heading
              </div>
              <input
                type="text"
                placeholder="Item Heading"
                value={this.state?.dotd?.cms_data?.[i]?.item_heading || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.dotd ? JSON.parse(JSON.stringify(this.state.dotd)) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].item_heading = e.target.value;
                  if (this.props.content.type === "edit") new_data.dotd = tempObj;
                  else post_data.dotd = tempObj;
                  this.setState({
                    dotd: tempObj,
                  });
                }}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Item Icon
              </div>
              {this.state?.dotd?.cms_data?.[i] && this.state?.dotd?.cms_data?.[i]?.icon &&
              typeof this.state?.dotd?.cms_data?.[i]?.icon === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state?.dotd?.cms_data?.[i]?.icon}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      const tempObj = this.state.dotd ? JSON.parse(JSON.stringify(this.state.dotd)) : {};
                      if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                      tempObj.cms_data[i].icon = null;
                      // if (this.props.content.type === "edit") new_data[key] = tempObj;
                      // else post_data[key] = tempObj;
                      this.setState({
                        dotd: tempObj,
                      });
                    }}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const tempObj = this.state.dotd ? JSON.parse(JSON.stringify(this.state.dotd)) : {};
                      if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                      tempObj.cms_data[i].icon = e.target.files[0];
                      // if (this.props.content.type === "edit") new_data[key] = tempObj;
                      // else post_data[key] = tempObj;
                      this.setState({
                        dotd: tempObj,
                      });
                    }}
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={iconRef}
                      type="text"
                      placeholder="Icon"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        iconRef.current.value.length &&
                        this.state?.dotd?.cms_data?.[i]?.icon &&
                        typeof this.state?.dotd?.cms_data?.[i]?.icon === "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      
                      if (iconRef.current.value) {
                        const tempObj = this.state.dotd ? JSON.parse(JSON.stringify(this.state.dotd)) : {};
                        if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                        tempObj.cms_data[i].icon = iconRef.current.value;
                        if (this.props.content.type === "edit") new_data.dotd = tempObj;
                        else post_data.dotd = tempObj;
                        this.setState({
                          dotd: tempObj,
                        });
                      } else if (
                        typeof this.state?.dotd?.cms_data?.[i]?.icon === "object"
                      ) {
                        this.uploadImageOnServer2('dotd', this.state?.dotd?.cms_data?.[i]?.icon, i);
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="border-box clearfix fields">
          <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Item SubHeading
              </div>
              <input
                type="text"
                placeholder="Item SubHeading"
                value={this.state?.dotd?.cms_data?.[i]?.item_subheading || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.dotd ? JSON.parse(JSON.stringify(this.state.dotd)) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].item_subheading = e.target.value;
                  if (this.props.content.type === "edit") new_data.dotd = tempObj;
                  else post_data.dotd = tempObj;
                  this.setState({
                    dotd: tempObj,
                  });
                }}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Redirect Url
              </div>
              <input
                type="text"
                placeholder="URL"
                value={this.state?.dotd?.cms_data?.[i]?.redirect_url || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.dotd ? JSON.parse(JSON.stringify(this.state.dotd)) : {};
                  if (!tempObj.cms_data[i]) tempObj.cms_data[i] = {};
                  tempObj.cms_data[i].redirect_url = e.target.value;
                  if (this.props.content.type === "edit") new_data.dotd = tempObj;
                  else post_data.dotd = tempObj;
                  this.setState({
                    dotd: tempObj,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return dotdList;
  }

  getHomePageFilterInput() {
    let filterList = [];
    for( let i = 0; i < this.state?.filter_section?.count; i += 1) {
      filterList.push(
        <div className="categoryItem extramainpanel" style={{ margin: '10px 0 15px 0' }}>
          <div className="border-box clearfix footer">
            <div className="border-box floatleft leftalign blackspan2 shadow-text">
              {`Filter Item ${i + 1}`}
            </div>
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => {
                  if (this.state?.filter_section?.count === 1) {
                    NotificationManager.error(
                      "Atleast 1 Item must be present.",
                      "Error",
                      3000
                    );
                    return;
                  }
                  const tempObj = JSON.parse(JSON.stringify(this.state.filter_section));
                  tempObj.data.splice(i, 1);
                  tempObj.count = tempObj.count - 1;
                  if (this.props.content.type === "edit") new_data.filter_section = tempObj;
                  else post_data.filter_section = tempObj;
                  this.setState({
                    filter_section: tempObj,
                  });
                }}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Title
              </div>
              <input
                type="text"
                placeholder="Title"
                value={this.state?.filter_section?.data?.[i]?.title || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.filter_section ? JSON.parse(JSON.stringify(this.state.filter_section)) : {};
                  if (!tempObj.data[i]) tempObj.data[i] = {};
                  tempObj.data[i].title = e.target.value;
                  if (this.props.content.type === "edit") new_data.filter_section = tempObj;
                  else post_data.filter_section = tempObj;
                  this.setState({
                    filter_section: tempObj,
                  });
                }}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Filter Type
              </div>
              <input
                type="text"
                placeholder="Type"
                value={this.state?.filter_section?.data?.[i]?.type || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.filter_section ? JSON.parse(JSON.stringify(this.state.filter_section)) : {};
                  if (!tempObj.data[i]) tempObj.data[i] = {};
                  tempObj.data[i].type = e.target.value;
                  if (this.props.content.type === "edit") new_data.filter_section = tempObj;
                  else post_data.filter_section = tempObj;
                  this.setState({
                    filter_section: tempObj,
                  });
                }}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Lower Limit
              </div>
              <input
                type="text"
                placeholder="Lower Limit"
                value={this.state?.filter_section?.data?.[i]?.lower_limit || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.filter_section ? JSON.parse(JSON.stringify(this.state.filter_section)) : {};
                  if (!tempObj.data[i]) tempObj.data[i] = {};
                  tempObj.data[i].lower_limit = e.target.value;
                  if (this.props.content.type === "edit") new_data.filter_section = tempObj;
                  else post_data.filter_section = tempObj;
                  this.setState({
                    filter_section: tempObj,
                  });
                }}
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Upper Limit
              </div>
              <input
                type="text"
                placeholder="Upper Limit"
                value={this.state?.filter_section?.data?.[i]?.upper_limit || ""}
                className="field border-box small"
                onChange={(e) => {
                  const tempObj = this.state.filter_section ? JSON.parse(JSON.stringify(this.state.filter_section)) : {};
                  if (!tempObj.data[i]) tempObj.data[i] = {};
                  tempObj.data[i].upper_limit = e.target.value;
                  if (this.props.content.type === "edit") new_data.filter_section = tempObj;
                  else post_data.filter_section = tempObj;
                  this.setState({
                    filter_section: tempObj,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return filterList;
  }
  
  removeTestimonial = (testimonialKey, countKey, index) => {
    if (this.state[countKey] === 1) {
      NotificationManager.error("Atleast 1 testimonial/review must be present.", "Error", 3000);
      return;
    }
    const testimonialObj = JSON.parse(JSON.stringify(this.state[testimonialKey]));
    testimonialObj.splice(index,1);
    if (this.props.content.type === "edit") {
      if (this.props.content.tag === "searchbysku") {
        if (!new_data.product_update) new_data.product_update = {};
        new_data.product_update[testimonialKey] = testimonialObj;
      } else new_data[testimonialKey] = testimonialObj;
    }
    else post_data[testimonialKey] = testimonialObj;
    this.setState({
      [testimonialKey]: testimonialObj,
      [countKey]: this.state[countKey] -1,
    });
  }

  removeFAQ = (index) => {
    if (this.state.faqCount === 1) {
      NotificationManager.error("Atleast 1 FAQ must be present.", "Error", 3000);
      return;
    }
    const faqObj = JSON.parse(JSON.stringify(this.state.faq));
    faqObj.splice(index,1);
    if (this.props.content.type === "edit") {
      if (this.props.content.tag === "searchbysku") {
        if (!new_data.product_update) new_data.product_update = {};
        new_data.product_update.faq = faqObj;
      } else new_data.faq = faqObj;
    }
    else post_data.faq = faqObj;
    this.setState({
      faq: faqObj,
      faqCount: this.state.faqCount -1,
    });
  }

  removeVideo = (index) => {
    const videoOBJ = JSON.parse(JSON.stringify(this.state.video));
    videoOBJ.splice(index,1);
    if (this.props.content.type === "edit") {
      if (this.props.content.tag === "searchbysku") {
        if (!new_data.product_update) new_data.product_update = {};
        new_data.product_update.video = videoOBJ;
      } else new_data.video = videoOBJ;
    }
    else post_data.video = videoOBJ;
    this.setState({
      video: videoOBJ,
      videoCount: this.state.videoCount -1,
    });
  }

  removePromotionalBanner = (index) => {
    if (this.state.pbannerCount === 1) {
      NotificationManager.error("Atleast 1 Promotional Banner must be present.", "Error", 3000);
      return;
    }
    const bannerObj = JSON.parse(JSON.stringify(this.state.promotional_banners));
    bannerObj.splice(index,1);
    if (this.props.content.type === "edit") new_data.promotional_banners = bannerObj;
    else post_data.promotional_banners = bannerObj;
    this.setState({
      promotional_banners: bannerObj,
      pbannerCount: this.state.pbannerCount - 1,
    });
  }

  removeStoreImg = (index) => {
    if (this.state.storeImgCount === 1) {
      NotificationManager.error(
        "Atleast 1 Store Image must be present.",
        "Error",
        3000
      );
      return;
    }
    const storeObj = JSON.parse(JSON.stringify(this.state.store_data));
    storeObj.store_images.splice(index, 1);
    if (this.props.content.type === "edit") new_data.store_data = storeObj;
    else post_data.store_data = storeObj;
    this.setState({
      store_data: storeObj,
      storeImgCount: this.state.storeImgCount - 1,
    });
  };

  removePopularCategoryImg = (index) => {
    if (this.state.categoryCount === 1) {
      NotificationManager.error(
        "Atleast 1 popular category Image must be present.",
        "Error",
        3000
      );
      return;
    }
    const popularCategoryObj = JSON.parse(
      JSON.stringify(this.state.popular_category_images)
    );
    popularCategoryObj.splice(index, 1);
    if (this.props.content.type === "edit") new_data.popular_category_images = popularCategoryObj;
    else post_data.popular_category_images = popularCategoryObj;
    this.setState({
      popular_category_images: popularCategoryObj,
      categoryCount: this.state.categoryCount - 1,
    });
  };

  removeBannerImg = (screentype, countKey, index, bannerKey = 'banner') => {
    if (this.state[countKey] === 1) {
      NotificationManager.error(
        "Atleast 1 Banner Image must be present.",
        "Error",
        3000
      );
      return;
    }
    const bannerObj = JSON.parse(JSON.stringify(this.state[bannerKey]));
    bannerObj[screentype].splice(index, 1);
    if (this.props.content.type === "edit") new_data[bannerKey] = bannerObj;
    else post_data[bannerKey] = bannerObj;
    this.setState({
      [bannerKey]: bannerObj,
      [countKey]: this.state[countKey] - 1,
    });
  };

  handleObjectkeyChange(fieldName, keyName, e) {
    const tempObj = this.state[fieldName] ? JSON.parse(JSON.stringify(this.state[fieldName])) : {};
    tempObj[keyName] = e.target.value;
    if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
    else post_data[fieldName] = tempObj;
    this.setState({
      [fieldName]: tempObj,
    });
  }

  handleArraykeyChange(fieldName, keyName, index, e) {
    const tempArr = this.state[fieldName] ? JSON.parse(JSON.stringify(this.state[fieldName])) : [];
    if (!tempArr[index]) tempArr[index] = {};
    tempArr[index][keyName] = keyName === 'is_visible' ? Number(e.target.value) : e.target.value;
    if (this.props.content.type === "edit") {
      if (this.props.content.tag === "searchbysku") {
        if (!new_data.product_update) new_data.product_update = {};
        new_data.product_update[fieldName] = tempArr;
      } else new_data[fieldName] = tempArr;
    } else post_data[fieldName] = tempArr;
    this.setState({
      [fieldName]: tempArr,
    });
  }

  handleStoreImgkeyChange(keyName, index, e) {
    const tempArr = this.state.store_data ? JSON.parse(JSON.stringify(this.state.store_data)) : [];
    if (!tempArr.store_images[index]) tempArr.store_images[index] = {};
    tempArr.store_images[index][keyName] = e.target.value;
    if (this.props.content.type === "edit") new_data.store_data = tempArr;
    else post_data.store_data = tempArr;
    this.setState({
      store_data: tempArr,
    });
  }

  handleBannerImgkeyChange(screentype, keyName, index, e, bannerKey = 'banner') {
    const tempArr = this.state[bannerKey] ? JSON.parse(JSON.stringify(this.state[bannerKey])) : [];
    if (!tempArr[screentype][index]) tempArr[screentype][index] = {};
    tempArr[screentype][index][keyName] = e.target.value;
    if (this.props.content.type === "edit") new_data[bannerKey] = tempArr;
    else post_data[bannerKey] = tempArr;
    this.setState({
      [bannerKey]: tempArr,
    });
  }

  deleteStoreImg (index) {
    const tempObj = JSON.parse(JSON.stringify(this.state.store_data));
      tempObj.store_images[index].img_url = null;
      if (this.props.content.type === "edit") new_data.store_data = tempObj;
      else post_data.store_data = tempObj;
      this.setState({
        store_data: tempObj,
      });
  }

  deleteImage (fieldName, keyName, index, isHome) {
    if (keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      if (isHome) {
        tempObj[index][keyName] = null;
      } else tempObj[keyName].splice(index, 1);
      if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
      else post_data[fieldName] = tempObj;
      this.setState({
        [fieldName]: tempObj,
      });
    } else if (!keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      tempObj.splice(index, 1);
      if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
      else post_data[fieldName] = tempObj;
      this.setState({
        [fieldName]: tempObj,
      });
    } else {
      if (this.props.content.type === "edit") new_data[fieldName] = {
        ...new_data[fieldName],
        [keyName]: null,
      };
      else post_data[fieldName] = {
        ...post_data[fieldName],
        [keyName]: null,
      };
      this.setState({
        [fieldName]: {
          ...this.state[fieldName],
          [keyName]: null,          
        }
      });
    }
  }

  deleteBannerImage (screentype, index, noRedirect, bannerKey = 'banner') {
    const tempObj = JSON.parse(JSON.stringify(this.state[bannerKey]));
    if (noRedirect) tempObj[screentype][index] = null;
    else tempObj[screentype][index].banner_url = null;
    if (this.props.content.type === "edit") new_data[bannerKey] = tempObj;
    else post_data[bannerKey] = tempObj;
    this.setState({
      [bannerKey]: tempObj,
    });
  }

  onInputStoreImage (e, index) {
    const tempObj = JSON.parse(JSON.stringify(this.state.store_data));
    if (!tempObj.store_images[index]) tempObj.store_images[index] = {};
    tempObj.store_images[index].img_url = e.target.files[0];
    if (this.props.content.type === "edit") new_data.store_data = tempObj;
    else post_data.store_data = tempObj;
    this.setState({
      store_data: tempObj,
    });
  }

  onInputImage (fieldName, keyName, e, index, isHome) {
    if (keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      if (isHome) {
        if (!tempObj[index]) tempObj[index] = {};
        tempObj[index][keyName]=e.target.files[0];
      } else tempObj[keyName][index]=e.target.files[0];
      if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
      else post_data[fieldName] = tempObj;
      this.setState({
        [fieldName]: tempObj,
      });
    } else if (!keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      tempObj[index]=e.target.files[0];
      if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
      else post_data[fieldName] = tempObj;
      this.setState({
        [fieldName]: tempObj,
      });
    } else {
      if (this.props.content.type === "edit") new_data[fieldName] = {
        ...new_data[fieldName],
        [keyName]: e.target.files[0],  
      };
      else post_data[fieldName] = {
        ...post_data[fieldName],
        [keyName]: e.target.files[0],   
      };
      this.setState({
        [fieldName]: {
          ...this.state[fieldName],
          [keyName]: e.target.files[0],          
        }
      });
    }
  }

  onInputBannerImage(screentype, e, index, noRedirect, bannerKey = 'banner') {
    const tempObj = JSON.parse(JSON.stringify(this.state[bannerKey]));
    if (!tempObj[screentype][index]) tempObj[screentype][index] = {};
    if (noRedirect) tempObj[screentype][index] = e.target.files[0];
    else tempObj[screentype][index].banner_url = e.target.files[0];
    if (this.props.content.type === "edit") new_data[bannerKey] = tempObj;
    else post_data[bannerKey] = tempObj;
    this.setState({
      [bannerKey]: tempObj,
    });
  }

  uploadStoreImageOnServer ( file, index) {
    if (!file) {
      NotificationManager.error(
        `Please select an image first`,
        'Error!!',
        5000
      );
      return;
    }
    let params;
    params = new FormData()
    params.append('image', file)
    API.uploadAccessoriesImage(params)
      .then((response) => {
        if (response.data.data.img_url){
            const tempObj = JSON.parse(JSON.stringify(this.state.store_data));
            tempObj.store_images[index].img_url = response.data.data.img_url;
            if (this.props.content.type === "edit") new_data.store_data = tempObj;
            else post_data.store_data = tempObj;
            this.setState({
              store_data: tempObj,
            });
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || 'Error Occured',
          'Error!!',
          5000
        );
      })
  }

  uploadImageOnServer (fieldName, keyName, file, index, isHome, productData) {
    if (!file) {
      NotificationManager.error(
        `Please select an image first`,
        'Error!!',
        5000
      );
      return;
    }
    // if (fieldName.includes('banner') && parseFloat(file.size / 1024).toFixed(2) > 150) {
    //   NotificationManager.error(
    //     `Size of banner should be less than 150kb`,
    //     'Error!!',
    //     5000
    //   );
    //   return;
    // }
    // size check if required
    let params;
    params = new FormData()
    params.append('image', file)
    API.uploadAccessoriesImage(params)
      .then((response) => {
        if (response.data.data.img_url){
          if (productData){
            if (productData.index) {
              this.handleArraykeyChange(
                keyName,
                fieldName,
                productData.index,
                { target: { value: response.data.data.img_url } },
              );
            } else {
              this.handleProductFieldChange(
                keyName,
                { target: { value: response.data.data.img_url } },
                productData.type,
                productData.fieldType,
                fieldName,
              );
            }
            return;
          }
          if (!fieldName && keyName) {
            if (this.props.content.type === "edit") new_data[keyName] = response.data.data.img_url;
            else post_data[keyName] = response.data.data.img_url;
            this.setState({
              [keyName]: response.data.data.img_url,
            });
          }
          if (keyName && typeof index !== "undefined") {
            const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
            if (isHome) {
              if (!tempObj[index]) tempObj[index] = {};
              tempObj[index][keyName]= response.data.data.img_url;
            } else tempObj[keyName][index]=response.data.data.img_url;
            if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
            else post_data[fieldName] = tempObj;
            this.setState({
              [fieldName]: tempObj,
            });
          } else if (!keyName && typeof index !== "undefined") {
            const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
            tempObj[index]=response.data.data.img_url;
            if (this.props.content.type === "edit") new_data[fieldName] = tempObj;
            else post_data[fieldName] = tempObj;
            this.setState({
              [fieldName]: tempObj,
            });
          } else {
            if (this.props.content.type === "edit") new_data[fieldName] = {
              ...new_data[fieldName],
              [keyName]: response.data.data.img_url, 
            };
            else post_data[fieldName] = {
              ...post_data[fieldName],
              [keyName]: response.data.data.img_url, 
            };
            this.setState({
              [fieldName]: {
                ...this.state[fieldName],
                [keyName]: response.data.data.img_url,          
              }
            });
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || 'Error Occured',
          'Error!!',
          5000
        );
      })
  }

  uploadBannerImageOnServer(file, screentype, index, noRedirect, bannerKey = 'banner') {
    if (!file) {
      NotificationManager.error(
        `Please select an image first`,
        'Error!!',
        5000
      );
      return;
    }
    let params;
    params = new FormData()
    params.append('image', file)
    API.uploadAccessoriesImage(params)
      .then((response) => {
        if (response.data.data.img_url){
            const tempObj = JSON.parse(JSON.stringify(this.state[bannerKey]));
            if (noRedirect) tempObj[screentype][index] = response.data.data.img_url;
            else tempObj[screentype][index].banner_url = response.data.data.img_url;
            if (this.props.content.type === "edit") new_data[bannerKey] = tempObj;
            else post_data[bannerKey] = tempObj;
            this.setState({
              [bannerKey]: tempObj,
            });
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || 'Error Occured',
          'Error!!',
          5000
        );
      })
  }

  uploadCollectionFile(file) {
    if (!file) {
      NotificationManager.error(
        `Please select an excel file first`,
        'Error!!',
        5000
      );
      return;
    }
    let params;
    params = new FormData()
    params.append('file', file)
    API.uploadAccessoriesProductFromFile(params)
      .then((response) => {
        let x = [];
        let y = [];
        if (this.state.sku_codes && this.state.sku_codes.length > 0) {
          x = [...this.state.sku_codes];
        }
        if (
          this.state.collection_products &&
          this.state.collection_products.length > 0
        ) {
          y = [...this.state.collection_products];
        }
        if (response.data.data && response.data.data.length) {
          x = [
            ...x,
            ...response.data.data.map(item => item.product_id),
          ];
          y = [
            ...y,
            ...response.data.data,
          ];
          this.setState({ sku_codes: x, product: null, collection_products: y });
          // if (this.props.content.tag === 'searchbysku' && this.props.content.type === "edit") {
          //   if (!new_data.product_update) new_data.product_update = {};
          //   new_data.product_update.frequently_bought_together = x;
          // }
        } else if (response.data.data.product_id) {
          x.push(response.data.data.product_id);
          y.push(response.data.data);
          this.setState({ sku_codes: x, product: null, collection_products: y });
          // if (this.props.content.tag === 'searchbysku' && this.props.content.type === "edit") {
          //   if (!new_data.product_update) new_data.product_update = {};
          //   new_data.product_update.frequently_bought_together = x;
          // }
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || 'Error Occured',
          'Error!!',
          5000
        );
      })
  }

  uploadImageOnServer2 (key, file, index) {
    if (!file) {
      NotificationManager.error(
        `Please select an image first`,
        'Error!!',
        5000
      );
      return;
    }
    // if (fieldName.includes('banner') && parseFloat(file.size / 1024).toFixed(2) > 150) {
    //   NotificationManager.error(
    //     `Size of banner should be less than 150kb`,
    //     'Error!!',
    //     5000
    //   );
    //   return;
    // }
    // size check if required
    let params;
    params = new FormData()
    params.append('image', file)
    API.uploadAccessoriesImage(params)
      .then((response) => {
        if (response.data.data.img_url){
          const tempObj = this.state[key] ? JSON.parse(JSON.stringify(this.state[key])) : {};
          if (key.includes('video')) {
            if (!tempObj.data[index]) tempObj.data[index] = {};
            tempObj.data[index].thumbnail = response.data.data.img_url;
          } else {
            if (!tempObj.cms_data[index]) tempObj.cms_data[index] = {};
            tempObj.cms_data[index].icon = response.data.data.img_url;
          }
          if (this.props.content.type === "edit") new_data[key] = tempObj;
          else post_data[key] = tempObj;
          this.setState({
            [key]: tempObj,
          });
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || 'Error Occured',
          'Error!!',
          5000
        );
      })
  }

  render() {
    var that = this;
    if (that.props.content.type === "delete") {
      return (
        <div
          id="dashboardprimeextra"
          className="border-box bgwhite1 shadow-box"
        >
          <div className="extramainpanel border-box leftalign">
            <div className="border-box clearfix title">
              <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                Delete
              </div>
              <div className="border-box floatright rightalign">
                <button
                  className="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("close")}
                >
                  Close
                </button>
              </div>
            </div>

            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7 rightalign">
                  Are you sure you want to delete this entry?
                </div>
              </div>
            </div>
            <div className="border-box clearfix footer">
              <div className="border-box floatright rightalign">
                <button
                  className="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("save")}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.content.type === "editimages") {
      switch (this.props.content.tag) {
        case "segments":
          return (
            <>
              <ImageUpload
                data={that.props.content.content}
                type={that.props.content.tag}
                onClose={() => that.handleButtonClick("close")}
              ></ImageUpload>
            </>
          );
        case "categories":
          return (
            <>
              <ImageUpload
                data={that.props.content.content}
                type={that.props.content.tag}
                onClose={() => that.handleButtonClick("close")}
              ></ImageUpload>
            </>
          );
        case "searchbysku":
          return (
            <>
              <ImageUpload
                data={that.props.content.content.variants}
                type={that.props.content.tag}
                onClose={() => that.handleButtonClick("close")}
              ></ImageUpload>
            </>
          );
        default: // console.log("No case match")
      }
    } else if (this.props.content.type === "addRelated") {
      if (this.props.content.tag === "searchbysku") {
        return (
          <div
            id="dashboardprimeextra"
            className="border-box bgwhite1 shadow-box"
          >
            <div className="extramainpanel border-box leftalign">
              <div className="border-box clearfix title">
                <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                  Related Products
                </div>
                <div className="border-box floatright rightalign">
                  <button
                    className="btn btn_small btncolorprim btncard move btncloseextra"
                    onClick={() => this.handleButtonClick("close")}
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="border-box fields">
                <div className="border-box field2">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Similar Products
                  </div>
                  {this.state.similarProducts && (
                    <MultiSelect
                      options={this.state.similarProducts}
                      labelKey="display_name"
                      checkbox_id="sku_code"
                      placeholder="Similar Products"
                      disabledItems={[]}
                      multiple
                      selectedOptions={this.state.selectedSimilarProducts}
                      onChange={(e) => {
                        this.setState({ selectedSimilarProducts: e });
                      }}
                    />
                  )}
                </div>
                <div className="border-box field2">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Customer Liked
                  </div>
                  {this.state.customerLike && (
                    <MultiSelect
                      options={this.state.customerLike}
                      labelKey="display_name"
                      checkbox_id="sku_code"
                      placeholder="Customer Liked Products"
                      multiple
                      disabledItems={[]}
                      selectedOptions={this.state.selectedCustomerLike}
                      onChange={(e) => {
                        this.setState({ selectedCustomerLike: e });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="border-box fields">
                <div className="border-box field2">
                  <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                    Recommended Products
                  </div>
                  {this.state.recommendedProducts && (
                    <MultiSelect
                      options={this.state.recommendedProducts}
                      labelKey="display_name"
                      checkbox_id="sku_code"
                      placeholder="Recommended Products"
                      disabledItems={[]}
                      multiple
                      selectedOptions={this.state.selectedRecommendedProducts}
                      onChange={(e) => {
                        this.setState({ selectedRecommendedProducts: e });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="border-box clearfix footer">
                <div className="border-box floatright rightalign">
                  <button
                    className="btn btn_small btncolorprim btncard move btncloseextra"
                    onClick={() => this.handleButtonClick("addRelated")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (this.props.content.type === "installationService") {
      if (this.props.content.tag === "searchbysku") {
        return (
          <ServiceInstallation
            handleButtonClick={this.handleButtonClick}
            productDetail={this.props.content.content}
            type="add"
            pageRefresh={() =>
              this.props.onMiddleExtraButtonClick("save", null)
            }
          />
        );
      }
    } else {
      switch (this.props.content.tag) {
        case "homepage":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta description"
                      value={this.state.meta_description}
                      onChange={(e) => this.handleChange("meta_description", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Keywors
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Keywords"
                      value={this.state.keywords}
                      onChange={(e) => this.handleChange("keywords", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Upper Banner Details
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.upper_banner?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('upper_banner', 'is_visible', obj)
                      }}
                    />
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Desktop Banner
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({upper_banner_desktopCnt: (this.state.upper_banner_desktopCnt + 1)})}
                        >
                          Add Desktop Banner
                        </button>
                      </div>
                      {this.getBannerList('desktop', 'upper_banner_desktopCnt', false, 'upper_banner', true)}
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Mobile Banner
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({upper_banner_mobileCnt: (this.state.upper_banner_mobileCnt + 1)})}
                        >
                          Add Mobile Banner
                        </button>
                      </div>
                      {this.getBannerList('mobile', 'upper_banner_mobileCnt', false, 'upper_banner', true)}
                    </div>
                    {/* <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner Heading"
                          value={this.state.banner ? this.state.banner.heading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'heading', e)}
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner SubHeading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner SubHeading"
                          value={this.state.banner ? this.state.banner.subheading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'subheading', e)}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Promotional Upper Banner Details
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.promotional_upper_banner?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('promotional_upper_banner', 'is_visible', obj)
                      }}
                    />
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Desktop Banner
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({promotional_upper_banner_desktopCnt: (this.state.promotional_upper_banner_desktopCnt + 1)})}
                        >
                          Add Desktop Banner
                        </button>
                      </div>
                      {this.getBannerList('desktop', 'promotional_upper_banner_desktopCnt', false, 'promotional_upper_banner', true)}
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Mobile Banner
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({promotional_upper_banner_mobileCnt: (this.state.promotional_upper_banner_mobileCnt + 1)})}
                        >
                          Add Mobile Banner
                        </button>
                      </div>
                      {this.getBannerList('mobile', 'promotional_upper_banner_mobileCnt', false, 'promotional_upper_banner', true)}
                    </div>                    
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Collection Page Banner Details
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.collection_page_banner?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('collection_page_banner', 'is_visible', obj);
                      }}
                    />
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Desktop Banner
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({collection_page_banner_desktopCnt: (this.state.collection_page_banner_desktopCnt + 1)})}
                        >
                          Add Desktop Banner
                        </button>
                      </div>
                      {this.getBannerList('desktop', 'collection_page_banner_desktopCnt', false, 'collection_page_banner', true)}
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Mobile Banner
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({collection_page_banner_mobileCnt: (this.state.collection_page_banner_mobileCnt + 1)})}
                        >
                          Add Mobile Banner
                        </button>
                      </div>
                      {this.getBannerList('mobile', 'collection_page_banner_mobileCnt', false, 'collection_page_banner', true)}
                    </div>                    
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Payment &amp; Offer Banner Section
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.payment_offers_banner.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('payment_offers_banner', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.payment_offers_banner?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('payment_offers_banner', 'is_visible', obj)
                      }}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Sponsored Brand Section
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.sponsered_brands.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('sponsered_brands', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.sponsered_brands?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('sponsered_brands', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({sponsered_brands: { ...this.state.sponsered_brands, count: this.state.sponsered_brands.count + 1 }})}
                    >
                      Add Sponsored Brand
                    </button>
                  </div>
                  {this.getHomePageBrandInput('sponsered_brands')}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Unsponsored Brand Section
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.unsponsered_brands.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('unsponsered_brands', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.unsponsered_brands?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('unsponsered_brands', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({unsponsered_brands: { ...this.state.unsponsered_brands, count: this.state.unsponsered_brands.count + 1 }})}
                    >
                      Add Unsponsored Brand
                    </button>
                  </div>
                  {this.getHomePageBrandInput('unsponsered_brands')}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Top Category
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.top_categories.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('top_categories', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.top_categories?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('top_categories', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({top_categories: { ...this.state.top_categories, count: this.state.top_categories.count + 1 }})}
                    >
                      Add Top Category
                    </button>
                  </div>
                  {this.getHomePageCategoryInput('top_categories')}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Remaining Category
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.remaining_categories.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('remaining_categories', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.remaining_categories?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('remaining_categories', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({remaining_categories: { ...this.state.remaining_categories, count: this.state.remaining_categories.count + 1 }})}
                    >
                      Add Remaining Category
                    </button>
                  </div>
                  {this.getHomePageCategoryInput('remaining_categories')}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Product List
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.products_list.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('products_list', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.products_list?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('products_list', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({products_list: { ...this.state.products_list, count: this.state.products_list.count + 1 }})}
                    >
                      Add Product List
                    </button>
                  </div>
                  {this.getHomePageProductInput()}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Upper Video Section
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.upper_video_section.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('upper_video_section', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.upper_video_section?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('upper_video_section', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({upper_video_section: { ...this.state.upper_video_section, count: this.state.upper_video_section.count + 1 }})}
                    >
                      Add Video
                    </button>
                  </div>
                  {this.getHomePageVideo('upper_video_section')}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Lower Video Section
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.lower_video_section.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('lower_video_section', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.lower_video_section?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('lower_video_section', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({lower_video_section: { ...this.state.lower_video_section, count: this.state.lower_video_section.count + 1 }})}
                    >
                      Add Video
                    </button>
                  </div>
                  {this.getHomePageVideo('lower_video_section')}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Deal Of The Day
                    </div>
                  </div>
                  {/* <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.products_list.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('products_list', 'section_heading', e)}
                    />
                  </div> */}
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.dotd?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('dotd', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({dotd: { ...this.state.dotd, count: this.state.dotd.count + 1 }})}
                    >
                      Add Item
                    </button>
                  </div>
                  {this.getHomePageDotdInput()}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix subtitle">
                    <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                      Filter Section
                    </div>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.filter_section.section_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange('filter_section', 'section_heading', e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is visible
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id={"is_visible_payment"}
                      name="is_visible"
                      checked={this.state?.filter_section?.is_visible || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleObjectkeyChange('filter_section', 'is_visible', obj)
                      }}
                    />
                  </div>
                  <div className="border-box rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({filter_section: { ...this.state.filter_section, count: this.state.filter_section.count + 1 }})}
                    >
                      Add Item
                    </button>
                  </div>
                  {this.getHomePageFilterInput()}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.bottom_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("bottom_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Testimonial Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({testimonialCount: (this.state.testimonialCount + 1)})}
                    >
                      Add Testimonial
                    </button>
                  </div>
                </div>
                <div className="testimonialContainer">
                  {this.getTestimonialList('testimonials', 'testimonialCount', true)}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                  <div className="faqContainer">
                    {this.getFAQList()}
                  </div>
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "segments":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Name
                    </div>
                    <input
                      type="text"
                      placeholder="Segment Name"
                      value={this.state.name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Slug
                    </div>
                    <input
                      type="text"
                      placeholder="Url Page Path"
                      value={this.state.slug}
                      className="field border-box small"
                      readOnly={this.props.content.type !== "add"}
                      onChange={(e) => this.handleChange("slug", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page name
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page title
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Display Name
                    </div>
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={this.state.display_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("display_name", e)}
                    />
                  </div>
                  {this.props.content.type === "add" && (
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Google Category ID
                      </div>
                      <input
                        type="text"
                        placeholder="Google Category ID"
                        value={this.state.google_category_id}
                        className="field border-box small"
                        onChange={(e) =>
                          this.handleChange("google_category_id", e)
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta description"
                      onChange={(e) => this.handleChange("meta_description", e)}
                      value={this.state.meta_description}
                    />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Main Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Desktop Banner
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({webBannerCnt: (this.state.webBannerCnt + 1)})}
                        >
                          Add Desktop Banner
                        </button>
                      </div>
                      {this.getBannerList('desktop', 'webBannerCnt')}
                      {/* <div
                        className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                        style={{ marginLeft: '20px' }}
                      >
                        Mobile Banner
                      </div> */}
                      {/* <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({mobileBannerCnt: (this.state.mobileBannerCnt + 1)})}
                        >
                          Add Mobile Banner
                        </button>
                      </div>
                      {this.getBannerList('mobile', 'mobileBannerCnt')} */}
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner Heading"
                          value={this.state.banner ? this.state.banner.heading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'heading', e)}
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner SubHeading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner SubHeading"
                          value={this.state.banner ? this.state.banner.subheading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'subheading', e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.content.type !== 'add' ? (
                  <div className="border-box fields">
                    {this.state.segmentCategories ? (
                      <div className="border-box field1">
                        <div className="border-box uppercased fieldtitle weight700 blackspan7">
                          Popular Categories
                        </div>
                        <MultiSelect
                          options={this.state.segmentCategories}
                          labelKey="name"
                          checkbox_id="name"
                          placeholder=""
                          disabledItems={[]}
                          multiple
                          hideOnBlur
                          selectedOptions={this.state.popularCategory}
                          onChange={(e) => {
                            let tempArr = [];
                            if (e.length)
                              tempArr = e.map((item) => item._id);
                            if (this.props.content.type === "edit") {
                              new_data.popular_category_ids = tempArr;
                            }
                            else {
                              post_data.popular_category_ids = tempArr
                            }
                            this.setState({ popularCategory: e });
                          }}
                        />
                      </div>
                    ) : null}
                    {this.state.segmentProducts ? (
                      <div className="border-box field1">
                        <div className="border-box uppercased fieldtitle weight700 blackspan7">
                          Select Products for price list
                        </div>                                    
                          <MultiSelect
                            options={this.state.segmentProducts}
                            labelKey="display_name"
                            checkbox_id="sku_code"
                            placeholder="Product List"
                            multiple
                            disabledItems={[]}
                            selectedOptions={this.state.priceList}
                            onChange={(e) => {
                              let tempArr = [];
                              if (e.length)
                                tempArr = e.map((item) => item.product_id);
                              if (this.props.content.type === "edit") {
                                new_data.pricelist_product_ids = tempArr;
                              }
                              else {
                                post_data.pricelist_product_ids = tempArr
                              }
                              this.setState({priceList: e})
                            }}
                          />          
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box uppercased fieldtitle weight700 blackspan7">
                      Patner Brands
                    </div>
                    {
                      this.state.brandList && <MultiSelect
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder=""
                        disabledItems={[]}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.partnerBrands}
                        onChange={(e) => {
                          let tempArr = [];
                          if (e.length)
                            tempArr = e.map((item) => item._id);
                          if (this.props.content.type === "edit") {
                            new_data.partner_brand_ids = tempArr;
                          }
                          else {
                            post_data.partner_brand_ids = tempArr
                          }
                          this.setState({ partnerBrands: e });
                        }}
                      />
                    }
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.bottom_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("bottom_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Testimonial Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({testimonialCount: (this.state.testimonialCount + 1)})}
                    >
                      Add Testimonial
                    </button>
                  </div>
                </div>
                <div className="testimonialContainer">
                  {this.getTestimonialList('testimonials', 'testimonialCount')}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                </div>
                <div className="faqContainer">
                  {this.getFAQList()}
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "categories":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Category Name
                    </div>
                    <input
                      type="text"
                      placeholder="Category Name"
                      value={this.state.name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Slug
                    </div>
                    <input
                      type="text"
                      placeholder="Url page path"
                      value={this.state.slug}
                      readOnly={this.props.content.type !== "add"}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("slug", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page name
                    </div>
                    <input
                      type="text"
                      placeholder="Page Name"
                      value={this.state.page_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Parent Category
                    </div>
                    {this.state.categoriesList && (
                      <MultiSelect
                        options={this.state.categoriesList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Category"
                        disabledItems={[]}
                        selectedOptions={{
                          name: this.state.parent_category_name,
                        }}
                        onChange={(e) => {
                          if (this.props.content.type === "edit") {
                            new_data.parent_category_name = e.name;
                          }
                          else {
                            post_data.parent_category_name = e.name;
                          }
                          this.setState({...this.state, parent_category_name: e.name});
                        }}
                      />
                    )}
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Google Category ID
                    </div>
                    <input
                      type="text"
                      placeholder="Google Category ID"
                      value={this.state.google_category_id}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("google_category_id", e)
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Popular
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_popular1"
                      name="is_popular"
                      value="1"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.is_popular = Number(e.target.value);
                        }
                        else {
                          post_data.is_popular = Number(e.target.value);
                        }
                        this.setState({is_popular: Number(e.target.value)});
                      }}
                      checked={this.state.is_popular}
                    />
                    <label for="is_popular1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_popular2"
                      name="is_popular"
                      value="0"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.is_popular = Number(e.target.value);
                        }
                        else {
                          post_data.is_popular = Number(e.target.value);
                        }
                        this.setState({is_popular: Number(e.target.value)});
                      }}
                      checked={!this.state.is_popular}
                    />
                    <label for="is_popular2">No</label>
                  </div>
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Star Category
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="star_category1"
                      name="star_category"
                      value="1"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.star_category = Number(e.target.value);
                        }
                        else {
                          post_data.star_category = Number(e.target.value);
                        }
                        this.setState({star_category: Number(e.target.value)});
                      }}
                      checked={this.state.star_category}
                    />
                    <label for="star_category1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="star_category2"
                      name="star_category"
                      value="0"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.star_category = Number(e.target.value);
                        }
                        else {
                          post_data.star_category = Number(e.target.value);
                        }
                        this.setState({star_category: Number(e.target.value)});
                      }}
                      checked={!this.state.star_category}
                    />
                    <label for="star_category2">No</label>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta description"
                      value={this.state.meta_description}
                      onChange={(e) => this.handleChange("meta_description", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Variable Fields
                    </div>
                    <input
                      type="text"
                      className="field border-box small"
                      placeholder="Variable Fields"
                      value={this.state.variable_text}
                      onChange={(e) => this.handleChange("variable_text", e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          let temp = this.state.variable_fields;
                          temp.push(e.target.value.trim());
                          if (this.props.content.type === "edit") {
                            new_data.variable_fields = temp;
                            delete new_data.variable_text;
                          }
                          else {
                            post_data.variable_fields = temp;
                            delete post_data.variable_text;
                          }
                          this.setState({variable_fields: temp, variable_text: ""});
                        }
                      }}
                    />
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {this.state.variable_fields &&
                        this.state.variable_fields.map((item) => (
                          <Fragment>
                            <li
                              key={item}
                              style={{
                                width: "150px",
                                display: "inline-block",
                                padding: "5px",
                                margin: "5px",
                                fontSize: "15px",
                              }}
                              className="choiceboxmultiinnerselected"
                            >
                              <span>{item}</span>
                              <span
                                style={{
                                  float: "right",
                                  display: "inline-block",
                                  padding: "0px 5px",
                                  background: "#ccc",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let temp = this.state.variable_fields
                                  temp.splice(temp.indexOf(item), 1)
                                  if (this.props.content.type === "edit") {
                                    new_data.variable_fields = temp;
                                  }
                                  else {
                                    post_data.variable_fields = temp;
                                  }
                                  this.setState({variable_fields: temp})
                                }}
                              >
                                x
                              </span>
                            </li>
                          </Fragment>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Show on Listing
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="show_on_listing1"
                      name="show_on_listing"
                      value="1"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.show_on_listing = Number(e.target.value);
                        }
                        else {
                          post_data.show_on_listing = Number(e.target.value);
                        }
                        this.setState({show_on_listing: Number(e.target.value)});
                      }}
                      checked={this.state.show_on_listing}
                    />
                    <label for="show_on_listing1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="show_on_listing2"
                      name="show_on_listing"
                      value="0"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.show_on_listing = Number(e.target.value);
                        }
                        else {
                          post_data.show_on_listing = Number(e.target.value);
                        }
                        this.setState({show_on_listing: Number(e.target.value)});
                      }}
                      checked={!this.state.show_on_listing}
                    />
                    <label for="show_on_listing2">No</label>
                  </div>
                  <div className="border-box field2 clearfix">  
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Show Products &amp; Brands Count
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="showCount1"
                      name="show_brands_products_count"
                      value="1"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.show_brands_products_count = Number(e.target.value);
                        }
                        else {
                          post_data.show_brands_products_count = Number(e.target.value);
                        }
                        this.setState({show_brands_products_count: Number(e.target.value)});
                      }}
                      checked={this.state.show_on_listing}
                    />
                    <label for="showCount1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="showCount2"
                      name="show_brands_products_count"
                      value="0"
                      onChange={(e) => {
                        if (this.props.content.type === "edit") {
                          new_data.show_brands_products_count = Number(e.target.value);
                        }
                        else {
                          post_data.show_brands_products_count = Number(e.target.value);
                        }
                        this.setState({show_brands_products_count: Number(e.target.value)});
                      }}
                      checked={!this.state.show_on_listing}
                    />
                    <label for="showCount2">No</label>
                  </div>  
                </div>
                <div className="border-box clearfix fields">  
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Display Name
                    </div>
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={this.state.display_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("display_name", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">  
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Attributes
                    </div>
                    <input
                      type="text"
                      className="field border-box small"
                      placeholder="Add value and press enter"
                      value={this.state.attribute_text}
                      onChange={(e) => this.handleChange("attribute_text", e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          let temp = this.state.attributes;
                          temp.push(e.target.value.trim());
                          if (this.props.content.type === "edit") {
                            new_data.attributes = temp;
                            delete new_data.attribute_text;
                          }
                          else {
                            post_data.attributes = temp;
                            delete post_data.attribute_text;
                          }
                          this.setState({attributes: temp, attribute_text: ""});
                        }
                      }}
                    />
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {this.state.attributes &&
                        this.state.attributes.map((item) => (
                          <Fragment>
                            <li
                              key={item}
                              style={{
                                padding: "5px",
                                fontSize: "15px",
                              }}
                              className="choiceboxmultiinnerselected"
                            >
                              <span>{item}</span>
                              <span
                                style={{
                                  float: "right",
                                  display: "inline-block",
                                  padding: "0px 5px",
                                  background: "#ccc",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let temp = this.state.attributes
                                  temp.splice(temp.indexOf(item), 1)
                                  if (this.props.content.type === "edit") {
                                    new_data.attributes = temp;
                                  }
                                  else {
                                    post_data.attributes = temp;
                                  }
                                  this.setState({attributes: temp})
                                }}
                              >
                                x
                              </span>
                            </li>
                          </Fragment>
                        ))}
                    </ul>
                  </div> 
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div
                      className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                      style={{ marginLeft: '20px' }}
                    >
                      Desktop Banner
                    </div>
                    <div className="border-box rightalign">
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setState({webBannerCnt: (this.state.webBannerCnt + 1)})}
                      >
                        Add Desktop Banner
                      </button>
                    </div>
                    {this.getBannerList('web', 'webBannerCnt')}
                    <div
                      className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                      style={{ marginLeft: '20px' }}
                    >
                      Mobile Banner
                    </div>
                    <div className="border-box floatright rightalign">
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setState({mobileBannerCnt: (this.state.mobileBannerCnt + 1)})}
                      >
                        Add Mobile Banner
                      </button>
                    </div>
                    {this.getBannerList('mobile', 'mobileBannerCnt')}
                    <div className="border-box clearfix fields">
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Potrait Banner
                        </div>
                        {this.state.banner && this.state.banner.portrait && typeof this.state.banner.portrait === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.banner.portrait}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('banner', 'portrait')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('banner', 'portrait', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerPortrait}
                                type="text"
                                placeholder="Portrait Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.mainBannerPortrait.current.value.length && this.state.banner
                                    && this.state.banner.portrait && typeof this.state.banner.portrait === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerPortrait.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerPortrait.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('banner', 'portrait', e);
                                } else  if (typeof this.state.banner.portrait === 'object'){
                                  this.uploadImageOnServer('banner', 'portrait', this.state.banner.portrait)
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner SubTitle
                        </div>
                        <input
                          type="text"
                          placeholder="Banner Subtitle"
                          value={this.state.banner ? this.state.banner.banner_heading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'banner_heading', e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.content.type !== 'add' ? (
                  <div className="border-box fields">
                    {this.state.categoryProducts ? (
                      <div className="border-box field1">
                        <div className="border-box uppercased fieldtitle weight700 blackspan7">
                          Popular Products(maximum 5)
                        </div>
                        <MultiSelect
                          options={this.state.categoryProducts}
                          labelKey="display_name"
                          checkbox_id="sku_code"
                          placeholder=""
                          disabledItems={[]}
                          multiple
                          selectedOptions={this.state.popularProducts}
                          onChange={(e) => {
                            let tempArr = [];
                            if (e.length)
                              tempArr = e.map((item) => item.product_id);
                            if (this.props.content.type === "edit") {
                              new_data.listing_product_ids = tempArr;
                            }
                            else {
                              post_data.listing_product_ids = tempArr
                            }
                            this.setState({ popularProducts: e });
                          }}
                        />
                      </div>
                    ) : null}
                    {this.state.categoryProducts ? (
                      <div className="border-box field1">
                        <div className="border-box uppercased fieldtitle weight700 blackspan7">
                          Select Products for price list
                        </div>                                    
                          <MultiSelect
                            options={this.state.categoryProducts}
                            labelKey="display_name"
                            checkbox_id="sku_code"
                            placeholder="Product List"
                            multiple
                            disabledItems={[]}
                            selectedOptions={this.state.priceList}
                            onChange={(e) => {
                              let tempArr = [];
                              if (e.length)
                                tempArr = e.map((item) => item.product_id);
                              if (this.props.content.type === "edit") {
                                new_data.pricelist_product_ids = tempArr;
                              }
                              else {
                                post_data.pricelist_product_ids = tempArr
                              }
                              this.setState({priceList: e})
                            }}
                          />          
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box uppercased fieldtitle weight700 blackspan7">
                      Patner Brands
                    </div>
                    {
                      this.state.brandList && <MultiSelect
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder=""
                        disabledItems={[]}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.partnerBrands}
                        onChange={(e) => {
                          let tempArr = [];
                          if (e.length)
                            tempArr = e.map((item) => item._id);
                          if (this.props.content.type === "edit") {
                            new_data.partner_brand_ids = tempArr;
                          }
                          else {
                            post_data.partner_brand_ids = tempArr
                          }
                          this.setState({ partnerBrands: e });
                        }}
                      />
                    }
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.bottom_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("bottom_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Testimonial Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({testimonialCount: (this.state.testimonialCount + 1)})}
                    >
                      Add Testimonial
                    </button>
                  </div>
                </div>
                <div className="testimonialContainer">
                  {this.getTestimonialList('testimonials', 'testimonialCount')}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                  <div className="faqContainer">
                    {this.getFAQList()}
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Promotional Banners
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({pbannerCount: (this.state.pbannerCount + 1)})}
                    >
                      Add Banner
                    </button>
                  </div>
                </div>
                <div className="bannerContainer">
                  {this.getPromotionaBannerList()}
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "products":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Product Name
                    </div>
                    <input
                      type="text"
                      placeholder="Product Name"
                      value={this.state.title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("title", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brand Name
                    </div>
                    <input
                      type="text"
                      placeholder="Brand Name"
                      value={this.state.brand_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("brand_name", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Description
                    </div>
                    <input
                      type="text"
                      placeholder="Description"
                      value={this.state.description.details}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("details", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Condition
                    </div>
                    <input
                      type="text"
                      placeholder="condition"
                      value={this.state.condition}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("condition", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Segment Name
                    </div>
                    <input
                      type="text"
                      placeholder="segment_name"
                      value={this.state.segment_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("segment_name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Category Name
                    </div>
                    <input
                      type="text"
                      placeholder="category_name"
                      value={this.state.category_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("category_name", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Warranty Value
                    </div>
                    <input
                      type="text"
                      placeholder="Warranty Value"
                      value={this.state.warranty.value}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("value", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Warranty Condition
                    </div>
                    <input
                      type="text"
                      placeholder="warranty condition"
                      value={this.state.warranty.condition}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("Condition", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Materials
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.materials.type}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("type", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Fragility Level
                    </div>
                    <input
                      type="text"
                      placeholder="fragility_level"
                      value={this.state.fragility_level}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("fragility_level", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Installation Required?
                    </div>
                    <input
                      type="text"
                      placeholder="is_installation_required"
                      value={this.state.is_installation_required}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("is_installation_required", e)
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Popularity
                    </div>
                    <input
                      type="text"
                      placeholder="popularity"
                      value={this.state.popularity}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("popularity", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "searchbysku":
          return this.props.content.type === "add" ? (
            <AddProduct
              brandList={this.state.brandList}
              categoriesList={this.state.categoriesList}
              handleButtonClick={this.handleButtonClick}
            />
          ) : (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Name
                    </div>
                    <input
                      type="text"
                      placeholder="Name"
                      value={this.state.variants.name}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("name", e, "variant")
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      UPC Code
                    </div>
                    <input
                      type="text"
                      placeholder="UPC Code"
                      value={this.state.variants.upc_code}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("upc_code", e, "variant")
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Feed Title
                    </div>
                    <input
                      type="text"
                      placeholder="Feed Title"
                      value={this.state.variants.feed_title}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("feed_title", e, "variant")
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      MRP
                    </div>
                    <input
                      type="text"
                      placeholder="MRP"
                      value={this.state.variants.mrp}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("mrp", e, "variant")
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Price
                    </div>
                    <input
                      type="text"
                      placeholder="Price"
                      value={this.state.variants.price}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("price", e, "variant")
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Discount Percent
                    </div>
                    <input
                      type="text"
                      placeholder="Discount Percent"
                      value={this.state.variants.discount_percent}
                      className="field border-box small"
                      readOnly
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "discount_percent",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Color
                    </div>
                    <input
                      type="text"
                      placeholder="Color"
                      value={this.state.variants.color}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("color", e, "variant")
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Compatibility
                    </div>
                    <input
                      type="text"
                      placeholder="Compatibility"
                      value={this.state.variants.compatibility}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "compatibility",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Availability status
                    </div>
                    <input
                      type="text"
                      placeholder="Availability status"
                      value={this.state.variants.availability_status}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "availability_status",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Volume
                    </div>
                    <input
                      type="text"
                      placeholder="volume"
                      value={this.state.variants.volume}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("volume", e, "variant")
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Weight
                    </div>
                    <input
                      type="text"
                      placeholder="Weight"
                      value={this.state.variants.weight}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("weight", e, "variant")
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Google Yes
                    </div>
                    <input
                      type="text"
                      placeholder="is google yes"
                      value={this.state.variants.is_google_yes}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "is_google_yes",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Xml Inclusion
                    </div>
                    <input
                      type="text"
                      placeholder="xml inclusion"
                      value={this.state.variants.xml_inclusion}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "xml_inclusion",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Local Inventory
                    </div>
                    <input
                      type="text"
                      placeholder="is local inventory"
                      value={this.state.variants.is_local_inventory}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "is_local_inventory",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Unicommerce Sku Code
                    </div>
                    <input
                      type="text"
                      placeholder="Unicommerce Sku Code"
                      value={this.state.variants.unicommerce_skucode}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "unicommerce_skucode",
                          e,
                          "variant"
                        )
                      }
                    />
                  </div>
                </div>
                {this.props.content.content &&
                  this.props.content.content.variants.specifications ? (
                  <>
                    <div
                      className="border-box floatleft leftalign blackspan2 shadow-text"
                      style={{ marginLeft: "30px" }}
                    >
                      Specifications
                    </div>
                    {this.makeDimensionUI("specifications")}
                  </>
                ) : null}
                {this.props.content.content &&
                  this.props.content.content.variants.dimensions ? (
                  <>
                    <div
                      className="border-box floatleft leftalign blackspan2 shadow-text"
                      style={{ marginLeft: "30px" }}
                    >
                      Dimensions
                    </div>
                    {this.makeDimensionUI("dimensions")}
                  </>
                ) : null}
                {this.props.content.content &&
                  this.props.content.content.variants.attributes ? (
                  <>
                    <div
                      className="border-box floatleft leftalign blackspan2 shadow-text"
                      style={{ marginLeft: "30px", fontWeight: '800' }}
                    >
                      Attributes
                    </div>
                    {this.makeDimensionUI("attributes")}
                  </>
                ) : null}
                <div
                  className="border-box floatleft leftalign blackspan2 shadow-text"
                  style={{ marginLeft: "30px" }}
                >
                  Product Details
                  <br />
                  <span style={{ fontSize: "13px" }}>
                    <b>Note:</b>
                    <i>
                      The following details will be updated at product level.
                    </i>
                  </span>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page Title
                    </div>
                    <input
                      type="text"
                      placeholder="Page Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "page_title",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Display Name
                    </div>
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={this.state.display_name}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "display_name",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.title}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("title", e, "product")
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Year
                    </div>
                    <input
                      type="text"
                      placeholder="Year"
                      value={this.state.year}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange("year", e, "product")
                      }
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brand
                    </div>
                    {this.state.brandList && (
                      <MultiSelect
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Brand"
                        disabledItems={[]}
                        selectedOptions={{ name: this.state.brand_name }}
                        onChange={(e) => {
                          this.setState({ ...this.state, brand_name: e.name });
                          if (!new_data["product_update"]) {
                            new_data["product_update"] = {};
                          }
                          new_data["product_update"].brand_name = e.name;
                        }}
                      />
                    )}
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Category
                    </div>
                    {this.state.categoriesList && (
                      <MultiSelect
                        options={this.state.categoriesList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Category"
                        disabledItems={[]}
                        selectedOptions={{ name: this.state.category_name }}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            category_name: e.name,
                          });
                          if (!new_data["product_update"]) {
                            new_data["product_update"] = {};
                          }
                          new_data["product_update"].category_name = e.name;
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta Description
                    </div>
                    <textarea
                      type="text"
                      placeholder="Meta Description"
                      value={this.state.meta_description}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "meta_description",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Specifications
                    </div>
                    <textarea
                      type="text"
                      placeholder="Specifications"
                      value={this.state.specification}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "specification",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Description
                    </div>
                    <textarea
                      placeholder="Description"
                      value={this.state.description.details}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "details",
                          e,
                          "product",
                          "object",
                          "description"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Features
                    </div>
                    <textarea
                      type="text"
                      placeholder="Features"
                      value={this.state.features.text}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "text",
                          e,
                          "product",
                          "object",
                          "features"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Properties
                    </div>
                    <textarea
                      type="text"
                      placeholder="Properties"
                      value={this.state.properties}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "properties",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      How to use
                    </div>
                    <textarea
                      type="text"
                      placeholder="How to use"
                      value={this.state.how_to_use}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "how_to_use",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Color
                    </div>
                    <input
                      type="text"
                      className="field border-box small"
                      placeholder="Color"
                      value={this.state.color_text}
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "color_text",
                          e,
                          "product"
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          let temp = this.state.color;
                          temp.push(e.target.value.trim());
                          this.setState({ color: temp, color_text: "" });
                          if (!new_data["product_update"])
                            new_data["product_update"] = {};
                          new_data["product_update"].color = temp;
                        }
                      }}
                    />
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {this.state.color &&
                        this.state.color.map((item) => (
                          <Fragment>
                            <li
                              key={item}
                              style={{
                                width: "150px",
                                display: "inline-block",
                                padding: "5px",
                                margin: "5px",
                                fontSize: "15px",
                              }}
                              className="choiceboxmultiinnerselected"
                            >
                              <span>{item}</span>
                              <span
                                style={{
                                  float: "right",
                                  display: "inline-block",
                                  padding: "0px 5px",
                                  background: "#ccc",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let temp = this.state.color;
                                  temp.splice(temp.indexOf(item), 1);
                                  this.setState({ color: temp });
                                  if (!new_data["product_update"])
                                    new_data["product_update"] = {};
                                  new_data["product_update"].color = temp;
                                }}
                              >
                                x
                              </span>
                            </li>
                          </Fragment>
                        ))}
                    </ul>
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Tags
                    </div>
                    <input
                      type="text"
                      className="field border-box small"
                      placeholder="Tags"
                      value={this.state.tags_text}
                      onChange={(e) =>
                        this.handleProductFieldChange("tags_text", e, "product")
                      }
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          let temp = this.state.tags;
                          temp.push(e.target.value.trim());
                          this.setState({ tags: temp, tags_text: "" });
                          if (!new_data["product_update"])
                            new_data["product_update"] = {};
                          new_data["product_update"].tags = temp;
                        }
                      }}
                    />
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {this.state.tags &&
                        this.state.tags.map((item) => (
                          <Fragment>
                            <li
                              key={item}
                              style={{
                                width: "150px",
                                display: "inline-block",
                                padding: "5px",
                                margin: "5px",
                                fontSize: "15px",
                              }}
                              className="choiceboxmultiinnerselected"
                            >
                              <span>{item}</span>
                              <span
                                style={{
                                  float: "right",
                                  display: "inline-block",
                                  padding: "0px 5px",
                                  background: "#ccc",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let temp = this.state.tags;
                                  temp.splice(temp.indexOf(item), 1);
                                  this.setState({ tags: temp });
                                  if (!new_data["product_update"])
                                    new_data["product_update"] = {};
                                  new_data["product_update"].tags = temp;
                                }}
                              >
                                x
                              </span>
                            </li>
                          </Fragment>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Popularity
                    </div>
                    <input
                      type="text"
                      placeholder="Popularity"
                      value={this.state.popularity}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "popularity",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Product Tag
                    </div>
                    <input
                      type="text"
                      placeholder="Product Tag"
                      value={this.state.product_tag}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "product_tag",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Star Product
                    </div>
                    <input
                      type="text"
                      placeholder="Is Star Product"
                      value={this.state.is_star_product}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "is_star_product",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Hero Product
                    </div>
                    <input
                      type="text"
                      placeholder="Is Hero Product"
                      value={this.state.is_hero_product}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "is_hero_product",
                          e,
                          "product"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Visible on Web
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="visible_on_web1"
                      name="visible_on_web"
                      value="1"
                      onChange={(e) => {
                        this.setState({
                          visible_on_web: Number(e.target.value),
                        });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].visible_on_web = Number(
                          e.target.value
                        );
                      }}
                      checked={this.state.visible_on_web}
                    />
                    <label for="visible_on_web1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="visible_on_web2"
                      name="visible_on_web"
                      value="0"
                      onChange={(e) => {
                        this.setState({
                          visible_on_web: Number(e.target.value),
                        });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].visible_on_web = Number(
                          e.target.value
                        );
                      }}
                      checked={!this.state.visible_on_web}
                    />
                    <label for="visible_on_web2">No</label>
                  </div>
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Trending
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_trending1"
                      name="is_trending"
                      value="1"
                      onChange={(e) => {
                        this.setState({ is_trending: Number(e.target.value) });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].is_trending = Number(
                          e.target.value
                        );
                      }}
                      checked={this.state.is_trending}
                    />
                    <label for="is_trending1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_trending2"
                      name="is_trending"
                      value="0"
                      onChange={(e) => {
                        this.setState({ is_trending: Number(e.target.value) });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].is_trending = Number(
                          e.target.value
                        );
                      }}
                      checked={!this.state.is_trending}
                    />
                    <label for="is_trending2">No</label>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Bundled Item
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_bundle_item1"
                      name="is_bundle_item"
                      value="1"
                      onChange={(e) => {
                        this.setState({ is_bundle_item: Number(e.target.value) });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].is_bundle_item = Number(
                          e.target.value
                        );
                      }}
                      checked={this.state.is_bundle_item}
                    />
                    <label for="is_bundle_item1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_bundle_item2"
                      name="is_bundle_item"
                      value="0"
                      onChange={(e) => {
                        this.setState({ is_bundle_item: Number(e.target.value) });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].is_bundle_item = Number(
                          e.target.value
                        );
                      }}
                      checked={!this.state.is_bundle_item}
                    />
                    <label for="is_bundle_item2">No</label>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      GST Rate
                    </div>
                    <input
                      type="text"
                      placeholder="GST Rate"
                      value={this.state.gst_rate}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleProductFieldChange(
                          "gst_rate",
                          e,
                          "product_update"
                        )
                      }
                    />
                  </div>
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Installation Required
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_installation_required1"
                      name="is_installation_required"
                      value="1"
                      checked={this.state.is_installation_required}
                      onChange={(e) => {
                        this.setState({
                          is_installation_required: Number(e.target.value),
                        });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].is_installation_required =
                          Number(e.target.value);
                      }}
                    />
                    <label for="is_installation_required1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="is_installation_required2"
                      name="is_installation_required"
                      value="0"
                      checked={!this.state.is_installation_required}
                      onChange={(e) => {
                        this.setState({
                          is_installation_required: Number(e.target.value),
                        });
                        if (!new_data["product_update"])
                          new_data["product_update"] = {};
                        new_data["product_update"].is_installation_required =
                          Number(e.target.value);
                      }}
                    />
                    <label for="is_installation_required2">No</label>
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Warranty Details
                  </div>
                </div>
                <div className="warrantyContainer">
                  <div className="videoItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Title/Warranty
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.warranty_details ? this.state.warranty_details.title : ""}
                          className="field border-box small"
                          onChange={(e) => {
                            this.handleProductFieldChange(
                              "title",
                              e,
                              "product",
                              "object",
                              "warranty_details"
                            );
                          }}
                        />
                      </div>
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Covered in warranty
                        </div>
                        <textarea
                          class="field border-box small"
                          placeholder="Descriptionn"
                          value={this.state.warranty_details ? this.state.warranty_details.covered : ""}
                          onChange={(e) => {
                            this.handleProductFieldChange(
                              "covered",
                              e,
                              "product",
                              "object",
                              "warranty_details"
                            );
                          }}
                        />
                      </div>
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Not Covered in warranty
                        </div>
                        <textarea
                          class="field border-box small"
                          placeholder="Descriptionn"
                          value={this.state.warranty_details ? this.state.warranty_details.uncovered : ""}
                          onChange={(e) => {
                            this.handleProductFieldChange(
                              "uncovered",
                              e,
                              "product",
                              "object",
                              "warranty_details"
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Video details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({videoCount: (this.state.videoCount + 1)})}
                    >
                      Add Video
                    </button>
                  </div>
                </div>
                <div className="videoContainer">
                  {this.getProductVideoList(true)}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                </div>
                <div className="faqContainer">
                  {this.getFAQList()}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Reviews Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({reviewCount: (this.state.reviewCount + 1)})}
                    >
                      Add Review
                    </button>
                  </div>
                </div>
                <div className="testimonialContainer">
                  {this.getProductReviewList('reviews', 'reviewCount')}
                </div>
                <div className="border-box clearfix fields" style={{ marginTop: '50px'}}>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Frequently Bought Together Products
                    </div>
                    <input
                      type="text"
                      placeholder="Product ID or SKU Code"
                      value={this.state.product}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("product", e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          this.handleProductInputEnter(e);
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="middle border-box clearfix leftalign">
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes10"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Product ID</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes20"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">SKU Code</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes30"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Product Name</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes20"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Price</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes15"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Remove</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix fields" style={{ marginBottom: '50px'}}>
                {this.state.collection_products && this.state.collection_products.map((item, index) => (
                  <Fragment>
                    <div className="middle border-box clearfix leftalign">
                      <div
                        className="textsize12 border-box weight500 dpm1boxes10"
                        style={{ display: "inline-block" }}
                      >
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{item.product_id}</td>
                          </tr>
                        </table>
                      </div>
                      <div
                        className="textsize12 border-box weight500 dpm1boxes20"
                        style={{ display: "inline-block" }}
                      >
                        <table
                          className="stdtable colorprim500span"
                          style={{ textTransform: "uppercase" }}
                        >
                          <tr>
                            <td valign="center">{typeof item.sku_code === 'string' ? item.sku_code : item.sku_code[0]}</td>
                          </tr>
                        </table>
                      </div>
                      <div
                        className="textsize12 border-box weight500 dpm1boxes30"
                        style={{ display: "inline-block" }}
                      >
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{item.title}</td>
                          </tr>
                        </table>
                      </div>
                      <div
                        className="textsize12 border-box weight500 dpm1boxes20"
                        style={{ display: "inline-block" }}
                      >
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{item.final_price}</td>
                          </tr>
                        </table>
                      </div>
                      <div
                        className="textsize12 border-box weight500 dpm1boxes15"
                        style={{ display: "inline-block" }}
                      >
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span
                                style={{
                                  display: "inline-block",
                                  padding: "0px 5px",
                                  background: "#ccc",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.onChangeRemoveClick(index);
                                }}
                              >
                                x
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </Fragment>
                ))}
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Cities
                    </div>
                    <MultiSelect
                      options={this.state.cityList}
                      labelKey="name"
                      checkbox_id="name"
                      placeholder="Add Cities for Inventory Availabilty"
                      disabledItems={this.state.invenoryList}
                      selectedOptions={this.state.acc_inventory_cities}
                      multiple
                      onChange={(e) => {
                        this.setState({ acc_inventory_cities: e });
                      }}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="middle border-box clearfix leftalign">
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes10"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td>City ID</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes25"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td>City Name</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes15"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td>Availability Status</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes15"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Remove</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  {this.state.invenoryList &&
                    this.state.invenoryList.map((item, index) => (
                      <Fragment>
                        <div className="middle border-box clearfix leftalign">
                          <div
                            className="textsize12 border-box weight500 dpm1boxes10"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td>{item.city_id}</td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes25"
                            style={{ display: "inline-block" }}
                          >
                            <table
                              className="stdtable colorprim500span"
                              style={{ textTransform: "uppercase" }}
                            >
                              <tr>
                                <td>{item.city}</td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes15"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td>
                                  {item.availability_status ? "Yes" : "No"}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes15"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td valign="center">
                                  <span
                                    style={{
                                      display: "inline-block",
                                      padding: "0px 5px",
                                      background: "#ccc",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.removeCityInventory(
                                        item.city_id,
                                        index
                                      );
                                    }}
                                  >
                                    x
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "collection":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page Title
                    </div>
                    <input
                      type="text"
                      placeholder="Page Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta Description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta Description"
                      onChange={(e) => this.handleChange("meta_description", e)}
                    >
                      {this.state.meta_description}
                    </textarea>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Slug
                    </div>
                    <input
                      type="text"
                      placeholder="Page Slug"
                      value={this.state.slug}
                      className="field border-box small"
                      readOnly={this.props.content.type !== "add"}
                      onChange={(e) => this.handleChange("slug", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Page Heading"
                      value={this.state.name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("name", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Google Category ID
                    </div>
                    <input
                      type="text"
                      placeholder="Google Category ID"
                      value={this.state.google_category_id}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("google_category_id", e)
                      }
                    />
                  </div>
                  {this.props.content.type === "edit" && (
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Show Page
                      </div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={this.state.visible_on_web}
                          onChange={(e) => {
                            const obj = {};
                            obj.target = {};
                            obj.target.value = e.target.checked;
                            this.handleChange("visible_on_web",obj)
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  )}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Main Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Desktop Main Banner
                        </div>
                        {this.state.banner && this.state.banner.web_banner && typeof this.state.banner.web_banner === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.banner.web_banner}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('banner', 'web_banner')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('banner', 'web_banner', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerWeb}
                                type="text"
                                placeholder="Desktop Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.mainBannerWeb.current.value.length && this.state.banner
                                    && this.state.banner.web_banner && typeof this.state.banner.web_banner === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerWeb.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerWeb.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('banner', 'web_banner', e);
                                } else  if (typeof this.state.banner.web_banner === 'object'){
                                  this.uploadImageOnServer('banner', 'web_banner', this.state.banner.web_banner)
                                }
                              }}
                            >
                              Upload Banner
                            </button>
                          </div>
                        )}
                      </div>
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Mobile Main Banner
                        </div>
                        {this.state.banner && this.state.banner.mobile_banner && typeof this.state.banner.mobile_banner === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.banner.mobile_banner}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('banner', 'mobile_banner')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('banner', 'mobile_banner', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerMobile}
                                type="text"
                                placeholder="Mobile Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.mainBannerMobile.current.value.length && this.state.banner
                                    && this.state.banner.mobile_banner && typeof this.state.banner.mobile_banner === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerMobile.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerMobile.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('banner', 'mobile_banner', e);
                                } else  if (typeof this.state.banner.mobile_banner === 'object'){
                                  this.uploadImageOnServer('banner', 'mobile_banner', this.state.banner.mobile_banner)
                                }
                              }}
                            >
                              Upload Banner
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Redirect URL
                        </div>
                        <input
                          type="text"
                          placeholder="URL"
                          value={this.state.banner ? this.state.banner.redirect_url : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'redirect_url', e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Sub Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Desktop Sub Banner
                        </div>
                        {this.state.sub_banner && this.state.sub_banner.web_banner
                          && typeof this.state.sub_banner.web_banner === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.sub_banner.web_banner}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('sub_banner', 'web_banner')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('sub_banner', 'web_banner', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.subBannerWeb}
                                type="text"
                                placeholder="Desktop Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.subBannerWeb.current.value.length && this.state.sub_banner
                                    && this.state.sub_banner.web_banner && typeof this.state.sub_banner.web_banner === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.subBannerWeb.current.value) {
                                  const e = {
                                    target: {
                                      value: this.subBannerWeb.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('sub_banner', 'web_banner', e);
                                } else  if (typeof this.state.sub_banner.web_banner === 'object'){
                                  this.uploadImageOnServer('sub_banner', 'web_banner', this.state.sub_banner.web_banner)
                                }
                              }}
                            >
                              Upload Banner
                            </button>
                          </div>
                        )}
                      </div>
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Mobile Sub Banner
                        </div>
                        {this.state.sub_banner && this.state.sub_banner.mobile_banner
                          && typeof this.state.sub_banner.mobile_banner === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.sub_banner.mobile_banner}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('sub_banner', 'mobile_banner')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('sub_banner', 'mobile_banner', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.subBannerMobile}
                                type="text"
                                placeholder="Mobile Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.subBannerMobile.current.value.length && this.state.sub_banner
                                    && this.state.sub_banner.mobile_banner && typeof this.state.sub_banner.mobile_banner === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.subBannerMobile.current.value) {
                                  const e = {
                                    target: {
                                      value: this.subBannerMobile.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('sub_banner', 'mobile_banner', e);
                                } else  if (typeof this.state.sub_banner.mobile_banner === 'object'){
                                  this.uploadImageOnServer('sub_banner', 'mobile_banner', this.state.sub_banner.mobile_banner)
                                }
                              }}
                            >
                              Upload Banner
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Redirect URL
                        </div>
                        <input
                          type="text"
                          placeholder="URL"
                          value={this.state.sub_banner ? this.state.sub_banner.redirect_url : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("sub_banner", 'redirect_url', e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.bottom_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("bottom_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                  <div className="faqContainer">
                    {this.getFAQList()}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Products
                    </div>
                    <input
                      type="text"
                      placeholder="Product ID or SKU Code"
                      value={this.state.product}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("product", e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          this.handleProductInputEnter(e);
                          e.target.value = "";
                        }
                      }}
                    />
                    <div>Or Upload Excel File</div>
                    <input
                        type="file"
                        name="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => {
                          var obj = this.state;
                          obj.file = e.target.files[0];
                          this.setState(obj);
                        }}
                    />
                  </div>
                  <button
                    className='btn btn_small btncolorprim btncard move btndelete'
                    onClick={() => {
                      if (!this.state.file) {
                        NotificationManager.error(
                            "Please select excel file",
                            "Error!!",
                            5000
                        );
                        return
                      }
                      this.uploadCollectionFile(this.state.file);
                    }}
                  >
                    Upload File
                  </button>
                </div>
                <div className="border-box clearfix fields">
                  <div className="middle border-box clearfix leftalign">
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes10"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Product ID</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes20"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">SKU Code</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes30"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Product Name</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes20"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Price</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="border-box blackspan65 weight700 dpm1boxes15"
                      style={{ display: "inline-block" }}
                    >
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Remove</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  {this.state.collection_products &&
                    this.state.collection_products.map((item, index) => (
                      <Fragment>
                        <div className="middle border-box clearfix leftalign">
                          <div
                            className="textsize12 border-box weight500 dpm1boxes10"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td valign="center">{item.product_id}</td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes20"
                            style={{ display: "inline-block" }}
                          >
                            <table
                              className="stdtable colorprim500span"
                              style={{ textTransform: "uppercase" }}
                            >
                              <tr>
                                <td valign="center">{item.sku_code[0]}</td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes30"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td valign="center">{item.title}</td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes20"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td valign="center">{item.final_price}</td>
                              </tr>
                            </table>
                          </div>
                          <div
                            className="textsize12 border-box weight500 dpm1boxes15"
                            style={{ display: "inline-block" }}
                          >
                            <table className="stdtable">
                              <tr>
                                <td valign="center">
                                  <span
                                    style={{
                                      display: "inline-block",
                                      padding: "0px 5px",
                                      background: "#ccc",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.onChangeRemoveClick(index);
                                    }}
                                  >
                                    x
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "coupons":
          return (
            <ManageCoupons
              handleButtonClick={this.handleButtonClick}
              brandList={this.state.brandList}
              couponData={this.props.content.content}
              type={this.props.content.type}
              categoriesList={this.state.categoriesList}
              pageRefresh={() =>
                this.props.onMiddleExtraButtonClick("save", null)
              }
            />
          );
        case "installationservice":
          return (
            <ServiceInstallation
              handleButtonClick={this.handleButtonClick}
              productDetail={this.props.content.content}
              type={this.props.content.type}
              pageRefresh={() =>
                this.props.onMiddleExtraButtonClick("save", null)
              }
            />
          );
        case "rails":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type === "add" ? "Add" : "Edit"} Rails
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Rails Name
                    </div>
                    <input
                      type="text"
                      placeholder="Rails Name"
                      value={this.state.name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("name", e)}
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Show rail on Products
                    </div>
                    <input
                      type="text"
                      placeholder="Enter ',' seperated IDs"
                      value={this.state.product_specific_ids_string}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("product_specific_ids_string", e)
                      }
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Rail Priority Order
                    </div>
                    <input
                      type="text"
                      placeholder="Enter a number to set a priority order"
                      value={this.state.priority_order}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("priority_order", e)
                      }
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Dyanmic Pricing Rails
                    </div>
                    <input
                      className="field border-box small"
                      type="checkbox"
                      id="dyanmicPrice"
                      name="dyanmicPrice"
                      checked={this.state.dynamicPricing || false}
                      onChange={(e) => {
                        const obj = {};
                        obj.target = {};
                        obj.target.value = e.target.checked;
                        this.handleChange("dynamicPricing", obj);
                      }}
                    />
                  </div>
                </div>
                {!this.state.dynamicPricing ? (
                  <>
                    <div className="border-box fields">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Max Price
                        </div>
                        <input
                          type="text"
                          placeholder="Max Price"
                          value={this.state.maximum_price || ""}
                          className="field border-box small"
                          onChange={(e) => this.handleChange("maximum_price", e)}
                        />
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Min Price
                        </div>
                        <input
                          type="text"
                          placeholder="Min Price"
                          value={this.state.minimum_price || ""}
                          className="field border-box small"
                          onChange={(e) => this.handleChange("minimum_price", e)}
                        />
                      </div>
                    </div>
                    <div className="border-box fields">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Max Rating
                        </div>
                        <input
                          type="text"
                          placeholder="Max Rating"
                          value={this.state.maximum_rating || ""}
                          className="field border-box small"
                          onChange={(e) => this.handleChange("maximum_rating", e)}
                        />
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Min Rating
                        </div>
                        <input
                          type="text"
                          placeholder="Min Rating"
                          value={this.state.minimum_rating || ""}
                          className="field border-box small"
                          onChange={(e) => this.handleChange("minimum_rating", e)}
                        />
                      </div>
                    </div>
                    <div className="border-box fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Categories/Sub-catgeories to be included
                        </div>
                        {this.state.categoriesList && (
                          <MultiSelect
                            options={this.state.categoriesList}
                            labelKey="name"
                            checkbox_id="slug"
                            placeholder="Category/ SubCategory (include)"
                            disabledItems={this.state.excludedCategories}
                            multiple
                            hideOnBlur
                            selectedOptions={this.state.selectedCategories}
                            onChange={(e) => {
                              this.setState({ selectedCategories: e });
                            }}
                          />
                        )}
                      </div>
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Categories/Sub-catgeories to be excluded
                        </div>
                        {this.state.categoriesList && (
                          <MultiSelect
                            options={this.state.categoriesList}
                            labelKey="name"
                            checkbox_id="slug"
                            placeholder="Category/ SubCategory (exclude)"
                            disabledItems={this.state.selectedCategories}
                            hideOnBlur
                            multiple
                            selectedOptions={this.state.excludedCategories}
                            onChange={(e) => {
                              this.setState({ excludedCategories: e });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="border-box fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Brands to be included
                        </div>
                        {this.state.brandList && (
                          <MultiSelect
                            options={this.state.brandList}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Brands (include)"
                            disabledItems={this.state.excludedBrands}
                            multiple
                            hideOnBlur
                            selectedOptions={this.state.selectedBrands}
                            onChange={(e) => {
                              this.setState({ selectedBrands: e });
                            }}
                          />
                        )}
                      </div>
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Brands to be excluded
                        </div>
                        {this.state.brandList && (
                          <MultiSelect
                            options={this.state.brandList}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Brands (exclude)"
                            disabledItems={this.state.selectedBrands}
                            multiple
                            hideOnBlur
                            selectedOptions={this.state.excludedBrands}
                            onChange={(e) => {
                              this.setState({ excludedBrands: e });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="border-box fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Tags to be included
                        </div>
                        {this.state.tagsList && (
                          <MultiSelect
                            options={this.state.tagsList}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Tags (include)"
                            disabledItems={[]}
                            multiple
                            hideOnBlur
                            selectedOptions={this.state.selectedTags}
                            onChange={(e) => {
                              this.setState({ selectedTags: e });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="border-box fields">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Include Products IDs
                        </div>
                        <input
                          type="text"
                          placeholder="Enter ',' seperated IDs"
                          value={this.state.product_ids_string}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleChange("product_ids_string", e)
                          }
                        />
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Exclude Products IDs
                        </div>
                        <input
                          type="text"
                          placeholder="Enter (,) seperated IDs"
                          value={this.state.ex_product_ids_string}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleChange("ex_product_ids_string", e)
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "feed":
          return this.state.showLoader ? (
            <OverlayLoader />
          ) : (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type === "add" ? "Add" : "Edit"} Feed
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("approve")}
                    >
                      Approve Feed
                    </button>                    
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Feed Type
                    </div>
                    {this.props.extraData && (
                      <MultiSelect
                        options={this.props.extraData.map((el) => {
                          return { name: el };
                        })}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Feed Type"
                        disabledItems={[]}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.applicable_on}
                        onChange={(e) => {
                          this.setState({ applicable_on: e });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Max Price
                    </div>
                    <input
                      type="text"
                      placeholder="Max Price"
                      value={this.state.maximum_price || ""}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("maximum_price", e)}
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Min Price
                    </div>
                    <input
                      type="text"
                      placeholder="Min Price"
                      value={this.state.minimum_price || ""}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("minimum_price", e)}
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Max Rating
                    </div>
                    <input
                      type="text"
                      placeholder="Max Rating"
                      value={this.state.maximum_rating || ""}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("maximum_rating", e)}
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Min Rating
                    </div>
                    <input
                      type="text"
                      placeholder="Min Rating"
                      value={this.state.minimum_rating || ""}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("minimum_rating", e)}
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Categories/Sub-catgeories to be included
                    </div>
                    {this.state.categoriesList && (
                      <MultiSelect
                        options={this.state.categoriesList}
                        labelKey="name"
                        checkbox_id="slug"
                        placeholder="Category/ SubCategory (include)"
                        disabledItems={this.state.excludedCategories}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.selectedCategories}
                        onChange={(e) => {
                          this.setState({ selectedCategories: e });
                        }}
                      />
                    )}
                  </div>
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Categories/Sub-catgeories to be excluded
                    </div>
                    {this.state.categoriesList && (
                      <MultiSelect
                        options={this.state.categoriesList}
                        labelKey="name"
                        checkbox_id="slug"
                        placeholder="Category/ SubCategory (exclude)"
                        disabledItems={this.state.selectedCategories}
                        hideOnBlur
                        multiple
                        selectedOptions={this.state.excludedCategories}
                        onChange={(e) => {
                          this.setState({ excludedCategories: e });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brands to be included
                    </div>
                    {this.state.brandList && (
                      <MultiSelect
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Brands (include)"
                        disabledItems={this.state.excludedBrands}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.selectedBrands}
                        onChange={(e) => {
                          this.setState({ selectedBrands: e });
                        }}
                      />
                    )}
                  </div>
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brands to be excluded
                    </div>
                    {this.state.brandList && (
                      <MultiSelect
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Brands (exclude)"
                        disabledItems={this.state.selectedBrands}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.excludedBrands}
                        onChange={(e) => {
                          this.setState({ excludedBrands: e });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Tags to be included
                    </div>
                    {this.state.tagsList && (
                      <MultiSelect
                        options={this.state.tagsList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Tags (include)"
                        disabledItems={[]}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.selectedTags}
                        onChange={(e) => {
                          this.setState({ selectedTags: e });
                        }}
                      />
                    )}
                  </div>
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Tags to be excluded
                    </div>
                    {this.state.tagsList && (
                      <MultiSelect
                        options={this.state.tagsList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Tags (exclude)"
                        disabledItems={this.state.selectedTags}
                        multiple
                        hideOnBlur
                        selectedOptions={this.state.excludedTags}
                        onChange={(e) => {
                          this.setState({ excludedTags: e });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Include Products IDs
                    </div>
                    <input
                      type="text"
                      placeholder="Enter ',' seperated IDs"
                      value={this.state.product_ids_string}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("product_ids_string", e)
                      }
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Exclude Products IDs
                    </div>
                    <input
                      type="text"
                      placeholder="Enter (,) seperated IDs"
                      value={this.state.ex_product_ids_string}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("ex_product_ids_string", e)
                      }
                    />
                  </div>
                </div>
                <div className="border-box clearfix footer" id="scroller">
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("fetch")}
                    >
                      Fetch Products
                    </button>
                  </div>
                </div>
              </div>
              {this.state.fetchedProducts &&
              this.state.fetchedProducts.length ? (
                <div id="dashboardprime" style={{ marginTop: "20px" }}>
                  <div
                    className="middle border-box clearfix leftalign"
                    style={{ position: "relative", top: "unset" }}
                  >
                    <div className="border-box blackspan65 weight700 dpm1boxes3">
                      <table className="stdtable">
                        <tr>
                          <td valign="center"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes6">
                      <table className="stdtable">
                        <tr>
                          <td valign="center">ID</td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes35">
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Name</td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes15">
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Brand</td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes10">
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Category</td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes10">
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Segment</td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes10">
                      <table className="stdtable">
                        <tr>
                          <td valign="center">Price</td>
                        </tr>
                      </table>
                    </div>
                    <div className="border-box blackspan65 weight700 dpm1boxes2">
                      <table className="stdtable">
                        <tr>
                          <td valign="center"></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    className="main border-box leftalign"
                    style={{ position: "relative", top: "unset" }}
                  >
                    {this.state.fetchedProducts.map((obj) => (
                      <Row data={obj} tag={"feed_product"} />
                    ))}
                  </div>
                  <div className="border-box clearfix footer">
                    {/* <div
                      className="border-box floatright rightalign"
                      style={{ marginRight: "20px" }}
                    >
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.handleButtonClick("approve")}
                      >
                        Approve Feed
                      </button>
                    </div> */}
                  </div>
                </div>
              ) : null}
            </div>
          );
        case "enquiry":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    Edit details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page Title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta Desctiption
                    </div>
                    <textarea
                      class="field border-box small"
                      placeholder="Meta description"
                      value={this.state.metadata}
                      onChange={(e) => this.handleChange("metadata", e)}
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Our Partner Brands
                    </div>
                    {this.state.brandList && (
                      <MultiSelect
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Brands"
                        multiple
                        disabledItems={[]}
                        selectedOptions={this.state.brands}
                        onChange={(e) => {
                          let tempArr = [];
                          if (e.length)
                            tempArr = e.map((item) => item._id);
                          if (this.props.content.type === "edit") {
                            new_data.brand_ids = tempArr;
                          }
                          else {
                            post_data.brand_ids = tempArr
                          }
                          this.setState({ brands: e });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Pop Up Box Offer
                  </div>
                </div>  
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Customer Name"
                      value={this.state.offer ? this.state.offer.heading : ''}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange("offer", "heading", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Content
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.offer ? this.state.offer.content : ''}
                      className="field border-box small"
                      onChange={(e) => this.handleObjectkeyChange("offer", "content", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Banner Heading Testimonial
                  </div>
                </div>  
                <div className="testimonialContainer">
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            heading_testimonialCount: this.state.heading_testimonialCount + 1,
                          })
                        }
                      >
                        Add Testimonial
                      </button>
                    </div>
                  </div>
                  {this.getTestimonialList('heading_testimonials', 'heading_testimonialCount')}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Popular Categories
                  </div>
                </div>  
                <div className="popularCategoryContainer extramainpanel">
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            categoryCount: this.state.categoryCount + 1,
                          })
                        }
                      >
                        Add Image
                      </button>
                    </div>
                  </div>
                  {this.getPopularCategoryImgList()}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Video Details (See how it is Done)
                  </div>
                </div>  
                <div className="videoContainer">
                  <div className="videoItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video URL
                        </div>
                        <input
                          type="text"
                          placeholder="Video URL"
                          value={this.state.video ? this.state.video.link : ""}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "link", e)
                          }
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Video Heading"
                          value={this.state.video ? this.state.video.text : ""}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "text", e)
                          }
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video Subheading
                        </div>
                        <input
                          type="text"
                          placeholder="Video Subheading"
                          value={
                            this.state.video ? this.state.video.subText : ""
                          }
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "subText", e)
                          }
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Thumbnail Icon
                        </div>
                        {this.state.video &&
                        this.state.video.thumbnail &&
                        typeof this.state.video.thumbnail === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.video.thumbnail}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("video", "thumbnail")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("video", "thumbnail", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.videoThumbnail}
                                type="text"
                                placeholder="Thumnail URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.videoThumbnail.current.value.length &&
                                  this.state.video.thumbnail &&
                                  typeof this.state.video.thumbnail === "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.videoThumbnail.current.value) {
                                  const e = {
                                    target: {
                                      value: this.videoThumbnail.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "video",
                                    "thumbnail",
                                    e
                                  );
                                } else if (
                                  typeof this.state.video.thumbnail === "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "video",
                                    "thumbnail",
                                    this.state.video.thumbnail
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Main Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Desktop Banner
                        </div>
                        {this.state.banner &&
                        this.state.banner.web_banner &&
                        typeof this.state.banner.web_banner === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.banner.web_banner}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("banner", "web_banner")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("banner", "web_banner", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerWeb}
                                type="text"
                                placeholder="Banner Desktop URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.mainBannerWeb.current.value.length &&
                                  this.state.banner &&
                                  this.state.banner.web_banner &&
                                  typeof this.state.banner.web_banner ===
                                    "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerWeb.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerWeb.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "banner",
                                    "web_banner",
                                    e
                                  );
                                } else if (
                                  typeof this.state.banner.web_banner ===
                                  "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "banner",
                                    "web_banner",
                                    this.state.banner.web_banner
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Mobile Banner
                        </div>
                        {this.state.banner &&
                        this.state.banner.mobile_banner &&
                        typeof this.state.banner.mobile_banner === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.banner.mobile_banner}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("banner", "mobile_banner")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("banner", "mobile_banner", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerMobile}
                                type="text"
                                placeholder="Banner mobile URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.mainBannerMobile.current.value.length &&
                                  this.state.banner &&
                                  this.state.banner.mobile_banner &&
                                  typeof this.state.banner.mobile_banner ===
                                    "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerMobile.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerMobile.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "banner",
                                    "mobile_banner",
                                    e
                                  );
                                } else if (
                                  typeof this.state.banner.mobile_banner ===
                                  "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "banner",
                                    "mobile_banner",
                                    this.state.banner.mobile_banner
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Redirection URL
                        </div>
                        <input
                          type="text"
                          placeholder="Video URL"
                          value={
                            this.state.banner
                              ? this.state.banner.redirect_url
                              : ""
                          }
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange(
                              "banner",
                              "redirect_url",
                              e
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.bottom_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("bottom_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Testimonial Details
                  </div>
                </div>  
                <div className="testimonialContainer">
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            testimonialCount: this.state.testimonialCount + 1,
                          })
                        }
                      >
                        Add Testimonial
                      </button>
                    </div>
                  </div>
                  {this.getTestimonialList('customer_testimonials', 'testimonialCount')}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Store Details
                  </div>
                </div>
                <div className="storeContainer extramainpanel">
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field1">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Heading
                      </div>
                      <input
                        type="text"
                        placeholder="Heading"
                        value={
                          this.state.store_data
                            ? this.state.store_data.heading
                            : ""
                        }
                        className="field border-box small"
                        onChange={(e) =>
                          this.handleObjectkeyChange("store_data", "heading", e)
                        }
                      />
                    </div>
                  </div>
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            storeImgCount: this.state.storeImgCount + 1,
                          })
                        }
                      >
                        Add Image
                      </button>
                    </div>
                  </div>
                  {this.getStoreImgList()}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                </div>
                <div className="faqContainer">
                  {this.getFAQList()}
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "brands":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brand Name
                    </div>
                    <input
                      type="text"
                      placeholder="Brand Name"
                      value={this.state.name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Slug
                    </div>
                    <input
                      type="text"
                      placeholder="Url page path"
                      value={this.state.slug}
                      readOnly={this.props.content.type !== "add"}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("slug", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brand Code
                    </div>
                    <input
                      type="text"
                      placeholder="Brand Code"
                      value={this.state.brand_code}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("brand_code", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta description"
                      value={this.state.meta_description}
                      onChange={(e) => this.handleChange("meta_description", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brand Icon
                    </div>
                    {this.state.icon && typeof this.state.icon === "string" ? (
                      <div className="border-box fieldimage1">
                        <img
                          src={this.state.icon}
                          height="100"
                          width="100"
                          alt="icon"
                        />
                        <br></br>
                        <button
                          class="btn btn_small btncolorprim btncard move btndelete"
                          onClick={() =>
                            this.handleChange("icon", { target: { value: null }})
                          }
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className="border-box fieldimage1">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            this.handleChange("icon", { target: { value: e.target.files[0] }})
                          }
                        />
                        <div
                          className="border-box clearfix field2"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Or Enter URL
                          </div>
                          <input
                            ref={this.brandIcon}
                            type="text"
                            placeholder="Icon URL"
                            className="field border-box small"
                          />
                        </div>
                        <button
                          class="btn btn_small btncolorprim btncard move btndelete"
                          onClick={() => {
                            if (
                              this.brandIcon.current.value.length &&
                              this.state.icon &&
                              typeof this.state.icon === "object"
                            ) {
                              NotificationManager.error(
                                "Either choose a image to upload or enter URL",
                                "Error!!",
                                5000
                              );
                              return;
                            }
                            if (this.brandIcon.current.value) {
                              const e = {
                                target: {
                                  value: this.brandIcon.current.value,
                                },
                              };
                              this.handleChange("icon", e);
                            } else if (
                              typeof this.state.icon === "object"
                            ) {
                              this.uploadImageOnServer(
                                null,
                                "icon",
                                this.state.icon
                              );
                            }
                          }}
                        >
                          Upload Icon
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Brand Alternate Image
                    </div>
                    {this.state.alt_img && typeof this.state.alt_img === "string" ? (
                      <div className="border-box fieldimage1">
                        <img
                          src={this.state.alt_img}
                          height="100"
                          width="100"
                          alt="icon"
                        />
                        <br></br>
                        <button
                          class="btn btn_small btncolorprim btncard move btndelete"
                          onClick={() =>
                            this.handleChange("alt_img", { target: { value: null }})
                          }
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className="border-box fieldimage1">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            this.handleChange("alt_img", { target: { value: e.target.files[0] }})
                          }
                        />
                        <div
                          className="border-box clearfix field2"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                            Or Enter URL
                          </div>
                          <input
                            ref={this.altImg}
                            type="text"
                            placeholder="Icon URL"
                            className="field border-box small"
                          />
                        </div>
                        <button
                          class="btn btn_small btncolorprim btncard move btndelete"
                          onClick={() => {
                            if (
                              this.altImg.current.value.length &&
                              this.state.alt_img &&
                              typeof this.state.alt_img === "object"
                            ) {
                              NotificationManager.error(
                                "Either choose a image to upload or enter URL",
                                "Error!!",
                                5000
                              );
                              return;
                            }
                            if (this.altImg.current.value) {
                              const e = {
                                target: {
                                  value: this.altImg.current.value,
                                },
                              };
                              this.handleChange("alt_img", e);
                            } else if (
                              typeof this.state.alt_img === "object"
                            ) {
                              this.uploadImageOnServer(
                                null,
                                "alt_img",
                                this.state.alt_img
                              );
                            }
                          }}
                        >
                          Upload Icon
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div
                      className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                      style={{ marginLeft: '20px' }}
                    >
                      Desktop Banner
                    </div>
                    <div className="border-box rightalign">
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setState({webBannerCnt: (this.state.webBannerCnt + 1)})}
                      >
                        Add Desktop Banner
                      </button>
                    </div>
                    {this.getBannerList('desktop', 'webBannerCnt')}
                    <div
                      className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                      style={{ marginLeft: '20px' }}
                    >
                      Mobile Banner
                    </div>
                    <div className="border-box floatright rightalign">
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setState({mobileBannerCnt: (this.state.mobileBannerCnt + 1)})}
                      >
                        Add Mobile Banner
                      </button>
                    </div>
                    {this.getBannerList('mobile', 'mobileBannerCnt')}
                    {/* <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner SubTitle
                        </div>
                        <input
                          type="text"
                          placeholder="Banner Subtitle"
                          value={this.state.banner ? this.state.banner.banner_heading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'banner_heading', e)}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.description}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("description", e)}
                      />
                  </div>
                  {this.state.brandProducts ? (
                      <div className="border-box field1">
                        <div className="border-box uppercased fieldtitle weight700 blackspan7">
                          Select Products for price list
                        </div>                                    
                          <MultiSelect
                            options={this.state.brandProducts}
                            labelKey="display_name"
                            checkbox_id="sku_code"
                            placeholder="Product List"
                            multiple
                            disabledItems={[]}
                            selectedOptions={this.state.priceList}
                            onChange={(e) => {
                              let tempArr = [];
                              if (e.length)
                                tempArr = e.map((item) => item.product_id);
                              if (this.props.content.type === "edit") {
                                new_data.pricelist_product_ids = tempArr;
                              }
                              else {
                                post_data.pricelist_product_ids = tempArr
                              }
                              this.setState({priceList: e})
                            }}
                          />          
                      </div>
                    ) : null}
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 shadow-text weight700">
                    Video Details
                  </div>
                </div>  
                <div className="videoContainer">
                  <div className="videoItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video URL
                        </div>
                        <input
                          type="text"
                          placeholder="Video URL"
                          value={this.state.video ? this.state.video.link : ""}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "link", e)
                          }
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Video Heading"
                          value={this.state.video ? this.state.video.text : ""}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "text", e)
                          }
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video Subheading
                        </div>
                        <input
                          type="text"
                          placeholder="Video Subheading"
                          value={
                            this.state.video ? this.state.video.subText : ""
                          }
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "subText", e)
                          }
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Thumbnail Icon
                        </div>
                        {this.state.video &&
                        this.state.video.thumbnail &&
                        typeof this.state.video.thumbnail === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.video.thumbnail}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("video", "thumbnail")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("video", "thumbnail", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.videoThumbnail}
                                type="text"
                                placeholder="Thumnail URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.videoThumbnail.current.value.length &&
                                  this.state.video.thumbnail &&
                                  typeof this.state.video.thumbnail === "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.videoThumbnail.current.value) {
                                  const e = {
                                    target: {
                                      value: this.videoThumbnail.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "video",
                                    "thumbnail",
                                    e
                                  );
                                } else if (
                                  typeof this.state.video.thumbnail === "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "video",
                                    "thumbnail",
                                    this.state.video.thumbnail
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Testimonial Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({testimonialCount: (this.state.testimonialCount + 1)})}
                    >
                      Add Testimonial
                    </button>
                  </div>
                </div>
                <div className="testimonialContainer">
                  {this.getTestimonialList('testimonials', 'testimonialCount')}
                </div> */}
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                  <div className="faqContainer">
                    {this.getFAQList()}
                  </div>
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "carPage":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {this.props.content.type === "add" ? (
                  <>
                    <div className="border-box fields">
                      <div className="border-box field1 clearfix">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Page Type
                        </div>
                        <input
                          className="field border-box small"
                          type="radio"
                          id="page_type_1"
                          name="Brand Page"
                          value="0"
                          onChange={(e) => {
                            this.setState({pageType: Number(e.target.value)});
                          }}
                          checked={this.state.pageType === 0}
                        />
                        <label for="page_type_1">Car Brand Page</label>
                        <input
                          style={{ marginLeft: '60px' }}
                          className="field border-box small"
                          type="radio"
                          id="page_type_2"
                          name="Brand Model Page"
                          value="1"
                          onChange={(e) => {
                            this.setState({pageType: Number(e.target.value)});
                          }}
                          checked={this.state.pageType === 1}
                        />
                        <label for="page_type_2">Car Brand Model Page</label>
                      </div>
                    </div>
                    <div className="border-box fields">
                      {this.state.carBrandList && this.state.carBrandList.length ? (
                        <div className="border-box field1">
                          <div className="border-box uppercased fieldtitle weight700 blackspan7">
                            Select Car Brand
                          </div>
                          <MultiSelect
                            options={this.state.carBrandList}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Car Brands"
                            disabledItems={[]}
                            selectedOptions={this.state.selectedCarBrand}
                            onChange={(e) => {
                              this.setState({ selectedCarBrand: e, selectedCarModel: null });
                            }}
                          />              
                        </div>
                      ) : null}
                      {this.state.carModelList && this.state.carModelList.length && this.state.pageType === 1 ? (
                        <div className="border-box field1">
                          <div className="border-box uppercased fieldtitle weight700 blackspan7">
                            Select Car Model
                          </div>
                          <MultiSelect
                            options={this.state.carModelList}
                            labelKey="name"
                            checkbox_id="name"
                            placeholder="Car Model"
                            disabledItems={[]}
                            selectedOptions={this.state.selectedCarModel}
                            onChange={(e) => {
                              this.setState({ selectedCarModel: e });
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
                {this.props.content.type === "edit" ? (
                  <div className="border-box clearfix fields">
                    <div className="border-box field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Car Brand Name
                      </div>
                      <input
                        type="text"
                        placeholder="Car Brand"
                        value={this.state.brand_name}
                        className="field border-box small"
                        readOnly
                      />
                    </div>
                    {this.state.model_name ? (
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Car Model Name
                        </div>
                        <input
                          type="text"
                          placeholder="Car Model"
                          value={this.state.model_name}
                          className="field border-box small"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="border-box clearfix fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta description"
                      value={this.state.meta_description}
                      onChange={(e) => this.handleChange("meta_description", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Top Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Desktop Top Banner
                        </div>
                        {this.state.top_banner && this.state.top_banner.desktop && typeof this.state.top_banner.desktop === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.top_banner.desktop}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('top_banner', 'desktop')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('top_banner', 'desktop', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.topBannerDesktop}
                                type="text"
                                placeholder="Desktop Top Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.topBannerDesktop.current.value.length && this.state.top_banner
                                    && this.state.top_banner.desktop && typeof this.state.top_banner.desktop === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.topBannerDesktop.current.value) {
                                  const e = {
                                    target: {
                                      value: this.topBannerDesktop.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('top_banner', 'desktop', e);
                                } else  if (typeof this.state.top_banner.desktop === 'object'){
                                  this.uploadImageOnServer('top_banner', 'desktop', this.state.top_banner.desktop)
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Mobile Top Banner
                        </div>
                        {this.state.top_banner && this.state.top_banner.mobile && typeof this.state.top_banner.mobile === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.top_banner.mobile}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('top_banner', 'mobile')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('top_banner', 'mobile', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.topBannerMobile}
                                type="text"
                                placeholder="Mobile Top Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.topBannerMobile.current.value.length && this.state.top_banner
                                    && this.state.top_banner.mobile && typeof this.state.top_banner.mobile === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.topBannerMobile.current.value) {
                                  const e = {
                                    target: {
                                      value: this.topBannerMobile.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('top_banner', 'mobile', e);
                                } else  if (typeof this.state.top_banner.mobile === 'object'){
                                  this.uploadImageOnServer('top_banner', 'mobile', this.state.top_banner.mobile)
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner Heading"
                          value={this.state.banner ? this.state.banner.heading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'heading', e)}
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner SubHeading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner SubHeading"
                          value={this.state.banner ? this.state.banner.subheading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'subheading', e)}
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Redirect URL
                        </div>
                        <input
                          type="text"
                          placeholder="URL"
                          value={this.state.banner ? this.state.banner.redirect_url : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'redirect_url', e)}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Bottom Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Desktop Bottom Banner
                        </div>
                        {this.state.bottom_banner && this.state.bottom_banner.desktop && typeof this.state.bottom_banner.desktop === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.bottom_banner.desktop}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('bottom_banner', 'desktop')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('bottom_banner', 'desktop', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.bottomBannerDesktop}
                                type="text"
                                placeholder="Desktop Bottom Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.bottomBannerDesktop.current.value.length && this.state.bottom_banner
                                    && this.state.bottom_banner.desktop && typeof this.state.bottom_banner.desktop === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.bottomBannerDesktop.current.value) {
                                  const e = {
                                    target: {
                                      value: this.bottomBannerDesktop.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('bottom_banner', 'desktop', e);
                                } else  if (typeof this.state.bottom_banner.desktop === 'object'){
                                  this.uploadImageOnServer('bottom_banner', 'desktop', this.state.bottom_banner.desktop)
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                      <div className='border-box clearfix field2'>
                        <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                          Mobile Bottom Banner
                        </div>
                        {this.state.bottom_banner && this.state.bottom_banner.mobile && typeof this.state.bottom_banner.mobile === 'string' ? (
                          <div className='border-box fieldimage1'>
                            <img
                              src={this.state.bottom_banner.mobile}
                              height='100'
                              width='100'
                              alt='icon'
                            />
                            <br></br>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => this.deleteImage('bottom_banner', 'mobile')}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className='border-box fieldimage1'>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => this.onInputImage('bottom_banner', 'mobile', e)}
                            />
                            <div className="border-box clearfix field2" style={{ marginTop: '10px' }}>
                              <div
                                className="border-box clearfix uppercased fieldtitle weight700 blackspan7"
                              >
                                Or Enter URL
                              </div>
                              <input
                                ref={this.bottomBannerMobile}
                                type="text"
                                placeholder="Mobile Bottom Banner URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              className='btn btn_small btncolorprim btncard move btndelete'
                              onClick={() => {
                                if (this.bottomBannerMobile.current.value.length && this.state.bottom_banner
                                    && this.state.bottom_banner.mobile && typeof this.state.bottom_banner.mobile === 'object' ) {
                                  NotificationManager.error(
                                    'Either choose a image to upload or enter URL',
                                    'Error!!',
                                    5000
                                  );
                                  return;
                                }
                                if (this.bottomBannerMobile.current.value) {
                                  const e = {
                                    target: {
                                      value: this.bottomBannerMobile.current.value,
                                    }
                                  }
                                  this.handleObjectkeyChange('bottom_banner', 'mobile', e);
                                } else  if (typeof this.state.bottom_banner.mobile === 'object'){
                                  this.uploadImageOnServer('bottom_banner', 'mobile', this.state.bottom_banner.mobile)
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner Heading"
                          value={this.state.banner ? this.state.banner.heading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'heading', e)}
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner SubHeading
                        </div>
                        <input
                          type="text"
                          placeholder="Banner SubHeading"
                          value={this.state.banner ? this.state.banner.subheading : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'subheading', e)}
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Redirect URL
                        </div>
                        <input
                          type="text"
                          placeholder="URL"
                          value={this.state.banner ? this.state.banner.redirect_url : ''}
                          className="field border-box small"
                          onChange={(e) => this.handleObjectkeyChange("banner", 'redirect_url', e)}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Top Content
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Top Content"
                      value={this.state.top_content}
                      onChange={(e) => this.handleChange("top_content", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Bottom Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.bottom_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("bottom_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    FAQ Details 
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({faqCount: (this.state.faqCount + 1)})}
                    >
                      Add FAQ
                    </button>
                  </div>
                  <div className="faqContainer">
                    {this.getFAQList()}
                  </div>
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "reviews":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stcikyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    Edit details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review Id
                    </div>
                    <input
                      type="text"
                      readOnly
                      value={this.state._id}
                      className="field border-box small"
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review
                    </div>
                    <input
                      type="text"
                      readOnly
                      value={this.state.product_id}
                      className="field border-box small"
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review Rating
                    </div>
                    <input
                      type="text"
                      readOnly
                      value={this.state.rating}
                      className="field border-box small"
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review By
                    </div>
                    <input
                      type="text"
                      readOnly
                      value={this.state.review_by}
                      className="field border-box small"
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review Date
                    </div>
                    <input
                      type="text"
                      readOnly
                      value={this.state.review_date}
                      className="field border-box small"
                    />
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Order Id
                    </div>
                    <input
                      type="text"
                      readOnly
                      value={this.state.order_id}
                      className="field border-box small"
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review Title
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Review Title"
                      value={this.state.title}
                      readOnly
                    />
                  </div>
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Review
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Review"
                      value={this.state.review}
                      readOnly
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box field2 clearfix">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Is Visible
                    </div>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="visible_on_web1"
                      name="visible_on_web"
                      value="1"
                      onChange={(e) => {
                        this.setState({
                          is_visible: Number(e.target.value),
                        });
                      }}
                      checked={typeof(this.state.is_visible) !== 'undefined' ? this.state.is_visible : null}
                    />
                    <label for="visible_on_web1">Yes</label>
                    <input
                      className="field border-box small"
                      type="radio"
                      id="visible_on_web2"
                      name="visible_on_web"
                      value="0"
                      onChange={(e) => {
                        this.setState({
                          is_visible: Number(e.target.value),
                        });
                      }}
                      checked={typeof(this.state.is_visible) !== 'undefined' ? !this.state.is_visible : null}
                    />
                    <label for="visible_on_web2">No</label>
                  </div>
                </div>
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );  
        case "collectionv2":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {this.state.basic_details ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Basic Collection Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            base_file: false,
                            basic_details: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Page title
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.page_title}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Collection Name
                        </div>
                        <input
                          type="text"
                          placeholder="Name"
                          value={this.state.name}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Meta description
                        </div>
                        <textarea
                          className="field border-box small"
                          placeholder="Meta description"
                          value={this.state.meta_description}
                          readOnly
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Basic Collection Details File
                      </div>
                      <input
                        type="file"
                        name="base_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.title_banner ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Title Banner Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            title_banner_file: false,
                            title_banner: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Heading"
                          value={this.state.title_banner.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Sub-Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Sub-Heading"
                          value={this.state.title_banner.section_subheading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Desktop Banner
                      </div>
                      {this.state.title_banner.desktop ? this.state.title_banner.desktop.map((banner, index) => (
                        <>
                        <div className="border-box leftalign blackspan2 shadow-text">
                          {`Desktop Banner ${index + 1}`}
                        </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Mobile Banner
                      </div>
                      {this.state.title_banner.mobile ? this.state.title_banner.mobile.map((banner, index) => (
                        <>
                          <div className="border-box leftalign blackspan2 shadow-text">
                            {`Mobile Banner ${index + 1}`}
                          </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                    </div>                    
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Title Banner Details File
                      </div>
                      <input
                        type="file"
                        name="title_banner_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.upper_category_section ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Upper Category Section Details
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            upper_category_section_file: false,
                            upper_category_section: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.upper_category_section.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      {this.state.upper_category_section.data ? this.state.upper_category_section.data.map((category) => (
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          {category.heading}
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="middle border-box clearfix leftalign">
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes10"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">Product ID</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">SKU Code</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes50"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">Product Name</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">Price</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          {category.products && category.products.map((item, index) => (
                              <>
                                <div className="middle border-box clearfix leftalign">
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes10"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table className="stdtable">
                                      <tr>
                                        <td valign="center">{item.product_id}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes20"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table
                                      className="stdtable colorprim500span"
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      <tr>
                                        <td valign="center">{item.sku_code}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes50"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table className="stdtable">
                                      <tr>
                                        <td valign="center">{item.title}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes20"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table className="stdtable">
                                      <tr>
                                        <td valign="center">{item.price}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      )) : null}
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Upper Category Section Details File
                      </div>
                      <input
                        type="file"
                        name="upper_category_section_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.upper_banner ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Upper Banner Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            upper_banner_file: false,
                            upper_banner: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.upper_banner.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Desktop Banner
                      </div>
                      {this.state.upper_banner.desktop ? this.state.upper_banner.desktop.map((banner, index) => (
                        <>
                        <div className="border-box leftalign blackspan2 shadow-text">
                          {`Desktop Banner ${index + 1}`}
                        </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Mobile Banner
                      </div>
                      {this.state.upper_banner.mobile ? this.state.upper_banner.mobile.map((banner, index) => (
                        <>
                          <div className="border-box leftalign blackspan2 shadow-text">
                            {`Mobile Banner ${index + 1}`}
                          </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                    </div>                    
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Upper Banner Details File
                      </div>
                      <input
                        type="file"
                        name="upper_banner_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.offers_banner ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Offer Banner Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            offers_banner_file: false,
                            offers_banner: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      {/* <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.offers_banner.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div> */}
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Desktop Banner
                      </div>
                      {this.state.offers_banner.desktop ? this.state.offers_banner.desktop.map((banner, index) => (
                        <>
                        <div className="border-box leftalign blackspan2 shadow-text">
                          {`Desktop Banner ${index + 1}`}
                        </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Mobile Banner
                      </div>
                      {this.state.offers_banner.mobile ? this.state.offers_banner.mobile.map((banner, index) => (
                        <>
                          <div className="border-box leftalign blackspan2 shadow-text">
                            {`Mobile Banner ${index + 1}`}
                          </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                    </div>                    
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Offer Details File
                      </div>
                      <input
                        type="file"
                        name="offers_banner_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.middle_category_banner ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Middle Category Section Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            middle_category_banner_file: false,
                            middle_category_banner: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.middle_category_banner.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Sub-Heading
                        </div>
                        <input
                          type="text"
                          value={this.state.middle_category_banner.section_subheading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="middle border-box clearfix leftalign">
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes50"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center" style={{ textAlign: 'left' }}>Category Name(Title)</td>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes30"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center" style={{ textAlign: 'left' }}>Sub Title</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      {this.state.middle_category_banner.data && this.state.middle_category_banner.data.map((item) => (
                          <>
                            <div className="middle border-box clearfix leftalign">
                              <div
                                className="textsize12 border-box weight500 dpm1boxes50"
                                style={{ display: "inline-block" }}
                              >
                                <table className="stdtable">
                                  <tr>
                                    <td valign="center" style={{ textAlign: 'left' }}>{item.heading}</td>
                                  </tr>
                                </table>
                              </div>
                              <div
                                className="textsize12 border-box weight500 dpm1boxes30"
                                style={{ display: "inline-block" }}
                              >
                                <table
                                  className="stdtable"
                                >
                                  <tr>
                                    <td valign="center" style={{ textAlign: 'left' }}>{item.subheading}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Middle Category Section Details File
                      </div>
                      <input
                        type="file"
                        name="middle_category_banner_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.lower_category_section ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Lower Category Section Details
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            lower_category_section_file: false,
                            lower_category_section: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.lower_category_section.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      {this.state.lower_category_section.data ? this.state.lower_category_section.data.map((category) => (
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          {category.heading}
                        </div>
                        <div className="border-box clearfix fields">
                          <div className="middle border-box clearfix leftalign">
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes10"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">Product ID</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">SKU Code</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes50"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">Product Name</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="border-box blackspan65 weight700 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">Price</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="border-box clearfix fields">
                          {category.products && category.products.map((item, index) => (
                              <>
                                <div className="middle border-box clearfix leftalign">
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes10"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table className="stdtable">
                                      <tr>
                                        <td valign="center">{item.product_id}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes20"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table
                                      className="stdtable colorprim500span"
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      <tr>
                                        <td valign="center">{item.sku_code}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes50"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table className="stdtable">
                                      <tr>
                                        <td valign="center">{item.title}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div
                                    className="textsize12 border-box weight500 dpm1boxes20"
                                    style={{ display: "inline-block" }}
                                  >
                                    <table className="stdtable">
                                      <tr>
                                        <td valign="center">{item.price}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      )) : null}
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Lower Category Section Details File
                      </div>
                      <input
                        type="file"
                        name="lower_category_section_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.filter_section ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Filter Section Details
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            filter_section_file: false,
                            filter_section: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.filter_section.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="middle border-box clearfix leftalign">
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes20"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center">Filter Title</td>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes20"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center">Filter Type</td>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes20"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center">Lower Limit</td>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes20"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center">Upper Limit</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      {this.state.filter_section.data && this.state.filter_section.data.map((filter) => (
                        <>
                          <div className="middle border-box clearfix leftalign">
                            <div
                              className="textsize12 border-box weight500 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">{filter.title}</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="textsize12 border-box weight500 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: "uppercase" }}
                              >
                                <tr>
                                  <td valign="center">{filter.type}</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="textsize12 border-box weight500 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">{filter.lower_limit}</td>
                                </tr>
                              </table>
                            </div>
                            <div
                              className="textsize12 border-box weight500 dpm1boxes20"
                              style={{ display: "inline-block" }}
                            >
                              <table className="stdtable">
                                <tr>
                                  <td valign="center">{filter.upper_limit}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Filter Section Details File
                      </div>
                      <input
                        type="file"
                        name="filter_section_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.lower_banner ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Lower Banner Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            lower_banner_file: false,
                            lower_banner: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.lower_banner.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Desktop Banner
                      </div>
                      {this.state.lower_banner.desktop ? this.state.lower_banner.desktop.map((banner, index) => (
                        <>
                        <div className="border-box leftalign blackspan2 shadow-text">
                          {`Desktop Banner ${index + 1}`}
                        </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                      <div className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'>
                        Mobile Banner
                      </div>
                      {this.state.lower_banner.mobile ? this.state.lower_banner.mobile.map((banner, index) => (
                        <>
                          <div className="border-box leftalign blackspan2 shadow-text">
                            {`Mobile Banner ${index + 1}`}
                          </div>
                          <div className="border-box clearfix field2">
                            <div className='border-box fieldimage1'>
                              <img
                                src={banner.banner_url}
                                height='100'
                                width='100'
                                alt='icon'
                              />
                              <br></br>
                            </div>
                          </div>
                          <div className="border-box clearfix field1">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                              Redirect Url
                            </div>
                            <input
                              type="text"
                              placeholder="Banner redirect url"
                              value={banner.redirect_url}
                              className="field border-box small"
                              readOnly
                            />
                          </div>
                        </>
                      )) : null}
                    </div>                    
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Lower Banner Details File
                      </div>
                      <input
                        type="file"
                        name="lower_banner_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.partner_brands ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Brands Section Details
                      </div>
                      <div className="border-box rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            partner_brands_file: false,
                            partner_brands: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box field2">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.partner_brands.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="middle border-box clearfix leftalign">
                        <div
                          className="border-box blackspan65 weight700 dpm1boxes50"
                          style={{ display: "inline-block" }}
                        >
                          <table className="stdtable">
                            <tr>
                              <td valign="center" style={{ textAlign: 'left' }}>Brand Name</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      {this.state.partner_brands.data && this.state.partner_brands.data.map((item) => (
                          <>
                            <div className="middle border-box clearfix leftalign">
                              <div
                                className="textsize12 border-box weight500 dpm1boxes50"
                                style={{ display: "inline-block" }}
                              >
                                <table className="stdtable">
                                  <tr>
                                    <td valign="center" style={{ textAlign: 'left' }}>{item.name}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Brands Section Details File
                      </div>
                      <input
                        type="file"
                        name="partner_brands_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                {this.state.video ? (
                  <>
                    <div className="border-box fields" style={{ marginTop: '40px', display: 'flex' }}>
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Video Section Details
                      </div>
                      <div className="border-box floatright rightalign">
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() => this.setState({
                            video_file: false,
                            video: false,
                          })}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    {/* <div className="border-box clearfix fields">
                      <div className="border-box field1">
                        <div className="border-box clearfix uppercased fieldtitle weight500 blackspan7">
                          Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Title"
                          value={this.state.filter_section.section_heading || ''}
                          className="field border-box small"
                          readOnly
                        />
                      </div>
                    </div> */}
                    <div className="videoContainer">
                      {this.state.video ? this.state.video.map((video, index) => (
                        <div className="videoItem extramainpanel">
                          <div className="fields border-box leftalign blackspan2 shadow-text">
                            {`Video ${index + 1}`}
                          </div>
                          <div className="border-box clearfix fields">
                            <div className="border-box clearfix field2">
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Video URL
                              </div>
                              <input
                                type="text"
                                placeholder="Video URL"
                                value={this.state.video.link}
                                className="field border-box small"
                                readOnly
                              />
                            </div>
                            <div className="border-box clearfix field2">
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Video Heading
                              </div>
                              <input
                                type="text"
                                placeholder="Video Heading"
                                value={this.state.video.text}
                                className="field border-box small"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="border-box clearfix fields">
                            <div className="border-box clearfix field2">
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Video Subheading
                              </div>
                              <input
                                type="text"
                                placeholder="Video Subheading"
                                value={video.subText}
                                className="field border-box small"
                                readOnly
                              />
                            </div>
                            <div className="border-box clearfix field2">
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Thumbnail Icon
                              </div>
                              <div className="border-box fieldimage1">
                                <img
                                  src={video.thumbnail}
                                  height="100"
                                  width="100"
                                  alt="icon"
                                />
                                <br></br>
                              </div>
                            </div>
                          </div>
                        </div>
                      )) : null}
                    </div>
                  </>
                ) : (
                  <div className="border-box fields">
                    <div className="border-box field1 clearfix">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Video Section Details File
                      </div>
                      <input
                        type="file"
                        name="video_file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onFileInput}
                      />
                    </div>
                  </div>
                )}
                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        case "export":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title stickyTitle">
                  <div
                    className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                    style={{ padding: "0px 0px 0px 10px" }}
                  >
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <textarea
                      className="field border-box small"
                      placeholder="Meta description"
                      value={this.state.meta_description}
                      onChange={(e) => this.handleChange("meta_description", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Banner Details
                  </div>
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div
                      className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                      style={{ marginLeft: '20px' }}
                    >
                      Desktop Banner
                    </div>
                    <div className="border-box rightalign">
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setState({webBannerCnt: (this.state.webBannerCnt + 1)})}
                      >
                        Add Desktop Banner
                      </button>
                    </div>
                    {this.getBannerList('desktop', 'webBannerCnt')}
                    <div
                      className='border-box floatleft clearfix uppercased fieldtitle weight700 blackspan7'
                      style={{ marginLeft: '20px' }}
                    >
                      Mobile Banner
                    </div>
                    <div className="border-box floatright rightalign">
                      <button
                        className="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() => this.setState({mobileBannerCnt: (this.state.mobileBannerCnt + 1)})}
                      >
                        Add Mobile Banner
                      </button>
                    </div>
                    {this.getBannerList('mobile', 'mobileBannerCnt')}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  {this.state.categoriesList && this.state.categoriesList.length ? (
                    <div className="border-box field1">
                      <div className="border-box uppercased fieldtitle weight700 blackspan7">
                        Categories
                      </div>
                      <MultiSelect
                        options={this.state.categoriesList}
                        labelKey="name"
                        checkbox_id="_id"
                        placeholder="Categories"
                        disabledItems={[]}
                        multiple
                        selectedOptions={this.state.categories}
                        onChange={(e) => {
                          let tempArr = JSON.parse(JSON.stringify(this.state.categories));
                          tempArr = tempArr.concat(e);
                          if (this.props.content.type === "edit") {
                            new_data.categories = tempArr;
                          }
                          else {
                            post_data.categories = tempArr
                          }
                          this.setState({ categories: tempArr });
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                      <Editor
                        apiKey='lnmu87p598pumqbtdrwv5oz2yg1fo0ehxyjn79xf2fzilv21'
                        initialValue={this.state.top_content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                            
                        }}
                        onChange={(e) => this.handleTinyMCEChange("top_content", e)}
                      />
                  </div>
                </div>
                <div className="border-box clearfix subtitle">
                  <div className="border-box floatleft leftalign blackspan2 weight700 shadow-text">
                    Video details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      className="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.setState({videoCount: (this.state.videoCount + 1)})}
                    >
                      Add Video
                    </button>
                  </div>
                </div>
                <div className="videoContainer">
                  {this.getProductVideoList(false)}
                </div>
                <div className="border-box clearfix footer">
                </div>
              </div>
            </div>
          );
        default:
          return <React.Fragment />;
      }
    }
  }
}

class ImageBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.content ? this.props.content : {};
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleButtonClick(type) {
    if (type === "delete") {
      this.props.onDelete(this.props.type, this.props.url);
    }
  }

  handleOnChange(e, type) {
    this.props.onDeleteCollectionImage(e, type);
  }

  render() {
    if (this.props.collectionBanner) {
      return (
        <React.Fragment>
          {this.props.url && typeof this.props.url === "string" ? (
            <div className="border-box fieldimage1">
              <img src={this.props.url} height="100" width="100" alt="img" />
              <br></br>
              <button
                className="btn btn_small btncolorprim btncard move btndelete"
                onClick={() => this.handleButtonClick("delete")}
              >
                Delete
              </button>
            </div>
          ) : (
            <input
              type="file"
              onChange={(e) => this.handleOnChange(e, this.props.type)}
              accept="image/*"
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="border-box fieldimage1">
            <img src={this.props.url} height="100" width="100" alt="img" />
            <br></br>
            <button
              className="btn btn_small btncolorprim btncard move btndelete"
              onClick={() => this.handleButtonClick("delete")}
            >
              Delete
            </button>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default MiddleExtra;
