import firebase from 'firebase'
import Constants from './constants'

firebase.initializeApp(Constants.getFirebaseConfig());

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js')
    .then(function(registration) {
        firebase.messaging().useServiceWorker(registration);
    }).catch(function(err) {
      // console.log('Service worker registration failed, error:', err);
    });
  }

export default firebase;