import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import API from "../api";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
const HomepageHeadings = ({homepageHeadings,getHomepageHeadings}) => {

    const [heading, setHeading]=useState(homepageHeadings);
    const [loader, setLoader] = useState(false)

    const handleHeadingChange=(e,keyname)=>{
        let temp={...heading}
        temp[keyname]=e.target.value
        setHeading({...heading,...temp})

    }

    const handleHeadingSave=()=>{
        setLoader(true)
        API.addHomepageHeadings({homepage_headings:heading}).then(function (response) {  
            if (response.data.status) {
                setLoader(false)
              getHomepageHeadings() 
              NotificationManager.success(
                'Successfully',
                'Homepage headings added',
                )}
        })
          .catch(function (error) {
            setLoader(false)
            NotificationManager.error(error.message? error.message:`Something went wrong, Please try again later`, 'Error', 3000)
          })
          .finally(function () {
            setLoader(false)
          });
    }

  return (
    <div className='top border-box leftalign' style={{backgroundColor: "#f9f9f9",top:'3rem'}}>
        <div className='border-box'>
            <div className="border-box rightalign" style={{width:'85%',marginTop:'2rem'}}>
            {loader?
                <ButtonLoader/>
                :<button className="btn btn_small btncolorprim btncard move btncloseextra" onClick={()=>handleHeadingSave()}>
                    Save
                </button>
            }   
            </div>
        </div>
        {homepageHeadings && Object.keys(homepageHeadings).map((item)=>{
            return(
                <div className='border-box '>
                    <div className='upload-row border-bottom mtop-1'>
                            <div className='blackspan65 weight700'
                                style={{
                                    display: 'flex',
                                    marginTop: '12px',
                                }}
                            >
                                <div className="heading-name">
                                {item.replace(/_/g, ' ')}
                                </div>
                                <div style={{width:'50vw'}}>
                                <div className="border-box heading-input-div">
                                    <input
                                        className="heading-input"
                                        type="text"
                                        placeholder="Car text 1"
                                        value={heading[item]}
                                        onChange={(e)=>handleHeadingChange(e,`${item}`)}
                                        onKeyPress={(e) => {
                                            try {
                                            var startPos = e.currentTarget.selectionStart;
                                            if (e.which === 32 && startPos == 0) {
                                                e.preventDefault();
                                            }
                                            } catch (err) { console.log(err); }
                                        }}
                                    />
                                    </div>
                                    </div>
                            </div>
                    </div>
                </div>
            )})}
    </div>
  )
}

export default HomepageHeadings