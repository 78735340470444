import React from 'react';
import API from '../api';
import MultiSelect from '../broadcast/MultiSelect/MultiSelect'
import { NotificationManager } from "react-notifications";

class MiddleUpper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            segmentsList: []
        };
        if (props.tag == 'reviews') {
            this.state = {
                reviewFilter: {
                    product_id: '',
                    order_id: '',
                    week: '',
                    user_id: '',
                    rating: '',
                }
            }
        }
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.uploadBulkProduct = this.uploadBulkProduct.bind(this);
        this.uploadBulkPincode = this.uploadBulkPincode.bind(this);
        this.bulkEditProduct = this.bulkEditProduct.bind(this);
        this.handleAddLink = this.handleAddLink.bind(this);
        this.addVariantToExistingProduct = this.addVariantToExistingProduct.bind(this);
        this.syncImagesHandler = this.syncImagesHandler.bind(this);
    }

    handleFilterClick() {
        this.props.onFilterClick(this.props.tag, this.state.reviewFilter);
    }

    handleSearchClick() {
        if (this.state.search != null) {
            this.props.onSearchClick(this.state.search);
        }
    }

    handleChange(type, e) {
        var obj = this.state;
        obj[type] = e.target.value;
        this.setState(obj);
    }

    handleAddClick() {
        this.props.onAddClick(this.props.tag);
    }

    onChange(e) {
        const temp = {}
        temp[e.target.name] = e.target.files[0]
        this.setState({ ...temp })
    }

    onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.fileUpload(this.state.file).then((response) => {
            // console.log(response.data);
        })
    }

    fileUpload(file) {
        return API.bulkUploadProducts(file);
    }

    getSegments() {
        API.getAllAccessoriesSegmentsList()
            .then(response => {
                this.setState({segmentsList: response.data.data})
            })
            .catch(err => console.error(err))
    }

    syncFiltersData() {
        API.syncAccessoriesFiltersData()
            .then(() => {
                NotificationManager.success(
                    "Filters Syncer started in background.",
                    "Success",
                    5000
                )
            })
            .catch(() => {
                NotificationManager.error(
                    "Some error occured while starting Filters Syncer.",
                    "Error!!",
                    5000
                )
            })
    }

    syncSeoData() {
        API.syncAccessoriesSeoData()
            .then(() => {
                NotificationManager.success(
                    "SEO Syncer started in background.",
                    "Success",
                    5000
                )
            })
            .catch(() => {
                NotificationManager.error(
                    "Some error occured while starting SEO Syncer.",
                    "Error!!",
                    5000
                )
            })
    }

    syncRedisData() {
        API.syncAccessoriesRedisData()
            .then(() => {
                NotificationManager.success(
                    "Redis Syncer started in background.",
                    "Success",
                    5000
                )
            })
            .catch(() => {
                NotificationManager.error(
                    "Some error occured while starting Redis Syncer.",
                    "Error!!",
                    5000
                )
            })
    }

    configFirebaseData() {
        API.configFirebaseAccessoriesData()
            .then((response) => {
                navigator.clipboard.writeText(JSON.stringify(response.data.data))
                NotificationManager.success(
                    "Firebase data copied to clipboard.",
                    "Success",
                    5000
                )
            })
            .catch(() => {
                NotificationManager.error(
                    "Some error occured.",
                    "Error!!",
                    5000
                )
            })
    }

    uploadBulkProduct() {
        if (!this.state.file) {
            NotificationManager.error(
                "Please select product csv.",
                "Error!!",
                5000
            )
            return
        }
        if (!this.state.variant_file) {
            NotificationManager.error(
                "Please select product variant csv.",
                "Error!!",
                5000
            )
            return
        }
        const formdata = new FormData()
        formdata.append('file', this.state.file)
        formdata.append('variant_file', this.state.variant_file)
        API.uploadAccessoriesProductBulk(formdata)
            .then(() => {
                NotificationManager.success(
                    "Product Uploaded successfully.",
                    "Success",
                    5000
                )
            })
            .catch((error) => {
                NotificationManager.error(
                    error.response && error.response.data 
                        ? error.response.data.message : "Server Error",
                    "Error!!",
                    5000
                )
            })
    }

    uploadBulkPincode() {
        if (!this.state.pincode_file) {
            NotificationManager.error(
                "Please select pincode csv.",
                "Error!!",
                5000
            )
            return
        }
        const formdata = new FormData()
        formdata.append('file', this.state.pincode_file)
        API.uploadAccessoriesPincodeBulk(formdata)
            .then(() => {
                NotificationManager.success(
                    "Product Uploaded successfully.",
                    "Success",
                    5000
                )
            })
            .catch((error) => {
                NotificationManager.error(
                    error.response && error.response.data 
                        ? error.response.data.message : "Server Error",
                    "Error!!",
                    5000
                )
            })
    }

    addVariantToExistingProduct () {
        if (!this.state.variant_file) {
            NotificationManager.error(
                "Please select new variant csv.",
                "Error!!",
                5000
            )
            return
        }
        const formdata = new FormData()
        formdata.append('variant_file', this.state.variant_file)
        API.uploadAccessoriesVariantBulk(formdata)
            .then(() => {
                NotificationManager.success(
                    "Product Uploaded successfully.",
                    "Success",
                    5000
                )
            })
            .catch((error) => {
                NotificationManager.error(
                    error.response && error.response.data 
                        ? error.response.data.message : "Server Error",
                    "Error!!",
                    5000
                )
            })
    }

    bulkEditProduct() {
        if (!this.state.file) {
            NotificationManager.error(
                "Please select product csv.",
                "Error!!",
                5000
            )
            return
        }
        const formdata = new FormData()
        formdata.append('file', this.state.file)
        API.bulkEditAccessoriesProduct(formdata)
            .then(() => {
                NotificationManager.success(
                    "Product Edited successfully.",
                    "Success",
                    5000
                )
            })
            .catch((error) => {
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message : "Server Error",
                    "Error!!",
                    5000
                )
            })
    }
    handleAddLink(){
        if (!this.state.link) {
            NotificationManager.error("Please Enter Link.", "Error!!", 5000)
            return
        }

        var folder_id = /[^/]*$/.exec(this.state.link)[0];
        API.addDriveLink(folder_id)
        .then(() => {
            NotificationManager.success("Link Uploaded successfully.", "Success", 5000)
        })
        .catch((error) => {
            NotificationManager.error("Server Error", "Error!!", 5000)
        })
    }

    syncImagesHandler() {
        if (!this.state.imgLink) {
            NotificationManager.error("Please Enter Link.", "Error!!", 5000)
            return
        }

        API.syncImagesApi(this.state.imgLink)
        .then(() => {
            NotificationManager.success("Images Uploaded successfully.", "Success", 5000)
        })
        .catch((error) => {
            NotificationManager.error("Server Error", "Error!!", 5000)
        })
    }

    componentDidMount() {
        // if (this.props.tag === 'searchbysku' ) {
            API.getAccLatestProductId().then((response) => {
                this.setState({latest_product_id: response.data.data})
            })
            .catch((error) => {
                console.error(error)
            })
        // }
    }

    render() {

        switch (this.props.tag) {
            case 'homepage':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatright" style={{height: "auto"}}>
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                Pincode BulkUpload CSV: &nbsp;
                                                <input
                                                    type="file"
                                                    name="pincode_file"
                                                    accept=".csv"
                                                    onChange={this.onChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    className="btn btn_small btnwhite btncard move"
                                                    onClick={this.uploadBulkPincode}
                                                >
                                                    Upload Pincode
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Home Page Data</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'segments':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                {/* <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="mapleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={() => this.syncFiltersData()}
                                                >
                                                    Sync Filters
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div> */}
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="mapleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={() => this.syncSeoData()}
                                                >
                                                    Sync SEO
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="mapleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={() => this.syncRedisData()}
                                                >
                                                    Sync Redis
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="mapleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={() => this.configFirebaseData()}
                                                >
                                                    Firebase Data
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="addleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add segment
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Slug</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Description</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Title</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                );
            case 'categories':
                if (this.state.segmentsList.length < 1) {
                    this.getSegments()
                }
                return (
                    <React.Fragment>
                        <div className="top border-box leftalign">
                            <div className="border-box">
                                <div
                                    className="blackspan65 weight700 floatleft"
                                    style={{height: 'auto', overflow: 'visible'}}
                                >
                                    <table className="stdtable" style={{height: 'auto'}}>
                                        <tr>
                                            <td valign="center">
                                            {
                                                this.state.segmentsList && <MultiSelect
                                                    options={this.state.segmentsList}
                                                    labelKey="name"
                                                    checkbox_id="slug"
                                                    placeholder="Segment"
                                                    disabledItems={[]}
                                                    selectedOptions={{slug: this.state.filter_key}}
                                                    onChange={(e) => {this.setState({filter_key: e.slug})}}
                                                />
                                            }
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="filterbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={
                                                        () => this.props.filterFunc(this.state.filter_key, 'filter')
                                                    }
                                                >
                                                    Filter
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="addleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Category
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="summarybtn"
                                                    className="btn btn_small btnwhite btncard move"
                                                    data-type=""
                                                    onClick={
                                                        () => this.props.filterFunc(this.state.search, 'search')
                                                    }
                                                >
                                                    Search
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div
                                    className="blackspan65 weight700 floatright"
                                    style={{height: 'auto', overflow: 'visible'}}
                                >
                                    <table className="stdtable" style={{height: 'auto'}}>
                                        <tr>
                                            <td valign="center">
                                                <input
                                                    type="text"
                                                    className="field border-box tiny"
                                                    name="searchbysku"
                                                    placeholder="Search by Category Name"
                                                    style={{ width: "200px" }}
                                                    onChange={(e) => this.handleChange("search", e)}
                                                /> 
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Segment</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Star Category</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Title</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                );

            case 'products':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft" style={{height:"50px"}}>
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <input type="text" className="dashboardfields dashboardchangefields tiny" name="searchbysku" placeholder="Enter Link" style={{ width: "250px",fontSize:"16px",height:"45px"}} onChange={(e) => this.handleChange("link", e)}></input>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button className="btn btn_small btncolorprim btncard move" onClick={this.handleAddLink}>Add</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                PRODUCT CSV: &nbsp;
                                                <input
                                                    type="file"
                                                    accept=".csv"
                                                    name="file"
                                                    onChange={this.onChange}
                                                />
                                                <button
                                                    className="btn btn_small btnwhite btncard move"
                                                    onClick={this.bulkEditProduct}
                                                >
                                                    Edit Bulk Product
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes35">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Brand</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Category</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Segment</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Price</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                );
            case 'brands':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="addleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Brand
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes45">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes40">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Code</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                );
            case "searchbysku":
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <input
                                                    type="text"
                                                    className="dashboardfields dashboardchangefields tiny"
                                                    name="searchbysku"
                                                    placeholder="Search by SKU"
                                                    style={{ width: "200px" }}
                                                    onChange={(e) => this.handleChange("search", e)}
                                                />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={this.handleSearchClick}
                                                >
                                                    Search
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Product
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    className="btn btn_small btnwhite btncard move"
                                                    onClick={this.uploadBulkProduct}
                                                >
                                                    Add Bulk Product
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    className="btn btn_small btnwhite btncard move"
                                                    onClick={this.addVariantToExistingProduct}
                                                >
                                                    Add New Variant
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright" style={{height: "auto"}}>
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                Product CSV: &nbsp;
                                                <input
                                                    type="file"
                                                    name="file"
                                                    accept=".csv"
                                                    onChange={this.onChange}
                                                />
                                                <br/><br/>
                                                Product Variant CSV: &nbsp;
                                                <input
                                                    type="file"
                                                    name="variant_file"
                                                    accept=".csv"
                                                    onChange={this.onChange}
                                                />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                {this.state.latest_product_id}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatleft" style={{height:"50px"}}>
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <input type="text" className="dashboardfields dashboardchangefields tiny" name="syncImage" placeholder="Enter Link" style={{ width: "250px",fontSize:"16px",height:"45px"}} onChange={(e) => this.handleChange("imgLink", e)}></input>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button className="btn btn_small btncolorprim btncard move" onClick={this.syncImagesHandler}>Sync Images</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            {/* <div className="border-box blackspan65 weight700 dpmboxes1">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Slug</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Description</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Title</td>
                                    </tr>
                                </table>
                            </div> */}
                        </div>
                    </React.Fragment>
                );
            case 'collectionv2':
            case 'collection':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="summarybtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    data-type=""
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Collection
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes20">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Collection Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes20">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Collection Slug</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes35">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Page Title</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'coupons':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="summarybtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    data-type=""
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Coupon
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="summarybtn"
                                                    className="btn btn_small btnwhite btncard move"
                                                    data-type=""
                                                    onClick={this.handleSearchClick}
                                                >
                                                    Search
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div
                                    className="blackspan65 weight700 floatright"
                                    style={{height: 'auto', overflow: 'visible'}}
                                >
                                    <table className="stdtable" style={{height: 'auto'}}>
                                        <tr>
                                            <td valign="center">
                                                <input
                                                    type="text"
                                                    className="field border-box tiny"
                                                    name="searchbysku"
                                                    placeholder="Search by Coupon Code"
                                                    style={{ width: "200px" }}
                                                    onChange={(e) => this.handleChange("search", e)}
                                                /> 
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Coupon Code</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Coupon Type</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Coupon Amt/PCT</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes20">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Start Date</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes20">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">End Date</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'installationservice':
                return (
                    <React.Fragment>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Product ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Service Code</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes20">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Service Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Price</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">MRP</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Tax Rate</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Final Price</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'rails':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="summarybtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    data-type=""
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Rails
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Rails Name</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'feed':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            {this.props.noContent ? (
                                <div className="border-box clearfix">
                                    <div className="blackspan65 weight700 floatright">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">
                                                    <button
                                                        id="summarybtn"
                                                        className="btn btn_small btncolorprim btncard move"
                                                        data-type=""
                                                        onClick={this.handleAddClick}
                                                    >
                                                        Add Feed
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Feed Type</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'enquiry':
                return (
                    <React.Fragment>
                    <div className="top border-box clearfix leftalign">
                      {/* <div className="border-box clearfix">
                        <div className="blackspan65 weight700 floatright">
                          <table className="stdtable">
                            <tr>
                              <td valign="center">
                                <button
                                  id="addleadbtn"
                                  className="btn btn_small btncolorprim btncard move"
                                  onClick={this.handleAddClick}
                                >
                                  Add mapping
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div> */}
                    </div>
                    <div className="middle border-box clearfix leftalign">
                      <div className="border-box blackspan65 weight700 dpmboxes1">
                        <table className="stdtable">
                          <tr>
                            <td valign="center"></td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box blackspan65 weight700 dpmboxes26">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">Page ID</td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box blackspan65 weight700 dpmboxes10">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">Title</td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box blackspan65 weight700 dpmboxes10">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">Created At</td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box blackspan65 weight700 dpmboxes10">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">Updated At</td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box blackspan65 weight700 dpmboxes9">
                        <table className="stdtable">
                          <tr>
                            <td valign="center"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                )
            case 'carPage':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div className="border-box clearfix">
                                <div className="blackspan65 weight700 floatright">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button
                                                    id="addleadbtn"
                                                    className="btn btn_small btncolorprim btncard move"
                                                    onClick={this.handleAddClick}
                                                >
                                                    Add Car Page
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes45">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes40">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Path</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                );                                
            case 'reviews':
                return (
                    <React.Fragment>
                        <div className="top border-box clearfix leftalign">
                            <div
                                className="border-box clearfix"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <input
                                    type="text"
                                    className="field border-box tiny"
                                    style={{ marginRight: '10px' }}
                                    placeholder="Product ID"
                                    value={this.state.reviewFilter.product_id}
                                    onChange={(e) => this.setState({ reviewFilter: { ...this.state.reviewFilter, product_id: e.target.value } })}
                                />
                                <input
                                    type="text"
                                    className="field border-box tiny"
                                    style={{ marginRight: '10px' }}
                                    placeholder="Order ID"
                                    value={this.state.reviewFilter.order_id}
                                    onChange={(e) => this.setState({ reviewFilter: { ...this.state.reviewFilter, order_id: e.target.value } })}
                                />
                                <input
                                    type="text"
                                    className="field border-box tiny"
                                    style={{ marginRight: '10px' }}
                                    placeholder="User ID"
                                    value={this.state.reviewFilter.user_id}
                                    onChange={(e) => this.setState({ reviewFilter: { ...this.state.reviewFilter, user_id: e.target.value } })}
                                />
                                <input
                                    type="tel"
                                    className="field border-box tiny"
                                    style={{ marginRight: '10px' }}
                                    placeholder="Week"
                                    value={this.state.reviewFilter.week}
                                    onChange={(e) => this.setState({ reviewFilter: { ...this.state.reviewFilter, week: e.target.value } })}
                                />
                                <input
                                    type="tel"
                                    className="field border-box tiny"
                                    style={{ marginRight: '10px' }}
                                    placeholder="Rating"
                                    value={this.state.reviewFilter.rating}
                                    onChange={(e) => this.setState({ reviewFilter: { ...this.state.reviewFilter, rating: e.target.value } })}
                                />
                                <button
                                    id="summarybtn"
                                    className="btn btn_small btncolorprim btncard move"
                                    data-type=""
                                    onClick={this.handleFilterClick}
                                >
                                    Filter Reviews
                                </button>
                            </div>
                        </div>
                        <div className="middle border-box clearfix leftalign">
                            <div className="border-box blackspan65 weight700 dpm1boxes3">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"> Product ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Order ID</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes15">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Review By</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes20">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Title</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes25">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Review</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes5">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Rating</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes10">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Reveiew Date</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="border-box blackspan65 weight700 dpm1boxes2">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                )
            case 'export':
                return (
                    <React.Fragment>
                    <div className="top border-box clearfix leftalign">
                        {/* <div className="border-box clearfix">
                        <div className="blackspan65 weight700 floatright">
                            <table className="stdtable">
                            <tr>
                                <td valign="center">
                                <button
                                    id="addleadbtn"
                                    className="btn btn_small btncolorprim btncard move"
                                    onClick={this.handleAddClick}
                                >
                                    Add mapping
                                </button>
                                </td>
                            </tr>
                            </table>
                        </div>
                        </div> */}
                    </div>
                    <div className="middle border-box clearfix leftalign">
                        <div className="border-box blackspan65 weight700 dpmboxes1">
                        <table className="stdtable">
                            <tr>
                            <td valign="center"></td>
                            </tr>
                        </table>
                        </div>
                        <div className="border-box blackspan65 weight700 dpmboxes40">
                        <table className="stdtable">
                            <tr>
                            <td valign="center">Page ID</td>
                            </tr>
                        </table>
                        </div>
                        <div className="border-box blackspan65 weight700 dpmboxes30">
                        <table className="stdtable">
                            <tr>
                            <td valign="center">Title</td>
                            </tr>
                        </table>
                        </div>
                        <div className="border-box blackspan65 weight700 dpmboxes10">
                        <table className="stdtable">
                            <tr>
                            <td valign="center">Created At</td>
                            </tr>
                        </table>
                        </div>
                        <div className="border-box blackspan65 weight700 dpmboxes10">
                        <table className="stdtable">
                            <tr>
                            <td valign="center">Updated At</td>
                            </tr>
                        </table>
                        </div>
                        <div className="border-box blackspan65 weight700 dpmboxes9">
                        <table className="stdtable">
                            <tr>
                            <td valign="center"></td>
                            </tr>
                        </table>
                        </div>
                    </div>
                    </React.Fragment>
                )        
            default:
            return null;
        }
    }
}

export default MiddleUpper;