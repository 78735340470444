import React from "react";
import "./base/sitebase1.css";
// import "./dashboard/dashboard.css"

import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";

class ChoiceBoxMulti1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props;
    }

    render() {
        var that = this;
        switch (this.props.type) {
            case "fleets":
                const items1 = JSON.parse(
                    localStorage.getItem("fleets_summary")
                ).map((obj, index) => (
                    <ChoiceBoxMulti1Inner
                        index={index}
                        selected={that.props.status[index][obj.id]}
                        datatype={that.props.type}
                        data-type={that.props.type}
                        name={obj.name}
                        data-name={obj.name}
                        onClick={this.props.onClick}
                        data-id={obj.id}
                        dataid={obj.id}
                    />
                ));
                return (
                    <React.Fragment>
                        <div className="fieldtitle">
                            {this.props.title} &nbsp;&nbsp;&nbsp;{" "}
                            <button
                                class="btn btn_tiny btnwhite btncard move toggleallfleets"
                                data-type={this.props.title}
                            >
                                Toggle all
                            </button>
                            {/* &nbsp;&nbsp;&nbsp; <button class="btn btn_tiny btnwhite btncard move togglebusinessfleets" data-type={this.props.title}>Toggle Business</button>
                          &nbsp;&nbsp;&nbsp; <button class="btn btn_tiny btnwhite btncard move toggleretailfleets" data-type={this.props.title}>Toggle Retail</button> */}
                        </div>
                        <div className="choiceboxmulti1" id={this.props.box_id}>
                            {items1}
                        </div>
                    </React.Fragment>
                );
            case "garages":
                const items2 = JSON.parse(
                    localStorage.getItem("garages_summary")
                ).map((obj, index) => (
                    <ChoiceBoxMulti1Inner
                        index={index}
                        selected={that.props.status[index][obj.id]}
                        datatype={that.props.type}
                        data-type={that.props.type}
                        name={obj.name}
                        data-name={obj.name}
                        onClick={this.props.onClick}
                        data-id={obj.id}
                        dataid={obj.id}
                    />
                ));
                return (
                    <React.Fragment>
                        <div className="fieldtitle">
                            {this.props.title} &nbsp;&nbsp;&nbsp;{" "}
                            <button
                                class="btn btn_tiny btnwhite btncard move toggleallgarages"
                                data-type={this.props.title}
                            >
                                Toggle all
                            </button>
                        </div>
                        <div className="choiceboxmulti1" id={this.props.box_id}>
                            {items2}
                        </div>
                    </React.Fragment>
                );
            case "statusgeneral":
                const items3 = JSON.parse(localStorage.getItem("statuses"))
                    .filter((o) => o.status_type == 101)
                    .map((obj, index) => (
                        <ChoiceBoxMulti1Inner
                            index={index}
                            selected={that.props.status[index][obj.id]}
                            datatype={that.props.type}
                            data-type={that.props.type}
                            name={obj.status}
                            data-name={obj.status}
                            onClick={this.props.onClick}
                            data-id={obj.id}
                            dataid={obj.id}
                        />
                    ));
                return (
                    <React.Fragment>
                        <div className="fieldtitle">
                            {this.props.title} &nbsp;&nbsp;&nbsp;{" "}
                            <button
                                class="btn btn_tiny btnwhite btncard move toggleallstatusgeneral"
                                data-type={this.props.title}
                            >
                                Toggle all
                            </button>
                        </div>
                        <div className="choiceboxmulti1" id={this.props.box_id}>
                            {items3}
                        </div>
                    </React.Fragment>
                );
            case "statusinsurance":
                const items4 = JSON.parse(localStorage.getItem("statuses"))
                    .filter((o) => o.status_type == 103)
                    .map((obj, index) => (
                        <ChoiceBoxMulti1Inner
                            index={index}
                            selected={that.props.status[index][obj.id]}
                            datatype={that.props.type}
                            data-type={that.props.type}
                            name={obj.status}
                            data-name={obj.status}
                            onClick={this.props.onClick}
                            data-id={obj.id}
                            dataid={obj.id}
                        />
                    ));
                return (
                    <React.Fragment>
                        <div className="fieldtitle">
                            {this.props.title} &nbsp;&nbsp;&nbsp;{" "}
                            <button
                                class="btn btn_tiny btnwhite btncard move toggleallstatusinsurance"
                                data-type={this.props.title}
                            >
                                Toggle all
                            </button>
                        </div>
                        <div className="choiceboxmulti1" id={this.props.box_id}>
                            {items4}
                        </div>
                    </React.Fragment>
                );
            case "statuspreventive":
                const items5 = JSON.parse(localStorage.getItem("statuses"))
                    .filter((o) => o.status_type == 100)
                    .map((obj, index) => (
                        <ChoiceBoxMulti1Inner
                            index={index}
                            selected={that.props.status[index][obj.id]}
                            datatype={that.props.type}
                            data-type={that.props.type}
                            name={obj.status}
                            data-name={obj.status}
                            onClick={this.props.onClick}
                            data-id={obj.id}
                            dataid={obj.id}
                        />
                    ));
                return (
                    <React.Fragment>
                        <div className="fieldtitle">
                            {this.props.title} &nbsp;&nbsp;&nbsp;{" "}
                            <button
                                class="btn btn_tiny btnwhite btncard move toggleallstatuspreventive"
                                data-type={this.props.title}
                            >
                                Toggle all
                            </button>
                        </div>
                        <div className="choiceboxmulti1" id={this.props.box_id}>
                            {items5}
                        </div>
                    </React.Fragment>
                );
            case "statusdocument":
                const items6 = JSON.parse(localStorage.getItem("statuses"))
                    .filter((o) => o.status_type == 102)
                    .map((obj, index) => (
                        <ChoiceBoxMulti1Inner
                            index={index}
                            selected={that.props.status[index][obj.id]}
                            datatype={that.props.type}
                            data-type={that.props.type}
                            name={obj.status}
                            data-name={obj.status}
                            onClick={this.props.onClick}
                            data-id={obj.id}
                            dataid={obj.id}
                        />
                    ));
                return (
                    <React.Fragment>
                        <div className="fieldtitle">
                            {this.props.title} &nbsp;&nbsp;&nbsp;{" "}
                            <button
                                class="btn btn_tiny btnwhite btncard move toggleallstatusdocument"
                                data-type={this.props.title}
                            >
                                Toggle all
                            </button>
                        </div>
                        <div className="choiceboxmulti1" id={this.props.box_id}>
                            {items6}
                        </div>
                    </React.Fragment>
                );
        }
    }
}

class ChoiceBoxMulti1Inner extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(type, id, index) {
        //          this.props.onTabClick(this.props.tag);
        /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
        /*if(this.state.selected){
              this.setState({selected:false});
          }else{
            this.setState({selected:true});
          }*/
        this.props.onClick(type, id, index);
    }

    render() {
        return (
            <div
                className={
                    this.props.selected
                        ? "choiceboxmulti1inner choiceboxmulti1innerselected"
                        : "choiceboxmulti1inner"
                }
                data-type={this.props.datatype}
                name={this.props.name}
                data-name={this.props.name}
                onClick={() =>
                    this.handleClick(
                        this.props.datatype,
                        this.props.dataid,
                        this.props.index
                    )
                }
                data-id={this.props.dataid}
            >
                {this.props.name}
            </div>
        );
    }
}

class ChoiceBoxMultiInner extends React.Component {
    constructor(props) {
        super(props);
        //      this.state = this.props;

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(type, value) {
        //          this.props.onTabClick(this.props.tag);
        /*this.setState(state => ({
            isToggleOn: !state.isToggleOn
          }));*/
        /*          if(this.state.selected){
              this.setState({selected:false});
          }else{
            this.setState({selected:true});
          }*/
        this.props.onClick(type, value);
    }

    render() {
        return (
            <div
                className={
                    this.props.selected
                        ? "choiceboxmultiinner choiceboxmultiinnerselected"
                        : "choiceboxmultiinner"
                }
                data-type={this.props.type}
                name={this.props.name}
                onClick={() =>
                    this.handleClick(this.props.type, this.props.value)
                }
            >
                {this.props.value}
            </div>
        );
    }
}

class FilterBox extends React.Component {
    constructor(props) {
        super(props);
        //    this.state = {};

        if (this.props.filterArgs) {
            this.state = this.props.filterArgs;
        } else {
            var fleets_array = [];
            JSON.parse(localStorage.getItem("fleets_summary")).forEach(
                function (obj, index) {
                    var obj1 = {};
                    obj1[obj.id] = false;
                    fleets_array.push(obj1);
                }
            );

            var garages_array = [];
            JSON.parse(localStorage.getItem("garages_summary")).forEach(
                function (obj, index) {
                    var obj1 = {};
                    obj1[obj.id] = false;
                    garages_array.push(obj1);
                }
            );

            var statuses = JSON.parse(localStorage.getItem("statuses"));

            var statusgeneral_array = [];
            statuses
                .filter((o) => o.status_type == 101)
                .forEach(function (obj, index) {
                    var obj1 = {};
                    obj1[obj.id] = false;
                    statusgeneral_array.push(obj1);
                });

            var statusinsurance_array = [];
            statuses
                .filter((o) => o.status_type == 103)
                .forEach(function (obj, index) {
                    var obj1 = {};
                    obj1[obj.id] = false;
                    statusinsurance_array.push(obj1);
                });

            var statuspreventive_array = [];
            statuses
                .filter((o) => o.status_type == 100)
                .forEach(function (obj, index) {
                    var obj1 = {};
                    obj1[obj.id] = false;
                    statuspreventive_array.push(obj1);
                });

            var statusdocument_array = [];
            statuses
                .filter((o) => o.status_type == 102)
                .forEach(function (obj, index) {
                    var obj1 = {};
                    obj1[obj.id] = false;
                    statusdocument_array.push(obj1);
                });

            this.state = {
                ordertypechoice: {
                    General: true,
                    Insurance: true,
                    Preventive: true,
                    Document: true,
                },
                channelchoice: { Business: true, Retail: true },
                fleets: fleets_array,
                garages: garages_array,
                statusdocument: statusdocument_array,
                statusgeneral: statusgeneral_array,
                statusinsurance: statusinsurance_array,
                statuspreventive: statuspreventive_array,
                all_statusdocument: [],
                all_statuspreventive: [],
                all_statusgeneral: [],
                all_statusinsurance: [],
                //            pick_date_end : [new Date()],
                //            delivery_date_end : [new Date()],
                //            est_delivery_date_end : [new Date()],
                all_fleets: [],
                all_garages: [],
            };
        }
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleChoiceBoxMultiInnerClick = this.handleChoiceBoxMultiInnerClick.bind(
            this
        );
        this.handleChoiceBoxMulti1Click = this.handleChoiceBoxMulti1Click.bind(
            this
        );
    }

    handleChoiceBoxMultiInnerClick(type, value, selected) {
        //this.setState({type : })
        switch (type) {
            case "ordertypechoice":
                var obj = this.state.ordertypechoice;

                if (this.state.ordertypechoice[value]) {
                    obj[value] = false;
                } else {
                    obj[value] = true;
                }
                this.setState({ ordertypechoice: obj });
                break;
            case "channelchoice":
                var obj = this.state.channelchoice;
                if (this.state.channelchoice[value]) {
                    obj[value] = false;
                } else {
                    obj[value] = true;
                }
                this.setState({ channelchoice: obj });
                break;
        }
    }

    handleChoiceBoxMulti1Click(type, value, index) {
        switch (type) {
            case "fleets":
                var array = this.state.fleets;
                var obj = array[index];
                obj[value] = !obj[value];
                array[index] = obj;
                var array1 = this.state.all_fleets;
                var index1 = array1.indexOf(value);
                if (index1 !== -1) {
                    if (!obj[value]) array1.splice(index1, 1);
                } else {
                    if (obj[value]) array1.push(value);
                }
                this.setState({ fleets: array, all_fleets: array1 });
                break;
            case "garages":
                var array = this.state.garages;
                var obj = array[index];
                obj[value] = !obj[value];
                array[index] = obj;
                var array1 = this.state.all_garages;
                var index1 = array1.indexOf(value);
                if (index1 !== -1) {
                    if (!obj[value]) array1.splice(index1, 1);
                } else {
                    if (obj[value]) array1.push(value);
                }
                this.setState({ garages: array, all_garages: array1 });
                break;
            case "statusgeneral":
                var array = this.state.statusgeneral;
                var obj = array[index];
                obj[value] = !obj[value];
                array[index] = obj;
                var array1 = this.state.all_statusgeneral;
                var index1 = array1.indexOf(value);
                if (index1 !== -1) {
                    if (!obj[value]) array1.splice(index1, 1);
                } else {
                    if (obj[value]) array1.push(value);
                }
                this.setState({
                    statusgeneral: array,
                    all_statusgeneral: array1,
                });
                break;
            case "statusinsurance":
                var array = this.state.statusinsurance;
                var obj = array[index];
                obj[value] = !obj[value];
                array[index] = obj;
                var array1 = this.state.all_statusinsurance;
                var index1 = array1.indexOf(value);
                if (index1 !== -1) {
                    if (!obj[value]) array1.splice(index1, 1);
                } else {
                    if (obj[value]) array1.push(value);
                }
                this.setState({
                    statusinsurance: array,
                    all_statusinsurance: array1,
                });
                break;
            case "statuspreventive":
                var array = this.state.statuspreventive;
                var obj = array[index];
                obj[value] = !obj[value];
                array[index] = obj;
                var array1 = this.state.all_statuspreventive;
                var index1 = array1.indexOf(value);
                if (index1 !== -1) {
                    if (!obj[value]) array1.splice(index1, 1);
                } else {
                    if (obj[value]) array1.push(value);
                }
                this.setState({
                    statuspreventive: array,
                    all_statuspreventive: array1,
                });
                break;
            case "statusdocument":
                var array = this.state.statusdocument;
                var obj = array[index];
                obj[value] = !obj[value];
                array[index] = obj;
                var array1 = this.state.all_statusdocument;
                var index1 = array1.indexOf(value);
                if (index1 !== -1) {
                    if (!obj[value]) array1.splice(index1, 1);
                } else {
                    if (obj[value]) array1.push(value);
                }
                this.setState({
                    statusdocument: array,
                    all_statusdocument: array1,
                });
                break;
            default:
                break;
        }
    }

    handleFilterClick() {
        this.props.onFilterClick();
    }

    render() {
        return (
            <div
                className={
                    this.props.filterShowing
                        ? "filterbox active border-box clearfix leftalign card bgwhite filterleads"
                        : "filterbox border-box clearfix leftalign card bgwhite filterleads"
                }
            >
                <div className="border-box clearfix">
                    <div className="blackspan65 weight700 floatleft">
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <button
                                        id="filterclosebtn"
                                        className="btn btn_small btncolorprim btncard move"
                                        onClick={this.handleFilterClick}
                                    >
                                        Close
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    {/* <div className="blackspan65 weight700 floatright">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <button id="filterleadsummarybtn" className="btn btn_small btncolorprim btncard move">Summary</button>
                              </td>
                          </tr>
                      </table>
                  </div> */}
                    <div className="blackspan65 weight700 floatright">
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <button
                                        id="filterleadsearchbtn"
                                        className="btn btn_small btncolorprim btncard move"
                                        onClick={() =>
                                            this.props.onSearchClick(this.state)
                                        }
                                    >
                                        Search
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                {/* <div className="border-box clearfix">
                  <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <input type="text" id="fieldsearchbyid" className="dashboardfields dashboardchangefields tiny" name="searchbyid" placeholder="Search by Ref. No." style={{width: '150px'}} />
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatright">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <input type="text" id="fieldsearchbyname" className="dashboardfields dashboardchangefields tiny" name="searchbyname" placeholder="Search by Name" style={{width: '150px'}} />
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatright">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <input type="text" id="fieldsearchbyphone" className="dashboardfields dashboardchangefields tiny" name="searchbyphone" placeholder="Search by Phone" style={{width: '150px'}} />
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatright">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <input type="text" id="fieldsearchbynrcount" className="dashboardfields dashboardchangefields tiny" name="searchbynrcount" placeholder="Search by NR Count" style={{width: '150px'}} />
                              </td>
                          </tr>
                      </table>
                  </div>
              </div> */}
                <div className="border-box clearfix">
                    <div className="blackspan65 weight700 floatleft">
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <div className="choiceboxmulti">
                                        <ChoiceBoxMultiInner
                                            name="101"
                                            value="General"
                                            type="ordertypechoice"
                                            selected={
                                                this.state.ordertypechoice
                                                    .General
                                            }
                                            onClick={
                                                this
                                                    .handleChoiceBoxMultiInnerClick
                                            }
                                        />
                                        {/* <ChoiceBoxMultiInner name="103" value="Insurance" type="ordertypechoice" selected={true} onClick={() => this.handleChoiceBoxMultiInnerClick("ordertypechoice", 103)} /> */}
                                        <ChoiceBoxMultiInner
                                            name="103"
                                            value="Insurance"
                                            type="ordertypechoice"
                                            selected={
                                                this.state.ordertypechoice
                                                    .Insurance
                                            }
                                            onClick={
                                                this
                                                    .handleChoiceBoxMultiInnerClick
                                            }
                                        />
                                        <ChoiceBoxMultiInner
                                            name="100"
                                            value="Preventive"
                                            type="ordertypechoice"
                                            selected={
                                                this.state.ordertypechoice
                                                    .Preventive
                                            }
                                            onClick={
                                                this
                                                    .handleChoiceBoxMultiInnerClick
                                            }
                                        />
                                        <ChoiceBoxMultiInner
                                            name="102"
                                            value="Document"
                                            type="ordertypechoice"
                                            selected={
                                                this.state.ordertypechoice
                                                    .Document
                                            }
                                            onClick={
                                                this
                                                    .handleChoiceBoxMultiInnerClick
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="blackspan65 weight700 floatright">
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <div className="choiceboxmulti">
                                        <ChoiceBoxMultiInner
                                            name="0"
                                            value="Business"
                                            type="channelchoice"
                                            selected={
                                                this.state.channelchoice
                                                    .Business
                                            }
                                            onClick={
                                                this
                                                    .handleChoiceBoxMultiInnerClick
                                            }
                                        />
                                        <ChoiceBoxMultiInner
                                            name="1"
                                            value="Retail"
                                            type="channelchoice"
                                            selected={
                                                this.state.channelchoice.Retail
                                            }
                                            onClick={
                                                this
                                                    .handleChoiceBoxMultiInnerClick
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                {/* <div className="border-box clearfix">
                  <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <div className="choiceboxmulti">
                                      <div className="choiceboxmultiinner choiceboxmultiinnerselected directionchoice" name="to_office">Going to office</div>
                                      <div className="choiceboxmultiinner directionchoice" name="from_office">Coming from office</div>
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatright">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <div className="choiceboxmulti">
                                      <div className="choiceboxmultiinner genderchoice" name="Male">Male</div>
                                      <div className="choiceboxmultiinner genderchoice" name="Female">Female</div>
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </div>
              </div> */}
                <div className="border-box clearfix ">
                    {/* <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <select className="dashboardfields dashboardchangefields select tiny" id="fieldstarttimefrom" name="starttimefrom" style={{width: '80px'}}>' + timeoptions + '</select>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <select className="dashboardfields dashboardchangefields select tiny" id="fieldstarttimeto" name="starttimeto" style={{width: '80px'}}>' + timeoptions + '</select>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <select className="dashboardfields dashboardchangefields select tiny" id="fieldendtimefrom" name="endtimefrom" style={{width: '80px'}}>' + timeoptions + '</select>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="blackspan65 weight700 floatleft">
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <select className="dashboardfields dashboardchangefields select tiny" id="fieldendtimeto" name="endtimeto" style={{width: '80px'}}>' + timeoptions + '</select>
                              </td>
                          </tr>
                      </table>
                  </div> */}
                    <div className="blackspan65 weight700 floatleft">
                        <table className="stdtable">
                            <tr>
                                <div className="fieldtitle">Pick date</div>
                            </tr>
                            <tr>
                                <td valign="center">
                                    <Flatpickr
                                        value={this.state.pick_date_start}
                                        onChange={(date) => {
                                            this.setState({
                                                pick_date_start: date,
                                            });
                                        }}
                                    />
                                    {/* <input type="text" id="fielddateto" className="dashboardfields dashboardchangefields tiny" name="dateto" placeholder="End date" readonly="readonly" style={{width: '100px', fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                                </td>
                                <td valign="center">
                                    <Flatpickr
                                        value={this.state.pick_date_end}
                                        onChange={(date) => {
                                            this.setState({
                                                pick_date_end: date,
                                            });
                                        }}
                                    />
                                    {/* <input type="text" id="fielddateto" className="dashboardfields dashboardchangefields tiny" name="dateto" placeholder="End date" readonly="readonly" style={{width: '100px', fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="blackspan65 weight700 floatleft">
                        <table className="stdtable">
                            <tr>
                                <div className="fieldtitle">Delivery date</div>
                            </tr>
                            <tr>
                                <td valign="center">
                                    <Flatpickr
                                        value={this.state.delivery_date_start}
                                        onChange={(date) => {
                                            this.setState({
                                                delivery_date_start: date,
                                            });
                                        }}
                                    />
                                    {/* <input type="text" id="fielddateto" className="dashboardfields dashboardchangefields tiny" name="dateto" placeholder="End date" readonly="readonly" style={{width: '100px', fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                                </td>
                                <td valign="center">
                                    <Flatpickr
                                        value={this.state.delivery_date_end}
                                        onChange={(date) => {
                                            this.setState({
                                                delivery_date_end: date,
                                            });
                                        }}
                                    />
                                    {/* <input type="text" id="fielddateto" className="dashboardfields dashboardchangefields tiny" name="dateto" placeholder="End date" readonly="readonly" style={{width: '100px', fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="blackspan65 weight700 floatleft">
                        <table className="stdtable">
                            <tr>
                                <div className="fieldtitle">
                                    Est. Delivery date
                                </div>
                            </tr>
                            <tr>
                                <td valign="center">
                                    <Flatpickr
                                        value={
                                            this.state.est_delivery_date_start
                                        }
                                        onChange={(date) => {
                                            this.setState({
                                                est_delivery_date_start: date,
                                            });
                                        }}
                                    />
                                    {/* <input type="text" id="fielddateto" className="dashboardfields dashboardchangefields tiny" name="dateto" placeholder="End date" readonly="readonly" style={{width: '100px', fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                                </td>
                                <td valign="center">
                                    <Flatpickr
                                        value={this.state.est_delivery_date_end}
                                        onChange={(date) => {
                                            this.setState({
                                                est_delivery_date_end: date,
                                            });
                                        }}
                                    />
                                    {/* <input type="text" id="fielddateto" className="dashboardfields dashboardchangefields tiny" name="dateto" placeholder="End date" readonly="readonly" style={{width: '100px', fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                {/* <div className="border-box clearfix">
                  <div className="border-box blackspan65 weight700 floatleft" style={{width: '48%', height: 'auto'}}>
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <div className="fieldtitle">Start City</div>
                                  <div className="choiceboxmulti1" id="choiceboxcitystart"></div>
                              </td>
                          </tr>
                      </table>
                  </div>
                  <div className="border-box blackspan65 weight700 floatright" style={{width: '48%', height: 'auto'}}>
                      <table className="stdtable">
                          <tr>
                              <td valign="center">
                                  <div className="fieldtitle">End City</div>
                                  <div className="choiceboxmulti1" id="choiceboxcityend"></div>
                              </td>
                          </tr>
                      </table>
                  </div>
              </div> */}
                <div className="border-box clearfix">
                    <div
                        className="border-box blackspan65 weight700 floatleft"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <ChoiceBoxMulti1
                                        title="Status - General"
                                        box_id="choiceboxstatusgeneral"
                                        type="statusgeneral"
                                        status={this.state.statusgeneral}
                                        onClick={
                                            this.handleChoiceBoxMulti1Click
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="border-box clearfix">
                    <div
                        className="border-box blackspan65 weight700 floatleft"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <ChoiceBoxMulti1
                                        title="Status - Insurance"
                                        box_id="choiceboxstatusinsurance"
                                        type="statusinsurance"
                                        status={this.state.statusinsurance}
                                        onClick={
                                            this.handleChoiceBoxMulti1Click
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="border-box clearfix">
                    <div
                        className="border-box blackspan65 weight700 floatleft"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <ChoiceBoxMulti1
                                        title="Status - Preventive"
                                        box_id="choiceboxstatuspreventive"
                                        type="statuspreventive"
                                        status={this.state.statuspreventive}
                                        onClick={
                                            this.handleChoiceBoxMulti1Click
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="border-box clearfix">
                    <div
                        className="border-box blackspan65 weight700 floatleft"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <ChoiceBoxMulti1
                                        title="Status - Document"
                                        box_id="choiceboxstatusdocument"
                                        type="statusdocument"
                                        status={this.state.statusdocument}
                                        onClick={
                                            this.handleChoiceBoxMulti1Click
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="border-box clearfix">
                    <div
                        className="border-box blackspan65 weight700 floatleft"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <ChoiceBoxMulti1
                                        title="Fleets"
                                        box_id="choiceboxfleets"
                                        type="fleets"
                                        status={this.state.fleets}
                                        onClick={
                                            this.handleChoiceBoxMulti1Click
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="border-box clearfix">
                    <div
                        className="border-box blackspan65 weight700 floatright"
                        style={{ width: "100%", height: "auto" }}
                    >
                        <table className="stdtable">
                            <tr>
                                <td valign="center">
                                    <ChoiceBoxMulti1
                                        title="Garages"
                                        box_id="choiceboxgarages"
                                        type="garages"
                                        status={this.state.garages}
                                        onClick={
                                            this.handleChoiceBoxMulti1Click
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilterBox;
