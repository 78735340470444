import React, {  useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import MultiSelect from "../broadcast/MultiSelect/MultiSelect";
import _ from "lodash";
import API from "../api";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

const ManageAgents = (props) => {
  const {
    handleButtonClick,
    type,
    agentData,
    pageRefresh,
    getAgent
  } = props;


  const mandateFields = [
    { key: "first_name", err_msg: "Name" },
    { key: "phone_number", err_msg: "Phone Number" },
    { key: "email", err_msg: "Email" },
    { key: "agent_type", err_msg: "Agent Type" },
  ];

  const [agentObj, setagentObj] = useState({
    customer_id: _.get(agentData, ["customer_id"], null),
    first_name: _.get(agentData, ["first_name"], null),
    phone_number: _.get(agentData, ["phone_number"], null),
    email: _.get(agentData, ["email"], null),
    retailers:agentData && Object.keys(agentData.retailers).map((key)=>({id:Number(key),name:agentData.retailers[key]})),
    retailer:null,
    file:null,
    agent_type:_.get(agentData, ["agent_type"], null),
    search:'',
    pay_later:_.get(agentData, ["pay_later"], false),
    agentLoader:false,
    loader:false
  });

  const agentType = [
    { label: "Sales", value: "Sales" },
    { label: "CCE", value: "CCE" },
    { label: "GAR", value: "GAR" },
    { label: "Distribution", value: "Distribution" },
  ];

  useEffect(() => {
    setagentObj({...agentObj,retailers:agentData && Object.keys(agentData.retailers).map((key)=>({id:Number(key),name:agentData.retailers[key]})),
  })      
  }, [agentData])
  

  function validateNumber(evt) {  //check for number
    let e = evt || window.event;
    let key = e.keyCode || e.which;
    if (!(key >= 48 && key <= 57)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  }
  const onInputChange = (e, keyName, type) => {
    const temp = { ...agentObj };
    if (type === "number") {
        temp[keyName] = Number(e.target.value);
    }
     else {
      temp[keyName] = e.target.value;
    }
 
    setagentObj({ ...temp });
  };

  const removeRetailer=(id)=> {
    const ids=agentObj.retailers.filter(item=>item.id!==id)
   setagentObj({...agentObj,retailers:ids})
  }

  const saveAgentDetails = () => {
    const errStr = mandateFields.find((item) => !agentObj[item.key]);
      if (agentObj.email&& !agentObj.email.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/)) {
        NotificationManager.error('Enter Your Correct Email Id',"Error")
        return;
     }
    if (errStr) {
      NotificationManager.error(
        `${errStr.err_msg} is required.`,
        "Error!!",
      );
      return;
    }
    const data = JSON.parse(JSON.stringify(agentObj));
    data.retailers=agentObj.retailers && agentObj.retailers.map(item=>Number(item.id))
    data.phone_number=''+agentObj.phone_number
    data.pay_later=agentObj.pay_later?1:0;

    delete data.retailer
		delete data.file
		delete data.search
    if (type === "add") {
      setagentObj({...agentObj,agentLoader:true})
      API.addAgent(data)
        .then(() => {
          setagentObj({...agentObj,agentLoader:false})
          pageRefresh();
          NotificationManager.success("Agent created", "Success");
        })
        .catch((error) => {
         setagentObj({...agentObj,agentLoader:false})
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
          console.error(error);
        });
    } else {
      setagentObj({...agentObj,agentLoader:true})
      API.updateAgent(data)
        .then(() => {
          setagentObj({...agentObj,agentLoader:false})
          pageRefresh();
          NotificationManager.success(
            "Agent updated successfully.",
            "Success",
            5000
          );
        })
        .catch((error) => {
          setagentObj({...agentObj,agentLoader:false})
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
          console.error(error);
        });
    }
  };
  function handleSearchClick() {
		if (agentObj.search != null) {
      API.getRetailer(agentObj.search).then(function (response) {
          if (response.status === 204 || response.data.data.length == 0) {
            alert('No Content');
          } else {
            setagentObj({...agentObj,
              retailers:[{id:response.data.data.customer_id,name:response.data.data.first_name},...(agentObj.retailers || [])],
              search:'',
        })
          }
        
        })
        .catch(function (error) {
          NotificationManager.error('Invalid User', 'Error', 2000);
        })
        .finally(function () {
          // always executed
        });
		} else {
			NotificationManager.error(
				'Please Enter Valid Search Term',
				'Error',
				3000
			);
		}
	}
const onRetailersBulkSubmit=(e)=>{
  e.preventDefault();
		if (!agentObj.file)
			NotificationManager.error(
				'Please Select Correct Format File First',
				'Error',
				3000  
			);
		else {
      setagentObj({...agentObj,loader:true})
			API.uploadRetailersBulkDiscount(agentObj.file,agentObj.customer_id)
				.then(() => {
          setagentObj({...agentObj,loader:false})
          getAgent(agentObj.customer_id)   
					NotificationManager.success('File Uploaded', 'Success', 3000);
				})
				.catch((e) => {
          setagentObj({...agentObj,loader:false})
					NotificationManager.error(
						_.get(e, ['response', 'data', 'message'], 'Invalid File format.'),
						'Error',
						3000
					);
					console.log('error ', e);
				});
		}
}
  return (
        <div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box">
          <div className="extramainpanel border-box leftalign">
            <div className="border-box clearfix title">
              <div
                className="border-box floatleft leftalign blackspan2 weight900 shadow-text"
                style={{ padding: "0px 0px 0px 10px" }}
              >
                {type === "edit" ? "Edit Agent Details" : "Add Agent Details"}
              </div>
              <div className="border-box floatright rightalign">
                <button
                  className="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => handleButtonClick("close")}
                >
                  Close
                </button>
              </div>
              <div className="border-box floatright rightalign">
          {!agentObj.customer_id? 
            agentObj.agentLoader?
              <ButtonLoader />:
             <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={saveAgentDetails}
            >
              Save
            </button>:
            agentObj.agentLoader?
              <ButtonLoader />:
            <button
              className="btn btn_small btncolorprim btncard move btncloseextra"
              onClick={saveAgentDetails}
            >
              Update
            </button>
            }
        
          </div>
            </div>
            <div className="border-box fields">
              <div className="border-box field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Name<sup>*</sup>
                </div>
                <input
                  type="text"
                  name='first_name'
                  placeholder="Agent name"
                  value={agentObj.first_name}
                  className="field border-box small uppercased"
                  //empty space validation at first index
                  onKeyPress={(e) => {
                    try {
                      var startPos = e.currentTarget.selectionStart;
                      if (e.which === 32 && startPos == 0) {
                        e.preventDefault();
                      }
                    } catch (err) { console.log(err); }
                  }}
                  onChange={(e) => onInputChange(e, "first_name", "string")}
                />
              </div>
              <div className="border-box field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Phone<sup>*</sup>
                </div>
                <input
                  type="tel"  
                  maxLength={10}
                  onKeyPress={(event) => validateNumber(event)}
                  placeholder="Phone"
                  value={agentObj.phone_number}
                  className="field border-box small uppercased"
                  onChange={(e) => onInputChange(e, "phone_number", "number")}
                />
              </div>
            </div>

            <div className="border-box fields">
              <div className="border-box field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Email<sup>*</sup>
                </div>
                <input
                  type="email"
                  placeholder="email"
                  value={agentObj.email}
                  className="field border-box small"
                  onChange={(e) => onInputChange(e, "email", "string")}
                />
              </div>
              <div className="border-box field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Agent Type<sup>*</sup>
            </div>
            <MultiSelect
              options={agentType}
              labelKey="value"
              checkbox_id="value"
              placeholder="Agent Type"
              disabledItems={[]}
              selectedOptions={{value:agentObj.agent_type}}
              onChange={(e) => {
                setagentObj({
                  ...agentObj,
                  agent_type: e.value,
                });
              }}
            />
          </div>
         
            </div>
            {type!=='add' &&

            <div className="border-box clearfix fields">

              <div className="border-box clearfix field2">
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                  Customer ID<sup>*</sup>
                </div>
                <input
                  type="number"
                  placeholder="Customer Id"
                  value={agentObj.customer_id}
                  className="field border-box small uppercased"
                  onChange={(e) => onInputChange(e, "customer_id", "number")}
                  readOnly={type === "edit"}
                />
              </div>

              <div className="border-box clearfix field2">
                  <div style={{ width: "25%",minHeight:'5rem'}}> 
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Pay Later
            </div>
            <div>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchWallet5"
                id="toggleSwitchWallet5"
                onChange={() =>
                  setagentObj({...agentObj,pay_later: !agentObj.pay_later})
                }
                defaultChecked={agentObj.pay_later}
              />
              <label
                className="toggle-switch-label"
                htmlFor="toggleSwitchWallet5"
              >
                <span className="toggle-switch-inner" />
              </label>
            </div>
            </div>
          </div>
              </div>
            }

            <div className="border-box clearfix fields ">
            <div className="border-box clearfix field2">
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7" 
            >
              Retailers
              </div> 
                  <div style={{ display: "flex" }}>
                    <input
												type='text'
                        className="field border-box small"
												name='searchbysku'
												placeholder='Search customer name or No.'
												value={agentObj.search}
												onChange={(e) => onInputChange(e,'search','string')}
											></input>
                  <div>
                        <button
                          className="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() =>{ 
                            handleSearchClick()
                          }
                          }
                          style={{ marginLeft:"1rem" }}
                          >
                          Add New Retailer
                        </button>
                        </div>
                      </div>
                  </div>
            { type==='add'&&     <div className="border-box clearfix field2">
                  <div style={{ width: "25%",minHeight:'5rem'}}> 
            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
              Pay Later
            </div>
            <div>
              <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitchWallet5"
                id="toggleSwitchWallet5"
                onChange={() =>
                  setagentObj({...agentObj,pay_later: !agentObj.pay_later})
                }
                defaultChecked={agentObj.pay_later}
              />
              <label
                className="toggle-switch-label"
                htmlFor="toggleSwitchWallet5"
              >
                <span className="toggle-switch-inner" />
              </label>
            </div>
            </div>
          </div>}
                  {type!=='add' &&<>

                  <div className="border-box clearfix field2">
								<div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Retailer Bulk Upload</div>
								<div style={{display:'flex'}}>
										<input
											className='epc-file-upload'
											type='file'
											id='myepcfile'
											onChange={(e) =>
												setagentObj({...agentObj, file: e.target.files[0] })
											}
											accept='.csv'
										/>
                   {agentObj.loader?
                  <ButtonLoader whiteColor={true}/>:
									<form onSubmit={onRetailersBulkSubmit}>
										<button
											type='submit'
											className='btn btn_small btnwhite btncard move'
										>
										 Upload
										</button>
									</form>}

										<a
											href='https://dev.spares-mp.gomechanic.app/agent-bulk/'
											target='_blank'
										>
											<button
												type='button'
												className='btn btn_small btnwhite btncard move'
											>
												TEMPLATE
											</button>
										</a>
              </div>
							</div>
              </>}
           
              </div>           
            {agentObj.retailers && agentObj.retailers.map((item)=>{
              return(
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2" style={{display:'flex'}}>

                      <input
                        type="text"
                        placeholder="Retailer"
                        className="field border-box small"
                        value={ item.name? item.name + '-'+ item.id:item.id}
                      />
                        <div style={{display: "flex"}}>
                        <div className="border-box">
                        <button
                          class="btn btn_small btncolorprim btncard move btncloseextra"
                          onClick={() =>removeRetailer(item.id)}
                          style={{ marginLeft: "1rem" }}
                        >
                          Remove
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            )})}
        <div className="border-box clearfix footer"></div>
      </div>
    </div>
  );
};

export default ManageAgents;
