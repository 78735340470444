import React from "react";
// import "./dashboard/dashboard.css"
class MiddleUpper extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleSummaryClick = this.handleSummaryClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleFilterClick() {
    // console.log("clicking");
    this.props.onFilterClick();
  }

  handleSummaryClick() {}

  handleAddClick() {
    this.props.onAddClick(this.props.tag);
  }

  render() {
    switch (this.props.tag) {
      case "page_path_mapping":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleFilterClick}
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleAddClick}
                        >
                          Add mapping
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="summarybtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                          onClick={this.handleSummaryClick}
                        >
                          Summary
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes26">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Path</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Title</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Heading</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Content heading</td>
                  </tr>
                </table>
              </div>
              {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                <table className="stdtable">
                                    <tr>
                                        <td valign="center">Content</td>
                                    </tr>
                                </table>
                            </div> */}
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );
      case "faq_near_me":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleFilterClick}
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleAddClick}
                        >
                          Add Question
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="summarybtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                          onClick={this.handleSummaryClick}
                        >
                          Summary
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Path</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes4">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Heading</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes4">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Content</td>
                  </tr>
                </table>
              </div>
              {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                        <table className="stdtable">
                                            <tr>
                                                <td valign="center">Content</td>
                                            </tr>
                                        </table>
                                    </div> */}
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );

      case "price_list":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleFilterClick}
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                                <table className="stdtable">
                                                    <tr>
                                                        <td valign="center">
                                                            <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleAddClick}
                        >
                          Add Service
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="summarybtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                          onClick={this.handleSummaryClick}
                        >
                          Summary
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Path</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes3">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Heading</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes3">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Service</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Price</td>
                  </tr>
                </table>
              </div>

              {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">Content</td>
                                                </tr>
                                            </table>
                                        </div> */}
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );
      case "price_comparison":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleFilterClick}
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                                <table className="stdtable">
                                                    <tr>
                                                        <td valign="center">
                                                            <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleAddClick}
                        >
                          Add Comparison
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="summarybtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                          onClick={this.handleSummaryClick}
                        >
                          Summary
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Path</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Car type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Auth Mech Price</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Local Mech Price</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">GoMech Price</td>
                  </tr>
                </table>
              </div>

              {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">Content</td>
                                                </tr>
                                            </table>
                                        </div> */}
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );
      case "testimonials":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleFilterClick}
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                                    <table className="stdtable">
                                                        <tr>
                                                            <td valign="center">
                                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleAddClick}
                        >
                          Add Testimonial
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="summarybtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                          onClick={this.handleSummaryClick}
                        >
                          Summary
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes12">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page Path</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Customer</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes3">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Heading</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes3">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Content</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Source</td>
                  </tr>
                </table>
              </div>

              {/* <div className="border-box blackspan65 weight700 dpmboxes8">
                                                <table className="stdtable">
                                                    <tr>
                                                        <td valign="center">Content</td>
                                                    </tr>
                                                </table>
                                            </div> */}
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );
      case "fleets":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                    <table className="stdtable">
                                        <tr>
                                            <td valign="center">
                                                <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                        >
                          Add fleet
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes26">
                <table className="stdtable">
                  <tr>
                    <td valign="center">ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Name</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Location</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Primary contact</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Billing name</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );

      case "garages":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatleft">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="filterbtn"
                          className="btn btn_small btncolorprim btncard move"
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                {/*		        <div className="blackspan65 weight700 floatleft">
                                            <table className="stdtable">
                                                <tr>
                                                    <td valign="center">
                                                        <button id="mapleadbtn" className="btn btn_small btncolorprim btncard move">Map</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>*/}
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                        >
                          Add garage
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="excelleadsbtn"
                          className="btn btn_small btnwhite btncard move"
                          data-type=""
                        >
                          Get as Excel file
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes26">
                <table className="stdtable">
                  <tr>
                    <td valign="center">ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Name</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Code</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Location</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Contact</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Display name</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );
      case "near_me_acc":
        return (
          <React.Fragment>
            <div className="top border-box clearfix leftalign">
              <div className="border-box clearfix">
                <div className="blackspan65 weight700 floatright">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          id="addleadbtn"
                          className="btn btn_small btncolorprim btncard move"
                          onClick={this.handleAddClick}
                        >
                          Add mapping
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="middle border-box clearfix leftalign">
              <div className="border-box blackspan65 weight700 dpmboxes1">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes26">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Page ID</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes8">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Type</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Path</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Title</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Heading</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes10">
                <table className="stdtable">
                  <tr>
                    <td valign="center">Content heading</td>
                  </tr>
                </table>
              </div>
              <div className="border-box blackspan65 weight700 dpmboxes9">
                <table className="stdtable">
                  <tr>
                    <td valign="center"></td>
                  </tr>
                </table>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return null;
    }
  }
}

export default MiddleUpper;
