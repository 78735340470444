import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import "./hurme1.css";
import * as Sentry from "@sentry/browser";
import "./base/sitebase1.css";
import App from "./App";
import AppContentWebsite from "./AppContentWebsite";
import AppAccessories from "./AppAccessories";
import AppAccessoriesB2B from "./AppAccessoriesB2B";
import AppSpares from "./AppSpares";
import AppSparesBanners from "./AppSparesBanners";

import AppSparesContent from "./AppSparesContent";
import AppInsurance from "./AppInsurance";
// import AppIncentives from './AppIncentives';
// import AppChat from './AppChat';
import Login from "./Login";
import Logout from "./Logout";
import Dashboard from "./dashboard";
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import AppBroadCast from "./AppBroadCast";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
Sentry.init({
    dsn: "https://227a629ed34141c9b70770977992e5e6@sentry.io/1861269",
});

ReactDOM.render(
    <BrowserRouter>
        <NotificationContainer />
        <Switch>
            {/* <Route exact path="/" component={App} /> */}
            {/* {(localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Support Executive') > -1) && <Redirect exact from='/' to='/chat'/>}
            {(localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Content Executive') > -1) && <Redirect exact from='/' to='/content/website'/>} */}
            {/* {(localStorage.getItem("user_roles") && localStorage.getItem("user_roles").indexOf('Support Executive') > -1) && <Route path="/chat" component={AppChat} />} */}
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/dashboard" component={App} />
            {/* <Route path="/chat" component={AppChat} /> */}
            {/* <Route exact path="/content" component={<div>Hello Content</div>} /> AppSpares*/}
            <Route exact path="/content/website" component={AppContentWebsite} />
            <Route path="/accessories" component={AppAccessories} />
            <Route exact path="/spares" component={AppSpares} />
            <Route exact path="/spares/content" component={AppSparesContent} />
            <Route exact path="/spares/banners" component={AppSparesBanners} />


            <Route path="/accessoriesb2b" component={AppAccessoriesB2B} />
            <Route path="/spares" component={AppSpares} />
            <Route path="/insurance" component={AppInsurance} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/broadcast" component={AppBroadCast} />
            {/* <Route path="/incentives" component={AppIncentives} /> */}
        </Switch>
    </BrowserRouter>,
    document.getElementById("page-body")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// const express = require('express');
// const path = require('path');

// const app = express();

// // Serve the static files from the React app
// app.use(express.static(path.join(__dirname, 'client/build')));

// // An api endpoint that returns a short list of items
// // app.get('/api/getList', (req,res) => {
// //     var list = ["item1", "item2", "item3"];
// //     res.json(list);
// //     // console.log('Sent list of items');
// // });

// // Handles any requests that don't match the ones above
// app.get('*', (req,res) =>{
//     res.sendFile(path.join(__dirname+'/client/build/index.html'));
// });

// const port = process.env.PORT || 5000;
// app.listen(port);

// // console.log('App is listening on port ' + port);
