import Constants from './constants';
import React from 'react';
import Toast from '../src/common/Toast';
// import ChatUtilsArchive from './support_chat/chat_utils_archive';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import _ from 'lodash';

const axios = require('axios');

function getConfig(token) {
	return { headers: { Authorization: 'Bearer ' + token } };
}

function getConfigMultiForm(token) {
	return {
		headers: {
			Authorization: 'Bearer ' + token,
			'content-type': 'multipart/form-data',
		},
	};
}

function getConfigMultiForm1() {
	return { headers: { 'content-type': 'multipart/form-data' } };
}

function getConfigMultiForm2() {
	return {
		headers: {
			'content-type': 'application/json',
		},
	};
}

function getConfigWithParams(token, params) {
	return { headers: { Authorization: 'Bearer ' + token }, params: params };
}
function getConfigMultiForm3() {
	return {
		headers: {
			accept: 'application/json',
			Authorization:
				'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MDM4MzIyODcsImlhdCI6MTY0MDc1OTk4Nywic3ViIjoic3BhcmVzX2FwcCJ9.evbj-LJChHTL4uO24sFZX8iMbLMzAu4w2PnbI6aI4yA',
			'Content-Type': 'multipart/form-data',
		},
	};
}

function getConfigWithParams1(token, params) {
	return {
		headers: {
			Authorization:
				'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6MjAwLCJnc3RfbnVtYmVyIjpudWxsLCJ0b3RhbF9vcmRlcl9hbW91bnQiOjAsInVzZXJfdHlwZSI6ImFnZW50Iiwid2FyZWhvdXNlIjoiZ21fREVMIn0.zgXPXx4zngKfGajHYZ9Y5XklRbv7UQAdIYNH551r5Ws',
		},
		params: params,
	};
}

class API {
	combinationDiscount(category, brand, discount) {
		return axios.post(Constants.getSparesBaseURL() + 'combination-discounts/', {
			sub_category_name: category,
			sku_brand: brand,
			discount_percent: discount,
		});
	}
	downloadCustomersDiscount() {
		return axios.get(
			Constants.getSparesBaseURL() + 'download-customers-discounts/',
			getConfigMultiForm2()
		);
	}

	getDownloadTabCSV(fields, attributes, page) {
		let queryParams = '';
		for (let field of Object.entries(fields)) {
			if (field[1]) queryParams = `${queryParams}&${field[0]}=${field[1]}`;
		}
		if (queryParams !== '') queryParams = '?' + queryParams.substr(1);

		for (let field of Object.entries(attributes)) {
			if (field[1]) queryParams = `${queryParams}&${field[0]}=${field[1]}`;
		}

		window.open(
			`${Constants.getSparesBaseURL()}cataloguing-team-api/${queryParams}&page=${page}`
		);
	}

	getDownloadTabPageCount(fields, attributes) {
		let queryParams = '';
		for (let field of Object.entries(fields)) {
			if (field[1]) queryParams = `${queryParams}&${field[0]}=${field[1]}`;
		}
		if (queryParams !== '') queryParams = '?' + queryParams.substr(1);

		for (let field of Object.entries(attributes)) {
			if (field[1]) queryParams = `${queryParams}&${field[0]}=${field[1]}`;
		}
		return axios.get(
			`${Constants.getSparesBaseURL()}pages-count/${queryParams}`
			// { params: attributes }
		);
	}

	getCMSBrands() {
		return axios.get(`${Constants.getSparesBaseURL()}brands-cms-v2/`);
	}

	syncSparesBanners() {
		return axios
			.post(`${Constants.getSparesBaseURL()}sync-banners/`)
	}
	syncSparesCars() {
		return axios
			.post(`${Constants.getSparesBaseURL()}cars-firebase-sync/`)
			.then((response) => {
				if (response.status === 200) alert('Cars Synced');
				else alert('Unable To Sync');
			});
	}
	syncSparesSegment() {
		return axios
			.post(`${Constants.getSparesBaseURL()}segment-firebase-sync`)
			.then((response) => {
				if (response.status === 201) alert('Segment Synced');
				else alert('Unable To Sync');
			});
	}

	insurancePolicyUpload(url, name) {
		return axios.post('https://comms.gomechanic.app/api/policy', {
			url: url,
			name: name,
		});
	}

	// insurancePolicyUpload(file){
	//     const formData = new FormData();
	//     formData.append('file',file)
	//     return axios.post('https://comms.gomechanic.app/api/policy', formData, {timeout : 90000}, getConfigMultiForm1());
	// }

	addAccessoriesProdcut(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(baseurl + 'products/', data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				'content-type': 'application/json',
			},
		});
	}
	syncSparesBanners() {
		return axios
			.post(`${Constants.getSparesBaseURL()}sync-banners/`)
	}
	updateAccessoriesVariantImages(sku_code, files, master_image, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		const formData = new FormData();
		let img_count = 0;

		formData.append('sku_code', sku_code);
		if (master_image) {
			formData.append('master_image', master_image);
			img_count++;
		} else {
			img_count = files.length;
			for (var i = 0; i < files.length; i++) {
				formData.append('image_' + (i + 1), files[i]);
			}
		}
		formData.append('img_count', img_count);

		return axios.post(
			baseurl + 'variant-images/',
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}

	deleteAccessoriesVariantImage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.delete(baseurl + 'variant-images/', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data: {
				sku_code: data.sku_code,
				image_url: data.image_url,
			},
		});
	}
	imageProcessing(file) {
		// console.log("try",files)
		const formData = new FormData();
		formData.append('overlay_image', true);
		formData.append('desired_height', 480);
		formData.append('desired_width', 720);
		// for (var i = 0; i < files.length; i++) {
		formData.append('image_path', file);
		// }
		return axios.post(
			Constants.getSparesBaseURL() + 'v1/api/imageprocessing/',
			formData,
			{ responseType: 'arraybuffer', ...getConfigMultiForm3() }
		);
	}

	updateSparesProduct(tag, data, files) {
		return axios.put(
			Constants.getSparesBaseURL() + 'part-details/',
			data,
			getConfigMultiForm2()
		);
	}

	updateDiscountDefault(data) {
		return axios.put(
			Constants.getSparesBaseURL() + 'default-discounts/',
			data,
			getConfigMultiForm2()
		);
	}

	addNewBrand(data) {
		return axios.post(
			Constants.getSparesBaseURL() + 'brands/',
			data,
			getConfigMultiForm2()
		);
	}

	deleteDiscountBrand(obj) {
		return axios.delete(Constants.getSparesBaseURL() + 'brands/', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data: obj,
		});
	}

	async getDefaultDiscounts() {
		const response = await axios.get(
			Constants.getSparesBaseURL() + 'default-discounts/',
			getConfig(localStorage.getItem('access_token'))
		);
		return response;
	}
	//files===processedImage
	updateSparesProductImage(tag, data, updatedfiles) {
		console.log('length',typeof updatedfiles)
		const formData = new FormData();
		formData.append('product_id', data.product_id);
		// formData.append('img_count', updatedfiles.length);
		formData.append('img_count', 1);

		formData.append('id', tag);
		// for (var i = 0; i < updatedfiles.length; i++) {
		// 	formData.append('image_' + (i + 1), updatedfiles[i]);
		// }
		formData.append('image_1', updatedfiles);
		formData.delete('type');
		formData.delete('image_url');
		formData.delete('id');

		return axios.put(
			Constants.getSparesBaseURL() + 'parts-images/?source=cms',
			formData,
			getConfigMultiForm1()
		);
	}

	updatePaymentMethod(data) {
		return axios.post(
			Constants.getSparesBaseURL() + 'payment-methods/',
			JSON.stringify(data),
			getConfigMultiForm2()
		);
	}
	updatePrivateLevelCashback = (cashback) => {
		let payload = {
			sku_code: cashback.sku_code,
			limit: Number(cashback.limit) >= 0 ? Number(cashback.limit) : 0,
			min_cashback:
				Number(cashback.min_cashback) >= 0 ? Number(cashback.min_cashback) : 0,
			max_cashback:
				Number(cashback.max_cashback) >= 0 ? Number(cashback.max_cashback) : 0,
		};
		return axios.post(
			Constants.getSparesBaseURL() + 'generate-qrcode/',
			payload,
			getConfigMultiForm2()
		);
	};

	downloadPrivateLevelCashbackExcel = (cashback) => {
		let payload = {
			sku_code: cashback.sku_code,
			limit: Number(cashback.limit) >= 0 ? Number(cashback.limit) : 0,
			min_cashback: Number(cashback.min_cashback) >= 0 ? Number(cashback.min_cashback) : 0,
			max_cashback: Number(cashback.max_cashback) >= 0 ? Number(cashback.max_cashback) : 0,
		};
		return axios.post(
			Constants.getSparesBaseURL() + 'generate-qr-excel/',
			payload,
			getConfigMultiForm2()
		);
	};

	updateTechSpecs = (data) => {
		const formData = new FormData();
		formData.append('product_id', data.product_id);
		formData.append('batch_id', data.batch_id);
		formData.append('pdf', data.pdf);
		return axios.post(
			Constants.getSparesBaseURL() + `tech-spec/`,
			formData,
			getConfigMultiForm2()
		);
	}

	getTechSpecsListing(productId) {
		if (productId) {
			return axios.get(Constants.getSparesBaseURL() + `tech-spec/?product_id=${productId}&source=CMS`);
		}
	}

	updateDiscount(customerId, data, categoryDiscounts, brandSubCategoryDiscount) {
		// delete data.selectedBrand;
		// delete data.discountValue;
		let payload = {
			customer_id: customerId,
			discounts: data,
			user_sub_category_discounts: categoryDiscounts,
		};

		if (
			brandSubCategoryDiscount.brand &&
			brandSubCategoryDiscount.subCategory &&
			!isNaN(Number(brandSubCategoryDiscount.discount))
		) {
			payload = {
				...payload,
				sku_brand: brandSubCategoryDiscount.brand,
				sub_category_name: brandSubCategoryDiscount.subCategory,
				brand_category_discount: Number(brandSubCategoryDiscount.discount),
			};
		}
		return axios.put(
			Constants.getSparesBaseURL() + 'user-discounts/',
			payload,
			getConfigMultiForm2()
		);
	}

	getBrandSubCategoryDiscount(sku_brand, category_name, customer_id) {
		return axios.get(
			`${Constants.getSparesBaseURL()}user-brand-category-discount/?source=cms&sku_brand=${encodeURIComponent(
				sku_brand
			)}
			&sub_category_name=${encodeURIComponent(category_name)}&
			customer_id=${customer_id}`
		);
	}

	getOTPForDiscount(mobile) {
		return axios.post(
			Constants.getSparesBaseURL() + 'send-otp/',
			{ mobile: mobile },
			getConfigMultiForm2()
		);
	}

	verifyOTPForDiscount(mobile, otp) {
		return axios.post(
			Constants.getSparesBaseURL() + 'cms-otp-verification/',
			{ mobile: mobile, otp: otp },
			getConfigMultiForm2()
		);
	}

	updatePricing(data) {
		return axios.post(
			Constants.getSparesBaseURL() + 'stockone-parts/',
			JSON.stringify(data),
			getConfigMultiForm2()
		);
	}

	downloadPricingExcel() {
		return axios.get(
			Constants.getSparesBaseURL() + 'download-excel/',
			getConfigMultiForm2()
		);
	}

	updateVechileDetails(data) {
		return axios.put(
			Constants.getSparesBaseURL() + 'vehicles-cms/',
			JSON.stringify(data),
			getConfigMultiForm2()
		);
	}
	updateCategories(data) {
		return axios.put(
			Constants.getSparesBaseURL() + 'categories/',
			JSON.stringify(data),
			getConfigMultiForm2()
		);
	}

	deleteCategory(obj) {
		return axios.delete(Constants.getSparesBaseURL() + 'categories/', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data: obj,
		});
	}

	deleteVechile(obj) {
		return axios.delete(Constants.getSparesBaseURL() + 'vehicles-cms/', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data: obj,
		});
	}

	addNewVechile(data) {
		return axios.post(
			Constants.getSparesBaseURL() + 'vehicles-cms/',
			JSON.stringify(data),
			getConfigMultiForm2()
		);
	}
	addNewCategories(data) {
		return axios.post(
			Constants.getSparesBaseURL() + 'categories/',
			JSON.stringify(data),
			getConfigMultiForm2()
		);
	}

	updateSparesProductCompatibility(tag, productId, updatedCParams) {	
		var newArray = updatedCParams.filter(
			(value) => Object.keys(value).length !== 0
		);
		for (let i of newArray) {
			// if (Object.keys(i).length == 0) delete i;
			delete i.end_year;
			delete i.year;
			// delete i.year_range;
			delete i._id;
			if (i.fuelType) delete i.fuelType;
			if (i.modelsForBrand) delete i.modelsForBrand;
			if (i.selectedBrandForModel) delete i.selectedBrandForModel;
			if (i.selectedFuelType) delete i.selectedFuelType;
			if (i.selectedModel) delete i.selectedModel;
			if (i.selectedVarientType) delete i.selectedVarientType;
			if (i.varientType) delete i.varientType;
		}
		// delete updatedCParams.brandWiseModels;
		// delete updatedCParams.brands;
		// delete updatedCParams.fuelType;
		// delete updatedCParams.selectedModel
		// delete updatedCParams.modelsForBrand
		// delete updatedCParams.selectedBrandForModel;
		// delete updatedCParams.selectedFuelType;

		var obj = { sku_code: tag, product_id: productId };
		// var compatibility_params = updatedCParams.concat(deletedCParams);
		// obj["compatibility_params"] = compatibility_params;
		obj['compatibility_params'] = newArray;

		// // console.log(formData);
		// // console.log(formData+"");

		return axios.post(
			Constants.getSparesBaseURL() + 'compatibility-params/',
			obj,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteSparesProductCompatibility(tag, productId, deletedCParams) {
		var obj = { sku_code: tag, product_id: productId };
		// var compatibility_params = updatedCParams.concat(deletedCParams);
		// obj["compatibility_params"] = compatibility_params;
		obj['compatibility_ids'] = deletedCParams;

		return axios.delete(
			Constants.getSparesBaseURL() + 'compatibility-params/',
			{
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
				data: obj,
			}
		);

		// return axios.delete(Constants.getSparesBaseURL() + 'compatibility-params/', getConfig(localStorage.getItem("access_token")), obj);
	}

	deleteSparesImage(tag, data) {
		let obj = {
			product_id: data.product_id,
			image_urls: [data.image_urls],
		};
		return axios.delete(Constants.getSparesBaseURL() + 'parts-images/', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data: obj,
		});
	}

	updateAccessories(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		delete data['sku_code'];
		return axios.put(
			baseurl + 'products/',
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	bulkUploadProducts(file, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		const formData = new FormData();
		formData.append('file', file);
		return axios.post(
			baseurl + 'product-bulk-upload',
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}

	bulkUploadSparesProducts(file) {
		let user_id = localStorage.getItem('user_id');
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			Constants.getSparesBaseURL() + 'parts-bulk-upload/?user_id=' + user_id,
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}

	bulkUploadCategorization(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			Constants.getSparesBaseURL() + 'subcategorization-upload/',
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}

	sparesBannerUpload(obj, new_images) {
		const formData = new FormData();
		formData.append('name', obj.name);
		formData.append('type', obj.type);
		formData.append('product_id', obj.product_id);
		formData.append('brand_id', obj.brand_id);
		formData.append('brand_name', obj.brand_name);
		formData.append('sub_category_name', obj.sub_category_name);
		formData.append('sub_category_id', obj.sub_category_id);
		formData.append('sub_category_display_id', obj.sub_category_display_id);
		formData.append('source', obj.source);
		formData.append('order', obj.order);
		formData.append('banner_size', obj.banner_size);
		formData.append('is_visible', obj.is_visible ? 1 : 0);
		formData.append('checkout_page', obj.checkout_page ? 1 : 0);
		formData.append('is_clearance', obj.is_clearance ? 1 : 0);
		formData.append('additional_discount', obj.additional_discount);
		formData.append('banner_location', obj.banner_location);
		for (var i = 0; i < new_images.length; i++) {
			formData.append('image', new_images[i]);
		}
		return axios.post(
			Constants.getSparesBaseURL() + 'caraousels/',
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}

	sparesBannerEdit(obj, new_images) {
		const formData = new FormData();
		formData.append('_id', obj._id);
		formData.append('name', obj.name);
		formData.append('type', obj.type);
		formData.append('product_id', obj.product_id);
		formData.append('brand_name', obj.brand_name);
		formData.append('brand_id', obj.brand_id);
		formData.append('sub_category_name', obj.sub_category_name);
		formData.append('sub_category_id', obj.sub_category_id);
		formData.append('sub_category_display_id', obj.sub_category_display_id); 
		formData.append('banner_size', obj.banner_size);
		formData.append('source', obj.source);
		formData.append('order', obj.order);
		formData.append('is_visible', obj.is_visible ? 1 : 0);
		formData.append('checkout_page', obj.checkout_page ? 1 : 0);
		formData.append('is_clearance', obj.is_clearance ? 1 : 0);
		formData.append('additional_discount', obj.additional_discount);
		formData.append('banner_location', obj.banner_location);
		if (new_images !== undefined) {
			for (var i = 0; i < new_images.length; i++) {
				formData.append('image', new_images[i]);
			}
		}
		return axios.put(
			Constants.getSparesBaseURL() + 'caraousels/',
			formData,
			getConfigMultiForm1()
		);
	}

	deleteSparesBanner(id) {
		return axios.delete(Constants.getSparesBaseURL() + 'caraousels/', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data: {
				_id: id,
			},
		});
	}
	deleteCoupon(code) {
		return axios.delete(
			Constants.getSparesBaseURL() + 'api/v1/oauth/orders/create-update-coupon',
			{
				data: {
					code: code,
				},
			}
		);
	}
	// axios
	// 				.get(
	// 					Constants.getSparesBaseURL() + 'user-discounts/',
	// 					getConfigWithParams1(localStorage.getItem('access_token'), {
	// 						customer_search: text,
	// 						source: 'cms',
	// 					})
	// 				)
	// 				.then(function (response) {
	// 					if (response.status === 204 || response.data.data.length == 0) {
	// 						alert('No Content');
	// 						// return <Toast />
	// 						// return (<Toast level='success'
	// 						//     message='No content'
	// 						//     visible='true' />)
	// 					} else that.setState({ showMiddleContent: false, middleContent: null, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: response.data.data, showLoader: false });
	// 					// this.setState({selectedRow : response.data.data.id, showRightContent : true, rightContent : response.data.data});

	// 					// that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
	// 				})
	// 				.catch(function (error) {
	// 					// handle error
	// 					// console.log(error)
	// 					NotificationManager.error('Invalid User', 'Error', 2000);
	// 					that.setState({ showLoader: false });
	// 				})
	// 				.finally(function () {
	// 					// always executed
	// 				});
	getRetailer(text){
		return axios.get(Constants.getSparesBaseURL()+'user-discounts/',	
		getConfigWithParams1(localStorage.getItem('access_token'), {
							customer_search: text,
							source: 'cms',
						})
					)
	}
	getCustomersList(text){
		return axios.get(Constants.getSparesBaseURL() + 'customer-tab/',
		getConfigWithParams1(localStorage.getItem('access_token'), {
			customer_search: text,
		}))
	}
	getAgent(){
		return axios.get(Constants.getSparesBaseURL()+'agent-tab/')
	}
	deleteAgent(customer_id) {
		return axios.delete(Constants.getSparesBaseURL() + `agent-tab/?customer_id=${customer_id}`,
		);
	}
	addAgent(data) {
		delete data.customer_id
		return axios.post(
			Constants.getSparesBaseURL() + 'agent-tab/',
			data
		);
	}
	updateAgent(data) {
		return axios.put(Constants.getSparesBaseURL() + 'agent-tab/',data);
	}
	addCoupon(data) {
		return axios.post(
			Constants.getSparesBaseURL() + 'api/v1/oauth/orders/create-update-coupon',
			data
		);
	}
	updateCoupon(data) {
		return axios.put(
			Constants.getSparesBaseURL() + 'api/v1/oauth/orders/create-update-coupon',
			data
		);
	}

	sparesPaymentToggel(obj) {
		return axios.put(
			Constants.getSparesBaseURL() + 'payment-options/',
			obj,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	bulkUploadSparesProductsPricing(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			Constants.getSparesBaseURL() + 'parts-bulk-upload/?source=pricing_module',
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}
	bulkUploadSparesDelivery(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			Constants.getSparesBaseURL() + 'pincode-bulk-upload/?source=cms',
			formData
		);
	}
	getSampleDeliveryExcel() {
		return axios.get(Constants.getSparesBaseURL() + 'pincode-bulk-upload/');
	}
	updateDeliveryPincode(obj) {
		return axios
			.put(Constants.getSparesBaseURL() + 'pincode-details/', obj)
			.then((response) => {
				if (response.status === 201)
					NotificationManager.success('Pincode data updated', 'success', 3000);
			})
			.catch((error) => {
				alert(error);
			});
	}

	uploadSparesProductsCompatibility(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			Constants.getSparesBaseURL() + 'compatibility-bulk-upload/',
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}

	updateContentWebsite(tag, row, data) {
		// console.log('req', tag, row, data)
		if (tag === 'near_me_acc') {
			// console.log(data._id)
			return axios.patch(
				Constants.getAccessoriesBaseURL() + 'nearme-pages/',
				{
					near_me_id: data._id,
					banner: data.banner,
					popular_category_ids:
						data.popular_category && data.popular_category.length
							? data.popular_category.map((item) => item._id)
							: [],
					customer_testimonials: data.customer_testimonials,
					faq: data.faq,
					heading: data.heading,
					product_heading: data.product_heading,
					main_content: data.main_content,
					page_title: data.page_title,
					store_data: data.store_data,
					sub_banner: data.sub_banner,
					url_slug: data.url_slug,
					video: data.video,
					brand_ids: data.selectedBrands
						? data.selectedBrands.map((brand) => brand._id)
						: [],
					//brand_ids: data.selectedCategories ? data.selectedCategories.map((brand) => brand._id) : [],
					pricing_product_ids: data.selectedProductList
						? data.selectedProductList.map((item) => item.product_id)
						: [],
					metadata: data.metadata,
					popular_category_images: data.popular_category_images,
				},
				getConfig(localStorage.getItem('access_token'))
			);
		} else {
			return axios.post(
				Constants.getNearMeURL() + 'api/v1/oauth/web_content/update_data',
				{
					data: data,
					table: tag,
				},
				getConfig(localStorage.getItem('access_token'))
			);
		}
	}

	addContentWebsite(tag, data) {
		// console.log('req', tag, data)
		if (tag === 'near_me_acc') {
			// console.log(data._id)
			return axios.post(
				Constants.getAccessoriesBaseURL() + 'nearme-pages/',
				{
					banner: data.banner,
					popular_category_ids:
						data.popular_category && data.popular_category.length
							? data.popular_category.map((item) => item._id)
							: [],
					customer_testimonials: data.customer_testimonials,
					faq: data.faq,
					heading: data.heading,
					product_heading: data.product_heading,
					main_content: data.main_content,
					page_title: data.page_title,
					store_data: data.store_data,
					sub_banner: data.sub_banner,
					url_slug: data.url_slug,
					video: data.video,
					brand_ids: data.selectedBrands
						? data.selectedBrands.map((brand) => brand._id)
						: [],
					metadata: data.metadata,
					popular_category_images: data.popular_category_images,
				},
				getConfig(localStorage.getItem('access_token'))
			);
		} else {
			return axios.post(
				Constants.getNearMeURL() + 'api/v1/oauth/web_content/save_data',
				{
					data: data,
					table: tag,
				},
				getConfig(localStorage.getItem('access_token'))
			);
		}
	}

	deleteContentWebsite(tag, data) {
		// console.log('req', tag, data)
		if (tag === 'near_me_acc') {
			// console.log(data._id)
			return axios.delete(Constants.getAccessoriesBaseURL() + 'nearme-pages/', {
				headers: {
					Authorization: localStorage.getItem('access_token'),
				},
				data: {
					near_me_id: data._id,
				},
			});
		} else {
			return axios.post(
				Constants.getNearMeURL() + 'api/v1/oauth/web_content/delete_data',
				{
					data: data,
					table: tag,
				},
				getConfig(localStorage.getItem('access_token'))
			);
		}
	}

	tabClickCallSpares(tag, that, source) {
		switch (tag) {
			case 'x':
				axios
					.get(
						Constants.getAccessoriesBaseURL() + 'products',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						// console.log('responsex ', response.data)
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						// for (var i = 0; i < response.data.data.length; i++) {
						// //     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
						//     response.data.data[i].id = response.data.data[i].page_id;
						// //     if(response.data.data[i].delivery_date)
						// //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						// //     if(response.data.data[i].estimated_delivery)
						// //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						//   }

						that.setState({
							showMiddleContent: true,
							middleContent: { data: response.data.data.product_data },
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'delivery':
				axios
					.get(Constants.getSparesBaseURL() + 'pincodes/')
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data,
							middleTag: tag,
							showLoader: false,
						});
					});
				axios
					.get(Constants.getSparesBaseURL() + 'pincode-summary/')
					.then(function (response) {
						that.setState({
							pincodeSummary: response.data.data,
						});
					});

				break;
			case 'coupons':
				axios
					.get(Constants.getSparesBaseURL() + 'cms-coupons/')
					.then(function (response) {
						console.log('coupon', response);
						that.setState({
							showMiddleContent: true,
							middleContent: response.data,
							middleTag: tag,
							showLoader: false,
						});
					});

				break;
				case 'checkout':
						axios.get(`${Constants.getSparesBaseURL()}brands-cms-v2/`)
						.then(function (response) {
							that.setState({
								middleContent: response.data.data,
								middleTag: tag,
								showLoader: false,
							});
						});
					break;
				case 'agents':
					  axios.get(Constants.getSparesBaseURL()+'agent-tab/')
					  .then(function (response) {
						  that.setState({
							  showMiddleContent: true,
							  middleContent: response.data.data,
							  middleTag: tag,
							  showLoader: false,
						  });
					  });
					break;
			case 'pricing':
				// axios.get(Constants.getSparesBaseURL() + 'parts', getConfig(localStorage.getItem("access_token")))
				axios
					.get(Constants.getSparesBaseURL() + 'stockone-parts/?pagenum=1')
					.then(function (response) {
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						for (var i = 0; i < response.data.data.length; i++) {
							//     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
							response.data.data[i].id = response.data.data[i].page_id;
							//     if(response.data.data[i].delivery_date)
							//         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
							//     if(response.data.data[i].estimated_delivery)
							//         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						}

						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;

			case 'categories_spares':
				if (source && source.subCategory === 'segment') {
					axios
						.get(
							`${Constants.getSparesBaseURL()}segments/`,
							getConfig(localStorage.getItem('access_token'))
						)
						.then(function (response) {
							that.setState({
								showMiddleContent: true,
								masterContent: response.data,
								middleTag: tag,
								showLoader: false,
								masterTabCategory: {
									category: 'categories_spares',
									subCategory: 'segment',
								},
							});
						})
						.catch(function (error) {
							// handle error
							console.log(error);
						})
						.finally(function () {
							// always executed
						});
				} else if (source && source.subCategory === 'subCategory') {
					axios
						.get(
							`${Constants.getSparesBaseURL()}sub-categories/`,
							getConfig(localStorage.getItem('access_token'))
						)
						.then(function (response) {
							that.setState({
								showMiddleContent: true,
								masterContent: response.data,
								middleTag: tag,
								showLoader: false,
								masterTabCategory: {
									category: 'categories_spares',
									subCategory: 'subCategory',
								},
							});
						})
						.catch(function (error) {
							// handle error
							console.log(error);
						})
						.finally(function () {
							// always executed
						});
				} else {
					axios
						.get(`${Constants.getSparesBaseURL()}categories/`)
						.then(function (response) {
							const category = [];
							response.data.data.forEach((item) =>
								category.push({ slug: item.slug })
							);
							that.setState({
								showMiddleContent: true,
								masterContent: response.data,
								middleContent: response.data,
								middleTag: tag,
								showLoader: false,
								category: category,
								masterTabCategory: {
									category: 'categories_spares',
									subCategory: 'category',
								},
							});
						})
						.catch(function (error) {
							// handle error
							console.log(error);
						})
						.finally(function () {
							// always executed
						});
				}
				break;

			case 'brands_spares':
				axios
					.get(`${Constants.getSparesBaseURL()}brands-cms-v2/`)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							masterTabCategory: {
								category: 'brands_spares',
								subCategory: null,
							},
							masterContent: response.data,
							middleContent: response.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'main_banner':
				const currentSource = source === 'app' ? 'app' : 'web';
				axios
					.get(
						Constants.getSparesBaseURL() + `v5/caraousels/?source=${currentSource}`
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data.main_banner,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						that.setState({...that.state,
							showLoader: false,
						});
					})
					.finally(function () {
						// always executed
					});
				break;

			case 'trending_banner':
			const trendingSource = source === 'app' ? 'app' : 'web';
			axios
				.get(
					Constants.getSparesBaseURL() + `v5/caraousels/?source=${trendingSource}`
				)
				.then(function (response) {
					that.setState({
						showMiddleContent: true,
						middleContent:response.data.data.trending_banner,
						middleTag: tag,
						showLoader: false,
					});
				})
				.catch(function (error) {
					// handle error
					// console.log(error)
				})
				.finally(function () {
					// always executed
				});
			break;

			case 'tyre_banner':
			const tyreSource = source === 'app' ? 'app' : 'web';
			axios
				.get(
					Constants.getSparesBaseURL() + `v5/caraousels/?source=${tyreSource}`
				)
				.then(function (response) {
					that.setState({
						showMiddleContent: true,
						middleContent: response.data.data.tyre_banner,
						middleTag: tag,
						showLoader: false,
					});
				})
				.catch(function (error) {
					// handle error
					// console.log(error)
				})
				.finally(function () {
					// always executed
				});
			break;
			case 'master': {
				if (_.get(source, ['category'], '') === 'categories_spares') {
					this.tabClickCallSpares(
						_.get(source, ['category'], ''),
						that,
						source
					);
				} else {
					this.tabClickCallSpares('brands_spares', that, source);
				}
				break;
			}
			case 'spares_payment':
				// that.setState({ showLoader: false });
				axios
					.get(Constants.getSparesBaseURL() + `payment-options/`)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;

			default:
				that.setState({ showLoader: false });
				break;
		}
	}

		tabClickCallContent(tag, that, source,show) {
		switch (tag) {
		  case "x":
			axios
			  .get(
				Constants.getAccessoriesBaseURL() + "products",
				getConfig(localStorage.getItem("access_token"))
			  )
			  .then(function (response) {
	
				that.setState({
				  showMiddleContent: false,
				  middleContent: { data: response.data.data.product_data },
				  middleTag: tag,
				});
			  })
			  .catch(function (error) {
				// handle error
				// console.log(error)
			  })
			  .finally(function () {
				// always executed
			  });
			break;
		  case "delivery":
			axios
			  .get(Constants.getSparesBaseURL() + "pincodes/")
			  .then(function (response) {
				that.setState({
				  showMiddleContent: true,
				  middleContent: response.data,
				  middleTag: tag,
				  showLoader: false,
				});
			  });
			axios
			  .get(Constants.getSparesBaseURL() + "pincode-summary/")
			  .then(function (response) {
				that.setState({
				  pincodeSummary: response.data.data,
				});
			  });
	
			break;
			// case "product-faq":
			// 	 axios.get(`${Constants.getSparesBaseURL()}faq/`)
			// 	 .then(function (response) {
			// 	   that.setState({
			// 		 showMiddleContent: true,
			// 		 masterContent: response.data,
			// 		 middleTag: tag,
			// 		 showLoader: false,
			// 	   });
			// 	 });

			// 	break
		  case "pricing":
			// axios.get(Constants.getSparesBaseURL() + 'parts', getConfig(localStorage.getItem("access_token")))
			axios
			  .get(Constants.getSparesBaseURL() + "stockone-parts/?pagenum=1")
			  .then(function (response) {
				//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
				for (var i = 0; i < response.data.data.length; i++) {
				  //     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
				  response.data.data[i].id = response.data.data[i].page_id;
				  //     if(response.data.data[i].delivery_date)
				  //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				  //     if(response.data.data[i].estimated_delivery)
				  //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				}
	
				that.setState({
				  showMiddleContent: true,
				  middleContent: response.data.data,
				  middleTag: tag,
				  showLoader: false,
				});
			  })
			  .catch(function (error) {
				// handle error
				// console.log(error)
			  })
			  .finally(function () {
				// always executed
			  });
			break;
		//   case "categories_spares":
		// 	if (source && source.subCategory === "segment") {
		// 	  axios
		// 		.get(
		// 		  `${Constants.getSparesBaseURL()}segments/`,
		// 		  getConfig(localStorage.getItem("access_token"))
		// 		)
		// 		.then(function (response) {
		// 		  that.setState({
		// 			showMiddleContent: true,
		// 			masterContent: response.data,
		// 			middleTag: tag,
		// 			showLoader: false,
		// 			masterTabCategory: {
		// 			  category: "categories_spares",
		// 			  subCategory: "segment",
		// 			},
		// 		  });
		// 		})
		// 		.catch(function (error) {
		// 		  // handle error
		// 		  console.log(error);
		// 		})
		// 		.finally(function () {
		// 		  // always executed
		// 		});
		// 	} else if (source && source.subCategory === "subCategory") {
		// 	  axios
		// 		.get(
		// 		  `${Constants.getSparesBaseURL()}sub-categories/`,
		// 		  getConfig(localStorage.getItem("access_token"))
		// 		)
		// 		.then(function (response) {
		// 		  that.setState({
		// 			showMiddleContent: true,
		// 			masterContent: response.data,
		// 			middleTag: tag,
		// 			showLoader: false,
		// 			masterTabCategory: {
		// 			  category: "categories_spares",
		// 			  subCategory: "subCategory",
		// 			},
		// 		  });
		// 		})
		// 		.catch(function (error) {
		// 		  // handle error
		// 		  console.log(error);
		// 		})
		// 		.finally(function () {
		// 		  // always executed
		// 		});
		// 	} else {
		// 	  axios
		// 		.get(`${Constants.getSparesBaseURL()}categories/`)
		// 		.then(function (response) {
		// 		  const category = [];
		// 		  response.data.data.forEach((item) =>
		// 			category.push({ slug: item.slug })
		// 		  );
		// 		  that.setState({
		// 			showMiddleContent: true,
		// 			masterContent: response.data,
		// 			middleContent: response.data,
		// 			middleTag: tag,
		// 			showLoader: false,
		// 			category: category,
		// 			masterTabCategory: {
		// 			  category: "categories_spares",
		// 			  subCategory: "category",
		// 			},
		// 		  });
		// 		})
		// 		.catch(function (error) {
		// 		  // handle error
		// 		  console.log(error);
		// 		})
		// 		.finally(function () {
		// 		  // always executed
		// 		});
		// 	}
		// 	break;
			case "content_vehicle":
			axios
			  .get(`${Constants.getSparesBaseURL()}brands-cms-v2/`)
			  .then(function (response) {
				that.setState({
				  showMiddleContent: false,
				  masterTabCategory: {
					category: "content_vehicle",
					subCategory: null,
				  },
				  masterContent: response.data,
				  middleContent: response.data,
				  middleTag: tag,
				  showLoader: false,
				});
			  })
			  .catch(function (error) {
				// handle error
				// console.log(error)
			  })
			  .finally(function () {
				// always executed
			  });
			break;
		//   case "content_home":
		// 	const currentSource = source ? source : "web";
		// 	axios
		// 	  .get(
		// 		Constants.getSparesBaseURL() + `caraousels/?source=${currentSource}`
		// 	  )
		// 	  .then(function (response) {
		// 		that.setState({
		// 		  showMiddleContent: false,
		// 		  middleContent: response.data,
		// 		  middleTag: tag,
		// 		  showLoader: false,
		// 		});
		// 	  })
		// 	  .catch(function (error) {
		// 		// handle error
		// 		// console.log(error)
		// 	  })
		// 	  .finally(function () {
		// 		// always executed
		// 	  });
		// 	break;
			// case "content_brandSegment":
			// axios
			//   .get(`${Constants.getSparesBaseURL()}brands-cms-v2/`)
			//   .then(function (response) {
			// 	that.setState({
			// 	  showMiddleContent: false,
			// 	  masterTabCategory: {
			// 		category: "content_vehicle",
			// 		subCategory: null,
			// 	  },
			// 	  masterContent: response.data,
			// 	  middleContent: response.data,
			// 	  middleTag: tag,
			// 	  showLoader: false,
			// 	});
			//   })
			//   .catch(function (error) {
			// 	// handle error
			// 	// console.log(error)
			//   })
			//   .finally(function () {
			// 	// always executed
			//   });
			// break;
			
			// case "content_brandSubCategory":
			// axios
			//   .get(`${Constants.getSparesBaseURL()}brands-cms-v2/`)
			//   .then(function (response) {
			// 	that.setState({
			// 	  showMiddleContent: false,
			// 	  masterTabCategory: {
			// 		category: "content_brandVehicle",
			// 		subCategory: null,
			// 	  },
			// 	  masterContent: response.data,
			// 	  middleContent: response.data,
			// 	  middleTag: tag,
			// 	  showLoader: false,
			// 	});
			//   })
			//   .catch(function (error) {
			// 	// handle error
			// 	// console.log(error)
			//   })
			//   .finally(function () {
			// 	// always executed
			//   });
			// break;
		//   case "content_segment": {
		// 	if (_.get(source, ["category"], "") === "categories_spares") {
		// 		console.log('coming')
		// 	  this.tabClickCallSpares(
		// 		_.get(source, ["category"], ""),
		// 		that,
		// 		source
		// 	  );
		// 	} else {
		// 	  this.tabClickCallSpares("brands_spares", that, source);
		// 	}
		// 	break;
		//   }
		  case "spares_payment":
			axios
			  .get(Constants.getSparesBaseURL() + `payment-options/`)
			  .then(function (response) {
				that.setState({
				  showMiddleContent: false	,
				  middleContent: response.data,
				  middleTag: tag,
				  showLoader: false,
				});
			  })
			  .catch(function (error) {
				// handle error
				// console.log(error)
			  })
			  .finally(function () {
				// always executed
			  });
			break;
	
		  default:
			that.setState({ showLoader: false });
			break;
		}
	  }
	  getSubHeadingContent(name,type) {
			let typeCheck =type.toLowerCase()
		return axios.get(`${Constants.getSparesBaseURL()}sub-heading/?name=${encodeURIComponent(name)}&type=${encodeURIComponent(typeCheck)}`);
	  }
	  addSubHeadingContent(obj) {
		return axios.post(`${Constants.getSparesBaseURL()}sub-heading/`,obj);
			  }
	  updateSubHeadingContent(obj) {
	return axios.put(`${Constants.getSparesBaseURL()}sub-heading/`,obj);
  		}
	getHomepageHeadings(){
		return axios.get(`${Constants.getSparesBaseURL()}homepage/`);
	  }
	addHomepageHeadings(obj){
		return axios.post(`${Constants.getSparesBaseURL()}homepage/`, obj);
	  }
	  getContent() {
		return axios.get(`${Constants.getSparesBaseURL()}page-details/`);
	  }
	  addContent(obj) {
		return axios.post(`${Constants.getSparesBaseURL()}page-details/`, obj);
	  }
	  updateContent(obj){
		return axios.put(`${Constants.getSparesBaseURL()}page-details/`, obj);
	  }
	  deleteContent(obj){
		return axios.delete(`${Constants.getSparesBaseURL()}page-details/`, {data:obj});
	
	  }
	tabClickCallAccessories(tag, that, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		let apiEndPoint = '',
			errMsg = '';
		switch (tag) {
			case 'homepage':
				apiEndPoint =
				baseurl + 'v2/new-home-page-data-configure/';
				errMsg = 'Error in getting home page data!';
				break;

			case 'segments':
				apiEndPoint = baseurl + 'segments/';
				errMsg = 'Error in getting segments data!';
				break;

			case 'categories':
				apiEndPoint = baseurl + 'categories/';
				errMsg = 'Error in getting categories data!';
				break;

			case 'products':
				apiEndPoint = baseurl + 'products/';
				errMsg = 'Error in getting products data!';
				break;

			case 'brands':
				apiEndPoint = baseurl + 'brands/';
				errMsg = 'Error in getting brands data!';
				break;

			case 'collection':
				apiEndPoint = `${baseurl}products-collections-all/`;
				errMsg = 'Error in getting collections data!';
				break;

			case 'coupons':
				apiEndPoint = baseurl + 'coupon-cms/';
				errMsg = 'Error in getting coupon data!';
				break;

			case 'installationservice':
				apiEndPoint =
				baseurl + 'installation-service/';
				errMsg = 'Error in getting installation service data!';
				break;

			case 'rails':
				apiEndPoint =
				baseurl + 'rails/?product_limit=max';
				errMsg = 'Error in getting rails data!';
				break;

			case 'feed':
				apiEndPoint = `${baseurl}control-feed/`;
				errMsg = 'Error in getting feed data!';
				break;

			case 'enquiry':
				apiEndPoint = `${baseurl}enquiry-pages/`;
				errMsg = 'Error in getting enquiry page data!';
				break;
			case 'carPage':
				apiEndPoint = `${baseurl}page-data-carbrand/`;
				errMsg = 'Error in getting car page data!';
				break;
			case 'reviews':
				apiEndPoint = `${baseurl}review-cms/`;
				errMsg = 'Error in getting reviews data!';
				break;
			case 'facility':
				apiEndPoint = `${baseurl}facility/`;
				errMsg = 'Error in getting facilities data!';
				break;
			case 'collectionv2':
				apiEndPoint = `${baseurl}products-collections-v2/?all=1`;
				errMsg = 'Error in getting new collections data!';
				break;
			case 'user':
				apiEndPoint = `${baseurl}user-cms-crud/`;
				errMsg = 'Error in getting users data!';
				break;
			case 'export':
				apiEndPoint = `${baseurl}export-pages/`;
				errMsg = 'Error in getting export page data!';
				break;
			default:
				that.setState({
					showLoader: false,
				});
				break;
		}
		if (apiEndPoint && errMsg) {
			axios
				.get(apiEndPoint, getConfig(localStorage.getItem('access_token')))
				.then(function (response) {
					let extraData;
					if (tag === 'segment') {
						for (var i = 0; i < response.data.data.length; i++) {
							response.data.data[i].id = response.data.data[i].page_id;
						}
					}
					if (tag === 'products') {
						response.data.data = response.data.data.product_data;
					}
					if (tag === 'feed') {
						extraData = response.data.data.applicable_on;
						response.data.data = response.data.data.data;
					}
					if (tag === 'homepage' || tag === 'enquiry' || tag === 'export') {
						response.data.data = [response.data.data];
					}
					that.setState({
						showMiddleContent: true,
						middleContent: response.data.data,
						middleTag: tag,
						showLoader: false,
						extraData,
					});
				})
				.catch(function (error) {
					NotificationManager.error(
						error.response && error.response.data
							? error.response.data.message
							: errMsg,
						'Error!!',
						5000
					);
				})
				.finally(function () {
					// always executed
				});
		}
	}

	tabClickCallContentWebsite(tag, that) {
		// console.log('tabbing')
		switch (tag) {
			case 'page_path_mapping':
				axios
					.get(
						Constants.getNearMeURL() +
							'api/v1/oauth/web_content/get_page_path_mapping',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						for (var i = 0; i < response.data.data.length; i++) {
							//     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
							response.data.data[i].id = response.data.data[i].page_id;
							//     if(response.data.data[i].delivery_date)
							//         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
							//     if(response.data.data[i].estimated_delivery)
							//         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						}

						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'faq_near_me':
				// console.log('tabbing_faq')
				axios
					.get(
						Constants.getNearMeURL() +
							'api/v1/oauth/web_content/get_nearme_faq',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						// for (var i = 0; i < response.data.data.length; i++) {
						// //     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
						//     response.data.data[i].id = response.data.data[i].page_id;
						// //     if(response.data.data[i].delivery_date)
						// //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						// //     if(response.data.data[i].estimated_delivery)
						// //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						//   }

						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'price_list':
				// console.log('tabbing_faq')
				axios
					.get(
						Constants.getNearMeURL() +
							'api/v1/oauth/web_content/get_nearme_price_list',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						// console.log(response.data)
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						// for (var i = 0; i < response.data.data.length; i++) {
						// //     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
						//     response.data.data[i].id = response.data.data[i].page_id;
						// //     if(response.data.data[i].delivery_date)
						// //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						// //     if(response.data.data[i].estimated_delivery)
						// //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						//   }

						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'price_comparison':
				// console.log('tabbing_faq')
				axios
					.get(
						Constants.getNearMeURL() +
							'api/v1/oauth/web_content/get_nearme_price_comparison',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						// for (var i = 0; i < response.data.data.length; i++) {
						// //     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
						//     response.data.data[i].id = response.data.data[i].page_id;
						// //     if(response.data.data[i].delivery_date)
						// //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						// //     if(response.data.data[i].estimated_delivery)
						// //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						//   }

						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'testimonials':
				// console.log('tabbing_faq')
				axios
					.get(
						Constants.getNearMeURL() +
							'api/v1/oauth/web_content/get_nearme_testimonial',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						// console.log(response.data)
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						// for (var i = 0; i < response.data.data.length; i++) {
						// //     // // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
						//     response.data.data[i].id = response.data.data[i].page_id;
						// //     if(response.data.data[i].delivery_date)
						// //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						// //     if(response.data.data[i].estimated_delivery)
						// //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
						//   }

						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'garages':
				axios
					.get(
						Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'near_me_acc':
				axios
					.get(
						Constants.getAccessoriesBaseURL() + 'all-nearme-pages/',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						// handle error
						console.log(error);
					})
					.finally(function () {
						// always executed
					});
				break;
			default:
				break;
		}
	}

	tabClickCall(tag, that) {
		switch (tag) {
			case 'orders':
				axios
					.get(
						Constants.getBaseURL() + 'api/v1/oauth/orders/get_orders',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						for (var i = 0; i < response.data.data.length; i++) {
							// // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
							if (response.data.data[i].pick_date)
								response.data.data[i].pick_date = new Date(
									response.data.data[i].pick_date['$date']
								).toLocaleDateString('en-UK', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								});
							if (response.data.data[i].delivery_date)
								response.data.data[i].delivery_date = new Date(
									response.data.data[i].delivery_date['$date']
								).toLocaleDateString('en-UK', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								});
							if (response.data.data[i].estimated_delivery)
								response.data.data[i].estimated_delivery = new Date(
									response.data.data[i].estimated_delivery['$date']
								).toLocaleDateString('en-UK', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								});
						}
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'fleets':
				axios
					.get(
						Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'garages':
				axios
					.get(
						Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			default:
				break;
		}
	}

	tabSearchCall(tag, that, args) {
		switch (tag) {
			case 'orders':
				//delete args['fleets'];delete args['garages'];
				axios
					.post(
						Constants.getBaseURL() + 'api/v1/oauth/orders/search_orders',
						args,
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
						for (var i = 0; i < response.data.data.length; i++) {
							if (response.data.data[i].pick_date)
								response.data.data[i].pick_date = new Date(
									response.data.data[i].pick_date['$date']
								).toLocaleDateString('en-UK', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								});
							if (response.data.data[i].delivery_date)
								response.data.data[i].delivery_date = new Date(
									response.data.data[i].delivery_date['$date']
								).toLocaleDateString('en-UK', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								});
							if (response.data.data[i].estimated_delivery)
								response.data.data[i].estimated_delivery = new Date(
									response.data.data[i].estimated_delivery['$date']
								).toLocaleDateString('en-UK', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								});
						}
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'fleets':
				axios
					.get(
						Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'garages':
				axios
					.get(
						Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages',
						getConfig(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
						});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			default:
				break;
		}
	}

	tabSearch1Call(tag, that, text, text1, tabCategory) {
		// console.log('tag ', tag)
		switch (tag) {
			case 'products':
			case 'pricing':
				//delete args['fleets'];delete args['garages'];
				axios
					.get(
						Constants.getSparesBaseURL() + 'part-details/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							product_id: text,
							source: 'cms',
						})
					)
					.then(function (response) {
						if (response.status === 204) {
							alert('No Content');
							// return <Toast />
							// return (<Toast level='success'
							//     message='No content'
							//     visible='true' />)
						}
						that.setState({
							showMiddleContent: false,
							middleContent: response.data.data,
							middleTag: tag,
							selectedRow: response.data.data._id,
							showRightContent: true,
							rightContent: response.data.data,
							showLoader: false,
						});
						// this.setState({selectedRow : response.data.data.id, showRightContent : true, rightContent : response.data.data});

						// that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'delivery':
				axios
					.get(
						Constants.getSparesBaseURL() + 'pincode-details/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							pincode: text,
						})
					)
					.then(function (response) {
						if (response.status === 204) alert('No Data');
						else {
							that.setState({
								middleTag: tag,
								selectedRow: response.data.data.pincode,
								showRightContent: true,
								rightContent: response.data.data,
								showLoader: false,
							});
						}
					});
				break;
			case 'discount':
				axios
					.get(
						Constants.getSparesBaseURL() + 'user-discounts/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							customer_search: text,
							source: 'cms',
						})
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							alert('No Content');
						} else that.setState({ showMiddleContent: true, middleContent: response.data.data, middleTag: tag, selectedRow: response.data.data.customer_id,showLoader: false });
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error('Invalid User', 'Error', 2000);
						that.setState({ showLoader: false });
					})
					.finally(function () {
						// always executed
					});
				break;
				case 'customers':
					that.setState({
						customer_loader:true
					});
				axios
					.get(
						Constants.getSparesBaseURL() + 'customer-tab/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							customer_search: text,
						})
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							// alert('No Content');
						} else that.setState({showRightContent: true, showMiddleContent: true, middleContent: response.data.data, middleTag: tag, selectedRow: response.data.data.customer_id,showLoader: false,customer_loader:false });
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error(error.message?error.message:'Invalid User', 'Error', 2000);
						that.setState({ showLoader: false,customer_loader:false });
					})
					.finally(function () {
						// always executed
					});
				break;
				case 'wallet':
					axios
						.get(
							Constants.getSparesBaseURL() + 'customer-tab/',
							getConfigWithParams1(localStorage.getItem('access_token'), {
								customer_search: text,
							})
						)
						.then(function (response) {
							if (response.status === 204 || response.data.data.length == 0) {
								// alert('No Content');
							} else that.setState({showRightContent: true, showMiddleContent: true, middleContent: response.data.data, middleTag: tag, selectedRow: response.data.data.customer_id,showLoader: false });
						})
						.catch(function (error) {
							// handle error
							// console.log(error)
							NotificationManager.error('Invalid User', 'Error', 2000);
							that.setState({ showLoader: false });
						})
						.finally(function () {
							// always executed
						});
					break;
			case 'categories_spares':
				//delete args['fleets'];delete args['garages'];
				axios
					.get(
						Constants.getSparesBaseURL() + 'categories',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							segment_id: text,
						})
					)
					.then(function (response) {
						if (response.status === 204) {
							alert('No Content');
							// return <Toast />
							// return (<Toast level='success'
							//     message='No content'
							//     visible='true' />)
						}
						that.setState({
							showMiddleContent: false,
							middleContent: response.data.data,
							middleTag: tag,
							// selectedRow: response.data.data._id,
							showRightContent: true,
							rightContent: response.data.data,
							showLoader: false,
						});
						// this.setState({selectedRow : response.data.data.id, showRightContent : true, rightContent : response.data.data});

						// that.setState({showMiddleContent : true, middleContent: response.data.data, middleTag: tag});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
					})
					.finally(function () {
						// always executed
					});
				break;
			// } else {
			//     axios.get(Constants.getSparesBaseURL() + 'user-discounts', getConfigWithParams1(localStorage.getItem("access_token"), { mobile: text }))
			//         .then(function (response) {
			//             // console.log("response in discount", response, response.data);
			//             if (response.status === 204) {
			//                 alert("No Content");
			//                 // return <Toast />
			//                 // return (<Toast level='success'
			//                 //     message='No content'
			//                 //     visible='true' />)
			//             }
			//             that.setState({ showMiddleContent: false, middleContent: null, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: response.data.data });
			//             // this.setState({selectedRow : response.data.data.id, showRightContent : true, rightContent : response.data.data});

			case 'master':
				this.tabSearch1Call(tabCategory, that, text, text1);
				break;
			case 'vehicles':
				axios
					.get(
						`${Constants.getSparesBaseURL()}vehicles-cms/?make=${text}&source=cms`,
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							alert('No Content');
						} else
							that.setState({
								// showMiddleContent: false, middleContent: response.data, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: null, showLoader: false

								showMiddleContent: true,
								middleContent: response.data,
								masterContent: response.data,
								middleTag: tag,
								showLoader: false,
							});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error('Please Enter Valid ID', 'Error', 2000);
						that.setState({ showLoader: false });
					})
					.finally(function () {
						// always executed
					});
				break;

			case 'epc':
				axios
					.get(
						Constants.getSparesBaseURL() + 'vehicles-cms/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							make: text,
							model: text1,
							source: 'cms',
						})
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							alert('No Content');
						} else
							that.setState({
								// showMiddleContent: false, middleContent: response.data, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: null, showLoader: false

								showMiddleContent: true,
								middleContent: response.data,
								middleTag: tag,
								showLoader: false,
							});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error('Please Enter Valid ID', 'Error', 2000);
						that.setState({ showLoader: false });
					})
					.finally(function () {
						// always executed
					});
				break;

			case 'epcc':
				axios
					.get(
						Constants.getSparesBaseURL() + 'vehicles-cms/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							make: text,
							model: text1,
						})
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							alert('No Content');
						} else
							that.setState({
								// showMiddleContent: false, middleContent: response.data, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: null, showLoader: false

								showMiddleContent: true,
								middleContent: response.data,
								middleTag: tag,
								showLoader: false,
							});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error('Please Enter Valid ID', 'Error', 2000);
						that.setState({ showLoader: false });
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'download':
				axios
					.get(
						Constants.getSparesBaseURL() + 'vehicles-cms/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							make: text,
							model: text1,
						})
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							alert('No Content');
						} else
							that.setState({
								// showMiddleContent: false, middleContent: response.data, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: null, showLoader: false

								showMiddleContent: true,
								middleContent: response.data,
								middleTag: tag,
								showLoader: false,
							});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error('Please Enter Valid ID', 'Error', 2000);
						that.setState({ showLoader: false });
					})
					.finally(function () {
						// always executed
					});
				break;

			case 'upload':
				axios
					.get(
						Constants.getSparesBaseURL() + 'vehicles-cms/',
						getConfigWithParams1(localStorage.getItem('access_token'), {
							make: text,
							model: text1,
						})
					)
					.then(function (response) {
						if (response.status === 204 || response.data.data.length == 0) {
							alert('No Content');
						} else
							that.setState({
								// showMiddleContent: false, middleContent: response.data, middleTag: tag, selectedRow: response.data.data.id, showRightContent: true, rightContent: null, showLoader: false

								showMiddleContent: true,
								middleContent: response.data,
								middleTag: tag,
								showLoader: false,
							});
					})
					.catch(function (error) {
						// handle error
						// console.log(error)
						NotificationManager.error('Please Enter Valid ID', 'Error', 2000);
						that.setState({ showLoader: false });
					})
					.finally(function () {
						// always executed
					});
					break
					case 'agents':
						if(typeof text==='string'){
							that.setState({
								agent_loader:true
							});
							axios.get(Constants.getSparesBaseURL() + 'agent-tab/',
							getConfigWithParams1(localStorage.getItem('access_token'), {
								customer_search: text,
							})
						)
						.then(function (response) {
							if (response.status === 204 || response.data.data.length == 0) {
								that.setState({
									showLoader: false,
									agent_loader:false
								});
								NotificationManager.error('Agent not found!', 'Error', 2000);
							} else
							that.setState({
								showMiddleContent: true,
								middleContent: response.data.data,
								middleTag: tag,
								showLoader: false,
								agent_loader:false

							});
						})
						.catch(function (error) {
							NotificationManager.error(`${error.message? error.message:`Something went wrong, Please try again later`}`, 'Error', 5000)
							that.setState({ showLoader: false,agent_loader:false });

							}).finally(function () {
							// always executed
						});
						}
						else{
							axios.get(Constants.getSparesBaseURL() + 'agent-tab/',
							getConfigWithParams1(localStorage.getItem('access_token'), {
								mobile: text,
							})
						)
						.then(function (response) {
							if (response.status === 204 || response.data.data.length == 0) {
								// alert('No Content');
							} else
							that.setState({
								showMiddleContent: true,
								middleContent: response.data.data,
								middleTag: tag,
								showLoader: false,
							});
						})
						.catch(function (error) {
							// handle error
							// console.log(error)
							NotificationManager.error('Please Enter Valid Mobile Number', 'Error', 2000);
							that.setState({ showLoader: false });
						})
						.finally(function () {
							// always executed
						});

						}
			
				break;

			default:
				break;
		}
	}

	tabSearch2Call(tag, that, text, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		switch (tag) {
			case 'searchbysku':
				axios
					.get(
						baseurl + 'variant-details/',
						getConfigWithParams(localStorage.getItem('access_token'), {
							sku_code: text,
						})
					)
					.then(function (response) {
						var xdata = response.data.data;
						that.setState({
							showMiddleContent: false,
							middleContent: null,
							middleTag: tag,
							selectedRow: xdata.sku_code,
							showRightContent: true,
							rightContent: xdata,
						});
					})
					.catch(function (error) {
						// handle error
						NotificationManager.error(
							error.response && error.response.data
								? error.response.data.message
								: 'No product found.',
							'Error!!',
							5000
						);
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'products':
				axios
					.get(
						baseurl + 'products/' + text + '/',
						getConfigWithParams(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						var xdata = response.data.data;
						that.setState({
							showMiddleContent: false,
							middleContent: null,
							middleTag: tag,
							selectedRow: xdata.sku_code,
							showRightContent: true,
							rightContent: xdata,
						});
					})
					.catch(function (error) {
						// handle error
						console.log(error);
					})
					.finally(function () {
						// always executed
					});
				break;
			case 'coupons':
				axios
					.get(
						baseurl + 'search-cms-coupon/?code=' + text,
						getConfigWithParams(localStorage.getItem('access_token'))
					)
					.then(function (response) {
						that.setState({
							showMiddleContent: true,
							middleContent: response.data.data,
							middleTag: tag,
							showLoader: false,
						});
					})
					.catch(function (error) {
						NotificationManager.error(
							error.response && error.response.data
								? error.response.data.message
								: "No Coupon found.",
							'Error!!',
							5000
						);
					})
					.finally(function () {
						// always executed
					});
				break;
			default:
				break;
		}
	}

	getStatuses(config1 = null) {
		if (config1 != null) {
			return axios.get(
				Constants.getBaseURL() + 'api/v1/oauth/orders/get_order_statuses/',
				config1
			);
		} else {
			return axios.get(
				Constants.getBaseURL() + 'api/v1/oauth/orders/get_order_statuses/',
				getConfig(localStorage.getItem('access_token'))
			);
		}
	}
	get_fleets_summary1(config1 = null) {
		if (config1 != null) {
			return axios.get(
				Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets_summary',
				config1
			);
		} else {
			return axios.get(
				Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets_summary',
				getConfig(localStorage.getItem('access_token'))
			);
		}
	}
	get_garages_summary1(config1 = null) {
		if (config1 != null) {
			return axios.get(
				Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages_summary',
				config1
			);
		} else {
			return axios.get(
				Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages_summary',
				getConfig(localStorage.getItem('access_token'))
			);
		}
	}

	sendOTP(phone) {
		return axios.post(Constants.getCommsBaseURL() + 'api/send_otp', {
			number: phone,
			source: 'admindash',
		});
	}
	verifyLogin(phone, otp) {
		return axios.post(Constants.getCommsBaseURL() + 'api/verify_login_web', {
			number: phone,
			otp: otp,
			session_key: sessionStorage.getItem('session_key'),
			source: 'admindash',
		});
	}
	logout(phone, otp) {
		// console.log('access_token')
		// console.log(getConfig(localStorage.getItem('access_token')))
		return axios.post(
			Constants.getBaseURL() + 'api/logout',
			{},
			getConfig(localStorage.getItem('access_token'))
		);
	}

	get_fleets_summary() {
		axios
			.get(
				Constants.getBaseURL() + 'api/v1/oauth/fleets/get_fleets_summary',
				getConfig(localStorage.getItem('access_token'))
			)
			.then(function (response) {
				// console.log('fleeting')
				Constants.setFleetsSummary(response.data.data);
			})
			.catch(function (error) {
				// handle error
				// console.log(error)
			})
			.finally(function () {
				// always executed
			});
	}

	get_garages_summary() {
		axios
			.get(
				Constants.getBaseURL() + 'api/v1/oauth/garages/get_garages_summary',
				getConfig(localStorage.getItem('access_token'))
			)
			.then(function (response) {
				Constants.setGaragesSummary(response.data.data);
			})
			.catch(function (error) {
				// handle error
				// console.log(error)
			})
			.finally(function () {
				// always executed
			});
	}

	getChatUserDetails(tag, that) {
		that.setState({
			showLoader: true,
			showRightContent1: false,
			showRightContent2: false,
			rightContent1: NaN,
			rightContent2: NaN,
		});
		axios
			.get(
				Constants.getBaseURL() + 'api/v1/oauth/profile/get_user_profile_chat',
				getConfigWithParams(localStorage.getItem('access_token'), {
					user_id: tag,
				})
			)
			.then(function (response) {
				//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
				// for (var i = 0; i < response.data.data.length; i++) {
				//     // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
				//     if(response.data.data[i].pick_date)
				//         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				//     if(response.data.data[i].delivery_date)
				//         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				//     if(response.data.data[i].estimated_delivery)
				//         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				//   }
				if (response.data.status)
					that.setState({
						showRightContent1: true,
						rightContent1: response.data.data,
						showLoader: false,
					});
				else
					that.setState({
						showRightContent1: false,
						rightContent1: 'no_user',
						showLoader: false,
					});
			})
			.catch(function (error) {
				// handle error
				// console.log(error)
			})
			.finally(function () {
				// always executed
			});
	}

	getOrderDetailsPaginate(tag, data, that) {
		if (data.length != 0) {
			that.setState({ showLoader: true });
		}
		axios
			.get(
				Constants.getBaseURL() + 'api/v1/oauth/order/order_details_paginate',
				getConfigWithParams(localStorage.getItem('access_token'), {
					user_id: tag,
					limit: 5,
					offset: data.length,
				})
			)
			.then(function (response) {
				//that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
				// for (var i = 0; i < response.data.data.length; i++) {
				//     // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
				//     if(response.data.data[i].pick_date)
				//         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				//     if(response.data.data[i].delivery_date)
				//         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				//     if(response.data.data[i].estimated_delivery)
				//         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
				//   }
				if (response.data.status) {
					if (data.length == 0) {
						that.setState({
							showRightContent2: true,
							rightContent2: response.data.data,
							showLoader: false,
						});
					} else {
						if (response.data.data.length == 0)
							that.setState({ showLoader: false });
						else
							that.setState({
								showRightContent2: true,
								rightContent2: data.concat(response.data.data),
								showLoader: false,
							});
					}
				} else {
					that.setState({ showLoader: false });
				}
			})
			.catch(function (error) {
				// handle error
				// console.log(error)
			})
			.finally(function () {
				// always executed
			});
	}

	getCitiesListForBroadCast(that) {
		axios
			.get(
				'https://demo.gomechanic.app/' + 'api/v1/oauth/broadcast/clusters/',
				getConfigWithParams(localStorage.getItem('access_token'))
			)
			.then((response) => {
				that.setState({ options: response.data.data });
				return response;
			});
	}

	// getSupportChatArchives(supportchat, offset, assigned_executive, that){
	//     if(offset != 0){
	//         that.setState({showLeftLoader : true});
	//     }
	//     var params = {};
	//     if(assigned_executive){
	//         params = {assigned_executive: localStorage.getItem("user_id"), limit : 15, offset : offset}
	//     }else{
	//         params = {limit : 15, offset : offset}
	//     }
	//     axios.get(Constants.getBaseURL()+'api/v1/oauth/support/get_support_chat_archives', getConfigWithParams(localStorage.getItem("access_token"), params))
	//                 .then(function (response) {
	//                 // console.log(response.data);
	//                 //that.setState({showMiddleContent : true, middleContent: response.data, middleTag: tag});
	//                 // for (var i = 0; i < response.data.data.length; i++) {
	//                 //     // console.log(response.data.data[i].pick_date['$date'], response.data.data[i].estimated_delivery['$date']);
	//                 //     if(response.data.data[i].pick_date)
	//                 //         response.data.data[i].pick_date = (new Date(response.data.data[i].pick_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
	//                 //     if(response.data.data[i].delivery_date)
	//                 //         response.data.data[i].delivery_date = (new Date(response.data.data[i].delivery_date['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
	//                 //     if(response.data.data[i].estimated_delivery)
	//                 //         response.data.data[i].estimated_delivery = (new Date(response.data.data[i].estimated_delivery['$date'])).toLocaleDateString("en-UK", { year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" });
	//                 //   }
	//                 that.setState({showLoader : false, showLeftLoader: false});
	//                 if (response.data.status){
	//                     if(offset == 0){
	//                         ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
	//                     }else{
	//                         if(response.data.data.length == 0)
	//                             // that.setState({showLoader : false});
	//                             ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
	//                         else
	//                             // that.setState({showRightContent2 : true, rightContent2: data.concat(response.data.data), showLoader : false});
	//                             ChatUtilsArchive.loadInitial(supportchat, response.data.data, false);
	//                     }
	//                 }else{
	//                     // that.setState({showLoader : false});
	//                 }
	//                 })
	//                 .catch(function (error) {
	//                 // handle error
	//                 // console.log(error);
	//                 })
	//                 .finally(function () {
	//                 // always executed
	//                 });
	// }

	getAllProducts(make, model, varient, engine, segmentId, skuCategory) {
		return axios.get(
			`${Constants.getSparesBaseURL()}parts/?warehouse=gm_DEL&make=${make}&model=${model}&varient=${varient}&fuel=${engine}&segment_id=${segmentId}&sku_category=${skuCategory}`
		);
	}

	getEPCData(make, model, varient, engine, segmentId, family, year_range) {
		return axios.get(
			`${Constants.getSparesBaseURL()}epc/?make=${make}&model=${encodeURIComponent(model)}&varient=${encodeURIComponent(varient)}&fuel_engine=${engine}&segment_id=${segmentId}&family=${
				(
				family
			)}&year_range=${year_range}`
		);
	}

	getEPCImageURL(file) {
		const formData = new FormData();
		formData.append('image', file);
		return axios.post(
			`${Constants.getSparesBaseURL()}get-image-url/?source=epc`,
			formData
		);
	}
	
	getGlobalCustomerWallet(){
		return axios.get(`${Constants.getSparesBaseURL()}customer-tab/`);
	}

	globalCustomerWallet(file){
			let axiosConfig = localStorage.getItem('user_id');
			const formData = new FormData();
			formData.append('excel_file', file);
			formData.append('user_id', axiosConfig);
		return axios.post(`${Constants.getSparesBaseURL()}customer-tab/`,formData );
	}
	
	customerTab(customerId,data){
		return axios.put(`${Constants.getSparesBaseURL()}customer-tab/?customer_search=${customerId}`,data );
	}
	customerWalletTab(customerId,data){
		let axiosConfig = localStorage.getItem('user_id');
		return axios.post(`${Constants.getSparesBaseURL()}customer-tab/?customer_search=${customerId}`, { ...data, user_id: axiosConfig });
	}
	paymentModeDefault(){
		return axios.get(`${Constants.getSparesBaseURL()}payment-methods/?order_amount=201&pincode=`);
	}

	deleteCheckoutPaymentMode(sku_brand,sub_category_id){
		let data={brand:sku_brand,
			sub_category_id:sub_category_id 
		}
		return axios.delete(`${Constants.getSparesBaseURL()}custom-delivery-charge/`, {data});
	}

	deletePaymentMode(phone_number){
		let data={phone_number:phone_number}
		return axios.delete(`${Constants.getSparesBaseURL()}customer-tab/`, {data});
	}
	getEPCTags(img_url) {
		let headers = {
			accept: 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
		};
		let data_string = img_url;
		return axios({
			url: `${Constants.getSparesBaseURL()}gm-ocr/`,
			method: 'post',
			headers: headers,
			// This will urlencode the data correctly:
			data: new URLSearchParams({
				img_url: data_string,
			}),
		});

		// return axios.post(
		// 	'http://34.77.61.11/v1/gm_ocr',
		// 	{ img_url },
		// 	{
		// 		headers: {
		// 			'Content-Type': 'application/x-www-form-urlencoded',
		// 		},
		// 	}
		// );
	}
	
	customDeliveryCharge(data) {
		return axios.put(`${Constants.getSparesBaseURL()}custom-delivery-charge/`, data);
	}

	getCustomDeliveryData(sku_name,sub_category_id) {
		sub_category_id=sub_category_id.length>1?sub_category_id.join(',').replace(/,/g, '|').split():sub_category_id;
		return axios.get(`${Constants.getSparesBaseURL()}custom-delivery-charge/?brand=${encodeURIComponent(sku_name=='select all'?null:sku_name)}&sub_category_id=${sub_category_id}`);
	}

	uploadEPCData(payload) {
		return axios.post(`${Constants.getSparesBaseURL()}epc/`, payload);
	}

	uploadEPCCSV(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(`${Constants.getSparesBaseURL()}epc_bulk/`, formData);
	}
	uploadAdditionalDiscount(data) {
		return axios.post(`${Constants.getSparesBaseURL()}inventory-banner-discount/?additional_discount=${data}`);
	}
	
	uploadCustomerBulkDiscount(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(`${Constants.getSparesBaseURL()}customer-bulk-discount/`, formData);
	}
	uploadBrandBulkDiscount(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(`${Constants.getSparesBaseURL()}brand-discount-bulk-upload/`, formData);
	}
	uploadSubCategoryBulkDiscount(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(`${Constants.getSparesBaseURL()}subcategory-discount-bulk-upload/`, formData);
	}
	uploadRetailersBulkDiscount(file,customer_id) {
		const formData = new FormData();
		formData.append('excel_file', file);
		formData.append('customer_id', customer_id);

		return axios.post(`${Constants.getSparesBaseURL()}agent-bulk/`, formData);
	}
	uploadBrandSubCategoryBulkDiscount(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(`${Constants.getSparesBaseURL()}sub-brand-discount-upload/`, formData);
	}
	uploadBulkDiscount(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(`${Constants.getSparesBaseURL()}discount-bulk-upload/`, formData);
	}

	getCollectionProducts(slug, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}collections-products/?collection_slug=${slug}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccFacilityList() {
		const baseurl = Constants.getAccessoriesB2BBaseURL();
		return axios.get(
			`${baseurl}facility/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	editAccFacilityList(data) {
		const baseurl = Constants.getAccessoriesB2BBaseURL();
		return axios.put(
			`${baseurl}facility/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccFacilityList(data) {
		const baseurl = Constants.getAccessoriesB2BBaseURL();
		return axios.post(
			`${baseurl}facility/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	editAccessoriesCollection(data,isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}products-collections/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	searchAccessoriesProducts(query, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}product-search-crm/?query=${query}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addOrEditCollectionBanner(formData) {
		return axios.put(
			`${Constants.getAccessoriesBaseURL()}collection-images/`,
			formData,
			getConfigMultiForm(localStorage.getItem('access_token'))
		);
	}
	getProductIdSuggestions(term) {
		return axios.get(
			Constants.getSparesBaseURL() + 'search/?source=cms',
			getConfigWithParams1(localStorage.getItem('access_token'), {
				user_text: term,
				pagenum: 1,
			})
		);
	}

	getLineItemForRateControl(term) {
		return axios.get(
			Constants.getBaseURL() +
				`api/v1/oauth/web_content/cms_get_part_name?line_item=${term}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccessoriesCollection(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}products-collections/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccessoriesProduct(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}products/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAllAccessoriesCategoryList(mergeSegments = 0, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}all-categories/?merge_segments=${mergeSegments}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAllAccessoriesBrandList(isLogo, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}all-brands/${
				isLogo ? `?is_logo_available=${isLogo}` : ''
			}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAllTagsList(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}all-tags/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAllCarBrandsModelsList(allData, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}acc-car-data/?all_data=${allData}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getProductAttributes(slug, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}attributes/?name=${slug}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccessoriesSegment(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}segments/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccessoriesSegment(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}categories/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccessoriesCategory(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}categories/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccessoriesHomePage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}v2/new-home-page-data-configure/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccessoriesEnquiryPage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.patch(
			`${baseurl}enquiry-pages/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	uploadAccessoriesImage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}crm-image-upload/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	uploadAccessoriesProductFromFile(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}csv-product-search-crm/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	searchOrFilterAccessoriesCategory(type, key, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		if (type === 'search')
			return axios.get(
				`${baseurl}categories/?query=${key}`,
				getConfig(localStorage.getItem('access_token'))
			);
		else
			return axios.get(
				`${baseurl}categories/?segment_slug=${key}`,
				getConfig(localStorage.getItem('access_token'))
			);
	}

	getAllAccessoriesSegmentsList(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}all-segments/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	syncAccessoriesFiltersData(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}sync-filters/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	syncAccessoriesSeoData(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}sync-seo/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	syncAccessoriesRedisData(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}sync-redis/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	configFirebaseAccessoriesData(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}configure-firebase-data/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccessoriesProductRelated(productId, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}related-products-cms/?product_id=${productId}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addEditAccessoriesProductRelated(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}related-products-cms/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	uploadAccessoriesProductBulk(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}bulk-upload-new/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	uploadAccessoriesVariantBulk(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}bulk-add-variants/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	uploadAccessoriesPincodeBulk(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}pincode-bulk-upload/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getCollectionList(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}products-collections-all/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccessoriesCoupon(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}coupon-cms/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	editAccessoriesCoupon(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}coupon-cms/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteAccessoriesCoupon(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.delete(`${baseurl}coupon-cms/`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data,
		});
	}

	addAccessoriesRails(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}rails/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteAccessoriesRails(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.delete(`${baseurl}rails/`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data,
		});
	}
	updateAccessoriesRails(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.patch(
			`${baseurl}rails/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	bulkEditAccessoriesProduct(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}bulk-upload-new/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccessoriesCouponProducts(productIds, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}multiple-coupon-products/?product_ids=${productIds}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccessoriesCategoryProducts(categorySlug, segmentSlug, isAccB2b, brandSlug) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}products/${categorySlug ? `?category_slug=${categorySlug}` : ''}${segmentSlug ? `?segment_slug=${segmentSlug}` : ''}${brandSlug ? `?brand_slug=${brandSlug}` : ''}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccessoriesPincodes(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}accessories-pincode/?city_name=${data}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccessoriesStates(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}accessories-pincode/?get_states=1`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccessoriesCities(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}accessories-pincode/?state=${data}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	createAccProductInstallationService(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}installation-service/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccProductInstallationService(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}installation-service/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteAccProductInstallationService(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.delete(
			`${baseurl}installation-service/`,
			{
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
				data,
			}
		);
	}

	getAccProductCityInventory(skuCode, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}variants-retail-inventory/?sku_code=${skuCode}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccLatestProductId(isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.get(
			`${baseurl}latest-prodict-id/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	createAccProductCityInventory(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}variants-retail-inventory/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteAccProductCityInventory(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.delete(
			`${baseurl}variants-retail-inventory/`,
			{
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
				data,
			}
		);
	}

	getCustomFeedProducts(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}control-feed-products/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	approveNewCustomFeed(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}control-feed/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	approveEditedCustomFeed(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}control-feed/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteSparesCategory(data) {
		return axios.delete(`${Constants.getSparesBaseURL()}categories/`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			},
			data,
		});
	}

	getSparesCategoryList() {
		return axios.get(
			`${Constants.getSparesBaseURL()}categories/?source=product_tab`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getSparesBrandsList() {
		return axios.get(
			`${Constants.getSparesBaseURL()}brands/?source=product_tab`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getSparesSegments() {
		return axios.get(
			`${Constants.getSparesBaseURL()}segments/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}
	getCategories(segmentId) {
		return axios.get(
			`${Constants.getSparesBaseURL()}categories/?source=cms&${
				segmentId ? `segment_id=${segmentId}` : ''
			}`
		);
	}

	getSparesSubCategory() {
		return axios.get(
			`${Constants.getSparesBaseURL()}sub-categories/`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getSpecificationSampleCSV(segment, category, subCategory) {
		window.open(
			`${Constants.getSparesBaseURL()}specifications-bulk-upload/?segment_name=${encodeURIComponent(
				segment)}&sub_category_name=${encodeURIComponent(subCategory)}&category_name=${encodeURIComponent(category)}`
		);
	}

	getCategorizationSampleCSV() {
		window.open(`${Constants.getSparesBaseURL()}subcategorization-upload`);
	}
	partsEditSampleCSV() {
		window.open(`${Constants.getSparesBaseURL()}parts-bulk-upload/`);
	}
	getAlternativesSampleCSV() {
		window.open(`${Constants.getSparesBaseURL()}alternatives-mapping`);
	}
	deleteAlternativesSampleCSV() {
		window.open(`${Constants.getSparesBaseURL()}alternatives-delete/`);
	}
	uploadAlternativesCSV(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			`${Constants.getSparesBaseURL()}alternatives-mapping/`,
			formData,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteMappedAlternativesCSV(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			`${Constants.getSparesBaseURL()}alternatives-mapping-delete/`,
			formData,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	deleteAllAlternativesCSV(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			`${Constants.getSparesBaseURL()}alternatives-delete/`,
			formData,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	uploadSpecificationCSV(file) {
		const formData = new FormData();
		formData.append('excel_file', file);
		return axios.post(
			`${Constants.getSparesBaseURL()}specifications-bulk-upload/`,
			formData,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	searchSparesCategory(segmentId) {
		return axios.get(
			`${Constants.getSparesBaseURL()}categories/?segment_id=${segmentId}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addSparesCategory(data) {
		return axios.post(
			`${Constants.getSparesBaseURL()}categories/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateSparesSubCategory(data) {
		return axios.put(
			`${Constants.getSparesBaseURL()}sub-categories/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	searchSparesBrand(query) {
		return axios.get(
			`${Constants.getSparesBaseURL()}brands-cms-v2/?user_text=${query}`
		);
	}

	addSparesBrand(data) {
		return axios.post(`${Constants.getSparesBaseURL()}brands-cms-v2/`, data);
	}

	updateSparesBrand(data) {
		return axios.put(`${Constants.getSparesBaseURL()}brands-cms-v2/`, data);
	}

	updateSparesBrandDiscount(data) {
		return axios.put(`${Constants.getSparesBaseURL()}brands-discount-sync/`, data);
	}

	deleteSparesBrand(data) {
		return axios.delete(`${Constants.getSparesBaseURL()}brands-cms-v2/`, {
			// headers: {
			//   Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			// },
			data,
		});
	}

	syncSparesBrands() {
		return axios.post(`${Constants.getSparesBaseURL()}brands-firebase-sync/`);
	}

	syncSparesCategories() {
		return axios.post(`${Constants.getSparesBaseURL()}category-firebase-sync/`);
	}

	addDriveLink(folder_id, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}image-bulk-upload-from-drive/`,
			{ google_drive_folder_id: folder_id },
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccessoriesBrandPage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}new-brand-page/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccessoriesBrandPage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}new-brand-page/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getAccProductsBrandDetails(slug, that, type, tag, row, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		axios
			.get(
				`${baseurl}new-brand-page/`,
				getConfigWithParams(localStorage.getItem('access_token'), {
					brand_slug: slug,
				})
			)
			.then(function (response) {
				that.setState({
					middleExtraContent: {
						type: type,
						tag: tag,
						row: row,
						content: response.data.data,
					},
				});
			})
			.catch(function (error) {
				// handle error
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: 'No brand found.',
					'Error!!',
					5000
				);
			})
			.finally(function () {
				// always executed
			});
		// return axios.get(
		// 	`${baseurl}new-brand-page/?brand_slug=${slug}`,
		// 	getConfig(localStorage.getItem('access_token'))
		// );
	}

	updateAccessoriesExportPage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}export-pages/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	syncImagesApi(imgLink, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}image-upload/`,
			{ folder_name: imgLink },
			getConfig(localStorage.getItem('access_token'))
		);
	}

	getCarModelFromBrand(brandID, isAccB2b) {
		const baseurl = 'https://demo.gomechanic.app/'
		return axios.get(
			baseurl +
			`api/v2/oauth/vehicles/get_models_by_brand/?brand_id=${brandID}`,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccessoriesCarPage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}page-data-carbrand/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	updateAccessoriesCarPage(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}page-data-carbrand/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	AccessoriesFilterCall(tag, that, filterData, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		let apiEndPoint = '',
		errMsg = '';
		let queryparam = '';
		Object.keys(filterData).forEach((key, index) => {
			if (filterData[key]) {
				queryparam += `&${key}=${filterData[key]}`;
			}
		});
	switch (tag) {
		case 'reviews':
			apiEndPoint =	baseurl + `review-cms/?count="MAX"${queryparam}`;
			errMsg = 'Error in getting review data!';
			break;
		default:
			that.setState({
				showLoader: false,
			});
			break;
	}
	if (apiEndPoint && errMsg) {
		axios
			.get(apiEndPoint, getConfig(localStorage.getItem('access_token')))
			.then(function (response) {
				that.setState({
					showMiddleContent: true,
					middleContent: response.data.data,
					middleTag: tag,
					showLoader: false,
				});
			})
			.catch(function (error) {
				NotificationManager.error(
					error.response && error.response.data
						? error.response.data.message
						: errMsg,
					'Error!!',
					5000
				);
			})
			.finally(function () {
				// always executed
			});
	}
	}

	updateAccessoriesReview(data, isAccB2b) {
		const baseurl = isAccB2b ? Constants.getAccessoriesB2BBaseURL() : Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}review-cms/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}

	addAccCollectionV2(data) {
		const baseurl = Constants.getAccessoriesBaseURL();
		return axios.post(
			`${baseurl}products-collections-v2/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}
	editAccCollectionV2(data, slug) {
		const baseurl = Constants.getAccessoriesBaseURL();
		return axios.put(
			`${baseurl}products-collections-v2/?slug=${slug}`,
			data,
			getConfig(localStorage.getItem('access_token'))
		);
	}
	deleteB2BUser(data) {
		const baseurl = Constants.getAccessoriesB2BBaseURL();
		return axios.delete(
			`${baseurl}user-cms-crud/`,
			{
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
				data,
			}
		);
	}
	updateB2Buser(data) {
		const baseurl = Constants.getAccessoriesB2BBaseURL();
		return axios.put(
			`${baseurl}user-cms-crud/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		)
	}
	addB2Buser(data) {
		const baseurl = Constants.getAccessoriesB2BBaseURL();
		return axios.post(
			`${baseurl}user-cms-crud/`,
			data,
			getConfig(localStorage.getItem('access_token'))
		)
	}
}


export default new API();
