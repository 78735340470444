import React from 'react'

const VariantItem = (props) => {
  const {
    index,
    variant,
    carDependent,
    handleProductFieldChange,
    handleVariantChange,
    removeVariant,
    attributes,
  } = props

  return (
    <div className='variantItem extramainpanel'>
      <div className='border-box clearfix footer'>
        <div
          className='border-box floatleft leftalign blackspan2 shadow-text'
          style={{ padding: '0px 0px 0px 10px' }}
        >
          {`Variant ${index + 1}`}
        </div>
        <div className='border-box floatright rightalign'>
          <button
            className='btn btn_small btncolorprim btncard move btncloseextra'
            onClick={() => removeVariant(index)}
          >
            Remove
          </button>
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Name<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='Name'
            value={variant.name}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange('name', e, 'variant', null, null, index)
            }
          />
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            UPC Code<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='UPC Code'
            value={variant.upc_code}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange(
                'upc_code',
                e,
                'variant',
                null,
                null,
                index
              )
            }
          />
        </div>
      </div>
      {/* <div className="border-box clearfix fields">
        <div className="border-box clearfix field1">
          <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
            Feed Title
          </div>
          <input
            type="text"
            placeholder="Feed Title"
            value={variant.feed_title}
            className="field border-box small"
            onChange={(e) =>
              handleProductFieldChange('feed_title', e, 'variant', null, null, index)
            }
          />
        </div>
      </div> */}
      {carDependent ? (
        <>
          <div className='border-box floatleft leftalign blackspan2 shadow-text'>
            Car Details
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Model ID<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Model ID'
                value={variant.car ? variant.car.model_id : ''}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'model_id',
                    e,
                    'variant',
                    'object',
                    'car',
                    index
                  )
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Model Name<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Model Name'
                value={variant.car ? variant.car.model : ''}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'model',
                    e,
                    'variant',
                    'object',
                    'car',
                    index
                  )
                }
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Brand Name<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Brand Name'
                value={variant.car ? variant.car.brand : ''}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'brand',
                    e,
                    'variant',
                    'object',
                    'car',
                    index
                  )
                }
              />
            </div>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                Star Year<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='Star Year'
                value={variant.car ? variant.car.start_year : ''}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'start_year',
                    e,
                    'variant',
                    'object',
                    'car',
                    index
                  )
                }
              />
            </div>
          </div>
          <div className='border-box clearfix fields'>
            <div className='border-box clearfix field2'>
              <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                End Year<sup>*</sup>
              </div>
              <input
                type='text'
                placeholder='End Year'
                value={variant.car ? variant.car.end_year : ''}
                className='field border-box small'
                onChange={(e) =>
                  handleProductFieldChange(
                    'end_year',
                    e,
                    'variant',
                    'object',
                    'car',
                    index
                  )
                }
              />
            </div>
          </div>
        </>
      ) : null}
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            MRP<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='MRP'
            value={variant.mrp}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange('mrp', e, 'variant', null, null, index)
            }
          />
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Price<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='Price'
            value={variant.price}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange('price', e, 'variant', null, null, index)
            }
          />
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Color<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='Color'
            value={variant.color}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange('color', e, 'variant', null, null, index)
            }
          />
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Sku Code<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='Sku Code'
            value={variant.sku_code}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange(
                'sku_code',
                e,
                'variant',
                null,
                null,
                index
              )
            }
          />
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Compatibility
          </div>
          <input
            type='text'
            placeholder='Compatibility'
            value={variant.compatibility}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange(
                'compatibility',
                e,
                'variant',
                null,
                null,
                index
              )
            }
          />
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Availability status<sup>*</sup>
          </div>
          <input
            className='field border-box small'
            type='radio'
            id='is_Available1'
            name='is_Available'
            value='1'
            checked={variant.availability_status === 1}
            onChange={(e) => {
              handleVariantChange(
                'availability_status',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='is_Available1'>Available</label>
          <input
            className='field border-box small'
            type='radio'
            id='is_Available2'
            name='is_Available'
            value='0'
            checked={variant.availability_status === 0}
            onChange={(e) => {
              handleVariantChange(
                'availability_status',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='is_Available2'>Not Available</label>
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Volume
          </div>
          <input
            type='text'
            placeholder='volume'
            value={variant.volume}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange(
                'volume',
                e,
                'variant',
                null,
                null,
                index
              )
            }
          />
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Weight
          </div>
          <input
            type='text'
            placeholder='Weight'
            value={variant.weight}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange(
                'weight',
                e,
                'variant',
                null,
                null,
                index
              )
            }
          />
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Is Google Yes<sup>*</sup>
          </div>
          <input
            className='field border-box small'
            type='radio'
            id='is_google_yes1'
            name='is_google_yes'
            value='1'
            checked={variant.is_google_yes === 1}
            onChange={(e) => {
              handleVariantChange(
                'is_google_yes',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='is_google_yes1'>Yes</label>
          <input
            className='field border-box small'
            type='radio'
            id='is_google_yes2'
            name='is_google_yes'
            value='0'
            checked={variant.is_google_yes === 0}
            onChange={(e) => {
              handleVariantChange(
                'is_google_yes',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='is_google_yes2'>No</label>
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Xml Inclusion<sup>*</sup>
          </div>
          <input
            className='field border-box small'
            type='radio'
            id='xml_inclusion1'
            name='xml_inclusion'
            value='1'
            checked={variant.xml_inclusion === 1}
            onChange={(e) => {
              handleVariantChange(
                'xml_inclusion',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='xml_inclusion1'>Yes</label>
          <input
            className='field border-box small'
            type='radio'
            id='xml_inclusion2'
            name='xml_inclusion'
            value='0'
            checked={variant.xml_inclusion === 0}
            onChange={(e) => {
              handleVariantChange(
                'xml_inclusion',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='xml_inclusion2'>No</label>
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Is Local Inventory<sup>*</sup>
          </div>
          <input
            className='field border-box small'
            type='radio'
            id='is_local_inventory1'
            name='is_local_inventory'
            value='1'
            checked={variant.is_local_inventory === 1}
            onChange={(e) => {
              handleVariantChange(
                'is_local_inventory',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='is_local_inventory1'>Yes</label>
          <input
            className='field border-box small'
            type='radio'
            id='is_local_inventory2'
            name='is_local_inventory'
            value='0'
            checked={variant.is_local_inventory === 0}
            onChange={(e) => {
              handleVariantChange(
                'is_local_inventory',
                Number(e.target.value),
                null,
                null,
                index
              )
            }}
          />
          <label for='is_local_inventory2'>No</label>
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            unicommerce sku code<sup>*</sup>
          </div>
          <input
            type='text'
            placeholder='unicommerce sku code'
            value={variant.unicommerce_skucode}
            className='field border-box small'
            onChange={(e) =>
              handleProductFieldChange(
                'unicommerce_skucode',
                e,
                'variant',
                null,
                null,
                index
              )
            }
          />
        </div>
      </div>
      <div className='border-box clearfix fields'>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Specifications<sup>*</sup>
          </div>
          <input
            type='text'
            className='field border-box small'
            placeholder='Specifications (Enter data as key:value)'
            value={variant.specifications_text}
            onChange={(e) =>
              handleProductFieldChange(
                'specifications_text',
                e,
                'variant',
                null,
                null,
                index
              )
            }
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                let temp = { ...variant.specifications } || {}
                const data = e.target.value.trim().split(':')
                if (!data[1]) {
                  alert('Please enter data in required format ')
                  return
                }
                temp[data[0]] = data[1]
                handleVariantChange('specifications', temp, null, null, index)
              }
            }}
          />
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {variant.specifications &&
              Object.keys(variant.specifications).map((key) => (
                <>
                  <li
                    key={key}
                    style={{
                      width: '150px',
                      display: 'inline-block',
                      padding: '5px',
                      margin: '5px',
                      fontSize: '15px',
                    }}
                    className='choiceboxmultiinnerselected'
                  >
                    <span>{`${key}:${variant.specifications[key]}`}</span>
                    <span
                      style={{
                        float: 'right',
                        display: 'inline-block',
                        padding: '0px 5px',
                        background: '#ccc',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        let temp = { ...variant.specifications } || {}
                        delete temp[key]
                        handleVariantChange(
                          'specifications',
                          temp,
                          null,
                          null,
                          index
                        )
                      }}
                    >
                      x
                    </span>
                  </li>
                </>
              ))}
          </ul>
        </div>
        <div className='border-box clearfix field2'>
          <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
            Dimensions<sup>*</sup>
          </div>
          <input
            type='text'
            className='field border-box small'
            placeholder='Dimensions (Enter data as key:value)'
            value={variant.dimensions_text}
            onChange={(e) =>
              handleProductFieldChange(
                'dimensions_text',
                e,
                'variant',
                null,
                null,
                index
              )
            }
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                let temp = { ...variant.dimensions } || {}
                const data = e.target.value.trim().split(':')
                if (!data[1]) {
                  alert('Please enter data in required format ')
                  return
                }
                temp[data[0]] = data[1]
                handleVariantChange('dimensions', temp, null, null, index)
              }
            }}
          />
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {variant.dimensions &&
              Object.keys(variant.dimensions).map((key) => (
                <>
                  <li
                    key={key}
                    style={{
                      width: '150px',
                      display: 'inline-block',
                      padding: '5px',
                      margin: '5px',
                      fontSize: '15px',
                    }}
                    className='choiceboxmultiinnerselected'
                  >
                    <span>{`${key}:${variant.dimensions[key]}`}</span>
                    <span
                      style={{
                        float: 'right',
                        display: 'inline-block',
                        padding: '0px 5px',
                        background: '#ccc',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        let temp = { ...variant.dimensions } || {}
                        delete temp[key]
                        handleVariantChange(
                          'dimensions',
                          temp,
                          null,
                          null,
                          index
                        )
                      }}
                    >
                      x
                    </span>
                  </li>
                </>
              ))}
          </ul>
        </div>
      </div>
      {attributes && attributes.length ? (
        <div className='border-box clearfix fields'>
          <div className='border-box clearfix field1'>
            <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
              Attributes<sup>*</sup>
            </div>
            <div>
              {attributes.map((attr) => (
                <div style={{ display: 'flex', alignItems: 'center' }}> 
                  <div style={{ marginRight: '15px' }}>{attr}</div>
                  <input
                    type='text'
                    className='field border-box small'
                    placeholder={`Variant ${attr}`}
                    value={variant.attributes ? variant.attributes[attr] : ''}
                    onChange={(e) => {
                      let temp = { ...variant.attributes } || {}
                      temp[attr] = e.target.value.trim();
                      handleVariantChange('attributes', temp, null, null, index)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default VariantItem
