import React from 'react';
// import "./dashboard/dashboard.css"
import Row from './row';
import _ from 'lodash';

class MiddleMain extends React.Component {
	constructor(props) {
		super(props);
		//    this.state = {};
	}

	render() {
		var that = this;
		// console.log(that.props.tag);
		// console.log("props in spares ", this.props);
		// if (!this.props.content.length) this.props.content.data = [];
		let rows = [];
		if (that.props.tag === 'master') {
			rows =
				_.get(that.props, ['masterContent', 'data'], []) &&
				_.get(that.props, ['masterContent', 'data'], []).map((obj) => (
					<Row
						data={obj}
						masterTabCategory={that.props.masterTabCategory}
						selectedRow={that.props.selectedRow}
						tag={that.props.tag}
						onRowClick={that.props.onRowClick}
					/>
				));
		} 
		else if(this.props.tag==='HOMEPAGE'){
			rows =
				that.props.content && that.props.content?
				that.props.content.map((obj) => (
					<>
					<Row
						data={obj}
						selectedRow={that.props.selectedRow}
						tag={that.props.tag}
						onRowClick={that.props.onRowClick}
					/>
					</>
				)):null}
		else 
		{
			rows =
				that.props.content && that.props.content.data?
				that.props.content.data.map((obj) => (
					<>
					<Row
						data={obj}
						selectedRow={that.props.selectedRow}
						tag={that.props.tag}
						onRowClick={that.props.onRowClick}
					/>
					</>
				)):null}
		return (
			<>
				{!that.props.showContent ? null : that.props.content.length === 0 ? (
					<div className='main border-box leftalign'>
						<div className='empty centeralign weight400 blackspan56'>
							No Data.
						</div>
					</div>
				) : (
					<div className='main border-box leftalign'>{rows}</div>
				)}
				{/* <div className="main border-box leftalign">
                {!this.props.showContent ? (
                    <React.Fragment />
                ) : this.props.content.length == 0 ? (
                    <div className="empty centeralign weight400 blackspan56">
                        No Data.
                    </div>
                ) : (
                    rows
                )}
            </div> */}
			</>
		);
	}
}

export default MiddleMain;
