import React, { useState } from "react";
import Topbar from "./topbar";
// import Dashboard from './content_website/dashboard';
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";
import Dashboard from "./dashboard";
import PanelLeft from "./leftpanel";
import API from "./api";
import axios from "axios";
import MultiSelect from "./broadcast/MultiSelect/MultiSelect";
import { Redirect } from "react-router-dom";

import "./AppBroadCast.css";

class AppBroadCast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "logged in",
            selectedTab: "broadcast",
            options: [],
            subCities: [],
            isChecked: false,
            selectedCity: null,
            values: [],
            subCitiesToShow: [],
            selectedCityId: null,
            allChecked: false,
            title: "",
            description: "",
            endDate: new Date(),
            clusterIds: [],
            garages: [],
            selectedGarages: [],
            audience: null,
            selectedCities: [],
            selectedSubCities: [],
            selectedClusters: [],
        };

        this.handleTabClick = this.handleTabClick.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.selectAudience = this.selectAudience.bind(this);
        // var clusterIdsTemp = []
    }
    handleTabClick(tag) {
        var that = this;

        that.setState({ selectedTab: tag, showMiddleContent: false });
        API.tabClickCall(tag, that);
    }

    selectAudience(e) {
        this.setState({ audience: e.target.value });
    }

    componentWillMount() {
        axios
            .get("https://gomechanic.app/api/get_cities_v1", {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                this.setState({ options: response.data.data.city_list }, () => {
                    var citiesData = [];
                    citiesData = [
                        { id: "select-all", name: "Select All" },
                        ...this.state.options,
                    ];

                    this.setState({
                        options: citiesData,
                    });
                });
                return response;
            })
            .then(() => {
                API.tabClickCall(this.state.selectedTab, this);
            });
    }

    componentDidMount() {
        axios
            .get("https://gomechanic.app/api/v1/oauth/broadcast/clusters/", {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                this.setState({ subCities: response.data.data }, () => {
                    // var subCitiesData = [];
                    // subCitiesData = [
                    //     { id: "select-all", name: "Select All" },
                    //     ...this.state.subCities,
                    // ]
                    // this.setState({
                    //     subCities: subCitiesData
                    // })
                });

                return response;
            });
    }

    handleCheckbox(ind) {
        const clusterIdsTemp = [];
        const newOptions = [...this.state.subCitiesToShow];
        newOptions.map((city) => {
            if (ind === city.cluster_id) {
                clusterIdsTemp.push(city.cluster_id);
                this.setState({
                    clusterIds: [...this.state.clusterIds, city.cluster_id],
                });
                city.is_checked = !city.is_checked;
            }
        });

        this.setState({
            subCitiesToShow: newOptions,
        });
    }

    sendNotification(e) {
        e.preventDefault();
        let tempClusters = [];
        const selectedClusters = this.state.selectedClusters.map((garage) => {
            if (!isNaN(garage.cluster_id)) {
                tempClusters.push(garage.cluster_id);
            }
        });
        const postData = {
            title: this.state.title,
            description: this.state.description,
            start_date: new Date().toISOString().slice(0, 10) + " 00:00:00",
            end_date: this.state.endDate + " 23:59:59",
            cluster_id: tempClusters.toString(),
            audience: Number(this.state.audience),
        };

        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
        };

        axios
            .post(
                "https://gomechanic.app/api/v1/oauth/broadcast/broadcast_messages/",
                postData,
                config
            )
            .then((response) => {
                tempClusters.length = 0;
                this.setState({
                    title: "",
                    description: "",
                    end_date: new Date(),
                    audience: null,
                });
                alert("Notification sent successfully");
                window.location.reload();
                return response;
            });
    }

    afterSelectingCity() {
        var tempArr = [];
        tempArr = [
            { cluster_id: "select-all", cluster_name: "Select All" },
            // ...this.state.selectedSubCities,
        ];
        this.state.subCities.map((subCity) => {
            this.state.selectedCities.map((selectedCity) => {
                if (subCity.city_id == selectedCity.id) {
                    tempArr.push(subCity);
                    this.setState({ selectedSubCities: tempArr });
                }
            });
        });
    }

    render() {
        if (
            this.state.status === "not logged in" ||
            new Date(localStorage.getItem("token_expiration_date")) <=
                new Date()
        ) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("token_expiration_date");
            localStorage.removeItem("user_id");
            localStorage.removeItem("phone");
            localStorage.removeItem("custom_token");
            localStorage.removeItem("user_roles");
            return <Redirect to="/login" />;
        } else if (
            this.state.selectedTab === "home" ||
            this.state.selectedTab === "orders"
        ) {
            return <Redirect to="/" />;
        } else if (this.state.selectedTab === "support_chat") {
            return <Redirect to="/chat" />;
        } else if (this.state.selectedTab === "content_website") {
            return <Redirect to="/content/website" />;
        } else if (this.state.selectedTab === "accessories") {
            return <Redirect to="/accessories" />;
        } else if (this.state.selectedTab === "spares") {
            return <Redirect to="/spares" />;
        } else {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>GoMechanic Dashboard</title>
                    </Helmet>
                    <Topbar />

                    <div id="containermainouter1">
                        <PanelLeft
                            onTabClick={this.handleTabClick}
                            selectedTab={this.state.selectedTab}
                        />
                        <div
                            id="dashboardmainmiddle"
                            style={{ overflowY: "auto" }}
                            className="bgwhite leftalign"
                        >
                            <div id="dashboardprime" className="leftalign">
                                <div
                                    className="top border-box clearfix leftalign"
                                    style={{ height: "120vh" }}
                                >
                                    <form
                                        onSubmit={(e) =>
                                            this.sendNotification(e)
                                        }
                                    >
                                        <div>Title</div>
                                        <input
                                            type="text"
                                            class="dashboardfields dashboardchangefields tiny"
                                            name="title"
                                            placeholder="Type your Title"
                                            style={{ width: "200px" }}
                                            onChange={(e) =>
                                                this.setState({
                                                    title: e.target.value,
                                                })
                                            }
                                            value={this.state.title}
                                            required
                                        ></input>
                                        <div style={{ marginTop: "20px" }}>
                                            Announcement Message
                                        </div>
                                        <textarea
                                            style={{ width: "58%" }}
                                            placeholder="Type here"
                                            value={this.state.description}
                                            onChange={(e) =>
                                                this.setState({
                                                    description: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                        <div style={{ marginTop: "20px" }}>
                                            <span>
                                                Announcement Expiry Date
                                            </span>
                                            <input
                                                type="date"
                                                value={this.state.endDate}
                                                onChange={(e) =>
                                                    this.setState({
                                                        endDate: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                        </div>

                                        <div style={{ marginTop: "26px" }}>
                                            Audience
                                        </div>

                                        <select
                                            className="dashboardfields dashboardchangefields select tiny"
                                            id="fieldstarttimefrom"
                                            style={{
                                                width: "130px",
                                                height: "40px",
                                            }}
                                            onChange={this.selectAudience}
                                        >
                                            <option>Select Audience</option>
                                            <option value={1}>CRs</option>
                                            <option value={2}>Garages</option>
                                        </select>

                                        <div
                                            style={{
                                                width: "40%",
                                                marginTop: "16px",
                                            }}
                                        >
                                            <MultiSelect
                                                multiple
                                                options={this.state.options}
                                                labelKey="name"
                                                checkbox_id="id"
                                                placeholder="Select Cities"
                                                disabledItems={[]}
                                                selectedOptions={
                                                    this.state.selectedCities
                                                }
                                                onChange={(value) =>
                                                    this.setState(
                                                        {
                                                            selectedCities: value,
                                                        },
                                                        () => {
                                                            this.afterSelectingCity();
                                                        }
                                                    )
                                                }
                                            />
                                        </div>

                                        {this.state.selectedSubCities.length >
                                            0 && (
                                            <div
                                                style={{
                                                    width: "40%",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                <MultiSelect
                                                    multiple
                                                    options={
                                                        this.state
                                                            .selectedSubCities
                                                    }
                                                    labelKey="cluster_name"
                                                    checkbox_id="cluster_id"
                                                    placeholder="Select Clusters"
                                                    disabledItems={[]}
                                                    selectedOptions={
                                                        this.state
                                                            .selectedClusters
                                                    }
                                                    onChange={(value) =>
                                                        this.setState({
                                                            selectedClusters: value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}

                                        <div>
                                            <button
                                                style={{ marginTop: "20px" }}
                                                className="btn  btncolorprim btncard move"
                                            >
                                                SEND
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default AppBroadCast;
