import React from 'react';
// import "./dashboard/dashboard.css"
import Constants from "./constants";

class PanelRight extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
        if(!this.props.showContent){
            return (
                <div id="dashboardmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
                    <table className="stdtable"><tr><td valign="center">
                        Select an item on the left to view its details.
                    </td></tr></table>
                </div>
            );
        }else{
            switch(this.props.tag){
                case 'orders':
                        var statuses = JSON.parse(localStorage.getItem("statuses"));
                        var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
                        var status = "NA";
                        if(filteredstatuses.length > 0){
                            status = filteredstatuses[0].status;
                        }
                    return (
                        <div id="dashboardmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
                            <div className="border-box clearfix buttons"><div className="border-box blackspan9 weight500"><table className="stdtable"><tr><td valign="center"></td></tr></table></div>
                            <div className="border-box blackspan9 weight500 rightalign">
                            
                            </div></div>
                            <div className="border-box clearfix prime blackspan4 leftalign weight700">{this.props.content.reg_num}</div>
                            <div class="border-box clearfix name blackspan65 leftalign weight500"><span style={{textTransform:'none'}}>{this.props.content.order_id}</span></div>
                            <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Order Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Order ID</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span>
                            {this.props.content.order_id}
                            </span></td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Status</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {status}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Amount</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.amount}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {Constants.orderTypes()[this.props.content.order_type]}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Channel</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.fleet_type == 0 ? "Business" : "Retail"}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Fleet</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.fleet_name}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Pickup date</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.pick_date}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Delivery date</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.delivery_date}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Estimated Delivery</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.estimated_delivery}
                            </td></tr></table></div></div></div></div></div>
                            
                            {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            
                            </td></tr></table></div></div></div></div></div> */}
                        </div>
                    );

                    case 'spares':
                        var statuses = JSON.parse(localStorage.getItem("statuses"));
                        var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
                        var status = "NA";
                        if(filteredstatuses.length > 0){
                            status = filteredstatuses[0].status;
                        }
                    return (
                        <div id="dashboardmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
                            <div className="border-box clearfix buttons"><div className="border-box blackspan9 weight500"><table className="stdtable"><tr>
                                <td valign="center">
                                    <button class="btn btn_small btncolorprim btncard move btneditright" onClick={() => this.handleButtonClick("edit")}>Edit</button>
                                </td>
                                {/* <td valign="center">
                                    <button class="btn btn_small btncolorprim btncard move btndeleteright" onClick={() => this.handleButtonClick("delete")}>Delete</button>
                                </td> */}
                            </tr></table></div>
                            <div className="border-box blackspan9 weight500 rightalign">
                            
                            </div></div>
                            <div className="border-box clearfix prime blackspan4 leftalign weight700">{this.props.content.sku_desc}</div>
                            {/* <div class="border-box clearfix name blackspan65 leftalign weight500"><span style={{textTransform:'none'}}>{this.props.content.order_id}</span></div> */}
                            {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Order Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Order ID</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span>
                            {this.props.content.order_id}
                            </span></td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Status</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {status}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Amount</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.amount}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Type</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {Constants.orderTypes()[this.props.content.order_type]}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Channel</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.fleet_type == 0 ? "Business" : "Retail"}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Fleet</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.fleet_name}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Pickup date</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.pick_date}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Delivery date</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.delivery_date}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Estimated Delivery</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.estimated_delivery}
                            </td></tr></table></div></div></div></div></div> */}
                            
                            {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            
                            </td></tr></table></div></div></div></div></div> */}
                        </div>
                    );
            }      
        }
    }
  }

export default PanelRight;