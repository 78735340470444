import React from "react";
import tabList from "./TabList";

var statuses = [];

// const base_url = 'https://dev.gomechanic.app/';
// const accessories_base_url = 'https://devaccessories.gomechanic.app/';
// const comms_base_url = 'https://comms.gomechanic.app/';
// const spares_base_url = 'https://dev.spares-mp.gomechanic.app/';

const base_url = 'https://gomechanic.app/';
const accessories_base_url = 'https://accessories.gomechanic.app/';
const comms_base_url = 'https://comms.gomechanic.app/';
const spares_base_url = 'https://spares-mp.gomechanic.app/';
const base_url_mly = 'https://mly.gomechanic.app/';

const accb2b_base_url = 'https://dev.parcit.auto.gomechanic.one/';

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const firebase_config = {
  apiKey: "AIzaSyBrl7ms6peFc8DgUKwJpp231XoCeI23PpU",
  authDomain: "gomechanic-retail.firebaseapp.com",
  databaseURL: "https://gomechanic-retail.firebaseio.com",
  projectId: "gomechanic-retail",
  storageBucket: "gomechanic-retail.appspot.com",
  messagingSenderId: "5389558266",
  appId: "1:5389558266:web:e7a91a4051e54edb",
};

const firebaseConfig1 = {
  apiKey: "AIzaSyDbr9ELHhZlaSkn-MHLpUNvolYZQt1KST0",
  authDomain: "gomechanic-9f184.firebaseapp.com",
  databaseURL: "https://gomechanic-9f184.firebaseio.com",
  projectId: "gomechanic-9f184",
  storageBucket: "gomechanic-9f184.appspot.com",
  messagingSenderId: "378179120764",
  appId: "1:378179120764:web:eb2816bad6c9c77a8ae3f9",
};
// Initialize Firebase
//   firebase.initializeApp(firebase_config);
//   firebase.analytics();

class Constants {
  getTabList(type) {
    if (type === "home") {
      return tabList;
    } else if (type === "spares_content") {
      var list2 = [].concat(
        tabList[0].subTabs.filter((obj) => {
          return obj.tag === type;
        })[0].subTabs
      );

      return list2;
    } else {
      var list = [].concat(
        tabList.filter((obj) => {
          return obj.tag === type;
        })[0].subTabs
      );
      list.push({ tag: "home", title: "Home" });
      return list;
    }
  }

  //   getTabList1(type) {
  //     if (type === "spares_content") {
  //       var list2 = [].concat(
  //         tabList[0].subTabs.filter((obj) => {
  //           return obj.tag === type;
  //         })[0].subTabs
  //       );
  // 	  console.log("verma",list2)

  //       return list2;
  //     }
  //   }

  getFirebaseConfig() {
    return firebase_config;
  }
  getBaseURL() {
    return base_url;
  }
  getAccessoriesBaseURL() {
    return accessories_base_url;
  }
  getSparesBaseURL() {
    return spares_base_url;
  }
  getCommsBaseURL() {
    return comms_base_url;
  }
  orderTypes() {
    return {
      101: "General",
      100: "Preventive",
      103: "Insurance",
      102: "Document",
    };
  }

  getBaseURLMlY() {
    return base_url_mly;
  }
  getNearMeURL() {
    let filter = localStorage.getItem("nearme_country")
      ? JSON.parse(localStorage.getItem("nearme_country"))
      : undefined;
    let url = filter ? (filter.id == 1 ? base_url : base_url_mly) : base_url;
    return url;
  }

  setStatuses(data) {
    localStorage.setItem("statuses", JSON.stringify(data));
  }

  setFleetsSummary(data) {
    localStorage.setItem("fleets_summary", JSON.stringify(data));
  }

  setGaragesSummary(data) {
    localStorage.setItem("garages_summary", JSON.stringify(data));
  }

  statuses() {
    return statuses;
  }

  getTimeDifferenceFromMill(diff) {
    var secs = Math.floor(diff / 1000);
    if (secs < 60) {
      return secs + "s";
    } else {
      var mins = Math.floor(secs / 60);
      if (mins < 60) {
        secs = secs % 60;
        return mins + "m" + " " + secs + "s";
      } else {
        var hours = Math.floor(mins / 60);
        mins = mins % 60;
        return hours + "h" + " " + mins + "m";
      }
    }
  }

  get_TimeString = function (date) {
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ampm = hour < 12 ? "AM" : "PM";
    if (hour >= 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (date <= 9) {
      date = "0" + date;
    }
    if (hour <= 9) {
      hour = "0" + hour;
    }
    if (minute <= 9) {
      minute = "0" + minute;
    }

    // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    var timeString = hour + ":" + minute + " " + ampm;
    // dateString += timeString;

    return timeString;
  };

  get_DateString = function (date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ampm = hour < 12 ? "AM" : "PM";
    if (hour >= 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (date <= 9) {
      date = "0" + date;
    }
    if (hour <= 9) {
      hour = "0" + hour;
    }
    if (minute <= 9) {
      minute = "0" + minute;
    }
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }

    var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    var timeString = hour + ":" + minute + " " + ampm;
    dateString += timeString;

    return dateString;
  };

  get_DateStringX = function (date1) {
    var nowdate = new Date();
    var date = new Date(date1);

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ampm = hour < 12 ? "AM" : "PM";
    if (hour >= 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (date <= 9) {
      date = "0" + date;
    }
    if (hour <= 9) {
      hour = "0" + hour;
    }
    if (minute <= 9) {
      minute = "0" + minute;
    }
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }

    var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    var timeString = hour + ":" + minute + " " + ampm;
    dateString += timeString;

    return dateString;
  };

  get_DateStringX1 = function (date1) {
    var nowdate = new Date();
    var date = new Date(date1);

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ampm = hour < 12 ? "AM" : "PM";
    if (hour >= 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (date <= 9) {
      date = "0" + date;
    }
    if (hour <= 9) {
      hour = "0" + hour;
    }
    if (minute <= 9) {
      minute = "0" + minute;
    }
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }

    var dateString =
      day + " " + monthNames[month - 1] + ", " + date.getFullYear();

    return dateString;
  };

  get_DateString1 = function (date1) {
    var nowdate = new Date();
    var date = new Date(date1);

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ampm = hour < 12 ? "AM" : "PM";
    if (hour >= 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (date <= 9) {
      date = "0" + date;
    }
    if (hour <= 9) {
      hour = "0" + hour;
    }
    if (minute <= 9) {
      minute = "0" + minute;
    }
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }

    if (
      date.getDay() == nowdate.getDay() &&
      date.getMonth() == nowdate.getMonth() &&
      date.getYear() == nowdate.getYear()
    ) {
      return hour + ":" + minute + " " + ampm;
    } else {
      if (date.getYear() != nowdate.getYear()) {
        return monthNames[month - 1] + " " + date.getFullYear();
      } else {
        return day + " " + monthNames[month - 1];
      }
    }

    // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    // var timeString = hour + ":" + minute + " " + ampm;
    // dateString += timeString;

    //         return dateString;
  };

  get_DateString1Date = function (date1) {
    var nowdate = new Date();
    var date = new Date(date1);

    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (date <= 9) {
      date = "0" + date;
    }
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }

    if (
      date.getDay() == nowdate.getDay() &&
      date.getMonth() == nowdate.getMonth() &&
      date.getYear() == nowdate.getYear()
    ) {
      return "Today";
    } else {
      if (date.getYear() != nowdate.getYear()) {
        return day + " " + monthNames[month - 1] + " " + date.getFullYear();
      } else {
        return day + " " + monthNames[month - 1];
      }
    }

    // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    // var timeString = hour + ":" + minute + " " + ampm;
    // dateString += timeString;

    //         return dateString;
  };

  get_DateString2 = function (date1) {
    var nowdate = new Date();
    var date = new Date(date1);

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var ampm = hour < 12 ? "AM" : "PM";
    if (hour >= 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (date <= 9) {
      date = "0" + date;
    }
    if (hour <= 9) {
      hour = "0" + hour;
    }
    if (minute <= 9) {
      minute = "0" + minute;
    }
    if (month <= 9) {
      month = "0" + month;
    }
    if (day <= 9) {
      day = "0" + day;
    }

    var dateString =
      day + " " + monthNames[month - 1] + " " + date.getFullYear() + ", ";
    var timeString = hour + ":" + minute + " " + ampm;
    dateString += timeString;

    return dateString;
  };

  get_TimeComparison = function (date1, date2) {
    var date11 = new Date(date1);
    var date22 = new Date(date2);

    if (
      date11.getDay() == date22.getDay() &&
      date11.getMonth() == date22.getMonth() &&
      date11.getYear() == date22.getYear()
    ) {
      return true;
    } else {
      return false;
    }

    // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    // var timeString = hour + ":" + minute + " " + ampm;
    // dateString += timeString;

    //         return dateString;
  };

  get_TimeComparison1 = function (date1, date2) {
    var date11 = new Date(date1);
    var date22 = new Date(date2);

    if (
      date11.getDay() == date22.getDay() &&
      date11.getMonth() == date22.getMonth() &&
      date11.getYear() == date22.getYear() &&
      date11.getHours() == date22.getHours() &&
      date11.getMinutes() == date22.getMinutes()
    ) {
      return true;
    } else {
      return false;
    }

    // var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
    // var timeString = hour + ":" + minute + " " + ampm;
    // dateString += timeString;

    //         return dateString;
  };

  initializeTinyMCE() {
    // tinymce.init({
    //     selector: '#mytextarea'
    // });
  }
	getTabList(type) {
		if (type == 'home') {
			return tabList;
		} else {
			var list = [].concat(
				tabList.filter((obj) => {
					return obj.tag === type;
				})[0].subTabs
			);
			list.push({ tag: 'home', title: 'Home' });
			return list;
		}
	}
	getFirebaseConfig() {
		return firebase_config;
	}
	getBaseURL() {
		return base_url;
	}
	getAccessoriesBaseURL() {
		return accessories_base_url;
	}
	getAccessoriesB2BBaseURL() {
		return accb2b_base_url;
	}
	getSparesBaseURL() {
		return spares_base_url;
	}
	getCommsBaseURL() {
		return comms_base_url;
	}
	orderTypes() {
		return {
			101: 'General',
			100: 'Preventive',
			103: 'Insurance',
			102: 'Document',
		};
	}

	getBaseURLMlY() {
		return base_url_mly;
	}
	getNearMeURL() {
		let filter = localStorage.getItem('nearme_country')
			? JSON.parse(localStorage.getItem('nearme_country'))
			: undefined;
		let url = filter ? (filter.id == 1 ? base_url : base_url_mly) : base_url;
		return url;
	}

	setStatuses(data) {
		localStorage.setItem('statuses', JSON.stringify(data));
	}

	setFleetsSummary(data) {
		localStorage.setItem('fleets_summary', JSON.stringify(data));
	}

	setGaragesSummary(data) {
		localStorage.setItem('garages_summary', JSON.stringify(data));
	}

	statuses() {
		return statuses;
	}

	getTimeDifferenceFromMill(diff) {
		var secs = Math.floor(diff / 1000);
		if (secs < 60) {
			return secs + 's';
		} else {
			var mins = Math.floor(secs / 60);
			if (mins < 60) {
				secs = secs % 60;
				return mins + 'm' + ' ' + secs + 's';
			} else {
				var hours = Math.floor(mins / 60);
				mins = mins % 60;
				return hours + 'h' + ' ' + mins + 'm';
			}
		}
	}

	get_TimeString = function (date) {
		var hour = date.getHours();
		var minute = date.getMinutes();
		var ampm = hour < 12 ? 'AM' : 'PM';
		if (hour >= 12) {
			hour = hour - 12;
		}
		if (hour == 0) {
			hour = 12;
		}
		if (date <= 9) {
			date = '0' + date;
		}
		if (hour <= 9) {
			hour = '0' + hour;
		}
		if (minute <= 9) {
			minute = '0' + minute;
		}

		// var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
		var timeString = hour + ':' + minute + ' ' + ampm;
		// dateString += timeString;

		return timeString;
	};

	get_DateString = function (date) {
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var ampm = hour < 12 ? 'AM' : 'PM';
		if (hour >= 12) {
			hour = hour - 12;
		}
		if (hour == 0) {
			hour = 12;
		}
		if (date <= 9) {
			date = '0' + date;
		}
		if (hour <= 9) {
			hour = '0' + hour;
		}
		if (minute <= 9) {
			minute = '0' + minute;
		}
		if (month <= 9) {
			month = '0' + month;
		}
		if (day <= 9) {
			day = '0' + day;
		}

		var dateString = day + ' - ' + month + ' - ' + date.getFullYear() + ', ';
		var timeString = hour + ':' + minute + ' ' + ampm;
		dateString += timeString;

		return dateString;
	};

	get_DateStringX = function (date1) {
		var nowdate = new Date();
		var date = new Date(date1);

		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var ampm = hour < 12 ? 'AM' : 'PM';
		if (hour >= 12) {
			hour = hour - 12;
		}
		if (hour == 0) {
			hour = 12;
		}
		if (date <= 9) {
			date = '0' + date;
		}
		if (hour <= 9) {
			hour = '0' + hour;
		}
		if (minute <= 9) {
			minute = '0' + minute;
		}
		if (month <= 9) {
			month = '0' + month;
		}
		if (day <= 9) {
			day = '0' + day;
		}

		var dateString = day + ' - ' + month + ' - ' + date.getFullYear() + ', ';
		var timeString = hour + ':' + minute + ' ' + ampm;
		dateString += timeString;

		return dateString;
	};

	get_DateStringX1 = function (date1) {
		var nowdate = new Date();
		var date = new Date(date1);

		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var ampm = hour < 12 ? 'AM' : 'PM';
		if (hour >= 12) {
			hour = hour - 12;
		}
		if (hour == 0) {
			hour = 12;
		}
		if (date <= 9) {
			date = '0' + date;
		}
		if (hour <= 9) {
			hour = '0' + hour;
		}
		if (minute <= 9) {
			minute = '0' + minute;
		}
		if (month <= 9) {
			month = '0' + month;
		}
		if (day <= 9) {
			day = '0' + day;
		}

		var dateString =
			day + ' ' + monthNames[month - 1] + ', ' + date.getFullYear();

		return dateString;
	};

	get_DateString1 = function (date1) {
		var nowdate = new Date();
		var date = new Date(date1);

		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var ampm = hour < 12 ? 'AM' : 'PM';
		if (hour >= 12) {
			hour = hour - 12;
		}
		if (hour == 0) {
			hour = 12;
		}
		if (date <= 9) {
			date = '0' + date;
		}
		if (hour <= 9) {
			hour = '0' + hour;
		}
		if (minute <= 9) {
			minute = '0' + minute;
		}
		if (month <= 9) {
			month = '0' + month;
		}
		if (day <= 9) {
			day = '0' + day;
		}

		if (
			date.getDay() == nowdate.getDay() &&
			date.getMonth() == nowdate.getMonth() &&
			date.getYear() == nowdate.getYear()
		) {
			return hour + ':' + minute + ' ' + ampm;
		} else {
			if (date.getYear() != nowdate.getYear()) {
				return monthNames[month - 1] + ' ' + date.getFullYear();
			} else {
				return day + ' ' + monthNames[month - 1];
			}
		}

		// var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
		// var timeString = hour + ":" + minute + " " + ampm;
		// dateString += timeString;

		//         return dateString;
	};

	get_DateString1Date = function (date1) {
		var nowdate = new Date();
		var date = new Date(date1);

		var month = date.getMonth() + 1;
		var day = date.getDate();
		if (date <= 9) {
			date = '0' + date;
		}
		if (month <= 9) {
			month = '0' + month;
		}
		if (day <= 9) {
			day = '0' + day;
		}

		if (
			date.getDay() == nowdate.getDay() &&
			date.getMonth() == nowdate.getMonth() &&
			date.getYear() == nowdate.getYear()
		) {
			return 'Today';
		} else {
			if (date.getYear() != nowdate.getYear()) {
				return day + ' ' + monthNames[month - 1] + ' ' + date.getFullYear();
			} else {
				return day + ' ' + monthNames[month - 1];
			}
		}

		// var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
		// var timeString = hour + ":" + minute + " " + ampm;
		// dateString += timeString;

		//         return dateString;
	};

	get_DateString2 = function (date1) {
		var nowdate = new Date();
		var date = new Date(date1);

		var month = date.getMonth() + 1;
		var day = date.getDate();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var ampm = hour < 12 ? 'AM' : 'PM';
		if (hour >= 12) {
			hour = hour - 12;
		}
		if (hour == 0) {
			hour = 12;
		}
		if (date <= 9) {
			date = '0' + date;
		}
		if (hour <= 9) {
			hour = '0' + hour;
		}
		if (minute <= 9) {
			minute = '0' + minute;
		}
		if (month <= 9) {
			month = '0' + month;
		}
		if (day <= 9) {
			day = '0' + day;
		}

		var dateString =
			day + ' ' + monthNames[month - 1] + ' ' + date.getFullYear() + ', ';
		var timeString = hour + ':' + minute + ' ' + ampm;
		dateString += timeString;

		return dateString;
	};

	get_TimeComparison = function (date1, date2) {
		var date11 = new Date(date1);
		var date22 = new Date(date2);

		if (
			date11.getDay() == date22.getDay() &&
			date11.getMonth() == date22.getMonth() &&
			date11.getYear() == date22.getYear()
		) {
			return true;
		} else {
			return false;
		}

		// var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
		// var timeString = hour + ":" + minute + " " + ampm;
		// dateString += timeString;

		//         return dateString;
	};

	get_TimeComparison1 = function (date1, date2) {
		var date11 = new Date(date1);
		var date22 = new Date(date2);

		if (
			date11.getDay() == date22.getDay() &&
			date11.getMonth() == date22.getMonth() &&
			date11.getYear() == date22.getYear() &&
			date11.getHours() == date22.getHours() &&
			date11.getMinutes() == date22.getMinutes()
		) {
			return true;
		} else {
			return false;
		}

		// var dateString = day + " - " + month + " - " + date.getFullYear() + ", ";
		// var timeString = hour + ":" + minute + " " + ampm;
		// dateString += timeString;

		//         return dateString;
	};

	initializeTinyMCE() {
		// tinymce.init({
		//     selector: '#mytextarea'
		// });
	}
}

export default new Constants();
