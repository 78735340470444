import React from "react";
import Topbar from "./topbar";
// import Dashboard from './content_website/dashboard';
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";
import Dashboard from "./SparesContent/dashboard";

function AppSparesContent() {
  // console.log("protocol", window.location.protocol);
  // console.log("href", window.location.href);
  // if (window.location.protocol !== 'https:') {
  //   window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
  //   return // to stop app from mounting
  // }
  return (
    <React.Fragment>
      <Helmet>
        <title>GoMechanic Dashboard</title>
      </Helmet>
      <div id="containermain1">
        <Topbar />

        <Dashboard />
      </div>
    </React.Fragment>
  );
}

export default AppSparesContent;
