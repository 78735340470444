import React from 'react';
import Topbar from './topbar';
import {Helmet} from "react-helmet";
import Dashboard from './accessoriesb2b/dashboard';

function AppAccessories() {
  return (
    <React.Fragment>
    <Helmet>
      <title>GoMechanic Dashboard</title>
    </Helmet>
    <div id="containermain1">
    <Topbar />

    <Dashboard />
    </div>
    </React.Fragment>
  );
}

export default AppAccessories;
