import React from 'react';
import Constants from '../constants';

class PanelLeft extends React.Component {
  
  render() {
    const tabs = Constants.getTabList("accessories").map((tab) =>
      <Tab
        tag={tab.tag}
        title = {tab.title}
        onTabClick={this.props.onTabClick}
        selectedTab={this.props.selectedTab}
      />
    );

    return (
      <div id="dashboardmainleft" className="border-box bgwhite leftalign floatleft shadow-box">
        <div className="first border-box clearfix leftalign shadow-box">
          <div className="border-box clearfix">
            <div className="dashboardmainleft1boxesinner clearfix whitespan weight900 shadow-text">
              GoMechanic<br/>Internal
            </div>
            <div className="border-box clearfix whitespan2 weight700 shadow-text">
              <span style={{textTransform:'none'}}>Admin</span>
            </div>
            <div className="border-box clearfix whitespan2 weight400 shadow-text">
              GoMechanic
            </div>
          </div>
        </div>
        <div className="second border-box leftalign">
          <div className="floatleft leftalign blackspan7 weight400">
            {tabs}
          </div>
        </div>		
    </div>
    );
  }
}
class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showContent: false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onTabClick(this.props.tag);
  }

  render() {
    return (
        <div
          className={this.props.selectedTab === this.props.tag
            ? "tabs bgwhite border-box shadow-text selected"
            : "tabs bgwhite border-box shadow-text"
          } 
          data-type={this.props.tag}
          onClick={this.handleClick}
        >
          <table className="stdtable">
            <tr>
              <td valign="center">
              {this.props.title}
              </td>
            </tr>
          </table>
        </div>
    );
  }
}

  export default PanelLeft;