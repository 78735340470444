import React from 'react';
import TopbarLogin from './topbar_login';
import LoginMain from './LoginMain';
import { Helmet } from "react-helmet";
import HttpsRedirect from 'react-https-redirect';

function Login() {
  // console.log("protocol", window.location.protocol);
  // console.log("href", window.location.href);
  // if (window.location.protocol !== 'https:') {
  //   window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
  //   return // to stop app from mounting
  // }
  return (
    <React.Fragment>
      <Helmet>
        <title>Log in - GoMechanic Support</title>
      </Helmet>
      <div id="containermain1">
        <TopbarLogin />

        <LoginMain />
      </div>
    </React.Fragment>
  );
}

export default Login;
