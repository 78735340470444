const tabList = [
  {
    tag: "spares",
    title: "Spares",
    subTabs: [
      {
        tag: "spares_content",
        title: "Content",
        subTabs: [
          {
            tag: "HOMEPAGE",
            title: "Home Page",
            attributes: [
              // {
              //   tag: "home_related",
              //   title: "Related Blogs",
              //   active: false,
              // },
              {
                tag: "spares",
                title: "FAQs",
                active: false,

              },
              // {
              //   tag: "home_about",
              //   title: "About",
              //   active: false,
              // },
              // {
              //   tag: "home_review",
              //   title: "Reviews",
              //   active: false,
              // },
              // {
              //   tag: "home_near",
              //   title: "Near me Pages",
              //   active: false,
              // },
              {
                tag: "Home_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "home_seo",
                title: "Add SEO",
                active: false,
              },
              {
                tag: "home_headings",
                title: "Homepage Headings",
                active: false,
              },
            ],
          },
          {
            tag: "PRODUCT",
            title: "Product",
            attributes: [
              {
                tag: "product-faq",
                title: "FAQs",
                active: false,
              },
              // {
              //   tag: "product_review",
              //   title: "Reviews",
              //   active: false,
              // },
            ],
          },
          {
            tag: "BRAND",
            title: "Brand",
            attributes: [
              {
                tag: "brand_faq",
                title: "FAQs",
                active: false,

              },
              // {
              //   tag: "brand_review",
              //   title: "Reviews",
              //   active: false,
              // },
              {
                tag: "brand_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "brand_seo",
                title: "Add SEO",
                active: false,
              },
            ],
          },
          {
            tag: "SEGMENT",
            title: "Segment",
            attributes: [
              {
                tag: "segment-faq",
                title: "FAQs",
                active: false,
              },
              {
                tag: "segment_subHeading",
                title: "SubHeadings",
                active: false,
              },
              // {
              //   tag: "segment_review",
              //   title: "Reviews",
              //   active: false,
              // },
              {
                tag: "segment_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "segment_seo",
                title: "Add SEO",
                active: false,
              },
            ],
          },
          {
            tag: "CATEGORY",
            title: "Category",
            attributes: [
              {
                tag: "category_faq",
                title: "FAQs",
                active: false,
              },
              {
                tag: "category_subHeading",
                title: "SubHeadings",
                active: false,

              },
              // {
              //   tag: "category_review",
              //   title: "Reviews",
              //   active: false,
              // },
              {
                tag: "category_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "category_seo",
                title: "Add SEO",
                active: false,
              },
            ],
          },
          {
            tag: "SUBCATEGORY",
            title: "Sub Category",
            attributes: [
              {
                tag: "subCategory_faq",
                title: "FAQs",
                active: false,

              },
              // {
              //   tag: "subCategory_review",
              //   title: "Reviews",
              //   active: false,
              // },
              {
                tag: "subCategory_subHeading",
                title: "SubHeadings",
                active: false,

              },
              {
                tag: "subCategory_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "subCategory_seo",
                title: "Add SEO",
                active: false,
              },
            ],
          },
          {
            tag: "VEHICLE",
            title: "Vehicle",
            attributes: [
              {
                tag: "vehicle_faq",
                title: "FAQs",
                active: false,

              },
              // {
              //   tag: "vehicle_review",
              //   title: "Reviews",
              //   active: false,
              // },
              {
                tag: "vehicle_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "vehicle_seo",
                title: "Add SEO",
                active: false,
              },
            ],
          },
          {
            tag: "STORE",
            title: "Store",
            attributes: [
              {
                tag: "store-faq",
                title: "FAQs",
                active: false,

              },
              // {
              //   tag: "store_review",
              //   title: "Reviews",
              //   active: false,
              // },
              {
                tag: "store_BtmContent",
                title: "Bottom Content",
                active: false,
              },
              {
                tag: "store_seo",
                title: "Add SEO",
                active: false,
              },
            ],
          },
          {
            tag:"BACK",
            title:'back'
          }
        ],
      },
      {
        tag: "spares_banner",
        title: "Banners",
        subTabs:[ 
          {
            tag: "trending_banner",
            title: "Trending Banner",
          }, 
          {
            tag: "main_banner",
            title: "Main Banner",
          },
          {
            tag: "tyre_banner",
            title: "Tyre Banner",
          },
          // {
          //   tag: "vertical_banner",
          //   title: "Vertical Banner",
          // },
          {
            tag:"BACK",
            title:'back',
          }
        ]
      },
      {
        tag: "discount",
        title: "Discounts",
      },
      {
        tag: "products",
        title: "Products",
      },
      {
        tag: "customers",
        title: "Customers",
      },
      {
        tag: "private_label",
        title: "Private Label",
      },
      // {
      //   tag: "master",
      //   title: "masters",
      // },
      { tag: 'coupons', title: 'Coupons' },
      { tag: 'agents', title: 'Agents' },
      { tag: 'checkout', title: 'Checkout' },

      {
        tag: "wallet",
        title: "Wallet",
      },
      // { tag: 'vehicles', title: 'Vehicles' },
      // { tag: 'categories_spares', title: 'Categories' },
      // { tag: 'brands_spares', title: 'Brands' },
      {
        tag: "epc",
        title: "EPC",
      },
      // { tag: 'epcc', title: 'EPC user' },
      // {
      //   tag: "spares_payment",
      //   title: "Gateway",
      // },
      // { tag: 'download', title: 'Download' },
      // { tag: 'upload', title: 'Upload' },
    ],
  },
  {
    tag: "accessories",
    title: "Accessories",
    subTabs: [
      {
        tag: "homepage",
        title: "Home Page",
      },
      {
        tag: "segments",
        title: "Segments",
      },
      {
        tag: "categories",
        title: "Categories",
      },
      {
        tag: "products",
        title: "Products",
      },
      {
        tag: "brands",
        title: "Brands",
      },
      {
        tag: "searchbysku",
        title: "Search by SKU",
      },
      {
        tag: "collection",
        title: "Collections",
      },
      {
        tag: "collectionv2",
        title: "CollectionsV2",
      },
      {
        tag: "coupons",
        title: "Coupons",
      },
      {
        tag: "rails",
        title: "Rails",
      },
      {
        tag: "feed",
        title: "Custom Feed",
      },
      // { tag: "installationservice", title: "Installation Service"},
      {
        tag: "enquiry",
        title: "Enquiry page",
      },
      {
        tag: "reviews",
        title: "Reveiws",
      },
      { tag: 'carPage', title: 'Car page' },
      { tag: 'export', title: 'Export page' },
    ],
  },
  {
		tag: 'accessoriesb2b',
		title: 'B2B Accessories',
		subTabs: [
			// { tag: 'homepage', title: 'Home Page' },
      { tag: 'user', title: 'user' },
			{ tag: 'segments', title: 'Segments' },
			{ tag: 'categories', title: 'Categories' },
			{ tag: 'products', title: 'Products' },
			{ tag: 'brands', title: 'Brands' },
			{ tag: 'searchbysku', title: 'Search by SKU' },
      { tag: 'facility', title: 'Facility' },
			{ tag: 'collection', title: 'Collections' },
			{ tag: 'coupons', title: 'Coupons' },
			{ tag: 'rails', title: 'Rails' },
			{ tag: 'feed', title: 'Custom Feed' },
			// { tag: 'enquiry', title: 'Enquiry page' },
      // { tag: 'carPage', title: 'Car page' },
		],
	},
  {
    tag: "content_website",
    title: "Website content",
    subTabs: [
      {
        tag: "page_path_mapping",
        title: "Page path mapping",
      },
      {
        tag: "faq_near_me",
        title: "Near me FAQ",
      },
      {
        tag: "price_list",
        title: "Price list",
      },
      {
        tag: "price_comparison",
        title: "Price comparison",
      },
      {
        tag: "testimonials",
        title: "Testimonials",
      },
      {
        tag: "near_me_acc",
        title: "Accessories Near Me",
      },
    ],
  },
  {
    tag: "orders",
    title: "Orders",
  },
  {
    tag: "rsa_orders",
    title: "RSA Orders",
  },
  {
    tag: "fleets",
    title: "Fleets",
  },
  {
    tag: "users",
    title: "Users",
  },
  {
    tag: "services",
    title: "Services",
  },
  {
    tag: "packages",
    title: "Packages",
  },
  {
    tag: "deals",
    title: "Deals",
  },
  {
    tag: "cars",
    title: "Cars",
  },
  {
    tag: "invoices",
    title: "Invoices",
  },
  {
    tag: "garages",
    title: "Garages",
  },
  {
    tag: "crs",
    title: "CRs",
  },
  {
    tag: "bulkorders",
    title: "Bulk Orders",
  },
  {
    tag: "addresses",
    title: "Addresses",
  },
  {
    tag: "broadcast",
    title: "Broadcast",
  },
  // { tag: "incentives", title: "Incentives" }
];
export default tabList;
