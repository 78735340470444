import React from "react";
// import "./dashboard/dashboard.css"
import Row from "./row";
import { Editor } from "@tinymce/tinymce-react";
import API from "../api";
import MultipleSelection from "../broadcast/MultiSelect/MultiSelect";
import NotificationManager from "react-notifications/lib/NotificationManager";

class MiddleExtra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.props.content.content
        ? {
            ...this.props.content.content,
            ...(this.props.content.type === 'add' ? { 
              url_slug: '',
              page_title: '',
              heading: '',
              product_heading: '',
            } : {}),
            testimonialCount: this.props.content.content.customer_testimonials
              ? this.props.content.content.customer_testimonials.length
              : 1,
            faqCount: this.props.content.content.faq
              ? this.props.content.content.faq.length
              : 1,
            storeImgCnt: this.props.content.content.store_data
              ? this.props.content.content.store_data.store_images.length
              : 1,
            categoryCnt: this.props.content.content.popular_category_images
              ? this.props.content.content.popular_category_images.length
              : 1,
            selectedBrands: this.props.content.content.brands || [],
            popular_category: [],
            selectedProductList: [],
            popular_category_images:
              this.props.content.content.popular_category_images || [],
          }
        : {
            testimonialCount:
              this.props.content.tag === "near_me_acc" ? 1 : null,
            faqCount: this.props.content.tag === "near_me_acc" ? 1 : null,
            video: this.props.content.tag === "near_me_acc" ? {} : null,
            banner: this.props.content.tag === "near_me_acc" ? {} : null,
            sub_banner: this.props.content.tag === "near_me_acc" ? {} : null,
            faq: this.props.content.tag === "near_me_acc" ? [] : null,
            customer_testimonials:
              this.props.content.tag === "near_me_acc" ? [] : null,
            store_data:
              this.props.content.tag === "near_me_acc"
                ? {
                    store_images: [],
                  }
                : null,
            storeImgCnt: this.props.content.tag === "near_me_acc" ? 1 : null,
            categoryCnt: this.props.content.tag === "near_me_acc" ? 1 : null,
            selectedBrands:
              this.props.content.tag === "near_me_acc" ? [] : null,
            popular_category:
              this.props.content.tag === "near_me_acc" ? [] : null,
            selectedProductList:
              this.props.content.tag === "near_me_acc" ? [] : null,
            popular_category_images:
              this.props.content.tag === "near_me_acc" ? [] : null,
          }),
    };
    if (this.props.content.tag === "near_me_acc") {
      this.videoThumbnail = React.createRef();
      this.mainBannerWeb = React.createRef();
      this.mainBannerMob = React.createRef();
      this.subBannerWeb = React.createRef();
      this.subBannerMob = React.createRef();
    }
    // this.state.content = ;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
    this.handleObjectkeyChange = this.handleObjectkeyChange.bind(this);
    this.handleArraykeyChange = this.handleArraykeyChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onInputImage = this.onInputImage.bind(this);
    this.uploadImageOnServer = this.uploadImageOnServer.bind(this);
    this.getTestimonialList = this.getTestimonialList.bind(this);
    this.removeTestimonial = this.removeTestimonial.bind(this);
    this.getStoreImgList = this.getStoreImgList.bind(this);
    this.removeStoreImg = this.removeStoreImg.bind(this);
    this.getPopularCategoryImgList = this.getPopularCategoryImgList.bind(this);
    this.removePopularCategoryImg = this.removePopularCategoryImg.bind(this);
  }

  handleButtonClick(type) {
    var that = this;
    if (type == "save") {
      // this.refs.btn.setAttribute("disabled", "disabled");
      // // console.log("here1", this.props.conte);
      if (this.props.content.type == "edit") {
        // if (this.props.content.tag === 'near_me_acc') {
        //   const status = validateData();
        //   if(!status.flag) {
        //     NotificationManager.error(status.msg, 'Error!!', 5000);
        //     return;
        //   }
        // }
        API.updateContentWebsite(
          this.props.content.tag,
          this.props.content.row,
          this.state
        )
          .then(function (response) {
            // console.log("response");
            // console.log(response);
            if (response.data.status) {
              that.props.onMiddleExtraButtonClick(
                type,
                that.state,
                that.props.selectedRow
              );
            } else {
            }
          })
          .catch(function (error) {
            // handle error
            NotificationManager.error(
              error.response && error.response.data
                ? error.response.data.message
                : "error occured!",
              "Error!!",
              5000
            );
            // console.log(error);
          })
          .finally(function () {
            // always executed
          });
      } else if (this.props.content.type == "add") {
        // if (this.props.content.tag === 'near_me_acc') {
        //   const status = validateData();
        //   if(!status.flag) {
        //     NotificationManager.error(status.msg, 'Error!!', 5000);
        //     return;
        //   }
        // }
        API.addContentWebsite(this.props.content.tag, this.state)
          .then(function (response) {
            // console.log("response");
            // console.log(response);
            if (response.data.status) {
              that.props.onMiddleExtraButtonClick(type, that.state, null);
            } else {
            }
          })
          .catch(function (error) {
            // handle error
            NotificationManager.error(
              error.response && error.response.data
                ? error.response.data.message
                : "error occured!",
              "Error!!",
              5000
            );
            // console.log(error);
          })
          .finally(function () {
            // always executed
          });
      } else if (this.props.content.type == "delete") {
        API.deleteContentWebsite(this.props.content.tag, this.state)
          .then(function (response) {
            // console.log("response");
            // console.log(response);
            if (response.data.status) {
              that.props.onMiddleExtraButtonClick(type, that.state, null);
            } else {
            }
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }
    } else {
      this.props.onMiddleExtraButtonClick(type, null);
    }
  }

  handleChange(type, e) {
    // console.log(this.state);
    var obj = this.state;
    obj[type] = e.target.value;
    this.setState(obj);
    // console.log(e.target.value);
    // console.log(type);
    // console.log(this.state);
  }

  handleTinyMCEChange(type, e) {
    // console.log(e);
    // console.log(this.state);
    var obj = this.state;
    obj[type] = e.target.getContent();
    this.setState(obj);
    // console.log(e.target.getContent());
    // console.log(type);
    // console.log(this.state);
    // console.log(e);
  }

  handleObjectkeyChange(fieldName, keyName, e) {
    const tempObj = this.state[fieldName]
      ? JSON.parse(JSON.stringify(this.state[fieldName]))
      : {};
    tempObj[keyName] = e.target.value;
    this.setState({
      [fieldName]: tempObj,
    });
  }

  handleArraykeyChange(fieldName, keyName, index, e) {
    const tempArr = this.state[fieldName]
      ? JSON.parse(JSON.stringify(this.state[fieldName]))
      : [];
    if (!tempArr[index]) tempArr[index] = {};
    tempArr[index][keyName] = e.target.value;
    this.setState({
      [fieldName]: tempArr,
    });
  }

  deleteImage(fieldName, keyName, index) {
    if (keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      tempObj[keyName].splice(index, 1);
      this.setState({
        [fieldName]: tempObj,
      });
    } else if (!keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      tempObj.splice(index, 1);
      this.setState({
        [fieldName]: tempObj,
      });
    } else {
      this.setState({
        [fieldName]: {
          ...this.state[fieldName],
          [keyName]: null,
        },
      });
    }
  }

  onInputImage(fieldName, keyName, e, index) {
    if (keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      tempObj[keyName][index] = e.target.files[0];
      this.setState({
        [fieldName]: tempObj,
      });
    } else if (!keyName && typeof index !== "undefined") {
      const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
      tempObj[index] = e.target.files[0];
      this.setState({
        [fieldName]: tempObj,
      });
    } else {
      this.setState({
        [fieldName]: {
          ...this.state[fieldName],
          [keyName]: e.target.files[0],
        },
      });
    }
  }

  uploadImageOnServer(fieldName, keyName, file, index) {
    if (!file) {
      NotificationManager.error(
        `Please select an image first`,
        "Error!!",
        5000
      );
      return;
    }
    if (
      fieldName.includes("banner") &&
      parseFloat(file.size / 1024).toFixed(2) > 150
    ) {
      NotificationManager.error(
        `Size of banner should be less than 150kb`,
        "Error!!",
        5000
      );
      return;
    }
    // size check if required
    let params;
    params = new FormData();
    params.append("image", file);
    API.uploadAccessoriesImage(params)
      .then((response) => {
        if (response.data.data.img_url) {
          if (keyName && typeof index !== "undefined") {
            const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
            tempObj[keyName][index] = response.data.data.img_url;
            this.setState({
              [fieldName]: tempObj,
            });
          } else if (!keyName && typeof index !== "undefined") {
            const tempObj = JSON.parse(JSON.stringify(this.state[fieldName]));
            tempObj[index] = response.data.data.img_url;
            this.setState({
              [fieldName]: tempObj,
            });
          } else {
            this.setState({
              [fieldName]: {
                ...this.state[fieldName],
                [keyName]: response.data.data.img_url,
              },
            });
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(
          err.message || "Error Occured",
          "Error!!",
          5000
        );
      });
  }

  removeTestimonial = (index) => {
    if (this.state.testimonialCount === 1) {
      NotificationManager.error(
        "Atleast 1 testimonial must be present.",
        "Error",
        3000
      );
      return;
    }
    const testimonialObj = JSON.parse(
      JSON.stringify(this.state.customer_testimonials)
    );
    testimonialObj.splice(index, 1);
    this.setState({
      customer_testimonials: testimonialObj,
      testimonialCount: this.state.testimonialCount - 1,
    });
  };

  removeFAQ = (index) => {
    if (this.state.faqCount === 1) {
      NotificationManager.error(
        "Atleast 1 FAQ must be present.",
        "Error",
        3000
      );
      return;
    }
    const faqObj = JSON.parse(JSON.stringify(this.state.faq));
    faqObj.splice(index, 1);
    this.setState({
      faq: faqObj,
      faqCount: this.state.faqCount - 1,
    });
  };

  removeStoreImg = (index) => {
    if (this.state.storeImgCnt === 1) {
      NotificationManager.error(
        "Atleast 1 Store Image must be present.",
        "Error",
        3000
      );
      return;
    }
    const storeObj = JSON.parse(JSON.stringify(this.state.store_data));
    storeObj.store_images.splice(index, 1);
    this.setState({
      store_data: storeObj,
      storeImgCnt: this.state.storeImgCnt - 1,
    });
  };

  removePopularCategoryImg = (index) => {
    if (this.state.categoryCnt === 1) {
      NotificationManager.error(
        "Atleast 1 popular category Image must be present.",
        "Error",
        3000
      );
      return;
    }
    const popularCategoryObj = JSON.parse(
      JSON.stringify(this.state.popular_category_images)
    );
    popularCategoryObj.splice(index, 1);
    this.setState({
      popular_category_images: popularCategoryObj,
      categoryCnt: this.state.categoryCnt - 1,
    });
  };

  getTestimonialList() {
    let testimonialList = [];
    for (let i = 0; i < this.state.testimonialCount; i += 1) {
      testimonialList.push(
        <div className="testimonialItem extramainpanel">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Testimonial ${i + 1}`}
          </div>
          <div className="border-box clearfix footer">
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeTestimonial(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Customer Name
              </div>
              <input
                type="text"
                placeholder="Customer Name"
                value={
                  this.state.customer_testimonials[i]
                    ? this.state.customer_testimonials[i].customer_name
                    : ""
                }
                className="field border-box small"
                onChange={(e) =>
                  this.handleArraykeyChange(
                    "customer_testimonials",
                    "customer_name",
                    i,
                    e
                  )
                }
              />
            </div>
            <div className="border-box clearfix field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Heading
              </div>
              <input
                type="text"
                placeholder="Heading"
                value={
                  this.state.customer_testimonials[i]
                    ? this.state.customer_testimonials[i].heading
                    : ""
                }
                className="field border-box small"
                onChange={(e) =>
                  this.handleArraykeyChange(
                    "customer_testimonials",
                    "heading",
                    i,
                    e
                  )
                }
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Source
              </div>
              <input
                type="text"
                placeholder="source"
                value={
                  this.state.customer_testimonials[i]
                    ? this.state.customer_testimonials[i].source
                    : ""
                }
                className="field border-box small"
                onChange={(e) =>
                  this.handleArraykeyChange(
                    "customer_testimonials",
                    "source",
                    i,
                    e
                  )
                }
              />
            </div>
            <div className="border-box field2">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) =>
                  this.handleArraykeyChange(
                    "customer_testimonials",
                    "content",
                    i,
                    e
                  )
                }
              >
                {this.state.customer_testimonials[i]
                  ? this.state.customer_testimonials[i].content
                  : ""}
              </textarea>
            </div>
          </div>
        </div>
      );
    }
    return testimonialList;
  }

  getFAQList() {
    let faqList = [];
    for (let i = 0; i < this.state.faqCount; i += 1) {
      faqList.push(
        <div className="faqItem extramainpanel">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`FAQ ${i + 1}`}
          </div>
          <div className="border-box clearfix footer">
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeFAQ(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Heading
              </div>
              <input
                type="text"
                placeholder="Heading"
                value={this.state.faq[i] ? this.state.faq[i].Q : ""}
                className="field border-box small"
                onChange={(e) => this.handleArraykeyChange("faq", "Q", i, e)}
              />
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Content
              </div>
              <textarea
                className="field border-box small"
                placeholder="Content"
                onChange={(e) => this.handleArraykeyChange("faq", "A", i, e)}
              >
                {this.state.faq[i] ? this.state.faq[i].A : ""}
              </textarea>
            </div>
          </div>
        </div>
      );
    }
    return faqList;
  }

  getStoreImgList() {
    let storeImgList = [];
    for (let i = 0; i < this.state.storeImgCnt; i += 1) {
      let storeImgRef = React.createRef();
      storeImgList.push(
        <div className="storeImgItem extramainpanel">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Store Image ${i + 1}`}
          </div>
          <div className="border-box clearfix footer">
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removeStoreImg(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Image
              </div>
              {this.state.store_data &&
              this.state.store_data.store_images[i] &&
              typeof this.state.store_data.store_images[i] === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state.store_data.store_images[i]}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() =>
                      this.deleteImage("store_data", "store_images", i)
                    }
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      this.onInputImage("store_data", "store_images", e, i)
                    }
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={storeImgRef}
                      type="text"
                      placeholder="Image URL"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        storeImgRef.current.value.length &&
                        this.state.store_data.store_images[i] &&
                        typeof this.state.store_data.store_images[i] ===
                          "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      if (storeImgRef.current.value) {
                        const tempObj = JSON.parse(
                          JSON.stringify(this.state.store_data)
                        );
                        tempObj.store_images[i] = storeImgRef.current.value;
                        this.setState({
                          store_data: tempObj,
                        });
                      } else if (
                        typeof this.state.store_data.store_images[i] ===
                        "object"
                      ) {
                        this.uploadImageOnServer(
                          "store_data",
                          "store_images",
                          this.state.store_data.store_images[i],
                          i
                        );
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return storeImgList;
  }

  getPopularCategoryImgList() {
    let popularCategoryImgList = [];
    for (let i = 0; i < this.state.categoryCnt; i += 1) {
      let categoryImgRef = React.createRef();
      popularCategoryImgList.push(
        <div className="popularCategoryImgItem extramainpanel">
          <div className="border-box floatleft leftalign blackspan2 shadow-text">
            {`Category Image ${i + 1}`}
          </div>
          <div className="border-box clearfix footer">
            <div className="border-box floatright rightalign">
              <button
                class="btn btn_small btncolorprim btncard move btncloseextra"
                onClick={() => this.removePopularCategoryImg(i)}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="border-box clearfix fields">
            <div className="border-box clearfix field1">
              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                Image
              </div>
              {this.state.popular_category_images &&
              this.state.popular_category_images[i] &&
              typeof this.state.popular_category_images[i] === "string" ? (
                <div className="border-box fieldimage1">
                  <img
                    src={this.state.popular_category_images[i]}
                    height="100"
                    width="100"
                    alt="icon"
                  />
                  <br></br>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() =>
                      this.deleteImage("popular_category_images", null, i)
                    }
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <div className="border-box fieldimage1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      this.onInputImage("popular_category_images", null, e, i)
                    }
                  />
                  <div
                    className="border-box clearfix field2"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Or Enter URL
                    </div>
                    <input
                      ref={categoryImgRef}
                      type="text"
                      placeholder="Image URL"
                      className="field border-box small"
                    />
                  </div>
                  <button
                    class="btn btn_small btncolorprim btncard move btndelete"
                    onClick={() => {
                      if (
                        categoryImgRef.current.value.length &&
                        this.state.popular_category_images[i] &&
                        typeof this.state.popular_category_images[i] ===
                          "object"
                      ) {
                        NotificationManager.error(
                          "Either choose a image to upload or enter URL",
                          "Error!!",
                          5000
                        );
                        return;
                      }
                      if (categoryImgRef.current.value) {
                        const tempObj = JSON.parse(
                          JSON.stringify(this.state.popular_category_images)
                        );
                        tempObj[i] = categoryImgRef.current.value;
                        this.setState({
                          popular_category_images: tempObj,
                        });
                      } else if (
                        typeof this.state.popular_category_images[i] ===
                        "object"
                      ) {
                        this.uploadImageOnServer(
                          "popular_category_images",
                          null,
                          this.state.popular_category_images[i],
                          i
                        );
                      }
                    }}
                  >
                    Upload Icon
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return popularCategoryImgList;
  }

  componentDidMount() {
    if (this.props.content.tag === "near_me_acc") {
      API.getAllAccessoriesCategoryList()
        .then((response) => {
          localStorage.setItem(
            "accessories_categories",
            JSON.stringify(response.data.data)
          );
          let tempArray = [];
          if(this.state.popular_category_ids && this.state.popular_category_ids.length
            && this.props.content.type !== 'add'){
            tempArray = response.data.data.filter((item) => this.state.popular_category_ids.includes(item._id))
            
          // tempArray.push(item => {
          //   for(let i=0; i<=this.state.popular_category_ids.length; i++){
          //   if(item === this.props.content.content._id){
          //     return item
          //   } 
          //  }})
          }
          this.setState({
            categoriesList: response.data.data,
            popular_category: tempArray,
          });
        })
        .catch((err) => console.error(err));
      
      API.getAllAccessoriesBrandList(true)
        .then((response) => {
          localStorage.setItem(
            "accessories_brands",
            JSON.stringify(response.data.data)
          );
          this.setState({ brandList: response.data.data });
        })
        .catch((err) => console.error(err));
    }
  }

  componentDidUpdate() {
    if (
      this.state.popular_category &&
      this.state.popular_category.length &&
      !this.state.productList
    ) {
      const param = this.state.popular_category
        .map((item) => item.slug)
        .toString();
      API.getAccessoriesCategoryProducts(param)
        .then((res) => {
          if (
            res.data.data &&
            res.data.data.product_data &&
            res.data.data.product_data.length
          ) {
            let tempList = [];
            if (
              this.state.pricing_product_ids &&
              this.state.pricing_product_ids.length
              && this.props.content.type !== 'add'
            ) {
              this.state.pricing_product_ids.forEach((item) => {
                const obj = res.data.data.product_data.find(
                  (element) => element.product_id === item
                );
                if (obj) tempList.push(obj);
              });
            }
            this.setState({
              productList: res.data.data.product_data,
              selectedProductList: tempList,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          NotificationManager.error(
            err.response && err.response.data ? err.response.data.message : 'Error in getting products!',
            "Error!!",
            4000
          );
        });
    }
  }

  render() {
    var that = this;
    // console.log(that.props.content.tag);
    // console.log("type", that.props.content.type);
    if (that.props.content.type === "delete") {
      return (
        <div
          id="dashboardprimeextra"
          className="border-box bgwhite1 shadow-box"
        >
          <div className="extramainpanel border-box leftalign">
            <div className="border-box clearfix title">
              <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                Delete
              </div>
              <div className="border-box floatright rightalign">
                <button
                  class="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("close")}
                >
                  Close
                </button>
              </div>
            </div>

            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7 rightalign">
                  Are you sure you want to delete this entry?
                </div>
                {/* <input type="text" placeholder="Heading" value={this.state.heading} className="field border-box small" onChange={(e) => this.handleChange("heading", e)}/> */}
              </div>
              {/* <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                    <input type="text" placeholder="Page path" value={this.state.content} className="field border-box small" onChange={(e) => this.handleChange("content", e)}/>
                  </div> */}
            </div>
            <div className="border-box clearfix footer">
              {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
              <div className="border-box floatright rightalign">
                <button
                  class="btn btn_small btncolorprim btncard move btncloseextra"
                  onClick={() => this.handleButtonClick("save")}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      switch (this.props.content.tag) {
        case "page_path_mapping":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Content Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Content heading"
                      value={this.state.content_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("content_heading", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page path
                    </div>
                    <input
                      type="text"
                      placeholder="Page path"
                      value={this.state.page_path}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_path", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("title", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("heading", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page type
                    </div>
                    <input
                      type="text"
                      placeholder="Page type"
                      value={this.state.page_type}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_type", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Hosted on
                    </div>
                    <input
                      type="text"
                      placeholder="Page type"
                      value={this.state.hosted_on}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("hosted_on", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta description
                    </div>
                    <input
                      type="text"
                      placeholder="Meta description"
                      value={this.state.meta_description}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("meta_description", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    {/* <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Hosted on</div>
                        <input type="text" placeholder="Page type" value={this.state.hosted_on} className="field border-box small" onChange={(e) => this.handleChange("hosted_on", e)}/> */}
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Content
                    </div>
                    {/* <textarea class="field border-box small" name="commentsstartaddress" placeholder="Start address">{this.props.content.content.content}</textarea> */}
                    <Editor
                      apiKey="bsxx6zgh764x32s7aq19x52goeo0tqbo7z9l2mcagxrpijfr"
                      initialValue={this.state.content}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help",
                      }}
                      onChange={(e) => this.handleTinyMCEChange("content", e)}
                    />
                  </div>
                </div>

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        case "faq_near_me":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                {this.props.content.type == "add" ? (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Page ID
                      </div>
                      <input
                        type="text"
                        placeholder="Content heading"
                        value={this.state.page_id}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("page_id", e)}
                      />
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("heading", e)}
                    />
                  </div>
                  {/* <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                            <input type="text" placeholder="Page path" value={this.state.content} className="field border-box small" onChange={(e) => this.handleChange("content", e)}/>
                          </div> */}
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Content
                    </div>
                    {/* <input type="text" placeholder="Page type" value={this.state.page_type} className="field border-box small" onChange={(e) => this.handleChange("page_type", e)}/> */}
                    <textarea
                      className="field border-box small"
                      placeholder="Content"
                      onChange={(e) => this.handleChange("content", e)}
                    >
                      {this.state.content}
                    </textarea>
                  </div>
                  {/* <div className="border-box clearfix field2">
                            <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7"></div>
                            <input type="text" name="phone" placeholder="Phone number" className="field border-box small" onChange={(e) => this.handleChange("phone", e)}/>
                          </div> */}
                </div>

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        case "price_list":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                {this.props.content.type == "add" ? (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Page ID
                      </div>
                      <input
                        type="text"
                        placeholder="Content heading"
                        value={this.state.page_id}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("page_id", e)}
                      />
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("heading", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Service
                    </div>
                    <input
                      type="text"
                      placeholder="Service"
                      value={this.state.service}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("service", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Price
                    </div>
                    <input
                      type="text"
                      placeholder="Price"
                      value={this.state.price}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("price", e)}
                    />
                  </div>
                  {/* <div className="border-box clearfix field2">
                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Heading</div>
                                <input type="text" placeholder="Heading" value={this.state.heading} className="field border-box small" onChange={(e) => this.handleChange("heading", e)}/>
                              </div> */}
                </div>

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        case "price_comparison":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                {this.props.content.type == "add" ? (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Page ID
                      </div>
                      <input
                        type="text"
                        placeholder="Content heading"
                        value={this.state.page_id}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("page_id", e)}
                      />
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Authorized Mechanic price
                    </div>
                    <input
                      type="text"
                      placeholder="Authorized Mechanic price"
                      value={this.state.authorized_mec_price}
                      className="field border-box small"
                      onChange={(e) =>
                        this.handleChange("authorized_mec_price", e)
                      }
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Local Mechanic price
                    </div>
                    <input
                      type="text"
                      placeholder="Local Mechanic price"
                      value={this.state.local_mec_price}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("local_mec_price", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      GoMechanic price
                    </div>
                    <input
                      type="text"
                      placeholder="GoMechanic price"
                      value={this.state.go_mec_price}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("go_mec_price", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Car type
                    </div>
                    <input
                      type="text"
                      placeholder="Car type"
                      value={this.state.car_type}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("car_type", e)}
                    />
                  </div>
                </div>

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        case "testimonials":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type == "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>

                {this.props.content.type == "add" ? (
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field2">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Page ID
                      </div>
                      <input
                        type="text"
                        placeholder="Content heading"
                        value={this.state.page_id}
                        className="field border-box small"
                        onChange={(e) => this.handleChange("page_id", e)}
                      />
                    </div>
                  </div>
                ) : (
                  <React.Fragment />
                )}

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Customer Name
                    </div>
                    <input
                      type="text"
                      placeholder="Customer Name"
                      value={this.state.customer_name}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("customer_name", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Source
                    </div>
                    <input
                      type="text"
                      placeholder="Source"
                      value={this.state.source}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("source", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Garage
                    </div>
                    <input
                      type="text"
                      placeholder="Garage"
                      value={this.state.garage}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("garage", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("heading", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Content
                    </div>
                    {/* <input type="text" placeholder="Page type" value={this.state.page_type} className="field border-box small" onChange={(e) => this.handleChange("page_type", e)}/> */}
                    <textarea
                      className="field border-box small"
                      placeholder="Content"
                      onChange={(e) => this.handleChange("content", e)}
                    >
                      {this.state.content}
                    </textarea>
                  </div>
                  {/* <div className="border-box clearfix field2">
                                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7"></div>
                                    <input type="text" name="phone" placeholder="Phone number" className="field border-box small" onChange={(e) => this.handleChange("phone", e)}/>
                                  </div> */}
                </div>

                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        case "near_me_acc":
          return (
            <div
              id="dashboardprimeextra"
              className="border-box bgwhite1 shadow-box"
            >
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">
                    {this.props.content.type === "add" ? "Add" : "Edit"} details
                  </div>
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("close")}
                    >
                      Close
                    </button>
                  </div>
                </div>
                <div className="border-box fields">
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Category
                    </div>
                    {/* <input type="text" placeholder="Content heading" value={this.state.popular_category_slug} 
                    className="field border-box small" 
                    onChange={(e) => this.handleChange("popular_category_slug", e)}/> */}
                    {this.state.categoriesList && (
                      <MultipleSelection
                        options={this.state.categoriesList}
                        labelKey="name"
                        checkbox_id="_id"
                        placeholder="Category"
                        disabledItems={[]}
                        multiple
                        maxCount="2"
                        selectedOptions={this.state.popular_category}
                        onChange={(e) => {
                          this.setState({
                            popular_category: e,
                            productList: null,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className="border-box field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page path
                    </div>
                    <input
                      type="text"
                      placeholder="Page path"
                      value={this.state.url_slug}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("url_slug", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Page Title
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      value={this.state.page_title}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("page_title", e)}
                    />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("heading", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Product Heading
                    </div>
                    <input
                      type="text"
                      placeholder="Heading"
                      value={this.state.product_heading}
                      className="field border-box small"
                      onChange={(e) => this.handleChange("product_heading", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Meta Desctiption
                    </div>
                    <textarea
                      class="field border-box small"
                      placeholder="Meta description"
                      value={this.state.metadata}
                      onChange={(e) => this.handleChange("metadata", e)}
                    />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Content
                    </div>
                    <Editor
                      apiKey="bsxx6zgh764x32s7aq19x52goeo0tqbo7z9l2mcagxrpijfr"
                      initialValue={this.state.main_content}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help",
                      }}
                      onChange={(e) =>
                        this.handleTinyMCEChange("main_content", e)
                      }
                    />
                  </div>
                </div>
                <div className="border-box fields">
                  {this.state.productList ? (
                    <div className="border-box field1">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Select Products for price list
                      </div>
                      <MultipleSelection
                        options={this.state.productList}
                        labelKey="display_name"
                        checkbox_id="sku_code"
                        placeholder="Product List"
                        multiple
                        disabledItems={[]}
                        selectedOptions={this.state.selectedProductList}
                        onChange={(e) => {
                          this.setState({ selectedProductList: e });
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="border-box field1">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                      Our Partner Brands
                    </div>
                    {this.state.brandList && (
                      <MultipleSelection
                        options={this.state.brandList}
                        labelKey="name"
                        checkbox_id="name"
                        placeholder="Brands"
                        multiple
                        disabledItems={[]}
                        selectedOptions={this.state.selectedBrands}
                        onChange={(e) => {
                          this.setState({ selectedBrands: e });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  Popular Categories
                </div>
                <div className="popularCategoryContainer extramainpanel">
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            categoryCnt: this.state.categoryCnt + 1,
                          })
                        }
                      >
                        Add Image
                      </button>
                    </div>
                  </div>
                  {this.getPopularCategoryImgList()}
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  Video Details (See how it is Done)
                </div>
                <div className="videoContainer">
                  <div className="videoItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video URL
                        </div>
                        <input
                          type="text"
                          placeholder="Video URL"
                          value={this.state.video ? this.state.video.link : ""}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "link", e)
                          }
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video Heading
                        </div>
                        <input
                          type="text"
                          placeholder="Video Heading"
                          value={this.state.video ? this.state.video.text : ""}
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "text", e)
                          }
                        />
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Video Subheading
                        </div>
                        <input
                          type="text"
                          placeholder="Video Subheading"
                          value={
                            this.state.video ? this.state.video.subText : ""
                          }
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange("video", "subText", e)
                          }
                        />
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Thumbnail Icon
                        </div>
                        {this.state.video &&
                        this.state.video.thumbnail &&
                        typeof this.state.video.thumbnail === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.video.thumbnail}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("video", "thumbnail")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("video", "thumbnail", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.videoThumbnail}
                                type="text"
                                placeholder="Thumnail URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.videoThumbnail.current.value.length &&
                                  this.state.video.thumbnail &&
                                  typeof this.state.video.thumbnail === "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.videoThumbnail.current.value) {
                                  const e = {
                                    target: {
                                      value: this.videoThumbnail.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "video",
                                    "thumbnail",
                                    e
                                  );
                                } else if (
                                  typeof this.state.video.thumbnail === "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "video",
                                    "thumbnail",
                                    this.state.video.thumbnail
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  Main Banner Details
                </div>
                <div className="mainBannerContainer">
                  <div className="mainBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Desktop Banner
                        </div>
                        {this.state.banner &&
                        this.state.banner.web_banner &&
                        typeof this.state.banner.web_banner === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.banner.web_banner}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("banner", "web_banner")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("banner", "web_banner", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerWeb}
                                type="text"
                                placeholder="Banner Desktop URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.mainBannerWeb.current.value.length &&
                                  this.state.banner &&
                                  this.state.banner.web_banner &&
                                  typeof this.state.banner.web_banner ===
                                    "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerWeb.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerWeb.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "banner",
                                    "web_banner",
                                    e
                                  );
                                } else if (
                                  typeof this.state.banner.web_banner ===
                                  "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "banner",
                                    "web_banner",
                                    this.state.banner.web_banner
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Mobile Banner
                        </div>
                        {this.state.banner &&
                        this.state.banner.mobile_banner &&
                        typeof this.state.banner.mobile_banner === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.banner.mobile_banner}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("banner", "mobile_banner")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("banner", "mobile_banner", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.mainBannerMob}
                                type="text"
                                placeholder="Banner mobile URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.mainBannerMob.current.value.length &&
                                  this.state.banner &&
                                  this.state.banner.mobile_banner &&
                                  typeof this.state.banner.mobile_banner ===
                                    "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.mainBannerMob.current.value) {
                                  const e = {
                                    target: {
                                      value: this.mainBannerMob.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "banner",
                                    "mobile_banner",
                                    e
                                  );
                                } else if (
                                  typeof this.state.banner.mobile_banner ===
                                  "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "banner",
                                    "mobile_banner",
                                    this.state.banner.mobile_banner
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Redirection URL
                        </div>
                        <input
                          type="text"
                          placeholder="Video URL"
                          value={
                            this.state.banner
                              ? this.state.banner.redirect_url
                              : ""
                          }
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange(
                              "banner",
                              "redirect_url",
                              e
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  Sub Banner Details
                </div>
                <div className="subBannerContainer">
                  <div className="subBannerItem extramainpanel">
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Desktop Banner
                        </div>
                        {this.state.sub_banner &&
                        this.state.sub_banner.web_banner &&
                        typeof this.state.sub_banner.web_banner === "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.sub_banner.web_banner}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("sub_banner", "web_banner")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage("sub_banner", "web_banner", e)
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.subBannerWeb}
                                type="text"
                                placeholder="Sub Banner Desktop URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.subBannerWeb.current.value.length &&
                                  this.state.sub_banner &&
                                  this.state.sub_banner.web_banner &&
                                  typeof this.state.sub_banner.web_banner ===
                                    "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.subBannerWeb.current.value) {
                                  const e = {
                                    target: {
                                      value: this.subBannerWeb.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "sub_banner",
                                    "web_banner",
                                    e
                                  );
                                } else if (
                                  typeof this.state.sub_banner.web_banner ===
                                  "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "sub_banner",
                                    "web_banner",
                                    this.state.sub_banner.web_banner
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Mobile Banner
                        </div>
                        {this.state.sub_banner &&
                        this.state.sub_banner.mobile_banner &&
                        typeof this.state.sub_banner.mobile_banner ===
                          "string" ? (
                          <div className="border-box fieldimage1">
                            <img
                              src={this.state.sub_banner.mobile_banner}
                              height="100"
                              width="100"
                              alt="icon"
                            />
                            <br></br>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() =>
                                this.deleteImage("sub_banner", "mobile_banner")
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="border-box fieldimage1">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                this.onInputImage(
                                  "sub_banner",
                                  "mobile_banner",
                                  e
                                )
                              }
                            />
                            <div
                              className="border-box clearfix field2"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                                Or Enter URL
                              </div>
                              <input
                                ref={this.subBannerMob}
                                type="text"
                                placeholder="Sub Banner Mobile URL"
                                className="field border-box small"
                              />
                            </div>
                            <button
                              class="btn btn_small btncolorprim btncard move btndelete"
                              onClick={() => {
                                if (
                                  this.subBannerMob.current.value.length &&
                                  this.state.sub_banner &&
                                  this.state.sub_banner.mobile_banner &&
                                  typeof this.state.sub_banner.mobile_banner ===
                                    "object"
                                ) {
                                  NotificationManager.error(
                                    "Either choose a image to upload or enter URL",
                                    "Error!!",
                                    5000
                                  );
                                  return;
                                }
                                if (this.subBannerMob.current.value) {
                                  const e = {
                                    target: {
                                      value: this.subBannerMob.current.value,
                                    },
                                  };
                                  this.handleObjectkeyChange(
                                    "sub_banner",
                                    "mobile_banner",
                                    e
                                  );
                                } else if (
                                  typeof this.state.sub_banner.mobile_banner ===
                                  "object"
                                ) {
                                  this.uploadImageOnServer(
                                    "sub_banner",
                                    "mobile_banner",
                                    this.state.sub_banner.mobile_banner
                                  );
                                }
                              }}
                            >
                              Upload Icon
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-box clearfix fields">
                      <div className="border-box clearfix field2">
                        <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                          Banner Redirection URL
                        </div>
                        <input
                          type="text"
                          placeholder="Video URL"
                          value={
                            this.state.sub_banner
                              ? this.state.sub_banner.redirect_url
                              : ""
                          }
                          className="field border-box small"
                          onChange={(e) =>
                            this.handleObjectkeyChange(
                              "sub_banner",
                              "redirect_url",
                              e
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  Testimonial Details
                </div>
                <div className="testimonialContainer">
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            testimonialCount: this.state.testimonialCount + 1,
                          })
                        }
                      >
                        Add Testimonial
                      </button>
                    </div>
                  </div>
                  {this.getTestimonialList()}
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  FAQ Details
                </div>
                <div className="faqContainer">
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({ faqCount: this.state.faqCount + 1 })
                        }
                      >
                        Add FAQ
                      </button>
                    </div>
                  </div>
                  {this.getFAQList()}
                </div>
                <div className="border-box floatleft leftalign blackspan2 shadow-text">
                  Store Details
                </div>
                <div className="storeContainer extramainpanel">
                  <div className="border-box clearfix fields">
                    <div className="border-box clearfix field1">
                      <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">
                        Heading
                      </div>
                      <input
                        type="text"
                        placeholder="Heading"
                        value={
                          this.state.store_data
                            ? this.state.store_data.heading
                            : ""
                        }
                        className="field border-box small"
                        onChange={(e) =>
                          this.handleObjectkeyChange("store_data", "heading", e)
                        }
                      />
                    </div>
                  </div>
                  <div className="border-box clearfix footer">
                    <div className="border-box floatright rightalign">
                      <button
                        class="btn btn_small btncolorprim btncard move btncloseextra"
                        onClick={() =>
                          this.setState({
                            storeImgCnt: this.state.storeImgCnt + 1,
                          })
                        }
                      >
                        Add Image
                      </button>
                    </div>
                  </div>
                  {this.getStoreImgList()}
                </div>
                <div className="border-box clearfix footer">
                  <div className="border-box floatright rightalign">
                    <button
                      class="btn btn_small btncolorprim btncard move btncloseextra"
                      onClick={() => this.handleButtonClick("save")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return <React.Fragment />;
      }
    }
  }
}

export default MiddleExtra;
