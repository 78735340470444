import React from "react";
import "./base/sitebase1.css";
import Lottie from "react-lottie";
import animation from "./lottie.json";
// import "./dashboard/dashboard.css"

class Loader extends React.Component {
  constructor(props) {
    super(props);
    //    this.state = {};

    this.state = this.props;
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
      },
    };
    if (this.props.fullHeight) {
      return (
        <div
          id="dashboardchatmainright"
          className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
        >
          <table className="stdtable">
            <tr>
              <td valign="center">
                <Lottie options={defaultOptions} height={150} width={150} />
              </td>
            </tr>
          </table>
        </div>
      );
    } else {
      return (
        <div
          className="border-box bgwhite blackspan56 weight500"
          style={{ width: "100%", height: "auto" }}
        >
          <table className="stdtable">
            <tr>
              <td valign="center">
                <Lottie options={defaultOptions} height={150} width={150} />
              </td>
            </tr>
          </table>
        </div>
      );
    }
  }
}

export default Loader;
