import React from 'react'
import API from '../api'
import { NotificationManager } from 'react-notifications'
import Loader from '../loader'

class ImageUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.props.data
    if (this.props.type === "searchbysku" && this.state.images && this.state.images.length) {
      this.state.imagesIndex = this.state.images.map((item, index) => index + "");
    }
  }

  close() {
    this.props.onClose()
  }

  hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }

  deleteImage(field, index = null) {
    switch (this.props.type) {
      case 'segments':
        if (field === 'icon') {
          this.setState({ icon: null })
        } else {
          this.setState({ product_image: null })
        }
        break
      case 'categories':
        if (field === 'icon') {
          this.setState({ icon: null })
        } else if (field === 'web_category_image') {
          this.setState({ web_category_image: null })
        } else if (field === 'mobile_category_image') {
          this.setState({ mobile_category_image: null })
        } else {
          this.setState({ app_category_image: null })
        }
        break
      case 'searchbysku':
        if (field === 'thumbnail_img') {
          this.setState({ thumbnail_img: null })
        } else if (field === 'feed_img') {
          this.setState({ feed_img: null })
        } else {
          let temp = [...this.state.images]
          let temp1 = [...this.state.imagesIndex];
          temp.splice(index, 1)
          temp1.splice(index, 1);
          this.setState({ images: temp, imagesIndex: temp1 })
        }
        break
      default:
        console.log('No Action')
    }
  }

  onInputImage(field, e) {
    switch (this.props.type) {
      case 'segments':
        if (field === 'icon') {
          this.setState({ icon: e.target.files[0] })
        } else {
          this.setState({ product_image: e.target.files[0] })
        }
        break
      case 'categories':
        if (field === 'icon') {
          this.setState({ icon: e.target.files[0] })
        } else if (field === 'web_category_image') {
          this.setState({ web_category_image: e.target.files[0] })
        } else if (field === 'mobile_category_image') {
          this.setState({ mobile_category_image: e.target.files[0] })
        } else {
          this.setState({ app_category_image: e.target.files[0] })
        }
        break
      case 'searchbysku':
        if (field === 'thumbnail_img') {
          this.setState({ thumbnail_img: e.target.files[0] })
        } else if (field === 'feed_img') {
          this.setState({ feed_img: e.target.files[0] })
        } else {
          this.setState({ images_arr: e.target.files[0] })
        }
        break
      default:
        // console.log('No Action')
    }
  }

  uploadImageOnServer(type, file) {
    if (!file) {
      NotificationManager.error(
        `Please select ${
          type === 'icon'
            ? 'Icon'
            : type === 'product_image'
            ? 'Product'
            : type === 'web_category_image'
            ? 'Web Category'
            : type === 'mobile_category_image'
            ? 'Mobile Category'
            : type === 'app_category_image'
            ? 'App Category'
            : type === 'thumbnail_img'
            ? 'Product Thumbnail'
            : type === 'feed_img'
            ? 'Product Feed'
            : 'Product'
        } Image first.`,
        'Error!!',
        5000
      )
      return
    }
    if (
      (type === 'icon' && parseFloat(file.size / 1024).toFixed(2) > 20) ||
      parseFloat(file.size / 1024).toFixed(2) > 200
    ) {
      NotificationManager.error(
        `${
          type === 'icon'
            ? 'Icon'
            : type === 'product_image'
            ? 'Product'
            : type === 'web_category_image'
            ? 'Web Category'
            : type === 'mobile_category_image'
            ? 'Mobile Category'
            : type === 'app_category_image'
            ? 'App Category'
            : type === 'thumbnail_img'
            ? 'Product Thumbnail'
            : 'Product'
        } Image size should be <= ${type === 'icon' ? '20' : '200'} KB.`,
        'Error!!',
        5000
      )
      return
    }
    let params
    this.setState({ showLoader: true })
    switch (this.props.type) {
      case 'segments':
        params = new FormData()
        params.append('image', file)
        API.uploadAccessoriesImage(params, true)
          .then((response) => {
            if (type === 'icon')
              this.setState({
                icon: response.data.data.img_url,
                showLoader: false,
              })
            else
              this.setState({
                product_image: response.data.data.img_url,
                showLoader: false,
              })
          })
          .catch((err) => {
            console.error(err)
            this.setState({ showLoader: false })
          })
        break
      case 'categories':
        params = new FormData()
        params.append('image', file)
        API.uploadAccessoriesImage(params, true)
          .then((response) => {
            if (type === 'icon') {
              this.setState({
                icon: response.data.data.img_url,
                showLoader: false,
              })
            } else if (type === 'web_category_image') {
              this.setState({
                web_category_image: response.data.data.img_url,
                showLoader: false,
              })
            } else if (type === 'mobile_category_image') {
              this.setState({
                mobile_category_image: response.data.data.img_url,
                showLoader: false,
              })
            } else {
              this.setState({
                app_category_image: response.data.data.img_url,
                showLoader: false,
              })
            }
          })
          .catch((err) => {
            this.setState({ showLoader: false })
            console.error(err)
          })
        break
      case 'searchbysku':
        params = new FormData()
        params.append('image', file)
        API.uploadAccessoriesImage(params, true)
          .then((response) => {
            if (type === 'thumbnail_img') {
              this.setState({
                thumbnail_img: response.data.data.img_url,
                showLoader: false,
              })
            } else if (type === 'feed_img') {
              this.setState({
                feed_img: response.data.data.img_url,
                showLoader: false,
              })
            } else {
              const temp = this.state.images ? [...this.state.images] : []
              temp.push(response.data.data.img_url)
              const temp1 = [...this.state.imagesIndex];
              temp1.push(temp.length - 1);
              this.setState({
                images: temp,
                images_arr: null,
                imagesIndex: temp1,
                showLoader: false,
              })
            }
          })
          .catch((err) => {
            this.setState({ showLoader: false })
            console.error(err)
          })
        break
      default:
        console.log('No Action')
        this.setState({ showLoader: false })
    }
  }

  handleButtonClick() {
    let params
    switch (this.props.type) {
      case 'segments':
        params = {
          icon: this.state.icon,
          product_image: this.state.product_image,
          id: this.state._id,
        }
        API.updateAccessoriesSegment(params, true)
          .then((response) => {
            NotificationManager.success(
              'Images saved to segment',
              'Success',
              5000
            )
            this.close()
          })
          .catch((err) => {
            console.log(err)
            NotificationManager.error('Some error occured', 'Error!!', 5000)
          })
        break
      case 'categories':
        params = {
          icon: this.state.icon,
          app_category_image: this.state.app_category_image,
          mobile_category_image: this.state.mobile_category_image,
          web_category_image: this.state.web_category_image,
          id: this.state._id,
        }
        API.updateAccessoriesSegment(params, true)
          .then((response) => {
            NotificationManager.success(
              'Images saved to category',
              'Success',
              5000
            )
            this.close()
          })
          .catch((err) => {
            console.log(err)
            NotificationManager.error('Some error occured', 'Error!!', 5000)
          })
        break
      case 'searchbysku':
        let newImagesArr = [];
        if (this.state.imagesIndex && this.state.imagesIndex.length) {
          if (this.hasDuplicates(this.state.imagesIndex) || this.state.imagesIndex.includes("")){
            NotificationManager.error('Image index shouldn\'t have duplicate or empty value', 'Error!!', 5000)
            return;
          }
          newImagesArr = new Array(this.state.images.length);
          for (let i = 0; i < this.state.imagesIndex.length; i += 1 ) {
            newImagesArr[this.state.imagesIndex[i]] = this.state.images[i];
          }
        } else newImagesArr = this.state.images;
        params = {
          sku_update: {
            thumbnail_img: this.state.thumbnail_img,
            images: newImagesArr,
          },
          sku_code: this.state.sku_code,
        }
        if (this.state.feed_img && typeof this.state.feed_img === 'string') {
          params.sku_update.feed_img = this.state.feed_img;
        }
        API.updateAccessoriesProduct(params, true)
          .then((response) => {
            NotificationManager.success(
              'Images saved to Product Variant.',
              'Success',
              5000
            )
            this.close()
          })
          .catch((err) => {
            console.log(err)
            NotificationManager.error('Some error occured', 'Error!!', 5000)
          })
        break
      default:
        console.log('No case match')
    }
  }

  handleIndexChange(index, e) {
    let tempArr = [...this.state.imagesIndex];
    tempArr[index] = e.target.value;
    this.setState({
      imagesIndex: tempArr,
    });
  }

  render() {
    if (this.state.showLoader) {
      return (
        <div id='dashboardprimeextra' className='bgwhite centeralign'>
          <Loader showLoader={this.state.showLoader} />
        </div>
      )
    } else {
      const that = this
      switch (that.props.type) {
        case 'segments':
          return (
            <div
              id='dashboardprimeextra'
              className='border-box bgwhite1 shadow-box'
              style={{ margin: '5px', width: '100%' }}
            >
              <div className='extramainpanel border-box leftalign'>
                <div className='border-box clearfix title'>
                  <div className='border-box floatleft leftalign blackspan2 weight900 shadow-text'>
                    Image Upload
                  </div>
                  <div className='border-box floatright rightalign'>
                    <button
                      className='btn btn_small btncolorprim btncard move btncloseextra'
                      onClick={() => this.close()}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field2'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Icon
                    </div>
                    {this.state.icon && typeof this.state.icon === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.icon}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('icon')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) => this.onInputImage('icon', e)}
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer('icon', this.state.icon)
                          }
                        >
                          Upload Icon
                        </button>
                      </div>
                    )}
                  </div>

                  <div className='border-box clearfix field2'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Product Image
                    </div>
                    {this.state.product_image &&
                    typeof this.state.product_image === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.product_image}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('product_image')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) =>
                            this.onInputImage('product_image', e)
                          }
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer(
                              'product_image',
                              this.state.product_image
                            )
                          }
                        >
                          Upload Product Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className='border-box clearfix footer'>
                  <div className='border-box floatright rightalign'>
                    <button
                      className='btn btn_small btncolorprim btncard move btncloseextra'
                      onClick={() => this.handleButtonClick()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        case 'categories':
          return (
            <div
              id='dashboardprimeextra'
              className='border-box bgwhite1 shadow-box'
              style={{ margin: '5px', width: '100%' }}
            >
              <div className='extramainpanel border-box leftalign'>
                <div className='border-box clearfix title'>
                  <div className='border-box floatleft leftalign blackspan2 weight900 shadow-text'>
                    Image Upload
                  </div>
                  <div className='border-box floatright rightalign'>
                    <button
                      className='btn btn_small btncolorprim btncard move btncloseextra'
                      onClick={() => this.close()}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field2'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Icon
                    </div>
                    {this.state.icon && typeof this.state.icon === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.icon}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('icon')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) => this.onInputImage('icon', e)}
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer('icon', this.state.icon)
                          }
                        >
                          Upload Icon
                        </button>
                      </div>
                    )}
                  </div>

                  <div className='border-box clearfix field2'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Web Category Image
                    </div>
                    {this.state.web_category_image &&
                    typeof this.state.web_category_image === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.web_category_image}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('web_category_image')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) =>
                            this.onInputImage('web_category_image', e)
                          }
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer(
                              'web_category_image',
                              this.state.web_category_image
                            )
                          }
                        >
                          Upload Web Category Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field2'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Mobile Category Image
                    </div>
                    {this.state.mobile_category_image &&
                    typeof this.state.mobile_category_image === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.mobile_category_image}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.deleteImage('mobile_category_image')
                          }
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) =>
                            this.onInputImage('mobile_category_image', e)
                          }
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer(
                              'mobile_category_image',
                              this.state.mobile_category_image
                            )
                          }
                        >
                          Upload Mobile Category Image
                        </button>
                      </div>
                    )}
                  </div>

                  <div className='border-box clearfix field2'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      App Category Image
                    </div>
                    {this.state.app_category_image &&
                    typeof this.state.app_category_image === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.app_category_image}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('app_category_image')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) =>
                            this.onInputImage('app_category_image', e)
                          }
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer(
                              'app_category_image',
                              this.state.app_category_image
                            )
                          }
                        >
                          Upload App Category Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className='border-box clearfix footer'>
                  <div className='border-box floatright rightalign'>
                    <button
                      className='btn btn_small btncolorprim btncard move btncloseextra'
                      onClick={() => this.handleButtonClick()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        case 'searchbysku':
          return (
            <div
              id='dashboardprimeextra'
              className='border-box bgwhite1 shadow-box'
            >
              <div className='extramainpanel border-box leftalign'>
                <div className='border-box clearfix title'>
                  <div className='border-box floatleft leftalign blackspan2 weight900 shadow-text'>
                    Image Upload
                  </div>
                  <div className='border-box floatright rightalign'>
                    <button
                      className='btn btn_small btncolorprim btncard move btncloseextra'
                      onClick={() => this.close()}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field1'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Upload Product Images
                    </div>
                    <div className='border-box fieldimage1'>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={(e) => this.onInputImage('images_arr', e)}
                      />
                      <button
                        className='btn btn_small btncolorprim btncard move btndelete'
                        onClick={() =>
                          this.uploadImageOnServer(
                            'images_arr',
                            this.state.images_arr
                          )
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field1'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Product Images
                    </div>
                    {this.state.images &&
                      this.state.images.map((item, index) => (
                        <div
                          className='border-box fieldimage1'
                          style={{ display: 'inline-block', padding: '5px' }}
                        >
                          <img
                            src={item}
                            height='100'
                            width='100'
                            alt='images'
                          />
                          <br></br>
                          <input
                            type="tel"
                            maxLength="1"
                            placeholder="Index"
                            value={this.state.imagesIndex[index]}
                            className="field border-box small"
                            onChange={(e) => this.handleIndexChange(index, e)}
                          />
                          <button
                            className='btn btn_small btncolorprim btncard move btndelete'
                            onClick={() =>
                              this.deleteImage('images_arr', index)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      ))}
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field1'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Product Thumbnail Image
                    </div>
                    {this.state.thumbnail_img &&
                    typeof this.state.thumbnail_img === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.thumbnail_img}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('thumbnail_img')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) =>
                            this.onInputImage('thumbnail_img', e)
                          }
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer(
                              'thumbnail_img',
                              this.state.thumbnail_img
                            )
                          }
                        >
                          Upload Thumbnail Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className='border-box clearfix fields'>
                  <div className='border-box clearfix field1'>
                    <div className='border-box clearfix uppercased fieldtitle weight700 blackspan7'>
                      Product Feed Image
                    </div>
                    {this.state.feed_img &&
                    typeof this.state.feed_img === 'string' ? (
                      <div className='border-box fieldimage1'>
                        <img
                          src={this.state.feed_img}
                          height='100'
                          width='100'
                          alt='icon'
                        />
                        <br></br>
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() => this.deleteImage('feed_img')}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className='border-box fieldimage1'>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) =>
                            this.onInputImage('feed_img', e)
                          }
                        />
                        <button
                          className='btn btn_small btncolorprim btncard move btndelete'
                          onClick={() =>
                            this.uploadImageOnServer(
                              'feed_img',
                              this.state.feed_img
                            )
                          }
                        >
                          Upload Feed Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className='border-box clearfix footer'>
                  <div className='border-box floatright rightalign'>
                    <button
                      className='btn btn_small btncolorprim btncard move btncloseextra'
                      onClick={() => this.handleButtonClick()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        default:
          console.error('nothing to render')
      }
    }
  }
}

export default ImageUpload
