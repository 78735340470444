import React from 'react';


class Row extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onRowClick(this.props.data, this.props.tag);
    }

    render() {
        switch (this.props.tag) {
            case 'homepage':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes segments border-box clearfix leftalign blackspan9 selected"
                            : "boxes segments border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes30">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase', wordWrap: 'break-word' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes40">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.bottom_content}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'segments':

                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes segments border-box clearfix leftalign blackspan9 selected"
                            : "boxes segments border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes25">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase', wordWrap: 'break-word' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.slug}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.meta_description}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );

            case 'searchbysku':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes segments border-box clearfix leftalign blackspan9 selected"
                            : "boxes segments border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes25">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase', wordWrap: 'break-word' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.slug}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.meta_description}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );

            case 'categories':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes categories border-box clearfix leftalign blackspan9 selected"
                            : "boxes categories border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes25">
                            <table
                                className="stdtable colorprim500span" 
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.segment_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.star_category === 1 ? "Yes" : "No"}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'products':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data.product_id
                            ? "boxes products border-box clearfix leftalign blackspan9 selected"
                            : "boxes products border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data.product_id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes35">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.brand_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.category_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.segment_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.price}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'brands':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                            : "boxes orders border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes45">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes40">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.brand_code}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'collectionv2':
            case "collection":
                return (
                    <div
                        className={this.props.selectedRow === this.props.data.slug
                            ? "boxes categories border-box clearfix leftalign blackspan9 selected"
                            : "boxes categories border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes20">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes20">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.slug}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes35">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case "coupons":
                return (
                    <div
                        className={this.props.selectedRow === this.props.data.id
                            ? "boxes categories border-box clearfix leftalign blackspan9 selected"
                            : "boxes categories border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data.id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.code}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.coupon_type}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.percentage}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes20">
                            <table className="stdtable">
                                <tr><td valign="center">{new Date(this.props.data.start_time).toLocaleString()}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes20">
                            <table className="stdtable">
                                <tr><td valign="center">{new Date(this.props.data.end_time).toLocaleString()}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case "installationservice":
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes categories border-box clearfix leftalign blackspan9 selected"
                            : "boxes categories border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data.product_id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.sku_code}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes20">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.price}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.mrp}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.gst_rate}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.final_price}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'rails':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes segments border-box clearfix leftalign blackspan9 selected"
                            : "boxes segments border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes25">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase', wordWrap: 'break-word' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'feed':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes segments border-box clearfix leftalign blackspan9 selected"
                            : "boxes segments border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes25">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase', wordWrap: 'break-word' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.applicable_on.toString()}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case 'feed_product':
                return (
                    <div
                        className={"boxes products border-box clearfix leftalign blackspan9"}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes6">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data.product_id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes35">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.brand_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.category_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.segment_name}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.price}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case "enquiry":
                return (
                    <div
                    className={
                        this.props.selectedRow === this.props.data._id
                        ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                        : "boxes orders border-box clearfix leftalign blackspan9"
                    }
                    onClick={this.handleClick}
                    data-page-id={this.props.data._id}
                    data-page-type="car-accessories-enquiry"
                    data-title={this.props.data.page_title}
                    >
                    <div className="textsize12 border-box weight500 dpmboxes1">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">&#9679;</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes26">
                        <table
                        className="stdtable colorprim500span"
                        style={{ textTransform: "uppercase" }}
                        >
                        <tr>
                            <td valign="center">{this.props.data._id}</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes10">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">{this.props.data.page_title}</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes10">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">{this.props.data.created_at}</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes10">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">
                            {this.props.data.updated_at}
                            </td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes9">
                        <table className="stdtable">
                        <tr>
                            <td valign="center"></td>
                        </tr>
                        </table>
                    </div>
                    </div>
                );  
            case 'carPage':
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                            : "boxes orders border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes45">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data._id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes40">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.page_slug}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case "reviews":
                return (
                    <div
                        className={this.props.selectedRow === this.props.data._id
                            ? "boxes categories border-box clearfix leftalign blackspan9 selected"
                            : "boxes categories border-box clearfix leftalign blackspan9"}
                        onClick={this.handleClick}
                    >
                        <div className="textsize12 border-box weight500 dpm1boxes3">
                            <table className="stdtable">
                                <tr><td valign="center">&#9679;</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data.product_id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table
                                className="stdtable colorprim500span"
                                style={{ textTransform: 'uppercase' }}
                            >
                                <tr><td valign="center">{this.props.data.order_id}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes15">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.review_by}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes20">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.title}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes25">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.review}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes5">
                            <table className="stdtable">
                                <tr><td valign="center">{this.props.data.rating}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes10">
                            <table className="stdtable">
                                <tr><td valign="center">{new Date(this.props.data.review_date).toLocaleString()}</td></tr>
                            </table>
                        </div>
                        <div className="textsize12 border-box weight500 dpm1boxes2">
                            <table className="stdtable">
                                <tr><td valign="center"></td></tr>
                            </table>
                        </div>
                    </div>
                );
            case "export":
                return (
                    <div
                    className={
                        this.props.selectedRow === this.props.data._id
                        ? "boxes orders border-box clearfix leftalign blackspan9 selected"
                        : "boxes orders border-box clearfix leftalign blackspan9"
                    }
                    onClick={this.handleClick}
                    data-page-id={this.props.data._id}
                    data-page-type="car-accessories-enquiry"
                    data-title={this.props.data.page_title}
                    >
                    <div className="textsize12 border-box weight500 dpmboxes1">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">&#9679;</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes40">
                        <table
                        className="stdtable colorprim500span"
                        style={{ textTransform: "uppercase" }}
                        >
                        <tr>
                            <td valign="center">{this.props.data._id}</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes30">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">{this.props.data.page_title}</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes10">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">{this.props.data.created_at}</td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes10">
                        <table className="stdtable">
                        <tr>
                            <td valign="center">
                            {this.props.data.updated_at}
                            </td>
                        </tr>
                        </table>
                    </div>
                    <div className="textsize12 border-box weight500 dpmboxes9">
                        <table className="stdtable">
                        <tr>
                            <td valign="center"></td>
                        </tr>
                        </table>
                    </div>
                    </div>
                ); 
            default:
                return null;
        }
    }
}

export default Row;