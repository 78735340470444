import React from "react";
// import "./dashboard/dashboard.css"
import Constants from "../constants";

class PanelRight extends React.Component {
  constructor(props) {
    super(props);
  }

  handleButtonClick(type) {
    if (type === "view") {
      if (Constants.getAccessoriesBaseURL().includes("dev"))
        window.open(
          `https://dev.web.gomechanic.app/${this.props.content.url_slug}`,
          "_blank"
        );
      else
        window.open(
          `https://gomechanic.in/${this.props.content.url_slug}`,
          "_blank"
        );
      return;
    }
    this.props.onRightButtonClick(
      type,
      this.props.tag,
      this.props.selectedRow,
      this.props.content
    );
    /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
  }

  render() {
    if (!this.props.showContent) {
      return (
        <div
          id="dashboardmainright"
          className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
        >
          <table className="stdtable">
            <tr>
              <td valign="center">
                Select an item on the left to view its details.
              </td>
            </tr>
          </table>
        </div>
      );
    } else {
      switch (this.props.tag) {
        case "page_path_mapping":
          // var s = '<div></div>';
          // var temp = document.createElement('div');
          // temp.innerHTML = this.props.content.content;
          // var contentHTML = temp;
          // var statuses = JSON.parse(localStorage.getItem("statuses"));
          // var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
          // var status = "NA";
          // if(filteredstatuses.length > 0){
          //     status = filteredstatuses[0].status;
          // }
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500 rightalign"></div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.content_heading}
              </div>
              <div class="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.page_path}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.uuid}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.title}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.heading}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.content_heading}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Path
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_path}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Meta Desc
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.meta_description}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.content}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            
                            </td></tr></table></div></div></div></div></div> */}
            </div>
          );
        case "faq_near_me":
          // var s = '<div></div>';
          // var temp = document.createElement('div');
          // temp.innerHTML = this.props.content.content;
          // var contentHTML = temp;
          // var statuses = JSON.parse(localStorage.getItem("statuses"));
          // var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
          // var status = "NA";
          // if(filteredstatuses.length > 0){
          //     status = filteredstatuses[0].status;
          // }
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500 rightalign"></div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.heading}
              </div>
              <div class="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.ppm.page_path}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.uuid}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page path
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_path}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.heading}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.content}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                
                                </td></tr></table></div></div></div></div></div> */}
            </div>
          );
        case "price_list":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500 rightalign"></div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.service}
              </div>
              <div class="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.heading}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.uuid}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page path
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_path}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.heading}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Service
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.service}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.price}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                
                                </td></tr></table></div></div></div></div></div> */}
            </div>
          );
        case "price_comparison":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500 rightalign"></div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.go_mec_price}
              </div>
              <div class="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.car_type}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.uuid}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page path
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_path}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Auth Mech Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.authorized_mec_price}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Local Mech Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.local_mec_price}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                GoMech Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.go_mec_price}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Car Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.car_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                        
                                        </td></tr></table></div></div></div></div></div> */}
            </div>
          );
        case "testimonials":
          // var s = '<div></div>';
          // var temp = document.createElement('div');
          // temp.innerHTML = this.props.content.content;
          // var contentHTML = temp;
          // var statuses = JSON.parse(localStorage.getItem("statuses"));
          // var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
          // var status = "NA";
          // if(filteredstatuses.length > 0){
          //     status = filteredstatuses[0].status;
          // }
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500 rightalign"></div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.heading}
              </div>
              <div class="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.customer_name}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.uuid}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page path
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.ppm.page_path}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.heading}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.content}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Customer
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.customer_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Source
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.source}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Garage
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.garage}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                                
                                </td></tr></table></div></div></div></div></div> */}
            </div>
          );
        case "near_me_acc":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("add")}
                        >
                          Duplicate
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500 rightalign"></div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.popular_category_slug}
              </div>
              <div class="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.url_slug}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_title}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.heading}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content heading
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.popular_category_slug}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">near-me</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Path
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.url_slug}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.main_content}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return <React.Fragment />;
      }
    }
  }
}

export default PanelRight;
