import React from 'react';
import API from '../api';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
// import "./dashboard/dashboard.css"

import Constants from '../constants';

const formatDate=(date)=>{
	const year=new Date(date).getFullYear()
	const month=new Date(date).getMonth()+1
	const day=new Date(date).getUTCDate()
	return day+'/'+month+'/'+year
}
class Row extends React.Component {
	constructor(props) {
		super(props);
		let dateStart=_.get(this.props.data, ["start_time"], null)
		let dateEnd=_.get(this.props.data, ["end_time"], null)

		this.state = {
			_id: this.props.data._id ? this.props.data._id : null,
			payment_methods: this.props.data.name ? this.props.data.name : null,
			isVisible: this.props.data.isVisible,
			showDialog:false,
			couponRowObj:{
				is_disable:_.get(this.props.data, ["is_disable"], 0),
				description: _.get(this.props.data, ["description"], null),
				saving_title: _.get(this.props.data, ["saving_title"], null),  
				over_all_lmt: _.get(this.props.data, ["over_all_lmt"], null),  
				lmt_per_user: _.get(this.props.data, ["lmt_per_user"], null),
				discount_on: _.get(this.props.data, ["discount_on"], null),
				code: _.get(this.props.data, ["code"], null),
				percentage: _.get(this.props.data, ["percentage"], 0),
				/*usethis*/ max_discount_amount: _.get(
				  this.props.data,
				  ["max_discount_amount"],
				  null
				),
				// /*usethis*/ minimum_order: _.get(this.props.data, ["minimum_order"], 0),
			   cod_available: _.get(this.props.data, ["cod_available"], false), 
					  /*usethis*/cod_visible: _.get(this.props.data, ["cod_visible"], false),
				  /*usethis*/cod_enabled: _.get(this.props.data, ["cod_enabled"], false), 
			
				/*usethis minimum cart value*/ amount:
				  this.props.data && this.props.data.amount
					? _.get(this.props.data, ["amount"], 0)
					: Object.keys(_.get(this.props.data, ["cart_value_map"], 0))[0] ||
					  Object.keys(_.get(this.props.data, ["ficv_map"], 0))[0],
			 	/*usethis*/ start_time: dateStart?dateStart.slice(0,10):null,
    			/*usethis*/ end_time: dateEnd?dateEnd.slice(0,10):null,
				// applicable_per_order: 1,
				brands: _.get(this.props.data, ["sku_brand"], []).map((brand)=>({brand_name:brand})),
				coupon_type: _.get(this.props.data, ["coupon_type"], null),
				discount_type: this.props.data && _.get(this.props.data, ["discount_type"], null),
				display_name: _.get(this.props.data, ["code"], null),
				product_id:
				  this.props.data && this.props.data.discount_type==="FICV"?Object.values(_.get(this.props.data, ["ficv_map"], []))[0]:_.get(this.props.data, ["prod_map"], [])[0],
				is_display: _.get(this.props.data, ["is_display"], false),
				categories: _.get(this.props.data, ["cat_map"], []).map((category)=>({name:category})),
				// terms: null,
			  }
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleChangeForPayments = this.handleChangeForPayments.bind(this);
	}

	handleClick(e) {
		// console.log('clicking row ', this.props.data)
		this.props.onRowClick(this.props.data, this.props.tag);
		/*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
	}
	handleAgentRow=()=>{
		this.props.onRowClick(this.props.data, this.props.tag);
	}
	handleButtonClick =(type)=> {
		this.props.onRightButtonClick(
			type,
			this.props.tag,
			this.props.selectedRow,
			this.props.data
		);
	}

	handleDisableClick=()=>{
		const data = JSON.parse(JSON.stringify(this.state.couponRowObj));
		data.code = data.code.toUpperCase();
		data.display_name = data.display_name.toUpperCase();
		data.start_time = formatDate(this.state.couponRowObj.start_time);
		data.end_time=this.state.showDialog?formatDate(this.state.couponRowObj.end_time):formatDate(new Date())
		data.discount_type=this.props.data.discount_type;
		data.saving_title=this.props.data.saving_title;
		data.over_all_lmt=this.props.data.over_all_lmt
		data.lmt_per_user=this.props.data.lmt_per_user
		data.is_disable=this.state.couponRowObj.is_disable===0?1:0

		if( data.coupon_type==="FREE"){
		  data.amount=this.state.couponRowObj.amount;
		  data.product_id=this.state.couponRowObj.product_id;
		  delete data.categories
		  delete data.brands
		  delete data.percentage  
		  delete data.max_discount_amount          
	  }
	  if( data.coupon_type==="PCT"){
		 if(data.brands && data.brands.length>0){
		   data.brands=data.brands.map((brands)=> brands.brand_name)
		  delete data.categories 
		  delete data.product_id
	
		 }
		 else if(data.categories && data.categories.length>0 ){
		   data.categories=data.categories.map((category)=>category.name)
		  delete data.brands 
		  delete data.product_id
	
		 }else
		 if(data.product_id){ 
		  delete data.brands 
		  delete data.categories
		 }else
		 if(data.amount){
		  data.percentage= this.props.data.percentage
		  delete data.brands 
		  delete data.categories
		  delete data.product_id
		 }
	   }
		API.updateCoupon(data)
        .then(() => {
	
          NotificationManager.success(
            "Coupon updated successfully.",
            "Success",
            5000
          );
		  this.props.onMiddleExtraButtonClick(
			"close",
			this.state,
			this.props.selectedRow
		  );
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
          console.error(error);
        });
	}
	handleChangeForPayments(e, id, name, visible) {
		// console.log("30000", id, name, visible);
		// method.is_enabled = isVisible;
		this.setState({ isVisible: e.target.checked });
		let obj = {
			_id: id,
			method_name: name,
			is_visible: visible,
		};

		// console.log("4555"+JSON.stringify(obj));

		API.sparesPaymentToggel(obj)
			.then(function (response) {
				if (response.data.status) {
					// that.props.onMiddleExtraButtonClick(
					//     type,
					//     that.state,
					//     that.props.selectedRow
					// );
					NotificationManager.success('Updated', 'Successfully', 3000);
				} else {
				}
			})
			.catch(function (error) {
				// that.props.onMiddleExtraButtonClick(
				//     "close",
				//     that.state,
				//     that.props.selectedRow
				// );
				NotificationManager.error('An Error Occured', 'Error', 3000);
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	}

	getBrandsRows = () => {
		return (
			<div
				className={
				this.props.selectedRow === this.props.data._id
						? 'boxes products border-box clearfix leftalign blackspan9 selected'
						: 'boxes products border-box clearfix leftalign blackspan9'
						}
				onClick={this.handleClick}
			>
				<div className='textsize12 border-box weight500 dpm1boxes3'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>&#9679;</td>
						</tr>
					</table>
				</div>
				{/* <div className='textsize12 border-box weight500 dpm1boxes15'>
					<table
						className='stdtable colorprim500span'
						style={{ textTransform: 'uppercase' }}
					>
						<tr>
							<td valign='center'>{this.props.data.slug}</td>
						</tr>
					</table>
				</div> */}
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.brand_name}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.sku_brand}</td>
						</tr>
					</table>
				</div>
				{/* <div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.default_discount}</td>
						</tr>
					</table>
				</div> */}
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>
								{this.props.data.is_visible ? 'Yes' : 'No'}
							</td>
						</tr>
					</table>
				</div>
				{/* <div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_spare ? 'Yes' : 'No'}</td>
						</tr>
					</table>
				</div> */}
				{/* <div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_oem ? 'Yes' : 'No'}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes10'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_oes ? 'Yes' : 'No'}</td>
						</tr>
					</table>
				</div> */}
			</div>
		);
	};

	getVehicleRows = () => {
		return (
			<div
				className={
					this.props.selectedRow === this.props.data._id
						? 'boxes products border-box clearfix leftalign blackspan9 selected'
						: 'boxes products border-box clearfix leftalign blackspan9'
				}
				onClick={this.handleClick}
			>
				<div className='textsize12 border-box weight500 dpm1boxes3'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>&#9679;</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table
						className='stdtable colorprim500span'
						style={{ textTransform: 'uppercase' }}
					>
						<tr>
							<td valign='center'>{this.props.data.make}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table
						className='stdtable colorprim500span'
						style={{ textTransform: 'uppercase' }}
					>
						<tr>
							<td valign='center'>{this.props.data.model}</td>
						</tr>
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes35'>
					<table className='stdtable'>
					{this.props.data.icon?<img id="myImg" src={this.props.data.icon} alt="" style={{ objectFit: "contain",width: "100%",height:70,border:"none"}} />:null}
					</table>
				</div>
				<div className='textsize12 border-box weight500 dpm1boxes15'>
					<table className='stdtable'>
						<tr>
							<td valign='center'>{this.props.data.is_valid?"Yes":"No"}</td>
						</tr>
					</table>
				</div>
			</div>
		);
	};

	getCategoryRows = () => {
		if (
			_.get(this.props, ['masterTabCategory', 'subCategory'], '') === 'segment'
		) {
			return (
				<div
					className={
						this.props.selectedRow === this.props.data._id
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					style={this.props.data.icon?{height:"auto"}:{height:"70px"}}
					onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.order}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
							{this.props.data.icon?<img id="myImg" src={this.props.data.icon} alt="" style={{ objectFit: "contain",width: "100%",height:70,border:"none"}} />:null}
							</tr>
						</table>
					</div>
				</div>

			);
		} else if (
			_.get(this.props, ['masterTabCategory', 'subCategory'], '') ===
			'subCategory'
		) {
			return (
				<div
					className={
						this.props.selectedRow === this.props.data.id
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					style={this.props.data.image?{height:"auto"}:{height:"70px"}}
					onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.id}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
							{this.props.data.image?<img id="myImg" src={this.props.data.image} alt="" style={{ objectFit: "contain",width: "100%",height:70,border:"none"}} />:null}
							</tr>
						</table>
					</div>
				</div>
			);
		} else
			return (
				<div
					className={
						this.props.selectedRow === this.props.data._id
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					style={this.props.data.icon?{height:"auto"}:{height:"70px"}}
					onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.segment_name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>{this.props.data.order}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
							{this.props.data.icon?<img id="myImg" src={this.props.data.icon} alt="" style={{ objectFit: "contain",width: "100%",height:70,border:"none"}} />:null}
							</tr>
						</table>
					</div>
				{this.props.data.is_visible && 	<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									{this.props.data.is_visible ? 'Yes' : 'No'}
								</td>
							</tr>
						</table>
					</div>}
					{/* <div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center'>
									{this.props.data.updated_at
										? new Date(this.props.data.updated_at).toLocaleString()
										: 'NA'}
								</td>
							</tr>
						</table>
					</div> */}

					{/* <div className="textsize12 border-box weight500 dpm1boxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.segment_name}</td></tr></table></div>
                        <div className="textsize12 border-box weight500 dpm1boxes10"><table className="stdtable"><tr><td valign="center">{this.props.data.price}</td></tr></table></div>
                        <div className="textsize12 border-box weight500 dpm1boxes2"><table className="stdtable"><tr><td valign="center"></td></tr></table></div> */}
				</div>
			);
	};

	render() {
		// console.log(this.props.tag, "this.props ", this.props);
		switch (this.props.tag) {	
			case 'trending_banner':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.sku_code
								? 'boxes products border-box clearfix leftalign blackspan9 selected'
								: 'boxes products border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center centeralign '>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center' className='centeralign'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes20'>
							<table className='stdtable'>
								<tr>
									<td valign='center' className='centeralign' >
									<img src={this.props.data.image_url}
										className='bannerImg'
										alt='img'
										/>	
									</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center' className='centeralign'>{this.props.data.order}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center' className='centeralign'>
										{this.props.data.is_visible ? 'True' : 'False'}
									</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra" 
										onClick={()=>this.handleButtonClick('edit')}
										 >Edit</button></td>
									</tr>
								</table>
							</div>
							<div className='textsize12 border-box weight500 dpm1boxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra"
										onClick={()=>this.handleButtonClick('delete')}
										
										>Delete</button></td>
									</tr>
								</table>
							</div>
					</div>
				);
			case 'tyre_banner':
				return (
					<div
						className={
							this.props.selectedRow === this.props.data.sku_code
								? 'boxes products border-box clearfix leftalign blackspan9 selected'
								: 'boxes products border-box clearfix leftalign blackspan9'
						}
						onClick={this.handleClick}
					>
						<div className='textsize12 border-box weight500 dpm1boxes3'>
							<table className='stdtable'>
								<tr>
									<td valign='center centeralign '>&#9679;</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table
								className='stdtable colorprim500span'
								style={{ textTransform: 'uppercase' }}
							>
								<tr>
									<td valign='center' className='centeralign'>{this.props.data.name}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes20'>
							<table className='stdtable'>
								<tr>
									<td valign='center' className='centeralign' >
									<img src={this.props.data.image_url}
										className='bannerImg'
										alt='img'
										/>	
									</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center' className='centeralign'>{this.props.data.order}</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes15'>
							<table className='stdtable'>
								<tr>
									<td valign='center' className='centeralign'>
										{this.props.data.is_visible ? 'True' : 'False'}
									</td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra" 
										onClick={()=>this.handleButtonClick('edit')}
											>Edit</button></td>
									</tr>
								</table>
							</div>
							<div className='textsize12 border-box weight500 dpm1boxes8'>
								<table className='stdtable'>
									<tr>
										<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra"
										onClick={()=>this.handleButtonClick('delete')}
										
										>Delete</button></td>
									</tr>
								</table>
							</div>
					</div>
				);
			
			case 'main_banner':
			return (
				<div
					className={
						this.props.selectedRow === this.props.data.sku_code
							? 'boxes products border-box clearfix leftalign blackspan9 selected'
							: 'boxes products border-box clearfix leftalign blackspan9'
					}
					onClick={this.handleClick}
				>
					<div className='textsize12 border-box weight500 dpm1boxes3'>
						<table className='stdtable'>
							<tr>
								<td valign='center centeralign '>&#9679;</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table
							className='stdtable colorprim500span'
							style={{ textTransform: 'uppercase' }}
						>
							<tr>
								<td valign='center' className='centeralign'>{this.props.data.name}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes20'>
						<table className='stdtable'>
							<tr>
								<td valign='center' className='centeralign' >
								<img src={this.props.data.image_url}
									className='bannerImg'
									alt='img'
									/>	
								</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center' className='centeralign'>{this.props.data.order}</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes15'>
						<table className='stdtable'>
							<tr>
								<td valign='center' className='centeralign'>
									{this.props.data.is_visible ? 'True' : 'False'}
								</td>
							</tr>
						</table>
					</div>
					<div className='textsize12 border-box weight500 dpm1boxes8'>
							<table className='stdtable'>
								<tr>
									<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra" 
									onClick={()=>this.handleButtonClick('edit')}
										>Edit</button></td>
								</tr>
							</table>
						</div>
						<div className='textsize12 border-box weight500 dpm1boxes8'>
							<table className='stdtable'>
								<tr>
									<td valign='center'><button className="btn btn_small btncolorprim btncard move btncloseextra"
									onClick={()=>this.handleButtonClick('delete')}
									
									>Delete</button></td>
								</tr>
							</table>
						</div>
				</div>
			);

			default:
				return null;
		}
	}
}

export default Row;
