import React from "react";
import { NotificationManager } from "react-notifications";
import API from "../api";
import constants from "../constants";

class PanelRight extends React.Component {
  handleButtonClick(type) {
    if (type === "view") {
      const getUrlParam = () => {
        switch(this.props.tag) {
          case 'brands':
            return 'brands/';
          case 'collection':
            return 'collections/';
          case 'collectionv2':
            return 'collectionsv2/';
          case 'enquiry':
            return 'car-accessories-enquiry';
          case 'export':
            return 'export';
          default:
            return '';
        }
      };
      if (constants.getAccessoriesBaseURL().includes("dev"))
        window.open(
          `https://dev.web.gomechanic.app/accessories/${getUrlParam()}${this.props.content.slug || this.props.content.page_slug || ''}`,
          "_blank"
        );
      else
        window.open(
          `https://gomechanic.in/accessories/${getUrlParam()}${this.props.content.slug || this.props.content.page_slug || ''}`,
          "_blank"
        );
      return;
    } else if (type === "viewSKU") {
      if (constants.getAccessoriesBaseURL().includes("dev"))
        window.open(
          `https://dev.web.gomechanic.app/accessories/${this.props.content.slug}-${this.props.content.product_id}`,
          "_blank"
        );
      else
        window.open(
          `https://gomechanic.in/accessories/${this.props.content.slug}-${this.props.content.product_id}`,
          "_blank"
        );
      return;
    }
    this.props.onRightButtonClick(
      type,
      this.props.tag,
      this.props.selectedRow,
      this.props.content
    );
  }

  deleteCoupon(code) {
    if (window.confirm("Do you really want to delete this coupon?")) {
      API.deleteAccessoriesCoupon({ code: code })
        .then(() => {
          NotificationManager.success(
            "Coupon Deleted successfully",
            "Success",
            5000
          );
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
        });
    }
  }

  deleteRails(id) {
    if (window.confirm("Do you really want to delete this rails?")) {
      API.deleteAccessoriesRails({ id: id })
        .then(() => {
          NotificationManager.success(
            "Rails Deleted successfully",
            "Success",
            5000
          );
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
        });
    }
  }

  deleteInstallationService(skuCode) {
    if (window.confirm("Do you really want to delete this service?")) {
      API.deleteAccProductInstallationService({ sku_code: skuCode })
        .then(() => {
          NotificationManager.success(
            "Installation Service Deleted successfully",
            "Success",
            5000
          );
        })
        .catch((error) => {
          NotificationManager.error(
            error.response && error.response.data
              ? error.response.data.message
              : "error occured!",
            "Error!!",
            5000
          );
        });
    }
  }

  render() {
    if (!this.props.showContent) {
      return (
        <div
          id="dashboardmainright"
          className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
        >
          <table className="stdtable">
            <tr>
              <td valign="center">
                Select an item on the left to view its details.
              </td>
            </tr>
          </table>
        </div>
      );
    } else {
      switch (this.props.tag) {
        case "homepage":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                Home Page
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Content
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.bottom_content}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "segments":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("editimages")}
                        >
                          Add/Edit Images
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.name}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.page_title}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Slug
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.slug}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Meta Description
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.meta_description}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_title}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "categories":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("editimages")}
                        >
                          Add/Edit Images
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.name}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.page_title}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Segment ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.segment_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Segment Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.segment_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Star Category
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.star_category === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Slug
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.slug}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Meta Description
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.meta_description}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_title}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Google Category ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.google_category_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Variable Fields
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variable_fields}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "products":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons"></div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.display_name}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.brand_name}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.product_id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.title}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Display Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.display_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Slug
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.slug}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.price}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>MRP</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.mrp}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Currency
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.currency}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Segment ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.segment_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Segment Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.segment_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Category ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.category_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Category Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.category_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Brand ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.brand_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Brand Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.brand_name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Car Dependent
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.car_dependent === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Images
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.images}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );

        case "searchbysku":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditimagesright"
                          onClick={() => this.handleButtonClick("editimages")}
                        >
                          Add/Edit images
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("viewSKU")}
                        >
                          View Page
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() =>
                            this.handleButtonClick("installationService")
                          }
                        >
                          Ins. Service
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-box blackspan9 weight500">
                  <button
                    className="btn btn_small btncolorprim btncard move btndeleteright"
                    onClick={() => this.handleButtonClick("addRelated")}
                  >
                    Add/Edit Related Products
                  </button>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.variants.name}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.variants.brand_name}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                SKU Code
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>
                                {this.props.content.variants.sku_code}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                UPC Code
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.upc_code}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.name}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Compatibility
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.compatibility}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.price}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>MRP</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.mrp}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                GST Rate
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.gst_rate}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Color
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.color}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Availability Status
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.variants.availability_status}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Dimensions
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {/* {this.props.content.variants.dimensions} */}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "brands":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("delete")}
                        >
                          Delete
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.brand_name}
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Brand Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Brand Code
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.brand_code}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "collectionv2":
        case "collection":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.name}
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Slug
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.slug}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_title}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Description
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_description}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "coupons":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() =>
                            this.deleteCoupon(this.props.content.code)
                          }
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.code}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.description}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Coupon Code
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.code}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Percentage / Amount
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.percentage}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.coupon_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Max Discount Amount
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.max_discount_amount}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Minimum Order
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.minimum_order}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Discount Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.discount_type}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Discount On
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.discount_on}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Start Time
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.start_time
                                ? new Date(
                                    this.props.content.start_time
                                  ).toLocaleString()
                                : null}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                End Time
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.end_time
                                ? new Date(
                                    this.props.content.end_time
                                  ).toLocaleString()
                                : null}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "installationservice":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() =>
                            this.deleteInstallationService(
                              this.props.content.sku_code
                            )
                          }
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.name}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.sku_code}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Prouct ID
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content.product_id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Service SKU
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.sku_code}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Service Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.price}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>MRP</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.mrp}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                GST Rate
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.gst_rate}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Final Price
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.final_price}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "rails":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() =>
                            this.deleteRails(this.props.content._id)
                          }
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.name}
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Products: (ID-Title)
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <ul
                                style={{
                                  marginTop: "0",
                                  paddingLeft: "0",
                                  listStyle: "none",
                                }}
                              >
                                {this.props.content.products.map((item) => (
                                  <li style={{ marginBottom: "5px" }}>
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "black",
                                      }}
                                    >
                                      {`${item.product_id}- `}
                                    </span>
                                    {item.title}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "feed":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                Custom Feed
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Type
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.applicable_on.toString()}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "enquiry":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                Accessories Enquiry Page
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.page_title}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Created At
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.created_at}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Updated At
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.updated_at}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "carPage":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.brand_name + ' ' + (this.props.content.model_name || '')}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.page_title}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Name
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.brand_name + ' ' + (this.props.content.model_name || '')}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Slug
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.page_slug}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Meta Description
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.meta_description}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.page_title}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "reviews":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                {this.props.content.review_by}
              </div>
              <div className="border-box clearfix name blackspan65 leftalign weight500">
                <span style={{ textTransform: "none" }}>
                  {this.props.content.title}
                </span>
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Review By
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.review_by}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Product Id
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.product_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Review
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.review}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Rating
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.rating}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Review Date
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.review_date}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Order Id
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.order_id}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Is Visible
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {typeof(this.props.content.is_visible) !== 'undefined'
                                ? this.props.content.is_visible ? 'Yes' : 'No'
                                : '-'
                              }
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );        
        case "export":
          return (
            <div
              id="dashboardmainright"
              className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box"
            >
              <div className="border-box clearfix buttons">
                <div className="border-box blackspan9 weight500">
                  <table className="stdtable">
                    <tr>
                      <td valign="center">
                        <button
                          className="btn btn_small btncolorprim btncard move btneditright"
                          onClick={() => this.handleButtonClick("edit")}
                        >
                          Edit
                        </button>
                      </td>
                      <td valign="center">
                        <button
                          class="btn btn_small btncolorprim btncard move btndeleteright"
                          onClick={() => this.handleButtonClick("view")}
                        >
                          View Page
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="border-box clearfix prime blackspan4 leftalign weight700">
                Accessories Export Page
              </div>
              <div className="border-box clearfix details blackspan65 leftalign weight300">
                <div className="border-box clearfix blackspan56 leftalign weight500">
                  Details
                </div>
                <div className="border-box clearfix">
                  <div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>ID</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span>{this.props.content._id}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Page Title
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">{this.props.content.page_title}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Created At
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.created_at}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="border-box clearfix">
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              <span style={{ textTransform: "none" }}>
                                Updated At
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="border-box inner verticalTopalign">
                        <table className="stdtable">
                          <tr>
                            <td valign="center">
                              {this.props.content.updated_at}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return <React.Fragment />;
      }
    }
  }
}

export default PanelRight;
