import React from 'react';
// import "./dashboard/dashboard.css"
import Constants from "../constants";

class PanelRight extends React.Component {
    constructor(props) {
      super(props);
    }

    handleButtonClick(type) {
        // console.log("clickingXXX")
        this.props.onRightButtonClick(type, this.props.tag, this.props.selectedRow, this.props.content);
        /*this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));*/
    }
  
    render() {
        // console.log("lol", this.props.tag, this.props.content);
        if(!this.props.showContent){
            return (
                <div id="dashboardmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
                    <table className="stdtable"><tr><td valign="center">
                        Select an item on the left to view its details.
                    </td></tr></table>
                </div>
            );
        }else{
            // console.log(this.props.tag, this.props.content);
            switch(this.props.tag){
                case 'policy':
                        // var s = '<div></div>';
                        // var temp = document.createElement('div');
                        // temp.innerHTML = this.props.content.content;
                        // var contentHTML = temp;
                        // var statuses = JSON.parse(localStorage.getItem("statuses"));
                        // var filteredstatuses = statuses.filter(o => (o.status_type == this.props.content.order_type && o.id == this.props.content.status_id));
                        // var status = "NA";
                        // if(filteredstatuses.length > 0){
                        //     status = filteredstatuses[0].status;
                        // }
                    return (
                        <div id="dashboardmainright" className="rightmainpanel border-box bgwhite blackspan56 weight500 shadow-box">
                            <div className="border-box clearfix buttons"><div className="border-box blackspan9 weight500"><table className="stdtable"><tr>
                                {/* <td valign="center">
                                    <button class="btn btn_small btncolorprim btncard move btneditright" onClick={() => this.handleButtonClick("edit")}>Edit</button>
                                </td>
                                <td valign="center">
                                    <button class="btn btn_small btncolorprim btncard move btndeleteright" onClick={() => this.handleButtonClick("delete")}>Delete</button>
                                </td> */}
                            </tr></table></div>
                            <div className="border-box blackspan9 weight500 rightalign">
                            
                            </div></div>
                            <div className="border-box clearfix prime blackspan4 leftalign weight700">{this.props.content.data.insurance_company}</div>
                            {/* <div class="border-box clearfix name blackspan65 leftalign weight500"><span style={{textTransform:'none'}}>{this.props.content.page_title}</span></div> */}
                            <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            <span style={{textTransform:'none'}}>Status</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span>
                            {this.props.content.data.extraction}
                            </span></td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Message</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.message}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Policy Inception Date</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.data.policy_inception_date ? Constants.get_DateStringX1(this.props.content.data.policy_inception_date) : ""}
                            </td></tr></table></div></div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Policty Expiry Date</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            {this.props.content.data.policy_expiry_date ? Constants.get_DateStringX1(this.props.content.data.policy_expiry_date) : ""}
                            </td></tr></table></div></div></div></div></div>
                            
                            {/* <div className="border-box clearfix details blackspan65 leftalign weight300"><div className="border-box clearfix blackspan56 leftalign weight500">Person Details</div><div className="border-box clearfix"><div><div className="border-box clearfix"><div className="border-box inner"><table className="stdtable"><tr><td valign="center"><span style={{textTransform:'none'}}>Name</span></td></tr></table></div><div className="border-box inner"><table className="stdtable"><tr><td valign="center">
                            
                            </td></tr></table></div></div></div></div></div> */}
                        </div>
                    );
                default:
                    return (<React.Fragment />)
            }      
        }
    }
  }

export default PanelRight;