import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Toast.css'

const Toast = (props) => {
    const [showToastClass, setShowToastClass] = useState('')
    const { toastData } = props

    useEffect(() => {
        toggleToast()
        // console.log("Toast")
    }, [toastData]);

    const toggleToast = () => {
        setShowToastClass('show')
        setTimeout(() => setShowToastClass(''), 3000)
    }

    const messageBackground = () => toastData.status === 200 || toastData.status == true ? 'success' : 'error'

    if (toastData.message) {
        return (<div id='snackbar' className={`${showToastClass} ${messageBackground()}`}>{toastData.message}</div>);
    }
    else return null
}


export default withRouter(connect((state) => {
    const { toastData } = state
    return {
        toastData,
    }
}, {
})(Toast))