import React from 'react'
import "../dashboard/dashboard.css";
import $ from "jquery";
import _ from "lodash";

import PanelLeft from "./leftpanel";
import PanelRight from "./rightpanel";
import Row from "./row";
import FilterBox from "./filterbox";
import MiddleUpper from "./Middleupper";
import MiddleMain from "./middlemain";
import MiddleExtra from "./middleextra";
import Loader from "../loader";

import API from "../api";
import Constants from "../constants";
import { Redirect } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

const axios = require("axios");
// const tabList = [{tag:"content_website" , title : "Website content"}, {tag:"support_chat" , title : "Support Chat"}, {tag:"orders" , title : "Orders"}, {tag:"fleets" , title : "Fleets"}, {tag:"users" , title : "Users"}, {tag:"services" , title : "Services"}, {tag:"packages" , title : "Packages"}, {tag:"deals" , title : "Deals"}, {tag:"cars" , title : "Cars"}, {tag:"invoices" , title : "Invoices"}, {tag:"garages" , title : "Garages"}, {tag:"crs" , title : "CRs"}, {tag:"bulkorders" , title : "Bulk Orders"}, {tag:"addresses" , title : "Addresses"}];

class PanelMiddle extends React.Component {
	constructor(props) {
		super(props);
		//    this.state = {};
	}

	render() {
		// console.log("banner_test"+JSON.stringify(this.props));
		if (this.props.showLoader) {
			// if(true){
			return (
				<div id='dashboardmainmiddleloader' className='bgwhite centeralign'>
					<Loader showLoader={this.props.showLoader} />
				</div>
			);
		} else {
			console.log('sparesContent', this.props.middleExtraContent);
			if (this.props.extraContent) {
				return (
					<div id='dashboardmainmiddle' className='bgwhite leftalign'>
						<div id='dashboardprime' className='leftalign'>
							<MiddleExtra
								masterTabCategory={this.props.masterTabCategory}
								content={this.props.extraContent}
								onMiddleExtraButtonClick={this.props.onMiddleExtraButtonClick}
								selectedRow={this.props.selectedRow}
								extraData={this.props.rightContent}
							/>
							<FilterBox
								tag={this.props.tag}
								filterShowing={this.props.filterShowing}
								onFilterClick={this.props.onFilterClick}
								onSearchClick={this.props.onSearchClick}
								filterArgs={this.props.filterArgs}
							/>
							<MiddleUpper
								updateMasterTabCategory={this.props.updateMasterTabCategory}
								masterTabCategory={this.props.masterTabCategory}
								masterContent={this.props.masterContent}
								tag={this.props.tag}
								onFilterClick={this.props.onFilterClick}
								onAddClick={this.props.onAddClick}
								showRows={this.props.showRows}
								seeLoader={this.props.seeLoader}
								getFinal={this.props.getFinal}
								getHomepageHeadings={this.props.getHomepageHeadings}
							/>
							{this.props.showContent ? (
								<>
								<MiddleMain
									masterTabCategory={this.props.masterTabCategory}
									masterContent={this.props.masterContent}
									tag={this.props.tag}
									selectedRow={this.props.selectedRow}
									content={this.props.content}
									showContent={this.props.showContent}
									onRowClick={this.props.onRowClick}
								/>
								</>
							) : (
								<React.Fragment />
							)}
						</div>
					</div>
				);
			} else {
				
				return (
					<div
						id='dashboardmainmiddle'
						className={`${
							['HOMEPAGE','PRODUCT','SEGMENT','BRAND','CATEGORY','SUBCATEGORY','VEHICLE','STORE'].includes(this.props.tag) && 'full-width'
						} bgwhite leftalign`}
					>
						<div id='dashboardprime' className='leftalign'>
							<FilterBox
								tag={this.props.tag}
								filterShowing={this.props.filterShowing}
								onFilterClick={this.props.onFilterClick}
								onSearchClick={this.props.onSearchClick}
								filterArgs={this.props.filterArgs}
							/>
							<MiddleUpper
								updateMasterTabCategory={this.props.updateMasterTabCategory}
								masterTabCategory={this.props.masterTabCategory}
								masterContent={this.props.masterContent}
								tag={this.props.tag}
								onFilterClick={this.props.onFilterClick}
								onAddClick={this.props.onAddClick}
								onSearch1Click={this.props.onSearch1Click}
								onAddNewVariant={this.props.onAddNewVariant}
								handleAddClickSpares={this.props.handleAddClickSpares}
								// changeStateBanner={this.props.changeStateBanner}
								showRows={this.props.showRows}
								filterFunc={this.props.filterSegment}
								content={this.props.content}
								finalContent={this.props.finalContent}
								homepageHeadings={this.props.homepageHeadings}
								getFinal={this.props.getFinal}
								getHomepageHeadings={this.props.getHomepageHeadings}
								filterCategory={this.props.filterCategory}
								category={this.props.category}
								onCombinationDiscount={this.props.onCombinationDiscount}
								showCombinationDiscount={this.props.showCombinationDiscount}
								homepageHeadings={this.props.homepageHeadings}
								getHomepageHeadings={this.props.getHomepageHeadings}
							/>
							{this.props.showContent &&
							!['epc', 'epcc', 'upload', 'discount',].includes(
								this.props.tag
							) ? (
								<>
								<MiddleMain
									masterTabCategory={this.props.masterTabCategory}
									masterContent={this.props.masterContent}
									tag={this.props.tag}
									selectedRow={this.props.selectedRow}
									content={this.props.content}
									showContent={this.props.showContent}
									onRowClick={this.props.onRowClick}
								/>
								</>
							) : (
								<React.Fragment />
							)}
						</div>
					</div>
				);
			}
		}
	}
}

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			masterTabCategory: { category: 'brands_spares', subCategory: null },
			status: 'logged in',
			rightContent: NaN,
			middleContent: NaN,
			showMiddleContent: false,
			finalContent:null,
			homepage_headings:null,
			showRightContent: false,
			homepage_headings:null,
			middleExtraContent: NaN,
			selectedTab: "SEGMENT",
			selectedRow: '',
			filter_showing: false,
			filter_args: undefined,
			showCombinationDiscount: false,
			combinationCategories: [],
			combinationBrands: [],
			pincodeSummary: {},
		};

		this.handleTabClick = this.handleTabClick.bind(this);
		// this.seeLoader = this.seeLoader.bind(this);

		// this.showLoader=this.showLoader.bind(this)
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleFilterClick = this.handleFilterClick.bind(this);
		// this.handleAddClick = this.handleAddClick.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleSearch1Click = this.handleSearch1Click.bind(this);
		// this.handleAddNewVariant = this.handleAddNewVariant.bind(this);
		this.handleRightButtonClick = this.handleRightButtonClick.bind(this);
		this.handleMiddleExtraButtonClick =
			this.handleMiddleExtraButtonClick.bind(this);
		this.handleAddClickSpares = this.handleAddClickSpares.bind(this);
		this.changeStateBanner = this.changeStateBanner.bind(this);
		this.showRows=this.showRows.bind(this)
		this.getFinal=this.getFinal.bind(this)
		this.getHomepageHeadings=this.getHomepageHeadings.bind(this)
		this.filterSegment = this.filterSegment.bind(this);
		this.getHomepageHeadings=this.getHomepageHeadings.bind(this)
		// this.handleCombinationDiscount = this.handleCombinationDiscount.bind(this);
	}
	// handleCombinationDiscount(showComboDiscount) {
	// 	this.setState({ showCombinationDiscount: showComboDiscount }, () =>
	// 		console.log('155 ' + this.state.showCombinationDiscount)
	// 	);
	// 	if (!this.state.showCombinationDiscount) {
	// 		axios
	// 			.get(Constants.getSparesBaseURL() + 'combination-discounts/')
	// 			.then((data) => {
	// 				const categories = data.data.data['categories'].map((category) => {
	// 					if (category) return category;
	// 				});
	// 				const brands = data.data.data['brands'].map((brand) => {
	// 					if (brand) return brand;
	// 				});
	// 				this.setState(
	// 					{ combinationCategories: categories, combinationBrands: brands },
	// 					() => {
	// 						console.log('dipesh' + this.state.combinationCategories);
	// 					}
	// 				);
	// 			});
	// 	}
	// }
	handleFilterClick() {
		this.setState({ filter_showing: !this.state.filter_showing });
	}

	handleAddClick(tag) {
		this.setState({
			middleExtraContent: {
				type: 'add',
				tag: tag,
				row: null,
				content: null,
			},
		});
	}

	handleSearchClick(args) {
		this.setState({
			filter_args: args,
			filter_showing: !this.state.filter_showing,
		});
		API.tabSearchCall(this.state.selectedTab, this, args);
		//this.setState({filter_showing : !this.state.filter_showing});
	}

	handleSearch1Click(text, text1) {
		// this.setState({ showLoader: true });
		API.tabSearch1Call(
			this.state.selectedTab,
			this,
			text,
			text1,
			this.state.masterTabCategory.category
		);
		// this.setState({selectedRow : data.order_id, showRightContent : true, rightContent : data});
	}

	handleAddNewVariant(tag, make, model) {
		this.setState({
			middleExtraContent: {
				type: 'add',
				tag: tag,
				row: null,
				sourceType: 'other',
				content: [
					{
						make: make,
						model: model,
					},
				],
			},
		});
	}
	changeStateBanner(tag, that, source) {
		API.tabClickCallSpares(tag, this, source);
	}

	handleRowClick(data, tag) {
		// if(tag == "page_path_mapping")
		// 	this.setState({selectedRow : data.id, showRightContent : true, rightContent : data});
		// else
		console.log('tag ', tag);
		if (tag == 'products') {
			API.tabSearch1Call(this.state.selectedTab, this, data.product_id);
			this.setState({
				selectedRow: data.product_id,
				showRightContent: true,
				rightContent: data,
			});
		}
		else if (tag === 'HOMEPAGE') {
			this.setState({
				selectedRow: data.id,
				showRightContent: true,
				rightContent: data,
			});
		}
		 else if (tag === 'delivery') {
			API.tabSearch1Call(this.state.selectedTab, this, data.pincode);
			this.setState({
				selectedRow: data.pincode,
				showRightContent: true,
			});
		} else if (tag == 'categories_spares') {
			// API.tabSearch1Call(this.state.selectedTab, this, data._id)
			this.setState({
				selectedRow: data._id,
				showRightContent: true,
				rightContent: data,
			});
		} else {
			this.setState({
				selectedRow: data._id,
				showRightContent: true,
				rightContent: data,
			});
		}
	}

	handleAddClickSpares(tag, source) {
		this.setState({
			middleExtraContent: {
				type: 'add',
				tag: tag,
				source: source,
				row: null,
				content: null,
			},
		});
	}

	handleRightButtonClick(type, tag, row, content) {
		this.setState({
			middleExtraContent: {
				type: type,
				tag: tag,
				row: row,
				content: content,
			},
		});
	}

	handleMiddleExtraButtonClick(type, content, row) {
		// console.log(type, tag, row, content);
		if (type == 'save') {
			// var middleContent = this.state.middleContent;
			// let obj = middleContent.find(o => o.id === row);
			// if(obj != null){
			// 	var indexObj = middleContent.indexOf(obj);

			// 	middleContent[indexObj] = content;
			// }

			// this.setState({middleExtraContent: NaN, middleContent : middleContent, rightContent: content});

			this.setState(
				{
					showMiddleContent: false,
					showRightContent: false,
					rightContent: NaN,
					middleExtraContent: NaN,
					showLoader: false,
				},
				() => {
					API.tabClickCallSpares(
						this.state.selectedTab,
						this,
						this.state.masterTabCategory
					);
				}
			);
		} else {
			this.setState(
				{
					middleExtraContent: NaN,
					showRightContent: false,
					showMiddleContent: false,
					showLoader: false,
				},
				() => {
					API.tabClickCallSpares(
						this.state.selectedTab,
						this,
						this.state.masterTabCategory
					);
				}
			);
		}
	}
	showRows(show){
		this.setState({showMiddleContent : show});
	}
	seeLoader(){
		this.setState({showLoader : false});
		
	}
	handleTabClick(tag) {
		
		var that = this;

		that.setState({
			selectedTab: tag,
			showMiddleContent: false,
			showRightContent: false,
			rightContent: NaN,
			showLoader: true,
			middleContent: null,
			middleExtraContent: null,
		});

		API.tabClickCallContent(tag, that);

	}
	// used arow function to update and render the state change in finalContent
	 getFinal=()=>{
			API.getContent().then( (response) =>{
				this.setState({
					showMiddleContent: false,
					finalContent: response.data.data,
				});
			})
			.catch(function (error) {
				// handle error
				// console.log(error)
			})
			.finally(function () {
				// always executed
			});
		
	}
	getHomepageHeadings=()=>{
		var that=this
		API.getHomepageHeadings().then(function (response) {
			let res=[response.data.data['headings']][0]
			that.setState({...that.state,
				showMiddleContent: false,
				homepage_headings: res,
			});
		})
		.catch(function (error) {
			NotificationManager.error(`${error.message? error.message:`Something went wrong, Please try again later`}`, 'Error', 3000)
		})
		.finally(function () {
			// always executed
		});
	}

	getHomepageHeadings=()=>{
		var that=this
		API.getHomepageHeadings().then(function (response) {
			let res=[response.data.data['headings']][0]
			that.setState({...that.state,
				showMiddleContent: false,
				homepage_headings: res,
			});
		})
		.catch(function (error) {
			NotificationManager.error(`${error.message? error.message:`Something went wrong, Please try again later`}`, 'Error', 3000)
		})
		.finally(function () {
			// always executed
		});
	}

	componentWillMount() {
		var that=this
		API.getContent().then(function (response) {
			
			that.setState({...that.state,
				showMiddleContent: false,
				finalContent: response.data.data ,
				// showLoader:false
			});
		})
		.catch(function (error) {
			NotificationManager.error(`${error.message? error.message:`Something went wrong, Please try again later`}`, 'Error', 3000)
			// handle error
			// console.log(error)
		})
		.finally(function () {
			// always executed
		});

		API.getHomepageHeadings().then(function (response) {
			let res=[response.data.data['headings']][0]
			that.setState({...that.state,
				showMiddleContent: false,
				homepage_headings: res,
			});
		})
		.catch(function (error) {
			// handle error
			// console.log(error)
		})
		.finally(function () {
			// always executed
		});

		if (localStorage.getItem('access_token') === null) {
			this.setState({ status: 'not logged in' });
		} else {
			var that = this;
			that.setState({ showLoader: true });
			if (
				localStorage.getItem('statuses') === null ||
				localStorage.getItem('fleets_summary') === null ||
				localStorage.getItem('garages_summary') === null
			) {
				API.getStatuses({
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				})
					.then(function (response) {
						Constants.setStatuses(response.data.data);
						API.get_fleets_summary1({
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('access_token'),
							},
						})
							.then(function (response) {
								Constants.setFleetsSummary(response.data.data);
								API.get_garages_summary1({
									headers: {
										Authorization:
											'Bearer ' + localStorage.getItem('access_token'),
									},
								})
									.then(function (response) {
										Constants.setGaragesSummary(response.data.data);
										API.tabClickCallSpares(that.state.selectedTab, that);
										that.setState({ showLoader: false });
									})
									.catch(function (error) {
										// handle error
										console.log(error);
									})
									.finally(function () {
										// always executed
									});
							})
							.catch(function (error) {
								// handle error
								console.log(error);
							})
							.finally(function () {
								// always executed
							});
					})
					.catch(function (error) {
						// handle error
						console.log(error);
					})
					.finally(function () {
						// always executed
					});
			} else {
				API.tabClickCallSpares(
					that.state.selectedTab,
					that,
					this.state.masterTabCategory
				);
				that.setState({ showLoader: false });
			}
		}
	}

	componentDidMount() {}

	componentWillUnmount() {}

	filterSegment(searchKey, type) {
		if (searchKey) {
			switch (type) {
				case 'brands_spares':
					API.searchSparesBrand(searchKey)
						.then((response) => {
							this.setState({
								middleContent: response.data || [],
								masterContent: response.data || [],
							});
						})
						.catch((err) => console.error(err));
					break;
				case 'categories_spares':
					API.searchSparesCategory(searchKey)
						.then((response) => {
							const category = [];
							response.data.data.forEach((item) =>
								category.push({ slug: item.slug })
							);
							this.setState({
								middleContent: response.data || [],
								category: category,
								masterContent: response.data || [],
							});
						})
						.catch((err) => console.error(err));
					break;
				default:
					console.log('No match');
			}
		}
	}
	filterCategory = (categoryKey) => {
		const filteredMiddleContent = this.state.middleContent.data.filter(
			(item) => {
				let valid = false;
				valid = categoryKey ? item.slug === categoryKey : true;
				return valid;
			}
		);
		this.setState({
			middleContent: {
				...this.state.middleContent,
				data: [...filteredMiddleContent],
			},
			masterContent: {
				...this.state.middleContent,
				data: [...filteredMiddleContent],
			},
		});
	};

	updateMasterTabCategory = (item, subCategory = 'segment') => {
		this.setState(
			{
				masterTabCategory: { category: item, subCategory },
				masterContent: { data: [] },
				rightContent: NaN,
				showRightContent: false,
			},
			() =>
				this.debounceTabClickCallSpares(item, this, {
					category: item,
					subCategory,
				})
		);
	};

	debounceTabClickCallSpares = _.debounce(API.tabClickCallSpares, 1500);

	render() {
		if (
			this.state.status === 'not logged in' ||
			new Date(localStorage.getItem('token_expiration_date')) <= new Date()
		) {
			localStorage.removeItem('access_token');
			localStorage.removeItem('token_expiration_date');
			localStorage.removeItem('user_id');
			localStorage.removeItem('phone');
			localStorage.removeItem('custom_token');
			localStorage.removeItem('user_roles');
			return <Redirect to='/login' />;
		} else if (this.state.selectedTab === 'home') {
			return <Redirect to='/' />;
		} else if (this.state.selectedTab === 'support_chat') {
			return <Redirect to='/chat' />;	
		}
		else if (this.state.selectedTab === "spares_content") {
            return <Redirect to="/spares/content" />;
        }
		else {
			// return <Redirect to="/dashboard"/>
			return (
				<div id='containermainouter1'>
					{this.state.selectedTab &&
						!['EPC', 'EPCC',].includes(
							this.state.selectedTab.toLocaleUpperCase()
						) && (
							<PanelRight
								masterTabCategory={this.state.masterTabCategory}
								masterContent={this.state.masterContent}
								content={this.state.rightContent}
								showContent={this.state.showRightContent}
								tag={this.state.selectedTab}
								selectedRow={this.state.selectedRow}
								onRightButtonClick={this.handleRightButtonClick}
								combinationCategories={this.state.combinationCategories}
								combinationBrands={this.state.combinationBrands}
								showCombinationDiscount={this.state.showCombinationDiscount}
								onCombinationDiscount={this.handleCombinationDiscount}
								pincodeSummary={this.state.pincodeSummary}
							/>
						)}

					<PanelLeft
						onTabClick={this.handleTabClick}
						selectedTab={this.state.selectedTab}
						finalContent={this.state.finalContent}
						homepageHeadings={this.state.homepage_headings}

					/>
					<PanelMiddle
						updateMasterTabCategory={this.updateMasterTabCategory}
						masterTabCategory={this.state.masterTabCategory}
						masterContent={this.state.masterContent}
						tag={this.state.selectedTab}
						filterShowing={this.state.filter_showing}
						selectedRow={this.state.selectedRow}
						// filterArgs={this.state.filter_args}
						showLoader={this.state.showLoader}
						setLoader={this.setState}

						extraContent={this.state.middleExtraContent}
						onMiddleExtraButtonClick={this.handleMiddleExtraButtonClick}
						onAddClick={this.handleAddClick}
						content={this.state.middleContent}
						finalContent={this.state.finalContent}
						homepageHeadings={this.state.homepage_headings}
						category={this.state.category}
						showContent={this.state.showMiddleContent}	
						onRowClick={this.handleRowClick}
						onFilterClick={this.handleFilterClick}
						onSearchClick={this.handleSearchClick}
						onSearch1Click={this.handleSearch1Click}
						homepageHeadings={this.state.homepage_headings}
						// onAddNewVariant={this.handleAddNewVariant}
						handleAddClickSpares={this.handleAddClickSpares}
						changeStateBanner={this.changeStateBanner}
						showRows={this.showRows}
						getHomepageHeadings={this.getHomepageHeadings}
						seeLoader={()=>this.setState({showLoader:false})}
						getFinal={this.getFinal}
						getHomepageHeadings={this.getHomepageHeadings}
						filterSegment={this.filterSegment}
						filterCategory={this.filterCategory}
						extraData={this.state.rightContent}
						// onCombinationDiscount={this.handleCombinationDiscount}
						// showCombinationDiscount={this.state.showCombinationDiscount}
					/>
				</div>
			);
		}
	}
}

export default Dashboard;
