import React from 'react';
// import "./dashboard/dashboard.css"
import Row from "./row";
import { Editor } from '@tinymce/tinymce-react';
import API from '../api';
var new_images = [];

class MiddleExtra extends React.Component {


  constructor(props) {
    super(props);
    // console.log("content", this.props.content.content.sku_desc);
    this.state = this.props.content.content ? { sku_desc: this.props.content.content.sku_desc, sku_brand: this.props.content.content.sku_brand, sku_category: this.props.content.content.sku_category, price: this.props.content.content.price, mrp: this.props.content.content.mrp, hsn_code: this.props.content.content.hsn_code, color: this.props.content.content.color, image_url: this.props.content.content.image_url, sku_code: this.props.content.content.sku_code } : {};
    // this.state.content = ;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);

    // this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.fileUpload = this.fileUpload.bind(this);
  }

  onChange(e) {
    // console.log("stating");
    // var files = this.state.files;
    // if (!files || files == null){
    //   files = [];
    // }
    var image_url = this.state.image_url;
    // console.log("file", e.target.files[0]);
    new_images.push(e.target.files[0]);
    // // console.log("files", files);
    // this.setState({imageFiles:files});
    // console.log("imageFiles", new_images);
    image_url.push(URL.createObjectURL(e.target.files[0]));
    this.setState({ image_url: image_url });
  }

  // onFormSubmit(e){
  //     e.preventDefault() // Stop form submit
  //     this.fileUpload(this.state.file).then((response)=>{
  //         // console.log(response.data);
  //     })
  // }

  // fileUpload(file){
  //   return API.bulkUploadProducts(file);
  // }

  handleButtonClick(type) {

    var that = this;
    if (type == "save") {
      // this.refs.btn.setAttribute("disabled", "disabled");
      // // console.log("here1", this.props.conte);
      if (this.props.content.type == "edit") {
        API.updateSparesProduct(this.props.selectedRow, this.state, new_images).then(function (response) {
          // console.log("response");
          // console.log(response);
          if (response.data.status) {
            that.props.onMiddleExtraButtonClick(type, that.state, that.props.selectedRow);
          } else {

          }
        })
          .catch(function (error) {
            // handle error
            // console.log(error);
          })
          .finally(function () {
            // always executed
          });
      } else if (this.props.content.type == "add") {
        // API.addContentWebsite(this.props.content.tag, this.state).then(function (response) {
        //   // console.log("response");
        //   // console.log(response);
        //   if(response.data.status){
        //     that.props.onMiddleExtraButtonClick(type, that.state, null);
        //   }else{

        //   }
        //   })
        //   .catch(function (error) {
        //   // handle error
        //   // console.log(error);
        //   })
        //   .finally(function () {
        //   // always executed
        //   });
      } else if (this.props.content.type == "delete") {
        // API.deleteContentWebsite(this.props.content.tag, this.state).then(function (response) {
        //   // console.log("response");
        //   // console.log(response);
        //   if(response.data.status){
        //     that.props.onMiddleExtraButtonClick(type, that.state, null);
        //   }else{

        //   }
        //   })
        //   .catch(function (error) {
        //   // handle error
        //   // console.log(error);
        //   })
        //   .finally(function () {
        //   // always executed
        //   });
      }
    } else {
      this.props.onMiddleExtraButtonClick(type, null);
    }
  }

  handleChange(type, e) {
    // console.log(this.state);
    var obj = this.state;
    obj[type] = e.target.value;
    this.setState(obj);
    // console.log(e.target.value);
    // console.log(type);
    // console.log(this.state);
  }

  handleTinyMCEChange(type, e) {
    // console.log(e);
    // console.log(this.state);
    var obj = this.state;
    obj[type] = e.target.getContent();
    this.setState(obj);
    // console.log(e.target.getContent());
    // console.log(type);
    // console.log(this.state);
    // console.log(e);
  }

  render() {
    var that = this;
    // console.log(that.props.content.tag);
    // console.log("type", that.props.content.type);
    if (that.props.content.type == "delete") {
      return (
        <div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box">
          <div className="extramainpanel border-box leftalign">
            <div className="border-box clearfix title">
              <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Delete</div>
              <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.handleButtonClick("close")}>Close</button></div>
            </div>

            <div className="border-box clearfix fields">
              <div className="border-box clearfix field1">
                {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7 rightalign">Are you sure you want to delete this entry?</div>
                {/* <input type="text" placeholder="Heading" value={this.state.heading} className="field border-box small" onChange={(e) => this.handleChange("heading", e)}/> */}
              </div>
              {/* <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Content</div>
                    <input type="text" placeholder="Page path" value={this.state.content} className="field border-box small" onChange={(e) => this.handleChange("content", e)}/>
                  </div> */}
            </div>
            <div className="border-box clearfix footer">
              {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
              <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.handleButtonClick("save")}>Delete</button></div>
            </div>
          </div>
        </div>
      );
    } else {
      switch (this.props.content.tag) {

        case "products":

          const images = this.state.image_url.map((image) =>
            <ImageBox url={image} />
          );
          return (
            <div id="dashboardprimeextra" className="border-box bgwhite1 shadow-box">
              <div className="extramainpanel border-box leftalign">
                <div className="border-box clearfix title">
                  <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">{this.props.content.type == "add" ? "Add" : "Edit"} details</div>
                  <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.handleButtonClick("close")}>Close</button></div>
                </div>

                {/* {this.props.content.type == "add" ? (<div className="border-box clearfix fields">
                              <div className="border-box clearfix field2">
                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">SKU DESC</div>
                                <input type="text" placeholder="SKU DESC" value={this.state.sku_desc} className="field border-box small" onChange={(e) => this.handleChange("sku_desc", e)}/>
                              </div>
                            </div>) : (<React.Fragment />)} */}

                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">SKU DESC</div>
                    <input type="text" placeholder="SKU DESC" value={this.state.sku_desc} className="field border-box small" onChange={(e) => this.handleChange("sku_desc", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">SKU Brand</div>
                    <input type="text" placeholder="SKU Brand" value={this.state.sku_brand} className="field border-box small" onChange={(e) => this.handleChange("sku_brand", e)} />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">SKU Category</div>
                    <input type="text" placeholder="SKU Category" value={this.state.sku_category} className="field border-box small" onChange={(e) => this.handleChange("sku_category", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">HSN Code</div>
                    <input type="text" placeholder="HSN Code" value={this.state.hsn_code} className="field border-box small" onChange={(e) => this.handleChange("hsn_code", e)} />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Price</div>
                    <input type="text" placeholder="Price" value={this.state.price} className="field border-box small" onChange={(e) => this.handleChange("price", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">MRP</div>
                    <input type="text" placeholder="MRP" value={this.state.mrp} className="field border-box small" onChange={(e) => this.handleChange("mrp", e)} />
                  </div>
                </div>
                <div className="border-box clearfix fields">
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Color</div>
                    <input type="text" placeholder="Color" value={this.state.color} className="field border-box small" onChange={(e) => this.handleChange("color", e)} />
                  </div>
                  <div className="border-box clearfix field2">
                    {/* <input type="text" id="fielddateto" name="dateto" placeholder="End date" style={{fontSize : '0.6rem', padding : '.35rem .75rem .45rem'}} /> */}
                    <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">Add Image</div>
                    {/* <input type="text" placeholder="Color" value={this.state.color} className="field border-box small" onChange={(e) => this.handleChange("color", e)}/> */}
                    <input type="file" onChange={this.onChange} />
                  </div>

                  {/* <div className="border-box clearfix field2">
                                <div className="border-box clearfix uppercased fieldtitle weight700 blackspan7">MRP</div>
                                <input type="text" placeholder="MRP" value={this.state.mrp} className="field border-box small" onChange={(e) => this.handleChange("mrp", e)}/>
                              </div> */}
                </div>

                <div className="border-box clearfix fields">
                  {images}
                </div>


                <div className="border-box clearfix footer">
                  {/* <div className="border-box floatleft leftalign blackspan2 weight900 shadow-text">Edit details</div> */}
                  <div className="border-box floatright rightalign"><button class="btn btn_small btncolorprim btncard move btncloseextra" onClick={() => this.handleButtonClick("save")}>Save</button></div>
                </div>
              </div>
            </div>
          );

        default:
          return (<React.Fragment />);
      }
    }

  }
}



class ImageBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.content ? this.props.content : {};
    // this.state.content = ;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleTinyMCEChange = this.handleTinyMCEChange.bind(this);
  }

  handleButtonClick(type) {

  }

  render() {
    return (
      <React.Fragment>
        <div className="border-box fieldimage1">
          <img src={this.props.url} height="100" width="100" />
          {/* <button class="btn btn_small btncolorprim btncard move btndelete" onClick={() => this.handleButtonClick("delete")}>Delete</button> */}
        </div>
      </React.Fragment>
    );
  }
}

export default MiddleExtra;